import { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import ButtonDropdownOption from './ButtonDropdownOption';
import Icon from '../icon/Icon';

type DefaultActionBtnProps = {
  disabled?: boolean;
};

const DefaultActionBtn: React.FC<DefaultActionBtnProps> = ({ disabled }) => {
  return (
    <Icon
      icon='elipsis_horizontal'
      color={disabled ? 'disabled' : 'gray-dark'}
      size='medium'
      aria-disabled={disabled}
    />
  );
};

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  button?: ReactNode;
  options: DropDownActionOption[];
  id?: string;
  disabled?: boolean;
  position?: 'bottom' | 'top' | 'left' | 'right';
}

function ButtonDropdown({
  options,
  disabled = false,
  position = 'bottom',
  button = <DefaultActionBtn disabled={disabled} />,
  ...rest
}: Props) {
  const [showOptions, setShowOptions] = useState(false);

  const wrapperRef: any = useRef(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleShowOptions = (e: MouseEvent<HTMLDivElement>) => {
    if (!disabled) {
      e.stopPropagation();
      setShowOptions(!showOptions);
    }
  };

  const hideShowOptions = () => {
    setShowOptions(false);
  };

  options.sort((a, b) => (b.id.includes('delete') ? -1 : 1));

  return (
    <div className='button-dropdown' ref={wrapperRef} {...rest}>
      <div className='button-wrapper' onClick={handleShowOptions}>
        {button}
      </div>
      {showOptions && (
        <div className={`button-dropdown-options ${position}`}>
          {options.map((option) => (
            <ButtonDropdownOption
              key={option.id}
              value={option}
              disabled={option.disabled}
              onClickOption={option.onClick}
              hideShowOptions={hideShowOptions}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default ButtonDropdown;
