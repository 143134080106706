import Modal from 'components/ui/modal/Modal';
import { FormCreateMaterial } from './FormCreateMaterial';

type Props = {
  show: boolean;
  onClose: () => void;
};

export const ModalCreateMaterial: React.FC<Props> = ({ show, onClose }) => {
  return (
    <Modal.WithPortal show={show} onClose={onClose}>
      {show && <FormCreateMaterial onClose={onClose} />}
    </Modal.WithPortal>
  );
};
