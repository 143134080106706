import React, { useEffect, useState } from 'react';
import './Snowflakes.scss';
import { ReactComponent as SnowflakeSVG } from './snow.svg';

const Snowflakes: React.FC = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    // Set a timer to hide the snowflakes after 5 seconds
    const timer = setTimeout(() => {
      setShow(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const snowflakes = Array.from({ length: 60 }, (_, index) => {
    // Generate random sizes and positions for the snowflakes
    const size = Math.random() * 50; // size between 10px and 40px
    const left = Math.random() * 100; // left position as a percentage (0% to 100%)
    const delay = Math.random() * 5; // random delay for animation

    return {
      key: index,
      size,
      left,
      delay
    };
  });

  return (
    <div className={`snowflakes-container ${!show ? 'hidden' : ''}`}>
      {snowflakes.map(({ key, size, left, delay }) => (
        <div
          key={key}
          className='snowflake'
          style={{
            left: `${left}%`,
            animationDelay: `${delay}s`
          }}>
          <SnowflakeSVG width={`${size}px`} height={`${size}px`} />
        </div>
      ))}
    </div>
  );
};

export default Snowflakes;
