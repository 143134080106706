export const localStorageGetItem = (key: string): any => {
  const value = localStorage.getItem(key);
  if (!value) return null;

  try {
    // Attempt to parse the JSON
    return JSON.parse(value) as any;
  } catch {
    // Return the plain string if parsing fails
    return value as any;
  }
};

export const localStorageSetItem = <T>(key: string, item: T): void => {
  if (typeof item === 'string') {
    // Store plain strings directly
    localStorage.setItem(key, item);
  } else {
    // Store objects/arrays as JSON strings
    localStorage.setItem(key, JSON.stringify(item));
  }
};
