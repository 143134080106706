import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateOrganizationFiscalData, updateOrganizationInvoicingData } from '../../actions/auth';
import { UserContext } from '../../context/userContext';
import EditFiscalData from './fiscal/editFiscalData/EditFiscalData';
import LoaderCard from '../ui/loaders/loaderCard/LoaderCard';
import Modal from '../ui/modal/Modal';
import FiscalData from './FiscalData';
import OrganizationData from './OrganizationData';

import UserData from './UserData';
import './styles.scss';
import { EmissionFactor } from './emissionFactor/EmissionFactor';
import { Assumptions } from './assumptions/Assumptions';
import { Fiscal } from './fiscal/Fiscal';
import { AccountLinked } from './accountLinked/AccountLiked';
import { useOrgParent } from './hooks/useOrgParent';
import { LinkAccount } from './linkAccount/LinkAccount';
import { LinkPending } from './linkPending/LinkPending';
import useSelectedOrganization from '../../customHooks/useSelectedOrganization';
import { ReportingPeriod } from 'types/entities/organization';

function Profile() {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const dispatch = useDispatch();

  const [showEditFiscalData, setShowEditFiscalData] = useState(false);
  const selectedOrganization = useSelectedOrganization();
  const { data: holding, isLoading } = useOrgParent();

  if (!user) {
    return null;
  }
  const handleShowEditFiscalData = () => {
    setShowEditFiscalData(true);
  };

  const handleHideEditFiscalData = () => {
    setShowEditFiscalData(false);
  };

  const editInvoiceFiscalData = (invoicingData: InvoicingDataType, fiscalData: ReportingPeriod) => {
    handleHideEditFiscalData();
    if (user?.selectedOrganization) {
      dispatch(updateOrganizationInvoicingData(user.selectedOrganization, invoicingData));
      dispatch(updateOrganizationFiscalData(user.selectedOrganization, fiscalData));
    }
  };
  return (
    <section className='profile'>
      <div className='profile__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('profile.title')}</h3>
        <h4 className='subtitle2-font on-light-text-color'>{t('profile.subtitle')}</h4>
      </div>
      {isLoading ? (
        <LoaderCard />
      ) : (
        <div className='profile__body'>
          <UserData user={user} />
          {selectedOrganization && <OrganizationData organization={selectedOrganization} />}
          {selectedOrganization ? (
            <FiscalData
              showEditFiscalDataModal={handleShowEditFiscalData}
              role={selectedOrganization?.role || ''}
              organization={selectedOrganization}
            />
          ) : null}
          <div className='navigation-wrapper'>
            <EmissionFactor />
            <Assumptions />
            <Fiscal />
            {holding?.status === 'accepted' && <AccountLinked holding={holding} />}
            {holding?.status !== 'accepted' && <LinkAccount />}
            {holding?.status === 'pending' && <LinkPending holding={holding} />}
          </div>
        </div>
      )}

      <Modal
        show={showEditFiscalData}
        onClose={handleHideEditFiscalData}
        width='584px'
        maxWidth='584px'>
        {selectedOrganization ? (
          <EditFiscalData
            editInvoiceFiscalData={editInvoiceFiscalData}
            organization={selectedOrganization}
            buttonText={t('profile.save')}
          />
        ) : null}
      </Modal>
    </section>
  );
}

export default Profile;
