import { useTranslation } from 'react-i18next';
import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';
import Form from '../form/Form';
import { useState } from 'react';
import useErrors from '../../../../../../customHooks/useErrors';
import { postCustomEFDetail } from '../../../../../../services/api/customEFDetails';
import useAdapterCustomEF from '../../../../../../adapters/customEF';

type Props = {
  groupId?: string;
  addElement: (element: ICustomEFBack) => void;
};

const AddForm = ({ groupId, addElement }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'customize.create' });
  const ERRORS = useErrors();
  const [error, setError] = useState<ErrorType>();

  const { adaptCustomEFFront2Back2Send } = useAdapterCustomEF();

  const handleCreate = async (formData: ICustomEFFront) => {
    if (!groupId) return;
    const element = await adaptCustomEFFront2Back2Send(formData);

    const response = await postCustomEFDetail({ id: groupId, data: element });

    if (response?.response?.data === 'OVERLAPPING_DATES') {
      setError(ERRORS.CUSTOM_EMISSION_FACTORS.DATES_OVERLAP);
      return;
    }

    if (!response || response instanceof Error || response?.response?.status >= 400) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    addElement(response);
  };

  return (
    <>
      <FormHeader title={t('titleAdd')} description={t('descriptionAdd')} />
      <Form submit={handleCreate} error={error} />
    </>
  );
};

export default AddForm;
