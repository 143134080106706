import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setNotification } from 'actions/notification';
import { AxiosError } from 'axios';
import { useApiErrors } from 'customHooks/translations/useApiErrors';
import { useDispatch } from 'react-redux';
import { createMaterial } from 'services/api/lca/materialManagement';

export const useCreateLCAMaterial = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { t } = useApiErrors();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: createMaterial,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lca-materials'] });
      queryClient.resetQueries({ queryKey: ['lcaImpacts'] });
    },
    onError: (error: AxiosError) => {
      if (error.response?.data) {
        dispatch(setNotification(t(error.response.data)));
      }
    }
  });

  return { createMaterial: mutateAsync, isPending };
};
