import { BusinessTravelQueryParams, getBusinessTravels } from 'services/api/businessTravels';
import useFetchInfiniteList from '../../../../ui/infiniteList/hooks/useFetchInfiniteList';
import { IGetBackendBusinessTravel } from 'types/entities/businessTravels';
import { SIZE_PAGINATION_BIG } from '../../../../../constants';

type Params = {
  description?: string;
  start_date?: number;
  end_date?: number;
  filters?: string;
};
const makeFetch = (queryParams: Params) => async (page: number) => {
  const params: BusinessTravelQueryParams = {
    page,
    size: SIZE_PAGINATION_BIG,
    ...queryParams
  };
  const response = await getBusinessTravels(params);

  return response;
};

export const useGetData = ({ description, start_date, end_date, filters }: Params) => {
  const { rest, ...response } = useFetchInfiniteList<IGetBackendBusinessTravel, any>(
    makeFetch({ description, start_date, end_date, filters }),
    [description, start_date, end_date, filters]
  );

  return {
    ...response,
    total2: rest?.total2,
    totalTerminated: rest?.total_terminated
  };
};
