import { ChangeEvent, useCallback, useEffect, useState } from 'react';
const modalSelector = {
  primary: {
    small: '.extra-scope-kv3vph .extra-scope-91j9gp',
    medium: '.extra-scope-kv3vph .extra-scope-1f4az75',
    large: '',
    fit: ''
  },
  secondary: {
    small: '.extra-scope-kv3vph .extra-scope-kj2xyq',
    medium: '.extra-scope-kv3vph .extra-scope-uelwtj',
    large: '',
    fit: ''
  },
  link: { small: '.extra-scope-kv3vph .extra-scope-1oncxb7', medium: '', large: '', fit: '' }
};
const dropzoneSelector = 'div[role="presentation"]';
const inputSelector = '[role="presentation"] input[type="file"]';

type Params = {
  lookAndFeel?: 'primary' | 'secondary' | 'link';
  size?: 'small' | 'medium' | 'large' | 'fit';
};

const useNuvoFileName = (
  { lookAndFeel = 'primary', size = 'small' }: Params = { lookAndFeel: 'primary', size: 'small' }
) => {
  const [fileName, setFileName] = useState<string>('');
  const [modal, setModal] = useState<Element | null>(null);

  const handleGetFileName = () => {
    const observeModal = () => {
      const targetNode = document.body;

      const observerClick = new MutationObserver((_, observer) => {
        const newModal = document.querySelector(modalSelector[lookAndFeel][size]);
        if (!newModal) {
          return;
        }
        newModal.addEventListener('click', handleClickDropZone, true);
        setModal(newModal);
        observer.disconnect(); // Stop observing once the modal is found
      });

      const observerDropzone = new MutationObserver(() => {
        const dropzone = document.querySelector(dropzoneSelector);
        if (!dropzone) return;
        dropzone.addEventListener('drop', handleOnDropName);
        // observer.disconnect(); // Stop observing once the modal is found
      });

      // Start observing the body for changes
      observerClick.observe(targetNode, { childList: true, subtree: true });
      observerDropzone.observe(targetNode, { childList: true, subtree: true });
    };
    observeModal(); // Start observing when the handleGetFileName is called
  };

  const handleExit = () => {
    modal?.removeEventListener('click', handleClickDropZone, true);
    setFileName('');
  };
  const handleClickDropZone = useCallback(
    (event: any) => {
      const dropzone: any = document.querySelector(dropzoneSelector);
      const input: any = document.querySelector(inputSelector);

      input?.addEventListener('change', handleOnChangeName);
      // click inside of dropzone
      if (dropzone && input && dropzone.contains(event.target) && event.target !== input) {
        input?.click();
        event.stopPropagation();
      }
    },
    [modal]
  );
  const handleOnDropName = (event: any) => {
    const files = event.dataTransfer?.files;
    if (!files) return;
    handleFile(files);
  };
  const handleOnChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files;
    if (!files) return;
    handleFile(files);
  };
  const handleFile = (files: FileList) => {
    const filesArray = Array.from(files);
    let newFileName = '';
    filesArray?.forEach((element: File, i: number) => {
      const period = element.name.lastIndexOf('.');
      const name = element.name.substring(0, period);

      const nameNormalized = name.replace(/[\u0300-\u036f]/g, '').replace(/[\s.()-]+/g, '_');

      if (i === 0) newFileName += nameNormalized;
      else newFileName += `-${nameNormalized}`;
    });
    setFileName(newFileName);
  };
  useEffect(() => {
    return () => {
      modal?.removeEventListener('click', handleClickDropZone, true);
    };
  }, []);
  return { fileName, handleGetFileName, handleExit };
};
export default useNuvoFileName;
