import { forwardRef } from 'react';
import { LCAIcon } from 'components/lca/components/LCAIcon';

type LayoutProps = {
  children: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  disableHover?: boolean;
  className?: string;
};

const Layout = forwardRef<HTMLDivElement, LayoutProps>(
  ({ children, disabled, disableHover, className, selected }, ref) => {
    const classes = ['flex flex-col gap-y-4 p-4 border-box rounded-8 w-full'];

    if (selected) {
      classes.push('shadow-50-purple');
    }

    if (!selected) {
      classes.push('shadow-10');
    }

    if (disabled) {
      classes.push('bg-neutral-gray-warm-80');
    }

    if (!disabled) {
      classes.push('card-bg-color');
    }

    if (!disabled && !disableHover && !selected) {
      classes.push('hover:shadow-50');
    }

    if (className) {
      classes.push(className);
    }

    return (
      <div className={classes.join(' ')} ref={ref}>
        {children}
      </div>
    );
  }
);

Layout.displayName = 'LCACardLayout';

type ContentProps = {
  children: React.ReactNode;
};

const Content: React.FC<ContentProps> = ({ children }) => {
  return <div className='flex items-center gap-x-4'>{children}</div>;
};

const CustomNodeIcon = LCAIcon;

type TotalImpactProps = {
  impact: string;
  unit: string;
  disabled?: boolean;
  position?: 'left' | 'right' | 'default';
};

const POSITION_CLASSES = {
  left: 'mr-auto',
  right: 'ml-auto',
  default: ''
} as const;

const TotalImpact: React.FC<TotalImpactProps> = ({ impact, unit, position = 'default' }) => {
  return (
    <div
      className={`gap-y-1 min-w-20 border-box p-2 flex-col items-center justify-center rounded-8 bg-neutral-gray-warm-80 ${POSITION_CLASSES[position]}`}>
      <h3 className='m-0 font-headings-h4-sb highlight-text-color'>{impact}</h3>
      <p className='m-0 text-tag font-body-b2-r'>{unit}</p>
    </div>
  );
};

type TagProps = {
  children: React.ReactNode;
  disabled?: boolean;
};

const Tag: React.FC<TagProps> = ({ children, disabled }) => {
  if (disabled) {
    return (
      <p className='flex items-center gap-x-1 m-0 text-tag font-body-b2-r text-gray-2 p-1 rounded-4 w-fit-content bg-gray-light-2'>
        {children}
      </p>
    );
  }

  return (
    <p className='flex items-center m-0 text-tag font-body-b2-r tag-bg-text-color p-1 rounded-4 w-fit-content'>
      {children}
    </p>
  );
};

export const CustomNode = {
  Layout,
  Content,
  Icon: CustomNodeIcon,
  TotalImpact,
  Tag
};
