import { useLCATranslation } from 'customHooks/translations/useLCATranslation';

export const useMaterialOptions = () => {
  const { t } = useLCATranslation();

  const options: SelectOptionFormat<string>[] = [
    {
      id: 'material',
      name: t('types.material')
    },
    {
      id: 'energy',
      name: t('types.energy')
    }
  ];

  return { options };
};
