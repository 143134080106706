import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { switchOrganization } from '../../../../../actions/auth';
import { AMAZON_STATUSES } from '../../../../../constants/amzAuth';
import { ROUTES } from '../../../../../constants/routes';
import { UserContext } from '../../../../../context/userContext';
import useAmzAuth from '../../../../../customHooks/useAmzAuth';
import { Organization } from '../../../../../types/entities/organization';
import NameIcon from '../../../../ui/nameIcon/NameIcon';
import './styles.scss';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';

type Props = {
  organization: Organization;
};

const OrganizationSelection = ({ organization }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const flags = useFeatureFlags();
  const { isFromAmzAuth, handleAmazonRedirect } = useAmzAuth();

  const user = useContext(UserContext);
  const handleSelectOrganization = () => {
    dispatch(switchOrganization(organization.id, flags?.sotDcycleDemos));

    navigate(ROUTES.IMPROVE_DASHBOARD);
    if (isFromAmzAuth) {
      handleAmazonRedirect(AMAZON_STATUSES.AUTHORIZATION_SUCCESSFULL);
    }
  };

  return (
    <div onClick={handleSelectOrganization} className='organization'>
      <NameIcon name={organization.company_name} lastName='' />
      <div className='semibold-font '>{organization.company_name}</div>
      <img src='/images/icons/chevronRight.svg' alt='go to organization' className='change-org' />
    </div>
  );
};

export default OrganizationSelection;
