import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';

type Props = {
  onClickMassUpload: () => void;
  onClickManualAdd: () => void;
};

export const NoMaterials: React.FC<Props> = ({ onClickMassUpload, onClickManualAdd }) => {
  const { t } = useLCATranslation();

  return (
    <div className='grid grid-cols-1 place-items-center grow bg-neutral-gray-80'>
      <div className='flex-col gap-y-6 justify-center items-center'>
        <div className='flex-col font-14 weight-400 items-center'>
          <span>{t('materialManagement.noMaterials1')}</span>
          <span>{t('materialManagement.noMaterials2')}</span>
        </div>
        <div className='flex gap-x-2'>
          <Button
            text={t('materialManagement.uploadMaterials')}
            lookAndFeel='primary'
            iconNode={<Icon icon='upload' color='white' />}
            size='small'
            style={{ width: 'fit-content' }}
            onClick={onClickMassUpload}
          />
          <Button
            text={t('materialManagement.addMaterial')}
            lookAndFeel='secondary'
            iconNode={<Icon icon='add' color='gray-dark' />}
            size='small'
            style={{ width: 'fit-content' }}
            onClick={onClickManualAdd}
          />
        </div>
      </div>
    </div>
  );
};
