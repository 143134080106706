import { useQuery } from '@tanstack/react-query';
import { getLCAImpacts } from 'services/api/lca/lcaPortfolio';

export const useLCAImpacts = (acv_id: string, impact_category: SelectOptionFormat<string>) => {
  const { data, refetch, isFetching, isError } = useQuery({
    queryKey: ['lcaImpacts', acv_id, impact_category.id],
    queryFn: () =>
      getLCAImpacts({
        acv_id,
        methodology_id: 'df6be0e7-fa97-4dfe-aafa-f6c47073bae4',
        impact_category: impact_category.id
      }),
    retry: false,
    enabled: false
  });

  return { data, refetch, isFetching, isError };
};
