import { TFunction } from 'react-i18next';

export const makeTags = (material: LCAMaterial, t: TFunction) => {
  const tags: { text: string; title?: string; tooltip?: string }[] = [];

  if (material.country) {
    tags.push({
      text: material.country,
      tooltip: t(material.country)
    });
  }

  if (material.supplier) {
    tags.push({ text: material.supplier, title: material.supplier });
  }

  return tags;
};

export const getStatus = (material: LCAMaterial) => {
  if (material.unit_list.some((unit) => !unit.value)) {
    return 'needs_match';
  }

  return 'matched';
};
