import { LCAMaterialMetadata } from '../../../../../services/api/lca/lcaPortfolio';
import { INITIAL_FORM, LCAFormMaterial } from './constants';

export const mergeFormMaterialsAndSelectedMaterials = (
  selectedMaterials: LCAFormMaterial[],
  formMaterials: LCAFormMaterial[]
) => {
  return selectedMaterials.map((material) => {
    const formMaterial = formMaterials.find((m) => m.material_id === material.material_id);

    return {
      ...material,
      material_id: material.material_id,
      quantity: formMaterial?.quantity ?? '',
      unit: formMaterial?.unit ?? '',
      unit_id: formMaterial?.unit_id ?? ''
    };
  });
};

export const buildFormFromMetadata = (
  metadata: LCAMaterialMetadata | undefined,
  materials?: Omit<
    Required<LCAFormMaterial>,
    'code' | 'impact_source_id' | 'tech_notes' | 'description'
  >[]
) => {
  return {
    name: metadata?.name ?? INITIAL_FORM.name,
    materials: materials ?? INITIAL_FORM.materials
  };
};
