import { createContext, useEffect, useState } from 'react';
import Modal from '../../../../ui/modal/Modal';
import { FormProvider } from './FormContext';
import { STEP_IDS, STEPS } from './constants';
import useStepCarousel from '../../../../ui/stepCarouselList/useStepCarousel';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { StepProvider } from '../../../../ui/stepCarouselList/StepContext';
import { formSchema } from './schema';
import { adaptZodErrors } from '../../../../../adapters/zodErrors';
import { useModalContext } from '../ModalHandler';
import { useConfigureProcessForm } from '../../hooks/useConfigureProcessForm';
import { buildInputsFromMaterials, buildMaterialsFromInputs } from './utils';
import { AddInputModal } from '../AddInputModal';
import { FirstStep } from './Steps/FirstStep';
import { SecondStep } from './Steps/SecondStep';
import { FormCreateMaterial } from 'components/lca/MaterialManagement/components/FormCreateMaterial';
import { ModalBulkCreateMaterials } from 'components/lca/MaterialManagement/components/ModalBulkCreateMaterials';
import { useErrorTranslations } from 'customHooks/translations/useErrorTranslations';

type ConfigureProcessModalContextType = {
  nodeId: string | null;
  setNodeId: React.Dispatch<React.SetStateAction<string | null>>;
};
const Context = createContext<ConfigureProcessModalContextType | null>(null);

const Provider: React.FC = ({ children }) => {
  const [nodeId, setNodeId] = useState<string | null>(null);

  return <Context.Provider value={{ nodeId, setNodeId }}>{children}</Context.Provider>;
};

export const ConfigureProcessModal = () => {
  const { setModalData, modalData } = useModalContext();

  const { form, setForm, errors, setErrors } = useConfigureProcessForm(modalData?.target);

  const [isSelectMaterialOpen, setIsSelectMaterialOpen] = useState(false);
  const [isOpenBulkCreateMaterial, setIsOpenBulkCreateMaterial] = useState(false);
  const [isOpenSingleCreateMaterial, setIsOpenSingleCreateMaterial] = useState(false);

  const { t } = useLCATranslation();
  const { t: tError } = useErrorTranslations();

  const { stepSelected, steps, handleSelect } = useStepCarousel({
    stepsText: STEPS.map((step) => ({ id: step.id, text: t(step.text) })),
    selected: STEPS[0].id
  });

  useEffect(() => {
    handleSelect(STEPS[0].id);
  }, [modalData?.target]);

  if (!modalData?.target || modalData.type !== 'edit_process') {
    return null;
  }

  const onSelect = (id: string) => {
    const validationSuccess = formSchema[stepSelected?.id ?? STEP_IDS.FIRST]?.safeParse(form);

    if (!validationSuccess.success) {
      setErrors(adaptZodErrors(validationSuccess.error, tError));
      return;
    }

    handleSelect(id);
  };

  if (isOpenBulkCreateMaterial) {
    return (
      <Modal.WithPortal
        show={isOpenBulkCreateMaterial}
        onClose={() => setIsOpenBulkCreateMaterial(false)}
        width='720px'>
        <ModalBulkCreateMaterials.Header />
        <ModalBulkCreateMaterials.Body onClose={() => setIsOpenBulkCreateMaterial(false)} />
      </Modal.WithPortal>
    );
  }

  if (isOpenSingleCreateMaterial) {
    return (
      <Modal.WithPortal
        show={isOpenSingleCreateMaterial}
        onClose={() => setIsOpenSingleCreateMaterial(false)}
        width='720px'>
        <FormCreateMaterial onClose={() => setIsOpenSingleCreateMaterial(false)} />
      </Modal.WithPortal>
    );
  }

  if (isSelectMaterialOpen) {
    const goBack = () => setIsSelectMaterialOpen(false);

    const updateMaterials = (materials: LCAMaterial[]) => {
      const inputsInMaterials = form.inputs.filter((input) =>
        materials.some((material) => material.id === input.material_id)
      );

      const materialsNotInInputs = materials.filter((material) => {
        return !inputsInMaterials.some((input) => input.material_id === material.id);
      });

      const newInputs = [...inputsInMaterials, ...buildInputsFromMaterials(materialsNotInInputs)];

      setForm((prev) => ({ ...prev, inputs: newInputs }));
    };

    return (
      <Modal.WithPortal
        show={isSelectMaterialOpen}
        onClose={() => setIsSelectMaterialOpen(false)}
        width='720px'>
        <AddInputModal
          goBack={goBack}
          defaultMaterials={buildMaterialsFromInputs(form.inputs)}
          updateMaterials={updateMaterials}
          onClickManualAdd={() => setIsOpenSingleCreateMaterial(true)}
          onClickMassUpload={() => setIsOpenBulkCreateMaterial(true)}
        />
      </Modal.WithPortal>
    );
  }

  return (
    <Modal.WithPortal
      show={Boolean(modalData.target) && modalData.type === 'edit_process'}
      onClose={() => setModalData(undefined)}
      width='720px'>
      <StepProvider steps={steps} selectedStep={stepSelected} handleSelect={onSelect}>
        <FormProvider form={form} setForm={setForm}>
          {stepSelected?.id === STEP_IDS.FIRST && <FirstStep errors={errors} />}
          {stepSelected?.id === STEP_IDS.SECOND && (
            <SecondStep
              setErrors={setErrors}
              openSelectMaterialModal={() => setIsSelectMaterialOpen(true)}
            />
          )}
        </FormProvider>
      </StepProvider>
    </Modal.WithPortal>
  );
};

ConfigureProcessModal.Root = Provider;
