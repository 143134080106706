import { useFeatureFlags } from 'customHooks/useFeatureFlags';
import { useTranslation } from 'react-i18next';

export const useSteps = () => {
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  return [
    {
      id: 'doubleMateriality',
      text: t('doubleMateriality.steps.doubleMateriality')
    },
    {
      id: 'companyContext',
      text: t('doubleMateriality.steps.companyContext'),
      disabled: !flags?.doubleMaterialityTabs
    },
    {
      id: 'engageStakeholders',
      text: t('doubleMateriality.steps.engageStakeholders'),
      disabled: !flags?.doubleMaterialityTabs
    },
    {
      id: 'irosGapAnalysis',
      text: t('doubleMateriality.steps.irosGapAnalysis'),
      disabled: !flags?.doubleMaterialityTabs
    }
  ];
};
