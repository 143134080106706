import React, { Dispatch, SetStateAction, useEffect } from 'react';
import DivideConsumption from './components/DivideConsumption';
import FormElementFull from '../../../../../../../../ui/formComponents/formElementFull/FormElementFull';
import { useTranslation } from 'react-i18next';
import SuccessLabel from '../../../../../../../../ui/statusLabels/successLabel/SuccessLabel';
import ErrorLabel from '../../../../../../../../ui/statusLabels/errorLabel/ErrorLabel';
import WarningLabel from '../../../../../../../../ui/statusLabels/warningLabel/WarningLabel';
import { useFeatureFlags } from '../../../../../../../../../customHooks/useFeatureFlags';
import TooltipWrapper from '../../../../../../../../ui/tooltip/TooltipWrapper';
import StepCarouselList from '../../../../../../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../../../../../../ui/stepCarouselList/useStepCarousel';
import { Percentages } from '../../../../../../../../../types/entities/invoice';
import { Facility } from '../../../../../../../../../types/entities/facility';
import Tooltip from '../../../../../../../../ui/tooltip/Tooltip';
import useSelectedOrganization from '../../../../../../../../../customHooks/useSelectedOrganization';
import { numberToDecimalNonZero } from '../../../../../../../../../utils/numberToDecimal';
import formatNumber from '../../../../../../../../../utils/formatNumber';

type Props = {
  formData: {
    quantity?: string;
    unit?: SelectOptionFormat;
    errors: ErrorType[];
    percentages: Percentages[];
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFormData: Dispatch<SetStateAction<any>>;
  organizationOptions: SelectOptionFormat[];
  units: SelectOptionFormat[];
  facility: Facility;
};
const DivideConsumptionsWrapper = ({
  formData,
  setFormData,
  organizationOptions,
  units,
  facility
}: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'facilityDetail' });
  const flags = useFeatureFlags();
  const selectedOrganization = useSelectedOrganization();

  const {
    stepSelected,
    handleSelect: handleSelectCarousel,
    steps
  } = useStepCarousel({
    stepsText: [
      {
        id: 'no_consumption_division',
        text: t('noConsumptionDivision')
      },
      {
        id: 'consumption_division',
        text: t('consumptionDivision')
      }
    ]
  });

  useEffect(() => {
    const totalPercentages = parseFloat(
      formData.percentages.reduce((acc, elem) => acc + parseFloat(elem.percentage), 0).toFixed(2)
    );
    const newErrors = formData.errors.filter((elem) => elem.error !== 'percentages');

    if (totalPercentages > 100) {
      newErrors.push({
        error: 'percentages',
        description: t('moreThan100', {
          remaining: totalPercentages - 100,
          percentage: totalPercentages
        })
      });
    }
    if (!totalPercentages) {
      newErrors.push({
        error: 'percentages',
        description: t('noPercentageAssigned')
      });
    }

    setFormData((prev: any) => ({
      ...prev,
      errors: newErrors
    }));

    // Check if there is at least one percentage less than 100
    const atLeastOneLessThan100 = formData.percentages.some(
      (elem) => parseFloat(elem.percentage) < 100
    );

    if (atLeastOneLessThan100) {
      handleSelectCarousel('consumption_division');
    }
  }, [JSON.stringify(formData.percentages)]);

  const onChangeStep = (step: string) => {
    if (step === 'no_consumption_division') {
      setFormData((prev: any) => ({
        ...prev,
        percentages: [
          {
            facility: { id: facility.id, name: facility.name },
            organization: {
              id: selectedOrganization?.id ?? '',
              name: selectedOrganization?.company_name ?? ''
            },
            percentage: '100'
          }
        ]
      }));
    }
    handleSelectCarousel(step);
  };

  const handleAddMore = () => {
    setFormData((prev: any) => ({
      ...prev,
      percentages: [
        ...prev.percentages,
        {
          organization: {
            id: organizationOptions.length === 1 ? organizationOptions[0].id : '',
            name: organizationOptions.length === 1 ? organizationOptions[0].name : ''
          },
          facility: { id: '', name: '' },
          percentage: '0'
        }
      ]
    }));
  };

  const renderLabel = () => {
    const totalPercentages = parseFloat(
      formData.percentages.reduce((acc, elem) => acc + parseFloat(elem.percentage), 0).toFixed(2)
    );

    if (totalPercentages === 100) {
      return <SuccessLabel>{t('100Assigned')}</SuccessLabel>;
    }

    if (totalPercentages > 100) {
      return (
        <ErrorLabel>
          {t('moreThan100', {
            remaining: formatNumber(numberToDecimalNonZero(totalPercentages - 100)),
            percentage: formatNumber(numberToDecimalNonZero(totalPercentages))
          })}
        </ErrorLabel>
      );
    }

    if (totalPercentages === 0 || isNaN(totalPercentages)) {
      return <ErrorLabel>{t('noPercentageAssigned')}</ErrorLabel>;
    }

    return (
      <WarningLabel>
        {t('remainingUntil100', {
          remaining: formatNumber(numberToDecimalNonZero(100 - totalPercentages)),
          percentage: formatNumber(numberToDecimalNonZero(totalPercentages))
        })}
      </WarningLabel>
    );
  };

  if (!flags?.consumptionDivision) return null;

  return (
    <FormElementFull>
      <div className='flex-col gap-4'>
        <div className='flex items-center gap-2'>
          <span className='input-label-font on-light-text-color '>
            {t('quantityPosted')}
            {': '}
          </span>
          <StepCarouselList steps={steps} handleSelect={onChangeStep} lookAndFeel='small' />
          <Tooltip text={t('divisionStepTooltip')} />
        </div>
        {stepSelected?.id === 'consumption_division' && (
          <div className='flex-col gap-2'>
            {formData.percentages.map((elem, index) => {
              return (
                <DivideConsumption
                  key={index}
                  formData={formData}
                  index={index}
                  value={elem}
                  setFormData={setFormData}
                  organizationOptions={organizationOptions}
                  units={units}
                />
              );
            })}
          </div>
        )}

        {stepSelected?.id === 'consumption_division' && (
          <div className='flex justify-between gap-2'>
            <TooltipWrapper text={t('alreadyAddedAllFacilities')} shouldAppear={false}>
              <div
                onClick={handleAddMore}
                className={`font-12 weight-600 pointer`}
                style={{ textDecoration: 'underline' }}>
                {`+ ${t('addAnotherFacility')}`}
              </div>
            </TooltipWrapper>

            {renderLabel()}
          </div>
        )}
      </div>
    </FormElementFull>
  );
};

export default DivideConsumptionsWrapper;
