import { useTranslation } from 'react-i18next';
import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';
import Form from '../form/Form';
import useErrors from '../../../../../../customHooks/useErrors';
import { useState } from 'react';
import { patchCustomEFDetail } from '../../../../../../services/api/customEFDetails';
import useAdapterCustomEF from '../../../../../../adapters/customEF';
import useElementToEdit from './hooks/useElementToEdit';

type Props = {
  id: string;
  editElement: (element: ICustomEFBack) => void;
};

const EditForm = ({ id, editElement }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'customize.create' });
  const ERRORS = useErrors();
  const [error, setError] = useState<ErrorType>();

  const { adaptCustomEFFront2Back2Send, adaptCustomEFBack2Front } = useAdapterCustomEF();
  const { element, loading } = useElementToEdit({ id });
  const elementAdapted = element ? adaptCustomEFBack2Front(element) : undefined;

  const handleEdit = async (formData: ICustomEFFront) => {
    const element = await adaptCustomEFFront2Back2Send(formData);

    const response = await patchCustomEFDetail({ id, data: element });
    if (response?.response?.data === 'OVERLAPPING_DATES') {
      setError(ERRORS.CUSTOM_EMISSION_FACTORS.DATES_OVERLAP);
      return;
    }
    if (!response || response?.response?.status >= 400) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    editElement(response);
  };

  return (
    <>
      <FormHeader title={t('titleEdit')} description={t('descriptionEdit')} />
      <Form submit={handleEdit} error={error} loading={loading} filledForm={elementAdapted} />
    </>
  );
};

export default EditForm;
