import { useTranslation } from 'react-i18next';
import { convertDateBackToFront } from '../../../../../utils/convertDates';
import Label from '../../../../ui/label/Label';
import Icon from '../../../../ui/icon/Icon';
import { useState } from 'react';
import ButtonDropdown from '../../../../ui/buttonDropdown/ButtonDropdown';

const useColumns = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'customize.table' });
  const { t: tC } = useTranslation('translation', { keyPrefix: 'customize' });
  const { t: tN } = useTranslation();

  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = () => setShowAddModal(true);
  const handleHideAddModal = () => setShowAddModal(false);

  const [elementToEdit, setElementToEdit] = useState('');

  const handleShowEdit = (id: string) => setElementToEdit(id);
  const handleHideEdit = () => setElementToEdit('');

  const [elementToDelete, setElementToDelete] = useState('');

  const handleShowDelete = (id: string) => setElementToDelete(id);
  const handleHideDelete = () => setElementToDelete('');

  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      render: (_: unknown, customEFDetail: ICustomEFBack) => {
        return customEFDetail.ef_name;
      }
    },
    {
      title: t('emissionFactor'),
      dataIndex: 'emissionFactor',
      key: 'emissionFactor',
      render: (_: unknown, customEFDetail: ICustomEFBack) => {
        let result = '';

        customEFDetail.emission_factor_values.forEach((emissionFactor, i) => {
          const value = `${emissionFactor.value} ${tN(`gasType.${emissionFactor.gas_type}`)}`; // ABC
          if (i === 0) result += value;
          else result += ` / ${value}`;
        });

        return result;
      }
    },
    {
      title: t('datRange'),
      dataIndex: 'datRange',
      key: 'datRange',
      render: (_: unknown, customEFDetail: ICustomEFBack) => {
        const startDate = convertDateBackToFront(customEFDetail.factor_start_date);
        const endDate = convertDateBackToFront(customEFDetail.factor_end_date);

        return `${startDate} - ${endDate}`;
      }
    },
    {
      title: t('type'),
      dataIndex: 'type',
      key: 'type',
      render: (_: unknown, customEFDetail: ICustomEFBack) => {
        return (
          <Label width='3rem' className='flex items-center justify-center'>
            {customEFDetail.tag}
          </Label>
        );
      }
    },
    // {
    //   title: t('customize.table.uploadedBy'),
    //   dataIndex: 'uploadedBy',
    //   key: 'uploadedBy'
    // },
    {
      title: t('attached'),
      dataIndex: 'attached',
      key: 'attached',
      render: (_: unknown, customEFDetail: ICustomEFBack) => {
        return customEFDetail.additional_docs ? (
          <>
            <a href={String(customEFDetail.additional_docs)} download>
              <Icon icon='attachment' color='gray' />
            </a>
          </>
        ) : null;
      }
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      render: (_: unknown, customEFDetail: ICustomEFBack) => {
        const options = [
          {
            id: `${customEFDetail.id}-edit`,
            name: tC('edit'),
            onClick: () => handleShowEdit(customEFDetail.id)
          },
          {
            id: `${customEFDetail.id}-delete`,
            name: tC('delete'),
            onClick: () => handleShowDelete(customEFDetail.id)
          }
        ];

        return (
          <ButtonDropdown
            button={<img src='/images/icons/editPen.svg' height={19} width={18} alt='edit-pen' />}
            options={options}
          />
        );
      }
    }
  ];

  return {
    columns,
    showAddModal,
    elementToEdit,
    elementToDelete,
    handleHideAddModal,
    handleShowAddModal,
    handleHideEdit,
    handleShowEdit,
    handleHideDelete,
    handleShowDelete
  };
};

export default useColumns;
