/* export const createLCAMaterialsLoaderSlice = (set: (newVal: boolean) => void) => {
  return {
    lcaMaterialsLoader: false,
    triggerMaterialsLoader: () => {
      set(true);

      setTimeout(() => {
        set(false);
      }, 30 * 1000);
    }
  };
};

export type LCAMaterialsLoaderSlice = ReturnType<typeof createLCAMaterialsLoaderSlice>;
 */

import { createAction, createReducer } from '@reduxjs/toolkit';

const trigger = createAction('TRIGGER_MATERIALS_LOADER');
const reset = createAction('RESET_MATERIALS_LOADER');

const reducer = createReducer(
  {
    lcaMaterialsLoader: false
  },
  (builder) => {
    builder
      .addCase(trigger, (state) => {
        state.lcaMaterialsLoader = true;
      })
      .addCase(reset, (state) => {
        state.lcaMaterialsLoader = false;
      });
  }
);

export const lcaMaterialsLoader = reducer;

export const lcaMaterialsLoaderActions = { trigger, reset };
