import { z } from 'zod';

export const manageUnitSchema = z.object({
  unitList: z.array(
    z.object({
      unit_id: z.string().min(1, { message: 'required' }),
      unit_name: z.string().min(1)
    })
  ),
  values: z.array(
    z
      .string()
      .min(1, { message: 'required' })
      .refine(
        (value) => {
          return !isNaN(Number(value)) && value?.length > 0;
        },
        { message: 'isNumber' }
      )
  )
});
