import moment from 'moment';

export const convertDateToString = (value: Date) => moment(value).format('DD/MM/YYYY');

export const convertStringToDate = (date: string) => {
  if (date === '') return new Date();
  const dateSplitted = date?.split('/');
  const day = dateSplitted[0];
  const month = dateSplitted[1];
  const year = dateSplitted[2];

  const dateParsed = moment.utc(`${year}-${month}-${day}`).toDate();

  // Fix Timezone so it is not 1 day behind
  dateParsed.setUTCHours(0, 0, 0, 0);

  return dateParsed;
};

export const convertStringToDateFullDay = (date: string) => {
  if (date === '') return new Date();
  const dateSplitted = date?.split('/');
  const day = dateSplitted[0];
  const month = dateSplitted[1];
  const year = dateSplitted[2];

  const dateParsed = moment.utc(`${year}-${month}-${day}`).toDate();

  // Fix Timezone so it has the whole day
  dateParsed.setUTCHours(23, 59, 59, 0);

  return dateParsed;
};

export const convertDateToStringBackend = (value: Date) => moment(value).format('YYYY-MM-DD');
export const convertDateToStringFrontend = (value: Date) => moment(value).format('DD/MM/YYYY');

export const convertStringToDateBackend = (date: string) => {
  if (date === '') return new Date();
  return moment.utc(date, 'YYYY-MM-DD').toDate();
};

export const convertDateFrontToBack = (value: string) =>
  moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');

export const convertDateBackToFront = (value: string) =>
  moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');

export const convertDateBackToFrontWithTime = (value: string) =>
  moment(value, 'YYYY-MM-DDTHH:mm:ssZ').format('DD/MM/YYYY HH:mm');

export const convertDateFrontToBackTimestamp = (value: string) =>
  +(convertStringToDate(value).getTime() / 1000).toFixed(0);

export const convertDateBackToFrontTimestamp = (value: number) =>
  moment.unix(value).format('DD/MM/YYYY');

export const checkIfValidStringDate = (value: string) => {
  const dateSplitted = value.split('/');
  if (dateSplitted.length !== 3) return false;
  const day = dateSplitted[0];
  const month = dateSplitted[1];
  const year = dateSplitted[2];

  return (
    year.length === 4 &&
    month.length > 0 &&
    month.length < 3 &&
    +month > 0 &&
    +month < 13 &&
    +day > 0 &&
    day.length > 0 &&
    day.length < 3 &&
    moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').isValid()
  );
};
