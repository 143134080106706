import { useTranslation } from 'react-i18next';
import { gray_color, scope1_color, scope2_color, scope3_color } from '../../../../styles/colors';
import { VehicleReport } from '../../../../types/entities/vehicle';
import formatNumber from '../../../../utils/formatNumber';
import numberToDecimal from '../../../../utils/numberToDecimal';
import StackedBar from '../bar/StackedBar';
import CardHeader from '../cardHeader/CardHeader';
import CardTotalCo2 from '../cardTotalCo2/CardTotalCo2';

type EmissionsByVehicle = {
  vehicle_id: string;
  co2e: number;
  color: string;
};

type Bar = {
  value: number;
  color: string;
};

type VehicleGroupById = {
  id: string;
  co2e: number;
  name: string;
  type: string;
};

type Props = {
  vehicles: VehicleReport[];
};
function Vehicles({ vehicles }: Props) {
  const { t } = useTranslation();

  let totalCo2 = 0;

  vehicles?.forEach((vehicle) => {
    totalCo2 += vehicle.co2e;
  });

  let totalPassengerCo2 = 0;
  let totalFreightCo2 = 0;

  const vehiclesGroupedById = [] as VehicleGroupById[];
  vehicles.forEach((vehicle) => {
    const foundVehicleIndex = vehiclesGroupedById.findIndex((vehicleGroup) => {
      return vehicleGroup.id === vehicle.id;
    });
    if (foundVehicleIndex > -1) {
      vehiclesGroupedById[foundVehicleIndex].co2e += vehicle.co2e;
    } else {
      vehiclesGroupedById.push({
        id: vehicle.id,
        co2e: vehicle.co2e,
        name: vehicle.license_plate !== '' ? vehicle.license_plate : vehicle.name,
        type: vehicle.type
      });
    }
  });

  const vehiclesGroupedByType = {
    passenger: [] as EmissionsByVehicle[],
    freight: [] as EmissionsByVehicle[]
  };

  const colors = [scope2_color, scope3_color, scope1_color, gray_color];

  const vehicleColors = vehiclesGroupedById?.slice(0, 3).map((vehicle, index) => {
    return {
      id: vehicle.id,
      name: vehicle.name,
      color: colors[index]
    };
  });
  vehicleColors.push({
    id: 'remaining',
    name: t('report.remaining'),
    color: gray_color
  });

  vehicles?.forEach((vehicle) => {
    if (vehicle.type === 'passenger') {
      const foundConsumptionIndex = vehiclesGroupedByType['passenger'].findIndex(
        (elem) => elem.vehicle_id === vehicle.id
      );
      if (foundConsumptionIndex > -1) {
        vehiclesGroupedByType['passenger'][foundConsumptionIndex].co2e += vehicle.co2e;
      } else {
        vehiclesGroupedByType['passenger'].push({
          vehicle_id: vehicle.id,
          co2e: vehicle.co2e,
          color: vehicleColors?.find((elem) => elem.id === vehicle.id)?.color || ''
        });
      }
      totalPassengerCo2 += vehicle.co2e;
    } else {
      const foundConsumptionIndex = vehiclesGroupedByType['freight'].findIndex(
        (elem) => elem.vehicle_id === vehicle.id
      );
      if (foundConsumptionIndex > -1) {
        vehiclesGroupedByType['freight'][foundConsumptionIndex].co2e += vehicle.co2e;
      } else {
        vehiclesGroupedByType['freight'].push({
          vehicle_id: vehicle.id,
          co2e: vehicle.co2e,
          color: vehicleColors?.find((elem) => elem.id === vehicle.id)?.color || ''
        });
      }
      totalFreightCo2 += vehicle.co2e;
    }
  });

  const totalList = [totalPassengerCo2, totalFreightCo2];
  const maxTotal = Math.max(...totalList);

  const renderBarSection = (total: number, values: Bar[], type: string, totalByType: number) => {
    return (
      <div className='card-info-rows '>
        <span className='name-logo body1-bold-font'>
          {type === 'passenger' ? (
            <img src='/images/icons/car.svg' alt='car' />
          ) : (
            <img src='/images/icons/truck.svg' alt='truck' />
          )}
          {t(`report.${type}`)}
        </span>
        <StackedBar total={total} values={values} remaining={total - totalByType} />
        <span className='body1-font'>{formatNumber(totalByType / 1000)}</span>
      </div>
    );
  };

  const calculateRemaining = () => {
    let top3Co2 = 0;
    let total = 0;
    if (vehiclesGroupedById.length < 4) {
      return 0;
    }
    vehiclesGroupedById.forEach((elem, index) => {
      if (index < 3) {
        top3Co2 += elem.co2e;
      }
      total += elem.co2e;
    });
    return total - top3Co2;
  };

  const renderName = (vehicle_name: string) => {
    const unknownVehicleNames = [
      'car',
      'van',
      'passenger_car',
      'truck',
      'bus',
      'moped',
      'motorcycle'
    ];

    let name = vehicle_name;

    if (unknownVehicleNames.includes(vehicle_name)) {
      name = t(`vehicles.${vehicle_name}`);
    }

    return name;
  };

  return (
    <div className='measure-detail-report'>
      <div className='card main-bg-color'>
        <CardHeader title={t('report.vehicles')} description={t('report.vehiclesDescription')} />

        <CardTotalCo2 totalCo2={totalCo2} />

        <div className='card-info-columns'>
          <span className='body2-font'>{t('report.top3vehicles')}</span>
          <span className='body2-font'>t CO₂ eq.</span>
          <span className='body2-font'>% Total</span>
        </div>
        <div className='rows-wrapper on-card-gray-bg-color'>
          {vehiclesGroupedById?.slice(0, 3).map((vehicle) => (
            <div className='card-info-rows' key={vehicle.id}>
              <span className='name-logo body1-bold-font'>
                {vehicle.type === 'passenger' ? (
                  <img src='/images/icons/car.svg' alt='car' />
                ) : (
                  <img src='/images/icons/truck.svg' alt='truck' />
                )}
                {renderName(vehicle.name)}
              </span>
              <span className='body1-font'>{formatNumber(vehicle.co2e / 1000)}</span>
              <span className='percentage body1-font tag-bg-text-color'>{`${
                totalCo2 ? formatNumber((vehicle.co2e * 100) / totalCo2) : 0
              } %`}</span>
            </div>
          ))}
          <div className='card-info-rows '>
            <span className='name-logo body1-bold-font'>
              <img src='/images/icons/car.svg' alt='car' />
              {t('report.remaining')}
            </span>
            <span className='body1-font'>{formatNumber(calculateRemaining() / 1000)}</span>
            <span className='percentage body1-font tag-bg-text-color'>{`${
              totalCo2 ? formatNumber((calculateRemaining() * 100) / totalCo2) : 0
            } %`}</span>
          </div>
        </div>
      </div>
      <div className='card main-bg-color '>
        <div className='scope-tag'>
          <div className='text-tag font-10 tag-bg-text-color tag'>{`${t(
            'measureMain.scope'
          )} 1`}</div>
        </div>

        <div className='card-info-columns'>
          <span className='body2-font'>{t('report.distribution')}</span>
          <span className='body2-font'>t CO₂ eq.</span>
        </div>
        <div className='rows-wrapper on-card-gray-bg-color'>
          {renderBarSection(
            maxTotal,
            vehiclesGroupedByType['passenger'].map((elem) => {
              return {
                color: elem.color,
                value: elem.co2e
              };
            }),
            'passenger',
            numberToDecimal(totalPassengerCo2)
          )}
          {renderBarSection(
            maxTotal,
            vehiclesGroupedByType['freight'].map((elem) => {
              return {
                color: elem.color,
                value: elem.co2e
              };
            }),
            'freight',
            numberToDecimal(totalFreightCo2)
          )}
        </div>
        <div className='card-info-columns card-footer'>
          <div className='body2-font'>{t('report.legend')}</div>
        </div>
        <div className='rows-wrapper on-card-gray-bg-color legends'>
          <div className='card-info-rows'>
            {vehicleColors?.map((vehicle) => (
              <div className='legend body1-font' key={vehicle.id}>
                <div className='dot round' style={{ backgroundColor: vehicle.color }} />
                {renderName(vehicle.name)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vehicles;
