import { ComponentProps } from 'react';
import ErrorText from '../../../errorText/ErrorText';
import InputWrapperPlain from '../inputWrapperPlain/InputWrapperPlain';
import InputLabel from './components/InputLabel';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { useErrorTranslations } from 'customHooks/translations/useErrorTranslations';

type InputWrapperPlainType = ComponentProps<typeof InputWrapperPlain>;
type InputLabelType = ComponentProps<typeof InputLabel>;
type DefaultProps = {
  tooltip?: string;
  error?: ErrorType;
  disabled?: boolean;
  className?: string;
};

type Props = InputWrapperPlainType & InputLabelType & DefaultProps;

type WithoutWrapperPlainProps = InputLabelType & DefaultProps;

const WithoutWrapperPlain: React.FC<WithoutWrapperPlainProps> = ({
  error,
  tooltip,
  label,
  ...rest
}) => {
  const { t } = useErrorTranslations();
  return (
    <div className={`input flex-col gap-y-2 ${rest.className ?? ''}`}>
      {label && <InputLabel tooltip={tooltip} label={label} />}
      {rest.children}
      {error && (
        <ErrorText style={{ marginTop: '0.5rem' }}>{error?.description ?? t('required')}</ErrorText>
      )}
    </div>
  );
};

const InputWrapper = ({ error, tooltip, label, ...rest }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={`input ${rest.className ?? ''}`}>
      {label && <InputLabel tooltip={tooltip} label={label} />}
      <InputWrapperPlain {...rest} error={error}>
        {rest.children}
      </InputWrapperPlain>
      {error && (
        <ErrorText style={{ marginTop: '0.5rem' }}>
          {error?.description ?? t('error.requiredField')}
        </ErrorText>
      )}
    </div>
  );
};

InputWrapper.WithoutWrapperPlain = WithoutWrapperPlain;

export default InputWrapper;
