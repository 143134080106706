import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import SectionHeader from 'components/layout/sectionHeader/SectionHeader';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import { useNavigate, useParams } from 'react-router-dom';
import { useLCAOfficialMaterial } from './hooks/useLCAOfficialMaterial';
import CardWrapper from 'components/ui/cards/common/CardWrapper';
import { LCAIcon } from 'components/lca/components/LCAIcon';
import { ICON_MAP } from './constants';
import Button from 'components/ui/button/Button';
import SuccessLabel from 'components/ui/statusLabels/successLabel/SuccessLabel';
import DisabledLabel from 'components/ui/statusLabels/disabledLabel/DisabledLabel';
import { GrayBox } from './components/GrayBox';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from 'components/ui/formComponents2/select/Select';
import { Link } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import InputTextArea from 'components/ui/formComponents2/inputTextArea/InputTextArea';
import useCountries from 'components/measure/vehicles/hooks/useCountries';
import { useMaterialOptions } from '../hooks/useMaterialOptions';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import { useEffect, useState } from 'react';
import Tooltip from 'components/ui/tooltip/Tooltip';
import { useDeleteLCAOfficialMaterial } from './hooks/useDeleteLCAOfficialMaterial';
import { ROUTES } from 'constants/routes';
import { zodResolver } from '@hookform/resolvers/zod';
import { editLCAOfficialMaterialSchema } from './schema/editLCAOfficialMaterialSchema';
import { adaptHookFormDropdownError, adaptHookFormError } from 'lib/ReactHookForm/adaptError';
import { useEditLCAOfficialMaterial } from './hooks/useEditLCAOfficialMaterial';
import { ManageUnitsModal } from 'components/lca/components/ManageUnitsModal';
import LoaderCard from 'components/ui/loaders/loaderCard/LoaderCard';
import WarningLabel from 'components/ui/statusLabels/warningLabel/WarningLabel';

export const MaterialDetail = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { t } = useLCATranslation();
  const { t: tMaterialDetail } = useLCATranslation({
    keyPrefix: 'materialManagement.materialDetail'
  });

  const { data: materialInfo, isLoading } = useLCAOfficialMaterial(id);
  const { deleteMaterial, isPending: isDeleting } = useDeleteLCAOfficialMaterial();
  const { editLCAOfficialMaterial, isEditing } = useEditLCAOfficialMaterial();

  const [isOpenUnitModal, setIsOpenUnitModal] = useState(false);

  const { options } = useMaterialOptions();
  const countries = useCountries();

  const defaultValues = {
    name: materialInfo?.name ?? '',
    supplier: materialInfo?.supplier ?? '',
    description: materialInfo?.description,
    country: countries.find((c) => c.id === materialInfo?.country) || { id: '', name: '' },
    type: options.find((o) => o.id === materialInfo?.type) || { id: '', name: '' },
    impact_source: {
      reference: materialInfo?.impact_source?.reference,
      database: materialInfo?.impact_source?.database,
      country: materialInfo?.impact_source.country,
      unit: materialInfo?.impact_source?.unit ? t(`units.${materialInfo.impact_source.unit}`) : ''
    }
  };

  const { register, formState, watch, setValue, handleSubmit, reset } = useForm({
    defaultValues,
    resolver: zodResolver(editLCAOfficialMaterialSchema)
  });

  useEffect(() => {
    reset(defaultValues);
  }, [JSON.stringify(defaultValues)]);

  if (!id) {
    return null;
  }

  if (isLoading) {
    return (
      <div className='flex-col gap-y-6 h-95svh'>
        <SectionHeader
          title={t('materialManagement.title')}
          breadcrumb={
            <Breadcrumb crumbsReplace={[{ key: id, value: materialInfo?.name ?? 'Loading...' }]} />
          }
        />
        <LoaderCard style={{ flexGrow: 1 }} />
      </div>
    );
  }

  if (!materialInfo) {
    return null;
  }

  const onValid: SubmitHandler<typeof defaultValues> = ({ name, country, supplier, type }) => {
    if (isEditing) return;

    const validatedType = type.id as MaterialType;

    editLCAOfficialMaterial({ id, name, country: country.id, supplier, type: validatedType });
  };

  const onDelete = async () => {
    await deleteMaterial(id);

    navigate(ROUTES.LCA_MATERIAL_MANAGEMENT);
  };

  return (
    <>
      <ManageUnitsModal
        show={isOpenUnitModal}
        onClose={() => setIsOpenUnitModal(false)}
        referenceUnit={{
          unit_id: materialInfo.impact_source.unit_id,
          unit_name: materialInfo.impact_source.unit
        }}
        material_id={id}
        unitList={materialInfo.unit_list}
      />
      <form className='flex-col gap-y-6 h-95svh' onSubmit={handleSubmit(onValid)}>
        <SectionHeader
          title={t('materialManagement.title')}
          breadcrumb={<Breadcrumb crumbsReplace={[{ key: id, value: materialInfo.name }]} />}
        />
        <CardWrapper className='flex-col gap-y-6 p-8'>
          <div className='flex justify-between'>
            <div className='flex gap-x-4 items-center'>
              <LCAIcon
                lookAndFeel='material'
                icon={ICON_MAP[materialInfo.type]}
                size='xlarge'
                disabled={!materialInfo.impact_source_id}
              />
              <h2 className='font-20 weight-400 inter'>{materialInfo.name}</h2>
            </div>
            <div className='flex gap-x-4 items-center'>
              {Boolean(materialInfo.impact_source_id) && (
                <SuccessLabel>
                  <span className='whitespace-nowrap'>{tMaterialDetail('successfullyLinked')}</span>
                </SuccessLabel>
              )}

              {!materialInfo.impact_source_id && (
                <DisabledLabel icon='clock'>
                  <span className='whitespace-nowrap'>{tMaterialDetail('linkInProgress')}</span>
                </DisabledLabel>
              )}
              <TooltipWrapper
                asChild
                shouldAppear={materialInfo.used_in.length !== 0}
                text={tMaterialDetail('disabledDelete', { count: materialInfo.used_in.length })}>
                <Button
                  lookAndFeel='secondary'
                  text={tMaterialDetail('delete')}
                  size='medium'
                  loading={isDeleting}
                  disabled={materialInfo.used_in.length !== 0 || isDeleting || isEditing}
                  style={{ whiteSpace: 'nowrap' }}
                  onClick={onDelete}
                />
              </TooltipWrapper>

              <Button
                lookAndFeel='primary'
                text={tMaterialDetail('save')}
                size='medium'
                loading={isEditing}
                disabled={!formState.isDirty || isDeleting || isEditing}
                style={{ whiteSpace: 'nowrap' }}
                type='submit'
              />
            </div>
          </div>
          <div className='grid grid-cols-3 gap-2'>
            <GrayBox className='col-span-2'>
              <h3 className='m-0 font-14 weight-600 inter text-neutral-gray-cold-20'>
                {tMaterialDetail('generalInfo')}
              </h3>
              <div className='grid grid-cols-2 gap-x-6'>
                <InputWrapper
                  label={tMaterialDetail('name')}
                  iconV2='leather'
                  error={adaptHookFormError(formState.errors.name)}>
                  <InputText {...register('name')} />
                </InputWrapper>
                <InputWrapper
                  label={tMaterialDetail('supplier')}
                  iconV2='input_text'
                  error={adaptHookFormError(formState.errors.supplier)}>
                  <InputText {...register('supplier')} />
                </InputWrapper>
              </div>
              <div className='grid grid-cols-4 gap-x-6'>
                <InputWrapper.WithoutWrapperPlain
                  className='col-span-2'
                  label={tMaterialDetail('description')}
                  error={adaptHookFormError(formState.errors.description)}>
                  <InputTextArea.Base {...register('description')} />
                </InputWrapper.WithoutWrapperPlain>
                <InputWrapper
                  label={tMaterialDetail('country')}
                  iconV2='location'
                  error={adaptHookFormDropdownError(formState.errors.country)}>
                  <Select
                    options={countries}
                    value={watch('country')}
                    onChange={(newVal) => setValue('country', newVal, { shouldDirty: true })}
                  />
                </InputWrapper>
                <InputWrapper
                  label={tMaterialDetail('type')}
                  iconV2='dropdown'
                  error={adaptHookFormDropdownError(formState.errors.type)}>
                  <Select
                    options={options}
                    value={watch('type')}
                    onChange={(newVal) => setValue('type', newVal, { shouldDirty: true })}
                  />
                </InputWrapper>
              </div>
            </GrayBox>
            <GrayBox>
              <div className='flex gap-x-4 justify-between items-center'>
                <h3 className='m-0 font-14 weight-600 inter text-neutral-gray-cold-20'>
                  {tMaterialDetail('usedIn')}
                </h3>
                <Tooltip text={tMaterialDetail('usedInTooltip')} position='left' />
              </div>
              {materialInfo.used_in.length > 0 && (
                <ol className='flex-col gap-y-1 max-h-39 overflow-y-auto m-0 pl-6'>
                  {materialInfo.used_in.map((acv) => {
                    return (
                      <li key={acv.id} className='inter font-14 weight-400'>
                        <Link to={`/lca/portfolio/${acv.id}`} className='underline  cursor-pointer'>
                          {acv.name}
                        </Link>
                      </li>
                    );
                  })}
                </ol>
              )}

              {materialInfo.used_in.length === 0 && (
                <span className='font-14 weight-400 text-neutral-gray-20'>
                  {tMaterialDetail('noUsedIn')}
                </span>
              )}
            </GrayBox>
            <GrayBox className='col-span-3'>
              <h3 className='m-0 font-14 weight-600 inter text-neutral-gray-cold-20'>
                {tMaterialDetail('dbReference')}
              </h3>
              {!materialInfo.impact_source_id && (
                <span className='font-14 weight-400 text-neutral-gray-20'>
                  {tMaterialDetail('noReference')}
                </span>
              )}
              {Boolean(materialInfo.impact_source_id) && (
                <div className='grid grid-cols-4 gap-6'>
                  <InputWrapper
                    label={tMaterialDetail('reference')}
                    className='col-span-2'
                    disabled>
                    <InputText {...register('impact_source.reference')} disabled />
                  </InputWrapper>
                  <InputWrapper label={tMaterialDetail('db')} disabled>
                    <InputText {...register('impact_source.database')} disabled />
                  </InputWrapper>
                  <InputWrapper label={tMaterialDetail('countryRegion')} disabled>
                    <InputText {...register('impact_source.country')} disabled />
                  </InputWrapper>
                  {Boolean(materialInfo.tech_notes) && (
                    <div className='flex-col gap-y-2 col-span-3'>
                      <span className='font-10 weight-600 text-neutral-gray-cold-20'>
                        {tMaterialDetail('techNotes')}
                      </span>
                      <span className='text-left inter font-10 weight-400'>
                        {materialInfo.tech_notes}
                      </span>
                    </div>
                  )}
                  <InputWrapper label={tMaterialDetail('dbUnit')} disabled className='col-start-1'>
                    <InputText {...register('impact_source.unit')} disabled />
                  </InputWrapper>

                  <div className='flex-col gap-y-2 col-start-1'>
                    <div className='flex gap-x-2 items-center'>
                      <span className='font-10 weight-600 inter text-neutral-gray-cold-20'>
                        {tMaterialDetail('customUnits')}
                      </span>
                      <Tooltip text={tMaterialDetail('customUnitsTooltip')} />
                    </div>
                    <div className='flex gap-x-2 items-center'>
                      <Button
                        lookAndFeel='secondary'
                        text={tMaterialDetail('editOrAddUnits')}
                        size='medium'
                        onClick={() => setIsOpenUnitModal(true)}
                      />
                      {materialInfo.unit_list.some(
                        (unit) => unit.value === undefined || unit.value === null
                      ) && (
                        <WarningLabel className='whitespace-nowrap cursor-default'>
                          {tMaterialDetail('unitToMatch')}
                        </WarningLabel>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </GrayBox>
          </div>
        </CardWrapper>
      </form>
    </>
  );
};
