import { useTranslation } from 'react-i18next';
import useFilterOptions from './useFilterOptions';

export const useSideBarFilters = () => {
  const { t } = useTranslation();
  const filterOptions = useFilterOptions();

  const sideBarFilters = [
    {
      options: filterOptions.transportOptions,
      title: t('employees.transport'),
      field: 'transport_type_period'
    },
    {
      options: filterOptions.pendingResponse,
      title: t('employees.status'),
      field: 'status'
    },
    {
      options: filterOptions.statusOptions,
      title: t('employees.situation'),
      field: 'situation_period'
    },

    {
      options: filterOptions.responseWay,
      title: t('employees.responseWay'),
      field: 'response_medium_period'
    }
  ];

  return { sideBarFilters };
};
