import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceType } from '../../../../../../types/entities/invoice';
import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';
import SelectInvoiceType from './components/common/selectInvoiceType/SelectInvoiceType';
import './styles.scss';
import Treatment from './components/treatment/Treatment';
import Waste from './components/waste/Waste';
import { Facility } from '../../../../../../types/entities/facility';
import Heat from './components/heat/Heat';
import Recharge from './components/recharge/Recharge';
import Electricity from './components/electricity/Electricity';
import Water from './components/water/Water';
import useGetData from './hooks/useGetData';

type Props = {
  switchToAuto: () => void;
  facility: Facility;
  addInvoice: (invoice: InvoiceType | null) => void;
  defaultType?: string;
  onClose: () => void;
};

const today = new Date();
today.setHours(0, 0, 0, 0);
today.setDate(today.getDate());

const aMonthAgo = new Date();
aMonthAgo.setMonth(aMonthAgo.getMonth() - 1);
aMonthAgo.setHours(0, 0, 0, 0);

const CreateInvoice = ({ switchToAuto, addInvoice, defaultType, facility, onClose }: Props) => {
  const { t } = useTranslation();

  if (defaultType === 'wastes') {
    defaultType = 'waste';
  }
  const [type, setType] = useState<string>(
    defaultType
      ? defaultType
      : facility.categories && facility.categories.length === 1
      ? facility.categories[0]
      : ''
  );

  return (
    <div className='create-invoice'>
      <FormHeader title={t('facilityDetail.createNewInvoiceManual')} />
      <SelectInvoiceType
        mode={'manual'}
        action={'create'}
        onClickCard={(id: string) => {
          setType(id);
        }}
        type={type as string}
        facilityType={facility.facility_purpose_type}
        facilityCategories={facility.categories}
      />
      {type === 'heat' && (
        <Heat switchToAuto={switchToAuto} facility={facility} addInvoice={addInvoice} type={type} />
      )}
      {type === 'recharge' && <Recharge facility={facility} addInvoice={addInvoice} type={type} />}
      {type === 'electricity' && (
        <Electricity
          switchToAuto={switchToAuto}
          facility={facility}
          addInvoice={addInvoice}
          type={type}
        />
      )}
      {type === 'water' && (
        <Water
          switchToAuto={switchToAuto}
          facility={facility}
          addInvoice={addInvoice}
          type={type}
        />
      )}
      {type === 'waste' && <Waste addInvoice={addInvoice} facility={facility} />}
      {type === 'waste_water_treatment' && (
        <Treatment addInvoice={addInvoice} facilityId={facility.id} onClose={onClose} />
      )}
    </div>
  );
};

export default CreateInvoice;
