import { StringMap, TOptions } from 'i18next';

export const getCustomError = (errorMsg: string): [key: string, options?: TOptions<StringMap>] => {
  if (errorMsg.startsWith('min') && errorMsg.includes(':')) {
    const errorSplitted = errorMsg.split(':');

    return [errorSplitted[0], { count: Number(errorSplitted[1]) }];
  }

  return [errorMsg];
};
