import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setNotification } from 'actions/notification';
import { useNotification } from 'customHooks/translations/useNotification';
import { useDispatch } from 'react-redux';
import { deleteMaterial } from 'services/api/lca/materialManagement';

export const useDeleteLCAOfficialMaterial = () => {
  const dispatch = useDispatch();
  const { t } = useNotification();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: deleteMaterial,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lca-materials'] });
      dispatch(setNotification(t('lcaMaterialDelete.success')));
    },
    onError: () => {
      dispatch(setNotification(t('lcaMaterialDelete.error')));
    }
  });

  return { deleteMaterial: mutateAsync, isPending };
};
