import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';

import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import InputText from '../../../../ui/formComponents/inputText/InputText';
import Select from '../../../../ui/formComponents/select/Select';
import { FacilityForm } from '../EditCreateFacility';
import FormSelect from '../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import { getCountries } from '../../../../../constants/phonePrefixes';

type Props = {
  bodyForm: FacilityForm;
  setFormData: React.Dispatch<any>;
  typeOptions: SelectOptionFormat[];
  mode: 'create' | 'edit';
  typeId: string;
};

const BaseForm = ({ bodyForm, setFormData, typeOptions, mode, typeId }: Props) => {
  const { t, i18n } = useTranslation();
  const { onChangeValue } = useOnChangeValue({ setFormData });

  return (
    <>
      <FormWrapper>
        <InputText
          icon={'/images/icons/building.svg'}
          placeholder={t('facilities.writeFacilityName')}
          label={t('facilities.name')}
          value={bodyForm.name}
          onChangeValue={onChangeValue('name')}
          height='31px'
          fontClass='input-small-font'
          size='small'
          error={bodyForm?.errors?.find((elem) => elem.error === 'name')}
        />
        <Select
          icon={
            bodyForm.type.id === 'rent' ? '/images/icons/tag.svg' : '/images/icons/database.svg'
          }
          placeholder={t('facilities.writeFacilityType')}
          label={t('facilities.type')}
          value={bodyForm.type}
          onChangeValue={onChangeValue('type')}
          height='31px'
          fontClass='input-small-font'
          size='small'
          options={typeOptions}
          error={bodyForm?.errors?.find((elem) => elem.error === 'type')}
          sort={false}
        />
        {typeId !== 'waste_water_facilities' && (
          <FormSelect
            iconV2='earth'
            value={bodyForm.country}
            onChange={mode === 'create' ? onChangeValue('country') : () => null}
            label={t(`facilityDetail.country`)}
            placeholder={t(`facilityDetail.writeCountry`)}
            options={getCountries(i18n.resolvedLanguage, i18n.resolvedLanguage.toUpperCase())}
            disabled={mode !== 'create'}
            error={bodyForm.errors.find((error) => error.error === 'country')}
          />
        )}
      </FormWrapper>
    </>
  );
};

export default BaseForm;
