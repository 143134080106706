import { InvoiceType } from 'types/entities/invoice';
import formatNumber from 'utils/formatNumber';
import { parseQuantity, parseUnit } from './createInvoice/components/common/utils';
import Tooltip from 'components/ui/tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'react-i18next';

export const renderQuantity = (
  invoice: InvoiceType,
  currencyUnit: SelectOptionFormat | undefined,
  t: TFunction,
  showUnit = true
) => {
  let value = '-';
  if (invoice.status !== 'loading') {
    value = formatNumber(parseQuantity(invoice, currencyUnit));
  }

  return (
    <div className='icon-text-wrapper' style={{ justifyContent: 'right' }}>
      <span>{value}</span>
      {showUnit && (
        <>
          {invoice.unit ? (
            <span>{t(`units_short.${parseUnit(invoice, currencyUnit)}`)}</span>
          ) : (
            <></>
          )}
          {invoice.percentage < 1 && invoice.percentage > 0 && (
            <Tooltip
              text={t('facilityDetail.invoiceDivided', {
                percentage: (invoice.percentage * 100).toFixed(2),
                consumption: invoice.base_quantity,
                unit: invoice.unit ? t(`units_short.${parseUnit(invoice, currencyUnit)}`) : ''
              })}
            />
          )}
        </>
      )}
    </div>
  );
};
export const renderAltQuantity = (value: number, t: TFunction, unit?: string) => {
  return (
    <div className='icon-text-wrapper' style={{ justifyContent: 'right' }}>
      <span>{formatNumber(value)}</span>
      {unit && <span>{t(`units_short.${unit}`)}</span>}
    </div>
  );
};
