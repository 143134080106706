type Props = {
  children: React.ReactNode;
  title?: string;
};

export const BlueTag: React.FC<Props> = ({ children, title }) => {
  return (
    <span
      className='text-blue-tag bg-blue-tag font-12 weight-400 p-1 w-fit-content m-0 rounded-4'
      title={title}>
      {children}
    </span>
  );
};
