import { LCACard } from '../../../Portfolio/LCADetail/components/LCACard';

const ICON_MAP = {
  material: 'leather',
  energy: 'electricity'
} as const;

type Props = {
  type: keyof typeof ICON_MAP;
  lookAndFeel?: 'material' | 'process';
  disabled?: boolean;
};

export const MaterialIcon: React.FC<Props> = ({ type, disabled, lookAndFeel = 'process' }) => {
  if (lookAndFeel === 'material') {
    return <LCACard.Icon icon={ICON_MAP[type]} lookAndFeel='material' disabled={disabled} />;
  }

  return (
    <div className='relative'>
      <LCACard.Icon icon='factory' lookAndFeel='process' />
      <LCACard.Icon
        icon={ICON_MAP[type]}
        lookAndFeel='material'
        size='medium'
        style={{ position: 'absolute', top: 'calc(100% - 8px)', left: 'calc(100% - 8px)' }}
        disabled={disabled}
      />
    </div>
  );
};
