import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: 30_000,
      staleTime: 180_000
    }
  }
});
