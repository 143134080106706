import { useNavigate, useParams } from 'react-router-dom';
import './styles.scss';
import { useTranslation, Trans } from 'react-i18next';
import CardPanel from 'components/ui/cardPanel/CardPanel';
import logoLogicReturner from '../utils/logoLogicReturner';
import dateRenderer from '../../../utils/frameworkDateRenderer';
import { FrameworkPlan } from 'types/entities/organization';
import LoaderCard from 'components/ui/loaders/loaderCard/LoaderCard';
import useGetData from 'components/dashboard/controlPanel/hooks/useGetData';
import useFrameworksPlans from 'customHooks/api/useFrameworksPlans';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import { IconName } from 'types/utils/iconName';
import { generateQueryParamsFromObject, getUrl } from 'utils/url';
import { ROUTES } from 'constants/routes';
const CSRDDashboard = () => {
  const { frameworkName } = useParams();
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.frameworkProgress'
  });
  const xOrganizationId = localStorage.getItem('x-organization-id');
  const { frameworkPlans, frameworksLoading } = useFrameworksPlans(xOrganizationId || undefined);
  const { organizationFrameworkStatus } = useGetData();
  const getFrameworkPlanByName = (
    name: string | null | undefined,
    plans: FrameworkPlan[] | null | undefined
  ): FrameworkPlan | null => {
    if (!plans || !Array.isArray(plans) || !name) {
      return null; // Return null if plans is invalid or name is null/undefined
    }

    return (
      plans.find(
        (plan) => plan?.framework && plan.framework.name.toLowerCase() === name.toLowerCase()
      ) || null
    );
  };
  const frameworkEntry = organizationFrameworkStatus.data.find(
    (item) => item.framework.toLowerCase() === frameworkName?.toLowerCase()
  );
  const reverseDate = (date: string): string => {
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  };
  const frameworkPlan = getFrameworkPlanByName(frameworkName, frameworkPlans);
  const navigate = useNavigate();
  const buttonLinks = [
    {
      id: 'general',
      title: t('generalInfo'),
      icon: 'vat'
    },
    {
      id: 'environmental',
      title: t('environmental'),
      icon: 'earth'
    },
    {
      id: 'social',
      title: t('social'),
      icon: 'members'
    },
    {
      id: 'governance',
      title: t('governance'),
      icon: 'own_facility'
    }
  ];
  const buttonRenderer = (title: string, icon: IconName, id: string) => {
    return (
      <Button
        key={title}
        title={title}
        onClick={() =>
          navigate(
            getUrl(ROUTES.IMPROVE_DASHBOARD_TOTAL + `/csrd/${id}/`, {
              queryParams: generateQueryParamsFromObject({ mode: 'controlPanel' })
            })
          )
        }
        lookAndFeel='secondary'
        style={{ flexGrow: 1, maxWidth: '25%', justifyContent: 'flex-start' }}
        className='p-4'>
        <Icon
          icon={icon}
          color='gradient'
          size='large'
          style={icon === 'own_facility' ? { width: '30px' } : undefined}
        />{' '}
        <span className='font-14'>{title}</span>
      </Button>
    );
  };
  return (
    <div className='framework-dashboard__body'>
      {!frameworksLoading ? (
        <>
          <CardPanel className='p-6'>
            <div className='framework-scope__iconTitle flex gap-8 items-center'>
              <img
                src={logoLogicReturner(frameworkName || 'csrd', false)}
                alt='CSRD'
                className='framework-scope__icon'
                style={{ width: 'auto', height: '55px' }}
              />
              <div className='framework-scope__titleNumber flex items-center gap-8 justify-between'>
                <h4 className='co2-dashboard-font-small on-light-text-color'>
                  <Trans
                    i18nKey='controlPanel.frameworkProgress.csrdDashboardTitle'
                    components={{ italic: <i /> }}
                  />
                </h4>
                {frameworkPlan &&
                  dateRenderer(
                    reverseDate(frameworkPlan?.plan_end_date),
                    frameworkEntry?.submitted || false,
                    t,
                    'calendar'
                  )}
              </div>
            </div>
          </CardPanel>
          <CardPanel className='p-6'>
            <div className='flex gap-4'>
              {buttonLinks.map((item) =>
                buttonRenderer(item.title, item.icon as IconName, item.id)
              )}
            </div>
          </CardPanel>
        </>
      ) : (
        <LoaderCard style={{ gridColumn: '1 / -1' }} />
      )}
    </div>
  );
};

export default CSRDDashboard;
