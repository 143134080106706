import { createContext, useEffect, useState } from 'react';
import Modal from '../../../../ui/modal/Modal';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { materialFormSchema } from './materialFormSchema';
import { FirstStep } from './FirstStep';
import StepCarouselList from '../../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../../ui/stepCarouselList/useStepCarousel';
import { SecondStep } from './SecondStep';
import { useModalContext } from '../ModalHandler';
import { useConfigureMaterialForm } from '../../hooks/useConfigureMaterialForm';
import { FormCreateMaterial } from 'components/lca/MaterialManagement/components/FormCreateMaterial';
import { ModalBulkCreateMaterials } from 'components/lca/MaterialManagement/components/ModalBulkCreateMaterials';
import { MaterialSelection } from './MaterialSelection';
import { LCAFormMaterial } from './constants';

type MaterialModalContextType = {
  node_id: string | null;
  setNodeId: React.Dispatch<React.SetStateAction<string | null>>;
};

const MaterialModalContext = createContext<MaterialModalContextType | null>(null);

const Provider: React.FC = ({ children }) => {
  const [node_id, setNodeId] = useState<string | null>(null);

  return (
    <MaterialModalContext.Provider value={{ node_id, setNodeId }}>
      {children}
    </MaterialModalContext.Provider>
  );
};

type Props = {
  final_product: string;
};

const STEPS = [
  {
    id: 'name',
    text: 'lcaDetail.processFlow.materialModal.basicInfo'
  },
  {
    id: 'material',
    text: 'lcaDetail.processFlow.materialModal.material'
  }
] as const;

export const SelectMaterialModal = ({ final_product }: Props) => {
  const { modalData, setModalData } = useModalContext();

  const { form, setForm, validate, errors } = useConfigureMaterialForm(modalData?.target);
  const [isOpenCreateMaterial, setIsOpenCreateMaterial] = useState(false);
  const [isOpenCreateBulk, setIsOpenCreateBulk] = useState(false);
  const [isSelectMaterialOpen, setIsSelectMaterialOpen] = useState(false);

  const { t } = useLCATranslation();

  const { steps, stepSelected, handleNext, handleSelect } = useStepCarousel({
    stepsText: STEPS.map((step) => ({
      id: step.id,
      text: t(step.text)
    }))
  });

  useEffect(() => {
    handleSelect(STEPS[0].id);
  }, [modalData?.target]);

  const handleSelectStep = (id: string) => {
    const isValid = validate(form, materialFormSchema);

    if (!isValid) {
      return;
    }

    handleSelect(id);
  };

  if (isOpenCreateBulk) {
    return (
      <Modal.WithPortal
        show={isOpenCreateBulk}
        onClose={() => setIsOpenCreateBulk(false)}
        width='720px'>
        <ModalBulkCreateMaterials.Header />
        <ModalBulkCreateMaterials.Body onClose={() => setIsOpenCreateBulk(false)} />
      </Modal.WithPortal>
    );
  }

  if (isOpenCreateMaterial) {
    return (
      <Modal.WithPortal
        show={Boolean(modalData?.target) && modalData?.type === 'edit_material'}
        onClose={() => setModalData(undefined)}
        width='720px'>
        <FormCreateMaterial onClose={() => setIsOpenCreateMaterial(false)} />
      </Modal.WithPortal>
    );
  }

  if (isSelectMaterialOpen) {
    const handleSelectMaterials = (materials: LCAFormMaterial[]) => {
      setForm((prev) => ({
        ...prev,
        materials
      }));

      setIsSelectMaterialOpen(false);
    };

    const onCancel = () => {
      setIsSelectMaterialOpen(false);
    };

    return (
      <Modal.WithPortal
        show={isSelectMaterialOpen}
        onClose={() => setIsSelectMaterialOpen(false)}
        width='720px'>
        <Modal.Header
          title={t('lcaDetail.processFlow.materialModal.title')}
          description={t('lcaDetail.processFlow.materialModal.description', { final_product })}
        />
        <MaterialSelection
          materials={form.materials}
          setMaterials={handleSelectMaterials}
          onCancel={onCancel}
          openCreateMaterial={() => setIsOpenCreateMaterial(true)}
          openBulkCreateMaterial={() => setIsOpenCreateBulk(true)}
        />
      </Modal.WithPortal>
    );
  }

  return (
    <Modal.WithPortal
      show={Boolean(modalData?.target) && modalData?.type === 'edit_material'}
      onClose={() => setModalData(undefined)}
      width='720px'>
      <Modal.Header
        title={t('lcaDetail.processFlow.materialModal.title')}
        description={t('lcaDetail.processFlow.materialModal.description', { final_product })}
      />
      <StepCarouselList
        steps={steps}
        handleSelect={handleSelectStep}
        style={{ marginBottom: '1rem' }}
      />

      {stepSelected?.id === 'name' && (
        <FirstStep
          form={form}
          setForm={setForm}
          errors={errors}
          validate={validate}
          handleNext={handleNext}
        />
      )}
      {stepSelected?.id === 'material' && (
        <SecondStep
          form={form}
          setForm={setForm}
          errors={errors}
          validate={validate}
          openSelectMaterial={() => setIsSelectMaterialOpen(true)}
        />
      )}
    </Modal.WithPortal>
  );
};

SelectMaterialModal.Root = Provider;
