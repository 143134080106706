import { convertDateFrontToBack } from 'utils/convertDates';
import apiFetch from '../../utils/apiFetch';

export const downloadByCategory = async (
  category: string,
  output_format: string,
  start_date?: string,
  end_date?: string
) => {
  try {
    const params: {
      output_format: string;
      start_date?: string;
      end_date?: string;
    } = {
      output_format
    };
    if (start_date) {
      params.start_date = convertDateFrontToBack(start_date);
    }
    if (end_date) {
      params.end_date = convertDateFrontToBack(end_date);
    }
    const response = await apiFetch('GET', `/download/${category}`, null, null, params);
    return response.data;
  } catch (error) {
    return null;
  }
};
