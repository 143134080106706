import Checkbox from 'components/ui/formComponents2/checkbox/Checkbox';

type Props = {
  title: string;
  subtitle: string;
  blocked?: boolean;
  logo: string;
  onChange: (value: boolean) => void;
  checked: boolean;
};
const FrameworkCheckbox = ({ title, subtitle, logo, blocked, onChange, checked }: Props) => {
  return (
    <Checkbox
      text={
        <div className='flex gap-x-2'>
          <div
            className='flex justify-center items-center'
            style={{ maxHeight: '3rem', maxWidth: '3rem' }}>
            <img src={logo} alt={title} style={{ height: 'auto', width: '100%' }} />
          </div>
          <div
            className='flex-col items-start gap-2 h-full justify-center'
            style={{ maxWidth: '190px' }}>
            <div className={'font-14 weight-700'}>{title}</div>
            <div className={'font-12 weight-400 disabled-text-color'}>{subtitle}</div>
          </div>
        </div>
      }
      onChange={onChange}
      selected={checked}
      type='square'
      disabled={blocked}
    />
  );
};

export default FrameworkCheckbox;
