import { useTranslation } from 'react-i18next';
import { User } from '../../../../../../types/entities/esgMetrics';
import NameIcon from '../../../../../ui/nameIcon/NameIcon';
const useColumns = (isGeneralInfo = false) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.metrics'
  });
  const columns = [
    {
      title: t(isGeneralInfo ? 'metric' : 'primaryData'),
      dataIndex: 'name',
      key: 'name'
    },
    // {
    //   title: t('category'),
    //   dataIndex: 'category',
    //   key: 'category'
    // },
    {
      title: t('responsible'),
      dataIndex: 'assigned_to',
      key: 'assigned_to',
      render: (user: User) => {
        return (
          <span className='flex gap-x-2 items-center'>
            <NameIcon name={user?.first_name ?? '-'} lastName={user?.last_name} />
            {user?.id ? `${user?.first_name} ${user?.last_name ?? ''}` : t('unassigned')}
          </span>
        );
      }
    },
    {
      title: t('frameworks'),
      dataIndex: 'frameworks',
      key: 'frameworks'
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];
  return columns;
};

export default useColumns;
