import { ChangeEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';
import { NotificationContext } from '../../../../context/notificationContext';
import { UserContext } from '../../../../context/userContext';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';
import { IGetBackendBusinessTravel } from '../../../../types/entities/businessTravels';
import { CarSize, TransportOptions } from '../../../../types/entitiesEnums/employeePeriod';
import { Status } from '../../../../types/utilsEnums/status';
import { formatDate } from '../../../../utils/formatDate';
import { limitString } from '../../../../utils/limitString';
import { generateQueryParamsFromObject, getUrl } from '../../../../utils/url';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import Button from '../../../ui/button/Button';
import ButtonDropdown from '../../../ui/buttonDropdown/ButtonDropdown';
import InfiniteList from '../../../ui/infiniteList/InfiniteListV2';
import Modal from '../../../ui/modal/Modal';
import ErrorLabel from '../../../ui/statusLabels/errorLabel/ErrorLabel';
import PendingLabel from '../../../ui/statusLabels/pendingLabel/PendingLabel';
import SuccessLabel from '../../../ui/statusLabels/successLabel/SuccessLabel';
import { CategoriesUploadedFiles } from '../../uploadedFiles/constants';
import AddBusinessTravelWrapper from './addBusinessTravel/AddBusinessTravelWrapper';
import AddBusinessTravelWrapperBeforeNuvo from './addBusinessTravel/AddBusinessTravelWrapperBeforeNuvo';
import InputNuvo from './components/inputNuvo/InputNuvo';
import DeleteBusinessTravel from './deleteBusinessTravel/DeleteBusinessTravel';
import EditBusinessTravel from './editBusinessTravel/EditBusinessTravel';
import './styles.scss';
import TooltipWrapper from '../../../ui/tooltip/TooltipWrapper';
import formatNumber from '../../../../utils/formatNumber';
import { numberToDecimalNonZero } from '../../../../utils/numberToDecimal';
import DownloadCategoryModal from 'components/downloadCategoryModal/DownloadCategoryModal';
import MultiSelect from 'components/ui/formComponents/multiSelect/MultiSelect';
import InputCalendar from 'components/ui/formComponents/inputCalendar/InputCalendar';
import useColumns from './hooks/useColumns';
import InputTextDebounce from 'components/ui/formComponents/inputTextDebounce/InputTextDebounce';
import { useGetData } from './hooks/useGetData';
import { convertDateFrontToBackTimestamp } from 'utils/convertDates';
import TotalLegendV2 from 'components/ui/totalLegend/TotalLegendV2';
import Icon from 'components/ui/icon/Icon';

function BusinessTravels() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const user = useContext(UserContext);
  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );

  const setNotification = useContext(NotificationContext);

  const dispatch = useDispatch();
  const flags = useFeatureFlags();

  const [businessTravelToEdit, setBusinessTravelToEdit] =
    useState<IGetBackendBusinessTravel | null>();
  const [businessTravelToDelete, setBusinessTravelToDelete] = useState('');
  const [showAddBusinessTravel, setShowAddBusinessTravel] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const [inputText, setInputText] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [otherFilters, setOtherFilters] = useState<SelectOptionFormat[]>([]);

  const {
    data: businessTravels,
    fetchData,
    loading,
    firstLoading,
    total,
    removeElement,
    addElement,
    editElement
  } = useGetData({
    description: inputText,
    start_date: startDate ? +convertDateFrontToBackTimestamp(startDate) : undefined,
    end_date: endDate ? +convertDateFrontToBackTimestamp(endDate) : undefined,
    filters: otherFilters.map((elem) => elem.id).join(',')
  });

  const renderDropdownComponent = (businessTravel: IGetBackendBusinessTravel) => {
    const options = [
      {
        id: `${businessTravel.id}-delete`,
        name: t('businessTravels.delete'),
        onClick: () => {
          setBusinessTravelToDelete(businessTravel.id);
        }
      }
    ];

    if (businessTravel.transport_type) {
      options.push({
        id: `${businessTravel.id}-edit`,
        name: t('businessTravels.edit'),
        onClick: () => {
          setBusinessTravelToEdit(businessTravel);
        }
      });
    }

    if (businessTravel.file_name) {
      options.push({
        id: `${businessTravel.id}-goToFile`,
        name: t('businessTravels.goToFile'),
        onClick: () => {
          navigate(
            getUrl(`${ROUTES.MEASURE_UPLOADED_FILES}/${CategoriesUploadedFiles.BUSINESS_TRAVELS}`, {
              queryParams: generateQueryParamsFromObject({
                name: businessTravel.file_name ?? ''
              })
            })
          );
        }
      });
    }
    return <ButtonDropdown options={options} />;
  };

  const renderNameComponent = (name: string) => {
    let nameParsed = name;
    if (nameParsed === 'file_processing') {
      nameParsed = t(`businessTravels.${nameParsed}`);
    }
    return (
      <div className='icon-text-wrapper'>
        <img src='/images/icons/planeGray.svg' alt='aircraft' />
        <span>{nameParsed ? limitString(nameParsed, 15) : '-'}</span>
      </div>
    );
  };

  const renderBusinessTravelType = (businessTravelType?: string) => {
    if (!businessTravelType) return '-';
    return businessTravelType === 'one_way'
      ? t('businessTravels.oneWay')
      : t(`businessTravels.${businessTravelType}`);
  };

  const onCloseModal = () => {
    setShowAddBusinessTravel(false);
    setBusinessTravelToEdit(null);
    setBusinessTravelToDelete('');
  };

  const handleShowAddBusinessTravelModal = () => {
    setShowAddBusinessTravel(true);
  };

  const addBusinessTravel = (businessTravel: IGetBackendBusinessTravel) => {
    addElement(businessTravel);
    onCloseModal();
    dispatch(setNotification(t('notification.createBusinessTravel')));
  };

  const editBusinessTravel = (value: IGetBackendBusinessTravel) => {
    editElement(value);
    dispatch(setNotification(t('notification.editBusinessTravel')));

    setBusinessTravelToEdit(null);
  };

  const removeBusinessTravel = (id: string) => {
    removeElement(id);
    dispatch(setNotification(t('notification.deleteBusinessTravel')));
    setBusinessTravelToDelete('');
  };

  const columns = useColumns();

  const onChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e?.target?.value);
  };
  const handleChangeStartDate = (date: string) => {
    setStartDate(date);
  };
  const handleChangeEndDate = (date: string) => {
    setEndDate(date);
  };

  const resetStartDate = () => {
    setStartDate('');
  };
  const resetEndDate = () => {
    setEndDate('');
  };

  const onChangeFilters = (value: SelectOptionFormat) => {
    const filtersOld = [...otherFilters];
    filtersOld.push(value);
    setOtherFilters(filtersOld);
  };
  const onRemoveFilters = (id: string) => {
    setOtherFilters(otherFilters.filter((element) => element?.id !== id));
  };

  const renderTransportType = (value: IGetBackendBusinessTravel) => {
    let transportType = value.transport_type;
    if (value.transport_type === TransportOptions.CAR && value.vehicle_size === CarSize.SMALL) {
      transportType = TransportOptions.CAR_SMALL;
    } else if (
      value.transport_type === TransportOptions.CAR &&
      value.vehicle_size === CarSize.MEDIUM
    ) {
      transportType = TransportOptions.CAR_MEDIUM;
    } else if (
      value.transport_type === TransportOptions.CAR &&
      value.vehicle_size === CarSize.LARGE
    ) {
      transportType = TransportOptions.CAR_LARGE;
    }
    return t(`businessTravels.${transportType}`);
  };

  const renderStatusTag = (status: string) => {
    switch (status) {
      case Status.ACTIVE:
        return <SuccessLabel key='active'>{t('general.completed')}</SuccessLabel>;
      case Status.LOADING:
        return <PendingLabel>{t('general.processing')}</PendingLabel>;
      case Status.ERROR:
        return <ErrorLabel>{t('general.withErrors')}</ErrorLabel>;
      default:
        return <></>;
    }
  };

  const parseData = (businessTravels: IGetBackendBusinessTravel[]) => {
    const data = businessTravels.map((businessTravel) => {
      return {
        ...businessTravel,
        email: businessTravel.email ? businessTravel.email : '-',
        name: renderNameComponent(businessTravel.name ?? '-'),
        origin: businessTravel.origin ? limitString(businessTravel.origin, 15) : '-',
        destination: businessTravel.destination ? limitString(businessTravel.destination, 15) : '-',
        transport_type: businessTravel.transport_type ? renderTransportType(businessTravel) : '-',
        travel_type: renderBusinessTravelType(businessTravel.travel_type),
        distance_km: businessTravel.distance_km ? businessTravel.distance_km : '-',
        travel_number: businessTravel.travel_number ? businessTravel.travel_number : '-',
        date:
          businessTravel.start_date && businessTravel.end_date
            ? `${formatDate(new Date(businessTravel.start_date))}-${formatDate(
                new Date(businessTravel.end_date)
              )}`
            : '-',
        status: !businessTravel.transport_type ? (
          <PendingLabel>{t('general.loading')}</PendingLabel>
        ) : (
          renderStatusTag(businessTravel.status)
        ),
        edit: renderDropdownComponent(businessTravel),
        co2e:
          businessTravel.status === 'loading' && businessTravel.co2e === undefined ? (
            <TooltipWrapper
              text={t('facilityDetail.co2eTooltip')}
              style={{ width: '100%', textAlign: 'right' }}>
              <span
                className='highlight-text-color'
                style={{ fontWeight: 600, textAlign: 'right' }}>
                -
              </span>
            </TooltipWrapper>
          ) : (
            <span
              className='highlight-text-color'
              style={{ fontWeight: 600, textAlign: 'right', display: 'block' }}>
              {formatNumber(numberToDecimalNonZero(businessTravel.co2e ?? 0))} kg
            </span>
          ),

        disabled:
          (businessTravel.email && !businessTravel.origin) || businessTravel.status === 'loading'
            ? true
            : false
      };
    });
    return data;
  };

  const NuvoBTN =
    foundOrganization && total >= foundOrganization.limit_business_travels ? (
      <Button lookAndFeel='blocked' text={t('businessTravels.uploadAuto')} size='small' />
    ) : (
      <InputNuvo />
    );

  return (
    <section className='business-travels'>
      <div className='business-travels__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('businessTravels.title')}</h3>
        <Breadcrumb />
      </div>
      <div className='business-travels__body main-bg-color solid-border '>
        <div className='business-travels-card main-bg-color solid-border '>
          <h1 className='headline4-font'>{t('businessTravels.start')}</h1>
          <p className='subtitle3-font'>{t('businessTravels.startDescription')}</p>
          {businessTravels && (
            <div className='buttons'>
              {NuvoBTN}
              <Button
                lookAndFeel={
                  foundOrganization && total >= foundOrganization.limit_business_travels
                    ? 'blocked'
                    : 'secondary'
                }
                text={t('businessTravels.addManual')}
                size='small'
                onClick={handleShowAddBusinessTravelModal}
              />
            </div>
          )}
        </div>
      </div>
      <InfiniteList
        data={parseData(businessTravels)}
        fetchData={fetchData}
        columns={columns}
        firstLoading={firstLoading}
        loading={loading}
        total={total}
        header={
          <>
            <div className='flex justify-between items-center' style={{ width: '100%' }}>
              <div className='flex gap-2 items-center'>
                <InputTextDebounce
                  icon={'/images/icons/search.svg'}
                  placeholder={t(`businessTravels.inputFilter`)}
                  onChangeValue={onChangeSearchValue}
                  height='24px'
                  fontClass='input-smaller-font'
                  size='small'
                />
                <InputCalendar
                  mode='range'
                  height='24px'
                  fontClass='input-smaller-font'
                  size='small'
                  predefinedDates
                  handleChangeStartDate={handleChangeStartDate}
                  handleChangeEndDate={handleChangeEndDate}
                  startDateValue={startDate}
                  endDateValue={endDate}
                  resetStartDate={resetStartDate}
                  resetEndDate={resetEndDate}
                />
                <MultiSelect
                  icon='/images/icons/filter.svg'
                  placeholder={t(`businessTravels.filters`)}
                  value={{ id: '', name: '' }}
                  onChangeValue={onChangeFilters}
                  onRemoveValue={onRemoveFilters}
                  options={[
                    { id: 'car', name: t('businessTravels.car') },
                    { id: 'train', name: t('businessTravels.train') },
                    { id: 'trolleybus', name: t('businessTravels.trolleybus') },
                    { id: 'bus', name: t('businessTravels.bus') },
                    { id: 'aircraft', name: t('businessTravels.aircraft') },
                    { id: 'motorbike', name: t('businessTravels.motorbike') }
                  ]}
                  height='24px'
                  size='small'
                  fontClass='input-smaller-font'
                  selectedOptions={otherFilters}
                />
              </div>
              <div className='flex gap-2 items-center'>
                <TooltipWrapper text={t('general.downloadButtonEmailTooltip')}>
                  <Button
                    lookAndFeel={'primary'}
                    iconNode={<Icon icon='download' color='white' />}
                    text={t('general.download')}
                    size='small'
                    height='small'
                    onClick={() => setShowDownloadModal(true)}
                  />
                </TooltipWrapper>
                <TotalLegendV2
                  totalLabel={t('general.total')}
                  totalElements={[
                    {
                      label: t('businessTravels.total'),
                      value: total
                    }
                  ]}
                />
              </div>
            </div>
          </>
        }
      />
      <Modal show={showAddBusinessTravel} onClose={onCloseModal} width='600px' maxWidth='600px'>
        {flags?.nuvo ? (
          <AddBusinessTravelWrapper addBusinessTravel={addBusinessTravel} />
        ) : (
          <AddBusinessTravelWrapperBeforeNuvo addBusinessTravel={addBusinessTravel} />
        )}
      </Modal>
      <Modal show={!!businessTravelToEdit} onClose={onCloseModal} width='600px' maxWidth='600px'>
        {businessTravelToEdit && (
          <EditBusinessTravel
            businessTravelToEdit={businessTravelToEdit}
            editBusinessTravel={editBusinessTravel}
          />
        )}
      </Modal>
      <Modal show={!!businessTravelToDelete} onClose={onCloseModal} width='428px' maxWidth='428px'>
        <DeleteBusinessTravel
          removeBusinessTravel={removeBusinessTravel}
          businessTravelToDelete={businessTravelToDelete}
        />
      </Modal>
      <Modal show={showDownloadModal} onClose={() => setShowDownloadModal(false)} width='500px'>
        <DownloadCategoryModal
          category={'business_travel'}
          onClose={() => setShowDownloadModal(false)}
        />
      </Modal>
    </section>
  );
}

export default BusinessTravels;
