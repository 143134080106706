import { IconSize } from '../components/ui/icon/Icon';

/* TODO: update view box for small and xlarge cases */
export const getViewBox = (size: IconSize) => {
  switch (size) {
    case 'small':
      return '0 0 16 16';
    case 'medium':
      return '0 0 16 16';
    case 'large':
      return '2 2 12 12';
    case 'xlarge':
      return '1 1 14 14';
    default:
      return '0 0 16 16';
  }
};
