import { ReactNode } from 'react';
import AccordionItem, { AccordionItemType } from './AccordionItem';
import './styles.scss';

const Content: React.FC = ({ children }) => (
  <div className='max-h-79 overflow-y-auto'>{children}</div>
);

type AccordionType = {
  content?: AccordionItemType[];
  title?: string;
  children?: ReactNode;
};

const Accordion = ({ title, children }: AccordionType) => {
  return (
    <div className='accordion'>
      {title && <h3>{title}</h3>}
      {children}
    </div>
  );
};
Accordion.Content = Content;
Accordion.Item = AccordionItem;

export default Accordion;
