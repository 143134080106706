import debounce from 'lodash.debounce';
import { ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorDate } from '../components/ui/formComponents2/inputCalendar/InputCalendar';

export type AllowedTypes =
  | ChangeEvent<HTMLInputElement>
  | ChangeEvent<HTMLTextAreaElement>
  | SelectOptionFormat
  // | Date
  | string
  | string[]
  | boolean
  | PhoneNumber
  | File
  | number[]
  | number
  | undefined
  | null
  | any
  | ErrorType[];

interface FormDataType {
  [key: string]: AllowedTypes;
  errors: ErrorType[];
}

export interface SetFormDataType<T> {
  setFormData: Dispatch<SetStateAction<T>>;
}

function useOnChangeValue<T extends FormDataType>({ setFormData }: SetFormDataType<T>) {
  const { t } = useTranslation();

  const onChangeValue = function (attribute: Exclude<keyof T, 'errors'>) {
    return function changeValue(arg: AllowedTypes) {
      setFormData((prev: T) => ({
        ...prev,
        [attribute]: (arg as ChangeEvent<HTMLInputElement>)?.target?.value ?? arg,
        errors: (prev.errors as ErrorType[]).filter((error) => error.error !== attribute)
      }));
    };
  };

  const handleDateError = (error: string) => (value: ErrorDate) => {
    setFormData((prev: T) => {
      const newErrors = prev.errors as ErrorType[];

      const descriptions = {
        [ErrorDate.FORMAT]: t('error.invalidDate'),
        [ErrorDate.MAX_DATE]: t('error.maxDate'),
        [ErrorDate.MIN_DATE]: t('error.minDate')
      };

      const newError: ErrorType = {
        error,
        description: descriptions[value]
      };

      return {
        ...prev,
        errors: newErrors.filter((err) => err.error !== error).concat(newError)
      };
    });
  };
  const handleNegativeError = (error: string) => (value: boolean) => {
    setFormData((prev: T) => {
      const newErrors = prev.errors as ErrorType[];

      const newError: ErrorType = {
        error,
        description: t('error.minValue', { minValue: 0 })
      };

      return {
        ...prev,
        errors: value ? newErrors.filter((err) => err.error !== error) : newErrors.concat(newError)
      };
    });
  };
  const debounceOnChangeValue = useMemo(
    () =>
      (name: string, category: Exclude<keyof T, 'errors'>, delay = 300) =>
        debounce(() => {
          onChangeValue(category)(name);
        }, delay),
    []
  );

  return {
    onChangeValue,
    debounceOnChangeValue,
    handleDateError
  };
}

export default useOnChangeValue;
