import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ESG_ROUTES, ROUTES } from '../../../constants/routes';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { checkFlags } from '../../../utils/flags';
import useSections from './hooks/useSections';
import MainSection from './mainSection/MainSection';
import Section from './section/Section';
import './styles.scss';
import useToggleOptions from './hooks/useToggleOptions';
import { ESGModeContext } from '../../../context/esgModeContext';
import SlideToggleFacade from '../../ui/formComponents/slideToggle3/SlideToggleFacade';
import Icon from '../../ui/icon/Icon';
import { generateQueryParamsFromObject, getUrl } from 'utils/url';

function SideMenu() {
  const { i18n } = useTranslation('');
  const flags = useFeatureFlags();
  const { t } = useTranslation();

  const location = useLocation();

  const [subSections, setSubSections] = useState<SubSection[]>([]);
  const [sectionSelected, setSectionSelected] = useState('');
  const [showSubSections, setShowSubSections] = useState(false);

  const foundOrganization = useSelectedOrganization();

  const navigate = useNavigate();

  const toggleOptions = useToggleOptions();

  const { mode, setMode } = useContext(ESGModeContext);

  const { sections, sectionsBottom } = useSections({ flags });

  useEffect(() => {
    const pathname = location.pathname;
    let section = sections.find((section) => pathname.split('/')[1] === section.id);
    if (!section) {
      section = sectionsBottom.find((section) => pathname.split('/')[1] === section.id);
    }
    if (section) {
      setSectionSelected(section.id);
      if (section.subSections) {
        setSubSections(section.subSections);
      }
    } else {
      setSectionSelected('');
      setSubSections([]);
    }
    if (mode !== 'environmental' && section?.id === 'dashboard') {
      setShowSubSections(false);
      setSubSections([]);
    } else {
      setShowSubSections(true);
      // setSubSections(sections[0].subSections);
    }
  }, [location.pathname, i18n.resolvedLanguage, flags, mode]);

  const navigateToDashboard = () => {
    navigate(ROUTES.IMPROVE_DASHBOARD);
  };

  const onClickMainSection = (id: string) => {
    setSectionSelected(id);
    setShowSubSections(true);

    let section = sections.find((section) => section.id === id);
    if (!section) {
      section = sectionsBottom.find((section) => section.id === id);
    }
    // set sub sections
    if (section?.subSections) {
      setSubSections(section.subSections);
    } else {
      setSubSections([]);
    }
  };

  return (
    <>
      <aside className='side-menu menu-bg-color'>
        <img
          className='logo'
          src={foundOrganization?.logo_url ?? '/images/santa_logo.png'}
          alt='logo-menu'
          onClick={navigateToDashboard}
        />
        <div className='side-menu__section-wrapper'>
          <div className='side-menu__section-wrapper__top' id='menu'>
            {sections.map((section) =>
              checkFlags(section.id, flags) && !section.hide ? (
                <MainSection
                  key={section.text}
                  id={section.id}
                  idSelected={sectionSelected}
                  text={section.text}
                  icon={section.icon}
                  iconSelected={section.iconSelected}
                  route={section.route}
                  isNew={section.isNew}
                  onClickMainSection={onClickMainSection}
                />
              ) : null
            )}
          </div>
          <div className='side-menu__section-wrapper__bottom'>
            {sectionsBottom.map((section) =>
              checkFlags(section.id, flags) && !section.hide ? (
                <MainSection
                  key={section.text}
                  id={section.id}
                  idSelected={sectionSelected}
                  text={section.text}
                  icon={section.icon}
                  iconSelected={section.iconSelected}
                  route={section.route}
                  isNew={section.isNew}
                  onClickMainSection={onClickMainSection}
                />
              ) : null
            )}
          </div>
        </div>
        {subSections.length > 0 &&
          subSections.some((subSection) => checkFlags(subSection.id, flags)) && (
            <div
              className='side-menu-close solid-border'
              onClick={() => setShowSubSections(!showSubSections)}
              style={{
                right: showSubSections ? '-146px' : '-16px'
              }}>
              <Icon
                icon='double_chevron'
                color='gray-dark'
                style={{ transform: showSubSections ? 'rotate(0deg)' : 'rotate(180deg)' }}
              />
            </div>
          )}
      </aside>
      <div className='flex flex-col gap-2' style={{ paddingTop: '93px', position: 'relative' }}>
        {ESG_ROUTES.find((elem) => {
          return location.pathname.includes(elem);
        }) && (
          <div style={{ whiteSpace: 'nowrap', position: 'absolute', left: '8px', zIndex: 10 }}>
            <SlideToggleFacade
              handleSwitchMode={(value) => {
                setMode(value);
                // Set query param of route to value
                navigate(
                  getUrl(ROUTES.IMPROVE_DASHBOARD_TOTAL, {
                    queryParams: generateQueryParamsFromObject({ mode: value })
                  })
                );
              }}
              idSelected={mode}
              options={toggleOptions}
            />
          </div>
        )}
        {subSections.length > 0 &&
          subSections.some((subSection) => checkFlags(subSection.id, flags)) && (
            <div
              className='side-menu__sub-section-wrapper'
              style={{ paddingTop: '30px', display: showSubSections ? 'block' : 'none' }}>
              {subSections.map((subSection) =>
                checkFlags(subSection.id, flags) && !subSection.hide ? (
                  subSection?.isNew ? (
                    <div className='section-name' key={subSection.id}>
                      <Section
                        section={subSection}
                        key={subSection.id}
                        subscriptionPlan={foundOrganization?.subscription_plan || 'free_plan'}
                      />
                      <span className='tag-new span-small-font tag2-bg-text-color'>
                        {t('sideMenu.new')}
                      </span>
                    </div>
                  ) : (
                    <Section
                      section={subSection}
                      key={subSection.id}
                      subscriptionPlan={foundOrganization?.subscription_plan || 'free_plan'}
                    />
                  )
                ) : null
              )}
            </div>
          )}
      </div>
    </>
  );
}

export default SideMenu;
