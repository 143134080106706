import { useInfiniteQuery } from '@tanstack/react-query';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { getLCAMaterials } from 'services/api/lca/lcaPortfolio';
import type { IFilter, ISorter } from 'utils/url';

type Params = {
  filters?: IFilter[];
  sorters?: ISorter[];
  size?: number;
};
export const useLCAMaterials = ({ filters, sorters, size }: Params) => {
  const org = useSelectedOrganization();

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ['lca-materials', org?.id, JSON.stringify(filters), JSON.stringify(sorters), size],
    queryFn: ({ pageParam }) => getLCAMaterials({ page: pageParam, filters, sorters, size }),
    getNextPageParam: (lastPage) => {
      if (lastPage.page * (size ?? 10) >= lastPage.total) {
        return undefined;
      }

      return lastPage.page + 1;
    },
    initialPageParam: 1
  });

  return {
    data: data?.pages.reduce((acc, page) => {
      return [...acc, ...page.items];
    }, [] as LCAMaterial[]),
    total: data?.pages[0]?.total ?? 0,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  };
};
