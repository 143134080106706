import { localStorageGetItem } from './localStorage';

const formatNumber = (value?: number): string => {
  // Language map for locales
  const languageMapper: { [key: string]: string } = {
    es: 'es-ES', // Spanish locale
    en: 'en-US' // English locale
  };

  // Get locale from localStorage or default to Spanish
  const locale = localStorageGetItem('locale') || 'es';
  const language = languageMapper[locale] || 'es-ES';

  // Default value if `value` is undefined or null
  const number = value ?? 0;

  if (language === 'es-ES') {
    return number
      .toFixed(2)
      .replace('.', language === 'es-ES' ? ',' : '.')
      .replace(/\B(?=(\d{3})+(?!\d))/g, language === 'es-ES' ? '.' : ',');
  }

  // Use `Intl.NumberFormat` for proper formatting
  const formatter = new Intl.NumberFormat(language);

  return formatter.format(number);
};
export default formatNumber;

export const formatNumberToNdecimal = (value?: number, decimals = 2): string => {
  const languageMapper: { [key: string]: string } = {
    es: 'es-ES', // Spanish locale
    en: 'en-US' // English locale
  };

  // Get locale from localStorage or default to 'es'
  const locale = localStorageGetItem('locale') || 'es';
  const language = languageMapper[locale] || 'es-ES';

  // Default value if `value` is undefined or null
  const number = value ?? 0;

  if (language === 'es-ES') {
    return number
      .toFixed(decimals)
      .replace('.', language === 'es-ES' ? ',' : '.')
      .replace(/\B(?=(\d{3})+(?!\d))/g, language === 'es-ES' ? '.' : ',');
  }

  // Use `Intl.NumberFormat` for proper formatting
  return new Intl.NumberFormat(language, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(number);
};
