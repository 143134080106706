import { useQuery } from '@tanstack/react-query';
import { getLCAOfficialMaterial } from 'services/api/lca/materialManagement';

export const useLCAOfficialMaterial = (id?: string) => {
  const { data, isLoading } = useQuery({
    queryKey: ['lca-official-material', id],
    queryFn: () => {
      if (!id) return;

      return getLCAOfficialMaterial(id);
    },
    select: (data) => {
      if (!data) return data;

      return {
        ...data,
        used_in: data.used_in ?? [],
        impact_source: {
          country: data.impact_source?.country ?? '',
          database: data.impact_source?.database ?? '',
          reference: data.impact_source?.reference ?? '',
          unit: data.impact_source?.unit ?? '',
          unit_id: data.impact_source?.unit_id ?? ''
        }
      };
    },
    enabled: Boolean(id)
  });

  return { data, isLoading };
};
