import { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { BOOK_MEETING_URL } from '../../../constants';
import { SMALL_SCREEN } from '../../../constants/screenSizes';
import { FilterDatesContext } from '../../../context/filterDatesContext';
import { FreeTrialProvider } from '../../../context/freeTrialContext';
import { UserContext } from '../../../context/userContext';
import useScreenSize from '../../../customHooks/useScreenSize';

import { usePremiumRoutes } from '../../../customHooks/usePremiumRoutes';
import { checkExpiredPlan, checkFreePlan } from '../../../utils/checkFreePlan';
import { formatDate } from '../../../utils/formatDate';
import { localStorageGetItem, localStorageSetItem } from '../../../utils/localStorage';
import GHGReportDataForm from '../../communicate/ghgReport/GHGReportDataForm/GHGReportDataForm';
import Iso14064Report from '../../communicate/iso14064Report/Iso14064Report';
import PaiReportDataForm from '../../communicate/paiReport/paiReportDataForm/PaiReportDataForm';
import DcycleReportDataForm from '../../communicate/report/dcycleReportDataForm/DcycleReportDataForm';
import SnippetReportDataForm from '../../communicate/snippet/snippetReportDataForm/SnippetReportDataForm';
import FreePlanReminder from '../../freeTrials/freePlanReminder/FreePlanReminder';
import HubspotCalendar from '../../freeTrials/hubspotCalendar/HubspotCalendar';
import PremiumFeatureAdvise from '../../freeTrials/premiumFeatureAdvise/PremiumFeatureAdvise';
import SubscriptionReminder from '../../freeTrials/subscriptionReminder/SubscriptionReminder';
import MenuBurger from '../../ui/menuBurger/MenuBurger';
import Modal from '../../ui/modal/Modal';
import SideMenu from '../SideMenu/SideMenu';
import Header from '../header/Header';
import PoweredByDcycle from '../poweredByDcycle/PoweredByDcycle';
import './styles.scss';
import { ESGModeContext } from '../../../context/esgModeContext';
import CSRDModal from './components/CSRDModal';

type Props = {
  children: ReactNode;
  showFilterDates?: boolean;
};

function MainLayout({ children, showFilterDates = false }: Props) {
  const location = useLocation();
  const { width } = useScreenSize();
  const user = useContext(UserContext);

  const PREMIUM_ROUTES = usePremiumRoutes();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate());
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  oneYearAgo.setMonth(0);
  oneYearAgo.setDate(1);
  oneYearAgo.setHours(0, 0, 0, 0);

  const startDateLocalStorage = localStorageGetItem('start_date_dashboard');
  const endDateLocalStorage = localStorageGetItem('end_date_dashboard');

  const [startDate, setStartDate] = useState<string>(
    startDateLocalStorage ? startDateLocalStorage : formatDate(oneYearAgo)
  );
  const [endDate, setEndDate] = useState<string>(
    endDateLocalStorage ? endDateLocalStorage : formatDate(today)
  );
  const [isOpen, setIsOpen] = useState(false);

  const [dateError, setDateError] = useState<ErrorType>();

  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );

  const [freePlanExpired, setFreePlanExpired] = useState(false);
  const [showPremiumFeatureModal, setShowPremiumFeatureModal] = useState(
    PREMIUM_ROUTES.find((elem) => elem === location.pathname) &&
      foundOrganization &&
      checkFreePlan(foundOrganization)
      ? true
      : false
  );

  const [showOrgDescriptionModalGHG, setShowOrgDescriptionModalGHG] = useState(false);
  const [showOrgDescriptionModalDcycle, setShowOrgDescriptionModalDcycle] = useState(false);
  const [showOrgDescriptionModalSnippet, setShowOrgDescriptionModalSnippet] = useState(false);
  const [showOrgDescriptionModalPai, setShowOrgDescriptionModalPai] = useState(false);
  const [showOrgDescriptionModalIso14064, setShowOrgDescriptionModalIso14064] = useState(false);
  const [showCSRDModal, setShowCSRDModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [mode, setMode] = useState<string>('environmental');

  useEffect(() => {
    setShowPremiumFeatureModal(
      PREMIUM_ROUTES.find((elem) => elem === location.pathname || location.search.includes(elem)) &&
        foundOrganization &&
        checkFreePlan(foundOrganization)
        ? true
        : false
    );
  }, [location]);

  useEffect(() => {
    const handleOrgDescriptionModal = (
      key: string,
      setModal: Dispatch<SetStateAction<boolean>>
    ) => {
      if (searchParams.get(key) && foundOrganization?.subscription_plan !== 'free_plan') {
        setModal(true);
        searchParams.delete(key);
        setSearchParams(searchParams);
      }
    };
    if (foundOrganization) {
      const expirationDate = new Date(foundOrganization?.subscription_plan_expire_date);
      if (expirationDate < new Date()) {
        setFreePlanExpired(true);
        return;
      }
    }
    handleOrgDescriptionModal('showOrgDescriptionModalGHG', setShowOrgDescriptionModalGHG);
    handleOrgDescriptionModal('showOrgDescriptionModalDcycle', setShowOrgDescriptionModalDcycle);
    handleOrgDescriptionModal('showOrgDescriptionModalSnippet', setShowOrgDescriptionModalSnippet);
    handleOrgDescriptionModal('showOrgDescriptionModalPai', setShowOrgDescriptionModalPai);
    handleOrgDescriptionModal(
      'showOrgDescriptionModalIso14064',
      setShowOrgDescriptionModalIso14064
    );
  }, [searchParams]);

  const csrdModalSeen = localStorageGetItem('csrdModalSeen');

  useEffect(() => {
    if (!csrdModalSeen) {
      setShowCSRDModal(true);
    } else {
      setShowCSRDModal(false);
    }
  }, [csrdModalSeen]);

  const onCloseModal = () => {
    setShowOrgDescriptionModalGHG(false);
    setShowOrgDescriptionModalDcycle(false);
    setShowOrgDescriptionModalSnippet(false);
    setShowOrgDescriptionModalPai(false);
    setShowOrgDescriptionModalIso14064(false);
  };

  const onClosePremiumFeatureModal = () => {
    setShowPremiumFeatureModal(false);
  };

  // const onCloseSurveyModal = () => {
  //   // set local storage to not show survey modal
  //   localStorage.setItem('hideSurveyModal', 'true');
  //   emptySurveys();
  // };

  const renderModal = () => {
    const expired = checkExpiredPlan(foundOrganization);

    if (expired) {
      return <FreePlanReminder />;
    }
    return <PremiumFeatureAdvise />;
  };

  if (!user) {
    return null;
  }

  return (
    <section className='main-app app-bg-color'>
      <SubscriptionReminder />
      <FreeTrialProvider
        setShowPremiumFeatureModal={setShowPremiumFeatureModal}
        showPremiumFeatureModal={showPremiumFeatureModal}>
        <FilterDatesContext.Provider
          value={{ startDate, setStartDate, endDate, setEndDate, dateError, setDateError }}>
          <ESGModeContext.Provider value={{ mode, setMode }}>
            <div
              className='main-app-wrapper'
              style={{
                paddingBottom:
                  foundOrganization?.subscription_plan !== 'free_plan' ? '5px' : '10px',
                paddingTop: foundOrganization?.subscription_plan !== 'free_plan' ? '20px' : '30px'
              }}>
              {(width > SMALL_SCREEN || (width < SMALL_SCREEN && isOpen)) && <SideMenu />}
              {!freePlanExpired ? (
                <div className='main-app-content'>
                  <MenuBurger setIsOpen={setIsOpen} open={isOpen} />
                  {user && <Header showFilterDates={showFilterDates} />}
                  {PREMIUM_ROUTES.find((elem) => elem === location.pathname) &&
                  foundOrganization &&
                  checkFreePlan(foundOrganization) ? (
                    <></>
                  ) : (
                    children
                  )}
                  {/* <Footer /> */}
                </div>
              ) : (
                <div className='main-app-content'>
                  <MenuBurger setIsOpen={setIsOpen} open={isOpen} />

                  <HubspotCalendar url={BOOK_MEETING_URL} />
                </div>
              )}
            </div>

            <Modal
              show={showPremiumFeatureModal}
              onClose={onClosePremiumFeatureModal}
              width='550px'
              maxWidth='550px'>
              {renderModal()}
            </Modal>
          </ESGModeContext.Provider>
        </FilterDatesContext.Provider>
      </FreeTrialProvider>
      {foundOrganization?.logo_url && <PoweredByDcycle />}
      <Modal
        show={showOrgDescriptionModalGHG}
        onClose={onCloseModal}
        width='800px'
        maxWidth='800px'>
        <GHGReportDataForm onClose={onCloseModal} />
      </Modal>
      <Modal
        show={showOrgDescriptionModalDcycle}
        onClose={onCloseModal}
        width='550px'
        maxWidth='550px'>
        <DcycleReportDataForm onCloseModal={onCloseModal} />
      </Modal>
      <Modal
        show={showOrgDescriptionModalSnippet}
        onClose={onCloseModal}
        width='584px'
        maxWidth='584px'>
        <SnippetReportDataForm user={user} closeModal={onCloseModal} />
      </Modal>
      <Modal
        show={showOrgDescriptionModalPai}
        onClose={onCloseModal}
        width='584px'
        maxWidth='584px'>
        <PaiReportDataForm closeModal={onCloseModal} />
      </Modal>
      <Modal
        show={showOrgDescriptionModalIso14064}
        onClose={onCloseModal}
        width='584px'
        maxWidth='584px'>
        <Iso14064Report closeModal={onCloseModal} />
      </Modal>
      {/* <Modal
        show={
          !!flags?.surveys &&
          formIds.length > 0 &&
          !tutorial?.active &&
          (!localStorage.getItem('hideSurveyModal') ||
            localStorage.getItem('hideSurveyModal') === 'false')
        }
        onClose={onCloseSurveyModal}
        width='700px'>
        <Survey formIds={formIds} />
      </Modal> */}
      <Modal.WithPortal
        width='600px'
        maxWidth='600px'
        show={showCSRDModal}
        onClose={() => {
          localStorageSetItem('csrdModalSeen', true);
          setShowCSRDModal(false);
        }}>
        <CSRDModal
          handleClose={() => {
            localStorageSetItem('csrdModalSeen', true);
            setShowCSRDModal(false);
          }}
        />
      </Modal.WithPortal>
    </section>
  );
}

export default MainLayout;
