import i18next from 'i18next';
import moment from 'moment';
import { EntryChangeResult, FieldValue, ResultValues, Row } from 'nuvo-react';
import {
  highlight_color,
  secondary_bg_color,
  secondary_border_color,
  secondary_color
} from '../styles/colors';
import { basicNuvoStyle } from 'styles/nuvo';

export const transformNuvoResultsIntoCsv = (results: ResultValues) => {
  const headers = Object.keys(results[0]).map((key) => key);
  const body: string[][] = [];

  results.forEach((row, rowIndex) => {
    headers.forEach((key) => {
      const value = String(row[key] ?? ' ');
      if (body[rowIndex]) {
        body[rowIndex].push(`"${value}"`);
      } else {
        body[rowIndex] = [`"${value}"`];
      }
    });
  });

  const content = [
    headers.join(','),
    body
      .map((row) => {
        return row.join(',');
      })
      .join('\r\n')
  ].join('\r\n');

  return content;
};

export const treatNuvoString = (value: string) => {
  if (value.includes('"')) {
    return value.replaceAll('"', "'");
  }

  return value;
};

export const treatLine = <T extends object>(obj: T) => {
  const newObj: Record<string, any> = {};

  for (const key in obj) {
    const value = obj[key];

    if (typeof value === 'string') {
      newObj[key] = treatNuvoString(value);
      continue;
    }

    newObj[key] = value;
  }

  return newObj;
};

const MIN_YEAR = 1970;

const t = i18next?.t;

const isValidDate = (value: FieldValue) =>
  typeof value === 'string' && moment(value, 'YYYY-MM-DD').year() < MIN_YEAR;

export const validateDateFields = (keys: string[]) => {
  return (row: Row) => {
    const invalidKeys = keys.filter((key) => isValidDate(row[key]));

    const response = invalidKeys.reduce((acc, key) => {
      acc[key] = {
        value: row[key],
        info: [
          {
            level: 'error',
            message: t('nuvo.errors.minYear')
          }
        ]
      };

      return acc;
    }, {} as EntryChangeResult['data']);

    return response;
  };
};

export const NUVO_STYLE = {
  primary: {
    buttons: {
      import: basicNuvoStyle.buttons.primary
    }
  },
  secondary: {
    buttons: {
      import: {
        ...basicNuvoStyle.buttons.secondary,
        whiteSpace: 'nowrap',
        backgroundColor: secondary_bg_color,
        color: secondary_color,
        border: `1px solid ${secondary_border_color}`,
        borderRadius: '8px'
      }
    }
  },
  link: {
    buttons: {
      import: {
        ...basicNuvoStyle.buttons.link,
        backgroundColor: '#00FF0000',
        color: highlight_color,
        width: '10%'
      }
    }
  }
} as const;

export const NUVO_SIZE = {
  small: {
    padding: '7px',
    fontSize: '12px',
    minHeight: '0px',
    minWidth: '0px'
  },
  medium: {
    minHeight: '0px',
    minWidth: '0px',
    fontSize: '12px',
    width: '100%',
    padding: '9.5px 9px'
  },
  large: {},
  fit: {
    minHeight: '0px',
    minWidth: '0px',
    fontSize: '12px',
    width: '100%',
    height: '100%'
  }
};

export const getBTNSNuvotyles = (
  lookAndFeel: 'primary' | 'secondary' | 'link',
  size: keyof typeof NUVO_SIZE
) => {
  return {
    buttons: {
      primary: {
        ...NUVO_STYLE.primary.buttons.import,
        ...NUVO_SIZE[size]
      },
      secondary: {
        ...NUVO_STYLE.secondary.buttons.import,
        ...NUVO_SIZE[size]
      },
      link: {
        ...NUVO_STYLE.link.buttons.import,
        ...NUVO_SIZE[size]
      },
      import: {
        ...NUVO_STYLE[lookAndFeel]?.buttons.import,
        ...NUVO_SIZE[size]
      }
    }
  };
};
