import { useTranslation } from 'react-i18next';

const useErrors = () => {
  const { t } = useTranslation();

  // Do partition in future

  const ERRORS = {
    FILES: {
      ERR_MAX_LIMIT_REACHED: t('error.files.maxLimitReached'),
      ERR_WRONG_COLUMNS: t('error.files.wrongColumns')
    },
    NUVO: {
      END_DATE_BEFORE_START_DATE: t('error.nuvo.endDateBeforeStartDate'),
      GENERAL_ERROR_TITLE: t('error.nuvo.generalErrorTitle'),
      GENERAL_ERROR_MESSAGE: t('error.nuvo.generalErrorMessage'),
      ERRORS_FOUNDED_TITLE: t('error.nuvo.errorsFoundedTitle'),
      ERRORS_FOUNDED_MESSAGE: t('error.nuvo.errorsFoundedMessage'),
      START_DATE_IN_FUTURE: t('error.nuvo.startDateInFuture'),
      END_DATE_IN_FUTURE: t('error.nuvo.endDateInFuture'),
      VEHICLE_LICENSE_PLATE_NOT_FOUND: t('error.nuvo.vehicleLicensePlateNotFound'),
      VALUE_MUST_BE_POSITIVE: t('error.nuvo.valueMustBePositive'),
      VALUE_BIGGER_THAN_0: t('error.valueBiggerThan0'),
      VALUE_REQUIRED: t('error.nuvo.valueRequired'),
      COUNTRY_NOT_FOUND: t('error.nuvo.countryNotFound'),
      COUNTRY_REQUIRED_IF_NO_DISTANCE: t('error.nuvo.countryRequiredIfNoDistance'),
      FUEL_TYPE_NOT_ALLOWED: t('error.nuvo.fuelTypeNotAllowed')
    },
    FORM: {
      SOMETHING_WENT_WRONG: {
        error: 'FORM_SOMETHING_WENT_WRONG',
        description: t('error.form.somethingWentWrong')
      },
      MIN_START_DATE: (minStartDate?: string) => ({
        error: 'FORM_MIN_START_DATE',
        description: t('error.form.minStartDate', { minStartDate })
      }),
      MAX_END_DATE: (maxEndDate?: string) => ({
        error: 'FORM_MAX_START_DATE',
        description: t('error.form.maxStartDate', { maxEndDate })
      }),
      MUST_BE_WITHIN_SAME_YEAR: {
        error: 'date',
        description: t('error.form.mustBeWithinSameYear')
      }
    },
    DATADIS: {
      TIMEOUT: {
        error: 'TIMEOUT',
        description: t('error.datadisTimeout')
      },
      NOT_REGISTERED: {
        error: 'DATADIS_NOT_REGISTERED',
        description: t('datadisConnection.notRegistered')
      },
      ALREADY_REGISTERED: {
        error: 'DATADIS_ALREADY_REGISTERED',
        description: t('datadisConnection.alreadyRegistered')
      },
      ACTIVE_CONTRACT: {
        error: 'ACTIVE_CONTRACT',
        description: t('datadisContractAdd.error.activeContract')
      },
      SAME_PERIOD: {
        error: 'SAME_PERIOD',
        description: t('datadisContractAdd.error.samePeriod')
      },
      DELETE_FAILED: {
        error: 'DELETE_FAILED',
        description: t('datadisContracts.error.delete')
      }
    },
    EMPLOYEES: {
      IN_LABORE_NOT_IN_PERIOD: {
        error: 'IN_LABORE_NOT_IN_PERIOD',
        description: t('error.inLaboreNotInPeriod')
      },
      EXISTING_ACTIVE_PERIOD_FOR_ITINERE: {
        error: 'EXISTING_ACTIVE_PERIOD_FOR_ITINERE',
        description: t('error.existingActivePeriodInItinere')
      }
    },
    BUSINESS_TRAVEL: {
      ORIGIN_DESTINATION_CANNOT_BE_THE_SAME: {
        error: 'invalidAddress',
        description: `${t('error.originAndDestinationCannotBeTheSame')}`
      },
      INVALID_TRAVEL_MODE_BETWEEN_PLACES: {
        error: 'invalidTravelMode',
        description: `${t('error.invalidTravelMode')}`
      },
      INVALID_DISTANCE: {
        error: 'valueBiggerThan0',
        description: `${t('error.valueBiggerThan0')}`
      }
    },
    LOGISTICS: {
      NO_AVAILABLE_DATA: {
        error: 'NO_AVAILABLE_DATA',
        description: t('logistics.noAvailableData')
      },
      NO_RECHARGES: {
        error: 'NO_RECHARGES',
        description: t('logistics.noRecharges')
      },
      NO_REQUESTS: {
        error: 'NO_REQUESTS',
        description: t('logistics.noRequests')
      }
    },
    CUSTOM_EMISSION_FACTORS: {
      DATES_OVERLAP: {
        error: 'DATES_OVERLAP',
        description: t('error.datesOverlap')
      }
    },
    VEHICLES: {
      ERR_DUPLICATE_LICENSE_PLATE: (license_plate: string) => ({
        error: 'ERR_DUPLICATE_LICENSE_PLATE',
        description: t('error.vehicles.duplicateLicensePlateNumber', { license_plate })
      }),
      ERR_INVALID_LICENSE_PLATE: {
        error: 'ERR_INVALID_LICENSE_PLATE',
        description: t('error.vehicles.invalidLicensePlate')
      },
      ERR_TYPE_NOT_FOUND: {
        error: 'ERR_TYPE_NOT_FOUND',
        description: t('error.vehicles.typeNotFound')
      },
      ERR_FUEL_NOT_FOUND: {
        error: 'ERR_FUEL_NOT_FOUND',
        description: t('error.vehicles.fuelNotFound')
      },
      ERR_INVALID_OWNERSHIP: {
        error: 'ERR_INVALID_OWNERSHIP',
        description: t('error.vehicles.invalidOwnership')
      },
      ERR_UNKNOWN_VEHICLE_NOT_FOUND: {
        error: 'ERR_UNKNOWN_VEHICLE_NOT_FOUND',
        description: t('error.vehicles.unknownVehicleNotFound')
      },
      ERR_INVALID_COUNTRY: {
        error: 'ERR_INVALID_COUNTRY',
        description: t('error.vehicles.invalidCountry')
      },
      ERR_DUPLICATE_LICENSE: {
        error: 'ERR_DUPLICATE_LICENSE_PLATE',
        description: t('error.vehicles.duplicateLicensePlate')
      }
    }
  };

  return ERRORS;
};

export default useErrors;
