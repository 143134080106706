import NuvoImporter from 'components/ui/nuvoImporter/NuvoImporter';
import { LanguageType, OnResults, RejectSubmitResult, SettingsAPI } from 'nuvo-react';
import { useColumns } from '../hooks/useColumns';
import { CATEGORY, MAX_NUVO_ENTRIES, NUVO_IDENTIFIER } from '../constants';
import { basicNuvoStyle } from 'styles/nuvo';
import { useTranslation } from 'react-i18next';
import { nuvoAdapter } from '../adapters/nuvoAdapter';
import { parseNuvoResults } from 'components/measure/activity/purchases/Nuvo/utils';
import useNuvoButton from 'customHooks/useNuvoButton';
import useNuvoFileName from 'customHooks/useNuvoFileName';
import useErrors from 'customHooks/useErrors';
import { uploadFilePresignedUrl } from 'services/api/aws';
import { getPressignedUrlLCAMaterials } from 'services/api/lca/materialManagement';
import { GLOBAL_AVERAGE_SUPPLIER } from 'components/lca/constants';
import { useDispatch } from 'react-redux';
import { lcaMaterialsLoaderActions } from 'store/slices/lcaMaterialsLoader';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  onClose: () => void;
};

export const NuvoLCAMaterials: React.FC<Props> = ({ onClose }) => {
  const { columns } = useColumns();
  const { i18n } = useTranslation();
  const { fileName, handleGetFileName, handleExit } = useNuvoFileName();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  useNuvoButton({ onClick: handleGetFileName });

  const nuvoErrors = useErrors();

  const settings: SettingsAPI = {
    multipleFileUpload: true,
    language: i18n.resolvedLanguage as LanguageType,
    style: basicNuvoStyle,
    automaticHeaderDetection: true,
    maxEntries: MAX_NUVO_ENTRIES,
    identifier: NUVO_IDENTIFIER,
    columns
  };

  const onCancel = () => {
    onClose();
    handleExit();
  };

  const GeneralError = new RejectSubmitResult(
    nuvoErrors.NUVO.GENERAL_ERROR_TITLE,
    nuvoErrors.NUVO.GENERAL_ERROR_MESSAGE
  );

  const onUpload: OnResults = async (results, _, complete) => {
    const adaptedResults = nuvoAdapter(results);

    const { finalFileName, file } = await parseNuvoResults(adaptedResults, fileName, CATEGORY);

    const uploadURL = await getPressignedUrlLCAMaterials(finalFileName);

    const response = await uploadFilePresignedUrl(file, uploadURL);

    if (!response) return complete(GeneralError);

    dispatch(lcaMaterialsLoaderActions.trigger());

    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ['lca-materials'] });

      dispatch(lcaMaterialsLoaderActions.reset());
    }, 30 * 1000);

    onClose();

    complete();
  };

  return (
    <NuvoImporter
      lookAndFeel='primary'
      size='medium'
      namespace='lca'
      onEntryInit={(row) => {
        if (!row.supplier) {
          return { supplier: { value: GLOBAL_AVERAGE_SUPPLIER } };
        }
      }}
      btnI18nKey='materialManagement.nuvoLcaMaterials'
      settings={settings}
      onCancel={onCancel}
      onResults={onUpload}
    />
  );
};
