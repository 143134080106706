import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LCAProcessMetadata, patchLCAProcessNode } from 'services/api/lca/lcaPortfolio';
import { LCAMetadata } from '../types/LCAScenarios';

export const usePatchLCAProcessNode = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: patchLCAProcessNode,
    onSuccess: (data, params) => {
      queryClient.setQueryData(['lca-node-metadata', params.acv_id], (oldData: LCAMetadata) => {
        const oldProcess = oldData.process.find((process) => process.node_id === params.nodeId);

        const newProcess = {
          ...oldProcess,
          node_id: data.node_id,
          name: data.name,
          input_types: {
            consumption: data.inputs.filter((input) => input.type === 'consumption').length,
            energy: data.inputs.filter((input) => input.type === 'energy').length,
            raw_material: oldProcess?.input_types?.raw_material || 0
          },
          country: data.country
        };

        return {
          ...oldData,
          process: (JSON.parse(JSON.stringify([...oldData.process])) as Array<LCAProcessMetadata>)
            .filter((process) => process.node_id !== params.nodeId)
            .concat(newProcess)
        };
      });

      queryClient.setQueryData(
        ['lca-node-inputs', params.acv_id, params.nodeId],
        data.inputs.filter((input) => input.type === 'consumption' || input.type === 'energy')
      );

      queryClient.resetQueries({ queryKey: ['lcaImpacts'], exact: false });
    }
  });

  return { patchLCAProcessNode: mutateAsync, isLoading: isPending };
};
