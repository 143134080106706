import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMutateCache } from 'lib/ReactQuery/hooks';
import { updateLCAOfficialMaterial } from 'services/api/lca/materialManagement';

export const useEditLCAOfficialMaterial = () => {
  const { editInList } = useMutateCache(['lca-materials']);
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: updateLCAOfficialMaterial,
    onSuccess: (data) => {
      queryClient.setQueryData<LCAOfficialMaterial>(
        ['lca-official-material', data.id],
        (oldData) => ({
          ...oldData,
          ...data
        })
      );

      editInList(data, 'id');
    }
  });
  return {
    editLCAOfficialMaterial: mutate,
    isEditing: isPending
  };
};
