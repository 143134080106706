import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StepCarouselType } from '../../../types/stepCarousel';

export type CarouselContent<T extends string> = {
  id: T;
  text: ReactNode;
  errorKeys?: string[];
  disabled?: boolean;
};

type Props<T extends string> = {
  stepsText: Array<CarouselContent<T>> | ReadonlyArray<CarouselContent<T>>;
  selected?: string;
};

const useStepCarousel = <T extends string>({ stepsText, selected }: Props<T>) => {
  const [steps, setSteps] = useState<StepCarouselType<T>[]>([]);

  const { i18n } = useTranslation();

  useEffect(() => {
    const initSteps: StepCarouselType<T>[] = stepsText.map((step, index) => {
      return {
        ...step,
        isSelected: selected ? selected === step.id : index === 0 ? true : false
      };
    });
    setSteps(initSteps);
  }, [stepsText.length, selected, i18n.resolvedLanguage]);

  const handleNext = () => {
    setSteps((currentSteps) => {
      const index = currentSteps.findIndex((currentStep) => currentStep.isSelected === true);
      return currentSteps.map((currentStep, indexStep) => {
        const isLastElement = currentSteps.at(-1)?.isSelected;
        if (isLastElement) {
          return { ...currentStep };
        }

        return {
          ...currentStep,
          isSelected: indexStep === index + 1 ? true : false
        };
      });
    });
  };

  const handleBack = () => {
    setSteps((currentSteps) => {
      const index = currentSteps.findIndex((currentStep) => currentStep.isSelected === true);
      return currentSteps.map((currentStep, indexStep) => {
        const isFirstElement = currentSteps.at(0)?.isSelected;
        if (isFirstElement) {
          return { ...currentStep };
        }

        return {
          ...currentStep,
          isSelected: indexStep === index - 1 ? true : false
        };
      });
    });
  };

  const handleSelect = (id: string) => {
    if (steps.find((step) => step.id === id)?.disabled) {
      return;
    }
    setSteps((currentSteps) =>
      currentSteps.map((currentStep) => {
        if (currentStep.id === id) {
          return {
            ...currentStep,
            isSelected: true
          };
        }
        return {
          ...currentStep,
          isSelected: false
        };
      })
    );
  };

  return {
    steps,
    stepSelected: steps.find((step) => step.isSelected === true),
    handleNext,
    handleBack,
    handleSelect,
    isLastElement: steps.at(-1)?.isSelected,
    isFirstElement: steps.at(0)?.isSelected
  };
};

export default useStepCarousel;
