import { fetchAvailableFrameworks, AvailableFramework } from 'services/api/frameworks';
import { useQuery } from '@tanstack/react-query';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';
export const useAvailableFrameworks = (organizationId?: string) => {
  const flags = useFeatureFlags();
  const { data, isLoading, error } = useQuery<AvailableFramework[], Error>({
    queryKey: ['availableFrameworks', organizationId],
    queryFn: () => fetchAvailableFrameworks(organizationId),
    enabled: flags?.sotDcycleDemos
  });
  return { availableFrameworks: data, isLoading, error };
};
