import apiFetch from 'utils/apiFetch';

export type ReportingPeriod = {
  id: string;
  name: string;
  start_day: number;
  start_month: number;
  end_day: number;
  end_month: number;
};

export type ReportingPeriodPayload = {
  name: string;
  start_day: number;
  start_month: number;
  end_day: number;
  end_month: number;
};

/**
 * Helper function to conditionally add headers.
 */
const withOrganizationHeader = (organizationId?: string) => {
  return organizationId ? { 'x-organization-id': organizationId } : {};
};

/**
 * Fetch all reporting periods for an organization.
 */
export const fetchReportingPeriods = async (organizationId?: string): Promise<ReportingPeriod> => {
  try {
    const response = await apiFetch(
      'GET',
      '/sot_organization_reporting_period',
      organizationId ? {} : null,
      withOrganizationHeader(organizationId)
    );
    return response.data[0] as ReportingPeriod;
  } catch (error) {
    console.error('Error fetching reporting periods:', error);
    throw error;
  }
};

/**
 * Create a new reporting period.
 */
export const createReportingPeriod = async (
  payload: ReportingPeriodPayload,
  organizationId?: string
): Promise<ReportingPeriod> => {
  try {
    const response = await apiFetch(
      'POST',
      '/sot_organization_reporting_period',
      payload,
      withOrganizationHeader(organizationId)
    );
    return response.data as ReportingPeriod;
  } catch (error) {
    console.error('Error creating reporting period:', error);
    throw error;
  }
};

/**
 * Update an existing reporting period.
 */
export const updateReportingPeriod = async (
  id: string,
  payload: ReportingPeriodPayload,
  organizationId?: string
): Promise<ReportingPeriod> => {
  try {
    const response = await apiFetch(
      'PATCH',
      `/sot_organization_reporting_period/${id}`,
      payload,
      withOrganizationHeader(organizationId)
    );
    return response.data as ReportingPeriod;
  } catch (error) {
    console.error(`Error updating reporting period with ID ${id}:`, error);
    throw error;
  }
};
