import type { ISorter } from 'utils/url';

export const DEFAULT_SORTERS_EMPLOYEES: ISorter[] = [
  {
    field: 'created_at',
    order: 'desc'
  }
];

export const EMPLOYEE_MODALS = {
  EDIT_EMPLOYEE: 'edit_employee',
  ADD_EMPLOYEE: 'add_employee',
  DELETE_EMPLOYEE: 'delete_employee',
  QR_CODE: 'qr_code',
  SEND_CUSTOM: 'send_custom',
  CONFIRM_RESEND: 'confirm_resend',
  SEND_PENDING: 'send_pending',
  SHOW_DOWNLOAD: 'show_download',
  PERIOD_TO_DELETE: 'period_to_delete',
  PERIOD_TO_EDIT: 'period_to_edit',
  ADD_PERIOD: 'add_period',
  RESEND_ONE: 'resend_one'
} as const;

type EmployeeModalsEnum = typeof EMPLOYEE_MODALS;

export type EmployeeModals = EmployeeModalsEnum[keyof EmployeeModalsEnum];

export const FIND_EMPLOYEES = [EMPLOYEE_MODALS.EDIT_EMPLOYEE, EMPLOYEE_MODALS.RESEND_ONE] as const;

export const FIND_PERIODS = [
  EMPLOYEE_MODALS.PERIOD_TO_EDIT,
  EMPLOYEE_MODALS.PERIOD_TO_DELETE
] as const;
