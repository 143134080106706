import { useNuvoCountries } from 'customHooks/useNuvoCountries';
import { ColumnAPI } from 'nuvo-react';

export const useColumns = () => {
  const { countriesNuvo } = useNuvoCountries();

  const columns: ColumnAPI[] = [
    {
      key: 'name',
      label: 'name',
      columnType: 'string',
      validations: [{ validate: 'required' }]
    },
    {
      key: 'supplier',
      label: 'supplier',
      columnType: 'string'
    },
    {
      key: 'country',
      label: 'country',
      columnType: 'category',
      dropdownOptions: countriesNuvo,
      validations: [{ validate: 'required' }]
    },
    {
      key: 'description',
      label: 'description',
      columnType: 'string'
    }
  ];

  return { columns };
};
