import { z } from 'zod';

export const createMaterialSchema = z.object({
  name: z.string().min(1, 'min:1'),
  supplier: z.string().optional(),
  description: z.string().optional(),
  country: z.object({
    id: z.string().min(1),
    name: z.string().min(1)
  }),
  type: z.object({
    id: z.enum(['material', 'energy']),
    name: z.string().min(1)
  })
});

export type CreateMaterialForm = z.infer<typeof createMaterialSchema>;
