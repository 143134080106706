import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { setNotification } from '../../../../actions/notification';
import { SIZE_PAGINATION_SMALL } from '../../../../constants';
import { ROUTES } from 'constants/routes';
import { UserContext } from '../../../../context/userContext';
import useDebounceValue from '../../../../customHooks/useDebounceValue';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import useTreatmentSector from '../../../../customHooks/useTreatmentSector';
import { getFacility, updateFacility } from '../../../../services/api/facility';
import { getInvoiceById } from '../../../../services/api/invoices';
import { getUnitsByCountry } from '../../../../services/api/unit';
import { Facility } from '../../../../types/entities/facility';
import { InvoiceAuto, InvoiceEdit, InvoiceType } from '../../../../types/entities/invoice';
import { User } from '../../../../types/entities/user';
import { InvoiceSource, InvoiceStatus } from '../../../../types/entitiesEnums/invoice';
import { InputSize } from '../../../../types/utilsEnums/input';
import apiFetch from '../../../../utils/apiFetch';
import { checkFreePlan } from '../../../../utils/checkFreePlan';
import formatNumber from '../../../../utils/formatNumber';
import { numberToDecimalNonZero } from '../../../../utils/numberToDecimal';
import { sessionStorageGetItem } from '../../../../utils/sessionStorage';
import { generateQueryParamsFromObject, getUrl } from '../../../../utils/url';
import { FiltersContext } from '../../../layout/Filters/FilterContext';
import FilterSection, { FilterOptionType } from '../../../layout/Filters/FilterSection';
import Filters from '../../../layout/Filters/Filters';
import FiltersDate from '../../../layout/Filters/FiltersDate';
import FiltersHandlers from '../../../layout/Filters/FiltersHandlers';
import useFilters from '../../../layout/Filters/hooks/useFilters';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import Button from '../../../ui/button/Button';
import ButtonDropdown from '../../../ui/buttonDropdown/ButtonDropdown';
import CardCTA from '../../../ui/cards/cardCTA/CardCTA';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormText from '../../../ui/formComponents2/formInputs/formText/FormText';
import Icon from '../../../ui/icon/Icon';
import InfiniteList from '../../../ui/infiniteList/InfiniteListV2';
import LoaderTables from '../../../ui/loaders/loaderTables/LoaderTables';
import Modal from '../../../ui/modal/Modal';
import NameIcon from '../../../ui/nameIcon/NameIcon';
import Sorting from '../../../ui/sorting/Sorting';
import useOnSort from '../../../ui/sorting/hooks/useOnSort';
import ErrorLabel from '../../../ui/statusLabels/errorLabel/ErrorLabel';
import PendingLabel from '../../../ui/statusLabels/pendingLabel/PendingLabel';
import SuccessLabel from '../../../ui/statusLabels/successLabel/SuccessLabel';
import StepCarouselList from '../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../ui/stepCarouselList/useStepCarousel';
import Tooltip from '../../../ui/tooltip/Tooltip';
import TooltipWrapper from '../../../ui/tooltip/TooltipWrapper';
import TotalLegend from '../../../ui/totalLegend/TotalLegend';
import Validation, { ValidationCategory } from '../../../validation/Validation';
import { CategoriesUploadedFiles } from '../../uploadedFiles/constants';
import LimitCompleted from '../limitCompleted/LimitCompleted';
import CreateInvoice from './components/createInvoice/CreateInvoice';
import { parseQuantity, parseUnit } from './components/createInvoice/components/common/utils';
import Treatment from './components/createInvoice/components/treatment/Treatment';
import Waste from './components/createInvoice/components/waste/Waste';
import DeleteInvoice from './components/deleteInvoice/DeleteInvoice';
import EditInvoice from './components/editInvoice/EditInvoice';
import UploadInvoices from './components/uploadInvoices/UploadInvoices';
import useColumns from './hooks/useColumns';
import useFetchData from './hooks/useFetchData';
import useSideBarFilters from './hooks/useSideBarFilters';
import useSortingOptions from './hooks/useSortingOptions';
import './styles.scss';
import { renderAltQuantity, renderQuantity } from './components/TableHeaderRenderers';
import Label from 'components/ui/label/Label';

function FacilityDetail() {
  const { t } = useTranslation();
  const flags = useFeatureFlags();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const user = useContext(UserContext);

  const selectedOrganization = useSelectedOrganization();

  const { id } = useParams();

  const { isTreatmentOrganization } = useTreatmentSector();

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const foundOrganization = user?.organizations?.find(
    (elem) => elem.id === user.selectedOrganization
  );

  const defaultSort = {
    id: 'start_date',
    name: t('input.sorting.facilities.startDate'),
    direction: 'desc' as 'desc' | 'asc'
  };
  const BASE_URL = `/invoices/casted/facility/${params.id}?sort_by=${defaultSort.id}:${defaultSort.direction}`;

  const [facility, setFacility] = useState<Facility>();
  const [invoiceMode, setInvoiceMode] = useState<string>('');
  const [invoiceToEdit, setInvoiceToEdit] = useState<InvoiceType>();
  const [invoiceToDelete, setInvoiceToDelete] = useState<InvoiceType>();
  const [currencyUnit, setCurrencyUnit] = useState<SelectOptionFormat>();
  const [optionsForFilter, setOptionsForFilters] = useState<any>();
  const [showValidationModal, setShowValidationModal] = useState(true);
  const [filters, setFilters] = useState<FilterOptionType[]>([]);
  const [open, setOpen] = useState(false);
  const [remove, setRemoveFilters] = useState(false);
  const [clearDates, setClearDates] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState<{ startDate: string; endDate: string } | undefined>();
  const [url, setUrl] = useState(BASE_URL);
  const [searchValue, setSearchValue] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [showLimitCompleted, setShowLimitCompleted] = useState(false);

  const debounceValue = useDebounceValue(searchValue);

  const invoiceCategory = searchParams.get('category');

  const stepsText = [
    { id: 'heat', text: t('facilityDetail.combustion') },
    { id: 'recharge', text: t('facilityDetail.recharge') },
    { id: 'electricity', text: t('facilityDetail.electricity') },
    { id: 'water', text: t('facilityDetail.water') },
    { id: 'wastes', text: t('facilityDetail.waste') }
  ];

  if (isTreatmentOrganization) {
    stepsText.push({
      id: 'waste_water_treatment',
      text: t('facilityDetail.waste_water_treatment')
    });
  }

  const {
    stepSelected,
    handleSelect: handleSelectCarousel,
    steps
  } = useStepCarousel({
    stepsText: stepsText.filter((step: { id: string }) => facility?.categories?.includes(step.id))
  });

  const sortingOptions = useSortingOptions({ category: stepSelected?.id ?? '' });
  const {
    fetchData,
    firstLoading,
    loading: loadingTable,
    total,
    setTotal,
    total2: totalErrors,
    setTotal2: setTotalErrors,
    totalGeneral,
    invoices,
    setInvoices
  } = useFetchData({ url, category: stepSelected?.id ?? '' });

  const columns = useColumns(stepSelected?.id ?? '');

  useEffect(() => {
    const fetchDataFacility = async () => {
      if (!user?.selectedOrganization || !params.id) return;
      const facilityResult = await getFacility(params.id, user?.selectedOrganization);
      if (!facilityResult) return;
      setFacility(facilityResult);
      // Call endpoint that returns units of currency given a country
      const unit = await getUnitsByCountry(facilityResult.country);
      if (!unit) {
        setLoading(false);
        return;
      }
      setCurrencyUnit({
        id: unit.id,
        name: unit.name
      });
      setLoading(false);
    };
    fetchDataFacility();
  }, [params.id]);

  useEffect(() => {
    if (!invoiceCategory) return;
    const step = steps.find((step) => step.id === invoiceCategory);
    if (!step) return;
    handleSelectCarousel(step.id);
  }, [invoiceCategory, steps.length]);

  const fetchInvoiceToEdit = async (id: string) => {
    if (!id) return;
    if (stepSelected?.id === 'waste_water_treatment' || stepSelected?.id === 'wastes') {
      setInvoiceToEdit({
        id,
        supplier: undefined,
        facility_fuel: undefined,
        quantity: 0,
        unit: { id: '', name: '', type: '' },
        start_date: '',
        end_date: '',
        invoice_id: '',
        cups: '',
        type: stepSelected?.id ?? '',
        status: InvoiceStatus.ACTIVE,
        user: {
          first_name: user?.first_name || ''
        },
        base_quantity: 0,
        percentage: 100,
        facility_percentages: []
      });
      return;
    }

    const data = await getInvoiceById(id);
    setInvoiceToEdit(data);
  };

  const fetchInvoiceToDelete = async (id: string) => {
    if (!id) return;
    if (stepSelected?.id === 'waste_water_treatment' || stepSelected?.id === 'wastes') {
      setInvoiceToDelete({
        id,
        supplier: undefined,
        facility_fuel: undefined,
        quantity: 0,
        unit: { id: '', name: '', type: '' },
        start_date: '',
        end_date: '',
        invoice_id: '',
        cups: '',
        type: stepSelected?.id ?? '',
        status: InvoiceStatus.ACTIVE,
        user: {
          first_name: user?.first_name || ''
        },
        percentage: 100,
        base_quantity: 0,
        facility_percentages: []
      });
      return;
    }
    const data = await getInvoiceById(id);
    setInvoiceToDelete(data);
  };

  useEffect(() => {
    setShowValidationModal(false);
    // Extract the query parameter you want to monitor
    const queryParams = new URLSearchParams(location.search);
    const myQueryParam = queryParams.get('show');

    if (myQueryParam === 'create') setInvoiceMode('manual');
    if (myQueryParam === 'upload') setInvoiceMode('auto');
    if (myQueryParam === 'edit') {
      fetchInvoiceToEdit(queryParams.get('id') || '');
    }
    if (myQueryParam === 'delete') {
      fetchInvoiceToDelete(queryParams.get('id') || '');
    }
  }, [location.search]);

  useFilters({
    setUrl,
    url,
    setFilters,
    filters,
    removeFilters: setRemoveFilters,
    remove,
    dates,
    setDates,
    setClearDates,
    searchFilters: [
      {
        // key: 'invoice_id',
        key: stepSelected?.id === 'wastes' ? 'search' : 'invoice_id',
        value: debounceValue,
        setValue: setSearchValue
      }
    ]
  });

  const renderDropdownComponent = (invoice: InvoiceType) => {
    if (facility?.status === 'archived') {
      return (
        <TooltipWrapper
          text={t('facilityDetail.archivedFacilityTooltip')}
          style={{ marginLeft: 'auto' }}>
          <Icon icon='locked' color={'gradient'} />
        </TooltipWrapper>
      );
    }

    let blockedActions = null;
    invoice.facility_percentages?.forEach((elem) => {
      // Check invoice, if it has a facility percentage with an organization that is not the current one and the user is not of role parent, do not allow to do it
      const foundOrgDifferentFromCurrent =
        elem.organization_id !== user?.selectedOrganization ? elem : null;

      if (foundOrgDifferentFromCurrent) {
        const foundInUser = user?.organizations?.find(
          (elem) => elem.id === foundOrgDifferentFromCurrent.organization_id
        );
        if (!foundInUser || (foundInUser.role !== 'parent' && foundInUser.role !== 'owner')) {
          blockedActions = (
            <TooltipWrapper
              text={t('facilityDetail.createdByHoldingTooltip', {})}
              style={{ marginLeft: 'auto' }}>
              <Icon icon='locked' color={'gradient'} />
            </TooltipWrapper>
          );
          // finish loop
          return;
        }
      }
    });

    if (blockedActions) return blockedActions;

    const stepText = stepSelected?.id ? t(`facilityCategory.${stepSelected?.id}`) : '';

    const options = [
      {
        id: `${invoice.id}-delete`,
        name: t('facilities.delete', { type: stepText }),
        onClick: () => {
          fetchInvoiceToDelete(invoice.id);
        }
      },
      {
        id: `${invoice.id}-edit`,
        name: t('facilities.edit', { type: stepText }),
        onClick: () => {
          setInvoiceToEdit(invoice);
        }
      }
    ];

    if (stepSelected?.id === 'wastes' && invoice.file_name) {
      options.push({
        id: `${invoice.id}-goToFile`,
        name: t('facilities.goToFile'),
        onClick: () => {
          navigate(
            getUrl(`${ROUTES.MEASURE_UPLOADED_FILES}/${CategoriesUploadedFiles.WASTES}`, {
              queryParams: generateQueryParamsFromObject({ name: invoice.file_name ?? '' })
            })
          );
        }
      });
    }

    //
    if (invoice.file_url) {
      options.push({
        id: `${invoice.id}-download`,
        name: t('facilityDetail.download'),
        onClick: () => {
          window.open(invoice.file_url);
        }
      });
    }
    if (invoice.invofox_id && user?.email.includes('@dcycle.io')) {
      options.push({
        id: `${invoice.id}-invofox`,
        name: 'Copy Invofox ID',
        onClick: () => {
          navigator.clipboard.writeText(invoice.invofox_id || '');
        }
      });
    }
    return (
      <ButtonDropdown
        button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
        options={options}
      />
    );
  };

  const statusIconSelect = (status: InvoiceStatus, source?: InvoiceSource) => {
    if (status === InvoiceStatus.ACTIVE || status === InvoiceStatus.UPLOADED)
      return <SuccessLabel key='active'>{t('general.completed')}</SuccessLabel>;

    if (status === InvoiceStatus.LOADING)
      return (
        <TooltipWrapper text={t('facilityDetail.processingTooltip')} position='top' key='loading'>
          <PendingLabel>{t('general.processing')}</PendingLabel>
        </TooltipWrapper>
      );

    if (status === InvoiceStatus.ERROR && !source)
      return <ErrorLabel>{t('general.withErrors')}</ErrorLabel>;

    if (status === InvoiceStatus.ERROR && source === InvoiceSource.DATADIS)
      return (
        <TooltipWrapper text={t('facilityDetail.errorTooltipDatadis')} position='top'>
          <ErrorLabel>{t('general.withErrors')}</ErrorLabel>
        </TooltipWrapper>
      );

    return <></>;
  };

  const renderSupplierComponent = (invoice: InvoiceType) => {
    const supplier = invoice.supplier;

    if (invoice.custom_emission_factor && invoice.type === 'electricity') {
      return (
        <div className='flex gap-2 items-center'>
          <span
            style={{
              minWidth: '100px',
              maxWidth: '300px',
              whiteSpace: 'pre-wrap',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              maxHeight: '2.4em',
              overflow: 'hidden'
            }}>
            {invoice.custom_emission_factor.name}
          </span>
          <Label>{t('facilityDetail.custom')}</Label>
        </div>
      );
    }
    if (supplier && supplier.name) {
      return (
        <div className='icon-text-wrapper'>
          <span
            style={{
              minWidth: '200px',
              maxWidth: '300px',
              whiteSpace: 'pre-wrap',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              maxHeight: '2.4em',
              overflow: 'hidden'
            }}>
            {supplier.name}
          </span>
        </div>
      );
    } else {
      return (
        <div className='icon-text-wrapper'>
          <span>-</span>
        </div>
      );
    }
  };

  const renderUploadedBy = (user?: Partial<User>, source?: string) => {
    if (!user?.first_name && !user?.last_name && source)
      return <NameIcon name={source} lastName={''} />;
    return (
      <div className='flex' style={{ alignItems: 'center', gap: '8px' }}>
        <NameIcon name={user?.first_name || ''} lastName={user?.last_name || ''} />{' '}
        <span>
          {user?.first_name} {user?.last_name ? user?.last_name[0] : ''}
        </span>
      </div>
    );
  };

  const getClassName = (record: any) => {
    if (record?.status?.key === 'loading') return 'disabled';
    return '';
  };

  const onCloseModal = () => {
    const isNuvoOpen = sessionStorageGetItem('isNuvoOpen');

    if (isNuvoOpen) return;

    setInvoiceMode('');
    setInvoiceToEdit(undefined);
    setInvoiceToDelete(undefined);
    // delete query param "show"
    searchParams.delete('show');
    // delete query param "id"
    searchParams.delete('id');
    setSearchParams(searchParams);
  };

  const newAutoInvoice = () => {
    setInvoiceMode('auto');
  };

  const newManualInvoice = () => {
    setInvoiceMode('manual');
  };

  const addInvoice = (invoice: InvoiceType | null) => {
    if (invoice) {
      const oldInvoices = [...invoices];
      oldInvoices.push({
        ...invoice,
        id: invoice.id,
        custom_emission_factor_id: invoice.custom_emission_factor_id,
        type: invoice.type,
        supplier: invoice.supplier,
        facility_fuel: invoice?.facility_fuel,
        quantity: invoice.quantity,
        kg_sludge: invoice.kg_sludge || 0,
        m3_water_out: invoice.m3_water_out || 0,
        unit: invoice.unit,
        start_date: invoice.start_date,
        end_date: invoice.end_date,
        invoice_id: invoice.invoice_id,
        user: {
          first_name: user?.first_name || ''
        },
        status: stepSelected?.id === 'wastes' ? InvoiceStatus.LOADING : InvoiceStatus.ACTIVE,
        co2e: undefined
      });

      setInvoices(oldInvoices);
      setTotal((total) => total + 1);
      setTotalErrors((total2) => total2 + 1);
      handleSelectCarousel(invoice.type);
    }
    onCloseModal();
    dispatch(
      setNotification(
        t(stepSelected?.id === 'wastes' ? 'notification.uploadWaste' : 'notification.uploadInvoice')
      )
    );
  };

  const addInvoicesAuto = (invoicesUploaded: InvoiceAuto[]) => {
    const oldInvoices = [...invoices];
    const type = invoicesUploaded[0].type;
    invoicesUploaded.forEach((invoice) => {
      oldInvoices.push({
        id: invoice.id,
        type: invoice.type,
        supplier: invoice.supplier,
        facility_fuel: invoice?.facility_fuel,
        quantity: 0,
        unit: invoice.unit,
        start_date: new Date(invoice.start_date).toDateString(),
        end_date: new Date(invoice.end_date).toDateString(),
        invoice_id: invoice.invoice_id,
        cups: invoice.cups,
        user: {
          first_name: user?.first_name || ''
        },
        status: invoice.status,
        percentage: invoice.percentage,
        facility_percentages: invoice.facility_percentages,
        base_quantity: 0
      });
    });

    setInvoices(oldInvoices);
    onCloseModal();
    dispatch(setNotification(t('notification.uploadInvoice')));
    setTotal((total) => total + 1);
    setTotalErrors((total2) => total2 + 1);
    // setTotalGeneral((totalGeneral) => totalGeneral + 1);
    handleSelectCarousel(type);
  };

  const editInvoice = (id: string, invoice: InvoiceEdit) => {
    const oldInvoices = [...invoices];
    const indexFound = oldInvoices.findIndex((invoice) => invoice.id === id);
    const foundInvoiceOld = { ...oldInvoices[indexFound] };

    if (indexFound > -1) {
      if (invoice.type !== 'waste_water_treatment') {
        if (!invoice.facility_percentages?.find((elem) => elem.facility_id === facility?.id)) {
          deleteInvoice(id);
          return;
        }
      }
      oldInvoices[indexFound] = {
        id: invoice.id,
        custom_emission_factor_id: invoice.custom_emission_factor_id || undefined,
        type: invoice.type || foundInvoiceOld.type,
        supplier: invoice.supplier || undefined,
        facility_fuel: invoice.facility_fuel || undefined,
        quantity: invoice.quantity || invoice.original_quantity || foundInvoiceOld.quantity,
        kg_sludge: invoice.kg_sludge || 0,
        m3_water_out: invoice.m3_water_out || 0,
        unit: invoice.unit || foundInvoiceOld.unit,
        start_date: invoice.start_date || foundInvoiceOld.start_date,
        end_date: invoice.end_date || foundInvoiceOld.end_date,
        invoice_id: invoice.invoice_id || foundInvoiceOld.invoice_id,
        cups: invoice.cups || foundInvoiceOld.cups,
        user: {
          first_name: foundInvoiceOld.user.first_name || ''
        },
        status: invoice.status ? invoice.status : foundInvoiceOld.status,
        file_url: invoice.file_url ? invoice.file_url : foundInvoiceOld.file_url,
        co2e: invoice.co2e,
        percentage: invoice.percentage ? invoice.percentage : foundInvoiceOld.percentage,
        facility_percentages: invoice.facility_percentages || foundInvoiceOld.facility_percentages,
        base_quantity: invoice.base_quantity || foundInvoiceOld.base_quantity
      };
    }
    setInvoices(oldInvoices);
    setInvoiceToEdit(undefined);
    onCloseModal();
    dispatch(
      setNotification(
        t(stepSelected?.id === 'wastes' ? 'notification.editWaste' : 'notification.editInvoice')
      )
    );
  };

  const deleteInvoice = (id: string) => {
    const newInvoices = [...invoices];
    const indexInvoiceFound = newInvoices.findIndex((elem) => elem.id === id);
    const foundInvoice = indexInvoiceFound > -1 ? newInvoices[indexInvoiceFound] : null;
    if (indexInvoiceFound > -1) {
      newInvoices.splice(indexInvoiceFound, 1);
    }

    setInvoices(newInvoices);
    dispatch(
      setNotification(
        t(stepSelected?.id === 'wastes' ? 'notification.deleteWaste' : 'notification.deleteInvoice')
      )
    );
    onCloseModal();
    if (
      foundInvoice?.status === InvoiceStatus.ACTIVE ||
      foundInvoice?.status === InvoiceStatus.UPLOADED
    ) {
      setTotal((total) => total - 1);
    }
    if (
      foundInvoice?.status === InvoiceStatus.ERROR ||
      foundInvoice?.status === InvoiceStatus.LOADING
    ) {
      setTotalErrors((total) => total - 1);
    }
  };

  const renderFacilityFuel = (invoice: InvoiceType) => {
    const facilityFuelName =
      invoice.facility_fuel && invoice.facility_fuel.name
        ? t(`facility_fuels.${invoice.facility_fuel.name}`)
        : invoice.custom_emission_factor
        ? invoice.custom_emission_factor.name
        : '-';
    if (invoice.custom_emission_factor) {
      return (
        <div className='flex gap-2 items-center'>
          <span
            style={{
              maxWidth: '300px',
              whiteSpace: 'pre-wrap',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              maxHeight: '2.4em',
              overflow: 'hidden'
            }}>
            {facilityFuelName}
          </span>{' '}
          <Label>{t('facilityDetail.custom')}</Label>
        </div>
      );
    }
    return <span>{facilityFuelName}</span>;
  };

  const parseData = (invoices: any) => {
    const data = invoices.map((invoice: any) => {
      const invoiceStartDate =
        invoice.start_date !== 'Invalid Date'
          ? new Date(invoice.start_date).toLocaleDateString()
          : '-';
      const invoiceEndDate =
        invoice.end_date !== 'Invalid Date' ? new Date(invoice.end_date).toLocaleDateString() : '-';
      return {
        ...invoice,
        cups: invoice.cups || '-',
        quantity: renderQuantity(
          invoice,
          currencyUnit,
          t,
          invoice.type !== 'waste_water_treatment'
        ),
        drySludge: renderAltQuantity(invoice.kg_sludge, t),
        dischargeWater: renderAltQuantity(invoice.m3_water_out, t),
        unit: invoice.unit ? t(`units.${parseUnit(invoice, currencyUnit)}`) : '-',
        invoiceId: invoice.invoice_id || '-',
        startDate: invoiceStartDate,
        endDate: invoiceEndDate,
        uploadedBy: renderUploadedBy(invoice.user, invoice.source),
        status: statusIconSelect(invoice.status, invoice.source),
        edit: renderDropdownComponent(invoice),
        supplier: renderSupplierComponent(invoice),
        facility_fuel: renderFacilityFuel(invoice),
        file_url: invoice.file_url ? <img src='/images/icons/attached.svg' alt='attached' /> : '',
        disabled: invoice.status !== 'uploaded' && invoice.status !== 'active',
        rd_code: invoice.rd_code || '-',
        total_km_to_waste_center: invoice.total_km_to_waste_center || '-',
        co2e:
          invoice.status === InvoiceStatus.LOADING && invoice.co2e === undefined ? (
            <TooltipWrapper
              text={t('facilityDetail.co2eTooltip')}
              style={{ width: '100%', textAlign: 'right' }}>
              <span
                className='highlight-text-color'
                style={{ fontWeight: 600, textAlign: 'right' }}>
                -
              </span>
            </TooltipWrapper>
          ) : (
            <span
              className='highlight-text-color'
              style={{ fontWeight: 600, textAlign: 'right', display: 'block' }}>
              {formatNumber(numberToDecimalNonZero(invoice.co2e))} kg
            </span>
          )
      };
    });
    return data;
  };

  const switchToAuto = () => {
    setInvoiceMode('auto');
  };

  const handleDearchiveFacility = async () => {
    setLoadingButton(true);

    const responseFacilities = await apiFetch(
      'GET',
      '/facilities',
      undefined,
      {},
      {
        page: 1,
        size: SIZE_PAGINATION_SMALL,
        filter_by: 'status:eqactive'
      }
    );
    if (!responseFacilities || !responseFacilities.data) {
      setLoadingButton(false);
      return;
    }
    if (
      selectedOrganization?.limit_facilities &&
      selectedOrganization?.limit_facilities <= responseFacilities.data.total
    ) {
      setLoadingButton(false);
      setShowLimitCompleted(true);
      return;
    }
    if (!facility) {
      setLoadingButton(false);
      return;
    }
    const response = await updateFacility(facility.id, { status: 'active' });
    setLoadingButton(false);
    if (!response) return;
    setFacility({
      ...facility,
      status: 'active'
    });
    setNotification(t('notification.dearchiveFacility'));
  };

  const handleChangeInvoiceCategory = (category: string) => {
    // Change search params
    searchParams.set('category', category);
    setSearchParams(searchParams);
  };

  const onSort = useOnSort({ url, setUrl });

  const sideBarFilters = useSideBarFilters({
    optionsForFilter,
    setOptionsForFilters,
    facility,
    category: stepSelected?.id ?? 'heat',
    open
  });

  if (!facility) {
    return null;
  }
  return (
    <section className='facility-detail'>
      <div className='facility-detail__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('measureMain.title')}</h3>
        <Breadcrumb crumbsReplace={id ? [{ key: id, value: facility.name }] : []} />
      </div>
      <FiltersContext.Provider
        value={{
          setFilters,
          filters: filters,
          setOpen,
          open,
          removeFilters: setRemoveFilters,
          remove,
          dates,
          setDates,
          clearDates,
          setClearDates
        }}>
        <Filters>
          <FiltersDate />
          {sideBarFilters?.map(
            (section, idx) =>
              section.options?.length > 0 && (
                <FilterSection
                  options={section.options}
                  title={section.title}
                  elToShow={section.elToShow}
                  key={`section-${idx}`}
                />
              )
          )}
        </Filters>
        <CardCTA>
          <CardCTA.Header>
            <span className='headline4-font'>
              {facility.status !== 'archived'
                ? t('facilityDetail.start')
                : t('facilityDetail.archivedFacility')}
            </span>
            <span className='subtitle3-font'>
              {facility.status !== 'archived'
                ? t('facilityDetail.startDescription')
                : t('facilityDetail.archivedFacilityDescription')}
            </span>
          </CardCTA.Header>
          <CardCTA.Buttons>
            {facility.status !== 'archived' ? (
              <>
                <Button
                  lookAndFeel={checkFreePlan(foundOrganization) ? 'blocked' : 'primary'}
                  text={t('facilityDetail.uploadInvoice')}
                  size='small'
                  onClick={newAutoInvoice}
                  disabled={loading}
                />
                <Button
                  lookAndFeel='secondary'
                  text={t('facilityDetail.uploadManual')}
                  size='small'
                  onClick={newManualInvoice}
                  disabled={loading}
                  style={{ width: '7%' }}
                />
              </>
            ) : (
              <Button
                lookAndFeel='primary'
                text={t('facilityDetail.dearchiveFacility')}
                iconNode={<Icon icon='locked' color='white' />}
                size='small'
                onClick={handleDearchiveFacility}
                disabled={loading}
                loading={loadingButton}
              />
            )}
          </CardCTA.Buttons>
        </CardCTA>

        {steps.length > 1 && (
          <StepCarouselList
            steps={steps}
            handleSelect={handleChangeInvoiceCategory}
            lookAndFeel={'big'}
            style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
          />
        )}
        {loading && <LoaderTables />}
        {stepSelected?.id && (
          <InfiniteList
            fetchData={fetchData}
            data={parseData(invoices)}
            columns={columns}
            loading={loadingTable}
            firstLoading={firstLoading}
            total={totalGeneral}
            rowClassName={(record) => getClassName(record)}
            header={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  {/* distinto buscador si es residuos */}
                  {stepSelected?.id === 'wastes' ? (
                    <FormText
                      icon={'/images/icons/search.svg'}
                      placeholder={t(`facilityDetail.inputFilterWastes`)}
                      onChange={(e: { target: { value: string } }) => {
                        setSearchValue(e.target.value);
                      }}
                      value={searchValue}
                      size={InputSize.SMALL}
                    />
                  ) : (
                    <FormText
                      icon={'/images/icons/search.svg'}
                      placeholder={t(`facilityDetail.inputFilter`)}
                      onChange={(e: { target: { value: string } }) => {
                        setSearchValue(e.target.value);
                      }}
                      value={searchValue}
                      size={InputSize.SMALL}
                    />
                  )}

                  <Sorting
                    options={sortingOptions}
                    label={t('input.sorting.orderBy')}
                    placeholder={t('input.placeholderSelect')}
                    onSort={onSort}
                    defaultSort={defaultSort}
                  />
                  <FiltersHandlers />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  {flags?.dataValidation &&
                  stepSelected?.id !== 'wastes' &&
                  stepSelected?.id !== 'water' ? (
                    <Button
                      lookAndFeel='primary'
                      text={t('validation.validate')}
                      size='small'
                      onClick={() => setShowValidationModal(true)}
                    />
                  ) : null}
                  <TotalLegend
                    total={total}
                    total2={totalErrors}
                    loading={loadingTable}
                    i18key={
                      stepSelected?.id === 'wastes'
                        ? 'facilityDetail.wastesFiles'
                        : 'facilityDetail'
                    }
                  />
                </div>
              </div>
            }
          />
        )}
      </FiltersContext.Provider>
      <Modal.WithPortal
        show={invoiceMode === 'manual'}
        onClose={onCloseModal}
        width='700px'
        maxWidth={'700px'}>
        <CreateInvoice
          switchToAuto={switchToAuto}
          addInvoice={addInvoice}
          facility={facility}
          defaultType={stepSelected?.id}
          onClose={onCloseModal}
        />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={invoiceMode === 'auto'}
        onClose={onCloseModal}
        width='584px'
        maxWidth='1200px'>
        <UploadInvoices
          setTotal={setTotal}
          setTotalPending={setTotalErrors}
          setInvoiceMode={setInvoiceMode}
          addInvoice={addInvoicesAuto}
          facility={facility}
          defaultType={stepSelected?.id}
        />
      </Modal.WithPortal>
      {invoiceToEdit?.type === 'wastes' && (
        <Modal show={!!invoiceToEdit} onClose={onCloseModal} width={'685px'} maxWidth={'685px'}>
          <FormHeader
            title={t('facilityDetail.editInvoice')}
            description={t('facilityDetail.editInvoiceDescription')}
          />
          <Waste editInvoice={editInvoice} facility={facility} id={invoiceToEdit.id} />
        </Modal>
      )}
      {invoiceToEdit?.type === 'waste_water_treatment' && (
        <Modal.WithPortal
          show={!!invoiceToEdit}
          onClose={onCloseModal}
          width={'685px'}
          maxWidth={'685px'}>
          <FormHeader
            title={t('facilityDetail.editInvoice')}
            description={t('facilityDetail.editInvoiceDescription')}
          />
          <Treatment
            editInvoice={editInvoice}
            facilityId={params.id ?? ''}
            id={invoiceToEdit.id}
            onClose={onCloseModal}
          />
        </Modal.WithPortal>
      )}
      <Modal
        show={
          !!invoiceToEdit &&
          invoiceToEdit?.type !== 'wastes' &&
          invoiceToEdit?.type !== 'waste_water_treatment'
        }
        onClose={onCloseModal}
        width={'1100px'}
        maxWidth={'1100px'}>
        {invoiceToEdit && (
          <EditInvoice
            facility={facility}
            editInvoice={editInvoice}
            invoiceToEdit={invoiceToEdit}
          />
        )}
      </Modal>
      <Modal show={!!invoiceToDelete} onClose={onCloseModal} maxWidth='1025px' width='1025px'>
        {invoiceToDelete && (
          <DeleteInvoice
            user={user}
            deleteInvoice={deleteInvoice}
            invoiceToDelete={invoiceToDelete}
            columns={columns}
            parseData={parseData}
            closeModal={onCloseModal}
          />
        )}
      </Modal>
      <Modal
        show={showValidationModal}
        onClose={() => setShowValidationModal(false)}
        width='900px'
        maxWidth='900px'>
        <Validation
          category={ValidationCategory.FACILITIES}
          entity={facility.name}
          entityId={params.id ?? ''}
          subcategories={facility.categories}
        />
      </Modal>
      <Modal
        show={showLimitCompleted}
        onClose={() => setShowLimitCompleted(false)}
        maxWidth='484px'>
        <LimitCompleted handleClose={() => setShowLimitCompleted(false)} />
      </Modal>
    </section>
  );
}

export default FacilityDetail;
