import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../context/userContext';
import { useFeatureFlags } from '../../../../../customHooks/useFeatureFlags';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import { getWasteWaterLineValues } from 'services/api/treatment';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import SlideToggle from '../../../../ui/formComponents/slideToggle/SlideToggle';
import FormSelect from '../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import { TreatmentPlantForm } from '../EditCreateFacility';
import { getCountries } from 'constants/phonePrefixes';

type Props = {
  bodyForm: TreatmentPlantForm;
  setFormData: React.Dispatch<any>;
  mode: 'create' | 'edit';
};

const WasteWaterTreatmentForm = ({ bodyForm, setFormData, mode }: Props) => {
  const { t, i18n } = useTranslation();
  const flags = useFeatureFlags();

  const user = useContext(UserContext);
  const { onChangeValue } = useOnChangeValue({ setFormData });
  const [waterLineOptions, setWaterLineOptions] = useState<SelectOptionFormat[]>([]);
  const [sludgeLineOptions, setSludgeLineOptions] = useState<SelectOptionFormat[]>([]);
  const [waterDischargeLineOptions, setWaterDischargeLineOptions] = useState<SelectOptionFormat[]>(
    []
  );

  useEffect(() => {
    const getLineOptions = async () => {
      if (!user?.selectedOrganization) return;
      const response = await getWasteWaterLineValues({
        organization_id: user?.selectedOrganization
      });
      if (response?.status >= 400) return;
      // Filter and map the data for each category
      const waterLines = response
        .filter((value: WWLineValues) => value.line_type === 'water_treatment_line')
        .map((value: WWLineValues) => ({
          id: value.id,
          name: t(`wwLineValues.${value.line_code}`)
        }));

      const sludgeLines = response
        .filter((value: WWLineValues) => value.line_type === 'sludge_treatment_line')
        .map((value: WWLineValues) => ({
          id: value.id,
          name: t(`wwLineValues.${value.line_code}`)
        }));

      const dischargeLines = response
        .filter((value: WWLineValues) => value.line_type === 'discharge')
        .map((value: WWLineValues) => ({
          id: value.id,
          name: t(`wwLineValues.${value.line_code}`)
        }));

      // Set the options for each select input
      setWaterLineOptions(waterLines);
      setSludgeLineOptions(sludgeLines);
      setWaterDischargeLineOptions(dischargeLines);
    };

    getLineOptions();
  }, []);
  return (
    <FormWrapper>
      <FormSelect
        iconV2='earth'
        value={bodyForm.country}
        onChange={mode === 'create' ? onChangeValue('country') : () => null}
        label={t(`facilityDetail.country`)}
        placeholder={t(`facilityDetail.writeCountry`)}
        options={getCountries(i18n.resolvedLanguage, i18n.resolvedLanguage.toUpperCase())}
        disabled={mode !== 'create'}
        error={bodyForm.errors.find((error) => error.error === 'country')}
      />
      <FormSelect
        icon={'/images/icons/wave.svg'}
        placeholder={t('facilities.writeFacilityWaterLine')}
        label={t('facilities.waterLine')}
        value={bodyForm.waterLine}
        onChange={onChangeValue('waterLine')}
        options={waterLineOptions}
        error={bodyForm?.errors?.find((elem) => elem.error === 'waterLine')}
        sort={false}
        disabled={mode !== 'create'}
      />
      <FormSelect
        icon={'/images/icons/wave.svg'}
        placeholder={t('facilities.writeFacilitySludgeLine')}
        label={t('facilities.sludgeLine')}
        value={bodyForm.sludgeLine}
        onChange={onChangeValue('sludgeLine')}
        options={sludgeLineOptions}
        error={bodyForm?.errors?.find((elem) => elem.error === 'sludgeLine')}
        sort={false}
        disabled={mode !== 'create'}
      />
      <FormSelect
        icon={'/images/icons/wave.svg'}
        placeholder={t('facilities.writeFacilityWaterDischargeLine')}
        label={t('facilities.waterDischargeLine')}
        value={bodyForm.waterDischargeLine}
        onChange={onChangeValue('waterDischargeLine')}
        options={waterDischargeLineOptions}
        error={bodyForm?.errors?.find((elem) => elem.error === 'waterDischargeLine')}
        sort={false}
        disabled={mode !== 'create'}
      />
      {flags?.methaneBurn && (
        <div className='toggle-element'>
          <SlideToggle
            label={t('facilities.methaneBurn')}
            checked={bodyForm.methaneBurn}
            setChecked={mode === 'create' ? onChangeValue('methaneBurn') : () => null}
          />
        </div>
      )}
    </FormWrapper>
  );
};

export default WasteWaterTreatmentForm;
