import { useEffect } from 'react';

const buttonOpenSelector = {
  primary: { small: '.extra-scope-91j9gp', medium: '.extra-scope-1f4az75', large: '', fit: '' },
  secondary: { small: '.extra-scope-kj2xyq', medium: '.extra-scope-uelwtj', large: '', fit: '' },
  link: { small: '.extra-scope-1oncxb7', medium: '', large: '', fit: '' }
};

type Props = {
  onClick: () => void;
  lookAndFeel?: 'primary' | 'secondary' | 'link';
  size?: 'small' | 'medium' | 'large' | 'fit';
};

const useNuvoButton = ({ onClick, lookAndFeel = 'primary', size = 'small' }: Props) => {
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const buttonOpen = document.querySelector(buttonOpenSelector[lookAndFeel][size]);

      if (!buttonOpen) return;

      buttonOpen.addEventListener('click', onClick, true);
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);
};

export default useNuvoButton;
