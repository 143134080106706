import { Employee } from 'types/entities/employee';
import { IconName } from 'types/utils/iconName';
import { IconColor } from 'components/ui/IconBase/IconBase';
import { VARIANTS } from 'components/ui/label/Label';
import { EmployeeModals, FIND_EMPLOYEES, FIND_PERIODS } from '../constants';
import { isInNarrowedArray } from 'utils/arrays';

type ObjectStatus = {
  icon: IconName;
  color: keyof typeof VARIANTS;
  textColor: IconColor;
};

export const transformDateBackend = (originalDate: string) => {
  const dateParts = originalDate.split('/');
  const day = Number(dateParts[0]);
  const month = Number(dateParts[1]);
  const year = Number(dateParts[2]);

  const date = new Date(year, month - 1, day);

  const formattedYear = date.getFullYear();
  const formattedMonth = ('0' + (date.getMonth() + 1)).slice(-2);
  const formattedDay = ('0' + date.getDate()).slice(-2);

  return formattedYear + '-' + formattedMonth + '-' + formattedDay;
};

export const renderStatus = (status: string): ObjectStatus => {
  const statusMap: Record<string, ObjectStatus> = {
    default: {
      icon: 'clock',
      color: 'tertiary',
      textColor: 'blocked'
    },
    info: {
      icon: 'information',
      color: 'secondary',
      textColor: 'blocked'
    },
    pending: {
      icon: 'clock',
      color: 'secondary',
      textColor: 'blocked'
    },
    loading: {
      icon: 'clock',
      color: 'secondary',
      textColor: 'blocked'
    },
    blocked: {
      icon: 'error',
      color: 'disabled',
      textColor: 'disabled'
    },
    active: {
      icon: 'success',
      color: 'success',
      textColor: 'success'
    },
    inactive: {
      icon: 'error',
      color: 'disabled',
      textColor: 'disabled'
    },
    terminated: {
      icon: 'error',
      color: 'disabled',
      textColor: 'disabled'
    }
  };

  const statusObject: ObjectStatus = statusMap[status] || statusMap['default'];

  return statusObject;
};

export const findEmployeeData = (
  employees: Employee[],
  payload?: string,
  modalId?: EmployeeModals
) => {
  if (!payload || !modalId) {
    return { employee: undefined, period: undefined };
  }

  if (isInNarrowedArray(modalId, FIND_EMPLOYEES)) {
    const employee = employees.find((employee) => employee.id === payload);

    return { employee, period: undefined };
  }

  if (isInNarrowedArray(modalId, FIND_PERIODS)) {
    const employee = employees.find((employee) =>
      employee.periods.some((period) => period.id === payload)
    );

    const period = employee?.periods.find((period) => period.id === payload);

    return { employee, period };
  }

  return { employee: undefined, period: undefined };
};
