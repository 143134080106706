import moment from 'moment';
import { FormDataTreatment } from '../../components/measure/facilities/facilityDetail/components/createInvoice/components/treatment/Treatment';
import apiFetch from '../../utils/apiFetch';

export const getTreatmentById = async (id: string, organization_id: string) => {
  try {
    const response = await apiFetch('GET', `/waste_water_treatments/${id}`, null, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

type CreateTreatmentBody = {
  name: string;
  m3_water_in: number;
  kg_bod_per_m3_wwt_line: number;
  kg_bod_per_kg_sludge_line: number;
  kg_sludge: number;
  kg_r_wwt_line: number;
  kg_r_sludge_line: number;
  kg_n_per_m3_wwt_line: number;
  kg_n_per_m3_wwd_line: number;
  kg_bod_per_m3_wwd_line: number;
  m3_water_out: number;
  file_url: string;
  start_date: string;
  end_date: string;
  facility_id: string;
};

export const postTreatment = async (
  body: FormDataTreatment,
  organization_id: string,
  facility_id: string
) => {
  const bodyFormated: CreateTreatmentBody = {
    file_url: body.fileUrl,
    name: body.name,
    m3_water_in: Number(body.m3WaterIn),
    kg_bod_per_m3_wwt_line: Number(body.kgBodPerM3WwtLine),
    kg_bod_per_kg_sludge_line: Number(body.kgBodPerKgSludgeLine),
    kg_sludge: Number(body.kgSludge),
    kg_r_wwt_line: Number(body.kgRwwtLine) || 0,
    kg_r_sludge_line: Number(body.kgRSludgeLine) || 0,
    kg_n_per_m3_wwt_line: Number(body.kgNPerM3WwtLine),
    kg_n_per_m3_wwd_line: Number(body.kgNPerM3WwdLine),
    kg_bod_per_m3_wwd_line: Number(body.kgBodPerM3WwdLine),
    m3_water_out: Number(body.m3WaterOut),
    start_date: moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    end_date: moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    facility_id: body.facilityId
  };

  try {
    const response = await apiFetch('POST', `/waste_water_treatments`, bodyFormated, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

type EditTreatmentBody = {
  file_url: string;
  name: string;
  m3_water_in: number;
  kg_bod_per_m3_wwt_line: number;
  kg_bod_per_kg_sludge_line: number;
  kg_sludge: number;
  kg_r_wwt_line: number;
  kg_r_sludge_line: number;
  kg_n_per_m3_wwt_line: number;
  kg_n_per_m3_wwd_line: number;
  kg_bod_per_m3_wwd_line: number;
  m3_water_out: number;
  start_date: string;
  end_date: string;
  status: string;
};

export const patchTreatment = async (
  id: string,
  body: FormDataTreatment,
  organization_id: string
) => {
  const bodyFormated: EditTreatmentBody = {
    file_url: body.fileUrl,
    name: body.name,
    m3_water_in: Number(body.m3WaterIn),
    kg_bod_per_m3_wwt_line: Number(body.kgBodPerM3WwtLine),
    kg_bod_per_kg_sludge_line: Number(body.kgBodPerKgSludgeLine),
    kg_sludge: Number(body.kgSludge),
    kg_r_wwt_line: Number(body.kgRwwtLine),
    kg_r_sludge_line: Number(body.kgRSludgeLine),
    kg_n_per_m3_wwt_line: Number(body.kgNPerM3WwtLine),
    kg_n_per_m3_wwd_line: Number(body.kgNPerM3WwdLine),
    kg_bod_per_m3_wwd_line: Number(body.kgBodPerM3WwdLine),
    m3_water_out: Number(body.m3WaterOut),
    start_date: moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    end_date: moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    status: body.status
  };

  try {
    const response = await apiFetch('PATCH', `/waste_water_treatments/${id}`, bodyFormated, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteTreatment = async (id: string, organization_id: string) => {
  try {
    const response = await apiFetch('DELETE', `/waste_water_treatments/${id}`, null, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

type WWLineValuesBody = {
  organization_id: string;
};

export const getWasteWaterLineValues = async ({ organization_id }: WWLineValuesBody) => {
  try {
    const response = await apiFetch('GET', `/waste_water_treatments/ww_facility_lines`, null, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
