import formatNumber from '../../../../utils/formatNumber';
import StackedBarVertical from '../../report/bar/StackedBarVertical';
import './styles.scss';

type Props = {
  value: number;
  total: number;
  name: string;
  image: {
    src: string;
    alt: string;
  };
};

const BarReport = ({ value, total, name, image }: Props) => {
  return (
    <div className='vertical-bar-wrapper'>
      <span className='body1-font'>{formatNumber(value / 1000)}</span>

      <StackedBarVertical
        total={total}
        data={{
          value,
          color:
            'linear-gradient(180deg, rgba(56, 207, 217, 0.455) 0%, rgba(41, 69, 255, 0.5) 100%)'
        }}
      />
      <div className='icon-text-wrapper'>
        <img src={image.src} alt={image.alt} />
        <span className='name-logo body2-font'>{name}</span>
      </div>
    </div>
  );
};

export default BarReport;
