import { LCACard } from 'components/lca/Portfolio/LCADetail/components/LCACard';
import DisabledLabel from 'components/ui/statusLabels/disabledLabel/DisabledLabel';
import WarningLabel from 'components/ui/statusLabels/warningLabel/WarningLabel';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import { ROUTES } from 'constants/routes';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import { Link } from 'react-router-dom';
import { limitString } from 'utils/limitString';
import uuid from 'uuid';
import { getStatus, makeTags } from '../utils';
import { useCountryName } from 'customHooks/translations/useCountryName';
import { ICON_MAP } from '../MaterialDetail/constants';

type Props = {
  material: LCAMaterial;
};

const DEFAULT_DELAY = 1000;

export const MaterialCard: React.FC<Props> = ({ material }) => {
  const { t } = useLCATranslation();
  const { t: tCountry } = useCountryName();

  const disabled = !material.impact_source_id;
  const tags = makeTags(material, tCountry);
  const status = getStatus(material);

  return (
    <Link to={`${ROUTES.LCA_MATERIAL_MANAGEMENT}/${material.id}`}>
      <LCACard.Layout>
        <LCACard.Content>
          <LCACard.Icon icon={ICON_MAP[material.type]} lookAndFeel='material' disabled={disabled} />
          <div className='flex-col gap-y-2'>
            <h4 className='font-body-b1-b m-0'>{material.name}</h4>
            <div className='flex gap-x-1'>
              {disabled && (
                <div className='pr-2 border-r-solid border-r-1 border-r-neutral-gray-warm-60 mr-1'>
                  <TooltipWrapper
                    text={t('materialManagement.disabledMaterialTooltip')}
                    delay={DEFAULT_DELAY}>
                    <DisabledLabel icon='clock'>
                      {t('materialManagement.tags.disabled')}
                    </DisabledLabel>
                  </TooltipWrapper>
                </div>
              )}
              {!disabled && status === 'needs_match' && (
                <div className='pr-2 border-r-solid border-r-1 border-r-neutral-gray-warm-60 mr-1'>
                  <TooltipWrapper
                    text={t('materialManagement.needsMatchTooltip')}
                    delay={DEFAULT_DELAY}>
                    <WarningLabel icon='warning'>
                      {t('materialManagement.tags.needsMatch')}
                    </WarningLabel>
                  </TooltipWrapper>
                </div>
              )}
              {tags.map((tag) => (
                <TooltipWrapper
                  text={tag.tooltip}
                  key={uuid.v4()}
                  shouldAppear={Boolean(tag.tooltip)}
                  delay={DEFAULT_DELAY}>
                  <LCACard.Tag title={tag.title}>{limitString(tag.text, 16)}</LCACard.Tag>
                </TooltipWrapper>
              ))}
            </div>
          </div>
        </LCACard.Content>
      </LCACard.Layout>
    </Link>
  );
};
