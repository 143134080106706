import { useSelector } from 'react-redux';
import { PURCHASES_ACTIONS } from '../actions/types';
import { PurchasesType } from '../types/purchasesLoader';
import { RootState } from 'legacyStore';

const initialState: PurchasesType = {
  isLoading: false
};

type PurchaseActions = {
  type: keyof typeof PURCHASES_ACTIONS;
  payload: PurchasesType;
};

export const purchasesLoader = (
  state: PurchasesType = initialState,
  action: PurchaseActions
): PurchasesType => {
  const { type } = action;

  switch (type) {
    case 'START_LOADING_PURCHASES':
      return { isLoading: true };
    case 'STOP_LOADING_PURCHASES':
      return initialState;
    default:
      return state;
  }
};

export const usePurchasesLoader = () => {
  return useSelector<RootState, boolean>((state) => state.purchasesLoader.isLoading);
};
