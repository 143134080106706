import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMutateCache } from 'lib/ReactQuery/hooks';
import { updateMaterialUnits } from 'services/api/lca/materialManagement';

export const useUpdateMaterialUnits = () => {
  const { editInList } = useMutateCache(['lca-materials']);

  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: updateMaterialUnits,
    onSuccess: (data) => {
      editInList(
        {
          id: data.piece_id,
          unit_list: data.units.map(({ unit_id, unit_name, value }) => ({
            unit_id,
            unit_name,
            value
          }))
        },
        'id'
      );

      queryClient.setQueryData<LCAOfficialMaterial>(
        ['lca-official-material', data.piece_id],
        (oldData) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            unit_list: data.units.map(({ unit_id, unit_name, value }) => ({
              unit_id,
              unit_name,
              value
            }))
          };
        }
      );
    }
  });

  return { updateMaterialUnits: mutateAsync, isPending };
};
