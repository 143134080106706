import DisabledLabel from 'components/ui/statusLabels/disabledLabel/DisabledLabel';
import ErrorLabel from 'components/ui/statusLabels/errorLabel/ErrorLabel';
import SuccessLabel from 'components/ui/statusLabels/successLabel/SuccessLabel';
import WarningLabel from 'components/ui/statusLabels/warningLabel/WarningLabel';
import { TFunction } from 'i18next';
import { IconName } from 'types/utils/iconName';

const dateRenderer = (date: string, submitted: boolean, t: TFunction, icon?: IconName) => {
  // Check now date. if date is in the past and submitted is false, show error label
  // if date is in the past and submitted is true, show success label
  // if date is in the future, in the next 3 months, show warning label
  // if date is in the future, more than 3 months, show disabled label
  // Parse the date string (expected format: DD-MM-YYYY)
  const [day, month, year] = date.split('-'); // Split by dashes
  const targetDate = new Date(Number(year), Number(month) - 1, Number(day)); // Month is 0-based
  const now = new Date();
  const diffTime = targetDate.getTime() - now.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const commonProps = {
    showIcon: !!icon,
    ...(icon && { icon }) // Spread the icon prop only if it's defined
  };

  if (diffDays < 0) {
    return submitted ? (
      <SuccessLabel {...commonProps}>
        {t('submitted')} {date}
      </SuccessLabel>
    ) : (
      <ErrorLabel {...commonProps}>
        {t('overdue')} {date}
      </ErrorLabel>
    );
  } else if (diffDays / 30 <= 3) {
    return (
      <WarningLabel {...commonProps}>
        {t('deadline')} {date}
      </WarningLabel>
    );
  } else {
    return (
      <DisabledLabel {...commonProps}>
        {t('deadline')} {date}
      </DisabledLabel>
    );
  }
};
export default dateRenderer;
