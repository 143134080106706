import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../../constants/routes';
import { UserContext } from '../../../../../../context/userContext';
import { useFeatureFlags } from '../../../../../../customHooks/useFeatureFlags';
import useGetBase64 from '../../../../../../customHooks/useGetBase64';
import useOnChangeValue from '../../../../../../customHooks/useOnChangeValue';
import { updateInvoice } from '../../../../../../services/api/invoices';
import { getUnits, getUnitsByCountry } from '../../../../../../services/api/unit';
import { Facility } from '../../../../../../types/entities/facility';
import { FacilityFuelType } from '../../../../../../types/entities/facilityFuel';
import { InvoiceType, Percentages } from '../../../../../../types/entities/invoice';
import { InvoiceSource } from '../../../../../../types/entitiesEnums/invoice';
import { InputSize } from '../../../../../../types/utilsEnums/input';
import checkFormErrors from '../../../../../../utils/checkFormErrors';
import { convertDateToString } from '../../../../../../utils/convertDates';
import Button from '../../../../../ui/button/Button';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../../ui/formComponents/formWrapper/FormWrapper';
import InputFileInvoice from '../../../../../ui/formComponents/inputFile/InputFileInvoice';
import FormCalendarDouble from '../../../../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';
import FormSelect from '../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormText from '../../../../../ui/formComponents2/formInputs/formText/FormText';
import InputNumber from '../../../../../ui/formComponents2/inputNumber/InputNumber';
import InputWrapper from '../../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from '../../../../../ui/formComponents2/select/Select';
import CustomSkeletonLoader from '../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import DocumentPDFV2 from '../../../../../ui/pdf/DocumentPDFV2';
import StepCarouselList from '../../../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../../../ui/stepCarouselList/useStepCarousel';
import Tag from '../../../../../ui/tag/Tag';
import TooltipWrapper from '../../../../../ui/tooltip/TooltipWrapper';
import useConstants from '../../../../logistics/components/shipment/hooks/useConstants';
import DivideConsumptionsWrapper from '../createInvoice/components/common/divideConsumptions/DivideConsumptionsWrapper';
import { parseQuantity, parseUnit } from '../createInvoice/components/common/utils';
import {
  validateDates,
  validateFacilityWithZero,
  validateFacilityWithoutId
} from '../createInvoice/components/validations/validator';
import useGetData from '../createInvoice/hooks/useGetData';
import './styles.scss';

type FormData = {
  type: string;
  emissionFactor: string;
  supplier: SelectOptionFormat;
  facilityFuel: SelectOptionFormat;
  unitDefault: SelectOptionFormat;
  unitCustom: SelectOptionFormat;
  quantity: string;
  invoiceId: string;
  startDate: string;
  endDate: string;
  cups: string;
  file_url?: string;
  toc?: SelectOptionFormat;
  percentages: Percentages[];
  errors: ErrorType[];
};

type Props = {
  facility: Facility;
  invoiceToEdit: InvoiceType;
  editInvoice: (id: string, invoice: InvoiceType) => void;
};

const today = new Date();
today.setHours(0, 0, 0, 0);
today.setDate(today.getDate());

const aMonthAgo = new Date();
aMonthAgo.setMonth(aMonthAgo.getMonth() - 1);
aMonthAgo.setHours(0, 0, 0, 0);

const EditInvoice = ({ facility, invoiceToEdit, editInvoice }: Props) => {
  const { t } = useTranslation();

  const user = useContext(UserContext);
  const flags = useFeatureFlags();
  const { tocsSuperchargerHub } = useConstants();

  const navigate = useNavigate();

  const isSuperchargerHub = facility.supercharger;

  // STATES
  const [loadingButton, setLoadingButton] = useState(false);
  const { stepSelected, handleSelect, steps } = useStepCarousel({
    stepsText: [
      {
        id: 'default',
        text: t('facilityDetail.default')
      },
      {
        id: 'custom',
        text: t('facilityDetail.custom')
      }
    ],
    selected: invoiceToEdit?.custom_emission_factor_id ? 'custom' : 'default'
  });

  const [formData, setFormData] = useState<FormData>({
    type: invoiceToEdit.type || '',
    // toc: invoiceToEdit.toc
    //   ? { id: invoiceToEdit.toc, name: t(`logistics.tocs.${invoiceToEdit.toc}`) }
    //   : { id: '', name: '' },
    emissionFactor: invoiceToEdit.custom_emission_factor_id || '',
    supplier: invoiceToEdit.supplier
      ? { id: invoiceToEdit.supplier.id, name: invoiceToEdit.supplier.name }
      : { id: '', name: '' },
    facilityFuel: invoiceToEdit.facility_fuel
      ? {
          id: invoiceToEdit.facility_fuel.id,
          name: t(`facility_fuels.${invoiceToEdit.facility_fuel.name}`)
        }
      : { id: '', name: '' },
    unitCustom:
      invoiceToEdit.custom_emission_factor_id && invoiceToEdit.unit
        ? {
            id: invoiceToEdit.unit.id,
            name: t(`units.${invoiceToEdit.unit.name}`)
          }
        : { id: '', name: '' },
    unitDefault:
      !invoiceToEdit?.custom_emission_factor_id && invoiceToEdit.unit
        ? {
            id: invoiceToEdit.unit.id,
            name: t(`units.${invoiceToEdit.unit.name}`)
          }
        : { id: '', name: '' },
    file_url: invoiceToEdit.file_url ?? undefined,
    quantity:
      invoiceToEdit && invoiceToEdit.base_quantity >= 0
        ? invoiceToEdit.base_quantity.toString()
        : '',
    invoiceId: invoiceToEdit.invoice_id || '',
    cups: invoiceToEdit.cups || '',
    startDate: invoiceToEdit.start_date
      ? moment(invoiceToEdit.start_date).format('DD/MM/YYYY')
      : moment(aMonthAgo).format('DD/MM/YYYY'),
    endDate: invoiceToEdit.end_date
      ? moment(invoiceToEdit.end_date).format('DD/MM/YYYY')
      : moment(today).format('DD/MM/YYYY'),
    percentages: invoiceToEdit.facility_percentages.map((elem) => {
      return {
        facility: { id: elem.facility_id, name: elem.facility_name ?? '' },
        organization: {
          id: elem.organization_id,
          name: elem.company_name ?? ''
        },
        percentage: (elem.percentage * 100).toString()
      };
    }),

    errors: []
  });
  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData });
  const { suppliers, facilityFuels, groups, loadingData, organizationOptions } = useGetData({
    type: invoiceToEdit.type,
    country: facility.country,
    facilityId: facility.id
  });
  // const [renewable, setRenewable] = useState(
  //   invoiceToEdit.co2e === 0 && invoiceToEdit.type === 'electricity'
  // );
  const [units, setUnits] = useState<SelectOptionFormat[]>([]);
  const [unitsCustom, setUnitsCustom] = useState<SelectOptionFormat[]>([]);
  const [groupSelected, setGroupSelected] = useState<SelectOptionFormat>({
    id: '',
    name: ''
  });
  const [fileInvoiceToEdit, setFileInvoiceToEdit] = useState<File | string>('');
  const fileRead = useGetBase64(fileInvoiceToEdit as File);
  const groupsFiltered = groups.filter((group) => group.category === formData.type);

  useEffect(() => {
    (() => {
      if (!(formData?.facilityFuel as SelectOptionFormat).id) return;

      const facilityFuel = facilityFuels?.find(
        (elem) => elem.id === (formData?.facilityFuel as SelectOptionFormat).id
      );
      setUnits(facilityFuel?.units ?? []);
    })();
  }, [formData.facilityFuel, facilityFuels]);

  // get exactly cubic_metre_(m3) and kilowatt_hour_(kwh) (should be refactor)
  useEffect(() => {
    const getUnitWater = async () => {
      if (formData.type !== 'water') return;
      const data = await getUnits({ type: 'gas' });
      if (data?.response?.status >= 400) return;
      setUnits(data.filter((elem: Unit) => elem.name === 'cubic_metre_(m3)'));
    };

    const getUnitElectricity = async () => {
      if (formData.type !== 'electricity') return;
      const dataElectricity = await getUnits({ type: 'energy' });
      // Call endpoint that returns units of currency given a country
      const dataCurrency = await getUnitsByCountry(facility.country);
      if (dataElectricity?.response?.status >= 400) return;
      const data = [...dataElectricity];
      if (dataCurrency && dataCurrency.id) {
        data.push(dataCurrency);
      }

      const units = data.filter(
        (elem: Unit) =>
          elem.name === 'kilowatt_hour_(kwh)' ||
          (flags?.eurosInInvoices && elem.type === 'fiat_currency')
      );

      setUnits(units);
      const currencyUnit = units.find((elem) => elem.type === 'fiat_currency');
      if (!currencyUnit) return;
      const quantityParsed = parseQuantity(invoiceToEdit, currencyUnit);
      const unitParsed = parseUnit(invoiceToEdit, currencyUnit);
      setFormData((prev) => ({
        ...prev,
        quantity: quantityParsed.toString(),
        unitDefault: {
          id:
            invoiceToEdit.original_unit_id === currencyUnit.id
              ? currencyUnit.id
              : invoiceToEdit.unit.id,
          name: t(`units.${unitParsed}`)
        }
      }));
    };

    getUnitWater();
    getUnitElectricity();
  }, [formData.type]);

  useEffect(() => {
    const newGroupSelected = groups.find(
      (elem) => elem.group_id === invoiceToEdit.custom_emission_factor_id
    );
    setGroupSelected({
      id: newGroupSelected?.group_id ?? '',
      name: newGroupSelected?.group_name ?? ''
    });
    if (newGroupSelected?.group_id) handleSelect('custom');
    setUnitsCustom(
      newGroupSelected?.emission_factors?.map((elem) => ({
        id: elem?.unit?.id ?? '',
        name: elem?.unit?.name ?? ''
      })) ?? []
    );
  }, [groups]);

  const cleanErrors = (key?: string) => {
    setFormData((prev) => {
      const newErrors = key ? prev.errors.filter((elem) => elem.error !== key) : [];
      return { ...prev, errors: newErrors };
    });
  };

  const onChangeFacilityFuel = (value: SelectOptionFormat) => {
    cleanErrors('facilityFuel');
    setFormData({ ...formData, facilityFuel: value, unitDefault: { id: '', name: '' } });
    const foundFacilityFuel = facilityFuels?.find((elem: FacilityFuelType) => elem.id === value.id);
    if (!foundFacilityFuel) return;
    setUnits(foundFacilityFuel.units);
  };

  const onSelectGroup = (newGroup: SelectOptionFormat) => {
    setGroupSelected(newGroup);
    const groupToGet = groups.find((group) => group.group_id === newGroup.id);
    const units: SelectOptionFormat[] =
      groupToGet?.emission_factors.map((elem) => ({
        id: elem?.unit?.id ?? '',
        name: elem?.unit?.name ?? ''
      })) ?? [];
    setUnitsCustom(units?.at(0) ? [units[0]] : []);
    setFormData((prev) => ({
      ...prev,
      emissionFactor: groupToGet?.group_id ?? ''
    }));
  };

  const handleEditInvoice = async () => {
    try {
      const optionalFields = ['invoiceId', 'file_url', 'cups'];
      !isSuperchargerHub && optionalFields.push('toc');

      if (['heat', 'recharge', 'water'].includes(formData.type as string))
        optionalFields.push('supplier');
      if (['electricity', 'water'].includes(formData.type as string))
        optionalFields.push('facilityFuel');

      if (stepSelected?.id === 'default') {
        optionalFields.push('emissionFactor');
      }
      if (stepSelected?.id === 'custom') {
        optionalFields.push('facilityFuel');
        optionalFields.push('supplier');
      }

      // Do not check supplier if country is not Spain
      if (['electricity'].includes(formData.type as string) && facility.country !== 'ES') {
        optionalFields.push('supplier');
      }

      if (stepSelected?.id === 'custom') optionalFields.push('unitDefault');
      if (stepSelected?.id === 'default') optionalFields.push('unitCustom');

      // Check if there are errors in the form
      const newErrors = checkFormErrors(formData, formData.errors, optionalFields);

      if (stepSelected?.id === 'custom' && !groupSelected.id) {
        newErrors.push({
          error: 'emissionFactor'
        });
      }

      const dateError = validateDates(formData.startDate, formData.endDate);
      if (dateError) {
        newErrors.push(dateError);
      }

      const facilityWithZeroError = validateFacilityWithZero(formData.percentages);
      if (facilityWithZeroError) {
        newErrors.push(facilityWithZeroError);
      }

      const facilityWithoutIdError = validateFacilityWithoutId(formData.percentages);
      if (facilityWithoutIdError) {
        newErrors.push(facilityWithoutIdError);
      }

      if (newErrors.length > 0) {
        setFormData((prev) => ({
          ...prev,
          errors: newErrors
        }));
        return;
      }

      setLoadingButton(true);

      const parsedQuantity = parseFloat(formData.quantity as string);
      const parsedUnitId =
        stepSelected?.id === 'default'
          ? (formData.unitDefault as SelectOptionFormat).id
          : (formData.unitCustom as SelectOptionFormat).id;

      const invoice: any = {
        type: formData.type as string,
        custom_emission_factor_id: null,
        base_quantity: parseFloat(parsedQuantity.toFixed(2)),
        supplier_id: (formData.supplier as SelectOptionFormat).id || null,
        facility_fuel_id: (formData.facilityFuel as SelectOptionFormat).id || null,
        unit_id: parsedUnitId ?? null,
        quantity: parseFloat(parsedQuantity.toFixed(2)),
        invoice_id: (formData.invoiceId as string) ?? null,
        cups: (formData.cups as string) ? formData.cups : null,
        start_date: moment.utc(formData.startDate as string, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        end_date: moment.utc(formData.endDate as string, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        status: 'uploaded',
        uploaded_by: user?.id ?? null,
        facility_id: facility.id,
        organization_id: user?.selectedOrganization || '',
        renewable: false,
        file_url: invoiceToEdit.file_url ?? null,
        facility_percentages: formData.percentages.map(({ percentage, facility, organization }) => {
          return {
            facility_id: facility.id,
            organization_id: organization.id,
            percentage: (parseFloat(percentage) / 100).toFixed(4)
          };
        })
      };

      if (isSuperchargerHub) {
        invoice.toc = formData?.toc?.id;
      }

      if (fileInvoiceToEdit && fileInvoiceToEdit instanceof File)
        invoice.file_url = fileRead.fileBase64;

      handleEditInvoiceRequest(invoice);
    } catch (error) {
      setLoadingButton(false);
    }
  };

  const handleEditInvoiceRequest = async (invoice: any) => {
    try {
      let supplier;
      let facility_fuel;
      if (stepSelected?.id === 'custom') {
        invoice.custom_emission_factor_id = groupSelected.id;
        invoice.invoice = groupSelected.id;
        invoice.supplier_id = null;
        invoice.facility_fuel_id = null;
      }
      if (stepSelected?.id === 'default') {
        supplier = formData.supplier;
        facility_fuel = facilityFuels
          ? facilityFuels.find(
              (fuel) => fuel.id === (formData.facilityFuel as SelectOptionFormat).id
            )
          : null;
      }

      const data = await updateInvoice(invoiceToEdit.id, invoice);
      if (data) {
        editInvoice(invoiceToEdit.id, {
          ...data,
          supplier,
          co2e: undefined,
          facility_fuel,
          unit: units.find((elem) => elem.id === invoice.unit_id)
        });
      }

      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
    }
  };

  const handleUploadSingleFile = (fileUploaded: File) => {
    setFileInvoiceToEdit(fileUploaded);
  };

  const typeIconSelect = (type: string) => {
    const images = {
      heat: '/images/icons/fire50.svg',
      recharge: '/images/icons/velocimeter.svg',
      electricity: '/images/icons/thunder50.svg',
      water: '/images/icons/water.svg',
      waste_water_treatment: '/images/icons/road.svg',
      wastes: '/images/icons/waste.svg'
    };

    const src = images[type as keyof typeof images] || null;

    if (!src) return '';
    return src;
  };

  useEffect(() => {
    setFormData((prev) =>
      isSuperchargerHub
        ? {
            ...formData,
            toc: invoiceToEdit.toc
              ? { id: invoiceToEdit.toc, name: t(`logistics.tocs.${invoiceToEdit.toc}`) }
              : { id: '', name: '' }
          }
        : { ...prev }
    );
  }, [isSuperchargerHub]);

  const renderQuantityUnit = () => {
    const facilityFuel = formData.facilityFuel as SelectOptionFormat;
    const checkFacilityFuel = ['heat', 'recharge'];
    if (
      checkFacilityFuel.includes(formData.type as string) &&
      !facilityFuel.id &&
      stepSelected?.id === 'default'
    ) {
      return null;
    }
    return (
      <div className='input-element'>
        <InputWrapper
          icon={'/images/icons/plus.svg'}
          label={t('facilityDetail.quantity')}
          error={formData.errors.find(
            (elem) =>
              elem.error === 'quantity' ||
              elem.error === 'unitCustom' ||
              elem.error === 'unitDefault'
          )}>
          <InputNumber
            placeholder={t('facilityDetail.selectQuantity')}
            onChange={onChangeValue('quantity')}
            value={formData.quantity as string}
          />
          <Select
            placeholder={''}
            options={unitOptions.map((elem) => ({
              id: elem.id,
              name: t(`units.${elem.name}`)
            }))}
            value={
              stepSelected?.id === 'custom'
                ? (formData.unitCustom as SelectOptionFormat)
                : (formData.unitDefault as SelectOptionFormat)
            }
            onChange={
              stepSelected?.id === 'custom'
                ? onChangeValue('unitCustom')
                : onChangeValue('unitDefault')
            }
          />
        </InputWrapper>
        {/* {formData.type === 'electricity' && country !== 'ES' && stepSelected?.id === 'default' && (
          <div style={{ marginTop: '0.5rem' }}>
            <Checkbox
              selected={renewable}
              onChange={setRenewable}
              text={<span>{t('facilityDetail.renewableEnergy')}</span>}
            />
          </div>
        )} */}
      </div>
    );
  };

  const fileToShow = fileInvoiceToEdit ? fileInvoiceToEdit : invoiceToEdit.file_url;

  const documentPDF = fileToShow ? (
    <DocumentPDFV2 file={fileToShow} />
  ) : (
    <div className='edit-file-upload-wrapper'>
      <InputFileInvoice
        imgUrl='/images/icons/camera.svg'
        handleFile={handleUploadSingleFile}
        description={t('facilityDetail.uploadInvoiceToViewIt')}
      />
    </div>
  );

  const unitOptions = stepSelected?.id === 'custom' ? unitsCustom : units;

  const redirectCustomEmisionFactor = () => navigate(ROUTES.CUSTOM_EMISSION_FACTORS);

  const buttonTooltip =
    formData.percentages.reduce((acc, elem) => acc + parseFloat(elem.percentage), 0) === 0
      ? t('facilityDetail.noPercentageAssignedYet')
      : t('facilityDetail.percentageError');

  return (
    <div className='flex'>
      {invoiceToEdit?.source !== InvoiceSource.DATADIS && (
        <div className='invoice-pdf-wrapper' style={{ height: '75vh' }}>
          {documentPDF}
        </div>
      )}

      <div className='edit-invoice'>
        <FormHeader
          title={t('facilityDetail.editInvoice')}
          description={t('facilityDetail.editInvoiceDescription')}
        />
        {loadingData && <CustomSkeletonLoader count={2} />}
        {!loadingData && (
          <div style={{ width: 'fit-content', marginBottom: '0.5rem' }}>
            <Tag
              tags={[
                {
                  icon1: typeIconSelect(formData.type as string),
                  text: (
                    <span className='on-light-text-color tag-font'>
                      {t(`facilityDetail.${invoiceToEdit.type}`)}
                    </span>
                  )
                }
              ]}
            />
          </div>
        )}
        {!loadingData && (
          <>
            {flags?.customize ? (
              <div className='type-selector'>
                <span className='input-label-font on-light-text-color '>
                  {t('facilityDetail.selectType')}
                  {': '}
                </span>
                <StepCarouselList steps={steps} handleSelect={handleSelect} lookAndFeel={'small'} />
              </div>
            ) : null}

            <FormWrapper style={{ maxHeight: '50vh', overflowY: 'auto' }}>
              {suppliers && suppliers.length > 0 && stepSelected?.id === 'default' && (
                <FormSelect
                  icon={
                    formData.type === 'heat'
                      ? '/images/icons/fire50.svg'
                      : '/images/icons/thunder50.svg'
                  }
                  placeholder={t('facilityDetail.selectASupplier')}
                  label={
                    formData.type === 'heat'
                      ? t('facilityDetail.supplierOptional')
                      : t('facilityDetail.supplier')
                  }
                  options={suppliers}
                  value={formData.supplier as SelectOptionFormat}
                  onChange={onChangeValue('supplier')}
                  error={formData.errors.find((elem) => elem.error === 'supplier')}
                  optional={formData.type === 'heat'}
                />
              )}
              {isSuperchargerHub && (
                <FormSelect
                  icon='/images/icons/shipmentGradient.svg'
                  placeholder={t('facilityDetail.selectASupplier')}
                  label={t('hubs.tocType')}
                  options={tocsSuperchargerHub}
                  value={formData.toc as SelectOptionFormat}
                  onChange={onChangeValue('toc')}
                  error={formData.errors.find((elem) => elem.error === 'toc')}
                  size={InputSize.MEDIUM}
                />
              )}
              {facilityFuels && facilityFuels.length > 0 && stepSelected?.id === 'default' && (
                <FormSelect
                  icon='/images/icons/catalog.svg'
                  placeholder={t('facilityDetail.selectAFuel')}
                  label={t('facilityDetail.fuel')}
                  options={facilityFuels.map((elem: FacilityFuelType) => ({
                    id: elem.id,
                    name: t(`facility_fuels.${elem.name}`)
                  }))}
                  value={formData.facilityFuel as SelectOptionFormat}
                  onChange={onChangeFacilityFuel}
                  error={formData.errors.find((elem) => elem.error === 'facilityFuel')}
                />
              )}
              {stepSelected?.id === 'custom' && (
                <FormSelect
                  label={
                    <span>
                      {t('facilityDetail.emissionFactor')}
                      <a
                        onClick={redirectCustomEmisionFactor}
                        className='highlight-text-color pointer'>
                        {t('facilityDetail.emissionFactorHere')}
                      </a>
                    </span>
                  }
                  icon={'/images/icons/fire50.svg'}
                  placeholder={t('facilityDetail.selectGroup')}
                  options={groupsFiltered?.map((group) => ({
                    id: group.group_id,
                    name: group.group_name
                  }))}
                  value={groupSelected}
                  onChange={onSelectGroup}
                  error={formData.errors.find((error) => error.error === 'emissionFactor')}
                />
              )}
              {renderQuantityUnit()}
              <FormText
                icon='/images/icons/star.svg'
                placeholder={t('facilityDetail.selectInvoiceId')}
                label={t('facilityDetail.invoiceId')}
                value={formData.invoiceId as string}
                onChange={onChangeValue('invoiceId')}
                error={formData.errors.find((elem) => elem.error === 'invoiceId')}
              />
              {formData.type === 'electricity' && (
                <FormText
                  icon='/images/icons/star.svg'
                  placeholder={t('facilityDetail.writeCups')}
                  label={t('facilityDetail.cups')}
                  value={formData.cups as string}
                  onChange={onChangeValue('cups')}
                  error={formData.errors.find((elem) => elem.error === 'cups')}
                />
              )}
              <FormCalendarDouble
                label={t('facilityDetail.invoiceDates')}
                handleChangeStartDate={onChangeValue('startDate')}
                handleChangeEndDate={onChangeValue('endDate')}
                startDate={formData.startDate ? (formData.startDate as string) : ''}
                endDate={formData.endDate ? (formData.endDate as string) : ''}
                error={formData.errors.find(
                  (error) =>
                    error.error === 'startDate' ||
                    error.error === 'endDate' ||
                    error.error === 'date'
                )}
                handleStartDateError={handleDateError('startDate')}
                handleEndDateError={handleDateError('endDate')}
                maxEndDate={convertDateToString(today)}
              />
              {!isSuperchargerHub ? (
                <DivideConsumptionsWrapper
                  setFormData={setFormData}
                  organizationOptions={organizationOptions}
                  formData={{
                    ...formData,
                    unit:
                      stepSelected?.id === 'custom'
                        ? (formData.unitCustom as SelectOptionFormat)
                        : (formData.unitDefault as SelectOptionFormat)
                  }}
                  units={unitOptions}
                  facility={facility}
                />
              ) : null}
            </FormWrapper>
            <FormButtonSection>
              <TooltipWrapper
                text={buttonTooltip}
                shouldAppear={!!formData.errors.find((elem) => elem.error === 'percentages')}
                style={{ width: '100%' }}>
                <Button
                  lookAndFeel='primary'
                  text={t('facilityDetail.confirm')}
                  onClick={handleEditInvoice}
                  loading={loadingButton}
                  disabled={!!formData.errors.find((elem) => elem.error === 'percentages')}
                />
              </TooltipWrapper>
            </FormButtonSection>
          </>
        )}
      </div>
    </div>
  );
};

export default EditInvoice;
