import { useTranslation } from 'react-i18next';
import { gray_color, scope1_color, scope2_color, scope3_color } from '../../../../styles/colors';
import { PurchaseType } from '../../../../types/entitiesEnums/purchases';
import { PurchasesReport } from '../../../../types/purchases';
import formatNumber from '../../../../utils/formatNumber';
import { limitString } from '../../../../utils/limitString';
import numberToDecimal from '../../../../utils/numberToDecimal';
import StackedBar from '../bar/StackedBar';
import CardHeader from '../cardHeader/CardHeader';
import CardTotalCo2 from '../cardTotalCo2/CardTotalCo2';

type Bar = {
  value: number;
  color: string;
};
type Props = {
  purchases: PurchasesReport;
};

function Purchases({ purchases }: Props) {
  const { t } = useTranslation();

  let totalCo2 = 0;

  purchases.items.forEach((purchase) => {
    totalCo2 += purchase.co2e;
  });

  const renderBarSection = (total: number, values: Bar[], totalByElement: number) => {
    return (
      <div className='card-info-rows '>
        <span className='name-logo body1-bold-font'>
          <img src='/images/icons/shoppingBagGradient.svg' alt='purchase' />
          {t(`report.purchases`)}
        </span>
        <StackedBar total={total} values={values} remaining={total - totalByElement} />
        <span className='body1-font'>{formatNumber(totalByElement / 1000)}</span>
      </div>
    );
  };

  const calculateRemaining = () => {
    let top3Co2 = 0;
    let total = 0;
    if (purchases.items.length < 4) {
      return 0;
    }
    purchases.items.forEach((elem, index) => {
      if (index < 3) {
        top3Co2 += elem.co2e;
      }
      total += elem.co2e;
    });
    return total - top3Co2;
  };

  const colors = [scope2_color, scope3_color, scope1_color, gray_color];

  const purchaseColors = purchases.items?.slice(0, 3).map((purchase, index) => {
    return {
      product_name: purchase.product_name,
      co2e: purchase.co2e,
      color: colors[index],
      purchase_type: purchase.purchase_type
    };
  });
  purchaseColors.push({
    product_name: 'remaining',
    color: gray_color,
    co2e: calculateRemaining(),
    purchase_type: PurchaseType.SPEND_BASE
  });

  return (
    <div className='measure-detail-report'>
      <div className='card main-bg-color'>
        <CardHeader title={t('report.purchases')} description={t('report.purchasesDescription')} />

        <CardTotalCo2 totalCo2={totalCo2} />
        <div className='card-info-columns'>
          <span className='body2-font'>{t('report.top3Purchases')}</span>
          <span className='body2-font'>t CO₂ eq.</span>
          <span className='body2-font'>% Total</span>
        </div>
        <div className='rows-wrapper on-card-gray-bg-color'>
          {purchases.items?.slice(0, 3).map((purchase) => (
            <div className='card-info-rows' key={purchase.product_name}>
              <span className='name-logo body1-bold-font'>
                <img src='/images/icons/shoppingBagGradient.svg' alt='purchase' />
                {purchase.purchase_type === PurchaseType.SPEND_BASE &&
                  limitString(t(`purchases.products.${purchase.product_name}`), 20)}
                {purchase.purchase_type === PurchaseType.SUPPLIER_SPECIFIC &&
                  limitString(purchase.product_name, 20)}
              </span>
              <span className='body1-font'>{formatNumber(purchase.co2e / 1000)}</span>
              <span className='percentage body1-font tag-bg-text-color'>{`${
                totalCo2 ? formatNumber((purchase.co2e * 100) / totalCo2) : 0
              } %`}</span>
            </div>
          ))}
          <div className='card-info-rows '>
            <span className='name-logo body1-bold-font'>
              <img src='/images/icons/shoppingBagGradient.svg' alt='purchase' />
              {t('report.remaining')}
            </span>
            <span className='body1-font'>{formatNumber(calculateRemaining() / 1000)}</span>
            <span className='percentage body1-font tag-bg-text-color'>{`${
              totalCo2 ? formatNumber((calculateRemaining() * 100) / totalCo2) : 0
            } %`}</span>
          </div>
        </div>
      </div>
      <div className='card main-bg-color '>
        <div className='scope-tag'>
          <div className='text-tag font-10 tag-bg-text-color tag'>{`${t(
            'measureMain.scope'
          )} 3`}</div>
        </div>
        <div className='card-info-columns'>
          <span className='body2-font'>{t('report.distribution')}</span>
          <span className='body2-font'>t CO₂ eq.</span>
        </div>
        <div className='rows-wrapper on-card-gray-bg-color'>
          {renderBarSection(
            totalCo2,
            purchaseColors.map((elem) => {
              return {
                color: elem.color,
                value: elem.co2e
              };
            }),
            numberToDecimal(totalCo2)
          )}
        </div>
        <div className='card-info-columns card-footer'>
          <div className='body2-font'>{t('report.legend')}</div>
        </div>
        <div className='rows-wrapper on-card-gray-bg-color legends'>
          <div className='card-info-rows'>
            {purchaseColors?.map((purchase) => (
              <div className='legend body1-font' key={purchase.product_name}>
                <div className='dot round' style={{ backgroundColor: purchase.color }} />
                {purchase.purchase_type === PurchaseType.SPEND_BASE &&
                  limitString(t(`purchases.products.${purchase.product_name}`), 20)}
                {purchase.purchase_type === PurchaseType.SUPPLIER_SPECIFIC &&
                  limitString(purchase.product_name, 20)}
              </div>
            ))}
          </div>
        </div>
        <div className='card-footer'>
          <span className='body2-font'>{t('report.total')}</span>
        </div>
        <div className='total-info-cards'>
          <div className='total-info on-card-gray-bg-color'>
            <span className='body2-font'>{t('report.totalPurchases')}</span>
            <span className='highlight-report-text-color body1-bold-font'>{purchases.total}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Purchases;
