import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { Column } from '../../../../../types/table';
import InputNumber from '../../../../ui/formComponents2/inputNumber/InputNumber';
import InputWrapper from '../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import { MaterialIcon } from '../MaterialIcon/MaterialIcon';
import { FormType } from './constants';
import Select from '../../../../ui/formComponents2/select/Select';
import { ActionButton } from '../../../../ui/actionButton/ActionButton';
import { LCA_DECIMALS } from '../../../constants';
import Tooltip from 'components/ui/tooltip/Tooltip';
import { useUnits } from 'customHooks/api/useUnits';

type Params = {
  deleteMaterial: (id: string) => () => void;
  updateMaterialQuantity: (id: string, quantity: string) => void;
  updateMaterialUnit: (id: string, unit: SelectOptionFormat<string>) => void;
};

export const useColumns = ({
  deleteMaterial,
  updateMaterialQuantity,
  updateMaterialUnit
}: Params) => {
  const { t } = useLCATranslation();

  const { data: units } = useUnits();

  const columns: Array<Column<FormType['materials'][number]>> = [
    {
      title: t('lcaDetail.processFlow.materialModal.table.columns.material'),
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (name, row) => {
        return (
          <span className='m-0 font-body-b2-r flex gap-x-2 items-center'>
            <MaterialIcon type={row.type} disabled={!row.impact_source_id} lookAndFeel='material' />
            {name}
            {!row.impact_source_id && <Tooltip text={t('lcaDetail.matchingTooltip')} />}
          </span>
        );
      }
    },
    {
      title: t('lcaDetail.processFlow.processModal.table.columns.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      width: 200,
      render: (_, row) => {
        const unit =
          row.unit && row.unit_id ? { id: row.unit_id, name: row.unit } : { id: '', name: '' };

        return (
          <InputWrapper>
            <InputNumber
              value={row.quantity}
              onChange={(value) => {
                updateMaterialQuantity(row.material_id, value);
              }}
              decimals={LCA_DECIMALS}
              allowNegative
            />
            <Select
              options={units?.map(({ id, name }) => ({ id, name })) ?? [unit]}
              value={unit}
              onChange={(value) => updateMaterialUnit(row.material_id, value)}
            />
          </InputWrapper>
        );
      }
    },
    {
      title: t('lcaDetail.processFlow.processModal.table.columns.supplier'),
      dataIndex: 'supplier',
      key: 'supplier',
      width: 200
    },
    {
      title: '',
      dataIndex: 'material_id',
      key: 'delete',
      width: 50,
      render: (material_id) => {
        return (
          <ActionButton onClick={deleteMaterial(material_id)} icon='trash' color='gray-dark' />
        );
      }
    }
  ];

  return { columns };
};
