import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { getCustomError } from 'utils/errors';

export const adaptHookFormError = (error?: FieldError, t?: TFunction) => {
  if (!error || !error.message) {
    return undefined;
  }

  const errorMsg = getCustomError(error.message);

  return {
    error: error?.type,
    description: t ? t(...errorMsg) : errorMsg[0]
  };
};

export const adaptHookFormDropdownError = (
  error?: Merge<FieldError, FieldErrorsImpl<{ id: string; name: string }>>,
  t?: TFunction
) => {
  if (!error || !error.id?.message || !error.id.type) {
    return undefined;
  }

  return {
    error: 'required',
    description: t ? t('required') : 'Required field'
  };
};
