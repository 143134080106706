import React from 'react';
import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('businessTravels.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('businessTravels.email'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('businessTravels.transport'),
      dataIndex: 'transport_type',
      key: 'transport_type'
    },
    {
      title: t('businessTravels.travel_type'),
      dataIndex: 'travel_type',
      key: 'travel_type'
    },
    {
      title: t('businessTravels.origin'),
      dataIndex: 'origin',
      key: 'origin'
    },
    {
      title: t('businessTravels.destination'),
      dataIndex: 'destination',
      key: 'destination'
    },
    {
      title: t('businessTravels.distance_km'),
      dataIndex: 'distance_km',
      key: 'distance_km'
    },
    {
      title: t('businessTravels.travelNumber'),
      dataIndex: 'travel_number',
      key: 'travel_number'
    },
    {
      title: t('businessTravels.date'),
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: t('businessTravels.status'),
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: <div style={{ textAlign: 'right' }}>CO₂ eq</div>,
      dataIndex: 'co2e',
      key: 'co2e'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];
  return columns;
};

export default useColumns;
