import { getUrl, IFilter, QueryParams } from 'utils/url';
import apiFetch from '../../utils/apiFetch';

// INTENSITY METRIC GROUPS

export const getIntensityMetricGroupsList = async (organization_id: string) => {
  try {
    const response = await apiFetch(
      'GET',
      `/intensity_metric_groups`,
      {},
      {
        'x-organization-id': organization_id
      },
      {
        org_id: organization_id
      }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

type IntensityMetricGroupCreate = {
  name?: string;
  unit: string;
  scopes: number[];
  is_grouping: boolean;
};
export const createIntensityMetricGroup = async (
  intensityMetricGroup: IntensityMetricGroupCreate,
  organization_id: string
) => {
  try {
    const response = await apiFetch('POST', '/intensity_metric_groups', intensityMetricGroup, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const deleteIntensityMetricGroup = async (id: string, organization_id: string) => {
  try {
    const response = await apiFetch(
      'DELETE',
      `/intensity_metric_groups/${id}`,
      {},
      {
        'x-organization-id': organization_id
      },
      {
        org_id: organization_id
      }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getIntensityMetricGroupById = async (id: string, organization_id: string) => {
  try {
    const response = await apiFetch(
      'GET',
      `/intensity_metric_groups/${id}`,
      {},
      {
        'x-organization-id': organization_id
      },
      {
        org_id: organization_id
      }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getIntensityMetricGroupByIdYear = async (
  id: string,
  year: number,
  organization_id: string,
  returnRequest = false
) => {
  try {
    const request = apiFetch(
      'GET',
      `/intensity_metric_groups/${id}/year`,
      {},
      {
        'x-organization-id': organization_id
      },
      {
        year
      }
    );
    if (returnRequest) {
      return request;
    }
    const response = await request;
    return response.data;
  } catch (err) {
    return null;
  }
};

type IntensityMetricGroupUpdate = {
  name?: string;
  unit?: string;
  scopes?: number[];
  is_grouping?: boolean;
};

export const updateIntensityMetricGroup = async (
  id: string,
  intensityMetricGroup: IntensityMetricGroupUpdate,
  organization_id: string
) => {
  try {
    const response = await apiFetch(
      'PATCH',
      `/intensity_metric_groups/${id}`,
      intensityMetricGroup,
      {
        'x-organization-id': organization_id
      }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getIntensityMetricGroups = async () => {
  try {
    const response = await apiFetch('GET', `/intensity_metric_groups`, null, null, {
      page: 1,
      size: 100
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

// INTENSITY METRICS

export const getIntensityMetricsList = async (organization_id: string) => {
  try {
    const response = await apiFetch(
      'GET',
      `/intensity_metrics/list`,
      {},
      {
        'x-organization-id': organization_id
      },
      {
        org_id: organization_id
      }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

type IntensityMetricCreate = {
  start_date: number;
  end_date: number;
  quantity: number;
  intensity_metric_group_id: string;
};
export const createIntensityMetric = async (
  intensityMetric: IntensityMetricCreate,
  organization_id: string
) => {
  try {
    const response = await apiFetch('POST', `/intensity_metrics`, intensityMetric, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getIntensityMetricById = async (id: string, returnRequest = false) => {
  try {
    const request = apiFetch('GET', `/intensity_metrics/${id}`);
    if (returnRequest) {
      return request;
    }
    const response = await request;
    return response.data;
  } catch (err) {
    return null;
  }
};

export const deleteIntensityMetric = async (id: string) => {
  try {
    const response = await apiFetch('DELETE', `/intensity_metrics/${id}`);
    return response.data;
  } catch (err) {
    return null;
  }
};

type IntensityMetricUpdate = {
  quantity?: number;
  intensity_metric_group_id?: string;
};

export const updateIntensityMetric = async (id: string, intensityMetric: IntensityMetricUpdate) => {
  try {
    const response = await apiFetch('PATCH', `/intensity_metrics/${id}`, intensityMetric);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getIntensityMetricsByOrganization = async (start_date: number, end_date: number) => {
  try {
    const response = await apiFetch('GET', `/organizations/intensity_metrics`, null, null, {
      start_date,
      end_date
    });

    return response.data as Promise<IntensityMetricByOrg[]>;
  } catch (err) {
    return null;
  }
};

export const getIntensityMetricsByIdPaginated = async (
  id: string,
  page: number,
  size: number,
  filters?: IFilter[]
) => {
  try {
    const queryParams: QueryParams[] = [];
    queryParams.push({
      key: 'page',
      value: page.toString()
    });
    queryParams.push({
      key: 'size',
      value: size.toString()
    });

    const url = getUrl(`/intensity_metrics/list/${id}`, {
      filters,
      queryParams
    });
    const response = await apiFetch(
      'GET',
      url,
      {},
      {},
      {
        page,
        size,
        switch: 'total'
      }
    );
    return response.data;
  } catch (err) {
    return err;
  }
};
