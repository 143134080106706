import {
  HookedRecordRowResult,
  HookedRecordValue,
  LanguageType,
  OnEntryChange,
  OnEntryInit,
  RejectSubmitResult,
  Row
} from 'nuvo-react';
import { useTranslation } from 'react-i18next';
import useErrors from '../../../../../../customHooks/useErrors';
import useNuvoButton from '../../../../../../customHooks/useNuvoButton';
import useNuvoFileName from '../../../../../../customHooks/useNuvoFileName';
import useSelectedOrganization from '../../../../../../customHooks/useSelectedOrganization';
import { uploadFilePresignedUrl } from '../../../../../../services/api/aws';
import { postNuvoAnalytics } from '../../../../../../services/api/nuvoAnalytics';
import { getPresignedUrlVehicles } from '../../../../../../services/api/vehicle';
import { transformNuvoResultsIntoCsv } from '../../../../../../utils/nuvo';
import NuvoImporter from '../../../../../ui/nuvoImporter/NuvoImporter';
import { getOrganizationById } from '../../../../../../services/api/admin';
import useGetLicensePlates from '../../hooks/useGetLicensePlates';
import { useColumns } from './useColumns';
import Button from 'components/ui/button/Button';

const InputNuvoVehicles = () => {
  const { t, i18n } = useTranslation();
  const ERRORS = useErrors();
  const selectedOrganization = useSelectedOrganization();
  const { licensePlates } = useGetLicensePlates();

  const { fileName, handleGetFileName, handleExit } = useNuvoFileName();
  useNuvoButton({ onClick: handleGetFileName });

  const category = 'vehicles';

  const nuvoError = new RejectSubmitResult(
    ERRORS.NUVO.GENERAL_ERROR_TITLE,
    ERRORS.NUVO.GENERAL_ERROR_MESSAGE
  );

  const nuvoErrorFounded = new RejectSubmitResult(
    ERRORS.NUVO.ERRORS_FOUNDED_TITLE,
    ERRORS.NUVO.ERRORS_FOUNDED_MESSAGE
  );

  const nuvoErrorLimit = new RejectSubmitResult(
    t('vehicles.limitReachedTitle'),
    t('vehicles.limitReachedMessage')
  );

  const { columns, isLoading } = useColumns();

  const validateRow: OnEntryInit = (row: Row) => {
    if (!row) return;
    const errors = {} as any;

    if (row.license_plate && row.license_plate.toString().length < 3) {
      errors.license_plate = {
        value: row.license_plate,
        info: [
          {
            level: 'error',
            message: ERRORS.VEHICLES.ERR_INVALID_LICENSE_PLATE.description
          }
        ]
      };
    }

    if (licensePlates.includes(row.license_plate as string)) {
      errors.license_plate = {
        value: row.license_plate,
        info: [{ level: 'error', message: ERRORS.VEHICLES.ERR_DUPLICATE_LICENSE.description }]
      };
    }

    return errors;
  };

  const onEntryChange: OnEntryChange = (rows) => {
    return rows
      .filter((row) => Object.keys(validateRow(row.data, row.rowIndex) ?? {}).length > 0)
      .map((row) => {
        return {
          rowIndex: row.rowIndex,
          data: {
            ...row.data,
            ...validateRow(row.data, row.rowIndex)
          }
        };
      });
  };

  const columnHooks = {
    license_plate: (values: HookedRecordValue[]): HookedRecordRowResult[] => {
      return values.map(([item, index]) => {
        if (licensePlates.includes(item as string)) {
          return [
            {
              value: item,
              info: [{ level: 'error', message: ERRORS.VEHICLES.ERR_DUPLICATE_LICENSE.description }]
            },
            index
          ];
        }
        return [{ value: item }, index];
      });
    }
  };

  if (isLoading) {
    return (
      <Button
        lookAndFeel='primary'
        loading
        text={t('vehicles.automaticUploadVehicles')}
        disabled
        size='small'
      />
    );
  }

  return (
    <NuvoImporter
      btnI18nKey={`vehicles.automaticUploadVehicles`}
      settings={{
        multipleFileUpload: true,
        language: i18n.resolvedLanguage as LanguageType,
        automaticHeaderDetection: true,
        maxEntries: 700_000,
        enableExamples: true,
        identifier: 'vehicles_template_Dcycle', // Template file name
        columns
      }}
      onEntryInit={validateRow}
      onEntryChange={onEntryChange}
      onCancel={handleExit}
      columnHooks={columnHooks}
      onResults={async (results, errors, complete) => {
        if (errors.length > 0) return complete(nuvoErrorFounded);

        if (results.length <= 0) return complete(nuvoError);

        if (!selectedOrganization) return complete(nuvoError);

        const organizationData = await getOrganizationById(selectedOrganization.id);

        // error uploading file
        if (organizationData?.response?.status >= 400) return complete(nuvoError);

        if (
          (organizationData?.freight_vehicles_count_signup || 0) +
            (organizationData?.passenger_vehicles_count_signup || 0) +
            results.length >
          organizationData?.limit_vehicles
        )
          return complete(nuvoErrorLimit);

        // transform results into csv string
        const content = transformNuvoResultsIntoCsv(results);

        const finalFileName = `${fileName || category}.csv`;

        // transform content to File
        const file = new File([content], finalFileName, { type: 'text/csv' });

        // get presinged url
        const data = await getPresignedUrlVehicles(finalFileName);

        // error uploading file
        if (data?.response?.status >= 400) return complete(nuvoError);

        // upload file to presigned url
        const response = await uploadFilePresignedUrl(file, data?.upload_url);

        // error uploading file
        if (!response) return complete(nuvoError);

        // analytics
        await postNuvoAnalytics({
          numberOfRows: results.length,
          fileName: finalFileName,
          category: category
        });

        complete();
      }}
    />
  );
};

export default InputNuvoVehicles;
