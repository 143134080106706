import { useParams } from 'react-router-dom';
import './styles.scss';
import CardPanel from 'components/ui/cardPanel/CardPanel';
import logoLogicReturner from '../utils/logoLogicReturner';
import { useTranslation } from 'react-i18next';
import { allowedFrameworkDashboards } from '../utils/constants';
import { setNotification } from 'actions/notification';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const FrameworkDashboard = () => {
  const { frameworkName } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setNotification(t('error.somethingWentWrong')));
  }, []);
  return (
    <div className='framework-dashboard__body'>
      <CardPanel>
        <CardPanel.Header
          style={{
            alignItems: 'center'
          }}>
          <div className='framework-scope__iconTitle flex gap-4'>
            {frameworkName && allowedFrameworkDashboards.includes(frameworkName) && (
              <img
                src={logoLogicReturner(frameworkName, false)}
                alt='Framework icon'
                className='framework-scope__icon'
              />
            )}
            <div className='framework-scope__titleNumber flex-col justify-between'>
              <h4 className='co2-dashboard-font-small on-light-text-color'>
                {t(
                  `controlPanel.frameworkProgress.frameworks.${
                    frameworkName && allowedFrameworkDashboards.includes(frameworkName)
                      ? frameworkName
                      : 'notFound'
                  }`
                )}
              </h4>
            </div>
          </div>
        </CardPanel.Header>
      </CardPanel>
    </div>
  );
};

export default FrameworkDashboard;
