import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createFrameworkPlan,
  FrameworkEditPayload,
  FrameworkPlanPayload,
  SaveFramework,
  updateFrameworkPlan
} from 'services/api/frameworks';

const useUpdateFrameworkPlan = () => {
  const { mutateAsync } = useMutation({
    mutationFn: ({
      id,
      payload,
      organizationId
    }: {
      id: string;
      payload: FrameworkEditPayload;
      organizationId?: string;
    }) => {
      return updateFrameworkPlan(id, payload, organizationId);
    }
  });
  return { mutateAsync };
};
const useCreateFrameworkPlan = () => {
  const { mutateAsync } = useMutation({
    mutationFn: ({
      payload,
      organizationId
    }: {
      payload: FrameworkPlanPayload;
      organizationId?: string;
    }) => {
      return createFrameworkPlan(payload, organizationId);
    }
  });
  return { mutateAsync };
};

const useSaveFrameworksPlans = (organizationId = '') => {
  const { mutateAsync: createFrameworkPlan } = useCreateFrameworkPlan();
  const { mutateAsync: updateFrameworkPlan } = useUpdateFrameworkPlan();
  const queryClient = useQueryClient();
  const saveFrameworkPlans = async (
    frameworkPlans: SaveFramework[],
    reportingPeriodId?: string
  ) => {
    try {
      for (const plan of frameworkPlans) {
        // Extract properties to construct payload
        const { id, framework_id, ...rest } = plan;

        const editPayload: FrameworkEditPayload = {
          organization_framework: {
            framework_id,
            ...rest
          },
          ...(reportingPeriodId && { organization_reporting_period_id: reportingPeriodId })
        };

        if (id) {
          // Update existing plan
          await updateFrameworkPlan({ id, payload: editPayload, organizationId });
        } else {
          // Create new plan
          await createFrameworkPlan({
            payload: editPayload.organization_framework,
            organizationId
          });
        }
      }
      queryClient.invalidateQueries({ queryKey: ['frameworkPlans'] });
    } catch (error) {
      console.error('Error saving framework plans:', error);
    }
  };
  return { saveFrameworkPlans };
};

export default useSaveFrameworksPlans;
