import { useLCABlocks } from './useLCABlocks';
import { useLCAImpacts } from './useLCAImpacts';

export const useLCATotalImpact = (acv_id: string, impact_category: SelectOptionFormat<string>) => {
  const { data: lcaImpacts, refetch, isFetching, isError } = useLCAImpacts(acv_id, impact_category);

  const { lcaBlocks, isLoading } = useLCABlocks({ acv_id });

  const lastBlock = lcaBlocks?.find((block) =>
    block.outputs.some((output) => output.type === 'product')
  );

  const totalImpact = lcaImpacts?.items.reduce(
    (result, impact) => {
      if (impact.block_id === lastBlock?.id) {
        return impact;
      }

      return result;
    },
    { block_id: '', impact_specific_unit: '', impact_general_unit_id: '', sum_total_impact: 0 }
  );

  return { totalImpact, refetch, isFetching: isFetching || isLoading, isError };
};
