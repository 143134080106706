import React, { useState } from 'react';
import Button from '../../../../ui/button/Button';
import Modal from '../../../../ui/modal/Modal';
import { MaterialTable } from '../MaterialTable';
import { FormType } from './constants';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { mergeFormMaterialsAndSelectedMaterials } from './build';
import { Filters } from '../../../../layout/NewFilters/Filters';
import { IFilter } from '../../../../../utils/url';
import { FilterText } from '../../../../layout/NewFilters/FilterText';
import { DEFAULT_HEIGHT } from '../../constants';
import Icon from 'components/ui/icon/Icon';
import { useLCAMaterials } from 'components/lca/hooks/useLCAMaterials';
import { NoMaterials } from 'components/lca/MaterialManagement/components/NoMaterials';
import LoaderTables from 'components/ui/loaders/loaderTables/LoaderTables';

type Props = {
  materials: FormType['materials'];
  setMaterials: (materials: FormType['materials']) => void;
  onCancel: () => void;
  openCreateMaterial: () => void;
  openBulkCreateMaterial: () => void;
};

const TYPE_FILTER = { field: 'type', type: 'eq', value: 'material' } as const;

export const MaterialSelection: React.FC<Props> = ({
  materials,
  onCancel,
  setMaterials,
  openCreateMaterial,
  openBulkCreateMaterial
}) => {
  const { t } = useLCATranslation();

  const [materialsSelected, setMaterialsSelected] = useState(materials);
  const [filters, setFilters] = useState<IFilter[]>([TYPE_FILTER]);
  const { data, isLoading } = useLCAMaterials({ filters });
  const handleSubmit = () => {
    const mergedMaterials = mergeFormMaterialsAndSelectedMaterials(materialsSelected, materials);

    setMaterials(mergedMaterials);
  };

  if (isLoading) {
    return (
      <div className='flex-col grow gap-y-4 bg-neutral-gray-80' style={{ height: DEFAULT_HEIGHT }}>
        <div className='grid grid-cols-1 grow place-items-center'>
          <LoaderTables mode='fit' />
        </div>
        <Modal.Buttons>
          <Button
            lookAndFeel='secondary'
            text={t('lcaDetail.processFlow.materialModal.cancel')}
            size='small'
            onClick={onCancel}
          />
          <Button
            lookAndFeel='primary'
            text={t('lcaDetail.processFlow.materialModal.addMaterials')}
            size='small'
            onClick={handleSubmit}
          />
        </Modal.Buttons>
      </div>
    );
  }

  if (filters.length === 1 && !data?.length && !isLoading) {
    return (
      <div className='flex-col grow gap-y-4' style={{ height: DEFAULT_HEIGHT }}>
        <NoMaterials
          onClickManualAdd={openCreateMaterial}
          onClickMassUpload={openBulkCreateMaterial}
        />
        <Modal.Buttons>
          <Button
            lookAndFeel='secondary'
            text={t('lcaDetail.processFlow.materialModal.cancel')}
            size='small'
            onClick={onCancel}
          />
          <Button
            lookAndFeel='primary'
            text={t('lcaDetail.processFlow.materialModal.addMaterials')}
            size='small'
            onClick={handleSubmit}
          />
        </Modal.Buttons>
      </div>
    );
  }

  return (
    <Filters.Root.FiltersOnly filters={filters} setFilters={setFilters}>
      <Modal.Content style={{ flexGrow: 1, height: DEFAULT_HEIGHT }}>
        <div className='flex-col gap-y-4'>
          <div className='flex justify-between items-center pr-2'>
            <div className='w-80'>
              <FilterText type='il' field='name' placeholder='Nombre del material' />
            </div>
            <Button
              lookAndFeel='link-dark'
              iconNode={<Icon icon='add' color='gray-dark' />}
              text={t('lcaDetail.processFlow.materialModal.addMaterial')}
              onClick={openCreateMaterial}
            />
          </div>
          <MaterialTable
            selectedMaterials={materialsSelected}
            setSelectedMaterials={setMaterialsSelected}
            filters={filters}
          />
        </div>
      </Modal.Content>
      <Modal.Buttons>
        <Button
          lookAndFeel='secondary'
          text={t('lcaDetail.processFlow.materialModal.cancel')}
          size='small'
          onClick={onCancel}
        />
        <Button
          lookAndFeel='primary'
          text={t('lcaDetail.processFlow.materialModal.addMaterials')}
          size='small'
          onClick={handleSubmit}
        />
      </Modal.Buttons>
    </Filters.Root.FiltersOnly>
  );
};
