import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setNotification } from '../../../actions/notification';
import { ROUTES } from '../../../constants/routes';
import { UserContext } from '../../../context/userContext';
import useOnChangeValue from '../../../customHooks/useOnChangeValue';
import { Organization } from '../../../types/entities/organization';
import { InputSize } from '../../../types/utilsEnums/input';
import apiFetch from '../../../utils/apiFetch';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import CardList from '../../ui/cardList/CardListRefactored';
import CardsFilter from '../../ui/filter/CardsFilter';
import FormSelect from '../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormText from '../../ui/formComponents2/formInputs/formText/FormText';
import Modal from '../../ui/modal/Modal';
import DeleteOrganization from './DeleteOrganization';
import EditOrganization from './EditOrganization';
import useSubscriptionPlanOptions from './hooks/useSubscriptionPlanOptions';
import './styles.scss';
import UsageMetrics from './components/usageMetrics/UsageMetrics';
import Card from '../../ui/cards/card/Card';
import ButtonDropdown from '../../ui/buttonDropdown/ButtonDropdown';
import Icon from '../../ui/icon/Icon';
import { limitString } from '../../../utils/limitString';
import Label from '../../ui/label/Label';

function OrganizationList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const subscriptionPlanOptions = useSubscriptionPlanOptions();

  subscriptionPlanOptions.unshift({
    id: '',
    name: t('admin.all')
  });

  const [organizations, setOrganizations] = useState<Organization[]>();
  const [organizationToEdit, setOrganizationToEdit] = useState<Organization>();
  const [organizationToDelete, setOrganizationToDelete] = useState<string | undefined>(undefined);
  const [organizationToDeleteCascade, setOrganizationToDeleteCascade] = useState<
    string | undefined
  >(undefined);
  const [organizationUsageMetrics, setOrganizationUsageMetrics] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [listView, setListView] = useState(false);

  const toBeDeleted = organizationToDeleteCascade || organizationToDelete;

  // Filters
  const [filters, setFilters] = useState({
    searchValue: '',
    subscriptionPlanFilter: {
      id: '',
      name: ''
    },
    status: {
      id: '',
      name: ''
    },
    errors: []
  });

  const { onChangeValue } = useOnChangeValue({ setFormData: setFilters });

  const user = useContext(UserContext);

  useEffect(() => {
    if (!user || !user.selectedOrganization) return;
    setLoading(true);
    apiFetch('GET', '/organizations', {})
      .then((response) => {
        setOrganizations(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  const onClickCard = (id: string) => {
    navigate(`${ROUTES.ADMIN_ORGANIZATIONS}/${id}`);
  };

  const handleHideEditOrganization = () => {
    setOrganizationToEdit(undefined);
  };

  const handleHideDeleteOrganization = () => {
    setOrganizationToDelete(undefined);
    setOrganizationToDeleteCascade(undefined);
  };

  const editOrganization = (value: Organization, id: string) => {
    const newOrganizations = organizations ? [...organizations] : [];
    const indexOrganizationFound = newOrganizations.findIndex((elem) => elem.id === id);
    if (indexOrganizationFound > -1) {
      newOrganizations[indexOrganizationFound] = value;
    }

    setOrganizations(newOrganizations);
    dispatch(setNotification(t('notification.editOrganization')));

    handleHideEditOrganization();
  };

  const deleteOrganization = (id: string) => {
    const newOrganizations = organizations ? [...organizations] : [];
    const indexOrganizationFound = newOrganizations.findIndex((elem) => elem.id === id);
    if (indexOrganizationFound > -1) {
      newOrganizations.splice(indexOrganizationFound, 1);
    }

    setOrganizations(newOrganizations);
    dispatch(setNotification(t('notification.deleteOrganization')));
    handleHideDeleteOrganization();
  };

  const handleSetOrganizationToEdit = (id: string) => {
    const organizationFound = organizations?.find((elem) => elem.id === id);
    if (organizationFound) {
      setOrganizationToEdit(organizationFound);
    }
  };

  const handleChangeListView = () => {
    setListView(!listView);
  };

  const getDropdownOptions = (elem: Organization) => {
    const dropDownOptions = [
      {
        id: 'edit',
        name: t('admin.edit'),
        onClick: () => handleSetOrganizationToEdit(elem.id)
      },
      {
        id: 'delete',
        name: t('admin.delete'),
        onClick: () => setOrganizationToDelete(elem.id)
      },
      {
        id: 'copy_id',
        name: t('admin.copyId'),
        onClick: () => {
          navigator.clipboard.writeText(elem.id);
          dispatch(setNotification(t('notification.copyId')));
        }
      },
      {
        id: 'usage_metrics',
        name: t('admin.metrics'),
        onClick: () => {
          setOrganizationUsageMetrics(elem.id);
        }
      }
    ];
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      const isMainOrgMember = !!user?.organizations?.find(
        (org) => org.id === process.env.REACT_APP_DCYCLE_MAIN_ID
      );
      const isAdminLuis = user?.email === 'luis@dcycle.io' || user?.email === 'diego@dcycle.io';

      if (isMainOrgMember && isAdminLuis) {
        dropDownOptions.push({
          id: 'delete_cascade',
          name: t('admin.deleteCascade'),
          onClick: () => setOrganizationToDeleteCascade(elem.id)
        });
      }
    }
    return dropDownOptions;
  };

  return (
    <section className='organizations'>
      <SectionHeader title={t('admin.title')} />
      <CardsFilter listView={listView} onChangeListView={handleChangeListView}>
        <>
          <FormText
            icon={'/images/icons/search.svg'}
            placeholder={t('facilities.searchPlaceholder')}
            onChange={onChangeValue('searchValue')}
            value={filters.searchValue}
            size={InputSize.SMALL}
          />
          <FormSelect
            icon='/images/icons/envelope.svg'
            size={InputSize.SMALL}
            placeholder={t('admin.selectSubscriptionPlan')}
            options={subscriptionPlanOptions}
            value={filters.subscriptionPlanFilter}
            onChange={onChangeValue('subscriptionPlanFilter')}
            sort={false}
          />
          <FormSelect
            icon='/images/icons/organization.svg'
            size={InputSize.SMALL}
            placeholder={t('admin.selectStatus')}
            options={[
              {
                id: '',
                name: t('admin.all')
              },
              {
                id: 'active',
                name: t('admin.active')
              },
              {
                id: 'expired',
                name: t('admin.expired')
              },
              {
                id: 'inactive',
                name: t('admin.inactive')
              }
            ]}
            value={filters.status}
            onChange={onChangeValue('status')}
            sort={false}
          />
        </>
      </CardsFilter>
      {organizations && (
        <div className='organizations__list-wrapper'>
          <CardList listView={listView} loading={loading} showInformation={false}>
            {organizations
              .sort((a, b) => a.company_name.localeCompare(b.company_name))
              ?.filter((elem) => {
                let condition = true;
                if (filters.searchValue !== '') {
                  condition = elem.company_name
                    .toLowerCase()
                    .includes(filters.searchValue.toLowerCase());
                }
                if (filters.subscriptionPlanFilter.id !== '') {
                  condition =
                    condition && elem.subscription_plan === filters.subscriptionPlanFilter.id;
                }
                if (filters.status.id !== '') {
                  condition = condition && elem.status === filters.status.id;
                }
                return condition;
              })
              .map((elem) => {
                const orgParsed = {
                  id: elem.id,
                  title: elem.company_name,
                  description: elem.id,
                  co2e: elem.co2e,
                  icon:
                    elem.sector !== 'holding'
                      ? '/images/icons/organization.svg'
                      : '/images/icons/holding.svg',
                  tag: elem.sector === 'holding' ? 'Holding/Fund' : undefined
                };
                return (
                  <Card key={elem.id} listView={listView} onClick={() => onClickCard(elem.id)}>
                    <Card.Options>
                      <ButtonDropdown
                        style={{
                          height: '32px',
                          width: '32px',
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                        button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
                        options={getDropdownOptions(elem)}></ButtonDropdown>
                    </Card.Options>

                    <Card.Content>
                      <Card.Icon icon={orgParsed.icon} alt={orgParsed.title} />
                      <span className='headline4-font'>{orgParsed.title}</span>
                      <span className='subtitle3-font'>{orgParsed.description}</span>
                      {elem.enabled === false && <Label lookAndFeel='disabled'>Disabled</Label>}
                    </Card.Content>
                  </Card>
                );
              })}
          </CardList>
        </div>
      )}

      <Modal.WithPortal
        show={!!organizationToEdit}
        onClose={handleHideEditOrganization}
        width='600px'
        maxWidth='600px'>
        {organizationToEdit && (
          <EditOrganization
            organizationToEdit={organizationToEdit}
            editOrganization={editOrganization}
          />
        )}
      </Modal.WithPortal>

      <Modal
        show={organizationToDelete !== undefined || organizationToDeleteCascade !== undefined}
        onClose={handleHideDeleteOrganization}
        width='428px'
        maxWidth='428px'>
        {toBeDeleted && (
          <DeleteOrganization
            organizationToDelete={toBeDeleted}
            deleteOrganization={deleteOrganization}
            cascade={organizationToDeleteCascade !== undefined}
          />
        )}
      </Modal>
      <Modal
        show={!!organizationUsageMetrics}
        onClose={() => setOrganizationUsageMetrics('')}
        width='700px'
        maxWidth='700px'>
        <UsageMetrics
          id={organizationUsageMetrics || ''}
          name={
            organizations?.find((elem) => elem.id === organizationUsageMetrics)?.company_name ?? ''
          }
        />
      </Modal>
    </section>
  );
}

export default OrganizationList;
