import { useTranslation } from 'react-i18next';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { getIntensityMetricGroupById } from '../../../../../services/api/intensityMetrics';
import { convertDateBackToFront, convertDateFrontToBack } from '../../../../../utils/convertDates';

const useAdapterGHG = () => {
  const { t } = useTranslation();

  const { t: tScopes } = useTranslation('translation', {
    keyPrefix: 'orgDescriptionModal.ghg_report.form.scopes'
  });

  const { t: tCategories } = useTranslation('translation', {
    keyPrefix: 'categories'
  });

  const categories = [
    'stationary',
    'recharge',
    'water',
    'vehicles',
    'electricity',
    'purchases',
    'transport_distribution_upstream',
    'business_travel',
    'employees',
    'wastes',
    'transport_distribution_downstream'
  ];

  const selectedOrganization = useSelectedOrganization();

  const getIntensityMetrics = async (
    intensityMetrics?: string[]
  ): Promise<IntensityMetricGroup[]> => {
    if (!intensityMetrics || !selectedOrganization) {
      return [];
    }

    return Promise.all(
      intensityMetrics.map((intensityMetric) =>
        getIntensityMetricGroupById(intensityMetric, selectedOrganization.id)
      )
    );
  };

  const adaptFront2Back = (front: IGHGFront): IGHGBack => {
    // get startDate and endDate from year with format YYYY-01-01
    const startDate = front.startDate;
    const endDate = front.endDate;

    const intensityMetric = front.intensityMetric1.id ? [front.intensityMetric1.id] : undefined;
    if (front.intensityMetric2.id && intensityMetric)
      intensityMetric.push(front.intensityMetric2.id);

    const scopes = front.scopes
      .filter((scope) => scope.selected)
      .map((scope) => parseInt(scope.id));

    // if verification is none, set it to empty string, which is different from not selecting any option

    let verification: string = front.verification.id;

    if (front.verification.id === 'other') {
      verification = front.verificationOther;
    }

    return {
      start_date: convertDateFrontToBack(startDate),
      end_date: convertDateFrontToBack(endDate),
      trade_name: front.tradeName || undefined,
      intensity_metric: intensityMetric,
      verification,
      approach: front.approach.id || undefined,
      base_measure_year: front.baseYear.id ? parseInt(front.baseYear.id) : undefined,
      text_base_measure_year:
        !front.changeBaseYear.id || front.changeBaseYear.id === 'no'
          ? undefined
          : front.justificationChangeBaseYear,
      description: front.cnae || undefined,
      contact_info: front.contactInformation || undefined,
      other_limitation_organization: front.thresholds || undefined,
      scopes: scopes.length ? scopes : undefined,
      categories: front.categories
        .filter((category) => category.selected)
        .map((category) => category.id),
      text_scopes: front.justificationScopes || undefined,
      other_assumptions: front.otherAssumptions || undefined
    };
  };

  const adaptBack2Front = async (back: IGHGBack): Promise<IGHGFront> => {
    const intensity_metrics = await getIntensityMetrics(back.intensity_metric);

    const verification: SelectOptionFormat<'eqa' | '' | 'none' | 'other'> = {
      id: '',
      name: ''
    };

    let verificationOther = '';

    if (back.verification === undefined) {
      verification.id = '';
      verification.name = '';
    } else if (['eqa', 'none', ''].includes(back?.verification)) {
      verification.id = back.verification as 'eqa' | '' | 'none';
      verification.name = t(`ghgReport.verification.${back.verification}`);
    } else {
      verification.id = 'other';
      verification.name = t(`ghgReport.verification.${back.verification}`);
      verificationOther = back.verification;
    }

    return {
      startDate: back.start_date ? convertDateBackToFront(back.start_date) : '',
      endDate: back.end_date ? convertDateBackToFront(back.end_date) : '',
      tradeName: selectedOrganization?.trade_name || '',
      intensityMetric1: {
        id: intensity_metrics.at(0)?.id || '',
        name: intensity_metrics?.at(0)?.name || ''
      },
      intensityMetric2: {
        id: intensity_metrics.at(1)?.id || '',
        name: intensity_metrics?.at(1)?.name || ''
      },
      verification,
      verificationOther,
      approach: { id: back.approach || '', name: back.approach || '' },
      baseYear: {
        id: back.base_measure_year?.toString() || '',
        name: back.base_measure_year?.toString() || ''
      },
      changeBaseYear: {
        id: 'no',
        name: t('no')
      },
      justificationChangeBaseYear: back.text_base_measure_year || '',
      cnae: selectedOrganization?.description || '',
      contactInformation: back.contact_info || '',
      thresholds: back.other_limitation_organization || '',
      scopes: [
        {
          id: '1',
          label: tScopes('scope1'),
          selected: back.scopes?.includes(1) || false
        },
        {
          id: '2',
          label: tScopes('scope2'),
          selected: back.scopes?.includes(2) || false
        },
        {
          id: '3',
          label: tScopes('scope3'),
          selected: back.scopes?.includes(3) || false
        }
      ],
      categories: categories.map((category) => ({
        id: category,
        label: tCategories(category),
        selected: back.categories?.includes(category) || false
      })),
      justificationScopes: back.text_scopes || '',
      otherAssumptions: back.other_assumptions || '',
      errors: []
    };
  };

  let emptyFormData: IGHGFront = {
    startDate: '',
    endDate: '',
    tradeName: selectedOrganization?.trade_name || '',
    intensityMetric1: { id: '', name: '' },
    intensityMetric2: { id: '', name: '' },
    verification: { id: '', name: '' },
    verificationOther: '',
    approach: { id: '', name: '' },
    baseYear: { id: '', name: '' },
    changeBaseYear: { id: '', name: '' },
    justificationChangeBaseYear: '',
    cnae: selectedOrganization?.description || '',
    contactInformation: '',
    thresholds: '',
    scopes: [
      {
        id: '1',
        label: tScopes('scope1'),
        selected: false
      },
      {
        id: '2',
        label: tScopes('scope2'),
        selected: false
      },
      {
        id: '3',
        label: tScopes('scope3'),
        selected: false
      }
    ],
    categories: categories.map((category) => ({
      id: category,
      label: tCategories(category),
      selected: false
    })),
    justificationScopes: '',
    otherAssumptions: '',
    errors: []
  };

  // fill everything in the form with the selected organization data
  if (process.env.REACT_APP_ENVIRONMENT === 'local') {
    emptyFormData = {
      tradeName: 'trade name',
      startDate: '01/01/2024',
      endDate: '31/12/2024',
      baseYear: { id: '2020', name: '2020' },
      changeBaseYear: { id: 'no', name: t('no') },
      verification: { id: 'none', name: t('ghgReport.verification.none') },
      verificationOther: '',
      approach: { id: 'operational', name: 'operational' },
      intensityMetric1: { id: '1', name: 'Intensity metric 1' },
      intensityMetric2: { id: '2', name: 'Intensity metric 2' },
      contactInformation: 'contact information',
      thresholds: 'thresholds',
      justificationScopes: 'justification scopes',
      otherAssumptions: 'other assumptions',
      justificationChangeBaseYear: 'justification change base year',
      cnae: 'cnae description',
      scopes: [
        {
          id: '1',
          label: tScopes('scope1'),
          selected: true
        },
        {
          id: '2',
          label: tScopes('scope2'),
          selected: true
        },
        {
          id: '3',
          label: tScopes('scope3'),
          selected: true
        }
      ],
      categories: categories.map((category) => ({
        id: category,
        label: tCategories(category),
        selected: true
      })),
      errors: []
    };
  }
  return { adaptFront2Back, adaptBack2Front, emptyFormData };
};

export default useAdapterGHG;
