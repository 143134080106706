import { Fragment, MouseEvent, ReactNode } from 'react';
import { InputSize } from '../../../../../types/utilsEnums/input';
import './styles.scss';

type Props = {
  leftSection?: ReactNode;
  error?: ErrorType;
  disabled?: boolean;
  size?: InputSize;
  width?: string;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  children: ReactNode[] | ReactNode;
};

const InputWrapperPlainLabel = ({
  leftSection,
  error,
  disabled = false,
  size = InputSize.MEDIUM,
  width,
  onClick,
  children
}: Props) => {
  const borderColor = error ? 'error-border-color' : 'input-border-color';

  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    if (disabled) e.stopPropagation();
    if (!onClick) return;
    onClick(e);
  };

  const filteredChildren =
    children && Array.isArray(children) ? children.filter((node: any) => !!node) : [];
  return (
    <div
      className={`input-wrapper-plain flex ${borderColor} ${size} ${
        disabled ? 'input-disabled-bg-color' : 'bg-white'
      }`}
      onClick={handleOnClick}
      style={{ minWidth: width, width }}>
      {leftSection && (
        <div className={`image-wrapper-input ${borderColor} ${size}`}>{leftSection}</div>
      )}
      {filteredChildren.map((node, index) => (
        <Fragment key={index}>
          <div
            className={`input-element-wrapper-2 ${
              node.props.disabled ? 'input-disabled-bg-color' : 'bg-white'
            }`}>
            {node}
          </div>
          {index < (filteredChildren as ReactNode[]).length - 1 && <div className='divider-line' />}
        </Fragment>
      ))}
      {!Array.isArray(children) && <div className='input-element-wrapper-2'>{children}</div>}
    </div>
  );
};

export default InputWrapperPlainLabel;
