import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getIntensityMetricsByIdPaginated } from 'services/api/intensityMetrics';
import { limitString } from 'utils/limitString';
import { IFilter } from 'utils/url';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { getDataQualityAccuracy } from '../../../../services/api/data_quality';
import { getGHGReportInfo } from '../../../../services/api/ghg';
import { getRenewableEnergyKPI } from '../../../../services/api/invoices';
import { getMatrix } from '../../../../services/api/matrices';
import { getOrganizationEfs } from '../../../../services/api/organization';
import { getGWPTable, getIPCCVersion } from '../../../../services/api/orgEf';
import {
  getBusinessTravelsGroupedByTransport,
  getEmployees,
  getTotalImpactPurchases,
  getTotalImpactsInvestments
} from '../../../../services/api/total_impacts';
import { BusinessTravelGHGReport } from '../../../../types/entities/businessTravels';
import { EmissionFactor } from '../../../../types/entities/emissionFactor';
import { EmployeesGHGReport } from '../../../../types/entities/employee';
import { FacilityReport } from '../../../../types/entities/facility';
import { Holding, InvestmentReport, OrgInfo } from '../../../../types/entities/holdings';
import { TransportDistributionReport } from '../../../../types/entities/shipment';
import { VehicleReportGHG } from '../../../../types/entities/vehicle';
import { WastesGHGReport } from '../../../../types/entities/waste';
import { GroupDataMode } from '../../../../types/entitiesEnums/report';
import { PurchasesReportGHG } from '../../../../types/purchases';
import apiFetch from '../../../../utils/apiFetch';
import {
  convertDateBackToFront,
  convertStringToDate,
  convertStringToDateBackend
} from '../../../../utils/convertDates';
import { getAssumptions } from '../assumptions/service/getAssumptions';

type Props = { groupDataMode: GroupDataMode };

const useGetData = ({ groupDataMode }: Props) => {
  const flags = useFeatureFlags();
  const foundOrg = useSelectedOrganization();

  const [reportData, setReportData] = useState<IGHGBack>();
  const [facilities, setFacilities] = useState<FacilityReport[]>([]);
  const [vehicles, setVehicles] = useState<VehicleReportGHG[]>([]);
  const [dataElectricity, setDataElectricity] = useState<TotalImpactElectricity[]>([]);
  // const [dataEvolution, setDataEvolution] = useState<EvolutionData[]>([]);
  const [dataScopes, setDataScopes] = useState<ScopeData[]>([]);
  const [dataCategories, setDataCategories] = useState<CategoryData[]>([]);
  const [totalBiomass, setTotalBiomass] = useState(0);
  const [organizationEfs, setOrganizationEfs] = useState<EmissionFactor[]>([]);
  const [dataAccuracy, setDataAccuracy] = useState('');
  const [intensityMetric1, setIntensityMetric1] = useState<IntensityMetricReport>();
  const [intensityMetric2, setIntensityMetric2] = useState<IntensityMetricReport>();
  const [purchases, setPurchases] = useState<PurchasesReportGHG>({
    total: 0,
    total_recycled: 0,
    items: []
  });
  const [organizationCo2e, setOrganizationCo2e] = useState(0);
  const [renewableEnergy, setRenewableEnergy] = useState(0);
  const [shipments, setShipments] = useState<TransportDistributionReport>();
  const [deliveries, setDeliveries] = useState<TransportDistributionReport>();
  const [businessTravels, setBusinessTravels] = useState<BusinessTravelGHGReport[]>([]);
  const [employees, setEmployees] = useState<EmployeesGHGReport[]>([]);
  const [investments, setInvestments] = useState<InvestmentReport[]>([]);
  const [investmentTotalImpacts, setInvestmentTotalImpacts] = useState<TotalImpactPerMonth[]>([]);
  const [wastes, setWastes] = useState<WastesGHGReport[]>([]);
  const [assumptions, setAssumptions] = useState<AssumptionsResponse>({});
  const [gwpTable, setGwpTable] = useState<GWPTable>();
  const [ipccVersion, setIpccVersion] = useState<string>();

  const [loading, setLoading] = useState(true);

  const [searchParams] = useSearchParams();

  const intensityMetricId1 = searchParams.get('intensity_metric_id_1');
  const intensityMetricId2 = searchParams.get('intensity_metric_id_2');

  const categoriesWithScopes = [
    { category: 'stationary', scope: 1, total_emissions: 0, measured: false },
    { category: 'recharge', scope: 1, total_emissions: 0, measured: false },
    { category: 'vehicles', scope: 1, total_emissions: 0, measured: false },
    { category: 'electricity', scope: 2, total_emissions: 0, measured: false },
    { category: 'water', scope: 3, total_emissions: 0, measured: false },
    { category: 'employees', scope: 3, total_emissions: 0, measured: false },
    { category: 'business_travel', scope: 3, total_emissions: 0, measured: false },
    { category: 'purchases', scope: 3, total_emissions: 0, measured: false },
    { category: 'equity', scope: 3, total_emissions: 0, measured: false },
    { category: 'energy_fuel', scope: 3, total_emissions: 0, measured: false },
    { category: 'wastes', scope: 3, total_emissions: 0, measured: false },
    {
      category: 'transport_distribution_upstream',
      scope: 3,
      total_emissions: 0,
      measured: false
    },
    { category: 'rented_assets_upstream', scope: 3, total_emissions: 0, measured: false },
    {
      category: 'transport_distribution_downstream',
      scope: 3,
      total_emissions: 0,
      measured: false
    },
    { category: 'sold_products_processing', scope: 3, total_emissions: 0, measured: false },
    { category: 'sold_products_usage', scope: 3, total_emissions: 0, measured: false },
    { category: 'rented_assets_downstream', scope: 3, total_emissions: 0, measured: false },
    { category: 'franchise', scope: 3, total_emissions: 0, measured: false },
    { category: 'investment', scope: 3, total_emissions: 0, measured: false }
  ];

  const fetchConfig = async () => {
    if (!foundOrg) return;

    let config: IGHGBack = {
      id: '',
      trade_name: foundOrg.trade_name,
      description: foundOrg.description,
      start_date: undefined,
      end_date: undefined,
      intensity_metric: undefined,
      verification: undefined,
      approach: undefined,
      base_measure_year: undefined,
      text_base_measure_year: undefined,
      contact_info: undefined,
      other_limitation_organization: undefined,
      scopes: undefined,
      categories: undefined,
      text_scopes: undefined,
      other_assumptions: undefined
    };
    if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
      const responseGhgConfigurationInfo = await getGHGReportInfo();
      if (
        !responseGhgConfigurationInfo ||
        responseGhgConfigurationInfo?.response?.status >= 400 ||
        responseGhgConfigurationInfo instanceof Error
      )
        return;

      config = {
        ...responseGhgConfigurationInfo,
        description: foundOrg.description,
        trade_name: foundOrg.trade_name
      };
    } else {
      config = {
        id: '',
        trade_name: foundOrg.trade_name,
        description: foundOrg.description,
        start_date: '2024-01-01',
        end_date: '2024-12-31',
        intensity_metric: [''],
        verification: '',
        approach: '',
        base_measure_year: 2021,
        text_base_measure_year: '',
        contact_info: '',
        other_limitation_organization: '',
        scopes: [1, 2, 3],
        categories: [
          'stationary',
          'recharge',
          'vehicles',
          'electricity',
          'water',
          'employees',
          'business_travels',
          'purchases',
          'equity',
          'energy_fuel',
          'wastes',
          'transport_distribution_upstream',
          'transport_distribution_downstream'
        ],
        text_scopes: '',
        other_assumptions: ''
      };
    }

    setReportData(config);

    return {
      startDate: config.start_date,
      endDate: config.end_date,
      scopes_to_show: config.scopes,
      intensityMetrics: config.intensity_metric,
      categories: config.categories ?? []
    };
  };

  const fetchData = async () => {
    try {
      if (!foundOrg) return;
      setLoading(true);

      const configResponse = await fetchConfig();

      if (!configResponse) return;
      const { startDate, endDate, categories } = configResponse;

      if (!startDate || !endDate || !categories) return;

      const requests = [];

      const startDateToDate = convertStringToDateBackend(startDate);
      const endDateToDate = convertStringToDateBackend(endDate);

      // // Check if end_date year is equal to current year, then set end_date to today
      // const today = new Date();
      // if (endDateToDate.getFullYear() === today.getFullYear()) {
      //   // Format to YYYY-MM-DD
      //   endDateToDate = convertStringToDate(convertDateToStringBackend(today));
      // }

      startDateToDate.setUTCHours(0, 0, 0, 0);
      endDateToDate.setUTCHours(0, 0, 0, 0);

      const requestMatrix = getMatrix(
        foundOrg.id ?? '',
        startDateToDate,
        endDateToDate,
        groupDataMode,
        true
      );
      requests.push(requestMatrix);

      let requestFacilities = null;
      if (categories.includes('stationary') || categories.includes('recharge')) {
        requestFacilities = apiFetch('POST', '/total_impacts/facilities', {
          start_date: startDateToDate.toISOString(),
          end_date: endDateToDate.toISOString(),
          organization_id: foundOrg.id,
          switch: groupDataMode
        });
      }
      requests.push(requestFacilities);

      let requestVehicles = null;
      if (categories.includes('vehicles')) {
        requestVehicles = apiFetch('POST', '/total_impacts/vehicles', {
          organization_id: foundOrg.id,
          start_date: startDateToDate.toISOString(),
          end_date: endDateToDate.toISOString(),
          switch: groupDataMode
        });
      }
      requests.push(requestVehicles);

      let requestElectricity = null;
      if (categories.includes('electricity')) {
        requestElectricity = apiFetch('POST', '/total_impacts/electricity', {
          start_date: startDateToDate.toISOString(),
          end_date: endDateToDate.toISOString(),
          organization_id: foundOrg.id,
          switch: groupDataMode
        });
      }
      requests.push(requestElectricity);

      const requestBiomass = apiFetch('POST', '/total_impacts/biomass', {
        start_date: startDateToDate.toISOString(),
        end_date: endDateToDate.toISOString(),
        organization_id: foundOrg.id,
        switch: groupDataMode
      });
      requests.push(requestBiomass);

      const orgEfsRequests = categories.map((elem) => {
        return getOrganizationEfs(foundOrg.id ?? '', startDateToDate, endDateToDate, elem);
      });

      orgEfsRequests.push(
        getOrganizationEfs(
          foundOrg.id ?? '',
          startDateToDate,
          endDateToDate,
          'custom_emission_factors'
        )
      );

      const requestDataQualityAccuracy = getDataQualityAccuracy(
        foundOrg.id ?? '',
        startDateToDate,
        endDateToDate,
        true
      );
      requests.push(requestDataQualityAccuracy);

      // Check if the value of the intensity metric in the query url params
      let requestIntensityMetric1 = null;

      // Get query params of url
      const intensityMetric1 = intensityMetricId1 || undefined;
      const intensityMetric2 = intensityMetricId2 || undefined;

      const dateFilter = [
        `${moment.utc(startDate, 'YYYY-MM-DD').toDate().getTime() / 1000}`,
        `${moment.utc(endDate, 'YYYY-MM-DD').toDate().getTime() / 1000}`
      ];
      const filterByDates: IFilter[] = [];
      filterByDates.push({
        field: 'start_date',
        type: 'rd',
        value: dateFilter
      });
      filterByDates.push({
        field: 'end_date',
        type: 'rd',
        value: dateFilter
      });
      // Request to get the intensity metric value by id and year
      if (intensityMetric1) {
        requestIntensityMetric1 = getIntensityMetricsByIdPaginated(
          intensityMetric1,
          1,
          100,
          filterByDates
        );
      }

      requests.push(requestIntensityMetric1);

      let requestIntensityMetric2 = null;
      if (intensityMetric2) {
        requestIntensityMetric2 = getIntensityMetricsByIdPaginated(
          intensityMetric2,
          1,
          100,
          filterByDates
        );
      }
      requests.push(requestIntensityMetric2);

      let requestPurchases = null;
      if (categories.includes('purchases') || categories.includes('water')) {
        requestPurchases = getTotalImpactPurchases(
          startDateToDate,
          endDateToDate,
          foundOrg.id ?? '',
          groupDataMode,
          'ghg',
          true
        );
      }
      requests.push(requestPurchases);

      // check organization create date
      const organizationCreatedAt = convertStringToDate(
        convertDateBackToFront(foundOrg.created_at)
      );
      let combustion = false;
      // 27/05/2024 with moment to date
      const limitDate = moment('27/05/2024', 'DD/MM/YYYY');

      if (organizationCreatedAt < limitDate.toDate() || flags?.renewableEnergyKPI) {
        combustion = true;
      }
      // Fetch Renewable Energy KPI
      const requestRenewableEnergyKPI = getRenewableEnergyKPI(
        startDateToDate,
        endDateToDate,
        combustion,
        true
      );
      requests.push(requestRenewableEnergyKPI);

      let requestTransportDistributionDownstream = null;
      if (categories.includes('transport_distribution_downstream')) {
        requestTransportDistributionDownstream = apiFetch(
          'POST',
          '/total_impacts/transport_distribution',
          {
            start_date: startDateToDate.toISOString(),
            end_date: endDateToDate.toISOString(),
            organization_id: foundOrg.id,
            direction: 'downstream',
            switch: groupDataMode
          }
        );
      }
      requests.push(requestTransportDistributionDownstream);

      let requestTransportDistributionUpstream = null;
      if (categories.includes('transport_distribution_upstream')) {
        requestTransportDistributionUpstream = apiFetch(
          'POST',
          '/total_impacts/transport_distribution',
          {
            start_date: startDateToDate.toISOString(),
            end_date: endDateToDate.toISOString(),
            organization_id: foundOrg.id,
            direction: 'upstream',
            switch: groupDataMode
          }
        );
      }
      requests.push(requestTransportDistributionUpstream);

      let requestBusinessTravels = null;
      if (categories.includes('business_travel')) {
        requestBusinessTravels = getBusinessTravelsGroupedByTransport(
          foundOrg.id ?? '',
          startDateToDate,
          endDateToDate,
          groupDataMode,
          true
        );
      }
      requests.push(requestBusinessTravels);

      let requestEmployees = null;
      if (categories.includes('employees')) {
        requestEmployees = getEmployees(
          foundOrg.id ?? '',
          startDateToDate,
          endDateToDate,
          groupDataMode,
          true
        );
      }
      requests.push(requestEmployees);

      let requestInvestments = null;
      if (groupDataMode === GroupDataMode.INVESTMENT) {
        requestInvestments = getTotalImpactsInvestments(
          startDateToDate,
          endDateToDate,
          foundOrg.id ?? '',
          true
        );
      }

      requests.push(requestInvestments);

      requests.push(
        getAssumptions({ start_date: startDateToDate.getTime(), end_date: endDateToDate.getTime() })
      );

      let requestWastes = null;
      if (categories.includes('wastes')) {
        requestWastes = apiFetch('POST', '/total_impacts/wastes', {
          start_date: startDateToDate.toISOString(),
          end_date: endDateToDate.toISOString(),
          organization_id: foundOrg.id,
          switch: groupDataMode
        });
      }
      requests.push(requestWastes);

      requests.push(getGWPTable(true));

      requests.push(getIPCCVersion(true));

      if (!requests) return;

      const [
        responseMatrix,
        responseFacilities,
        responseVehicles,
        responseElectricity,
        responseBiomass,
        responseDataQualityAccuracy,
        responseIntensityMetric1,
        responseIntensityMetric2,
        responsePurchases,
        responseRenewableEnergyKPI,
        responseTransportDistributionDownstream,
        responseTransportDistributionUpstream,
        responseBusinessTravels,
        responseEmployees,
        responseInvestments,
        responseAssumptions,
        responseWastes,
        responseGWPTable,
        responseIPCCVersion
      ] = await Promise.all(requests);

      const responseGetOrganizationEfs = (await Promise.all(orgEfsRequests))
        .flat()
        .filter((value): value is EmissionFactor => Boolean(value));

      let totalCo2 = 0;

      const matrixData = {
        ...(responseMatrix?.data as Holding),
        parent_co2e: responseMatrix?.data.parent_co2e?.map((elem: any) => ({
          ...elem,
          category: elem.category
            .replace(/_in_labore/g, '')
            .replace(/_in_itinere/, '')
            .replace('passenger', 'vehicles')
            .replace('freight', 'vehicles')
            .replace('travels', 'business_travel')
        })),
        childs_info: responseMatrix?.data.childs_info?.map((elem: OrgInfo) => ({
          ...elem,
          co2e: elem.co2e.map((elem2) => ({
            ...elem2,
            category: elem2.category
              .replace(/_in_labore/g, '')
              .replace(/_in_itinere/, '')
              .replace('passenger', 'vehicles')
              .replace('freight', 'vehicles')
              .replace('travels', 'business_travel')
          }))
        }))
      };
      totalCo2 = groupByDataHolding(matrixData, categories);

      const investmentsParsed = matrixData.childs_info.map((investment: OrgInfo) => {
        let investmentCo2 = 0;
        investment.co2e.forEach((elem) => {
          investmentCo2 += elem.total_emissions;
        });
        return {
          ...investment,
          id: investment.org_id,
          co2e: investmentCo2
        };
      });
      setInvestments(
        investmentsParsed.sort((a: InvestmentReport, b: InvestmentReport) => b.co2e - a.co2e)
      );

      setOrganizationCo2e(totalCo2);

      if (responseAssumptions) {
        setAssumptions(responseAssumptions);
      }

      if (responseFacilities) {
        const facilitiesSorted = responseFacilities.data.sort(
          (a: FacilityReport, b: FacilityReport) => b.total_emissions - a.total_emissions
        );
        setFacilities(facilitiesSorted);
      }

      if (responseVehicles) {
        const vehiclesSorted = responseVehicles.data.sort(
          (a: VehicleReportGHG, b: VehicleReportGHG) => b.total_emissions - a.total_emissions
        );
        setVehicles(vehiclesSorted);
      }

      if (responseElectricity) {
        setDataElectricity(
          responseElectricity.data.map((elem: { year: number; month: number }) => ({
            ...elem,
            date: new Date(elem.year, elem.month - 1)
          }))
        );
      }

      setTotalBiomass(responseBiomass.data.biomass);

      setOrganizationEfs(
        responseGetOrganizationEfs?.sort((a, b) => {
          // sort by source and ef_source
          if (a.source < b.source) {
            return -1;
          } else if (a.source > b.source) {
            return 1;
          } else {
            if (a.ef_source < b.ef_source) {
              return -1;
            } else if (a.ef_source > b.ef_source) {
              return 1;
            } else {
              return 0;
            }
          }
        })
      );

      if (responseDataQualityAccuracy) {
        setDataAccuracy(responseDataQualityAccuracy.data.data_quality_uncertainty);
      }

      if (responseIntensityMetric1 || responseIntensityMetric2) {
        if (responseIntensityMetric1 && responseIntensityMetric1.items.length > 0) {
          const foundIntensityMetric1 = responseIntensityMetric1.items[0];
          if (foundIntensityMetric1) {
            setIntensityMetric1({
              id: foundIntensityMetric1.group_id ?? '',
              name: `${limitString(foundIntensityMetric1.unit ?? '', 25)}`,
              value: foundIntensityMetric1.value / 1000,
              unit: 't CO₂ eq.',
              start_date: foundIntensityMetric1.start_date,
              end_date: foundIntensityMetric1.end_date
            });
          }
        }

        if (responseIntensityMetric2 && responseIntensityMetric2.items.length > 0) {
          const foundIntensityMetric2 = responseIntensityMetric2.items[0];
          if (foundIntensityMetric2) {
            setIntensityMetric2({
              id: foundIntensityMetric2.group_id ?? '',
              name: `${limitString(foundIntensityMetric2.unit ?? '', 25)}`,
              value: foundIntensityMetric2.value / 1000,
              unit: 't CO₂ eq.',
              start_date: foundIntensityMetric2.start_date,
              end_date: foundIntensityMetric2.end_date
            });
          }
        }
      }

      if (responsePurchases) {
        setPurchases(responsePurchases.data);
      }

      if (responseRenewableEnergyKPI) {
        setRenewableEnergy(responseRenewableEnergyKPI.data.electricity.renewable_ratio * 100);
      }
      if (responseTransportDistributionDownstream) {
        setShipments(responseTransportDistributionDownstream.data);
      }
      if (responseTransportDistributionUpstream) {
        setDeliveries(responseTransportDistributionUpstream.data);
      }
      if (responseBusinessTravels) {
        const businessTravelsSorted = responseBusinessTravels.data.sort(
          (a: BusinessTravelGHGReport, b: BusinessTravelGHGReport) => b.co2e - a.co2e
        );
        setBusinessTravels(businessTravelsSorted);
      }

      if (responseEmployees) {
        const employeesSorted = responseEmployees.data.sort(
          (a: EmployeesGHGReport, b: EmployeesGHGReport) => b.co2e - a.co2e
        );
        setEmployees(employeesSorted);
      }

      if (responseInvestments) {
        setInvestmentTotalImpacts(responseInvestments?.data);
      }

      if (responseWastes) {
        setWastes(responseWastes.data);
      }

      if (responseGWPTable) {
        setGwpTable(responseGWPTable.data);
      }

      if (responseIPCCVersion) {
        setIpccVersion(responseIPCCVersion.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!foundOrg?.id || !flags || !Object.keys(flags).length) return;

    // call the function
    fetchData();
  }, [groupDataMode]);

  const groupByDataHolding = (dataHolding: Holding, categoriesToShow: string[]) => {
    let totalCo2 = 0;

    const groupByScope = [
      { scope: 1, total_emissions: 0, measured: false },
      { scope: 2, total_emissions: 0, measured: false },
      { scope: 3, total_emissions: 0, measured: false }
    ];

    const groupByCategory = categoriesWithScopes.filter((elem) =>
      categoriesToShow.includes(elem.category)
    );

    dataHolding.childs_info.forEach((child) => {
      child.co2e.forEach((elem) => {
        if (!categoriesToShow.includes(elem.category)) return;

        totalCo2 += elem.total_emissions;
        const categoryParsed = elem.category
          .replace(/_in_labore/g, '')
          .replace(/_in_itinere/, '')
          .replace('water', 'purchases')
          .replace('passenger', 'vehicles')
          .replace('freight', 'vehicles')
          .replace('travels', 'business_travel');

        if (groupDataMode === GroupDataMode.TOTAL) {
          if (elem.scope === 1) {
            groupByScope[0].total_emissions += elem.total_emissions;
            groupByScope[0].measured = true;
          }
          if (elem.scope === 2) {
            groupByScope[1].total_emissions += elem.total_emissions;
            groupByScope[1].measured = true;
          }
          if (elem.scope === 3) {
            groupByScope[2].total_emissions += elem.total_emissions;
            groupByScope[2].measured = true;
          }

          const foundCategoryIndex = groupByCategory.findIndex(
            (elem2) => elem2.category === categoryParsed
          );
          if (foundCategoryIndex !== -1) {
            groupByCategory[foundCategoryIndex].total_emissions += elem.total_emissions;
            groupByCategory[foundCategoryIndex].measured = true;
          } else {
            groupByCategory.push({
              category: categoryParsed,
              total_emissions: elem.total_emissions,
              scope: elem.scope,
              measured: true
            });
          }
        } else {
          // Only to investments
          groupByScope[2].total_emissions += elem.total_emissions;
          groupByScope[2].measured = true;
          const foundCategoryIndex = groupByCategory.findIndex(
            (elem2) => elem2.category === 'investment'
          );
          if (foundCategoryIndex !== -1) {
            groupByCategory[foundCategoryIndex].total_emissions += elem.total_emissions;
            groupByCategory[foundCategoryIndex].measured = true;
          } else {
            groupByCategory.push({
              category: 'investment',
              total_emissions: elem.total_emissions,
              scope: elem.scope,
              measured: true
            });
          }
        }
      });
    });

    dataHolding.parent_co2e?.forEach((elem) => {
      if (!categoriesToShow.includes(elem.category)) return;
      totalCo2 += elem.total_emissions;
      const categoryParsed = elem.category
        .replace(/_in_labore/g, '')
        .replace(/_in_itinere/, '')
        .replace('water', 'purchases')
        .replace('passenger', 'vehicles')
        .replace('freight', 'vehicles')
        .replace('travels', 'business_travel');
      if (elem.scope === 1) {
        groupByScope[0].total_emissions += elem.total_emissions;
        groupByScope[0].measured = true;
      }
      if (elem.scope === 2) {
        groupByScope[1].total_emissions += elem.total_emissions;
        groupByScope[1].measured = true;
      }
      if (elem.scope === 3) {
        groupByScope[2].total_emissions += elem.total_emissions;
        groupByScope[2].measured = true;
      }

      const foundCategoryIndex = groupByCategory.findIndex(
        (elem2) => elem2.category === categoryParsed
      );

      if (foundCategoryIndex !== -1) {
        groupByCategory[foundCategoryIndex].total_emissions += elem.total_emissions;
        groupByCategory[foundCategoryIndex].measured = true;
      } else {
        groupByCategory.push({
          category: categoryParsed,
          total_emissions: elem.total_emissions,
          scope: elem.scope,
          measured: true
        });
      }
    });

    setDataScopes(groupByScope);
    setDataCategories(groupByCategory);
    return totalCo2;
  };

  const query = new URLSearchParams(window.location.search);
  const showTemporalComparative = query.get('showTemporalComparative');

  return {
    reportData,
    facilities,
    vehicles,
    dataElectricity,
    dataScopes,
    dataCategories,
    totalBiomass,
    organizationEfs,
    dataAccuracy,
    intensityMetric1,
    intensityMetric2,
    purchases,
    organizationCo2e,
    renewableEnergy,
    shipments,
    deliveries,
    businessTravels,
    employees,
    investments,
    investmentTotalImpacts,
    showTemporalComparative,
    assumptions,
    wastes,
    gwpTable,
    ipccVersion,
    loading
  };
};

export default useGetData;
