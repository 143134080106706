import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import { gray_color, scope1_color, scope2_color, scope3_color } from '../../../../styles/colors';
import { FacilityDashboard } from '../../../../types/entities/facility';

import formatNumber from '../../../../utils/formatNumber';
import numberToDecimal from '../../../../utils/numberToDecimal';
import ScopeTag from '../../../ui/scopeTag/ScopeTag';
import StackedBar from '../bar/StackedBar';
import CardHeader from '../cardHeader/CardHeader';
import './styles.scss';

type Bar = {
  value: number;
  color: string;
};

type Props = {
  facilities: FacilityDashboard[];
  categoriesToShow: CategoryWithScope[];
};
const FacilitiesDistribution = ({ facilities, categoriesToShow }: Props) => {
  const { t } = useTranslation();

  let pieChartData = [{ title: '', value: 100, color: gray_color }];
  let totalCo2 = 0;
  let scope1 = 0;
  let scope2 = 0;
  let scope3 = 0;

  type Category = {
    [key: string]: number;
  };

  const categories: Category = {
    stationary: 0,
    recharge: 0,
    waste_water_treatments: 0,
    electricity: 0,
    water: 0,
    wastes: 0
  };

  const showStationary = categoriesToShow.find((category) => category.category === 'stationary');
  const showRecharge = categoriesToShow.find((category) => category.category === 'recharge');
  const showElectricity = categoriesToShow.find((category) => category.category === 'electricity');
  const showWater = categoriesToShow.find((category) => category.category === 'water');
  const showWastes = categoriesToShow.find((category) => category.category === 'wastes');
  const showWasteWaterTreatment = categoriesToShow.find(
    (category) => category.category === 'waste_water_treatments'
  );

  facilities.forEach((facility) => {
    if ('stationary' in facility.co2e && showStationary) {
      scope1 += facility.co2e.stationary;
      categories.stationary += facility.co2e.stationary;
      totalCo2 += facility.co2e.stationary;
    }
    if ('recharge' in facility.co2e && showRecharge) {
      scope1 += facility.co2e.recharge;
      categories.recharge += facility.co2e.recharge;
      totalCo2 += facility.co2e.recharge;
    }
    if ('electricity' in facility.co2e && showElectricity) {
      scope2 += facility.co2e.electricity;
      categories.electricity += facility.co2e.electricity;
      totalCo2 += facility.co2e.electricity;
    }
    if ('water' in facility.co2e && showWater) {
      scope3 += facility.co2e.water;
      categories.water += facility.co2e.water;
      totalCo2 += facility.co2e.water;
    }
    if ('wastes' in facility.co2e && showWastes) {
      scope3 += facility.co2e.wastes;
      categories.wastes += facility.co2e.wastes;
      totalCo2 += facility.co2e.wastes;
    }
    if ('waste_water_treatments' in facility.co2e && showWasteWaterTreatment) {
      scope1 += facility.co2e.waste_water_treatments;
      categories.waste_water_treatments += facility.co2e.waste_water_treatments;
      totalCo2 += facility.co2e.waste_water_treatments;
    }
  });
  pieChartData = [
    { title: '', value: (scope1 * 100) / totalCo2, color: scope1_color },
    { title: '', value: (scope2 * 100) / totalCo2, color: scope2_color },
    { title: '', value: (scope3 * 100) / totalCo2, color: scope3_color }
  ];
  pieChartData.sort((a, b) => {
    return b.value - a.value;
  });

  const totalCo2PieChart = totalCo2 / 1000;
  const unit = 't CO₂ eq.';

  const renderColour = (category: string) => {
    let color = scope3_color;

    if (
      category === 'stationary' ||
      category === 'recharge' ||
      category === 'waste_water_treatments'
    ) {
      color = scope1_color;
    }
    if (category === 'electricity') {
      color = scope2_color;
    }
    return color;
  };

  const renderCo2Information = () => {
    const value = totalCo2PieChart;
    let fontSize = 20;
    const maxDigits = 5;
    if (numberToDecimal(totalCo2).toString().length > maxDigits) {
      fontSize = 20 - (numberToDecimal(totalCo2).toString().length - maxDigits) - 2;
    }
    return (
      <div className='co2-information'>
        <div className='co2-value co2-dashboard-font-small' style={{ fontSize: `${fontSize}px` }}>
          {formatNumber(value)}
        </div>
        <span className='co2-units on-light-text-color body1-font'>{unit}</span>
      </div>
    );
  };

  const renderScopesInfo = () => {
    return (
      <div className='scopes-wrapper'>
        <ScopeTag scope={1} value={scope1 / 1000} textLookAndFeel='secondary' />
        <ScopeTag scope={2} value={scope2 / 1000} textLookAndFeel='secondary' />
        <ScopeTag scope={3} value={scope3 / 1000} textLookAndFeel='secondary' />
      </div>
    );
  };

  const renderIcon = (type: string) => {
    const icons = {
      recharge: '/images/icons/velocimeter.svg',
      electricity: '/images/icons/thunder50.svg',
      water: '/images/icons/water50.svg',
      heat: '/images/icons/fire50.svg',
      wastes: '/images/icons/waste.svg',
      waste_water_treatments: '/images/icons/road.svg'
    };

    return icons[type as keyof typeof icons] || '/images/icons/fire50.svg';
  };

  const renderBarSection = (total: number, values: Bar[], type: string, totalByType: number) => {
    return (
      <div className='card-info-rows' key={type}>
        <span className='name-logo body1-bold-font'>
          <img src={renderIcon(type)} alt='building' />
          {t(`categories.${type}`)}
        </span>
        <div className='stacked-bar-wrapper'>
          <StackedBar total={total} values={values} remaining={total - totalByType} />
          <span className='body1-font'>{formatNumber(totalByType / 1000)}</span>
        </div>
      </div>
    );
  };

  return (
    <div className='facilities-distribution measure-detail-report'>
      <div className='card main-bg-color'>
        <CardHeader
          title={t('report.facilities')}
          description={t('report.facilitiesDescription')}
        />
        <div className='facilities-distribution-wrapper'>
          <div className='rows-wrapper card-info-co2'>
            <div className='co2-progress-bar'>
              <PieChart // your data
                data={pieChartData}
                // width and height of the view box
                // viewBoxSize={[100, 100]}
                lineWidth={24}
                totalValue={100}
                rounded
                animate></PieChart>
              {renderCo2Information()}
            </div>
            {renderScopesInfo()}
          </div>
          <div className='facilities-distribution-categories'>
            <div className='card-info-columns'>
              <span className='body2-font'>{t('report.distribution')}</span>
              <span className='body2-font'>t CO₂ eq.</span>
            </div>
            <div className='rows-wrapper on-card-gray-bg-color'>
              {Object.entries(categories).map(
                ([key, value]) =>
                  categoriesToShow.find((category) => category.category === key) &&
                  renderBarSection(
                    totalCo2,
                    [
                      {
                        color: renderColour(key),
                        value
                      }
                    ],
                    key,
                    numberToDecimal(categories[key as keyof typeof categories])
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilitiesDistribution;
