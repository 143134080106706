import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gray_color, scope1_color, scope2_color, scope3_color } from '../../../../styles/colors';
import { TransportDistributionReport } from '../../../../types/entities/shipment';
import formatNumber from '../../../../utils/formatNumber';
import numberToDecimal, { numberToDecimalNonZero } from '../../../../utils/numberToDecimal';
import Icon from '../../../ui/icon/Icon';
import StackedBar from '../bar/StackedBar';
import CardHeader from '../cardHeader/CardHeader';
import CardTotalCo2 from '../cardTotalCo2/CardTotalCo2';

type DeliveryGroupById = {
  id: string;
  co2e: number;
  name: string;
};
type Bar = {
  value: number;
  color: string;
};
type Props = {
  deliveries: TransportDistributionReport;
};

function Deliveries({ deliveries }: Props) {
  const { t } = useTranslation();
  const [totalCo2, setTotalCo2] = useState(0);
  const [totalKg, setTotalKg] = useState(0);

  useEffect(() => {
    let totalCo2Sum = 0;
    let totalKgSum = 0;

    deliveries?.monthly.forEach((delivery) => {
      totalCo2Sum += delivery.co2e;
      totalKgSum += delivery.quantity_transported;
    });
    setTotalCo2(totalCo2Sum);
    setTotalKg(totalKgSum);
  }, []);

  const renderBarSection = (total: number, values: Bar[], totalByElement: number) => {
    return (
      <div className='card-info-rows '>
        <span className='name-logo body1-bold-font'>
          <Icon icon='box' color='gradient' />
          {t(`report.deliveriesShort`)}
        </span>
        <StackedBar total={total} values={values} remaining={total - totalByElement} />
        <span className='body1-font'>{formatNumber(totalByElement / 1000)}</span>
      </div>
    );
  };

  const calculateRemaining = () => {
    let top3Co2 = 0;
    if (deliveries.top3.length < 3) {
      return 0;
    }
    deliveries.top3.forEach((elem, index) => {
      if (index < 3) {
        top3Co2 += elem.co2e;
      }
    });
    return totalCo2 - top3Co2;
  };

  const colors = [scope2_color, scope3_color, scope1_color, gray_color];

  const deliveryColors = deliveries?.top3?.map((delivery, index) => {
    return {
      id: delivery.name || `${delivery.quantity_transported} ${t(`units.kilogram_(kg)`)}`,
      name: delivery.name,
      co2e: delivery.co2e,
      color: colors[index]
    };
  });
  deliveryColors.push({
    id: 'remaining',
    name: t('report.remaining'),
    color: gray_color,
    co2e: calculateRemaining()
  });

  return (
    <div className='measure-detail-report'>
      <div className='card main-bg-color'>
        <CardHeader
          title={t('report.deliveries')}
          description={t('report.deliveriesDescription')}
        />
        <CardTotalCo2 totalCo2={totalCo2} />
        <div className='card-info-columns'>
          <span className='body2-font'>{t('report.top3Deliveries')}</span>
          <span className='body2-font'>t CO₂ eq.</span>
          <span className='body2-font'>% Total</span>
        </div>
        <div className='rows-wrapper on-card-gray-bg-color'>
          {deliveries.top3.map((delivery) => (
            <div
              className='card-info-rows'
              key={delivery.name || `${delivery.quantity_transported} ${t(`units.kilogram_(kg)`)}`}>
              <span className='name-logo body1-bold-font'>
                <Icon icon='box' color='gradient' />
                {delivery.name}
              </span>
              <span className='body1-font'>{formatNumber(delivery.co2e / 1000)}</span>
              <span className='percentage body1-font tag-bg-text-color'>{`${
                totalCo2 ? formatNumber((delivery.co2e * 100) / totalCo2) : 0
              } %`}</span>
            </div>
          ))}
          <div className='card-info-rows '>
            <span className='name-logo body1-bold-font'>
              <Icon icon='box' color='gradient' />
              {t('report.remaining')}
            </span>
            <span className='body1-font'>
              {formatNumber(numberToDecimalNonZero(calculateRemaining() / 1000))}
            </span>
            <span className='percentage body1-font tag-bg-text-color'>{`${
              totalCo2 ? formatNumber((calculateRemaining() * 100) / totalCo2) : 0
            } %`}</span>
          </div>
        </div>
      </div>
      <div className='card main-bg-color '>
        <div className='scope-tag'>
          <div className='text-tag font-10 tag-bg-text-color tag'>{`${t(
            'measureMain.scope'
          )} 3`}</div>
        </div>
        <div className='card-info-columns'>
          <span className='body2-font'>{t('report.distribution')}</span>
          <span className='body2-font'>t CO₂ eq.</span>
        </div>
        <div className='rows-wrapper on-card-gray-bg-color'>
          {renderBarSection(
            totalCo2,
            deliveryColors?.map((elem) => {
              return {
                color: elem.color,
                value: elem.co2e
              };
            }),
            numberToDecimal(totalCo2)
          )}
        </div>
        <div className='card-info-columns card-footer'>
          <div className='body2-font'>{t('report.legend')}</div>
        </div>
        <div className='rows-wrapper on-card-gray-bg-color legends'>
          <div className='card-info-rows'>
            {deliveryColors?.map((delivery) => (
              <div className='legend body1-font' key={delivery.id}>
                <div className='dot round' style={{ backgroundColor: delivery.color }} />
                {delivery.name}
              </div>
            ))}
          </div>
        </div>
        <div className='card-footer'>
          <span className='body2-font'>{t('report.total')}</span>
        </div>
        <div className='total-info-cards'>
          <div className='total-info on-card-gray-bg-color'>
            <span className='body2-font'>{t('report.totalDeliveries')}</span>
            <span className='highlight-report-text-color body1-bold-font'>{deliveries.total}</span>
          </div>
          <div className='total-info on-card-gray-bg-color'>
            <span className='body2-font'>kg CO₂ eq./kg</span>
            <span className='highlight-report-text-color body1-bold-font'>
              {totalCo2 && totalKg ? formatNumber(totalCo2 / totalKg) : 0}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deliveries;
