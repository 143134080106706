import { ReactNode } from 'react';
type Props = {
  className?: string;
  children?: ReactNode;
};

const InputLabel = ({ className, children }: Props) => {
  return <div className={`px-2 ${className}`}>{children}</div>;
};

export default InputLabel;
