import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { switchOrganization } from '../../../../../actions/auth';
import { setNotification } from '../../../../../actions/notification';
import { ROUTES } from '../../../../../constants/routes';
import { UserContext } from '../../../../../context/userContext';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { HoldingCompany } from '../../../../../types/entities/holdings';
import { InputSize } from '../../../../../types/utilsEnums/input';
import apiFetch from '../../../../../utils/apiFetch';
import numberToDecimal from '../../../../../utils/numberToDecimal';
import Button from '../../../../ui/button/Button';
import ButtonDropdown from '../../../../ui/buttonDropdown/ButtonDropdown';
import CardCTA from '../../../../ui/cards/cardCTA/CardCTA';
import CardWrapper from '../../../../ui/cards/common/CardWrapper';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import LoaderTables from '../../../../ui/loaders/loaderTables/LoaderTables';
import Modal from '../../../../ui/modal/Modal';
import Table from '../../../../ui/table/Table';
import TotalLegendV2 from '../../../../ui/totalLegend/TotalLegendV2';
import DeleteRequest from '../../common/deleteRequest/DeleteRequest';
import InviteByMail from '../../common/inviteByMail/InviteByMail';
import UnbindCompany from '../../common/unbindCompany/UnbindCompany';
import ConfigureCompany from '../configureCompany/ConfigureCompany';
import useColumns from './hooks/useColumns';
import './styles.scss';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';

function CompaniesHolding() {
  const { t, i18n } = useTranslation();

  const user = useContext(UserContext);

  const flags = useFeatureFlags();
  const selectedOrganization = useSelectedOrganization();

  if (
    !selectedOrganization ||
    !user?.selectedOrganization ||
    (selectedOrganization.role !== 'owner' && selectedOrganization.role !== 'parent')
  ) {
    return null;
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tomorrow = new Date();
  tomorrow.setHours(0, 0, 0, 0);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [companies, setCompanies] = useState<HoldingCompany[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [startDate, setStartDate] = useState<Date>(new Date('2021-01-01'));
  const [endDate, setEndDate] = useState<Date>(tomorrow);
  const [filters, setFilters] = useState<SelectOptionFormat[]>([]);
  const [showInviteByMailModal, setShowInviteByMailModal] = useState(false);
  const [companyToConfigure, setCompanyToConfigure] = useState('');
  const [requestToDelete, setRequestToDelete] = useState('');
  const [companyToUnbind, setCompanyToUnbind] = useState('');

  const fetchData = async () => {
    try {
      setLoading(true);
      const url = `/matrices/${user?.selectedOrganization}/status`;
      const response = await apiFetch('GET', url, null, {
        'x-organization-id': user?.selectedOrganization
      });
      setCompanies(response.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderCompanyName = (company: HoldingCompany) => {
    return (
      <div className='icon-text-wrapper'>
        <img src='/images/icons/holdingGray.svg' alt='holding' />
        <span>
          {`${company.child_name.substring(0, 30)}${company.child_name.length > 30 ? '...' : ''}` ||
            '-'}
        </span>
      </div>
    );
  };

  const renderStatus = (status: string, child_id: string) => {
    return (
      <div
        className={`${
          status === 'pending' && child_id ? 'highlight-text-color pointer headline4-font' : ''
        }`}
        onClick={() => {
          if (status === 'pending' && child_id) {
            setCompanyToConfigure(child_id);
          }
        }}>
        {child_id && status === 'pending' ? t('companies.configure') : t(`companies.${status}`)}
      </div>
    );
  };

  const renderEditComponent = (company: HoldingCompany) => {
    if (company.status === 'pending' && !company.child_id) {
      return (
        <ButtonDropdown
          button={<img src='/images/icons/editPen.svg' height={19} width={18} alt='edit-pen' />}
          options={[
            {
              id: `${company.child_name}-delete`,
              name: t('companies.delete'),
              onClick: () => {
                setRequestToDelete(company.child_name); // This is the email of the user that we invited
              }
            },
            {
              id: `${company.child_name}-resend`,
              name: t('companies.resend'),
              onClick: async () => {
                await apiFetch(
                  'POST',
                  '/invite_user',
                  {
                    invited_by_user_id: user?.id,
                    email: company.child_name,
                    organization_id: user?.selectedOrganization,
                    role: 'child',
                    lang: i18n.resolvedLanguage
                  },
                  {
                    'x-organization-id': user?.selectedOrganization
                  }
                );
                dispatch(setNotification(t('notification.inviteCompany')));
              }
            }
          ]}
        />
      );
    } else if (company.status === 'accepted') {
      return (
        <div className='flex' style={{ alignItems: 'center', gap: '0.5rem' }}>
          <img
            src='/images/icons/chain.svg'
            alt='chain'
            className='img-margin-right pointer'
            onClick={() => setCompanyToUnbind(company.child_id)}
            width={16}
          />
          <img
            src='/images/icons/redirect.svg'
            alt='redirect'
            className='pointer'
            onClick={async () => {
              await dispatch(switchOrganization(company.child_id, flags?.sotDcycleDemos));

              navigate(ROUTES.IMPROVE_DASHBOARD);
            }}
            width={16}
          />
        </div>
      );
    } else if (company.status === 'pending' && company.child_id) {
      return (
        <div className='flex' style={{ alignItems: 'center', gap: '0.5rem' }}>
          <img
            src='/images/icons/chainGray.svg'
            alt='chain'
            className='img-margin-right'
            width={16}
          />
          <img src='/images/icons/redirectGray.svg' alt='redirect' width={16} />
        </div>
      );
    }
  };

  const handleCloseInviteByMailModal = () => {
    setShowInviteByMailModal(false);
  };

  const handleOpenInviteByMailModal = () => {
    setShowInviteByMailModal(true);
  };

  const handleCloseConfigureCompanyModal = () => {
    setCompanyToConfigure('');
  };

  const handleCloseDeleteRequestModal = () => {
    setRequestToDelete('');
  };

  const handleOpenUnBindCompanyModal = (id: string) => {
    setCompanyToUnbind(id);
    handleCloseConfigureCompanyModal();
  };

  const handleCloseUnbindCompanyModal = () => {
    setCompanyToUnbind('');
  };

  const addCompanies = (companiesToAdd: HoldingCompany[]) => {
    setCompanies([...companies, ...companiesToAdd]);
    setShowInviteByMailModal(false);
  };

  const updateCompanyStatus = (id: string, childCompany: HoldingCompany) => {
    const companiesOld = [...companies];
    const index = companiesOld.findIndex((element) => element.child_id === id);
    if (index < 0) return;
    companiesOld[index] = {
      ...companiesOld[index],
      status: childCompany.status,
      tag: childCompany.tag,
      share: childCompany.share,
      investment_scopes: childCompany.investment_scopes
    };
    setCompanies(companiesOld);
    handleCloseConfigureCompanyModal();
    dispatch(setNotification(t('notification.companyConfigured')));
  };

  const unBindCompany = (id: string) => {
    const companiesOld = [...companies];
    const index = companiesOld.findIndex((element) => element.child_id === id);
    if (index < 0) return;
    companiesOld.splice(index, 1);
    setCompanies(companiesOld);
    handleCloseUnbindCompanyModal();
    dispatch(setNotification(t('notification.companyUnbinded')));
  };

  const deleteRequest = (id: string) => {
    const companiesOld = [...companies];
    const index = companiesOld.findIndex((element) => element.child_name === id);
    companiesOld.splice(index, 1);
    setCompanies(companiesOld);
    handleCloseDeleteRequestModal();
    dispatch(setNotification(t('notification.requestDeleted')));
  };

  const navigateToCreateOrganizationHolding = () => {
    navigate(
      `${ROUTES.CREATE_ORGANIZATION_HOLDING}/${user?.selectedOrganization}/user/${user?.id}`
    );
  };

  const columns = useColumns();

  if (!user?.selectedOrganization) return null;

  const foundHolding = user?.organizations?.find(
    (organization) => organization.id === user?.selectedOrganization
  );

  if (!foundHolding) return null;

  // Filter function to check if child org name matches searchValue state variable, using useCallback
  // to avoid unnecessary re-renders, and memoize the function
  const filterCompanies = useCallback(
    (company: HoldingCompany) => {
      const includesName = company.child_name
        ? company.child_name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
        : false;
      let includesFilter = true;
      if (filters.length > 0) {
        includesFilter = !!filters.find((filter) => filter.id === company.status);
      }
      const inRange = true;

      return includesName && includesFilter && inRange;
    },
    [endDate, filters, searchValue, startDate]
  );

  const filteredCompanies = useMemo(() => {
    return companies.filter(filterCompanies);
  }, [companies, filterCompanies]);

  return (
    <>
      <CardCTA>
        <CardCTA.Header>
          <span className='headline4-font'>{t('companies.start')}</span>
          <span className='subtitle3-font'>
            {t('companies.startDescription').replace('{{organization_vat}}', foundHolding.vat)}
          </span>
        </CardCTA.Header>
        <CardCTA.Buttons>
          <Button
            lookAndFeel='primary'
            text={t('companies.inviteByMail')}
            size='small'
            onClick={handleOpenInviteByMailModal}
          />
          <Button
            lookAndFeel='secondary'
            text={t('companies.addManual')}
            size='small'
            onClick={navigateToCreateOrganizationHolding}
            style={{ width: '7%' }}
          />
        </CardCTA.Buttons>
      </CardCTA>
      {loading && <LoaderTables />}
      {!loading && (
        <CardWrapper
          style={{ padding: '2rem 1rem', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <FormText
                icon={'/images/icons/search.svg'}
                placeholder={t('companies.company')}
                value={searchValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                size={InputSize.SMALL}
              />
            </div>
            <TotalLegendV2
              totalLabel={'Total'}
              totalElements={[
                {
                  value: filteredCompanies.length,
                  label: t('companies.companies')
                }
              ]}
            />
          </div>
          <Table
            columns={columns}
            loading={loading}
            data={filteredCompanies.map((company) => {
              let companyShare = '0';
              if (company.share !== null && company.share !== undefined && company?.share >= 0) {
                companyShare = numberToDecimal(company.share * 100).toString();
              }
              if (company.status === 'pending') {
                companyShare = '-';
              }
              return {
                ...company,
                child_name: renderCompanyName(company),
                status: renderStatus(company.status, company.child_id),
                tag: (
                  <div
                    className={
                      company.tag ? 'text-tag body1-font tag-bg-text-color  corner-position' : ''
                    }>
                    {company.tag ?? '-'}
                  </div>
                ),
                investment_scopes: company.investment_scopes
                  ? company.investment_scopes.toString()
                  : '-',
                share: companyShare,
                edit: renderEditComponent(company),
                disabled: company.status === 'pending' && !company.child_id,
                id: company.child_id
              };
            })}
          />
        </CardWrapper>
      )}
      <Modal show={showInviteByMailModal} onClose={handleCloseInviteByMailModal}>
        {user && <InviteByMail user={user} addCompanies={addCompanies} />}
      </Modal>
      <Modal
        show={companyToConfigure !== ''}
        onClose={handleCloseConfigureCompanyModal}
        width='428px'
        maxWidth='428px'>
        <ConfigureCompany
          company={companyToConfigure}
          handleOpenUnBindCompanyModal={handleOpenUnBindCompanyModal}
          updateCompanyStatus={updateCompanyStatus}
          holdingId={user?.selectedOrganization}
        />
      </Modal>
      <Modal
        show={requestToDelete !== ''}
        onClose={handleCloseDeleteRequestModal}
        width='428px'
        maxWidth='428px'>
        <DeleteRequest
          requestToDelete={requestToDelete}
          user={user}
          deleteRequest={deleteRequest}
        />
      </Modal>
      <Modal
        show={companyToUnbind !== ''}
        onClose={handleCloseUnbindCompanyModal}
        width='428px'
        maxWidth='428px'>
        <UnbindCompany
          companyToUnbind={companyToUnbind}
          user={user}
          unbindCompany={unBindCompany}
        />
      </Modal>
    </>
  );
}

export default CompaniesHolding;
