import Modal from 'components/ui/modal/Modal';
import { MODAL_BULK_IMAGES } from '../constants';
import { HowToUploadStepLCAMaterials } from './HowToUploadStepLCAMaterials';
import Button from 'components/ui/button/Button';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import { NuvoLCAMaterials } from './NuvoLCAMaterials';
import React from 'react';

type Props = {
  show: boolean;
  onClose: () => void;
};

type BodyProps = {
  onClose: () => void;
};

const Body: React.FC<BodyProps> = ({ onClose }) => {
  const { t } = useLCATranslation({ keyPrefix: 'materialManagement.modalBulkCreateMaterials' });

  return (
    <>
      <div className='flex-col gap-y-2'>
        <HowToUploadStepLCAMaterials.Layout>
          <HowToUploadStepLCAMaterials.Image Img={MODAL_BULK_IMAGES[1]} />
          <HowToUploadStepLCAMaterials.TextWrapper>
            <HowToUploadStepLCAMaterials.Title>
              {t('step1.title')}
            </HowToUploadStepLCAMaterials.Title>
            <HowToUploadStepLCAMaterials.Subtitle>
              {t('step1.preList')}
              <ul className='my-0'>
                <li>{t('step1.info1')}</li>
                <li>{t('step1.info2')}</li>
              </ul>
            </HowToUploadStepLCAMaterials.Subtitle>
          </HowToUploadStepLCAMaterials.TextWrapper>
        </HowToUploadStepLCAMaterials.Layout>
        <HowToUploadStepLCAMaterials.Layout>
          <HowToUploadStepLCAMaterials.Image Img={MODAL_BULK_IMAGES[2]} />
          <HowToUploadStepLCAMaterials.TextWrapper>
            <HowToUploadStepLCAMaterials.Title>
              {t('step2.title')}
            </HowToUploadStepLCAMaterials.Title>
            <HowToUploadStepLCAMaterials.Subtitle>
              {t('step2.preList')}
              <ul className='my-0'>
                <li>{t('step2.info1')}</li>
                <li>{t('step2.info2')}</li>
              </ul>
            </HowToUploadStepLCAMaterials.Subtitle>
          </HowToUploadStepLCAMaterials.TextWrapper>
        </HowToUploadStepLCAMaterials.Layout>
        <HowToUploadStepLCAMaterials.Layout>
          <HowToUploadStepLCAMaterials.Image Img={MODAL_BULK_IMAGES[3]} />
          <HowToUploadStepLCAMaterials.TextWrapper>
            <HowToUploadStepLCAMaterials.Title>
              {t('step3.title')}
            </HowToUploadStepLCAMaterials.Title>
            <HowToUploadStepLCAMaterials.Subtitle>
              {t('step3.subTitle')}
            </HowToUploadStepLCAMaterials.Subtitle>
          </HowToUploadStepLCAMaterials.TextWrapper>
        </HowToUploadStepLCAMaterials.Layout>
      </div>

      <div className='grid grid-cols-2 gap-x-4 mt-4'>
        <Button lookAndFeel='secondary' text={t('cancel')} size='medium' onClick={onClose} />
        <NuvoLCAMaterials onClose={onClose} />
      </div>
    </>
  );
};

const Header = () => {
  const { t } = useLCATranslation({ keyPrefix: 'materialManagement.modalBulkCreateMaterials' });

  return <Modal.Header title={t('title')} description={t('description')} />;
};

export const ModalBulkCreateMaterials = ({ show, onClose }: Props) => {
  return (
    <Modal.WithPortal show={show} onClose={onClose} closeOnOutsideClick={false}>
      <Header />
      <Body onClose={onClose} />
    </Modal.WithPortal>
  );
};

ModalBulkCreateMaterials.Body = Body;
ModalBulkCreateMaterials.Header = Header;
