import { FunctionComponent, SVGProps } from 'react';

type LayoutProps = {
  className?: string;
};

const Layout: React.FC<LayoutProps> = ({ children, className }) => {
  const classes = ['bg-tertiary w-full rounded-16 border-box flex items-center gap-x-4 h-35'];

  if (className) {
    classes.push(className);
  }
  return <div className={classes.join(' ')}>{children}</div>;
};

type ImageProps = {
  Img: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>;
};

const Image: React.FC<ImageProps> = ({ Img }) => {
  return <Img className='rounded-16' />;
};

const TextWrapper: React.FC = ({ children }) => {
  return <span className='flex-col border-box px-4'>{children}</span>;
};

const Title: React.FC = ({ children }) => {
  return <strong className='font-body-b1-b mb-2'>{children}</strong>;
};

const Subtitle: React.FC = ({ children }) => {
  return <span className='font-body-b2-r max-w-50ch'>{children}</span>;
};

export const HowToUploadStep = {
  Layout,
  Image,
  TextWrapper,
  Title,
  Subtitle
};
