import { connect, ConnectedProps } from 'react-redux';
import { removeNotification } from '../../../actions/notification';
import './styles.scss';

import { createPortal } from 'react-dom';

type TemplateState = {
  notification: NotificationType | null;
};

const mapStateToProps = (state: TemplateState) => {
  return {
    notification: state.notification
  };
};

const mapDispatchToProps = {
  removeNotification
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

function Notification({ notification, removeNotification }: Props) {
  if (!notification) {
    return null;
  }

  const handleClick = () => {
    if (notification.id) {
      removeNotification();
    }
  };

  return createPortal(
    <button
      onClick={handleClick}
      className={`notification notification-bg-color notification-text-color font-body-b1-r notification-border ${
        notification.id ? 'showAnimation' : 'hideAnimation'
      }`}>
      {notification.msg}
    </button>,
    document.body
  );
}

export default connector(Notification);
