import { LanguageType, SettingsAPI } from 'nuvo-react';
import NuvoImporter from 'components/ui/nuvoImporter/NuvoImporter';
import { useTranslation } from 'react-i18next';
import { MAX_NUVO_ENTRIES, TEMPLATE_FILE_NAME } from './constants';
import { basicNuvoStyle } from 'styles/nuvo';
import {
  createMaterialNodeFromExcel,
  getDefaultCoords,
  MaterialNodeFromExcel
} from 'services/api/lca/lcaScenarios';
import { useACVId } from '../../../hooks/useACVId';
import { useAllLCAMaterials } from '../../../hooks/useAllLCAMaterials';
import { useModalContext } from '../../ModalHandler';
import { useReactFlow } from '@xyflow/react';
import { useQueryClient } from '@tanstack/react-query';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { LCABlockBack } from '../../../../types/LCABlock';
import { LCAMetadata } from '../../../types/LCAScenarios';
import { useColumns } from './useColumns';

export const MaterialsMassiveUpload = () => {
  const { i18n } = useTranslation();
  const { acv_id } = useACVId();
  const { data } = useAllLCAMaterials();
  const { modalData } = useModalContext();
  const queryClient = useQueryClient();
  const org = useSelectedOrganization();
  const { fitView } = useReactFlow();

  const { columns } = useColumns(data);

  const target = modalData?.target;

  const settings: SettingsAPI = {
    multipleFileUpload: true,
    language: i18n.resolvedLanguage as LanguageType,
    style: basicNuvoStyle,
    automaticHeaderDetection: true,
    maxEntries: MAX_NUVO_ENTRIES,
    identifier: TEMPLATE_FILE_NAME, // Template file name
    disableSuccessModal: true,
    columns
  };

  return (
    <NuvoImporter
      lookAndFeel='secondary'
      size='medium'
      btnI18nKey='modules.uploadMaterials'
      namespace='lca'
      settings={settings}
      onResults={async (results, _errors, complete) => {
        const groupedResults = results.reduce((acc, curr) => {
          const {
            tag,
            supplier,
            country,
            quantity,
            material: material_name
          } = curr as {
            tag: string;
            supplier: string;
            country: string;
            quantity: number;
            material: string;
          };

          const unit_id = curr.unit as string;

          const material = data?.find(
            (item) =>
              item.name.toLowerCase() === material_name.toLowerCase() &&
              item.supplier.toLowerCase() === supplier.toLowerCase() &&
              item.country.toLowerCase() === country.toLowerCase()
          );

          if (!material || !unit_id) {
            return acc;
          }

          if (tag in acc) {
            acc[tag].push({
              acv_id,
              name: material.name,
              impact_source_id: material.impact_source_id,
              type: material.type,
              material_id: material.id,
              code: material.code,
              location: material.location,
              supplier,
              country,
              quantity,
              unit_id
            });
            return acc;
          }

          acc[tag] = [
            {
              acv_id,
              name: material.name,
              impact_source_id: material.impact_source_id,
              type: material.type,
              material_id: material.id,
              code: material.code,
              location: material.location,
              supplier,
              country,
              quantity,
              unit_id
            }
          ];

          return acc;
        }, {} as Record<string, MaterialNodeFromExcel[]>);

        const { coord_x, coord_y } = await getDefaultCoords(acv_id);

        let requestNumber = 0;
        const requests = [];

        for (const tag of Object.keys(groupedResults)) {
          requests.push(
            createMaterialNodeFromExcel({
              tag,
              materials: groupedResults[tag],
              coord_x,
              coord_y,
              request_number: requestNumber,
              acv_id,
              target
            })
          );

          requestNumber++;
        }

        const responses = await Promise.all(requests);

        queryClient.setQueryData(['lcaBlocks', org?.id, acv_id], (oldData: LCABlockBack[]) => {
          const target_block = oldData.find((block) => block.id === target);

          const newBlocks = responses.map((response) => {
            return {
              id: response.node_id,
              name: response.name,
              entity_id: response.items.map((material) => material.id),
              entity_type: 'material',
              coordinates_x: response.coord_x,
              coordinates_y: response.coord_y,
              created_at: response.created_at,
              updated_at: response.updated_at,
              inputs: [],
              outputs: target_block
                ? [
                    {
                      id: target_block.id,
                      name: target_block.name,
                      type: 'process'
                    }
                  ]
                : []
            };
          });

          return [...oldData, ...newBlocks];
        });

        queryClient.setQueryData(['lca-node-metadata', acv_id], (oldData: LCAMetadata) => {
          const newMetadata = responses.map((response) => {
            return {
              node_id: response.node_id,
              name: response.name,
              entity_id: response.items.map((material) => material.id)
            };
          });

          return {
            process: oldData.process,
            material: [...oldData.material, ...newMetadata]
          };
        });

        complete();

        setTimeout(() => {
          fitView({ duration: 1000 });
        }, 0.5 * 1000);
      }}
    />
  );
};
