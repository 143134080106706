type Props = {
  className?: string;
  disabled?: boolean;
};

export const GrayBox: React.FC<Props> = ({ children, className, disabled = false }) => {
  const classes = ['p-8 rounded-8 flex-col gap-y-6'];

  if (!disabled) {
    classes.push('bg-tertiary');
  } else {
    classes.push('bg-neutral-gray-80');
  }

  if (className) {
    classes.push(className);
  }

  return <div className={classes.join(' ')}>{children}</div>;
};
