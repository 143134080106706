import { Status } from 'types/utilsEnums/status';
import { ReactComponent as Step1 } from 'assets/img/step_1_purchases.svg';
import { ReactComponent as Step2 } from 'assets/img/step_2_purchases.svg';
import { ReactComponent as Step3 } from 'assets/img/step_3_purchases.svg';

export const PURCHASE_STATUS = {
  [Status.ACTIVE]: Status.ACTIVE,
  [Status.IN_REVIEW]: Status.IN_REVIEW,
  [Status.IN_PROGRESS]: Status.IN_PROGRESS
} as const;

export const AMZ_BUSINESS_ID = 'amazon_business';

export const FILTER_IN_REVIEW = {
  field: 'status',
  value: Status.IN_REVIEW,
  type: 'eq'
} as const;

export const OLD_PURCHASE_SUPPLIER_OFFICIAL_SUPPLIER_ID = 'a57e1ee0-4510-4169-86e4-5b320a6f91bb';

export const STEP_COMPONENTS = {
  1: Step1,
  2: Step2,
  3: Step3
} as const;
