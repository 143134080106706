export const generalDatapoints = [
  {
    id: 'adbf0b02-3c58-44aa-9bc1-18f7fbc56ca0',
    name_dcycle: 'basis_for_preparation_of_sustainability_statement',
    datapoint_id: 'BP-1_01',
    esg_category: 'general',
    name_en: 'Basis for preparation of sustainability statement',
    name_es: 'Bases para la preparaci\u00f3n de la declaraci\u00f3n de sostenibilidad.',
    name_pt: 'Base para elabora\u00e7\u00e3o da declara\u00e7\u00e3o de sustentabilidade'
  },
  {
    id: '9be3eef1-3b0b-433a-a803-4ba59317f7b5',
    name_dcycle:
      'scope_of_consolidation_of_consolidated_sustainability_statement_is_same_as_for_financial_statements',
    datapoint_id: 'BP-1_02',
    esg_category: 'general',
    name_en:
      'Scope of consolidation of consolidated sustainability statement is same as for financial statements',
    name_es:
      'El alcance de consolidaci\u00f3n de la declaraci\u00f3n de sostenibilidad consolidada es el mismo que el de los estados financieros.',
    name_pt:
      'O escopo de consolida\u00e7\u00e3o da demonstra\u00e7\u00e3o consolidada de sustentabilidade \u00e9 o mesmo das demonstra\u00e7\u00f5es financeiras'
  },
  {
    id: '9ec42775-ebef-47c4-87a9-79c18f84611b',
    name_dcycle:
      'indication_of_subsidiary_undertakings_included_in_consolidation_that_are_exempted_from_individual_or_consolidated_sustainability_reporting',
    datapoint_id: 'BP-1_03',
    esg_category: 'general',
    name_en:
      'Indication of subsidiary undertakings included in consolidation that are exempted from individual or consolidated sustainability reporting',
    name_es:
      'Indicaci\u00f3n de empresas subsidiarias incluidas en la consolidaci\u00f3n que est\u00e1n exentas de informes de sostenibilidad individuales o consolidados',
    name_pt:
      'Indica\u00e7\u00e3o das empresas subsidi\u00e1rias inclu\u00eddas na consolida\u00e7\u00e3o que est\u00e3o dispensadas de reporte de sustentabilidade individual ou consolidado'
  },
  {
    id: '9649e777-93a0-40fb-b8de-4a03e7ced5d1',
    name_dcycle:
      'disclosure_of_extent_to_which_sustainability_statement_covers_upstream_and_downstream_value_chain',
    datapoint_id: 'BP-1_04',
    esg_category: 'general',
    name_en:
      'Disclosure of extent to which sustainability statement covers upstream and downstream value chain',
    name_es:
      'Divulgaci\u00f3n del grado en que la declaraci\u00f3n de sostenibilidad cubre la cadena de valor ascendente y descendente',
    name_pt:
      'Divulga\u00e7\u00e3o da extens\u00e3o em que a declara\u00e7\u00e3o de sustentabilidade abrange a cadeia de valor a montante e a jusante'
  },
  {
    id: 'e97dc992-4310-40dd-8a55-023bf8dcf893',
    name_dcycle:
      'option_to_omit_specific_piece_of_information_corresponding_to_intellectual_property,_know-how_or_results_of_innovation_has_been_used',
    datapoint_id: 'BP-1_05',
    esg_category: 'general',
    name_en:
      'Option to omit specific piece of information corresponding to intellectual property, know-how or results of innovation has been used',
    name_es:
      'Se ha utilizado la opci\u00f3n de omitir informaci\u00f3n espec\u00edfica correspondiente a propiedad intelectual, know-how o resultados de innovaci\u00f3n.',
    name_pt:
      'Foi utilizada a op\u00e7\u00e3o de omitir informa\u00e7\u00f5es espec\u00edficas correspondentes \u00e0 propriedade intelectual, know-how ou resultados de inova\u00e7\u00e3o'
  },
  {
    id: '9c2c323e-4c83-4e6b-adf6-6edc4503cfce',
    name_dcycle:
      'option_allowed_by_member_state_to_omit_disclosure_of_impending_developments_or_matters_in_course_of_negotiation_has_been_used',
    datapoint_id: 'BP-1_06',
    esg_category: 'general',
    name_en:
      'Option allowed by Member State to omit disclosure of impending developments or matters in course of negotiation has been used',
    name_es:
      'Se ha utilizado la opci\u00f3n permitida por el Estado miembro de omitir la divulgaci\u00f3n de acontecimientos inminentes o asuntos en curso de negociaci\u00f3n.',
    name_pt:
      'Foi utilizada a op\u00e7\u00e3o permitida pelo Estado-Membro de omitir a divulga\u00e7\u00e3o de desenvolvimentos iminentes ou assuntos em curso de negocia\u00e7\u00e3o'
  },
  {
    id: '2408682b-ee60-4121-bd40-a05464b7fddb',
    name_dcycle: 'disclosure_of_definitions_of_medium-_or_long-term_time_horizons',
    datapoint_id: 'BP-2_01',
    esg_category: 'general',
    name_en: 'Disclosure of definitions of medium- or long-term time horizons',
    name_es: 'Divulgaci\u00f3n de definiciones de horizontes temporales de mediano o largo plazo.',
    name_pt:
      'Divulga\u00e7\u00e3o de defini\u00e7\u00f5es de horizontes temporais de m\u00e9dio ou longo prazo'
  },
  {
    id: '552757bd-35e1-4de0-91a8-f3b3932d9fcd',
    name_dcycle: 'disclosure_of_reasons_for_applying_different_definitions_of_time_horizons',
    datapoint_id: 'BP-2_02',
    esg_category: 'general',
    name_en: 'Disclosure of reasons for applying different definitions of time horizons',
    name_es:
      'Divulgaci\u00f3n de razones para aplicar diferentes definiciones de horizontes temporales',
    name_pt:
      'Divulga\u00e7\u00e3o das raz\u00f5es para a aplica\u00e7\u00e3o de diferentes defini\u00e7\u00f5es de horizontes temporais'
  },
  {
    id: 'c7b0f5cd-8e83-49ef-9f2b-760910061043',
    name_dcycle:
      'disclosure_of_metrics_that_include_value_chain_data_estimated_using_indirect_sources',
    datapoint_id: 'BP-2_03',
    esg_category: 'general',
    name_en: 'Disclosure of metrics that include value chain data estimated using indirect sources',
    name_es:
      'Divulgaci\u00f3n de m\u00e9tricas que incluyen datos de la cadena de valor estimados utilizando fuentes indirectas.',
    name_pt:
      'Divulga\u00e7\u00e3o de m\u00e9tricas que incluem dados da cadeia de valor estimados usando fontes indiretas'
  },
  {
    id: 'de093410-35d1-48c4-b7e6-96abed007ba1',
    name_dcycle:
      'description_of_basis_for_preparation_of_metrics_that_include_value_chain_data_estimated_using_indirect_sources',
    datapoint_id: 'BP-2_04',
    esg_category: 'general',
    name_en:
      'Description of basis for preparation of metrics that include value chain data estimated using indirect sources',
    name_es:
      'Descripci\u00f3n de la base para la preparaci\u00f3n de m\u00e9tricas que incluyen datos de la cadena de valor estimados utilizando fuentes indirectas',
    name_pt:
      'Descri\u00e7\u00e3o da base para prepara\u00e7\u00e3o de m\u00e9tricas que incluem dados da cadeia de valor estimados usando fontes indiretas'
  },
  {
    id: '82b21154-4fec-4d49-a02f-89569e0cba3e',
    name_dcycle:
      'description_of_resulting_level_of_accuracy_of_metrics_that_include_value_chain_data_estimated_using_indirect_sources',
    datapoint_id: 'BP-2_05',
    esg_category: 'general',
    name_en:
      'Description of resulting level of accuracy of metrics that include value chain data estimated using indirect sources',
    name_es:
      'Descripci\u00f3n del nivel resultante de precisi\u00f3n de las m\u00e9tricas que incluyen datos de la cadena de valor estimados utilizando fuentes indirectas.',
    name_pt:
      'Descri\u00e7\u00e3o do n\u00edvel resultante de precis\u00e3o das m\u00e9tricas que incluem dados da cadeia de valor estimados usando fontes indiretas'
  },
  {
    id: 'a212dc2a-516a-4742-9345-5991fbc7c2bf',
    name_dcycle:
      'description_of_planned_actions_to_improve_accuracy_in_future_of_metrics_that_include_value_chain_data_estimated_using_indirect_sources',
    datapoint_id: 'BP-2_06',
    esg_category: 'general',
    name_en:
      'Description of planned actions to improve accuracy in future of metrics that include value chain data estimated using indirect sources',
    name_es:
      'Descripci\u00f3n de las acciones planificadas para mejorar la precisi\u00f3n en el futuro de las m\u00e9tricas que incluyen datos de la cadena de valor estimados utilizando fuentes indirectas.',
    name_pt:
      'Descri\u00e7\u00e3o das a\u00e7\u00f5es planejadas para melhorar a precis\u00e3o futura das m\u00e9tricas que incluem dados da cadeia de valor estimados usando fontes indiretas'
  },
  {
    id: '060ac3f6-6dfb-4da1-8573-45518e81a3e0',
    name_dcycle:
      'disclosure_of_quantitative_metrics_and_monetary_amounts_disclosed_that_are_subject_to_high_level_of_measurement_uncertainty',
    datapoint_id: 'BP-2_07',
    esg_category: 'general',
    name_en:
      'Disclosure of quantitative metrics and monetary amounts disclosed that are subject to high level of measurement uncertainty',
    name_es:
      'Divulgaci\u00f3n de m\u00e9tricas cuantitativas y montos monetarios divulgados que est\u00e1n sujetos a un alto nivel de incertidumbre en la medici\u00f3n.',
    name_pt:
      'Divulga\u00e7\u00e3o de m\u00e9tricas quantitativas e valores monet\u00e1rios divulgados que est\u00e3o sujeitos a alto n\u00edvel de incerteza de mensura\u00e7\u00e3o'
  },
  {
    id: 'c188aabe-3b0e-4f52-b8fd-90575188be79',
    name_dcycle: 'disclosure_of_sources_of_measurement_uncertainty',
    datapoint_id: 'BP-2_08',
    esg_category: 'general',
    name_en: 'Disclosure of sources of measurement uncertainty',
    name_es: 'Divulgaci\u00f3n de fuentes de incertidumbre de medici\u00f3n.',
    name_pt: 'Divulga\u00e7\u00e3o de fontes de incerteza de medi\u00e7\u00e3o'
  },
  {
    id: 'f987a45f-b80c-43df-a681-11e201319faf',
    name_dcycle: 'disclosure_of_assumptions,_approximations_and_judgements_made_in_measurement',
    datapoint_id: 'BP-2_09',
    esg_category: 'general',
    name_en: 'Disclosure of assumptions, approximations and judgements made in measurement',
    name_es:
      'Revelaci\u00f3n de supuestos, aproximaciones y juicios realizados en la medici\u00f3n.',
    name_pt:
      'Divulga\u00e7\u00e3o de premissas, aproxima\u00e7\u00f5es e julgamentos feitos na mensura\u00e7\u00e3o'
  },
  {
    id: '608d8d60-fbc0-4989-85fb-59ad7cc72a6f',
    name_dcycle:
      'explanation_of_changes_in_preparation_and_presentation_of_sustainability_information_and_reasons_for_them',
    datapoint_id: 'BP-2_10',
    esg_category: 'general',
    name_en:
      'Explanation of changes in preparation and presentation of sustainability information and reasons for them',
    name_es:
      'Explicaci\u00f3n de los cambios en la preparaci\u00f3n y presentaci\u00f3n de la informaci\u00f3n de sostenibilidad y los motivos de los mismos.',
    name_pt:
      'Explica\u00e7\u00e3o das mudan\u00e7as na prepara\u00e7\u00e3o e apresenta\u00e7\u00e3o de informa\u00e7\u00f5es de sustentabilidade e as raz\u00f5es para elas'
  },
  {
    id: 'a238664f-601a-48ad-bd14-594a9ab6c16e',
    name_dcycle:
      'adjustment_of_comparative_information_for_one_or_more_prior_periods_is_impracticable',
    datapoint_id: 'BP-2_11',
    esg_category: 'general',
    name_en: 'Adjustment of comparative information for one or more prior periods is impracticable',
    name_es:
      'El ajuste de la informaci\u00f3n comparativa para uno o m\u00e1s per\u00edodos anteriores es impracticable.',
    name_pt:
      'O ajuste de informa\u00e7\u00f5es comparativas de um ou mais per\u00edodos anteriores \u00e9 impratic\u00e1vel'
  },
  {
    id: '4809c817-0e68-4937-9e33-0e08b71dccaf',
    name_dcycle:
      'disclosure_of_difference_between_figures_disclosed_in_preceding_period_and_revised_comparative_figures',
    datapoint_id: 'BP-2_12',
    esg_category: 'general',
    name_en:
      'Disclosure of difference between figures disclosed in preceding period and revised comparative figures',
    name_es:
      'Divulgaci\u00f3n de la diferencia entre las cifras reveladas en el per\u00edodo anterior y las cifras comparativas revisadas',
    name_pt:
      'Divulga\u00e7\u00e3o da diferen\u00e7a entre os valores divulgados no per\u00edodo anterior e os valores comparativos revistos'
  },
  {
    id: 'c5f6d40d-3896-4ef7-91b4-2af8a232fe15',
    name_dcycle: 'disclosure_of_nature_of_prior_period_material_errors',
    datapoint_id: 'BP-2_13',
    esg_category: 'general',
    name_en: 'Disclosure of nature of prior period material errors',
    name_es:
      'Divulgaci\u00f3n de la naturaleza de los errores materiales del per\u00edodo anterior',
    name_pt: 'Divulga\u00e7\u00e3o da natureza dos erros materiais do per\u00edodo anterior'
  },
  {
    id: '0367d170-7d51-4765-adba-82378f54a3c9',
    name_dcycle: 'disclosure_of_corrections_for_prior_periods_included_in_sustainability_statement',
    datapoint_id: 'BP-2_14',
    esg_category: 'general',
    name_en: 'Disclosure of corrections for prior periods included in sustainability statement',
    name_es:
      'Divulgaci\u00f3n de correcciones de per\u00edodos anteriores incluidas en la declaraci\u00f3n de sostenibilidad',
    name_pt:
      'Divulga\u00e7\u00e3o de corre\u00e7\u00f5es de per\u00edodos anteriores inclu\u00eddas na declara\u00e7\u00e3o de sustentabilidade'
  },
  {
    id: '9396580f-36c5-4baf-a9d3-d450dad41335',
    name_dcycle: 'disclosure_of_why_correction_of_prior_period_errors_is_not_practicable',
    datapoint_id: 'BP-2_15',
    esg_category: 'general',
    name_en: 'Disclosure of why correction of prior period errors is not practicable',
    name_es:
      'Divulgaci\u00f3n de por qu\u00e9 no es practicable la correcci\u00f3n de errores del per\u00edodo anterior',
    name_pt:
      'Divulga\u00e7\u00e3o do motivo pelo qual a corre\u00e7\u00e3o de erros de per\u00edodos anteriores n\u00e3o \u00e9 pratic\u00e1vel'
  },
  {
    id: 'e6f738eb-7b9c-43e7-abd2-4ec2ac651be3',
    name_dcycle:
      'disclosure_of_other_legislation_or_generally_accepted_sustainability_reporting_standards_and_frameworks_based_on_which_information_has_been_included_in_sustainability_statement',
    datapoint_id: 'BP-2_16',
    esg_category: 'general',
    name_en:
      'Disclosure of other legislation or generally accepted sustainability reporting standards and frameworks based on which information has been included in sustainability statement',
    name_es:
      'Divulgaci\u00f3n de otra legislaci\u00f3n o est\u00e1ndares y marcos de informes de sostenibilidad generalmente aceptados en base a los cuales se ha incluido informaci\u00f3n en la declaraci\u00f3n de sostenibilidad.',
    name_pt:
      'Divulga\u00e7\u00e3o de outra legisla\u00e7\u00e3o ou de padr\u00f5es e estruturas de relat\u00f3rios de sustentabilidade geralmente aceitos, com base nas quais as informa\u00e7\u00f5es foram inclu\u00eddas na declara\u00e7\u00e3o de sustentabilidade'
  },
  {
    id: '948090c1-09b4-464a-8db1-62a9b017be02',
    name_dcycle: 'disclosure_of_reference_to_paragraphs_of_standard_or_framework_applied',
    datapoint_id: 'BP-2_17',
    esg_category: 'general',
    name_en: 'Disclosure of reference to paragraphs of standard or framework applied',
    name_es: 'Divulgaci\u00f3n de referencia a p\u00e1rrafos de norma o marco aplicado',
    name_pt:
      'Divulga\u00e7\u00e3o de refer\u00eancia a par\u00e1grafos da norma ou estrutura aplicada'
  },
  {
    id: 'd4d53bff-4968-41a9-a193-b4de36c2152f',
    name_dcycle:
      'european_standards_approved_by_european_standardisation_system_(iso/iec_or_cen/cenelec_standards)_have_been_relied_on',
    datapoint_id: 'BP-2_18',
    esg_category: 'general',
    name_en:
      'European standards approved by European Standardisation System (ISO/IEC or CEN/CENELEC standards) have been relied on',
    name_es:
      'Se han confiado en las normas europeas aprobadas por el Sistema Europeo de Normalizaci\u00f3n (normas ISO/IEC o CEN/CENELEC).',
    name_pt:
      'As normas europeias aprovadas pelo Sistema Europeu de Normaliza\u00e7\u00e3o (normas ISO/IEC ou CEN/CENELEC) foram utilizadas'
  },
  {
    id: '69323a6d-3e63-4dbb-89ae-779b298dd46e',
    name_dcycle:
      'disclosure_of_extent_to_which_data_and_processes_that_are_used_for_sustainability_reporting_purposes_have_been_verified_by_external_assurance_provider_and_found_to_conform_to_corresponding_iso/iec_or_cen/cenelec_standard',
    datapoint_id: 'BP-2_19',
    esg_category: 'general',
    name_en:
      'Disclosure of extent to which data and processes that are used for sustainability reporting purposes have been verified by external assurance provider and found to conform to corresponding ISO/IEC or CEN/CENELEC standard',
    name_es:
      'Divulgaci\u00f3n del grado en que los datos y procesos que se utilizan para fines de informes de sostenibilidad han sido verificados por un proveedor de verificaci\u00f3n externo y se ha determinado que cumplen con el est\u00e1ndar ISO/IEC o CEN/CENELEC correspondiente.',
    name_pt:
      'Divulga\u00e7\u00e3o da extens\u00e3o em que os dados e processos usados \u200b\u200bpara fins de relat\u00f3rios de sustentabilidade foram verificados por um provedor de garantia externo e considerados em conformidade com o padr\u00e3o ISO/IEC ou CEN/CENELEC correspondente'
  },
  {
    id: 'f7a90836-1d9f-4d8b-87ef-0c999c346896',
    name_dcycle: 'list_of_drs_or_dps_incorporated_by_reference',
    datapoint_id: 'BP-2_20',
    esg_category: 'general',
    name_en: 'List of DRs or DPs incorporated by reference',
    name_es: 'Lista de DR o DP incorporados por referencia',
    name_pt: 'Lista de DRs ou DPs incorporada por refer\u00eancia'
  },
  {
    id: 'd7e6fbc9-c116-492d-9799-f24e02a14f86',
    name_dcycle: 'topics_(e4,_s1,_s2,_s3,_s4)_have_been_assessed_to_be_material',
    datapoint_id: 'BP-2_21',
    esg_category: 'general',
    name_en: 'Topics (E4, S1, S2, S3, S4) have been assessed to be material',
    name_es: 'Los temas (E4, S1, S2, S3, S4) se han evaluado como materiales.',
    name_pt: 'Os t\u00f3picos (E4, S1, S2, S3, S4) foram avaliados como materiais'
  },
  {
    id: '77c0018f-9da3-4c42-8aff-d37d5ba709ab',
    name_dcycle: 'list_of_sustainability_matters_assessed_to_be_material_(phase-in)',
    datapoint_id: 'BP-2_22',
    esg_category: 'general',
    name_en: 'List of sustainability matters assessed to be material (phase-in)',
    name_es:
      'Lista de cuestiones de sostenibilidad consideradas materiales (incorporaci\u00f3n progresiva)',
    name_pt:
      'Lista de quest\u00f5es de sustentabilidade avaliadas como materiais (introdu\u00e7\u00e3o progressiva)'
  },
  {
    id: '4aeaace5-f5ef-4bd5-b65c-5381a09dd3b8',
    name_dcycle:
      'disclosure_of_how_business_model_and_strategy_take_account_of_impacts_related_to_sustainability_matters_assessed_to_be_material_(phase-in)',
    datapoint_id: 'BP-2_23',
    esg_category: 'general',
    name_en:
      'Disclosure of how business model and strategy take account of impacts related to sustainability matters assessed to be material (phase-in)',
    name_es:
      'Divulgaci\u00f3n de c\u00f3mo el modelo de negocio y la estrategia tienen en cuenta los impactos relacionados con cuestiones de sostenibilidad consideradas materiales (incorporaci\u00f3n gradual)',
    name_pt:
      'Divulga\u00e7\u00e3o de como o modelo e a estrat\u00e9gia de neg\u00f3cios levam em conta os impactos relacionados \u00e0s quest\u00f5es de sustentabilidade avaliados como materiais (phase-in)'
  },
  {
    id: '841198c3-da4a-4eb0-83d9-185043144459',
    name_dcycle:
      'description_of_any_time-bound_targets_set_related_to_sustainability_matters_assessed_to_be_material_(phase-in)_and_progress_made_towards_achieving_those_targets',
    datapoint_id: 'BP-2_24',
    esg_category: 'general',
    name_en:
      'Description of any time-bound targets set related to sustainability matters assessed to be material (phase-in) and progress made towards achieving those targets',
    name_es:
      'Descripci\u00f3n de cualquier objetivo con plazos determinados establecido en relaci\u00f3n con cuestiones de sostenibilidad que se considere material (introducci\u00f3n gradual) y el progreso realizado hacia el logro de esos objetivos.',
    name_pt:
      'Descri\u00e7\u00e3o de quaisquer metas com prazo definido relacionadas a quest\u00f5es de sustentabilidade avaliadas como materiais (implementa\u00e7\u00e3o progressiva) e progresso alcan\u00e7ado no sentido de atingir essas metas'
  },
  {
    id: '833a6131-3b83-491c-bb39-9fd84c235ece',
    name_dcycle:
      'description_of_policies_related_to_sustainability_matters_assessed_to_be_material_(phase-in)',
    datapoint_id: 'BP-2_25',
    esg_category: 'general',
    name_en:
      'Description of policies related to sustainability matters assessed to be material (phase-in)',
    name_es:
      'Descripci\u00f3n de las pol\u00edticas relacionadas con cuestiones de sostenibilidad consideradas materiales (incorporaci\u00f3n gradual)',
    name_pt:
      'Descri\u00e7\u00e3o das pol\u00edticas relacionadas com quest\u00f5es de sustentabilidade avaliadas como materiais (phase-in)'
  },
  {
    id: '4a365b4b-aa1c-4ba2-bf74-88c9a452b769',
    name_dcycle:
      'description_of_actions_taken_to_identify,_monitor,_prevent,_mitigate,_remediate_or_bring_end_to_actual_or_potential_adverse_impacts_related_to_sustainability_matters_assessed_to_be_material_(phase-in)_and_result_of_such_actions',
    datapoint_id: 'BP-2_26',
    esg_category: 'general',
    name_en:
      'Description of actions taken to identify, monitor, prevent, mitigate, remediate or bring end to actual or potential adverse impacts related to sustainability matters assessed to be material (phase-in) and result of such actions',
    name_es:
      'Descripci\u00f3n de las acciones tomadas para identificar, monitorear, prevenir, mitigar, remediar o poner fin a los impactos adversos reales o potenciales relacionados con asuntos de sostenibilidad evaluados como materiales (incorporaci\u00f3n gradual) y resultado de dichas acciones',
    name_pt:
      'Descri\u00e7\u00e3o das a\u00e7\u00f5es tomadas para identificar, monitorar, prevenir, mitigar, remediar ou p\u00f4r fim a impactos adversos reais ou potenciais relacionados a quest\u00f5es de sustentabilidade avaliados como materiais (introdu\u00e7\u00e3o progressiva) e resultado de tais a\u00e7\u00f5es'
  },
  {
    id: 'ea230a57-6c87-4aac-b6ed-aa1bc2d05207',
    name_dcycle:
      'disclosure_of_metrics_related_to_sustainability_matters_assessed_to_be_material_(phase-in)',
    datapoint_id: 'BP-2_27',
    esg_category: 'general',
    name_en:
      'Disclosure of metrics related to sustainability matters assessed to be material (phase-in)',
    name_es:
      'Divulgaci\u00f3n de m\u00e9tricas relacionadas con cuestiones de sostenibilidad consideradas materiales (introducci\u00f3n progresiva)',
    name_pt:
      'Divulga\u00e7\u00e3o de m\u00e9tricas relacionadas a quest\u00f5es de sustentabilidade avaliadas como materiais (phase-in)'
  },
  {
    id: 'f571f7e8-9b09-4c55-9a4b-71e0015d1e83',
    name_dcycle: 'number_of_executive_members',
    datapoint_id: 'GOV-1_01',
    esg_category: 'general',
    name_en: 'Number of executive members',
    name_es: 'N\u00famero de miembros ejecutivos',
    name_pt: 'N\u00famero de membros executivos'
  },
  {
    id: '390051a1-8bbe-47c4-8ba3-5c84cf53bdd1',
    name_dcycle: 'number_of_non-executive_members',
    datapoint_id: 'GOV-1_02',
    esg_category: 'general',
    name_en: 'Number of non-executive members',
    name_es: 'N\u00famero de miembros no ejecutivos',
    name_pt: 'N\u00famero de membros n\u00e3o executivos'
  },
  {
    id: 'f46911ba-6625-4efb-8a87-17284f6cbd10',
    name_dcycle: 'information_about_representation_of_employees_and_other_workers',
    datapoint_id: 'GOV-1_03',
    esg_category: 'general',
    name_en: 'Information about representation of employees and other workers',
    name_es: 'Informaci\u00f3n sobre la representaci\u00f3n de empleados y otros trabajadores.',
    name_pt:
      'Informa\u00e7\u00f5es sobre representa\u00e7\u00e3o de empregados e outros trabalhadores'
  },
  {
    id: '7b893eba-2bbc-4bba-8eb7-0bbeb3923f77',
    name_dcycle:
      "information_about_member's_experience_relevant_to_sectors,_products_and_geographic_locations_of_undertaking",
    datapoint_id: 'GOV-1_04',
    esg_category: 'general',
    name_en:
      "Information about member's experience relevant to sectors, products and geographic locations of undertaking",
    name_es:
      'Informaci\u00f3n sobre la experiencia del miembro relevante para sectores, productos y ubicaciones geogr\u00e1ficas de la empresa.',
    name_pt:
      'Informa\u00e7\u00f5es sobre a experi\u00eancia dos membros relevantes para setores, produtos e localiza\u00e7\u00f5es geogr\u00e1ficas da empresa'
  },
  {
    id: 'c15d4e05-c801-496c-b2c0-30e6a79452d1',
    name_dcycle:
      'percentage_of_members_of_administrative,_management_and_supervisory_bodies_by_gender_and_other_aspects_of_diversity',
    datapoint_id: 'GOV-1_05',
    esg_category: 'general',
    name_en:
      'Percentage of members of administrative, management and supervisory bodies by gender and other aspects of diversity',
    name_es:
      'Porcentaje de miembros de \u00f3rganos de administraci\u00f3n, direcci\u00f3n y supervisi\u00f3n por g\u00e9nero y otros aspectos de la diversidad',
    name_pt:
      'Percentagem de membros de \u00f3rg\u00e3os de administra\u00e7\u00e3o, de gest\u00e3o e de fiscaliza\u00e7\u00e3o por g\u00e9nero e outros aspetos da diversidade'
  },
  {
    id: 'c4bfa6ab-f311-4ccd-80a6-6d7221a4dfca',
    name_dcycle: "board's_gender_diversity_ratio",
    datapoint_id: 'GOV-1_06',
    esg_category: 'general',
    name_en: "Board's gender diversity ratio",
    name_es: 'Ratio de diversidad de g\u00e9nero del consejo',
    name_pt: 'R\u00e1cio de diversidade de g\u00e9nero do Conselho'
  },
  {
    id: '8632f822-9384-4c51-afd6-a1418388b27d',
    name_dcycle: 'percentage_of_independent_board_members',
    datapoint_id: 'GOV-1_07',
    esg_category: 'general',
    name_en: 'Percentage of independent board members',
    name_es: 'Porcentaje de consejeros independientes',
    name_pt: 'Percentual de conselheiros independentes'
  },
  {
    id: '61eda212-69a0-459a-9f8a-0b534b5a3603',
    name_dcycle:
      'information_about_identity_of_administrative,_management_and_supervisory_bodies_or_individual(s)_within_body_responsible_for_oversight_of_impacts,_risks_and_opportunities',
    datapoint_id: 'GOV-1_08',
    esg_category: 'general',
    name_en:
      'Information about identity of administrative, management and supervisory bodies or individual(s) within body responsible for oversight of impacts, risks and opportunities',
    name_es:
      'Informaci\u00f3n sobre la identidad de los \u00f3rganos administrativos, de gesti\u00f3n y de supervisi\u00f3n o de las personas dentro del organismo responsable de la supervisi\u00f3n de los impactos, riesgos y oportunidades.',
    name_pt:
      'Informa\u00e7\u00e3o sobre a identidade dos \u00f3rg\u00e3os de administra\u00e7\u00e3o, de gest\u00e3o e de fiscaliza\u00e7\u00e3o ou da(s) pessoa(s) do \u00f3rg\u00e3o respons\u00e1vel pela fiscaliza\u00e7\u00e3o dos impactos, riscos e oportunidades'
  },
  {
    id: 'b2fa1bdf-7488-4d60-8ecb-d9e081b2dec7',
    name_dcycle:
      "disclosure_of_how_body's_or_individuals_within_body_responsibilities_for_impacts,_risks_and_opportunities_are_reflected_in_undertaking's_terms_of_reference,_board_mandates_and_other_related_policies",
    datapoint_id: 'GOV-1_09',
    esg_category: 'general',
    name_en:
      "Disclosure of how body's or individuals within body responsibilities for impacts, risks and opportunities are reflected in undertaking's terms of reference, board mandates and other related policies",
    name_es:
      'Divulgaci\u00f3n de c\u00f3mo las responsabilidades del organismo o de los individuos dentro del organismo por los impactos, riesgos y oportunidades se reflejan en los t\u00e9rminos de referencia de la empresa, los mandatos de la junta directiva y otras pol\u00edticas relacionadas.',
    name_pt:
      'Divulga\u00e7\u00e3o de como as responsabilidades do \u00f3rg\u00e3o ou dos indiv\u00edduos dentro do \u00f3rg\u00e3o por impactos, riscos e oportunidades s\u00e3o refletidas nos termos de refer\u00eancia da empresa, mandatos do conselho e outras pol\u00edticas relacionadas'
  },
  {
    id: 'bd268f0f-969f-4794-9df0-e5d2a674e65d',
    name_dcycle:
      "description_of_management's_role_in_governance_processes,_controls_and_procedures_used_to_monitor,_manage_and_oversee_impacts,_risks_and_opportunities",
    datapoint_id: 'GOV-1_10',
    esg_category: 'general',
    name_en:
      "Description of management's role in governance processes, controls and procedures used to monitor, manage and oversee impacts, risks and opportunities",
    name_es:
      'Descripci\u00f3n del papel de la administraci\u00f3n en los procesos, controles y procedimientos de gobierno utilizados para monitorear, gestionar y supervisar los impactos, riesgos y oportunidades.',
    name_pt:
      'Descri\u00e7\u00e3o do papel da administra\u00e7\u00e3o nos processos, controles e procedimentos de governan\u00e7a utilizados para monitorar, gerenciar e supervisionar impactos, riscos e oportunidades'
  },
  {
    id: 'd7f6c8c8-8f4e-463a-b9bd-cc339618387c',
    name_dcycle:
      "description_of_how_oversight_is_exercised_over_management-level_position_or_committee_to_which_management's_role_is_delegated_to",
    datapoint_id: 'GOV-1_11',
    esg_category: 'general',
    name_en:
      "Description of how oversight is exercised over management-level position or committee to which management's role is delegated to",
    name_es:
      'Descripci\u00f3n de c\u00f3mo se ejerce la supervisi\u00f3n sobre el puesto de nivel gerencial o el comit\u00e9 al que se delega el rol de la gerencia',
    name_pt:
      'Descri\u00e7\u00e3o de como a supervis\u00e3o \u00e9 exercida sobre o cargo ou comit\u00ea de n\u00edvel gerencial ao qual a fun\u00e7\u00e3o da administra\u00e7\u00e3o \u00e9 delegada'
  },
  {
    id: 'd9c1a623-d150-4927-8017-52b18d35dbcb',
    name_dcycle:
      'information_about_reporting_lines_to_administrative,_management_and_supervisory_bodies',
    datapoint_id: 'GOV-1_12',
    esg_category: 'general',
    name_en:
      'Information about reporting lines to administrative, management and supervisory bodies',
    name_es:
      'Informaci\u00f3n sobre las l\u00edneas jer\u00e1rquicas ante los \u00f3rganos de administraci\u00f3n, direcci\u00f3n y supervisi\u00f3n',
    name_pt:
      'Informa\u00e7\u00e3o sobre linhas de reporte aos \u00f3rg\u00e3os de administra\u00e7\u00e3o, gest\u00e3o e fiscaliza\u00e7\u00e3o'
  },
  {
    id: '006ac5cb-5138-4db4-afc7-27f3b17e663d',
    name_dcycle:
      'disclosure_of_how_dedicated_controls_and_procedures_are_integrated_with_other_internal_functions',
    datapoint_id: 'GOV-1_13',
    esg_category: 'general',
    name_en:
      'Disclosure of how dedicated controls and procedures are integrated with other internal functions',
    name_es:
      'Divulgaci\u00f3n de c\u00f3mo se integran los controles y procedimientos dedicados con otras funciones internas.',
    name_pt:
      'Divulga\u00e7\u00e3o de como controles e procedimentos dedicados s\u00e3o integrados a outras fun\u00e7\u00f5es internas'
  },
  {
    id: '3e01aee1-8502-4573-8644-91de7cfaf831',
    name_dcycle:
      'disclosure_of_how_administrative,_management_and_supervisory_bodies_and_senior_executive_management_oversee_setting_of_targets_related_to_material_impacts,_risks_and_opportunities_and_how_progress_towards_them_is_monitored',
    datapoint_id: 'GOV-1_14',
    esg_category: 'general',
    name_en:
      'Disclosure of how administrative, management and supervisory bodies and senior executive management oversee setting of targets related to material impacts, risks and opportunities and how progress towards them is monitored',
    name_es:
      'Divulgaci\u00f3n de c\u00f3mo los \u00f3rganos administrativos, de gesti\u00f3n y de supervisi\u00f3n y la alta direcci\u00f3n ejecutiva supervisan el establecimiento de objetivos relacionados con impactos, riesgos y oportunidades materiales y c\u00f3mo se monitorea el progreso hacia ellos.',
    name_pt:
      'Divulga\u00e7\u00e3o de como os \u00f3rg\u00e3os de administra\u00e7\u00e3o, de gest\u00e3o e de supervis\u00e3o e a dire\u00e7\u00e3o executiva supervisionam a defini\u00e7\u00e3o de metas relacionadas com impactos, riscos e oportunidades materiais e como o progresso na sua consecu\u00e7\u00e3o \u00e9 monitorizado'
  },
  {
    id: '0476a910-4676-4ec6-8b03-bb04ccd7a600',
    name_dcycle:
      'disclosure_of_how_administrative,_management_and_supervisory_bodies_determine_whether_appropriate_skills_and_expertise_are_available_or_will_be_developed_to_oversee_sustainability_matters',
    datapoint_id: 'GOV-1_15',
    esg_category: 'general',
    name_en:
      'Disclosure of how administrative, management and supervisory bodies determine whether appropriate skills and expertise are available or will be developed to oversee sustainability matters',
    name_es:
      'Divulgaci\u00f3n de c\u00f3mo los \u00f3rganos administrativos, de gesti\u00f3n y de supervisi\u00f3n determinan si las habilidades y conocimientos adecuados est\u00e1n disponibles o se desarrollar\u00e1n para supervisar cuestiones de sostenibilidad.',
    name_pt:
      'Divulga\u00e7\u00e3o de como os \u00f3rg\u00e3os administrativos, de gest\u00e3o e de supervis\u00e3o determinam se as compet\u00eancias e conhecimentos adequados est\u00e3o dispon\u00edveis ou ser\u00e3o desenvolvidos para supervisionar quest\u00f5es de sustentabilidade'
  },
  {
    id: '0f352896-02f2-4c67-9456-5e703f9ee824',
    name_dcycle:
      'information_about_sustainability-related_expertise_that_bodies_either_directly_possess_or_can_leverage',
    datapoint_id: 'GOV-1_16',
    esg_category: 'general',
    name_en:
      'Information about sustainability-related expertise that bodies either directly possess or can leverage',
    name_es:
      'Informaci\u00f3n sobre la experiencia relacionada con la sostenibilidad que los organismos poseen directamente o pueden aprovechar',
    name_pt:
      'Informa\u00e7\u00f5es sobre conhecimentos especializados relacionados \u00e0 sustentabilidade que os \u00f3rg\u00e3os possuem diretamente ou podem aproveitar'
  },
  {
    id: 'd24011e6-b9c5-45ba-957e-349e31bae3f6',
    name_dcycle:
      'disclosure_of_how_sustainability-related_skills_and_expertise_relate_to_material_impacts,_risks_and_opportunities',
    datapoint_id: 'GOV-1_17',
    esg_category: 'general',
    name_en:
      'Disclosure of how sustainability-related skills and expertise relate to material impacts, risks and opportunities',
    name_es:
      'Divulgaci\u00f3n de c\u00f3mo las habilidades y conocimientos relacionados con la sostenibilidad se relacionan con los impactos, riesgos y oportunidades materiales.',
    name_pt:
      'Divulga\u00e7\u00e3o de como as compet\u00eancias e conhecimentos relacionados com a sustentabilidade se relacionam com impactos, riscos e oportunidades materiais'
  },
  {
    id: '89e57f49-d261-4ac0-a69f-5d0acd190a86',
    name_dcycle:
      'disclosure_of_whether,_by_whom_and_how_frequently_administrative,_management_and_supervisory_bodies_are_informed_about_material_impacts,_risks_and_opportunities,_implementation_of_due_diligence,_and_results_and_effectiveness_of_policies,_actions,_metrics_and_targets_adopted_to_address_them',
    datapoint_id: 'GOV-2_01',
    esg_category: 'general',
    name_en:
      'Disclosure of whether, by whom and how frequently administrative, management and supervisory bodies are informed about material impacts, risks and opportunities, implementation of due diligence, and results and effectiveness of policies, actions, metrics and targets adopted to address them',
    name_es:
      'Divulgaci\u00f3n de si, por qui\u00e9n y con qu\u00e9 frecuencia los \u00f3rganos administrativos, de gesti\u00f3n y de supervisi\u00f3n son informados sobre los impactos, riesgos y oportunidades materiales, la implementaci\u00f3n de la diligencia debida y los resultados y eficacia de las pol\u00edticas, acciones, m\u00e9tricas y objetivos adoptados para abordarlos.',
    name_pt:
      'Divulga\u00e7\u00e3o sobre se, por quem e com que frequ\u00eancia os \u00f3rg\u00e3os de administra\u00e7\u00e3o, gest\u00e3o e fiscaliza\u00e7\u00e3o s\u00e3o informados sobre impactos, riscos e oportunidades materiais, implementa\u00e7\u00e3o de due diligence, e resultados e efic\u00e1cia de pol\u00edticas, a\u00e7\u00f5es, m\u00e9tricas e metas adotadas para enfrent\u00e1-los'
  },
  {
    id: '82476bf0-1e42-4288-8d57-350d9da886c1',
    name_dcycle:
      'disclosure_of_how_administrative,_management_and_supervisory_bodies_consider_impacts,_risks_and_opportunities_when_overseeing_strategy,_decisions_on_major_transactions_and_risk_management_process',
    datapoint_id: 'GOV-2_02',
    esg_category: 'general',
    name_en:
      'Disclosure of how administrative, management and supervisory bodies consider impacts, risks and opportunities when overseeing strategy, decisions on major transactions and risk management process',
    name_es:
      'Divulgaci\u00f3n de c\u00f3mo los \u00f3rganos administrativos, de gesti\u00f3n y de supervisi\u00f3n consideran los impactos, riesgos y oportunidades al supervisar la estrategia, las decisiones sobre transacciones importantes y el proceso de gesti\u00f3n de riesgos.',
    name_pt:
      'Divulga\u00e7\u00e3o de como os \u00f3rg\u00e3os de administra\u00e7\u00e3o, gest\u00e3o e fiscaliza\u00e7\u00e3o consideram impactos, riscos e oportunidades na supervis\u00e3o da estrat\u00e9gia, decis\u00f5es sobre grandes transa\u00e7\u00f5es e processo de gest\u00e3o de riscos'
  },
  {
    id: 'e1d52317-b170-4533-bc30-066c0384fea3',
    name_dcycle:
      'disclosure_of_list_of_material_impacts,_risks_and_opportunities_addressed_by_administrative,_management_and_supervisory_bodies_or_their_relevant_committees',
    datapoint_id: 'GOV-2_03',
    esg_category: 'general',
    name_en:
      'Disclosure of list of material impacts, risks and opportunities addressed by administrative, management and supervisory bodies or their relevant committees',
    name_es:
      'Divulgaci\u00f3n de la lista de impactos, riesgos y oportunidades materiales abordados por los \u00f3rganos de administraci\u00f3n, gesti\u00f3n y supervisi\u00f3n o sus comit\u00e9s pertinentes.',
    name_pt:
      'Divulga\u00e7\u00e3o da lista de impactos, riscos e oportunidades materiais abordados pelos \u00f3rg\u00e3os de administra\u00e7\u00e3o, gest\u00e3o e fiscaliza\u00e7\u00e3o ou pelas suas comiss\u00f5es competentes'
  },
  {
    id: '085f60a2-2771-46ba-9500-09be7bf64e96',
    name_dcycle:
      'disclosure_of_how_governance_bodies_ensure_that_appropriate_mechanism_for_performance_monitoring_is_in_place',
    datapoint_id: 'GOV-2_04',
    esg_category: 'general',
    name_en:
      'Disclosure of how governance bodies ensure that appropriate mechanism for performance monitoring is in place',
    name_es:
      'Divulgaci\u00f3n de c\u00f3mo los \u00f3rganos de gobierno garantizan que exista un mecanismo adecuado para el seguimiento del desempe\u00f1o.',
    name_pt:
      'Divulga\u00e7\u00e3o de como os \u00f3rg\u00e3os de governan\u00e7a garantem a exist\u00eancia de mecanismos apropriados para monitoramento do desempenho'
  },
  {
    id: '2481755b-7e1a-47cb-a696-46e5afabd540',
    name_dcycle:
      'incentive_schemes_and_remuneration_policies_linked_to_sustainability_matters_for_members_of_administrative,_management_and_supervisory_bodies_exist',
    datapoint_id: 'GOV-3_01',
    esg_category: 'general',
    name_en:
      'Incentive schemes and remuneration policies linked to sustainability matters for members of administrative, management and supervisory bodies exist',
    name_es:
      'Existen planes de incentivos y pol\u00edticas retributivas ligadas a cuestiones de sostenibilidad para los miembros de los \u00f3rganos de administraci\u00f3n, direcci\u00f3n y supervisi\u00f3n.',
    name_pt:
      'Existem regimes de incentivos e pol\u00edticas de remunera\u00e7\u00e3o ligadas a quest\u00f5es de sustentabilidade para membros de \u00f3rg\u00e3os de administra\u00e7\u00e3o, gest\u00e3o e fiscaliza\u00e7\u00e3o'
  },
  {
    id: 'a4518871-41cd-4093-bbec-a0c4aecba448',
    name_dcycle: 'description_of_key_characteristics_of_incentive_schemes',
    datapoint_id: 'GOV-3_02',
    esg_category: 'general',
    name_en: 'Description of key characteristics of incentive schemes',
    name_es: 'Descripci\u00f3n de las caracter\u00edsticas clave de los planes de incentivos.',
    name_pt: 'Descri\u00e7\u00e3o das principais caracter\u00edsticas dos esquemas de incentivos'
  },
  {
    id: '30004426-cc9b-4a0a-a664-dc3717fe65ce',
    name_dcycle:
      'description_of_specific_sustainability-related_targets_and_(or)_impacts_used_to_assess_performance_of_members_of_administrative,_management_and_supervisory_bodies',
    datapoint_id: 'GOV-3_03',
    esg_category: 'general',
    name_en:
      'Description of specific sustainability-related targets and (or) impacts used to assess performance of members of administrative, management and supervisory bodies',
    name_es:
      'Descripci\u00f3n de objetivos espec\u00edficos relacionados con la sostenibilidad y (o) impactos utilizados para evaluar el desempe\u00f1o de los miembros de los \u00f3rganos de administraci\u00f3n, gesti\u00f3n y supervisi\u00f3n.',
    name_pt:
      'Descri\u00e7\u00e3o das metas e (ou) impactos espec\u00edficos relacionados com a sustentabilidade utilizados para avaliar o desempenho dos membros dos \u00f3rg\u00e3os de administra\u00e7\u00e3o, gest\u00e3o e fiscaliza\u00e7\u00e3o'
  },
  {
    id: '76052ac8-86f8-4c4a-b001-2851697855b3',
    name_dcycle:
      'disclosure_of_how_sustainability-related_performance_metrics_are_considered_as_performance_benchmarks_or_included_in_remuneration_policies',
    datapoint_id: 'GOV-3_04',
    esg_category: 'general',
    name_en:
      'Disclosure of how sustainability-related performance metrics are considered as performance benchmarks or included in remuneration policies',
    name_es:
      'Divulgaci\u00f3n de c\u00f3mo las m\u00e9tricas de desempe\u00f1o relacionadas con la sostenibilidad se consideran puntos de referencia de desempe\u00f1o o se incluyen en las pol\u00edticas de remuneraci\u00f3n.',
    name_pt:
      'Divulga\u00e7\u00e3o de como as m\u00e9tricas de desempenho relacionadas \u00e0 sustentabilidade s\u00e3o consideradas como benchmarks de desempenho ou inclu\u00eddas nas pol\u00edticas de remunera\u00e7\u00e3o'
  },
  {
    id: '081dfe1c-a9a6-4e5a-8a1f-82e7f749cab0',
    name_dcycle:
      'percentage_of_variable_remuneration_dependent_on_sustainability-related_targets_and_(or)_impacts',
    datapoint_id: 'GOV-3_05',
    esg_category: 'general',
    name_en:
      'Percentage of variable remuneration dependent on sustainability-related targets and (or) impacts',
    name_es:
      'Porcentaje de remuneraci\u00f3n variable dependiente de objetivos y (o) impactos relacionados con la sostenibilidad',
    name_pt:
      'Percentual da remunera\u00e7\u00e3o vari\u00e1vel dependente de metas e (ou) impactos relacionados \u00e0 sustentabilidade'
  },
  {
    id: 'eeadaba6-852d-465b-a9aa-77a4efaba18b',
    name_dcycle:
      'description_of_level_in_undertaking_at_which_terms_of_incentive_schemes_are_approved_and_updated',
    datapoint_id: 'GOV-3_06',
    esg_category: 'general',
    name_en:
      'Description of level in undertaking at which terms of incentive schemes are approved and updated',
    name_es:
      'Descripci\u00f3n del nivel de la empresa en el que se aprueban y actualizan las condiciones de los planes de incentivos',
    name_pt:
      'Descri\u00e7\u00e3o do n\u00edvel na empresa em que os termos dos esquemas de incentivos s\u00e3o aprovados e atualizados'
  },
  {
    id: '9072f30a-bdd0-4d02-967b-38f7f798431a',
    name_dcycle:
      'disclosure_of_mapping_of_information_provided_in_sustainability_statement_about_due_diligence_process',
    datapoint_id: 'GOV-4_01',
    esg_category: 'general',
    name_en:
      'Disclosure of mapping of information provided in sustainability statement about due diligence process',
    name_es:
      'Divulgaci\u00f3n del mapeo de la informaci\u00f3n proporcionada en la declaraci\u00f3n de sostenibilidad sobre el proceso de diligencia debida',
    name_pt:
      'Divulga\u00e7\u00e3o do mapeamento das informa\u00e7\u00f5es constantes da declara\u00e7\u00e3o de sustentabilidade sobre o processo de due diligence'
  },
  {
    id: '363901ea-cf80-45d1-a55e-5aeb71959d14',
    name_dcycle:
      'description_of_scope,_main_features_and_components_of_risk_management_and_internal_control_processes_and_systems_in_relation_to_sustainability_reporting',
    datapoint_id: 'GOV-5_01',
    esg_category: 'general',
    name_en:
      'Description of scope, main features and components of risk management and internal control processes and systems in relation to sustainability reporting',
    name_es:
      'Descripci\u00f3n del alcance, principales caracter\u00edsticas y componentes de los procesos y sistemas de gesti\u00f3n de riesgos y control interno en relaci\u00f3n con la presentaci\u00f3n de informes de sostenibilidad',
    name_pt:
      'Descri\u00e7\u00e3o do escopo, principais caracter\u00edsticas e componentes dos processos e sistemas de gest\u00e3o de riscos e de controle interno em rela\u00e7\u00e3o ao relato de sustentabilidade'
  },
  {
    id: '4e1de03f-0a2b-4cc1-818b-30b0776420cc',
    name_dcycle: 'description_of_risk_assessment_approach_followed',
    datapoint_id: 'GOV-5_02',
    esg_category: 'general',
    name_en: 'Description of risk assessment approach followed',
    name_es: 'Descripci\u00f3n del enfoque de evaluaci\u00f3n de riesgos seguido',
    name_pt: 'Descri\u00e7\u00e3o da abordagem de avalia\u00e7\u00e3o de risco seguida'
  },
  {
    id: '4e7cedf2-12d8-4ac4-babf-b8c74d78a39a',
    name_dcycle: 'description_of_main_risks_identified_and_their_mitigation_strategies',
    datapoint_id: 'GOV-5_03',
    esg_category: 'general',
    name_en: 'Description of main risks identified and their mitigation strategies',
    name_es:
      'Descripci\u00f3n de los principales riesgos identificados y sus estrategias de mitigaci\u00f3n',
    name_pt:
      'Descri\u00e7\u00e3o dos principais riscos identificados e respetivas estrat\u00e9gias de mitiga\u00e7\u00e3o'
  },
  {
    id: '8cc28423-9f29-4fb4-8115-1509c5db0378',
    name_dcycle:
      'description_of_how_findings_of_risk_assessment_and_internal_controls_as_regards_sustainability_reporting_process_have_been_integrated_into_relevant_internal_functions_and_processes',
    datapoint_id: 'GOV-5_04',
    esg_category: 'general',
    name_en:
      'Description of how findings of risk assessment and internal controls as regards sustainability reporting process have been integrated into relevant internal functions and processes',
    name_es:
      'Descripci\u00f3n de c\u00f3mo se han integrado los resultados de la evaluaci\u00f3n de riesgos y los controles internos en lo que respecta al proceso de elaboraci\u00f3n de informes de sostenibilidad en las funciones y procesos internos pertinentes.',
    name_pt:
      'Descri\u00e7\u00e3o de como as conclus\u00f5es da avalia\u00e7\u00e3o de riscos e dos controles internos relativos ao processo de relato de sustentabilidade foram integradas nas fun\u00e7\u00f5es e processos internos relevantes'
  },
  {
    id: '4feca7a2-bea2-48a4-8aac-7d63f330e073',
    name_dcycle:
      'description_of_periodic_reporting_of_findings_of_risk_assessment_and_internal_controls_to_administrative,_management_and_supervisory_bodies',
    datapoint_id: 'GOV-5_05',
    esg_category: 'general',
    name_en:
      'Description of periodic reporting of findings of risk assessment and internal controls to administrative, management and supervisory bodies',
    name_es:
      'Descripci\u00f3n de la presentaci\u00f3n de informes peri\u00f3dicos sobre los resultados de la evaluaci\u00f3n de riesgos y los controles internos a los \u00f3rganos de administraci\u00f3n, direcci\u00f3n y supervisi\u00f3n',
    name_pt:
      'Descri\u00e7\u00e3o do reporte peri\u00f3dico das conclus\u00f5es da avalia\u00e7\u00e3o de riscos e dos controlos internos aos \u00f3rg\u00e3os de administra\u00e7\u00e3o, gest\u00e3o e fiscaliza\u00e7\u00e3o'
  },
  {
    id: '846bae78-9cb0-4bd5-b648-9cf2febce433',
    name_dcycle: 'description_of_significant_groups_of_products_and_(or)_services_offered',
    datapoint_id: 'SBM-1_01',
    esg_category: 'general',
    name_en: 'Description of significant groups of products and (or) services offered',
    name_es: 'Descripci\u00f3n de grupos significativos de productos y (o) servicios ofrecidos.',
    name_pt:
      'Descri\u00e7\u00e3o de grupos significativos de produtos e (ou) servi\u00e7os oferecidos'
  },
  {
    id: '4d522059-404a-4a2e-b5ef-f70af0d20b2a',
    name_dcycle: 'description_of_significant_markets_and_(or)_customer_groups_served',
    datapoint_id: 'SBM-1_02',
    esg_category: 'general',
    name_en: 'Description of significant markets and (or) customer groups served',
    name_es: 'Descripci\u00f3n de mercados importantes y (o) grupos de clientes atendidos',
    name_pt: 'Descri\u00e7\u00e3o de mercados significativos e (ou) grupos de clientes atendidos'
  },
  {
    id: '08f0380c-1de2-4a9f-9532-8c5ad3a65555',
    name_dcycle: 'total_number_of_employees_(head_count)',
    datapoint_id: 'SBM-1_03',
    esg_category: 'general',
    name_en: 'Total number of employees (head count)',
    name_es: 'N\u00famero total de empleados (recuento de personas)',
    name_pt: 'N\u00famero total de funcion\u00e1rios (n\u00famero de funcion\u00e1rios)'
  },
  {
    id: '9a222a4d-ee5e-4b61-85fe-355346dee35a',
    name_dcycle: 'number_of_employees_(head_count)',
    datapoint_id: 'SBM-1_04',
    esg_category: 'general',
    name_en: 'Number of employees (head count)',
    name_es: 'N\u00famero de empleados (recuento de personas)',
    name_pt: 'N\u00famero de funcion\u00e1rios (n\u00famero de funcion\u00e1rios)'
  },
  {
    id: '9269e754-f6b4-46f5-86ab-7edb31d5b2eb',
    name_dcycle: 'description_of_products_and_services_that_are_banned_in_certain_markets',
    datapoint_id: 'SBM-1_05',
    esg_category: 'general',
    name_en: 'Description of products and services that are banned in certain markets',
    name_es:
      'Descripci\u00f3n de productos y servicios que est\u00e1n prohibidos en ciertos mercados.',
    name_pt: 'Descri\u00e7\u00e3o de produtos e servi\u00e7os proibidos em determinados mercados'
  },
  {
    id: '90f3dcd8-caaa-4ba8-85ef-65e918a23b26',
    name_dcycle: 'total_revenue',
    datapoint_id: 'SBM-1_06',
    esg_category: 'general',
    name_en: 'Total revenue',
    name_es: 'Ingresos totales',
    name_pt: 'Receita total'
  },
  {
    id: 'b9bb89e8-a3a3-42fd-ae70-bead3ecab030',
    name_dcycle: 'revenue_by_significant_esrs_sectors',
    datapoint_id: 'SBM-1_07',
    esg_category: 'general',
    name_en: 'Revenue by significant ESRS Sectors',
    name_es: 'Ingresos por sectores ESRS importantes',
    name_pt: 'Receita por setores ESRS significativos'
  },
  {
    id: 'f0e8cb6d-c1b7-4b4c-8856-2097bcde44f0',
    name_dcycle:
      'list_of_additional_significant_esrs_sectors_in_which_significant_activities_are_developed_or_in_which_undertaking_is_or_may_be_connected_to_material_impacts',
    datapoint_id: 'SBM-1_08',
    esg_category: 'general',
    name_en:
      'List of additional significant ESRS sectors in which significant activities are developed or in which undertaking is or may be connected to material impacts',
    name_es:
      'Lista de sectores adicionales ESRS significativos en los que se desarrollan actividades significativas o en los que el emprendimiento est\u00e1 o puede estar conectado a impactos materiales',
    name_pt:
      'Lista de setores adicionais significativos da ESRS nos quais s\u00e3o desenvolvidas atividades significativas ou nos quais o empreendimento est\u00e1 ou pode estar ligado a impactos materiais'
  },
  {
    id: '6942c7d8-12e0-4991-9e37-10a2d271f67b',
    name_dcycle: 'undertaking_is_active_in_fossil_fuel_(coal,_oil_and_gas)_sector',
    datapoint_id: 'SBM-1_09',
    esg_category: 'general',
    name_en: 'Undertaking is active in fossil fuel (coal, oil and gas) sector',
    name_es:
      'La empresa opera en el sector de los combustibles f\u00f3siles (carb\u00f3n, petr\u00f3leo y gas)',
    name_pt:
      'A empresa atua no setor de combust\u00edveis f\u00f3sseis (carv\u00e3o, petr\u00f3leo e g\u00e1s)'
  },
  {
    id: '5f1ca4b1-482d-4a4f-b7af-94694835efaa',
    name_dcycle: 'revenue_from_fossil_fuel_(coal,_oil_and_gas)_sector',
    datapoint_id: 'SBM-1_10',
    esg_category: 'general',
    name_en: 'Revenue from fossil fuel (coal, oil and gas) sector',
    name_es: 'Ingresos del sector de combustibles f\u00f3siles (carb\u00f3n, petr\u00f3leo y gas)',
    name_pt:
      'Receita do setor de combust\u00edveis f\u00f3sseis (carv\u00e3o, petr\u00f3leo e g\u00e1s)'
  },
  {
    id: '3b451306-f4a1-44fd-b020-c47760e717cd',
    name_dcycle: 'revenue_from_coal',
    datapoint_id: 'SBM-1_11',
    esg_category: 'general',
    name_en: 'Revenue from coal',
    name_es: 'Ingresos del carb\u00f3n',
    name_pt: 'Receita do carv\u00e3o'
  },
  {
    id: '8ea08e12-7a20-45cc-8179-47bfa9ad1654',
    name_dcycle: 'revenue_from_oil',
    datapoint_id: 'SBM-1_12',
    esg_category: 'general',
    name_en: 'Revenue from oil',
    name_es: 'Ingresos del petr\u00f3leo',
    name_pt: 'Receita do petr\u00f3leo'
  },
  {
    id: 'b5fb49c0-73d3-4560-982f-c17e5ccb628f',
    name_dcycle: 'revenue_from_gas',
    datapoint_id: 'SBM-1_13',
    esg_category: 'general',
    name_en: 'Revenue from gas',
    name_es: 'Ingresos por gas',
    name_pt: 'Receita de g\u00e1s'
  },
  {
    id: '034b67fe-c4a0-4b94-8c3a-7a062b43754e',
    name_dcycle: 'revenue_from_taxonomy-aligned_economic_activities_related_to_fossil_gas',
    datapoint_id: 'SBM-1_14',
    esg_category: 'general',
    name_en: 'Revenue from Taxonomy-aligned economic activities related to fossil gas',
    name_es:
      'Ingresos de actividades econ\u00f3micas alineadas con la taxonom\u00eda relacionadas con el gas f\u00f3sil',
    name_pt:
      'Receitas provenientes de atividades econ\u00f3micas alinhadas pela taxonomia relacionadas com o g\u00e1s f\u00f3ssil'
  },
  {
    id: 'c5d5c245-7962-46bc-a83e-cb57cfa7ed30',
    name_dcycle: 'undertaking_is_active_in_chemicals_production',
    datapoint_id: 'SBM-1_15',
    esg_category: 'general',
    name_en: 'Undertaking is active in chemicals production',
    name_es: 'La empresa se dedica a la producci\u00f3n de productos qu\u00edmicos.',
    name_pt: 'Empresa atua na produ\u00e7\u00e3o de produtos qu\u00edmicos'
  },
  {
    id: 'fbac99bc-c7e3-49de-86fe-6f3756148733',
    name_dcycle: 'revenue_from_chemicals_production',
    datapoint_id: 'SBM-1_16',
    esg_category: 'general',
    name_en: 'Revenue from chemicals production',
    name_es: 'Ingresos de la producci\u00f3n de productos qu\u00edmicos',
    name_pt: 'Receita da produ\u00e7\u00e3o de produtos qu\u00edmicos'
  },
  {
    id: '4b08a98c-fa77-485c-85b9-680e60dd7ac2',
    name_dcycle: 'undertaking_is_active_in_controversial_weapons',
    datapoint_id: 'SBM-1_17',
    esg_category: 'general',
    name_en: 'Undertaking is active in controversial weapons',
    name_es: 'La empresa participa activamente en armas controvertidas',
    name_pt: 'Empreendimento atua em armas pol\u00eamicas'
  },
  {
    id: 'bdea7c69-7318-4c4b-a42c-913447ee2c81',
    name_dcycle: 'revenue_from_controversial_weapons',
    datapoint_id: 'SBM-1_18',
    esg_category: 'general',
    name_en: 'Revenue from controversial weapons',
    name_es: 'Ingresos por armas controvertidas',
    name_pt: 'Receita de armas controversas'
  },
  {
    id: 'e37dc1c8-dae7-415c-833e-7d71cb1a1109',
    name_dcycle: 'undertaking_is_active_in_cultivation_and_production_of_tobacco',
    datapoint_id: 'SBM-1_19',
    esg_category: 'general',
    name_en: 'Undertaking is active in cultivation and production of tobacco',
    name_es: 'La empresa se dedica al cultivo y producci\u00f3n de tabaco.',
    name_pt: 'Empreendimento atua no cultivo e produ\u00e7\u00e3o de tabaco'
  },
  {
    id: '0d3521cf-1a4f-4c22-b728-e39a88b4b352',
    name_dcycle: 'revenue_from_cultivation_and_production_of_tobacco',
    datapoint_id: 'SBM-1_20',
    esg_category: 'general',
    name_en: 'Revenue from cultivation and production of tobacco',
    name_es: 'Ingresos del cultivo y producci\u00f3n de tabaco',
    name_pt: 'Receita do cultivo e produ\u00e7\u00e3o de tabaco'
  },
  {
    id: '022f68a1-f65d-45e7-a459-c0c0dc801096',
    name_dcycle:
      'description_of_sustainability-related_goals_in_terms_of_significant_groups_of_products_and_services,_customer_categories,_geographical_areas_and_relationships_with_stakeholders',
    datapoint_id: 'SBM-1_21',
    esg_category: 'general',
    name_en:
      'Description of sustainability-related goals in terms of significant groups of products and services, customer categories, geographical areas and relationships with stakeholders',
    name_es:
      'Descripci\u00f3n de los objetivos relacionados con la sostenibilidad en t\u00e9rminos de grupos significativos de productos y servicios, categor\u00edas de clientes, \u00e1reas geogr\u00e1ficas y relaciones con las partes interesadas.',
    name_pt:
      'Descri\u00e7\u00e3o dos objetivos relacionados com a sustentabilidade em termos de grupos significativos de produtos e servi\u00e7os, categorias de clientes, \u00e1reas geogr\u00e1ficas e rela\u00e7\u00f5es com stakeholders'
  },
  {
    id: 'f1d20ad8-d9f9-488c-bf29-d336d581df80',
    name_dcycle:
      'disclosure_of_assessment_of_current_significant_products_and_(or)_services,_and_significant_markets_and_customer_groups,_in_relation_to_sustainability-related_goals',
    datapoint_id: 'SBM-1_22',
    esg_category: 'general',
    name_en:
      'Disclosure of assessment of current significant products and (or) services, and significant markets and customer groups, in relation to sustainability-related goals',
    name_es:
      'Divulgaci\u00f3n de la evaluaci\u00f3n de productos y (o) servicios importantes actuales, y mercados y grupos de clientes importantes, en relaci\u00f3n con los objetivos relacionados con la sostenibilidad.',
    name_pt:
      'Divulga\u00e7\u00e3o da avalia\u00e7\u00e3o de produtos e (ou) servi\u00e7os atuais significativos, e de mercados e grupos de clientes significativos, em rela\u00e7\u00e3o \u00e0s metas relacionadas \u00e0 sustentabilidade'
  },
  {
    id: '77025dad-e8b0-413c-a734-f3ac08b16e44',
    name_dcycle:
      'disclosure_of_elements_of_strategy_that_relate_to_or_impact_sustainability_matters',
    datapoint_id: 'SBM-1_23',
    esg_category: 'general',
    name_en: 'Disclosure of elements of strategy that relate to or impact sustainability matters',
    name_es:
      'Divulgaci\u00f3n de elementos de la estrategia que se relacionan o impactan asuntos de sostenibilidad.',
    name_pt:
      'Divulga\u00e7\u00e3o de elementos de estrat\u00e9gia que se relacionam ou impactam quest\u00f5es de sustentabilidade'
  },
  {
    id: '44df41aa-6ff4-4777-90d9-4c6d7ca3ccc5',
    name_dcycle: 'list_of_esrs_sectors_that_are_significant_for_undertaking',
    datapoint_id: 'SBM-1_24',
    esg_category: 'general',
    name_en: 'List of ESRS sectors that are significant for undertaking',
    name_es: 'Lista de sectores ESRS que son importantes para emprender',
    name_pt: 'Lista de setores ESRS que s\u00e3o significativos para a realiza\u00e7\u00e3o'
  },
  {
    id: 'fde33c7f-c85c-4425-be60-cb134cd10ffd',
    name_dcycle: 'description_of_business_model_and_value_chain',
    datapoint_id: 'SBM-1_25',
    esg_category: 'general',
    name_en: 'Description of business model and value chain',
    name_es: 'Descripci\u00f3n del modelo de negocio y cadena de valor.',
    name_pt: 'Descri\u00e7\u00e3o do modelo de neg\u00f3cios e cadeia de valor'
  },
  {
    id: 'c1833b3a-cb25-49d1-9acd-d0bde354cd0a',
    name_dcycle: 'description_of_inputs_and_approach_to_gathering,_developing_and_securing_inputs',
    datapoint_id: 'SBM-1_26',
    esg_category: 'general',
    name_en: 'Description of inputs and approach to gathering, developing and securing inputs',
    name_es:
      'Descripci\u00f3n de los insumos y enfoque para recopilar, desarrollar y asegurar los insumos.',
    name_pt:
      'Descri\u00e7\u00e3o dos insumos e abordagem para coletar, desenvolver e garantir insumos'
  },
  {
    id: '2633122a-6d73-4610-964d-b3178d18404b',
    name_dcycle:
      'description_of_outputs_and_outcomes_in_terms_of_current_and_expected_benefits_for_customers,_investors_and_other_stakeholders',
    datapoint_id: 'SBM-1_27',
    esg_category: 'general',
    name_en:
      'Description of outputs and outcomes in terms of current and expected benefits for customers, investors and other stakeholders',
    name_es:
      'Descripci\u00f3n de productos y resultados en t\u00e9rminos de beneficios actuales y esperados para clientes, inversores y otras partes interesadas.',
    name_pt:
      'Descri\u00e7\u00e3o dos produtos e resultados em termos de benef\u00edcios atuais e esperados para clientes, investidores e outras partes interessadas'
  },
  {
    id: 'f2776396-40c9-441a-a072-663cce832c42',
    name_dcycle:
      'description_of_main_features_of_upstream_and_downstream_value_chain_and_undertakings_position_in_value_chain',
    datapoint_id: 'SBM-1_28',
    esg_category: 'general',
    name_en:
      'Description of main features of upstream and downstream value chain and undertakings position in value chain',
    name_es:
      'Descripci\u00f3n de las principales caracter\u00edsticas de la cadena de valor ascendente y descendente y de la posici\u00f3n de las empresas en la cadena de valor.',
    name_pt:
      'Descri\u00e7\u00e3o das principais caracter\u00edsticas da cadeia de valor a montante e a jusante e posi\u00e7\u00e3o das empresas na cadeia de valor'
  },
  {
    id: '186e8acc-2aa4-41da-bf40-226b3fbc4fc4',
    name_dcycle: 'description_of_stakeholder_engagement',
    datapoint_id: 'SBM-2_01',
    esg_category: 'general',
    name_en: 'Description of stakeholder engagement',
    name_es: 'Descripci\u00f3n de la participaci\u00f3n de las partes interesadas',
    name_pt: 'Descri\u00e7\u00e3o do envolvimento das partes interessadas'
  },
  {
    id: 'bc9d6805-e655-4b12-b8ba-08d772af7f4d',
    name_dcycle: 'description_of_key_stakeholders',
    datapoint_id: 'SBM-2_02',
    esg_category: 'general',
    name_en: 'Description of key stakeholders',
    name_es: 'Descripci\u00f3n de las partes interesadas clave',
    name_pt: 'Descri\u00e7\u00e3o das principais partes interessadas'
  },
  {
    id: 'bb6e2483-c977-42b8-8fc8-df2e12072332',
    name_dcycle: 'description_of_categories_of_stakeholders_for_which_engagement_occurs',
    datapoint_id: 'SBM-2_03',
    esg_category: 'general',
    name_en: 'Description of categories of stakeholders for which engagement occurs',
    name_es:
      'Descripci\u00f3n de las categor\u00edas de partes interesadas para las que se produce la participaci\u00f3n',
    name_pt:
      'Descri\u00e7\u00e3o das categorias de partes interessadas para as quais ocorre o envolvimento'
  },
  {
    id: 'c57a1a51-1bb0-407c-b1c6-61282fd9bcb0',
    name_dcycle: 'description_of_how_stakeholder_engagement_is_organised',
    datapoint_id: 'SBM-2_04',
    esg_category: 'general',
    name_en: 'Description of how stakeholder engagement is organised',
    name_es:
      'Descripci\u00f3n de c\u00f3mo se organiza la participaci\u00f3n de las partes interesadas',
    name_pt: 'Descri\u00e7\u00e3o de como o envolvimento das partes interessadas \u00e9 organizado'
  },
  {
    id: '4a19efd5-2180-4e11-bf64-073e093eddfd',
    name_dcycle: 'description_of_purpose_of_stakeholder_engagement',
    datapoint_id: 'SBM-2_05',
    esg_category: 'general',
    name_en: 'Description of purpose of stakeholder engagement',
    name_es:
      'Descripci\u00f3n del prop\u00f3sito de la participaci\u00f3n de las partes interesadas',
    name_pt: 'Descri\u00e7\u00e3o do prop\u00f3sito do envolvimento das partes interessadas'
  },
  {
    id: 'cb6f9759-ea64-4cac-b50c-de86075d8ec2',
    name_dcycle: 'description_of_how_outcome_of_stakeholder_engagement_is_taken_into_account',
    datapoint_id: 'SBM-2_06',
    esg_category: 'general',
    name_en: 'Description of how outcome of stakeholder engagement is taken into account',
    name_es:
      'Descripci\u00f3n de c\u00f3mo se tiene en cuenta el resultado de la participaci\u00f3n de las partes interesadas',
    name_pt:
      'Descri\u00e7\u00e3o de como o resultado do envolvimento das partes interessadas \u00e9 levado em considera\u00e7\u00e3o'
  },
  {
    id: 'c0569c5d-c25e-48d6-8f79-2c6ab5475b7b',
    name_dcycle:
      "description_of_understanding_of_interests_and_views_of_key_stakeholders_as_they_relate_to_undertaking's_strategy_and_business_model",
    datapoint_id: 'SBM-2_07',
    esg_category: 'general',
    name_en:
      "Description of understanding of interests and views of key stakeholders as they relate to undertaking's strategy and business model",
    name_es:
      'Descripci\u00f3n de la comprensi\u00f3n de los intereses y puntos de vista de las partes interesadas clave en relaci\u00f3n con la estrategia y el modelo de negocio de la empresa.',
    name_pt:
      'Descri\u00e7\u00e3o da compreens\u00e3o dos interesses e pontos de vista das principais partes interessadas no que se refere \u00e0 estrat\u00e9gia e ao modelo de neg\u00f3cio da empresa'
  },
  {
    id: '62af3bf2-24ee-4869-a77b-0b0cdf606041',
    name_dcycle: 'description_of_amendments_to_strategy_and_(or)_business_model',
    datapoint_id: 'SBM-2_08',
    esg_category: 'general',
    name_en: 'Description of amendments to strategy and (or) business model',
    name_es: 'Descripci\u00f3n de modificaciones a la estrategia y (o) modelo de negocio.',
    name_pt:
      'Descri\u00e7\u00e3o das altera\u00e7\u00f5es na estrat\u00e9gia e (ou) modelo de neg\u00f3cios'
  },
  {
    id: '4744e17a-b4f7-4b68-b65e-0fce7317f54f',
    name_dcycle:
      'description_of_how_strategy_and_(or)_business_model_have_been_amended_or_are_expected_to_be_amended_to_address_interests_and_views_of_stakeholders',
    datapoint_id: 'SBM-2_09',
    esg_category: 'general',
    name_en:
      'Description of how strategy and (or) business model have been amended or are expected to be amended to address interests and views of stakeholders',
    name_es:
      'Descripci\u00f3n de c\u00f3mo se ha modificado o se espera modificar la estrategia y (o) el modelo de negocio para abordar los intereses y puntos de vista de las partes interesadas.',
    name_pt:
      'Descri\u00e7\u00e3o de como a estrat\u00e9gia e (ou) o modelo de neg\u00f3cios foram alterados ou dever\u00e3o ser alterados para atender aos interesses e opini\u00f5es das partes interessadas'
  },
  {
    id: 'd2cda187-8617-468a-ae66-7821bb8a07cd',
    name_dcycle: 'description_of_any_further_steps_that_are_being_planned_and_in_what_timeline',
    datapoint_id: 'SBM-2_10',
    esg_category: 'general',
    name_en: 'Description of any further steps that are being planned and in what timeline',
    name_es:
      'Descripci\u00f3n de cualquier paso adicional que se est\u00e9 planificando y en qu\u00e9 cronograma',
    name_pt:
      'Descri\u00e7\u00e3o de quaisquer etapas adicionais que est\u00e3o sendo planejadas e em que cronograma'
  },
  {
    id: 'd3a4a9fe-6089-428b-818f-420b37f572c8',
    name_dcycle:
      'further_steps_that_are_being_planned_are_likely_to_modify_relationship_with_and_views_of_stakeholders',
    datapoint_id: 'SBM-2_11',
    esg_category: 'general',
    name_en:
      'Further steps that are being planned are likely to modify relationship with and views of stakeholders',
    name_es:
      'Es probable que las medidas adicionales que se est\u00e1n planificando modifiquen la relaci\u00f3n y las opiniones de las partes interesadas.',
    name_pt:
      'Outras etapas que est\u00e3o sendo planejadas provavelmente modificar\u00e3o o relacionamento e as opini\u00f5es das partes interessadas'
  },
  {
    id: 'ac7b44ae-7210-4c95-956b-17e7badc9d2f',
    name_dcycle:
      'description_of_how_administrative,_management_and_supervisory_bodies_are_informed_about_views_and_interests_of_affected_stakeholders_with_regard_to_sustainability-related_impacts',
    datapoint_id: 'SBM-2_12',
    esg_category: 'general',
    name_en:
      'Description of how administrative, management and supervisory bodies are informed about views and interests of affected stakeholders with regard to sustainability-related impacts',
    name_es:
      'Descripci\u00f3n de c\u00f3mo se informa a los \u00f3rganos administrativos, de gesti\u00f3n y de supervisi\u00f3n sobre las opiniones e intereses de las partes interesadas afectadas con respecto a los impactos relacionados con la sostenibilidad.',
    name_pt:
      'Descri\u00e7\u00e3o de como os \u00f3rg\u00e3os administrativos, de gest\u00e3o e de supervis\u00e3o s\u00e3o informados sobre as opini\u00f5es e interesses das partes interessadas afetadas no que diz respeito aos impactos relacionados com a sustentabilidade'
  },
  {
    id: '8ef1b62c-e626-469c-aa4f-5fbcbb027636',
    name_dcycle: 'description_of_material_impacts_resulting_from_materiality_assessment',
    datapoint_id: 'SBM-3_01',
    esg_category: 'general',
    name_en: 'Description of material impacts resulting from materiality assessment',
    name_es:
      'Descripci\u00f3n de los impactos materiales resultantes de la evaluaci\u00f3n de materialidad',
    name_pt:
      'Descri\u00e7\u00e3o dos impactos materiais resultantes da avalia\u00e7\u00e3o de materialidade'
  },
  {
    id: '929e09ed-43ec-4769-80ca-cf9d705081ee',
    name_dcycle:
      'description_of_material_risks_and_opportunities_resulting_from_materiality_assessment',
    datapoint_id: 'SBM-3_02',
    esg_category: 'general',
    name_en:
      'Description of material risks and opportunities resulting from materiality assessment',
    name_es:
      'Descripci\u00f3n de riesgos y oportunidades materiales resultantes de la evaluaci\u00f3n de materialidad',
    name_pt:
      'Descri\u00e7\u00e3o dos riscos e oportunidades materiais resultantes da avalia\u00e7\u00e3o de materialidade'
  },
  {
    id: 'c78ccbf0-07ea-41d2-a07a-385888f69363',
    name_dcycle:
      'disclosure_of_current_and_anticipated_effects_of_material_impacts,_risks_and_opportunities_on_business_model,_value_chain,_strategy_and_decision-making,_and_how_undertaking_has_responded_or_plans_to_respond_to_these_effects',
    datapoint_id: 'SBM-3_03',
    esg_category: 'general',
    name_en:
      'Disclosure of current and anticipated effects of material impacts, risks and opportunities on business model, value chain, strategy and decision-making, and how undertaking has responded or plans to respond to these effects',
    name_es:
      'Divulgaci\u00f3n de los efectos actuales y previstos de los impactos, riesgos y oportunidades materiales en el modelo de negocio, la cadena de valor, la estrategia y la toma de decisiones, y c\u00f3mo la empresa ha respondido o planea responder a estos efectos.',
    name_pt:
      'Divulga\u00e7\u00e3o dos efeitos atuais e previstos de impactos, riscos e oportunidades materiais no modelo de neg\u00f3cios, na cadeia de valor, na estrat\u00e9gia e na tomada de decis\u00f5es, e como a empresa respondeu ou planeja responder a esses efeitos'
  },
  {
    id: 'd6c36382-70b1-47b5-9a93-c1482356e577',
    name_dcycle:
      'disclosure_of_how_material_negative_and_positive_impacts_affect_(or_are_likely_to_affect)_people_or_environment',
    datapoint_id: 'SBM-3_04',
    esg_category: 'general',
    name_en:
      'Disclosure of how material negative and positive impacts affect (or are likely to affect) people or environment',
    name_es:
      'Divulgaci\u00f3n de c\u00f3mo los impactos materiales negativos y positivos afectan (o pueden afectar) a las personas o al medio ambiente.',
    name_pt:
      'Divulga\u00e7\u00e3o de como os impactos negativos e positivos materiais afetam (ou provavelmente afetar\u00e3o) as pessoas ou o meio ambiente'
  },
  {
    id: 'df1c926b-914a-48a9-9475-09db32d61e8d',
    name_dcycle:
      'disclosure_of_whether_and_how_material_impacts_originate_from_or_are_connected_to_strategy_and_business_model',
    datapoint_id: 'SBM-3_05',
    esg_category: 'general',
    name_en:
      'Disclosure of whether and how material impacts originate from or are connected to strategy and business model',
    name_es:
      'Divulgaci\u00f3n de si los impactos materiales se originan o est\u00e1n conectados con la estrategia y el modelo de negocio y c\u00f3mo',
    name_pt:
      'Divulga\u00e7\u00e3o de se e como os impactos materiais se originam ou est\u00e3o conectados \u00e0 estrat\u00e9gia e ao modelo de neg\u00f3cios'
  },
  {
    id: '40b65150-8b0d-483f-9b03-5af1e5832493',
    name_dcycle: 'disclosure_of_reasonably_expected_time_horizons_of_material_impacts',
    datapoint_id: 'SBM-3_06',
    esg_category: 'general',
    name_en: 'Disclosure of reasonably expected time horizons of material impacts',
    name_es:
      'Divulgaci\u00f3n de horizontes temporales razonablemente esperados de impactos materiales',
    name_pt:
      'Divulga\u00e7\u00e3o de horizontes temporais razoavelmente esperados de impactos materiais'
  },
  {
    id: '68907976-3e63-4aeb-803c-1cfa853b9df8',
    name_dcycle:
      'description_of_nature_of_activities_or_business_relationships_through_which_undertaking_is_involved_with_material_impacts',
    datapoint_id: 'SBM-3_07',
    esg_category: 'general',
    name_en:
      'Description of nature of activities or business relationships through which undertaking is involved with material impacts',
    name_es:
      'Descripci\u00f3n de la naturaleza de las actividades o relaciones comerciales a trav\u00e9s de las cuales la empresa participa con impactos materiales.',
    name_pt:
      'Descri\u00e7\u00e3o da natureza das atividades ou rela\u00e7\u00f5es comerciais atrav\u00e9s das quais a empresa est\u00e1 envolvida com impactos materiais'
  },
  {
    id: '799ecc3d-75b7-489e-962f-f9c0816f0e31',
    name_dcycle:
      'disclosure_of_current_financial_effects_of_material_risks_and_opportunities_on_financial_position,_financial_performance_and_cash_flows_and_material_risks_and_opportunities_for_which_there_is_significant_risk_of_material_adjustment_within_next_annual_reporting_period_to_carrying_amounts_of_assets_and_liabilities_reported_in_related_financial_statements',
    datapoint_id: 'SBM-3_08',
    esg_category: 'general',
    name_en:
      'Disclosure of current financial effects of material risks and opportunities on financial position, financial performance and cash flows and material risks and opportunities for which there is significant risk of material adjustment within next annual reporting period to carrying amounts of assets and liabilities reported in related financial statements',
    name_es:
      'Informaci\u00f3n a revelar de los efectos financieros actuales de los riesgos y oportunidades materiales sobre la situaci\u00f3n financiera, el desempe\u00f1o financiero y los flujos de efectivo y los riesgos y oportunidades materiales para los cuales existe un riesgo significativo de ajuste material dentro del pr\u00f3ximo per\u00edodo anual de presentaci\u00f3n de informes a los valores en libros de los activos y pasivos informados en los estados financieros relacionados.',
    name_pt:
      'Divulga\u00e7\u00e3o dos efeitos financeiros atuais de riscos e oportunidades materiais sobre a posi\u00e7\u00e3o financeira, o desempenho financeiro e os fluxos de caixa e riscos e oportunidades materiais para os quais existe um risco significativo de ajuste material no pr\u00f3ximo per\u00edodo de relat\u00f3rio anual aos valores cont\u00e1beis de ativos e passivos relatados nas demonstra\u00e7\u00f5es financeiras relacionadas'
  },
  {
    id: '893608d0-59f3-4569-9ca6-f4f03005dad8',
    name_dcycle:
      'disclosure_of_anticipated_financial_effects_of_material_risks_and_opportunities_on_financial_position,_financial_performance_and_cash_flows_over_short-,_medium-_and_long-term',
    datapoint_id: 'SBM-3_09',
    esg_category: 'general',
    name_en:
      'Disclosure of anticipated financial effects of material risks and opportunities on financial position, financial performance and cash flows over short-, medium- and long-term',
    name_es:
      'Divulgaci\u00f3n de los efectos financieros previstos de los riesgos y oportunidades materiales sobre la posici\u00f3n financiera, el desempe\u00f1o financiero y los flujos de efectivo a corto, mediano y largo plazo.',
    name_pt:
      'Divulga\u00e7\u00e3o dos efeitos financeiros previstos de riscos e oportunidades relevantes sobre a posi\u00e7\u00e3o financeira, o desempenho financeiro e os fluxos de caixa no curto, m\u00e9dio e longo prazo'
  },
  {
    id: '5774ec20-7317-471a-9370-60ae4e106d69',
    name_dcycle:
      'information_about_resilience_of_strategy_and_business_model_regarding_capacity_to_address_material_impacts_and_risks_and_to_take_advantage_of_material_opportunities',
    datapoint_id: 'SBM-3_10',
    esg_category: 'general',
    name_en:
      'Information about resilience of strategy and business model regarding capacity to address material impacts and risks and to take advantage of material opportunities',
    name_es:
      'Informaci\u00f3n sobre la resiliencia de la estrategia y el modelo de negocio con respecto a la capacidad para abordar impactos y riesgos materiales y aprovechar oportunidades materiales.',
    name_pt:
      'Informa\u00e7\u00f5es sobre a resili\u00eancia da estrat\u00e9gia e do modelo de neg\u00f3cios em rela\u00e7\u00e3o \u00e0 capacidade de lidar com impactos e riscos materiais e de aproveitar oportunidades materiais'
  },
  {
    id: 'd6faa269-9271-4e51-9374-ce1d4acfa916',
    name_dcycle:
      'disclosure_of_changes_to_material_impacts,_risks_and_opportunities_compared_to_previous_reporting_period',
    datapoint_id: 'SBM-3_11',
    esg_category: 'general',
    name_en:
      'Disclosure of changes to material impacts, risks and opportunities compared to previous reporting period',
    name_es:
      'Divulgaci\u00f3n de cambios en los impactos, riesgos y oportunidades materiales en comparaci\u00f3n con el per\u00edodo de informe anterior',
    name_pt:
      'Divulga\u00e7\u00e3o de altera\u00e7\u00f5es nos impactos, riscos e oportunidades materiais em compara\u00e7\u00e3o com o per\u00edodo de relat\u00f3rio anterior'
  },
  {
    id: '2675372e-d184-462f-9af3-8c16a003f905',
    name_dcycle:
      'disclosure_of_specification_of_impacts,_risks_and_opportunities_that_are_covered_by_esrs_disclosure_requirements_as_opposed_to_those_covered_by_additional_entity-specific_disclosures',
    datapoint_id: 'SBM-3_12',
    esg_category: 'general',
    name_en:
      'Disclosure of specification of impacts, risks and opportunities that are covered by ESRS Disclosure Requirements as opposed to those covered by additional entity-specific disclosures',
    name_es:
      'Divulgaci\u00f3n de la especificaci\u00f3n de los impactos, riesgos y oportunidades que est\u00e1n cubiertos por los Requisitos de Divulgaci\u00f3n ESRS en contraposici\u00f3n a aquellos cubiertos por divulgaciones adicionales espec\u00edficas de la entidad.',
    name_pt:
      'Divulga\u00e7\u00e3o de especifica\u00e7\u00f5es de impactos, riscos e oportunidades que s\u00e3o cobertos pelos Requisitos de Divulga\u00e7\u00e3o ESRS, em oposi\u00e7\u00e3o \u00e0queles cobertos por divulga\u00e7\u00f5es adicionais espec\u00edficas da entidade'
  },
  {
    id: 'df032572-7578-4d90-a8cc-35ccbeea6be6',
    name_dcycle:
      'description_of_methodologies_and_assumptions_applied_in_process_to_identify_impacts,_risks_and_opportunities',
    datapoint_id: 'IRO-1_01',
    esg_category: 'general',
    name_en:
      'Description of methodologies and assumptions applied in process to identify impacts, risks and opportunities',
    name_es:
      'Descripci\u00f3n de metodolog\u00edas y supuestos aplicados en el proceso para identificar impactos, riesgos y oportunidades.',
    name_pt:
      'Descri\u00e7\u00e3o das metodologias e premissas aplicadas no processo para identifica\u00e7\u00e3o de impactos, riscos e oportunidades'
  },
  {
    id: '712aa917-a2bb-41b7-98ba-55f5ae26373c',
    name_dcycle:
      'description_of_process_to_identify,_assess,_prioritise_and_monitor_potential_and_actual_impacts_on_people_and_environment,_informed_by_due_diligence_process',
    datapoint_id: 'IRO-1_02',
    esg_category: 'general',
    name_en:
      'Description of process to identify, assess, prioritise and monitor potential and actual impacts on people and environment, informed by due diligence process',
    name_es:
      'Descripci\u00f3n del proceso para identificar, evaluar, priorizar y monitorear los impactos potenciales y reales sobre las personas y el medio ambiente, informado por el proceso de debida diligencia',
    name_pt:
      'Descri\u00e7\u00e3o do processo para identificar, avaliar, priorizar e monitorar impactos potenciais e reais nas pessoas e no meio ambiente, informado pelo processo de due diligence'
  },
  {
    id: 'd7458992-f441-47e6-a2a6-0dc18b122836',
    name_dcycle:
      'description_of_how_process_focuses_on_specific_activities,_business_relationships,_geographies_or_other_factors_that_give_rise_to_heightened_risk_of_adverse_impacts',
    datapoint_id: 'IRO-1_03',
    esg_category: 'general',
    name_en:
      'Description of how process focuses on specific activities, business relationships, geographies or other factors that give rise to heightened risk of adverse impacts',
    name_es:
      'Descripci\u00f3n de c\u00f3mo el proceso se centra en actividades espec\u00edficas, relaciones comerciales, geograf\u00edas u otros factores que dan lugar a un mayor riesgo de impactos adversos.',
    name_pt:
      'Descri\u00e7\u00e3o de como o processo se concentra em atividades espec\u00edficas, rela\u00e7\u00f5es comerciais, geografias ou outros fatores que d\u00e3o origem a um risco aumentado de impactos adversos'
  },
  {
    id: '3d2e5f07-9035-4904-853b-80f9a623e9a2',
    name_dcycle:
      'description_of_how_process_considers_impacts_with_which_undertaking_is_involved_through_own_operations_or_as_result_of_business_relationships',
    datapoint_id: 'IRO-1_04',
    esg_category: 'general',
    name_en:
      'Description of how process considers impacts with which undertaking is involved through own operations or as result of business relationships',
    name_es:
      'Descripci\u00f3n de c\u00f3mo el proceso considera los impactos en los que se involucra el emprendimiento a trav\u00e9s de operaciones propias o como resultado de relaciones comerciales.',
    name_pt:
      'Descri\u00e7\u00e3o de como o processo considera os impactos com os quais a empresa est\u00e1 envolvida atrav\u00e9s de opera\u00e7\u00f5es pr\u00f3prias ou como resultado de rela\u00e7\u00f5es comerciais'
  },
  {
    id: '58f34482-6d5c-41dc-9bf0-a1f91be935b2',
    name_dcycle:
      'description_of_how_process_includes_consultation_with_affected_stakeholders_to_understand_how_they_may_be_impacted_and_with_external_experts',
    datapoint_id: 'IRO-1_05',
    esg_category: 'general',
    name_en:
      'Description of how process includes consultation with affected stakeholders to understand how they may be impacted and with external experts',
    name_es:
      'Descripci\u00f3n de c\u00f3mo el proceso incluye consultas con las partes interesadas afectadas para comprender c\u00f3mo pueden verse afectadas y con expertos externos.',
    name_pt:
      'Descri\u00e7\u00e3o de como o processo inclui consultas com as partes interessadas afetadas para entender como elas podem ser impactadas e com especialistas externos'
  },
  {
    id: '5bf7ff46-72d9-4a78-8b5a-cb6837245c80',
    name_dcycle:
      'description_of_how_process_prioritises_negative_impacts_based_on_their_relative_severity_and_likelihood_and_positive_impacts_based_on_their_relative_scale,_scope_and_likelihood_and_determines_which_sustainability_matters_are_material_for_reporting_purposes',
    datapoint_id: 'IRO-1_06',
    esg_category: 'general',
    name_en:
      'Description of how process prioritises negative impacts based on their relative severity and likelihood and positive impacts based on their relative scale, scope and likelihood and determines which sustainability matters are material for reporting purposes',
    name_es:
      'Descripci\u00f3n de c\u00f3mo el proceso prioriza los impactos negativos en funci\u00f3n de su relativa gravedad y probabilidad y los impactos positivos en funci\u00f3n de su relativa escala, alcance y probabilidad y determina qu\u00e9 asuntos de sostenibilidad son materiales para fines de presentaci\u00f3n de informes.',
    name_pt:
      'Descri\u00e7\u00e3o de como o processo prioriza os impactos negativos com base na sua gravidade e probabilidade relativas e os impactos positivos com base na sua escala, escopo e probabilidade relativos e determina quais quest\u00f5es de sustentabilidade s\u00e3o relevantes para fins de relat\u00f3rio'
  },
  {
    id: '6610dac7-f77b-480d-9901-59fb150a4ed4',
    name_dcycle:
      'description_of_process_used_to_identify,_assess,_prioritise_and_monitor_risks_and_opportunities_that_have_or_may_have_financial_effects',
    datapoint_id: 'IRO-1_07',
    esg_category: 'general',
    name_en:
      'Description of process used to identify, assess, prioritise and monitor risks and opportunities that have or may have financial effects',
    name_es:
      'Descripci\u00f3n del proceso utilizado para identificar, evaluar, priorizar y monitorear riesgos y oportunidades que tienen o pueden tener efectos financieros',
    name_pt:
      'Descri\u00e7\u00e3o do processo utilizado para identificar, avaliar, priorizar e monitorar riscos e oportunidades que t\u00eam ou podem ter efeitos financeiros'
  },
  {
    id: '4b2030e8-aac7-4240-8c43-072d060b94c7',
    name_dcycle:
      'description_of_how_connections_of_impacts_and_dependencies_with_risks_and_opportunities_that_may_arise_from_those_impacts_and_dependencies_have_been_considered',
    datapoint_id: 'IRO-1_08',
    esg_category: 'general',
    name_en:
      'Description of how connections of impacts and dependencies with risks and opportunities that may arise from those impacts and dependencies have been considered',
    name_es:
      'Descripci\u00f3n de c\u00f3mo se han considerado las conexiones de los impactos y dependencias con los riesgos y oportunidades que pueden surgir de esos impactos y dependencias.',
    name_pt:
      'Descri\u00e7\u00e3o de como foram consideradas as conex\u00f5es de impactos e depend\u00eancias com riscos e oportunidades que podem surgir desses impactos e depend\u00eancias'
  },
  {
    id: 'c56135f1-8f86-4aa7-9547-1c9ebcf3c69f',
    name_dcycle:
      'description_of_how_likelihood,_magnitude,_and_nature_of_effects_of_identified_risks_and_opportunities_have_been_assessed',
    datapoint_id: 'IRO-1_09',
    esg_category: 'general',
    name_en:
      'Description of how likelihood, magnitude, and nature of effects of identified risks and opportunities have been assessed',
    name_es:
      'Descripci\u00f3n de c\u00f3mo se han evaluado la probabilidad, magnitud y naturaleza de los efectos de los riesgos y oportunidades identificados.',
    name_pt:
      'Descri\u00e7\u00e3o de como a probabilidade, magnitude e natureza dos efeitos dos riscos e oportunidades identificados foram avaliadas'
  },
  {
    id: '9192d42f-5278-40b0-a9ff-424de0fd790d',
    name_dcycle:
      'description_of_how_sustainability-related_risks_relative_to_other_types_of_risks_have_been_prioritised',
    datapoint_id: 'IRO-1_10',
    esg_category: 'general',
    name_en:
      'Description of how sustainability-related risks relative to other types of risks have been prioritised',
    name_es:
      'Descripci\u00f3n de c\u00f3mo se han priorizado los riesgos relacionados con la sostenibilidad en relaci\u00f3n con otros tipos de riesgos',
    name_pt:
      'Descri\u00e7\u00e3o de como os riscos relacionados \u00e0 sustentabilidade em rela\u00e7\u00e3o a outros tipos de riscos foram priorizados'
  },
  {
    id: '52a9c787-2ee0-43b1-a726-4faa717c689b',
    name_dcycle: 'description_of_decision-making_process_and_related_internal_control_procedures',
    datapoint_id: 'IRO-1_11',
    esg_category: 'general',
    name_en: 'Description of decision-making process and related internal control procedures',
    name_es:
      'Descripci\u00f3n del proceso de toma de decisiones y procedimientos de control interno relacionados',
    name_pt:
      'Descri\u00e7\u00e3o do processo de tomada de decis\u00e3o e procedimentos de controle interno relacionados'
  },
  {
    id: 'dcdad36f-a0e4-4fb3-8963-e5658c747a2e',
    name_dcycle:
      'description_of_extent_to_which_and_how_process_to_identify,_assess_and_manage_impacts_and_risks_is_integrated_into_overall_risk_management_process_and_used_to_evaluate_overall_risk_profile_and_risk_management_processes',
    datapoint_id: 'IRO-1_12',
    esg_category: 'general',
    name_en:
      'Description of extent to which and how process to identify, assess and manage impacts and risks is integrated into overall risk management process and used to evaluate overall risk profile and risk management processes',
    name_es:
      'Descripci\u00f3n de hasta qu\u00e9 punto y c\u00f3mo el proceso para identificar, evaluar y gestionar los impactos y riesgos se integra en el proceso general de gesti\u00f3n de riesgos y se utiliza para evaluar el perfil general de riesgos y los procesos de gesti\u00f3n de riesgos.',
    name_pt:
      'Descri\u00e7\u00e3o de at\u00e9 que ponto e como o processo para identificar, avaliar e gerenciar impactos e riscos \u00e9 integrado ao processo geral de gest\u00e3o de riscos e usado para avaliar o perfil de risco geral e os processos de gest\u00e3o de riscos'
  },
  {
    id: '2725fc4c-b5b4-4d38-b1c1-f5e47e977312',
    name_dcycle:
      'description_of_extent_to_which_and_how_process_to_identify,_assess_and_manage_opportunities_is_integrated_into_overall_management_process',
    datapoint_id: 'IRO-1_13',
    esg_category: 'general',
    name_en:
      'Description of extent to which and how process to identify, assess and manage opportunities is integrated into overall management process',
    name_es:
      'Descripci\u00f3n de hasta qu\u00e9 punto y c\u00f3mo el proceso para identificar, evaluar y gestionar oportunidades se integra en el proceso de gesti\u00f3n general.',
    name_pt:
      'Descri\u00e7\u00e3o de at\u00e9 que ponto e como o processo de identifica\u00e7\u00e3o, avalia\u00e7\u00e3o e gest\u00e3o de oportunidades est\u00e1 integrado no processo de gest\u00e3o global'
  },
  {
    id: '1d3a83da-e70d-45db-85d0-b072839d4154',
    name_dcycle:
      'description_of_input_parameters_used_in_process_to_identify,_assess_and_manage_material_impacts,_risks_and_opportunities',
    datapoint_id: 'IRO-1_14',
    esg_category: 'general',
    name_en:
      'Description of input parameters used in process to identify, assess and manage material impacts, risks and opportunities',
    name_es:
      'Descripci\u00f3n de los par\u00e1metros de entrada utilizados en el proceso para identificar, evaluar y gestionar impactos, riesgos y oportunidades materiales.',
    name_pt:
      'Descri\u00e7\u00e3o dos par\u00e2metros de entrada usados \u200b\u200bno processo para identificar, avaliar e gerenciar impactos, riscos e oportunidades materiais'
  },
  {
    id: 'a57f91cf-a506-4976-8894-5c287b46750d',
    name_dcycle:
      'description_of_how_process_to_identify,_assess_and_manage_impacts,_risks_and_opportunities_has_changed_compared_to_prior_reporting_period',
    datapoint_id: 'IRO-1_15',
    esg_category: 'general',
    name_en:
      'Description of how process to identify, assess and manage impacts, risks and opportunities has changed compared to prior reporting period',
    name_es:
      'Descripci\u00f3n de c\u00f3mo ha cambiado el proceso para identificar, evaluar y gestionar los impactos, riesgos y oportunidades en comparaci\u00f3n con el per\u00edodo anterior.',
    name_pt:
      'Descri\u00e7\u00e3o de como o processo para identificar, avaliar e gerenciar impactos, riscos e oportunidades mudou em compara\u00e7\u00e3o com o per\u00edodo do relat\u00f3rio anterior'
  },
  {
    id: 'f5ba4fac-4e79-4bec-8806-75b3babd75f1',
    name_dcycle:
      'disclosure_of_list_of_data_points_that_derive_from_other_eu_legislation_and_information_on_their_location_in_sustainability_statement',
    datapoint_id: 'IRO-2_01',
    esg_category: 'general',
    name_en:
      'Disclosure of list of data points that derive from other EU legislation and information on their location in sustainability statement',
    name_es:
      'Divulgaci\u00f3n de una lista de puntos de datos que se derivan de otra legislaci\u00f3n de la UE e informaci\u00f3n sobre su ubicaci\u00f3n en la declaraci\u00f3n de sostenibilidad.',
    name_pt:
      'Divulga\u00e7\u00e3o da lista de pontos de dados que derivam de outra legisla\u00e7\u00e3o da UE e informa\u00e7\u00f5es sobre a sua localiza\u00e7\u00e3o na declara\u00e7\u00e3o de sustentabilidade'
  },
  {
    id: 'eaac4019-5c8f-456f-a09a-6247f35abeb9',
    name_dcycle:
      'disclosure_of_list_of_esrs_disclosure_requirements_complied_with_in_preparing_sustainability_statement_following_outcome_of_materiality_assessment',
    datapoint_id: 'IRO-2_02',
    esg_category: 'general',
    name_en:
      'Disclosure of list of ESRS Disclosure Requirements complied with in preparing sustainability statement following outcome of materiality assessment',
    name_es:
      'Divulgaci\u00f3n de la lista de requisitos de divulgaci\u00f3n de ESRS cumplidos al preparar la declaraci\u00f3n de sostenibilidad tras el resultado de la evaluaci\u00f3n de materialidad',
    name_pt:
      'Divulga\u00e7\u00e3o da lista de Requisitos de Divulga\u00e7\u00e3o ESRS cumpridos na prepara\u00e7\u00e3o da declara\u00e7\u00e3o de sustentabilidade ap\u00f3s o resultado da avalia\u00e7\u00e3o de materialidade'
  },
  {
    id: '50985d2b-191d-4493-aa8f-6f4cb80b3c49',
    name_dcycle: 'explanation_of_negative_materiality_assessment_for_esrs_e1_climate_change',
    datapoint_id: 'IRO-2_03',
    esg_category: 'general',
    name_en: 'Explanation of negative materiality assessment for ESRS E1 Climate change',
    name_es:
      'Explicaci\u00f3n de la evaluaci\u00f3n de materialidad negativa para ESRS E1 Cambio clim\u00e1tico',
    name_pt:
      'Explica\u00e7\u00e3o da avalia\u00e7\u00e3o de materialidade negativa para ESRS E1 Altera\u00e7\u00f5es clim\u00e1ticas'
  },
  {
    id: '949c1106-fc15-4b71-aae0-43ff31272936',
    name_dcycle: 'explanation_of_negative_materiality_assessment_for_esrs_e2_pollution',
    datapoint_id: 'IRO-2_04',
    esg_category: 'general',
    name_en: 'Explanation of negative materiality assessment for ESRS E2 Pollution',
    name_es:
      'Explicaci\u00f3n de la evaluaci\u00f3n de materialidad negativa para ESRS E2 Contaminaci\u00f3n',
    name_pt:
      'Explica\u00e7\u00e3o da avalia\u00e7\u00e3o de materialidade negativa para Polui\u00e7\u00e3o ESRS E2'
  },
  {
    id: '2b024110-fe49-40de-bb49-bb3db3b34811',
    name_dcycle:
      'explanation_of_negative_materiality_assessment_for_esrs_e3_water_and_marine_resources',
    datapoint_id: 'IRO-2_05',
    esg_category: 'general',
    name_en:
      'Explanation of negative materiality assessment for ESRS E3 Water and marine resources',
    name_es:
      'Explicaci\u00f3n de la evaluaci\u00f3n de materialidad negativa para ESRS E3 Recursos h\u00eddricos y marinos',
    name_pt:
      'Explica\u00e7\u00e3o da avalia\u00e7\u00e3o de materialidade negativa para ESRS E3 Recursos h\u00eddricos e marinhos'
  },
  {
    id: 'ac143d63-78fd-4464-88ca-fc8fd91044ec',
    name_dcycle:
      'explanation_of_negative_materiality_assessment_for_esrs_e4_biodiversity_and_ecosystems',
    datapoint_id: 'IRO-2_06',
    esg_category: 'general',
    name_en:
      'Explanation of negative materiality assessment for ESRS E4 Biodiversity and ecosystems',
    name_es:
      'Explicaci\u00f3n de la evaluaci\u00f3n de materialidad negativa para ESRS E4 Biodiversidad y ecosistemas',
    name_pt:
      'Explica\u00e7\u00e3o da avalia\u00e7\u00e3o de materialidade negativa para ESRS E4 Biodiversidade e ecossistemas'
  },
  {
    id: 'e083ce09-d1f3-4f1e-bd69-09077065f90c',
    name_dcycle: 'explanation_of_negative_materiality_assessment_for_esrs_e5_circular_economy',
    datapoint_id: 'IRO-2_07',
    esg_category: 'general',
    name_en: 'Explanation of negative materiality assessment for ESRS E5 Circular economy',
    name_es:
      'Explicaci\u00f3n de la evaluaci\u00f3n de materialidad negativa para ESRS E5 Econom\u00eda circular',
    name_pt:
      'Explica\u00e7\u00e3o da avalia\u00e7\u00e3o de materialidade negativa para ESRS E5 Economia circular'
  },
  {
    id: 'ae174ba9-95a8-4f7a-a519-cc701bde9b24',
    name_dcycle: 'explanation_of_negative_materiality_assessment_for_esrs_s1_own_workforce',
    datapoint_id: 'IRO-2_08',
    esg_category: 'general',
    name_en: 'Explanation of negative materiality assessment for ESRS S1 Own workforce',
    name_es:
      'Explicaci\u00f3n de la evaluaci\u00f3n de materialidad negativa para ESRS S1 Plantilla propia',
    name_pt:
      'Explica\u00e7\u00e3o da avalia\u00e7\u00e3o de materialidade negativa para ESRS S1 For\u00e7a de trabalho pr\u00f3pria'
  },
  {
    id: 'b833c808-dab8-4f75-89c6-a349c0fd557d',
    name_dcycle:
      'explanation_of_negative_materiality_assessment_for_esrs_s2_workers_in_value_chain',
    datapoint_id: 'IRO-2_09',
    esg_category: 'general',
    name_en: 'Explanation of negative materiality assessment for ESRS S2 Workers in value chain',
    name_es:
      'Explicaci\u00f3n de la evaluaci\u00f3n de materialidad negativa para ESRS S2 Trabajadores en la cadena de valor',
    name_pt:
      'Explica\u00e7\u00e3o da avalia\u00e7\u00e3o de materialidade negativa para trabalhadores ESRS S2 na cadeia de valor'
  },
  {
    id: '60e980ab-736a-4ca5-85bd-a9f7a08de8fa',
    name_dcycle: 'explanation_of_negative_materiality_assessment_for_esrs_s3_affected_communities',
    datapoint_id: 'IRO-2_10',
    esg_category: 'general',
    name_en: 'Explanation of negative materiality assessment for ESRS S3 Affected communities',
    name_es:
      'Explicaci\u00f3n de la evaluaci\u00f3n de materialidad negativa para ESRS S3 Comunidades afectadas',
    name_pt:
      'Explica\u00e7\u00e3o da avalia\u00e7\u00e3o de materialidade negativa para comunidades afetadas pelo ESRS S3'
  },
  {
    id: 'b0e2ca3d-49b9-4afa-afaf-c6c0f57f527d',
    name_dcycle:
      'explanation_of_negative_materiality_assessment_for_esrs_s4_consumers_and_end-users',
    datapoint_id: 'IRO-2_11',
    esg_category: 'general',
    name_en: 'Explanation of negative materiality assessment for ESRS S4 Consumers and end-users',
    name_es:
      'Explicaci\u00f3n de la evaluaci\u00f3n de materialidad negativa para ESRS S4 Consumidores y usuarios finales',
    name_pt:
      'Explica\u00e7\u00e3o da avalia\u00e7\u00e3o de materialidade negativa para consumidores e usu\u00e1rios finais do ESRS S4'
  },
  {
    id: '5a850dcb-fffd-4f15-a649-97b7d993500c',
    name_dcycle: 'explanation_of_negative_materiality_assessment_for_esrs_g1_business_conduct',
    datapoint_id: 'IRO-2_12',
    esg_category: 'general',
    name_en: 'Explanation of negative materiality assessment for ESRS G1 Business conduct',
    name_es:
      'Explicaci\u00f3n de la evaluaci\u00f3n de materialidad negativa para ESRS G1 Conducta empresarial',
    name_pt:
      'Explica\u00e7\u00e3o da avalia\u00e7\u00e3o de materialidade negativa para conduta empresarial ESRS G1'
  },
  {
    id: '52ac3a64-de67-4517-8d14-120be2fe65c5',
    name_dcycle:
      'explanation_of_how_material_information_to_be_disclosed_in_relation_to_material_impacts,_risks_and_opportunities_has_been_determined',
    datapoint_id: 'IRO-2_13',
    esg_category: 'general',
    name_en:
      'Explanation of how material information to be disclosed in relation to material impacts, risks and opportunities has been determined',
    name_es:
      'Explicaci\u00f3n de c\u00f3mo se ha determinado la informaci\u00f3n material a revelar en relaci\u00f3n con los impactos, riesgos y oportunidades materiales.',
    name_pt:
      'Explica\u00e7\u00e3o de como as informa\u00e7\u00f5es relevantes a serem divulgadas em rela\u00e7\u00e3o aos impactos, riscos e oportunidades relevantes foram determinadas'
  },
  {
    id: '5bbb7171-b296-451f-8dc2-613e1f968ca6',
    name_dcycle: 'description_of_key_contents_of_policy_',
    datapoint_id: 'MDR-P_01',
    esg_category: 'general',
    name_en: 'Description of key contents of policy ',
    name_es: 'Descripci\u00f3n de los contenidos clave de la pol\u00edtica. ',
    name_pt: 'Descri\u00e7\u00e3o dos principais conte\u00fados da pol\u00edtica '
  },
  {
    id: 'eff9f91b-75e1-414c-ae48-99c8deed9846',
    name_dcycle: 'description_of_scope_of_policy_or_of_its_exclusions_',
    datapoint_id: 'MDR-P_02',
    esg_category: 'general',
    name_en: 'Description of scope of policy or of its exclusions ',
    name_es: 'Descripci\u00f3n del alcance de la p\u00f3liza o de sus exclusiones ',
    name_pt: 'Descri\u00e7\u00e3o do escopo da pol\u00edtica ou de suas exclus\u00f5es '
  },
  {
    id: 'ea1d4b96-e23b-45c2-98f1-18c3fef244f6',
    name_dcycle:
      'description_of_most_senior_level_in_organisation_that_is_accountable_for_implementation_of_policy_',
    datapoint_id: 'MDR-P_03',
    esg_category: 'general',
    name_en:
      'Description of most senior level in organisation that is accountable for implementation of policy ',
    name_es:
      'Descripci\u00f3n del nivel m\u00e1s alto de la organizaci\u00f3n que es responsable de la implementaci\u00f3n de la pol\u00edtica. ',
    name_pt:
      'Descri\u00e7\u00e3o do n\u00edvel mais alto da organiza\u00e7\u00e3o que \u00e9 respons\u00e1vel pela implementa\u00e7\u00e3o da pol\u00edtica '
  },
  {
    id: '9dda1374-33c5-4398-88e4-1f2cf6e158a2',
    name_dcycle:
      'disclosure_of_third-party_standards_or_initiatives_that_are_respected_through_implementation_of_policy_',
    datapoint_id: 'MDR-P_04',
    esg_category: 'general',
    name_en:
      'Disclosure of third-party standards or initiatives that are respected through implementation of policy ',
    name_es:
      'Divulgaci\u00f3n de est\u00e1ndares o iniciativas de terceros que se respetan mediante la implementaci\u00f3n de pol\u00edticas. ',
    name_pt:
      'Divulga\u00e7\u00e3o de padr\u00f5es ou iniciativas de terceiros que s\u00e3o respeitados atrav\u00e9s da implementa\u00e7\u00e3o de pol\u00edticas '
  },
  {
    id: 'a110bcab-f5e9-4b5a-b84b-c351a641051d',
    name_dcycle:
      'description_of_consideration_given_to_interests_of_key_stakeholders_in_setting_policy_',
    datapoint_id: 'MDR-P_05',
    esg_category: 'general',
    name_en:
      'Description of consideration given to interests of key stakeholders in setting policy ',
    name_es:
      'Descripci\u00f3n de la consideraci\u00f3n dada a los intereses de las partes interesadas clave al establecer pol\u00edticas ',
    name_pt:
      'Descri\u00e7\u00e3o da considera\u00e7\u00e3o dada aos interesses das principais partes interessadas na defini\u00e7\u00e3o de pol\u00edticas '
  },
  {
    id: 'f145bbe5-46f7-44f9-a32d-d5bbd17b9842',
    name_dcycle:
      'explanation_of_whether_and_how_policy_is_made_available_to_potentially_affected_stakeholders_and_stakeholders_who_need_to_help_implement_it_',
    datapoint_id: 'MDR-P_06',
    esg_category: 'general',
    name_en:
      'Explanation of whether and how policy is made available to potentially affected stakeholders and stakeholders who need to help implement it ',
    name_es:
      'Explicaci\u00f3n de si la pol\u00edtica se pone a disposici\u00f3n de las partes interesadas potencialmente afectadas y de las partes interesadas que necesitan ayudar a implementarla, y c\u00f3mo hacerlo. ',
    name_pt:
      'Explica\u00e7\u00e3o sobre se e como a pol\u00edtica \u00e9 disponibilizada \u00e0s partes interessadas potencialmente afetadas e \u00e0s partes interessadas que precisam ajudar a implement\u00e1-la '
  },
  {
    id: '06ae53e1-4a69-46b4-89db-2f722cd9f437',
    name_dcycle: 'disclosure_of_key_action_',
    datapoint_id: 'MDR-A_01',
    esg_category: 'general',
    name_en: 'Disclosure of key action ',
    name_es: 'Divulgaci\u00f3n de acciones clave ',
    name_pt: 'Divulga\u00e7\u00e3o da a\u00e7\u00e3o principal '
  },
  {
    id: 'd811ba0a-a64a-4ba3-98cd-80a142b4e396',
    name_dcycle: 'description_of_scope_of_key_action_',
    datapoint_id: 'MDR-A_02',
    esg_category: 'general',
    name_en: 'Description of scope of key action ',
    name_es: 'Descripci\u00f3n del alcance de la acci\u00f3n clave ',
    name_pt: 'Descri\u00e7\u00e3o do escopo da a\u00e7\u00e3o principal '
  },
  {
    id: '8f7d9b75-ebd2-400a-a173-af60127e00d2',
    name_dcycle: 'time_horizon_under_which_key_action_is_to_be_completed',
    datapoint_id: 'MDR-A_03',
    esg_category: 'general',
    name_en: 'Time horizon under which key action is to be completed',
    name_es: 'Horizonte temporal bajo el cual se deben completar las acciones clave',
    name_pt: 'Horizonte temporal sob o qual a ac\u00e7\u00e3o-chave dever\u00e1 ser conclu\u00edda'
  },
  {
    id: 'e8b6afd6-2efb-4626-bb12-d28060d4053f',
    name_dcycle:
      'description_of_key_action_taken,_and_its_results,_to_provide_for_and_cooperate_in_or_support_provision_of_remedy_for_those_harmed_by_actual_material_impacts_',
    datapoint_id: 'MDR-A_04',
    esg_category: 'general',
    name_en:
      'Description of key action taken, and its results, to provide for and cooperate in or support provision of remedy for those harmed by actual material impacts ',
    name_es:
      'Descripci\u00f3n de las acciones clave tomadas, y sus resultados, para prever y cooperar o apoyar la provisi\u00f3n de reparaci\u00f3n para aquellos perjudicados por impactos materiales reales. ',
    name_pt:
      'Descri\u00e7\u00e3o das principais a\u00e7\u00f5es tomadas, e seus resultados, para fornecer e cooperar ou apoiar a presta\u00e7\u00e3o de repara\u00e7\u00e3o para aqueles prejudicados por impactos materiais reais '
  },
  {
    id: 'b5aa9c80-ec6a-449f-bd05-d69abf4e7e21',
    name_dcycle:
      'disclosure_of_quantitative_and_qualitative_information_regarding_progress_of_actions_or_action_plans_disclosed_in_prior_periods_',
    datapoint_id: 'MDR-A_05',
    esg_category: 'general',
    name_en:
      'Disclosure of quantitative and qualitative information regarding progress of actions or action plans disclosed in prior periods ',
    name_es:
      'Divulgaci\u00f3n de informaci\u00f3n cuantitativa y cualitativa sobre avances de acciones o planes de acci\u00f3n divulgados en periodos anteriores ',
    name_pt:
      'Divulga\u00e7\u00e3o de informa\u00e7\u00f5es quantitativas e qualitativas sobre andamento de a\u00e7\u00f5es ou planos de a\u00e7\u00e3o divulgados em per\u00edodos anteriores '
  },
  {
    id: '935d2c4b-3175-47bb-8afb-9489eb3d070b',
    name_dcycle:
      'disclosure_of_the_type_of_current_and_future_financial_and_other_resources_allocated_to_the_action_plan_(capex_and_opex)',
    datapoint_id: 'MDR-A_06',
    esg_category: 'general',
    name_en:
      'Disclosure of the type of current and future financial and other resources allocated to the action plan (Capex and Opex)',
    name_es:
      'Divulgaci\u00f3n del tipo de recursos financieros y de otro tipo, actuales y futuros, asignados al plan de acci\u00f3n (Capex y Opex)',
    name_pt:
      'Divulga\u00e7\u00e3o do tipo de recursos financeiros e outros atuais e futuros alocados ao plano de a\u00e7\u00e3o (Capex e Opex)'
  },
  {
    id: '994eb079-54ba-4c1d-8797-f1de3ed04f8d',
    name_dcycle:
      'explanation_of_how_current_financial_resources_relate_to_most_relevant_amounts_presented_in_financial_statements',
    datapoint_id: 'MDR-A_07',
    esg_category: 'general',
    name_en:
      'Explanation of how current financial resources relate to most relevant amounts presented in financial statements',
    name_es:
      'Explicaci\u00f3n de c\u00f3mo se relacionan los recursos financieros actuales con los montos m\u00e1s relevantes presentados en los estados financieros.',
    name_pt:
      'Explica\u00e7\u00e3o de como os recursos financeiros correntes se relacionam com os valores mais relevantes apresentados nas demonstra\u00e7\u00f5es financeiras'
  },
  {
    id: '3086cc42-d9ad-4ff3-9417-b08844b56ced',
    name_dcycle:
      'current_and_future_financial_resources_allocated_to_action_plan,_breakdown_by_time_horizon_and_resources',
    datapoint_id: 'MDR-A_08',
    esg_category: 'general',
    name_en:
      'Current and future financial resources allocated to action plan, breakdown by time horizon and resources',
    name_es:
      'Recursos financieros actuales y futuros asignados al plan de acci\u00f3n, desglosados \u200b\u200bpor horizonte temporal y recursos',
    name_pt:
      'Recursos financeiros atuais e futuros alocados ao plano de a\u00e7\u00e3o, discriminados por horizonte temporal e recursos'
  },
  {
    id: '05308439-5f37-411f-992e-1c3866c7d62b',
    name_dcycle: 'current_financial_resources_allocated_to_action_plan_(capex)',
    datapoint_id: 'MDR-A_09',
    esg_category: 'general',
    name_en: 'Current financial resources allocated to action plan (Capex)',
    name_es: 'Recursos financieros actuales asignados al plan de acci\u00f3n (Capex)',
    name_pt: 'Recursos financeiros atuais alocados ao plano de a\u00e7\u00e3o (Capex)'
  },
  {
    id: '262a5a01-7a2e-40ee-bde1-40312f780be0',
    name_dcycle: 'current_financial_resources_allocated_to_action_plan_(opex)',
    datapoint_id: 'MDR-A_10',
    esg_category: 'general',
    name_en: 'Current financial resources allocated to action plan (Opex)',
    name_es: 'Recursos financieros actuales asignados al plan de acci\u00f3n (Opex)',
    name_pt: 'Recursos financeiros atuais alocados ao plano de a\u00e7\u00e3o (Opex)'
  },
  {
    id: '0077a710-1838-49d7-98e0-ca2c079b6d73',
    name_dcycle: 'future_financial_resources_allocated_to_action_plan_(capex)',
    datapoint_id: 'MDR-A_11',
    esg_category: 'general',
    name_en: 'Future financial resources allocated to action plan (Capex)',
    name_es: 'Recursos financieros futuros asignados al plan de acci\u00f3n (Capex)',
    name_pt: 'Recursos financeiros futuros alocados ao plano de a\u00e7\u00e3o (Capex)'
  },
  {
    id: '55d26ae3-451a-400a-8160-ac9db3bca45a',
    name_dcycle: 'future_financial_resources_allocated_to_action_plan_(opex)',
    datapoint_id: 'MDR-A_12',
    esg_category: 'general',
    name_en: 'Future financial resources allocated to action plan (Opex)',
    name_es: 'Recursos financieros futuros asignados al plan de acci\u00f3n (Opex)',
    name_pt: 'Futuros recursos financeiros alocados ao plano de a\u00e7\u00e3o (Opex)'
  },
  {
    id: '305080d6-fce3-4e1c-bb62-961ba4fac550',
    name_dcycle:
      'description_of_metric_used_to_evaluate_performance_and_effectiveness,_in_relation_to_material_impact,_risk_or_opportunity_',
    datapoint_id: 'MDR-M_01',
    esg_category: 'general',
    name_en:
      'Description of metric used to evaluate performance and effectiveness, in relation to material impact, risk or opportunity ',
    name_es:
      'Descripci\u00f3n de la m\u00e9trica utilizada para evaluar el desempe\u00f1o y la eficacia, en relaci\u00f3n con el impacto, riesgo u oportunidad material ',
    name_pt:
      'Descri\u00e7\u00e3o da m\u00e9trica usada para avaliar o desempenho e a efic\u00e1cia, em rela\u00e7\u00e3o ao impacto, risco ou oportunidade material '
  },
  {
    id: 'db69239b-4aee-4025-b7bb-7ffa5a61907f',
    name_dcycle: 'disclosure_of_methodologies_and_significant_assumptions_behind_metric_',
    datapoint_id: 'MDR-M_02',
    esg_category: 'general',
    name_en: 'Disclosure of methodologies and significant assumptions behind metric ',
    name_es:
      'Divulgaci\u00f3n de metodolog\u00edas y supuestos importantes detr\u00e1s de las m\u00e9tricas. ',
    name_pt:
      'Divulga\u00e7\u00e3o de metodologias e premissas significativas por tr\u00e1s da m\u00e9trica '
  },
  {
    id: '27f80b6b-715a-45f4-b2cd-9d219f1337e9',
    name_dcycle: 'type_of_external_body_other_than_assurance_provider_that_provides_validation_',
    datapoint_id: 'MDR-M_03',
    esg_category: 'general',
    name_en: 'Type of external body other than assurance provider that provides validation ',
    name_es:
      'Tipo de organismo externo distinto del proveedor de aseguramiento que proporciona validaci\u00f3n ',
    name_pt:
      'Tipo de \u00f3rg\u00e3o externo, diferente do provedor de garantia, que fornece valida\u00e7\u00e3o '
  },
  {
    id: 'd3fadff0-5aa6-464b-945c-71707183338e',
    name_dcycle: 'relationship_with_policy_objectives',
    datapoint_id: 'MDR-T_01',
    esg_category: 'general',
    name_en: 'Relationship with policy objectives',
    name_es: 'Relaci\u00f3n con los objetivos de pol\u00edtica',
    name_pt: 'Rela\u00e7\u00e3o com objetivos pol\u00edticos'
  },
  {
    id: '14e7c8db-c451-43fc-86c9-eb4af920a3a1',
    name_dcycle: 'measurable_target',
    datapoint_id: 'MDR-T_02',
    esg_category: 'general',
    name_en: 'Measurable target',
    name_es: 'Objetivo mensurable',
    name_pt: 'Meta mensur\u00e1vel'
  },
  {
    id: '9854a1ba-a950-4496-a613-cf0c08ce55b4',
    name_dcycle: 'nature_of_target',
    datapoint_id: 'MDR-T_03',
    esg_category: 'general',
    name_en: 'Nature of target',
    name_es: 'Naturaleza del objetivo',
    name_pt: 'Natureza do alvo'
  },
  {
    id: 'f44847cd-e00d-447e-a89f-674613305435',
    name_dcycle: 'description_of_scope_of_target_',
    datapoint_id: 'MDR-T_04',
    esg_category: 'general',
    name_en: 'Description of scope of target ',
    name_es: 'Descripci\u00f3n del alcance del objetivo ',
    name_pt: 'Descri\u00e7\u00e3o do escopo da meta '
  },
  {
    id: '6698daf1-b9c5-4980-b4fc-281892fc7ff9',
    name_dcycle: 'baseline_value_',
    datapoint_id: 'MDR-T_05',
    esg_category: 'general',
    name_en: 'Baseline value ',
    name_es: 'Valor de referencia ',
    name_pt: 'Valor da linha de base '
  },
  {
    id: 'de766b0c-c2f3-4f13-b452-61af990ff405',
    name_dcycle: 'baseline_year',
    datapoint_id: 'MDR-T_06',
    esg_category: 'general',
    name_en: 'Baseline year',
    name_es: 'A\u00f1o de referencia',
    name_pt: 'Ano base'
  },
  {
    id: '1c2b27c9-4979-4b4d-88b5-f7c460e1d1cf',
    name_dcycle: 'period_to_which_target_applies',
    datapoint_id: 'MDR-T_07',
    esg_category: 'general',
    name_en: 'Period to which target applies',
    name_es: 'Per\u00edodo al que se aplica el objetivo',
    name_pt: 'Per\u00edodo ao qual a meta se aplica'
  },
  {
    id: '67759138-8d85-4a68-9be7-c2464419f46d',
    name_dcycle: 'indication_of_milestones_or_interim_targets_',
    datapoint_id: 'MDR-T_08',
    esg_category: 'general',
    name_en: 'Indication of milestones or interim targets ',
    name_es: 'Indicaci\u00f3n de hitos u objetivos intermedios ',
    name_pt: 'Indica\u00e7\u00e3o de marcos ou metas intermedi\u00e1rias '
  },
  {
    id: '2940a615-1886-47e5-8c07-a202130414cb',
    name_dcycle: 'description_of_methodologies_and_significant_assumptions_used_to_define_target_',
    datapoint_id: 'MDR-T_09',
    esg_category: 'general',
    name_en: 'Description of methodologies and significant assumptions used to define target ',
    name_es:
      'Descripci\u00f3n de metodolog\u00edas y supuestos significativos utilizados para definir el objetivo. ',
    name_pt:
      'Descri\u00e7\u00e3o das metodologias e premissas significativas utilizadas para definir a meta '
  },
  {
    id: 'ef26ddf9-c374-4bb5-b353-b13f0a7fabf9',
    name_dcycle:
      'target_related_to_environmental_matters_is_based_on_conclusive_scientific_evidence',
    datapoint_id: 'MDR-T_10',
    esg_category: 'general',
    name_en: 'Target related to environmental matters is based on conclusive scientific evidence',
    name_es:
      'El objetivo relacionado con cuestiones ambientales se basa en evidencia cient\u00edfica concluyente.',
    name_pt:
      'Meta relacionada a quest\u00f5es ambientais \u00e9 baseada em evid\u00eancias cient\u00edficas conclusivas'
  },
  {
    id: '776699c8-c7d1-4318-a95b-f9b471b45ecb',
    name_dcycle: 'disclosure_of_whether_and_how_stakeholders_have_been_involved_in_target_setting_',
    datapoint_id: 'MDR-T_11',
    esg_category: 'general',
    name_en: 'Disclosure of whether and how stakeholders have been involved in target setting ',
    name_es:
      'Divulgaci\u00f3n de si las partes interesadas han participado en el establecimiento de objetivos y c\u00f3mo lo han hecho. ',
    name_pt:
      'Divulga\u00e7\u00e3o sobre se e como as partes interessadas estiveram envolvidas na defini\u00e7\u00e3o de metas '
  },
  {
    id: '3e7668f0-b592-4e63-a17a-c1698216e036',
    name_dcycle:
      'description_of_any_changes_in_target_and_corresponding_metrics_or_underlying_measurement_methodologies,_significant_assumptions,_limitations,_sources_and_adopted_processes_to_collect_data_',
    datapoint_id: 'MDR-T_12',
    esg_category: 'general',
    name_en:
      'Description of any changes in target and corresponding metrics or underlying measurement methodologies, significant assumptions, limitations, sources and adopted processes to collect data ',
    name_es:
      'Descripci\u00f3n de cualquier cambio en el objetivo y las m\u00e9tricas correspondientes o metodolog\u00edas de medici\u00f3n subyacentes, supuestos importantes, limitaciones, fuentes y procesos adoptados para recopilar datos. ',
    name_pt:
      'Descri\u00e7\u00e3o de quaisquer altera\u00e7\u00f5es nas metas e m\u00e9tricas correspondentes ou metodologias de medi\u00e7\u00e3o subjacentes, suposi\u00e7\u00f5es significativas, limita\u00e7\u00f5es, fontes e processos adotados para coletar dados '
  },
  {
    id: '29141347-508c-430c-b07b-a89c1f628bed',
    name_dcycle: 'description_of_performance_against_disclosed_target_',
    datapoint_id: 'MDR-T_13',
    esg_category: 'general',
    name_en: 'Description of performance against disclosed target ',
    name_es: 'Descripci\u00f3n del desempe\u00f1o frente al objetivo divulgado ',
    name_pt: 'Descri\u00e7\u00e3o do desempenho em rela\u00e7\u00e3o \u00e0 meta divulgada '
  },
  {
    id: '97fac30f-8168-40a7-88e7-169da8ed21aa',
    name_dcycle: 'disclosure_of_reasons_for_not_having_adopted_policies',
    datapoint_id: 'MDR-P_07',
    esg_category: 'general',
    name_en: 'Disclosure of reasons for not having adopted policies',
    name_es: 'Divulgaci\u00f3n de motivos por los que no se han adoptado pol\u00edticas',
    name_pt: 'Divulga\u00e7\u00e3o dos motivos da n\u00e3o ado\u00e7\u00e3o de pol\u00edticas'
  },
  {
    id: 'fbddb275-23df-4ef2-a7c6-98c58f45f113',
    name_dcycle: 'disclosure_of_timeframe_in_which_the_undertakings_aims_to_adopt_policies',
    datapoint_id: 'MDR-P_08',
    esg_category: 'general',
    name_en: 'Disclosure of timeframe in which the undertakings aims to adopt policies',
    name_es: 'Divulgaci\u00f3n del calendario en el que la empresa pretende adoptar pol\u00edticas',
    name_pt:
      'Divulga\u00e7\u00e3o do prazo em que os empreendimentos pretendem adotar pol\u00edticas'
  },
  {
    id: '5654254e-2a44-4da0-8691-1828d76acdfc',
    name_dcycle: 'disclosure_of_reasons_for_not_having_adopted_actions',
    datapoint_id: 'MDR-A_13',
    esg_category: 'general',
    name_en: 'Disclosure of reasons for not having adopted actions',
    name_es: 'Divulgaci\u00f3n de motivos por los que no se han adoptado acciones',
    name_pt: 'Divulga\u00e7\u00e3o dos motivos da n\u00e3o ado\u00e7\u00e3o de a\u00e7\u00f5es'
  },
  {
    id: 'e7d69aaf-7f83-4a79-b939-57bc7d92db94',
    name_dcycle: 'disclosure_of_timeframe_in_which_the_undertakings_aims_to_adopt_actions',
    datapoint_id: 'MDR-A_14',
    esg_category: 'general',
    name_en: 'Disclosure of timeframe in which the undertakings aims to adopt actions',
    name_es: 'Divulgaci\u00f3n del plazo en el que la empresa pretende adoptar acciones',
    name_pt:
      'Divulga\u00e7\u00e3o do prazo em que os empreendimentos pretendem adotar a\u00e7\u00f5es'
  },
  {
    id: 'eb59fd84-9842-441a-b3d8-0eacf9d3b3d7',
    name_dcycle: 'disclosure_of_timeframe_for_setting_of_measurable_outcome-oriented_targets_',
    datapoint_id: 'MDR-T_14',
    esg_category: 'general',
    name_en: 'Disclosure of timeframe for setting of measurable outcome-oriented targets ',
    name_es:
      'Divulgaci\u00f3n del calendario para el establecimiento de objetivos mensurables orientados a resultados ',
    name_pt:
      'Divulga\u00e7\u00e3o do prazo para defini\u00e7\u00e3o de metas mensur\u00e1veis \u200b\u200borientadas para resultados '
  },
  {
    id: '68aa69a5-06db-481b-a98b-96a1599a0b08',
    name_dcycle:
      'description_of_reasons_why_there_are_no_plans_to_set_measurable_outcome-oriented_targets_',
    datapoint_id: 'MDR-T_15',
    esg_category: 'general',
    name_en:
      'Description of reasons why there are no plans to set measurable outcome-oriented targets ',
    name_es:
      'Descripci\u00f3n de las razones por las que no existen planes para establecer objetivos mensurables orientados a resultados ',
    name_pt:
      'Descri\u00e7\u00e3o das raz\u00f5es pelas quais n\u00e3o existem planos para definir metas mensur\u00e1veis \u200b\u200borientadas para resultados '
  },
  {
    id: '5556b693-6bad-4320-ae5a-235cdc9a3d3b',
    name_dcycle:
      'effectiveness_of_policies_and_actions_is_tracked_in_relation_to_material_sustainability-related_impact,_risk_and_opportunity',
    datapoint_id: 'MDR-T_16',
    esg_category: 'general',
    name_en:
      'Effectiveness of policies and actions is tracked in relation to material sustainability-related impact, risk and opportunity',
    name_es:
      'Se realiza un seguimiento de la eficacia de las pol\u00edticas y acciones en relaci\u00f3n con el impacto, el riesgo y las oportunidades materiales relacionados con la sostenibilidad.',
    name_pt:
      'A efic\u00e1cia das pol\u00edticas e a\u00e7\u00f5es \u00e9 monitorada em rela\u00e7\u00e3o ao impacto, risco e oportunidade materiais relacionados \u00e0 sustentabilidade'
  },
  {
    id: 'e0ff53b8-e352-4cbe-a9bd-ffcf463d36f1',
    name_dcycle:
      'description_of_processes_through_which_effectiveness_of_policies_and_actions_is_tracked_in_relation_to_material_sustainability-related_impact,_risk_and_opportunity_',
    datapoint_id: 'MDR-T_17',
    esg_category: 'general',
    name_en:
      'Description of processes through which effectiveness of policies and actions is tracked in relation to material sustainability-related impact, risk and opportunity ',
    name_es:
      'Descripci\u00f3n de los procesos a trav\u00e9s de los cuales se realiza un seguimiento de la eficacia de las pol\u00edticas y acciones en relaci\u00f3n con el impacto, el riesgo y la oportunidad materiales relacionados con la sostenibilidad. ',
    name_pt:
      'Descri\u00e7\u00e3o dos processos atrav\u00e9s dos quais a efic\u00e1cia das pol\u00edticas e a\u00e7\u00f5es \u00e9 monitorada em rela\u00e7\u00e3o ao impacto, risco e oportunidade material relacionado \u00e0 sustentabilidade '
  },
  {
    id: 'eac088df-3e7d-441a-821f-3fc83be0e105',
    name_dcycle:
      'description_of_defined_level_of_ambition_to_be_achieved_and_of_any_qualitative_or_quantitative_indicators_used_to_evaluate_progress_',
    datapoint_id: 'MDR-T_18',
    esg_category: 'general',
    name_en:
      'Description of defined level of ambition to be achieved and of any qualitative or quantitative indicators used to evaluate progress ',
    name_es:
      'Descripci\u00f3n del nivel definido de ambici\u00f3n que se debe alcanzar y de cualquier indicador cualitativo o cuantitativo utilizado para evaluar el progreso. ',
    name_pt:
      'Descri\u00e7\u00e3o do n\u00edvel definido de ambi\u00e7\u00e3o a ser alcan\u00e7ado e de quaisquer indicadores qualitativos ou quantitativos utilizados para avaliar o progresso '
  },
  {
    id: '6699e38d-181b-44f9-a3aa-6ebcc0f8e3c3',
    name_dcycle: 'base_year_from_which_progress_is_measured',
    datapoint_id: 'MDR-T_19',
    esg_category: 'general',
    name_en: 'Base year from which progress is measured',
    name_es: 'A\u00f1o base a partir del cual se mide el progreso',
    name_pt: 'Ano base a partir do qual o progresso \u00e9 medido'
  }
];

export const environmentalDatapoints = [
  {
    id: '5be1182b-e589-4709-8abc-eef5da130bb6',
    name_dcycle:
      'disclosure_of_whether_and_how_climate-related_considerations_are_factored_into_remuneration_of_members_of_administrative,_management_and_supervisory_bodies',
    datapoint_id: 'E1.GOV-3_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how climate-related considerations are factored into remuneration of members of administrative, management and supervisory bodies',
    name_es:
      'Divulgación de si y cómo se tienen en cuenta consideraciones relacionadas con el clima en la remuneración de los miembros de los órganos de administración, gestión y supervisión.',
    name_pt:
      'Divulgação sobre se e como as considerações relacionadas com o clima são tidas em conta na remuneração dos membros dos órgãos de administração, gestão e supervisão'
  },
  {
    id: '647808a3-d3dc-4b92-929d-6e14acbc94c0',
    name_dcycle:
      'percentage_of_remuneration_recognised_that_is_linked_to_climate_related_considerations',
    datapoint_id: 'E1.GOV-3_02',
    esg_category: 'environmental',
    name_en:
      'Percentage of remuneration recognised that is linked to climate related considerations',
    name_es:
      'Porcentaje de remuneración reconocida que está vinculada a consideraciones relacionadas con el clima',
    name_pt:
      'Percentagem de remuneração reconhecida que está ligada a considerações relacionadas com o clima'
  },
  {
    id: 'd8ad9c8d-d55e-4017-899d-8e235e1ed9c9',
    name_dcycle:
      'explanation_of_climate-related_considerations_that_are_factored_into_remuneration_of_members_of_administrative,_management_and_supervisory_bodies',
    datapoint_id: 'E1.GOV-3_03',
    esg_category: 'environmental',
    name_en:
      'Explanation of climate-related considerations that are factored into remuneration of members of administrative, management and supervisory bodies',
    name_es:
      'Explicación de las consideraciones relacionadas con el clima que se tienen en cuenta en la remuneración de los miembros de los órganos de administración, dirección y supervisión.',
    name_pt:
      'Explicação das considerações relacionadas com o clima que são tidas em conta na remuneração dos membros dos órgãos de administração, gestão e supervisão'
  },
  {
    id: 'dd9a21fe-8c43-4306-83a4-e36295b0f8bb',
    name_dcycle: 'disclosure_of_transition_plan_for_climate_change_mitigation',
    datapoint_id: 'E1-1_01',
    esg_category: 'environmental',
    name_en: 'Disclosure of transition plan for climate change mitigation',
    name_es: 'Divulgación del plan de transición para la mitigación del cambio climático',
    name_pt: 'Divulgação do plano de transição para mitigação das alterações climáticas'
  },
  {
    id: '167eaf79-d9d3-4d32-8657-d77f69e6810a',
    name_dcycle:
      'explanation_of_how_targets_are_compatible_with_limiting_of_global_warming_to_one_and_half_degrees_celsius_in_line_with_paris_agreement',
    datapoint_id: 'E1-1_02',
    esg_category: 'environmental',
    name_en:
      'Explanation of how targets are compatible with limiting of global warming to one and half degrees Celsius in line with Paris Agreement',
    name_es:
      'Explicación de cómo los objetivos son compatibles con la limitación del calentamiento global a un grado y medio Celsius de acuerdo con el Acuerdo de París',
    name_pt:
      'Explicação de como as metas são compatíveis com a limitação do aquecimento global a um grau e meio Celsius, em linha com o Acordo de Paris'
  },
  {
    id: '29e0c9c0-439a-40d9-ac23-1f38add307f5',
    name_dcycle: 'disclosure_of_decarbonisation_levers_and_key_action',
    datapoint_id: 'E1-1_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of decarbonisation levers and key action',
    name_es: 'Divulgación de palancas de descarbonización y acciones clave',
    name_pt: 'Divulgação de alavancas de descarbonização e ações-chave'
  },
  {
    id: '2bc8455e-3033-43a2-9299-e21211063f39',
    name_dcycle:
      'disclosure_of_significant_operational_expenditures_(opex)_and_(or)_capital_expenditures_(capex)_required_for_implementation_of_action_plan',
    datapoint_id: 'E1-1_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of significant operational expenditures (Opex) and (or) capital expenditures (Capex) required for implementation of action plan',
    name_es:
      'Divulgación de gastos operativos significativos (Opex) y (o) gastos de capital (Capex) necesarios para la implementación del plan de acción.',
    name_pt:
      'Divulgação de despesas operacionais significativas (Opex) e (ou) despesas de capital (Capex) necessárias para implementação do plano de ação'
  },
  {
    id: 'f69ba850-f283-4029-aeda-0abdcd3a6746',
    name_dcycle: 'financial_resources_allocated_to_action_plan_(opex)',
    datapoint_id: 'E1-1_05',
    esg_category: 'environmental',
    name_en: 'Financial resources allocated to action plan (OpEx)',
    name_es: 'Recursos financieros asignados al plan de acción (OpEx)',
    name_pt: 'Recursos financeiros alocados ao plano de ação (OpEx)'
  },
  {
    id: 'cbd16172-c0ec-4b09-9dea-aac0ceb4391f',
    name_dcycle: 'financial_resources_allocated_to_action_plan_(capex)',
    datapoint_id: 'E1-1_06',
    esg_category: 'environmental',
    name_en: 'Financial resources allocated to action plan (CapEx)',
    name_es: 'Recursos financieros asignados al plan de acción (CapEx)',
    name_pt: 'Recursos financeiros destinados ao plano de ação (CapEx)'
  },
  {
    id: '28dafa03-da76-49f1-97aa-c2542b98f290',
    name_dcycle:
      'explanation_of_potential_locked-in_ghg_emissions_from_key_assets_and_products_and_of_how_locked-in_ghg_emissions_may_jeopardise_achievement_of_ghg_emission_reduction_targets_and_drive_transition_risk',
    datapoint_id: 'E1-1_07',
    esg_category: 'environmental',
    name_en:
      'Explanation of potential locked-in GHG emissions from key assets and products and of how locked-in GHG emissions may jeopardise achievement of GHG emission reduction targets and drive transition risk',
    name_es:
      'Explicación de las posibles emisiones bloqueadas de GEI de activos y productos clave y de cómo las emisiones bloqueadas de GEI pueden poner en peligro el logro de los objetivos de reducción de emisiones de GEI e impulsar el riesgo de transición.',
    name_pt:
      'Explicação das potenciais emissões de GEE bloqueadas de ativos e produtos essenciais e de como as emissões de GEE bloqueadas podem comprometer o cumprimento das metas de redução de emissões de GEE e gerar riscos de transição'
  },
  {
    id: 'c6277a07-e6c4-47ca-b6fb-39f79abdbd92',
    name_dcycle:
      'explanation_of_any_objective_or_plans_(capex,_capex_plans,_opex)_for_aligning_economic_activities_(revenues,_capex,_opex)_with_criteria_established_in_commission_delegated_regulation_2021/2139',
    datapoint_id: 'E1-1_08',
    esg_category: 'environmental',
    name_en:
      'Explanation of any objective or plans (CapEx, CapEx plans, OpEx) for aligning economic activities (revenues, CapEx, OpEx) with criteria established in Commission Delegated Regulation 2021/2139',
    name_es:
      'Explicación de cualquier objetivo o plan (CapEx, planes CapEx, OpEx) para alinear las actividades económicas (ingresos, CapEx, OpEx) con los criterios establecidos en el Reglamento Delegado 2021/2139 de la Comisión.',
    name_pt:
      'Explicação de quaisquer objetivos ou planos (CapEx, planos CapEx, OpEx) para alinhar as atividades económicas (receitas, CapEx, OpEx) com os critérios estabelecidos no Regulamento Delegado 2021/2139 da Comissão'
  },
  {
    id: '99069c25-c6ac-4e8e-82b2-1e43441ec441',
    name_dcycle: 'significant_capex_for_coal-related_economic_activities',
    datapoint_id: 'E1-1_09',
    esg_category: 'environmental',
    name_en: 'Significant CapEx for coal-related economic activities',
    name_es: 'CapEx significativo para actividades económicas relacionadas con el carbón',
    name_pt: 'CapEx significativo para atividades económicas relacionadas com o carvão'
  },
  {
    id: '6d786c09-58de-4893-a502-deb042bda70a',
    name_dcycle: 'significant_capex_for_oil-related_economic_activities',
    datapoint_id: 'E1-1_10',
    esg_category: 'environmental',
    name_en: 'Significant CapEx for oil-related economic activities',
    name_es: 'CapEx significativo para actividades económicas relacionadas con el petróleo',
    name_pt: 'CapEx significativo para atividades económicas relacionadas com o petróleo'
  },
  {
    id: 'e619ae7c-8e5f-4467-accd-e602ad1ac875',
    name_dcycle: 'significant_capex_for_gas-related_economic_activities',
    datapoint_id: 'E1-1_11',
    esg_category: 'environmental',
    name_en: 'Significant CapEx for gas-related economic activities',
    name_es: 'CapEx significativo para actividades económicas relacionadas con el gas',
    name_pt: 'CapEx significativo para atividades económicas relacionadas com o gás'
  },
  {
    id: '3dcc788c-b0a4-4cec-ab5f-aead9a16031f',
    name_dcycle: 'undertaking_is_excluded_from_eu_paris-aligned_benchmarks',
    datapoint_id: 'E1-1_12',
    esg_category: 'environmental',
    name_en: 'Undertaking is excluded from EU Paris-aligned Benchmarks',
    name_es:
      'La empresa está excluida de los índices de referencia alineados con el Acuerdo de París de la UE',
    name_pt: 'Empresa é excluída dos benchmarks alinhados com Paris da UE'
  },
  {
    id: '716b45c4-9cb2-4a2f-a590-6dad0989c5ea',
    name_dcycle:
      'explanation_of_how_transition_plan_is_embedded_in_and_aligned_with_overall_business_strategy_and_financial_planning',
    datapoint_id: 'E1-1_13',
    esg_category: 'environmental',
    name_en:
      'Explanation of how transition plan is embedded in and aligned with overall business strategy and financial planning',
    name_es:
      'Explicación de cómo el plan de transición está integrado y alineado con la estrategia comercial general y la planificación financiera.',
    name_pt:
      'Explicação de como o plano de transição está incorporado e alinhado com a estratégia geral de negócios e o planejamento financeiro'
  },
  {
    id: '2e010dd9-f3bf-4a46-86b3-ee827f7845ed',
    name_dcycle: 'transition_plan_is_approved_by_administrative,_management_and_supervisory_bodies',
    datapoint_id: 'E1-1_14',
    esg_category: 'environmental',
    name_en: 'Transition plan is approved by administrative, management and supervisory bodies',
    name_es:
      'El plan de transición es aprobado por los órganos de administración, dirección y supervisión.',
    name_pt: 'Plano de transição é aprovado pelos órgãos de administração, gestão e fiscalização'
  },
  {
    id: '84ac6969-477c-45d3-ac0b-a52d61441f97',
    name_dcycle: 'explanation_of_progress_in_implementing_transition_plan',
    datapoint_id: 'E1-1_15',
    esg_category: 'environmental',
    name_en: 'Explanation of progress in implementing transition plan',
    name_es: 'Explicación del progreso en la implementación del plan de transición.',
    name_pt: 'Explicação do progresso na implementação do plano de transição'
  },
  {
    id: '5064aa42-ec9c-41f1-ac02-3bffec3cf797',
    name_dcycle:
      'date_of_adoption_of_transition_plan_for_undertakings_not_having_adopted_transition_plan_yet',
    datapoint_id: 'E1-1_16',
    esg_category: 'environmental',
    name_en:
      'Date of adoption of transition plan for undertakings not having adopted transition plan yet',
    name_es:
      'Fecha de adopción del plan de transición para las empresas que aún no lo han adoptado',
    name_pt:
      'Data de adoção do plano de transição para empresas que ainda não adotaram o plano de transição'
  },
  {
    id: 'fb7a76cb-aa15-4974-ad6d-bd5331841f87',
    name_dcycle: 'type_of_climate-related_risk',
    datapoint_id: 'E1.SBM-3_01',
    esg_category: 'environmental',
    name_en: 'Type of climate-related risk',
    name_es: 'Tipo de riesgo relacionado con el clima',
    name_pt: 'Tipo de risco relacionado ao clima'
  },
  {
    id: 'de015551-d0c5-4633-82f9-4448f57d7c1c',
    name_dcycle: 'description_of_scope_of_resilience_analysis',
    datapoint_id: 'E1.SBM-3_02',
    esg_category: 'environmental',
    name_en: 'Description of scope of resilience analysis',
    name_es: 'Descripción del alcance del análisis de resiliencia',
    name_pt: 'Descrição do escopo da análise de resiliência'
  },
  {
    id: 'ed1d3e58-f475-46da-8198-c6b1d7ceca8c',
    name_dcycle: 'disclosure_of_how_resilience_analysis_has_been_conducted',
    datapoint_id: 'E1.SBM-3_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of how resilience analysis has been conducted',
    name_es: 'Divulgación de cómo se ha realizado el análisis de resiliencia.',
    name_pt: 'Divulgação de como a análise de resiliência tem sido conduzida'
  },
  {
    id: '23a309a8-c038-4a87-88c0-2b635d96f580',
    name_dcycle: 'disclosure_of_how_resilience_analysis_has_been_conducted',
    datapoint_id: 'E1.SBM-3_04',
    esg_category: 'environmental',
    name_en: 'Disclosure of how resilience analysis has been conducted',
    name_es: 'Divulgación de cómo se ha realizado el análisis de resiliencia.',
    name_pt: 'Divulgação de como a análise de resiliência tem sido conduzida'
  },
  {
    id: '58f58180-b15f-418a-ae78-4342a68d761a',
    name_dcycle: 'time_horizons_applied_for_resilience_analysis',
    datapoint_id: 'E1.SBM-3_05',
    esg_category: 'environmental',
    name_en: 'Time horizons applied for resilience analysis',
    name_es: 'Horizontes temporales aplicados para el análisis de resiliencia',
    name_pt: 'Horizontes temporais aplicados para análise de resiliência'
  },
  {
    id: '0e59ceaf-213a-4733-87ff-f117827e3333',
    name_dcycle: 'description_of_results_of_resilience_analysis',
    datapoint_id: 'E1.SBM-3_06',
    esg_category: 'environmental',
    name_en: 'Description of results of resilience analysis',
    name_es: 'Descripción de los resultados del análisis de resiliencia',
    name_pt: 'Descrição dos resultados da análise de resiliência'
  },
  {
    id: 'cda7a6aa-42d7-42db-a8c3-befa40dfef13',
    name_dcycle:
      'description_of_ability_to_adjust_or_adapt_strategy_and_business_model_to_climate_change',
    datapoint_id: 'E1.SBM-3_07',
    esg_category: 'environmental',
    name_en:
      'Description of ability to adjust or adapt strategy and business model to climate change',
    name_es:
      'Descripción de la capacidad para ajustar o adaptar la estrategia y el modelo de negocio al cambio climático',
    name_pt:
      'Descrição da capacidade de ajustar ou adaptar a estratégia e o modelo de negócio às alterações climáticas'
  },
  {
    id: '3fa52266-80d2-43a6-a9c8-c9e186c7552b',
    name_dcycle: 'description_of_process_in_relation_to_impacts_on_climate_change',
    datapoint_id: 'E1.IRO-1_01',
    esg_category: 'environmental',
    name_en: 'Description of process in relation to impacts on climate change',
    name_es: 'Descripción del proceso en relación a los impactos sobre el cambio climático',
    name_pt: 'Descrição do processo em relação aos impactos nas mudanças climáticas'
  },
  {
    id: 'a90aecb5-ad99-4337-9137-ecd95593d459',
    name_dcycle:
      'description_of_process_in_relation_to_climate-related_physical_risks_in_own_operations_and_along_value_chain',
    datapoint_id: 'E1.IRO-1_02',
    esg_category: 'environmental',
    name_en:
      'Description of process in relation to climate-related physical risks in own operations and along value chain',
    name_es:
      'Descripción del proceso en relación con los riesgos físicos relacionados con el clima en las operaciones propias y a lo largo de la cadena de valor.',
    name_pt:
      'Descrição do processo em relação aos riscos físicos relacionados ao clima nas próprias operações e ao longo da cadeia de valor'
  },
  {
    id: '2581e155-9b69-4da4-a0a8-0a63735e0e2a',
    name_dcycle:
      'climate-related_hazards_have_been_identified_over_short-,_medium-_and_long-term_time_horizons',
    datapoint_id: 'E1.IRO-1_03',
    esg_category: 'environmental',
    name_en:
      'Climate-related hazards have been identified over short-, medium- and long-term time horizons',
    name_es:
      'Se han identificado peligros relacionados con el clima en horizontes temporales de corto, mediano y largo plazo.',
    name_pt:
      'Os perigos relacionados com o clima foram identificados em horizontes de curto, médio e longo prazo'
  },
  {
    id: '5a284855-ece8-46d1-9409-7e0fff76df86',
    name_dcycle:
      'undertaking_has_screened_whether_assets_and_business_activities_may_be_exposed_to_climate-related_hazards',
    datapoint_id: 'E1.IRO-1_04',
    esg_category: 'environmental',
    name_en:
      'Undertaking has screened whether assets and business activities may be exposed to climate-related hazards',
    name_es:
      'La empresa ha examinado si los activos y las actividades comerciales pueden estar expuestos a peligros relacionados con el clima.',
    name_pt:
      'A empresa examinou se os ativos e as atividades empresariais podem estar expostos a riscos relacionados com o clima'
  },
  {
    id: '05cf5828-3b2a-4b39-bc09-91bc12abd1cb',
    name_dcycle: 'short-,_medium-_and_long-term_time_horizons_have_been_defined',
    datapoint_id: 'E1.IRO-1_05',
    esg_category: 'environmental',
    name_en: 'Short-, medium- and long-term time horizons have been defined',
    name_es: 'Se han definido horizontes temporales a corto, medio y largo plazo.',
    name_pt: 'Foram definidos horizontes temporais de curto, médio e longo prazo'
  },
  {
    id: '4d0d6418-8dbe-4592-8715-c174de58b056',
    name_dcycle:
      'extent_to_which_assets_and_business_activities_may_be_exposed_and_are_sensitive_to_identified_climate-related_hazards_has_been_assessed',
    datapoint_id: 'E1.IRO-1_06',
    esg_category: 'environmental',
    name_en:
      'Extent to which assets and business activities may be exposed and are sensitive to identified climate-related hazards has been assessed',
    name_es:
      'Se ha evaluado el grado en que los activos y las actividades comerciales pueden estar expuestos y son sensibles a los peligros relacionados con el clima identificados.',
    name_pt:
      'Foi avaliada a extensão em que os ativos e as atividades empresariais podem estar expostos e são sensíveis aos perigos identificados relacionados com o clima.'
  },
  {
    id: '38964af8-1798-4e90-bb5e-a88c8e11f3c1',
    name_dcycle:
      'identification_of_climate-related_hazards_and_assessment_of_exposure_and_sensitivity_are_informed_by_high_emissions_climate_scenarios',
    datapoint_id: 'E1.IRO-1_07',
    esg_category: 'environmental',
    name_en:
      'Identification of climate-related hazards and assessment of exposure and sensitivity are informed by high emissions climate scenarios',
    name_es:
      'La identificación de peligros relacionados con el clima y la evaluación de la exposición y la sensibilidad se basan en escenarios climáticos de altas emisiones.',
    name_pt:
      'A identificação de perigos relacionados com o clima e a avaliação da exposição e sensibilidade são informadas por cenários climáticos de emissões elevadas'
  },
  {
    id: 'ec4c8dac-f496-450b-9196-eba6839e6d76',
    name_dcycle:
      'explanation_of_how_climate-related_scenario_analysis_has_been_used_to_inform_identification_and_assessment_of_physical_risks_over_short,_medium_and_long-term',
    datapoint_id: 'E1.IRO-1_08',
    esg_category: 'environmental',
    name_en:
      'Explanation of how climate-related scenario analysis has been used to inform identification and assessment of physical risks over short, medium and long-term',
    name_es:
      'Explicación de cómo se ha utilizado el análisis de escenarios relacionados con el clima para informar la identificación y evaluación de riesgos físicos a corto, mediano y largo plazo.',
    name_pt:
      'Explicação de como a análise de cenários relacionados ao clima tem sido usada para informar a identificação e avaliação de riscos físicos no curto, médio e longo prazo'
  },
  {
    id: 'f848012c-05df-4355-9e92-f91ecc21347f',
    name_dcycle:
      'description_of_process_in_relation_to_climate-related_transition_risks_and_opportunities_in_own_operations_and_along_value_chain',
    datapoint_id: 'E1.IRO-1_09',
    esg_category: 'environmental',
    name_en:
      'Description of process in relation to climate-related transition risks and opportunities in own operations and along value chain',
    name_es:
      'Descripción del proceso en relación con los riesgos y oportunidades de transición relacionados con el clima en las operaciones propias y a lo largo de la cadena de valor.',
    name_pt:
      'Descrição do processo em relação aos riscos e oportunidades de transição relacionados com o clima nas próprias operações e ao longo da cadeia de valor'
  },
  {
    id: 'b26ea4f6-580e-4189-b663-7478e212f9c1',
    name_dcycle:
      'transition_events_have_been_identified_over_short-,_medium-_and_long-term_time_horizons',
    datapoint_id: 'E1.IRO-1_10',
    esg_category: 'environmental',
    name_en:
      'Transition events have been identified over short-, medium- and long-term time horizons',
    name_es:
      'Se han identificado eventos de transición en horizontes temporales de corto, mediano y largo plazo.',
    name_pt: 'Eventos de transição foram identificados em horizontes de curto, médio e longo prazo'
  },
  {
    id: 'c978b6d0-6a1d-4191-95fa-dff2b4ca14b3',
    name_dcycle:
      'undertaking_has_screened_whether_assets_and_business_activities_may_be_exposed_to_transition_events',
    datapoint_id: 'E1.IRO-1_11',
    esg_category: 'environmental',
    name_en:
      'Undertaking has screened whether assets and business activities may be exposed to transition events',
    name_es:
      'La empresa ha examinado si los activos y las actividades comerciales pueden estar expuestos a eventos de transición.',
    name_pt:
      'A empresa analisou se os ativos e as atividades empresariais podem estar expostos a eventos de transição'
  },
  {
    id: '370295d2-c4d4-46a0-9b94-bcbe4981d2d8',
    name_dcycle:
      'extent_to_which_assets_and_business_activities_may_be_exposed_and_are_sensitive_to_identified_transition_events_has_been_assessed',
    datapoint_id: 'E1.IRO-1_12',
    esg_category: 'environmental',
    name_en:
      'Extent to which assets and business activities may be exposed and are sensitive to identified transition events has been assessed',
    name_es:
      'Se ha evaluado el grado en que los activos y las actividades comerciales pueden estar expuestos y son sensibles a eventos de transición identificados.',
    name_pt:
      'Foi avaliada a extensão em que os ativos e atividades comerciais podem estar expostos e são sensíveis a eventos de transição identificados'
  },
  {
    id: '5d29de55-a7da-48fa-83de-c11c173ddfd7',
    name_dcycle:
      'identification_of_transition_events_and_assessment_of_exposure_has_been_informed_by_climate-related_scenario_analysis',
    datapoint_id: 'E1.IRO-1_13',
    esg_category: 'environmental',
    name_en:
      'Identification of transition events and assessment of exposure has been informed by climate-related scenario analysis',
    name_es:
      'La identificación de eventos de transición y la evaluación de la exposición se han basado en análisis de escenarios relacionados con el clima.',
    name_pt:
      'A identificação de eventos de transição e a avaliação da exposição foram informadas pela análise de cenários relacionados com o clima'
  },
  {
    id: '94513e1a-c4c0-4ab6-8a37-1b2bb525c342',
    name_dcycle:
      'assets_and_business_activities_that_are_incompatible_with_or_need_significant_efforts_to_be_compatible_with_transition_to_climate-neutral_economy_have_been_identified',
    datapoint_id: 'E1.IRO-1_14',
    esg_category: 'environmental',
    name_en:
      'Assets and business activities that are incompatible with or need significant efforts to be compatible with transition to climate-neutral economy have been identified',
    name_es:
      'Se han identificado activos y actividades comerciales que son incompatibles con la transición a una economía climáticamente neutra o que necesitan esfuerzos significativos para ser compatibles con ella.',
    name_pt:
      'Foram identificados ativos e atividades empresariais que são incompatíveis ou que necessitam de esforços significativos para serem compatíveis com a transição para uma economia com impacto neutro no clima'
  },
  {
    id: 'b1df30ad-1691-4c89-8a4a-c76ecf37224e',
    name_dcycle:
      'explanation_of_how_climate-related_scenario_analysis_has_been_used_to_inform_identification_and_assessment_of_transition_risks_and_opportunities_over_short,_medium_and_long-term',
    datapoint_id: 'E1.IRO-1_15',
    esg_category: 'environmental',
    name_en:
      'Explanation of how climate-related scenario analysis has been used to inform identification and assessment of transition risks and opportunities over short, medium and long-term',
    name_es:
      'Explicación de cómo se ha utilizado el análisis de escenarios relacionados con el clima para informar la identificación y evaluación de los riesgos y oportunidades de transición a corto, mediano y largo plazo.',
    name_pt:
      'Explicação de como a análise de cenários relacionados ao clima tem sido usada para informar a identificação e avaliação de riscos e oportunidades de transição no curto, médio e longo prazo'
  },
  {
    id: '8a3318c1-7fba-4022-92ef-606e712b7f5c',
    name_dcycle:
      'explanation_of_how_climate_scenarios_used_are_compatible_with_critical_climate-related_assumptions_made_in_financial_statements',
    datapoint_id: 'E1.IRO-1_16',
    esg_category: 'environmental',
    name_en:
      'Explanation of how climate scenarios used are compatible with critical climate-related assumptions made in financial statements',
    name_es:
      'Explicación de cómo los escenarios climáticos utilizados son compatibles con los supuestos críticos relacionados con el clima hechos en los estados financieros.',
    name_pt:
      'Explicação de como os cenários climáticos utilizados são compatíveis com as premissas críticas relacionadas ao clima feitas nas demonstrações financeiras'
  },
  {
    id: 'ed19f583-a1ad-4e91-b914-98d43e4e8e98',
    name_dcycle: 'sustainability_matters_addressed_by_policy_for_climate_change',
    datapoint_id: 'E1-2_01',
    esg_category: 'environmental',
    name_en: 'Sustainability matters addressed by policy for climate change',
    name_es: 'Cuestiones de sostenibilidad abordadas por la política de cambio climático',
    name_pt: 'Questões de sustentabilidade abordadas pela política para as alterações climáticas'
  },
  {
    id: 'd6c6b91a-f2b6-4524-8927-9f37d9d72e68',
    name_dcycle: 'decarbonisation_lever_type',
    datapoint_id: 'E1-3_01',
    esg_category: 'environmental',
    name_en: 'Decarbonisation lever type',
    name_es: 'Tipo palanca de descarbonización',
    name_pt: 'Tipo de alavanca de descarbonização'
  },
  {
    id: '83a9a60e-d826-4344-9665-41741e3c2500',
    name_dcycle: 'adaptation_solution_type',
    datapoint_id: 'E1-3_02',
    esg_category: 'environmental',
    name_en: 'Adaptation solution type',
    name_es: 'Tipo de solución de adaptación',
    name_pt: 'Tipo de solução de adaptação'
  },
  {
    id: 'bba73b06-8f1a-4243-bc7b-f951d5378a86',
    name_dcycle: 'achieved_ghg_emission_reductions',
    datapoint_id: 'E1-3_03',
    esg_category: 'environmental',
    name_en: 'Achieved GHG emission reductions',
    name_es: 'Reducciones de emisiones de GEI logradas',
    name_pt: 'Reduções de emissões de GEE alcançadas'
  },
  {
    id: 'bd0163be-fca1-4773-adf5-915a1569154c',
    name_dcycle: 'expected_ghg_emission_reductions',
    datapoint_id: 'E1-3_04',
    esg_category: 'environmental',
    name_en: 'Expected GHG emission reductions',
    name_es: 'Reducciones esperadas de emisiones de GEI',
    name_pt: 'Reduções esperadas de emissões de GEE'
  },
  {
    id: '80448d89-ca70-4ca1-aedc-93dc3de726f9',
    name_dcycle:
      'explanation_of_extent_to_which_ability_to_implement_action_depends_on_availability_and_allocation_of_resources',
    datapoint_id: 'E1-3_05',
    esg_category: 'environmental',
    name_en:
      'Explanation of extent to which ability to implement action depends on availability and allocation of resources',
    name_es:
      'Explicación de hasta qué punto la capacidad de implementar acciones depende de la disponibilidad y asignación de recursos',
    name_pt:
      'Explicação de até que ponto a capacidade de implementar ações depende da disponibilidade e alocação de recursos'
  },
  {
    id: 'ab5a5d25-d319-444a-bad3-432a96900ad0',
    name_dcycle:
      'explanation_of_relationship_of_significant_capex_and_opex_required_to_implement_actions_taken_or_planned_to_relevant_line_items_or_notes_in_financial_statements',
    datapoint_id: 'E1-3_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of relationship of significant CapEx and OpEx required to implement actions taken or planned to relevant line items or notes in financial statements',
    name_es:
      'Explicación de la relación entre los gastos de capital y los gastos operativos significativos necesarios para implementar las acciones tomadas o planificadas con las partidas o notas relevantes de los estados financieros.',
    name_pt:
      'Explicação da relação de CapEx e OpEx significativos necessários para implementar ações tomadas ou planejadas para itens de linha ou notas relevantes nas demonstrações financeiras'
  },
  {
    id: 'ca20fd00-d262-4c2b-9420-8bb4b7782f4b',
    name_dcycle:
      'explanation_of_relationship_of_significant_capex_and_opex_required_to_implement_actions_taken_or_planned_to_key_performance_indicators_required_under_commission_delegated_regulation_(eu)_2021/2178',
    datapoint_id: 'E1-3_07',
    esg_category: 'environmental',
    name_en:
      'Explanation of relationship of significant CapEx and OpEx required to implement actions taken or planned to key performance indicators required under Commission Delegated Regulation (EU) 2021/2178',
    name_es:
      'Explicación de la relación de los gastos de capital y gastos operativos significativos necesarios para implementar las acciones tomadas o planificadas con los indicadores clave de rendimiento requeridos según el Reglamento Delegado (UE) 2021/2178 de la Comisión.',
    name_pt:
      'Explicação da relação entre CapEx e OpEx significativos necessários para implementar ações tomadas ou planeadas com os principais indicadores de desempenho exigidos pelo Regulamento Delegado (UE) 2021/2178 da Comissão'
  },
  {
    id: 'ced3aaa8-cf95-476e-a0ac-df6bcae77200',
    name_dcycle:
      'explanation_of_relationship_of_significant_capex_and_opex_required_to_implement_actions_taken_or_planned_to_capex_plan_required_by_commission_delegated_regulation_(eu)_2021/2178',
    datapoint_id: 'E1-3_08',
    esg_category: 'environmental',
    name_en:
      'Explanation of relationship of significant CapEx and OpEx required to implement actions taken or planned to CapEx plan required by Commission Delegated Regulation (EU) 2021/2178',
    name_es:
      'Explicación de la relación entre los CapEx y OpEx significativos necesarios para implementar las acciones tomadas o planificadas con el plan de CapEx requerido por el Reglamento Delegado (UE) 2021/2178 de la Comisión.',
    name_pt:
      'Explicação da relação entre CapEx e OpEx significativos necessários para implementar ações tomadas ou planeadas para o plano de CapEx exigido pelo Regulamento Delegado (UE) 2021/2178 da Comissão'
  },
  {
    id: '15dd7ee1-365f-47e2-9aca-ed0ba701ce73',
    name_dcycle:
      'disclosure_of_whether_and_how_ghg_emissions_reduction_targets_and_(or)_any_other_targets_have_been_set_to_manage_material_climate-related_impacts,_risks_and_opportunities',
    datapoint_id: 'E1-4_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how GHG emissions reduction targets and (or) any other targets have been set to manage material climate-related impacts, risks and opportunities',
    name_es:
      'Divulgación de si y cómo se han establecido objetivos de reducción de emisiones de GEI y (o) cualquier otro objetivo para gestionar los impactos, riesgos y oportunidades materiales relacionados con el clima.',
    name_pt:
      'Divulgação de se e como as metas de redução de emissões de GEE e (ou) quaisquer outras metas foram definidas para gerenciar impactos, riscos e oportunidades materiais relacionados ao clima'
  },
  {
    id: 'fc1a5f37-9a13-4a40-87fe-6dd30b9de410',
    name_dcycle:
      'tables:_multiple_dimensions_(baseline_year_and_targets;_ghg_types,_scope_3_categories,_decarbonisation_levers,_entity-specific_denominators_for_intensity_value)',
    datapoint_id: 'E1-4_02',
    esg_category: 'environmental',
    name_en:
      'Tables: Multiple Dimensions (baseline year and targets; GHG Types, Scope 3 Categories, Decarbonisation levers, entity-specific denominators for intensity value)',
    name_es:
      'Tablas: Dimensiones múltiples (año de referencia y objetivos; tipos de GEI, categorías de alcance 3, palancas de descarbonización, denominadores específicos de cada entidad para el valor de intensidad)',
    name_pt:
      'Tabelas: Dimensões Múltiplas (ano de referência e metas; Tipos de GEE, Categorias de Escopo 3, Alavancas de descarbonização, denominadores específicos da entidade para valor de intensidade)'
  },
  {
    id: '72f952a8-6845-4155-99eb-53671b38dff6',
    name_dcycle: 'absolute_value_of_total_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_03',
    esg_category: 'environmental',
    name_en: 'Absolute value of total Greenhouse gas emissions reduction',
    name_es: 'Valor absoluto de la reducción total de emisiones de gases de efecto invernadero',
    name_pt: 'Valor absoluto da redução total das emissões de gases de efeito estufa'
  },
  {
    id: '398decef-b10d-4026-99c3-b83140390d1a',
    name_dcycle:
      'percentage_of_total_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
    datapoint_id: 'E1-4_04',
    esg_category: 'environmental',
    name_en:
      'Percentage of total Greenhouse gas emissions reduction (as of emissions of base year)',
    name_es:
      'Porcentaje de reducción total de emisiones de gases de efecto invernadero (a partir de las emisiones del año base)',
    name_pt:
      'Porcentagem da redução total de emissões de gases de efeito estufa (a partir das emissões do ano base)'
  },
  {
    id: '59a74142-b1c0-4853-b7c2-48f8b4799f5c',
    name_dcycle: 'intensity_value_of_total_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_05',
    esg_category: 'environmental',
    name_en: 'Intensity value of total Greenhouse gas emissions reduction',
    name_es:
      'Valor de intensidad de la reducción total de emisiones de gases de efecto invernadero',
    name_pt: 'Valor da intensidade da redução total das emissões de gases de efeito estufa'
  },
  {
    id: 'e7ce0781-7fdf-4766-97bf-b202ed51b1f1',
    name_dcycle: 'absolute_value_of_scope_1_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_06',
    esg_category: 'environmental',
    name_en: 'Absolute value of Scope 1 Greenhouse gas emissions reduction',
    name_es:
      'Valor absoluto de la reducción de emisiones de gases de efecto invernadero de Alcance 1',
    name_pt: 'Valor absoluto da redução de emissões de gases de efeito estufa do Escopo 1'
  },
  {
    id: '89a6bc49-1da6-4e6d-8452-d93c04d7a9d9',
    name_dcycle:
      'percentage_of_scope_1_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
    datapoint_id: 'E1-4_07',
    esg_category: 'environmental',
    name_en:
      'Percentage of Scope 1 Greenhouse gas emissions reduction (as of emissions of base year)',
    name_es:
      'Porcentaje de reducción de emisiones de gases de efecto invernadero de Alcance 1 (a partir de las emisiones del año base)',
    name_pt:
      'Porcentagem de redução de emissões de gases de efeito estufa do Escopo 1 (a partir das emissões do ano base)'
  },
  {
    id: 'a737a2d7-4506-4bc8-b5c2-58a4451d420a',
    name_dcycle: 'intensity_value_of_scope_1_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_08',
    esg_category: 'environmental',
    name_en: 'Intensity value of Scope 1 Greenhouse gas emissions reduction',
    name_es:
      'Valor de intensidad de la reducción de emisiones de gases de efecto invernadero de Alcance 1',
    name_pt: 'Valor de intensidade da redução de emissões de gases de efeito estufa do Escopo 1'
  },
  {
    id: '63e99313-6520-4b37-a974-7fe9a27bdcc2',
    name_dcycle: 'absolute_value_of_location-based_scope_2_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_09',
    esg_category: 'environmental',
    name_en: 'Absolute value of location-based Scope 2 Greenhouse gas emissions reduction',
    name_es:
      'Valor absoluto de la reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en la ubicación',
    name_pt:
      'Valor absoluto da redução de emissões de gases de efeito estufa de Escopo 2 com base na localização'
  },
  {
    id: '2eb5b372-5cfb-474b-9b16-14bb2d1202ca',
    name_dcycle:
      'percentage_of_location-based_scope_2_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
    datapoint_id: 'E1-4_10',
    esg_category: 'environmental',
    name_en:
      'Percentage of location-based Scope 2 Greenhouse gas emissions reduction (as of emissions of base year)',
    name_es:
      'Porcentaje de reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en la ubicación (a partir de las emisiones del año base)',
    name_pt:
      'Porcentagem de redução de emissões de gases de efeito estufa de Escopo 2 com base na localização (a partir das emissões do ano base)'
  },
  {
    id: '27d7cf8a-19ec-4ef2-b189-722dad6d5573',
    name_dcycle: 'intensity_value_of_location-based_scope_2_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_11',
    esg_category: 'environmental',
    name_en: 'Intensity value of location-based Scope 2 Greenhouse gas emissions reduction',
    name_es:
      'Valor de intensidad de la reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en la ubicación',
    name_pt:
      'Valor de intensidade da redução de emissões de gases de efeito estufa de Escopo 2 com base na localização'
  },
  {
    id: '661b118d-86eb-445a-9060-33537b78687a',
    name_dcycle: 'absolute_value_of_market-based_scope_2_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_12',
    esg_category: 'environmental',
    name_en: 'Absolute value of market-based Scope 2 Greenhouse gas emissions reduction',
    name_es:
      'Valor absoluto de la reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en el mercado',
    name_pt:
      'Valor absoluto da redução de emissões de gases de efeito estufa de Escopo 2 com base no mercado'
  },
  {
    id: '09f641ab-d850-4ddb-bae3-b64e30ceab3b',
    name_dcycle:
      'percentage_of_market-based_scope_2_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
    datapoint_id: 'E1-4_13',
    esg_category: 'environmental',
    name_en:
      'Percentage of market-based Scope 2 Greenhouse gas emissions reduction (as of emissions of base year)',
    name_es:
      'Porcentaje de reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en el mercado (a partir de las emisiones del año base)',
    name_pt:
      'Porcentagem de redução de emissões de gases de efeito estufa de Escopo 2 com base no mercado (a partir das emissões do ano base)'
  },
  {
    id: '4cdbcb05-213b-484c-9b07-bc5a6ed45ad5',
    name_dcycle: 'intensity_value_of_market-based_scope_2_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_14',
    esg_category: 'environmental',
    name_en: 'Intensity value of market-based Scope 2 Greenhouse gas emissions reduction',
    name_es:
      'Valor de intensidad de la reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en el mercado',
    name_pt:
      'Valor da intensidade da redução de emissões de gases de efeito estufa de Escopo 2 com base no mercado'
  },
  {
    id: '8171542c-4e4c-44a0-a8a8-911a1497be46',
    name_dcycle: 'absolute_value_of_scope_3_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_15',
    esg_category: 'environmental',
    name_en: 'Absolute value of Scope 3 Greenhouse gas emissions reduction',
    name_es:
      'Valor absoluto de la reducción de emisiones de gases de efecto invernadero de Alcance 3',
    name_pt: 'Valor absoluto da redução de emissões de gases de efeito estufa do Escopo 3'
  },
  {
    id: '1a747fbf-e85b-45c1-939c-ae97d8326273',
    name_dcycle:
      'percentage_of_scope_3_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
    datapoint_id: 'E1-4_16',
    esg_category: 'environmental',
    name_en:
      'Percentage of Scope 3 Greenhouse gas emissions reduction (as of emissions of base year)',
    name_es:
      'Porcentaje de reducción de emisiones de gases de efecto invernadero de Alcance 3 (a partir de las emisiones del año base)',
    name_pt:
      'Porcentagem de redução de emissões de gases de efeito estufa do Escopo 3 (a partir das emissões do ano base)'
  },
  {
    id: '2a2140b1-7185-471b-a5e5-640251c98d68',
    name_dcycle: 'intensity_value_of_scope_3_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_17',
    esg_category: 'environmental',
    name_en: 'Intensity value of Scope 3 Greenhouse gas emissions reduction',
    name_es:
      'Valor de intensidad de la reducción de emisiones de gases de efecto invernadero de Alcance 3',
    name_pt: 'Valor de intensidade da redução de emissões de gases de efeito estufa do Escopo 3'
  },
  {
    id: '97da1db8-7adc-4e76-8c9f-69e81ba61535',
    name_dcycle:
      'explanation_of_how_consistency_of_ghg_emission_reduction_targets_with_ghg_inventory_boundaries_has_been_ensured',
    datapoint_id: 'E1-4_18',
    esg_category: 'environmental',
    name_en:
      'Explanation of how consistency of GHG emission reduction targets with GHG inventory boundaries has been ensured',
    name_es:
      'Explicación de cómo se ha garantizado la coherencia de los objetivos de reducción de emisiones de GEI con los límites del inventario de GEI',
    name_pt:
      'Explicação de como foi garantida a consistência das metas de redução de emissões de GEE com os limites do inventário de GEE'
  },
  {
    id: 'acd770ab-fa46-4e7e-87dd-5d0e8ca1d1ef',
    name_dcycle: 'disclosure_of_past_progress_made_in_meeting_target_before_current_base_year',
    datapoint_id: 'E1-4_19',
    esg_category: 'environmental',
    name_en: 'Disclosure of past progress made in meeting target before current base year',
    name_es:
      'Divulgación del progreso realizado en el pasado para cumplir la meta antes del año base actual',
    name_pt:
      'Divulgação dos progressos anteriores realizados no cumprimento da meta antes do ano base atual'
  },
  {
    id: '4088df8e-b070-4587-bed9-8ad8d9080e65',
    name_dcycle:
      'description_of_how_it_has_been_ensured_that_baseline_value_is_representative_in_terms_of_activities_covered_and_influences_from_external_factors',
    datapoint_id: 'E1-4_20',
    esg_category: 'environmental',
    name_en:
      'Description of how it has been ensured that baseline value is representative in terms of activities covered and influences from external factors',
    name_es:
      'Descripción de cómo se ha garantizado que el valor de referencia sea representativo en términos de las actividades cubiertas y las influencias de factores externos.',
    name_pt:
      'Descrição de como foi garantido que o valor de referência é representativo em termos de atividades abrangidas e influências de fatores externos'
  },
  {
    id: '9a23078c-c689-42cf-bc22-1b1616d8f3ca',
    name_dcycle:
      'description_of_how_new_baseline_value_affects_new_target,_its_achievement_and_presentation_of_progress_over_time',
    datapoint_id: 'E1-4_21',
    esg_category: 'environmental',
    name_en:
      'Description of how new baseline value affects new target, its achievement and presentation of progress over time',
    name_es:
      'Descripción de cómo el nuevo valor de referencia afecta el nuevo objetivo, su logro y presentación del progreso a lo largo del tiempo.',
    name_pt:
      'Descrição de como o novo valor da linha de base afeta a nova meta, seu alcance e apresentação do progresso ao longo do tempo'
  },
  {
    id: 'ef289fcc-015d-4bae-bb58-c849208eb761',
    name_dcycle:
      'ghg_emission_reduction_target_is_science_based_and_compatible_with_limiting_global_warming_to_one_and_half_degrees_celsius',
    datapoint_id: 'E1-4_22',
    esg_category: 'environmental',
    name_en:
      'GHG emission reduction target is science based and compatible with limiting global warming to one and half degrees Celsius',
    name_es:
      'El objetivo de reducción de emisiones de GEI tiene base científica y es compatible con limitar el calentamiento global a un grado y medio Celsius.',
    name_pt:
      'A meta de redução de emissões de GEE é baseada na ciência e é compatível com a limitação do aquecimento global a um grau e meio Celsius'
  },
  {
    id: '4237f7f8-bb8b-4921-8553-278646ba33cc',
    name_dcycle:
      'description_of_expected_decarbonisation_levers_and_their_overall_quantitative_contributions_to_achieve_ghg_emission_reduction_target',
    datapoint_id: 'E1-4_23',
    esg_category: 'environmental',
    name_en:
      'Description of expected decarbonisation levers and their overall quantitative contributions to achieve GHG emission reduction target',
    name_es:
      'Descripción de las palancas de descarbonización esperadas y sus contribuciones cuantitativas generales para lograr el objetivo de reducción de emisiones de GEI',
    name_pt:
      'Descrição das alavancas de descarbonização esperadas e das suas contribuições quantitativas globais para atingir a meta de redução das emissões de GEE'
  },
  {
    id: 'd06f33ea-2e51-4c4b-a8a4-ec56a9b2efe2',
    name_dcycle:
      'diverse_range_of_climate_scenarios_have_been_considered_to_detect_relevant_environmental,_societal,_technology,_market_and_policy-related_developments_and_determine_decarbonisation_levers',
    datapoint_id: 'E1-4_24',
    esg_category: 'environmental',
    name_en:
      'Diverse range of climate scenarios have been considered to detect relevant environmental, societal, technology, market and policy-related developments and determine decarbonisation levers',
    name_es:
      'Se ha considerado una amplia gama de escenarios climáticos para detectar desarrollos ambientales, sociales, tecnológicos, de mercado y políticos relevantes y determinar palancas de descarbonización.',
    name_pt:
      'Uma ampla gama de cenários climáticos foi considerada para detectar desenvolvimentos ambientais, sociais, tecnológicos, de mercado e políticos relevantes e determinar alavancas de descarbonização'
  },
  {
    id: 'cfcaad63-ec69-4f28-a295-21f5fbabed5e',
    name_dcycle: 'total_energy_consumption_related_to_own_operations',
    datapoint_id: 'E1-5_01',
    esg_category: 'environmental',
    name_en: 'Total energy consumption related to own operations',
    name_es: 'Consumo total de energía relacionado con operaciones propias.',
    name_pt: 'Consumo total de energia relacionado às operações próprias'
  },
  {
    id: 'bc9db2e6-3c17-4e22-8712-8cbe84bc0f7b',
    name_dcycle: 'total_energy_consumption_from_fossil_sources',
    datapoint_id: 'E1-5_02',
    esg_category: 'environmental',
    name_en: 'Total energy consumption from fossil sources',
    name_es: 'Consumo total de energía procedente de fuentes fósiles',
    name_pt: 'Consumo total de energia proveniente de fontes fósseis'
  },
  {
    id: 'e39819a3-5af5-4ceb-aa29-dc97934a7f5f',
    name_dcycle: 'total_energy_consumption_from_nuclear_sources',
    datapoint_id: 'E1-5_03',
    esg_category: 'environmental',
    name_en: 'Total energy consumption from nuclear sources',
    name_es: 'Consumo total de energía procedente de fuentes nucleares',
    name_pt: 'Consumo total de energia proveniente de fontes nucleares'
  },
  {
    id: '851b0bd6-f9c8-40d1-a6b8-7d3c549f21c7',
    name_dcycle:
      'percentage_of_energy_consumption_from_nuclear_sources_in_total_energy_consumption',
    datapoint_id: 'E1-5_04',
    esg_category: 'environmental',
    name_en: 'Percentage of energy consumption from nuclear sources in total energy consumption',
    name_es:
      'Porcentaje del consumo de energía procedente de fuentes nucleares sobre el consumo total de energía',
    name_pt:
      'Percentagem do consumo de energia proveniente de fontes nucleares no consumo total de energia'
  },
  {
    id: 'f5568f99-7bad-489d-9f27-ef3272c75a6d',
    name_dcycle: 'total_energy_consumption_from_renewable_sources',
    datapoint_id: 'E1-5_05',
    esg_category: 'environmental',
    name_en: 'Total energy consumption from renewable sources',
    name_es: 'Consumo total de energía procedente de fuentes renovables',
    name_pt: 'Consumo total de energia proveniente de fontes renováveis'
  },
  {
    id: '2951cb37-c21d-4f51-b809-a5915f5144f8',
    name_dcycle: 'fuel_consumption_from_renewable_sources',
    datapoint_id: 'E1-5_06',
    esg_category: 'environmental',
    name_en: 'Fuel consumption from renewable sources',
    name_es: 'Consumo de combustible procedente de fuentes renovables.',
    name_pt: 'Consumo de combustível proveniente de fontes renováveis'
  },
  {
    id: '565b1be5-5a80-4c5a-9c13-c98b32ff235f',
    name_dcycle:
      'consumption_of_purchased_or_acquired_electricity,_heat,_steam,_and_cooling_from_renewable_sources',
    datapoint_id: 'E1-5_07',
    esg_category: 'environmental',
    name_en:
      'Consumption of purchased or acquired electricity, heat, steam, and cooling from renewable sources',
    name_es:
      'Consumo de electricidad, calor, vapor y refrigeración comprados o adquiridos de fuentes renovables',
    name_pt:
      'Consumo de eletricidade, calor, vapor e resfriamento comprados ou adquiridos de fontes renováveis'
  },
  {
    id: 'e4307110-af79-42d3-a229-9529490a2533',
    name_dcycle: 'consumption_of_self-generated_non-fuel_renewable_energy',
    datapoint_id: 'E1-5_08',
    esg_category: 'environmental',
    name_en: 'Consumption of self-generated non-fuel renewable energy',
    name_es: 'Consumo de energía renovable autogenerada no combustible',
    name_pt: 'Consumo de energia renovável não combustível autogerada'
  },
  {
    id: '2d74a40f-dc5d-410d-b4d0-ec74f4ae3b2c',
    name_dcycle: 'percentage_of_renewable_sources_in_total_energy_consumption',
    datapoint_id: 'E1-5_09',
    esg_category: 'environmental',
    name_en: 'Percentage of renewable sources in total energy consumption',
    name_es: 'Porcentaje de fuentes renovables en el consumo total de energía',
    name_pt: 'Percentagem de fontes renováveis ​​no consumo total de energia'
  },
  {
    id: 'e37a66a0-ba84-4c7a-9050-025cd6451f9a',
    name_dcycle: 'fuel_consumption_from_coal_and_coal_products',
    datapoint_id: 'E1-5_10',
    esg_category: 'environmental',
    name_en: 'Fuel consumption from coal and coal products',
    name_es: 'Consumo de combustible procedente de carbón y productos del carbón.',
    name_pt: 'Consumo de combustível proveniente de carvão e produtos de carvão'
  },
  {
    id: 'a30c7eb0-81cc-4a51-b9a3-9cbf3334e3e4',
    name_dcycle: 'fuel_consumption_from_crude_oil_and_petroleum_products',
    datapoint_id: 'E1-5_11',
    esg_category: 'environmental',
    name_en: 'Fuel consumption from crude oil and petroleum products',
    name_es:
      'Consumo de combustible procedente de petróleo crudo y productos derivados del petróleo.',
    name_pt: 'Consumo de combustível proveniente de petróleo bruto e produtos petrolíferos'
  },
  {
    id: '6eefbfeb-a2fe-47e7-9373-dd9dca7251b8',
    name_dcycle: 'fuel_consumption_from_natural_gas',
    datapoint_id: 'E1-5_12',
    esg_category: 'environmental',
    name_en: 'Fuel consumption from natural gas',
    name_es: 'Consumo de combustible procedente de gas natural.',
    name_pt: 'Consumo de combustível proveniente de gás natural'
  },
  {
    id: '826c0a7f-0974-4b8e-b000-e710510172b8',
    name_dcycle: 'fuel_consumption_from_other_fossil_sources',
    datapoint_id: 'E1-5_13',
    esg_category: 'environmental',
    name_en: 'Fuel consumption from other fossil sources',
    name_es: 'Consumo de combustible de otras fuentes fósiles',
    name_pt: 'Consumo de combustível de outras fontes fósseis'
  },
  {
    id: '9cf9f66e-65f2-4206-bfe1-73e271586177',
    name_dcycle:
      'consumption_of_purchased_or_acquired_electricity,_heat,_steam,_or_cooling_from_fossil_sources',
    datapoint_id: 'E1-5_14',
    esg_category: 'environmental',
    name_en:
      'Consumption of purchased or acquired electricity, heat, steam, or cooling from fossil sources',
    name_es:
      'Consumo de electricidad, calor, vapor o refrigeración comprados o adquiridos de fuentes fósiles',
    name_pt:
      'Consumo de eletricidade, calor, vapor ou resfriamento comprados ou adquiridos de fontes fósseis'
  },
  {
    id: '8e2e53e5-850d-4c52-93cb-a9feabb4f95f',
    name_dcycle: 'percentage_of_fossil_sources_in_total_energy_consumption',
    datapoint_id: 'E1-5_15',
    esg_category: 'environmental',
    name_en: 'Percentage of fossil sources in total energy consumption',
    name_es: 'Porcentaje de fuentes fósiles en el consumo total de energía',
    name_pt: 'Percentagem de fontes fósseis no consumo total de energia'
  },
  {
    id: 'ed2558eb-3d24-483c-89f5-ae82ec4875f8',
    name_dcycle: 'non-renewable_energy_production',
    datapoint_id: 'E1-5_16',
    esg_category: 'environmental',
    name_en: 'Non-renewable energy production',
    name_es: 'Producción de energía no renovable',
    name_pt: 'Produção de energia não renovável'
  },
  {
    id: 'c55b6213-9a96-4465-b269-f119ec6ab57c',
    name_dcycle: 'renewable_energy_production',
    datapoint_id: 'E1-5_17',
    esg_category: 'environmental',
    name_en: 'Renewable energy production',
    name_es: 'Producción de energía renovable',
    name_pt: 'Produção de energia renovável'
  },
  {
    id: 'b9d1cd00-c4d2-4ff1-a2d2-3919da32ec80',
    name_dcycle:
      'energy_intensity_from_activities_in_high_climate_impact_sectors_(total_energy_consumption_per_net_revenue)',
    datapoint_id: 'E1-5_18',
    esg_category: 'environmental',
    name_en:
      'Energy intensity from activities in high climate impact sectors (total energy consumption per net revenue)',
    name_es:
      'Intensidad energética de actividades en sectores de alto impacto climático (consumo total de energía por ingreso neto)',
    name_pt:
      'Intensidade energética proveniente de atividades em setores de elevado impacto climático (consumo total de energia por receita líquida)'
  },
  {
    id: '41a20803-c3d8-4e02-97fe-34ec3ec19ab7',
    name_dcycle: 'total_energy_consumption_from_activities_in_high_climate_impact_sectors',
    datapoint_id: 'E1-5_19',
    esg_category: 'environmental',
    name_en: 'Total energy consumption from activities in high climate impact sectors',
    name_es: 'Consumo total de energía de actividades en sectores de alto impacto climático',
    name_pt:
      'Consumo total de energia proveniente de atividades em setores de elevado impacto climático'
  },
  {
    id: 'bc2bfe9d-35fd-4d53-8822-2112b35667f8',
    name_dcycle: 'high_climate_impact_sectors_used_to_determine_energy_intensity',
    datapoint_id: 'E1-5_20',
    esg_category: 'environmental',
    name_en: 'High climate impact sectors used to determine energy intensity',
    name_es:
      'Sectores de alto impacto climático utilizados para determinar la intensidad energética',
    name_pt: 'Setores de alto impacto climático usados ​​para determinar a intensidade energética'
  },
  {
    id: 'f07c343c-9c21-4dbd-b11f-06ee272e0641',
    name_dcycle:
      'disclosure_of_reconciliation_to_relevant_line_item_or_notes_in_financial_statements_of_net_revenue_from_activities_in_high_climate_impact_sectors',
    datapoint_id: 'E1-5_21',
    esg_category: 'environmental',
    name_en:
      'Disclosure of reconciliation to relevant line item or notes in financial statements of net revenue from activities in high climate impact sectors',
    name_es:
      'Divulgación de la conciliación con partidas o notas relevantes en los estados financieros de ingresos netos de actividades en sectores de alto impacto climático',
    name_pt:
      'Divulgação da reconciliação com itens de linha relevantes ou notas nas demonstrações financeiras da receita líquida de atividades em setores de alto impacto climático'
  },
  {
    id: '31c190a0-cc60-4ec0-8a9e-912e022d704e',
    name_dcycle: 'net_revenue_from_activities_in_high_climate_impact_sectors',
    datapoint_id: 'E1-5_22',
    esg_category: 'environmental',
    name_en: 'Net revenue from activities in high climate impact sectors',
    name_es: 'Ingresos netos de actividades en sectores de alto impacto climático',
    name_pt: 'Receita líquida de atividades em setores de alto impacto climático'
  },
  {
    id: '2592da5f-0adb-4f4b-95de-843f531b94f4',
    name_dcycle: 'net_revenue_from_activities_other_than_in_high_climate_impact_sectors',
    datapoint_id: 'E1-5_23',
    esg_category: 'environmental',
    name_en: 'Net revenue from activities other than in high climate impact sectors',
    name_es: 'Ingresos netos de actividades distintas a las de sectores de alto impacto climático',
    name_pt: 'Receita líquida de atividades que não sejam de setores de elevado impacto climático'
  },
  {
    id: 'c0c7781a-380b-4025-9782-603d98652362',
    name_dcycle: 'gross_scopes_1,_2,_3_and_total_ghg_emissions_-_ghg_emissions_per_scope_[table]',
    datapoint_id: 'E1-6_01',
    esg_category: 'environmental',
    name_en: 'Gross Scopes 1, 2, 3 and Total GHG emissions - GHG emissions per scope [table]',
    name_es:
      'Alcances brutos 1, 2, 3 y emisiones totales de GEI - Emisiones de GEI por alcance [tabla]',
    name_pt:
      'Emissões Brutas de Escopos 1, 2, 3 e Total de GEE - Emissões de GEE por escopo [tabela]'
  },
  {
    id: '6b1203ec-2c19-4c12-9270-e6cdf1126cd4',
    name_dcycle:
      'gross_scopes_1,_2,_3_and_total_ghg_emissions_-_financial_and_operational_control_[table]',
    datapoint_id: 'E1-6_02',
    esg_category: 'environmental',
    name_en:
      'Gross Scopes 1, 2, 3 and Total GHG emissions - financial and operational control [table]',
    name_es:
      'Alcances brutos 1, 2, 3 y emisiones totales de GEI - control financiero y operativo [tabla]',
    name_pt:
      'Emissões brutas de Escopos 1, 2, 3 e Total de GEE – controle financeiro e operacional [tabela]'
  },
  {
    id: 'c82bf531-6af0-4b86-83c3-807c7d208f32',
    name_dcycle:
      'disaggregation_of_ghg_emissions_-_by_country,_operating_segments,_economic_activity,_subsidiary,_ghg_category_or_source_type',
    datapoint_id: 'E1-6_03',
    esg_category: 'environmental',
    name_en:
      'Disaggregation of GHG emissions - by country, operating segments, economic activity, subsidiary, GHG category or source type',
    name_es:
      'Desagregación de emisiones de GEI - por país, segmentos operativos, actividad económica, subsidiaria, categoría de GEI o tipo de fuente',
    name_pt:
      'Desagregação das emissões de GEE – por país, segmentos operacionais, atividade económica, subsidiária, categoria de GEE ou tipo de fonte'
  },
  {
    id: 'e2fcccd4-5926-4834-950b-b579aeb9d8b0',
    name_dcycle:
      'gross_scopes_1,_2,_3_and_total_ghg_emissions_-_scope_3_ghg_emissions_(ghg_protocol)_[table]',
    datapoint_id: 'E1-6_04',
    esg_category: 'environmental',
    name_en:
      'Gross Scopes 1, 2, 3 and Total GHG emissions - Scope 3 GHG emissions (GHG Protocol) [table]',
    name_es:
      'Emisiones brutas de GEI de alcance 1, 2, 3 y totales de GEI - Emisiones de GEI de alcance 3 (Protocolo de GEI) [tabla]',
    name_pt:
      'Emissões brutas de GEE de Escopo 1, 2, 3 e Total - Emissões de GEE de Escopo 3 (GHG Protocol) [tabela]'
  },
  {
    id: 'b28b8bab-a5b0-487e-bfe6-f8fb89c9761a',
    name_dcycle:
      'gross_scopes_1,_2,_3_and_total_ghg_emissions_-_scope_3_ghg_emissions_(iso_14064-1)_[table]',
    datapoint_id: 'E1-6_05',
    esg_category: 'environmental',
    name_en:
      'Gross Scopes 1, 2, 3 and Total GHG emissions - Scope 3 GHG emissions (ISO 14064-1) [table]',
    name_es:
      'Emisiones brutas de GEI de alcance 1, 2, 3 y totales de GEI - Emisiones de GEI de alcance 3 (ISO 14064-1) [tabla]',
    name_pt:
      'Emissões brutas de GEE de Escopo 1, 2, 3 e Total - Emissões de GEE de Escopo 3 (ISO 14064-1) [tabela]'
  },
  {
    id: 'f14271d7-f701-4ebc-be3c-36ea927b327b',
    name_dcycle:
      'gross_scopes_1,_2,_3_and_total_ghg_emissions_-_total_ghg_emissions_-_value_chain_[table]',
    datapoint_id: 'E1-6_06',
    esg_category: 'environmental',
    name_en:
      'Gross Scopes 1, 2, 3 and Total GHG emissions - total GHG emissions - value chain [table]',
    name_es:
      'Alcances brutos 1, 2, 3 y emisiones totales de GEI - emisiones totales de GEI - cadena de valor [tabla]',
    name_pt:
      'Escopos brutos 1, 2, 3 e emissões totais de GEE - emissões totais de GEE - cadeia de valor [tabela]'
  },
  {
    id: '2502ab87-6d00-448a-9932-433338af0f7a',
    name_dcycle: 'gross_scope_1_greenhouse_gas_emissions',
    datapoint_id: 'E1-6_07',
    esg_category: 'environmental',
    name_en: 'Gross Scope 1 greenhouse gas emissions',
    name_es: 'Emisiones brutas de gases de efecto invernadero de alcance 1',
    name_pt: 'Emissões brutas de gases de efeito estufa de Escopo 1'
  },
  {
    id: 'b4802d24-57c6-49f7-b6a6-c62126e2379b',
    name_dcycle: 'percentage_of_scope_1_ghg_emissions_from_regulated_emission_trading_schemes',
    datapoint_id: 'E1-6_08',
    esg_category: 'environmental',
    name_en: 'Percentage of Scope 1 GHG emissions from regulated emission trading schemes',
    name_es:
      'Porcentaje de emisiones de GEI de Alcance 1 provenientes de esquemas regulados de comercio de derechos de emisión',
    name_pt:
      'Porcentagem de emissões de GEE de Escopo 1 provenientes de esquemas regulamentados de comércio de emissões'
  },
  {
    id: '9a71aa99-812f-4ccd-b41b-35ed6105115b',
    name_dcycle: 'gross_location-based_scope_2_greenhouse_gas_emissions',
    datapoint_id: 'E1-6_09',
    esg_category: 'environmental',
    name_en: 'Gross location-based Scope 2 greenhouse gas emissions',
    name_es: 'Emisiones brutas de gases de efecto invernadero de alcance 2 basadas en la ubicación',
    name_pt: 'Emissões brutas de gases de efeito estufa de Escopo 2 com base na localização'
  },
  {
    id: 'd430bef5-1f0f-48a7-8087-518f54c1ad65',
    name_dcycle: 'gross_market-based_scope_2_greenhouse_gas_emissions',
    datapoint_id: 'E1-6_10',
    esg_category: 'environmental',
    name_en: 'Gross market-based Scope 2 greenhouse gas emissions',
    name_es: 'Emisiones brutas de gases de efecto invernadero de alcance 2 basadas en el mercado',
    name_pt: 'Emissões brutas de gases de efeito estufa de Escopo 2 com base no mercado'
  },
  {
    id: 'c420172e-da1b-42b1-8e4d-fa94137f017e',
    name_dcycle: 'gross_scope_3_greenhouse_gas_emissions',
    datapoint_id: 'E1-6_11',
    esg_category: 'environmental',
    name_en: 'Gross Scope 3 greenhouse gas emissions',
    name_es: 'Emisiones brutas de gases de efecto invernadero de alcance 3',
    name_pt: 'Emissões brutas de gases de efeito estufa de Escopo 3'
  },
  {
    id: 'bfad23d9-cf34-471f-b6e0-3ad992b20f1f',
    name_dcycle: 'total_ghg_emissions_location_based',
    datapoint_id: 'E1-6_12',
    esg_category: 'environmental',
    name_en: 'Total GHG emissions location based',
    name_es: 'Ubicación de las emisiones totales de GEI según la ubicación',
    name_pt: 'Localização total das emissões de GEE com base'
  },
  {
    id: '367657d6-bbe9-4d71-bc8b-87a9ff4f73f4',
    name_dcycle: 'total_ghg_emissions_market_based',
    datapoint_id: 'E1-6_13',
    esg_category: 'environmental',
    name_en: 'Total GHG emissions market based',
    name_es: 'Total de emisiones de GEI basado en el mercado',
    name_pt: 'Total de emissões de GEE com base no mercado'
  },
  {
    id: '6117188a-79be-4720-9add-adaa28d39b74',
    name_dcycle:
      'disclosure_of_significant_changes_in_definition_of_what_constitutes_reporting_undertaking_and_its_value_chain_and_explanation_of_their_effect_on_year-to-year_comparability_of_reported_ghg_emissions',
    datapoint_id: 'E1-6_14',
    esg_category: 'environmental',
    name_en:
      'Disclosure of significant changes in definition of what constitutes reporting undertaking and its value chain and explanation of their effect on year-to-year comparability of reported GHG emissions',
    name_es:
      'Divulgación de cambios significativos en la definición de lo que constituye una empresa que informa y su cadena de valor y explicación de su efecto en la comparabilidad año tras año de las emisiones de GEI reportadas.',
    name_pt:
      'Divulgação de alterações significativas na definição do que constitui empresa relatora e da sua cadeia de valor e explicação do seu efeito na comparabilidade anual das emissões de GEE comunicadas'
  },
  {
    id: '305b5e67-47ef-4c2e-b0cc-bd226afbc9f7',
    name_dcycle:
      'disclosure_of_methodologies,_significant_assumptions_and_emissions_factors_used_to_calculate_or_measure_ghg_emissions',
    datapoint_id: 'E1-6_15',
    esg_category: 'environmental',
    name_en:
      'Disclosure of methodologies, significant assumptions and emissions factors used to calculate or measure GHG emissions',
    name_es:
      'Divulgación de metodologías, supuestos importantes y factores de emisión utilizados para calcular o medir las emisiones de GEI.',
    name_pt:
      'Divulgação de metodologias, premissas significativas e fatores de emissão utilizados para calcular ou medir emissões de GEE'
  },
  {
    id: 'ea244665-5dee-4ca5-b583-8667b439e099',
    name_dcycle:
      'disclosure_of_the_effects_of_significant_events_and_changes_in_circumstances_(relevant_to_its_ghg_emissions)_that_occur_between_the_reporting_dates_of_the_entities_in_its_value_chain_and_the_date_of_the_undertaking’s_general_purpose_financial_statements',
    datapoint_id: 'E1-6_16',
    esg_category: 'environmental',
    name_en:
      'Disclosure of the effects of significant events and changes in circumstances (relevant to its GHG emissions) that occur between the reporting dates of the entities in its value chain and the date of the undertaking’s general purpose financial statements',
    name_es:
      'Revelación de los efectos de eventos significativos y cambios en las circunstancias (relevantes para sus emisiones de GEI) que ocurren entre las fechas de presentación de informes de las entidades en su cadena de valor y la fecha de los estados financieros con propósito general de la empresa.',
    name_pt:
      'Divulgação dos efeitos de eventos significativos e alterações nas circunstâncias (relevantes para as suas emissões de GEE) que ocorrem entre as datas de relato das entidades na sua cadeia de valor e a data das demonstrações financeiras de finalidade geral da empresa'
  },
  {
    id: '131a4cbd-573d-4dac-8e7b-0124ca112fbc',
    name_dcycle:
      'biogenic_emissions_of_co2_from_the_combustion_or_bio-degradation_of_biomassnot_included_in_scope_1_ghg_emissions',
    datapoint_id: 'E1-6_17',
    esg_category: 'environmental',
    name_en:
      'biogenic emissions of CO2 from the combustion or bio-degradation of biomassnot included in Scope 1 GHG emissions',
    name_es:
      'Emisiones biogénicas de CO2 procedentes de la combustión o biodegradación de biomasa no incluidas en el alcance 1 de emisiones de GEI.',
    name_pt:
      'emissões biogênicas de CO2 provenientes da combustão ou biodegradação de biomassa não incluídas nas emissões de GEE do Escopo 1'
  },
  {
    id: '455a4cad-c00d-406d-9978-d1860b3066f3',
    name_dcycle: 'percentage_of_contractual_instruments,_scope_2_ghg_emissions',
    datapoint_id: 'E1-6_18',
    esg_category: 'environmental',
    name_en: 'Percentage of contractual instruments, Scope 2 GHG emissions',
    name_es: 'Porcentaje de instrumentos contractuales, emisiones de GEI de alcance 2',
    name_pt: 'Percentual de instrumentos contratuais, emissões de GEE de Escopo 2'
  },
  {
    id: '6608e475-9392-410f-8047-220db1e47f2d',
    name_dcycle: 'disclosure_of_types_of_contractual_instruments,_scope_2_ghg_emissions',
    datapoint_id: 'E1-6_19',
    esg_category: 'environmental',
    name_en: 'Disclosure of types of contractual instruments, Scope 2 GHG emissions',
    name_es: 'Divulgación de tipos de instrumentos contractuales, emisiones de GEI de Alcance 2',
    name_pt: 'Divulgação dos tipos de instrumentos contratuais, emissões de GEE Escopo 2'
  },
  {
    id: 'c9f13c4d-6b2e-4b85-a9c6-fdf7fc0974c4',
    name_dcycle:
      'percentage_of_market-based_scope_2_ghg_emissions_linked_to_purchased_electricity_bundled_with_instruments',
    datapoint_id: 'E1-6_20',
    esg_category: 'environmental',
    name_en:
      'Percentage of market-based Scope 2 GHG emissions linked to purchased electricity bundled with instruments',
    name_es:
      'Porcentaje de emisiones de GEI de alcance 2 basadas en el mercado vinculadas a la electricidad comprada junto con instrumentos',
    name_pt:
      'Percentagem de emissões de GEE de Âmbito 2 baseadas no mercado associadas à eletricidade adquirida em conjunto com instrumentos'
  },
  {
    id: '3481eebc-0811-4533-80c8-07b9614073fd',
    name_dcycle:
      'percentage_of_contractual_instruments_used_for_sale_and_purchase_of_energy_bundled_with_attributes_about_energy_generation_in_relation_to_scope_2_ghg_emissions',
    datapoint_id: 'E1-6_21',
    esg_category: 'environmental',
    name_en:
      'Percentage of contractual instruments used for sale and purchase of energy bundled with attributes about energy generation in relation to Scope 2 GHG emissions',
    name_es:
      'Porcentaje de instrumentos contractuales utilizados para la venta y compra de energía combinados con atributos sobre generación de energía en relación con las emisiones de GEI de Alcance 2',
    name_pt:
      'Percentual de instrumentos contratuais utilizados para venda e compra de energia agrupados com atributos sobre geração de energia em relação às emissões de GEE Escopo 2'
  },
  {
    id: '9c877f25-25fc-4124-952d-671214c42abd',
    name_dcycle:
      'percentage_of_contractual_instruments_used_for_sale_and_purchase_of_unbundled_energy_attribute_claims_in_relation_to_scope_2_ghg_emissions',
    datapoint_id: 'E1-6_22',
    esg_category: 'environmental',
    name_en:
      'Percentage of contractual instruments used for sale and purchase of unbundled energy attribute claims in relation to Scope 2 GHG emissions',
    name_es:
      'Porcentaje de instrumentos contractuales utilizados para la venta y compra de reclamaciones de atributos de energía desagregados en relación con las emisiones de GEI de Alcance 2',
    name_pt:
      'Percentual de instrumentos contratuais usados ​​para venda e compra de reivindicações de atributos de energia desagregados em relação às emissões de GEE de Escopo 2'
  },
  {
    id: '43d05274-7c52-4bde-9aa1-4d514c8a668d',
    name_dcycle:
      'disclosure_of_types_of_contractual_instruments_used_for_sale_and_purchase_of_energy_bundled_with_attributes_about_energy_generation_or_for_unbundled_energy_attribute_claims',
    datapoint_id: 'E1-6_23',
    esg_category: 'environmental',
    name_en:
      'Disclosure of types of contractual instruments used for sale and purchase of energy bundled with attributes about energy generation or for unbundled energy attribute claims',
    name_es:
      'Divulgación de tipos de instrumentos contractuales utilizados para la venta y compra de energía agrupada con atributos sobre generación de energía o para reclamaciones de atributos de energía desagregados.',
    name_pt:
      'Divulgação dos tipos de instrumentos contratuais utilizados para venda e compra de energia agrupada com atributos sobre geração de energia ou para reivindicações de atributos de energia desagregados'
  },
  {
    id: '946ca024-0108-4db4-a715-bd76dd202df8',
    name_dcycle:
      'biogenic_emissions_of_co2_from_combustion_or_bio-degradation_of_biomass_not_included_in_scope_2_ghg_emissions',
    datapoint_id: 'E1-6_24',
    esg_category: 'environmental',
    name_en:
      'Biogenic emissions of CO2 from combustion or bio-degradation of biomass not included in Scope 2 GHG emissions',
    name_es:
      'Emisiones biogénicas de CO2 procedentes de la combustión o biodegradación de biomasa no incluidas en las emisiones de GEI de Alcance 2',
    name_pt:
      'Emissões biogênicas de CO2 provenientes da combustão ou biodegradação de biomassa não incluídas nas emissões de GEE do Escopo 2'
  },
  {
    id: '120e3270-8e83-4599-8972-73fb7366d276',
    name_dcycle: 'percentage_of_ghg_scope_3_calculated_using_primary_data',
    datapoint_id: 'E1-6_25',
    esg_category: 'environmental',
    name_en: 'Percentage of GHG Scope 3 calculated using primary data',
    name_es: 'Porcentaje de GEI de Alcance 3 calculado utilizando datos primarios',
    name_pt: 'Porcentagem de GEE Escopo 3 calculada usando dados primários'
  },
  {
    id: '466445d3-53c2-462a-8412-3c80963d92a8',
    name_dcycle: 'disclosure_of_why_scope_3_ghg_emissions_category_has_been_excluded',
    datapoint_id: 'E1-6_26',
    esg_category: 'environmental',
    name_en: 'Disclosure of why Scope 3 GHG emissions category has been excluded',
    name_es: 'Divulgación de por qué se ha excluido la categoría de emisiones de GEI de Alcance 3',
    name_pt:
      'Divulgação do motivo pelo qual a categoria de emissões de GEE do Escopo 3 foi excluída'
  },
  {
    id: '978bea6d-0552-4b12-881e-4a5cc69f9634',
    name_dcycle: 'list_of_scope_3_ghg_emissions_categories_included_in_inventory',
    datapoint_id: 'E1-6_27',
    esg_category: 'environmental',
    name_en: 'List of Scope 3 GHG emissions categories included in inventory',
    name_es: 'Lista de categorías de emisiones de GEI de Alcance 3 incluidas en el inventario',
    name_pt: 'Lista de categorias de emissões de GEE de Escopo 3 incluídas no inventário'
  },
  {
    id: 'b535cdcb-87ba-4b70-8f5e-b1d7cfde8955',
    name_dcycle:
      'biogenic_emissions_of_co2_from_combustion_or_bio-degradation_of_biomass_that_occur_in_value_chain_not_included_in_scope_3_ghg_emissions',
    datapoint_id: 'E1-6_28',
    esg_category: 'environmental',
    name_en:
      'Biogenic emissions of CO2 from combustion or bio-degradation of biomass that occur in value chain not included in Scope 3 GHG emissions',
    name_es:
      'Emisiones biogénicas de CO2 procedentes de la combustión o biodegradación de biomasa que se producen en la cadena de valor no incluidas en las emisiones de GEI de Alcance 3',
    name_pt:
      'Emissões biogênicas de CO2 provenientes da combustão ou biodegradação de biomassa que ocorrem na cadeia de valor não incluídas nas emissões de GEE do Escopo 3'
  },
  {
    id: 'de2846ec-dc04-43fa-849b-3ad8e87bd4db',
    name_dcycle:
      'disclosure_of_reporting_boundaries_considered_and_calculation_methods_for_estimating_scope_3_ghg_emissions',
    datapoint_id: 'E1-6_29',
    esg_category: 'environmental',
    name_en:
      'Disclosure of reporting boundaries considered and calculation methods for estimating Scope 3 GHG emissions',
    name_es:
      'Divulgación de los límites de presentación de informes considerados y métodos de cálculo para estimar las emisiones de GEI de Alcance 3',
    name_pt:
      'Divulgação dos limites de relato considerados e métodos de cálculo para estimar as emissões de GEE de Escopo 3'
  },
  {
    id: 'df6d410e-d955-4805-b68d-bf31ba9ba6c3',
    name_dcycle: 'ghg_emissions_intensity,_location-based_(total_ghg_emissions_per_net_revenue)',
    datapoint_id: 'E1-6_30',
    esg_category: 'environmental',
    name_en: 'GHG emissions intensity, location-based (total GHG emissions per net revenue)',
    name_es:
      'Intensidad de las emisiones de GEI, basada en la ubicación (emisiones totales de GEI por ingreso neto)',
    name_pt:
      'Intensidade de emissões de GEE, com base na localização (total de emissões de GEE por receita líquida)'
  },
  {
    id: '29bce266-21e2-4d16-a9d2-0eb1361e1249',
    name_dcycle: 'ghg_emissions_intensity,_market-based_(total_ghg_emissions_per_net_revenue)',
    datapoint_id: 'E1-6_31',
    esg_category: 'environmental',
    name_en: 'GHG emissions intensity, market-based (total GHG emissions per net revenue)',
    name_es:
      'Intensidad de las emisiones de GEI, basada en el mercado (emisiones totales de GEI por ingreso neto)',
    name_pt:
      'Intensidade de emissões de GEE, com base no mercado (total de emissões de GEE por receita líquida)'
  },
  {
    id: 'fb2e6d01-58fc-4bb2-bea8-bd454ecf8405',
    name_dcycle:
      'disclosure_of_reconciliation_to_financial_statements_of_net_revenue_used_for_calculation_of_ghg_emissions_intensity',
    datapoint_id: 'E1-6_32',
    esg_category: 'environmental',
    name_en:
      'Disclosure of reconciliation to financial statements of net revenue used for calculation of GHG emissions intensity',
    name_es:
      'Divulgación de la conciliación con los estados financieros de los ingresos netos utilizados para el cálculo de la intensidad de las emisiones de GEI',
    name_pt:
      'Divulgação da conciliação com as demonstrações financeiras da receita líquida utilizada para cálculo da intensidade de emissões de GEE'
  },
  {
    id: 'edde6278-f83e-49da-9b42-80cdd35530a3',
    name_dcycle: 'net_revenue',
    datapoint_id: 'E1-6_33',
    esg_category: 'environmental',
    name_en: 'Net revenue',
    name_es: 'Ingresos netos',
    name_pt: 'Receita líquida'
  },
  {
    id: '2d666f15-5614-45b0-a83f-8e27314f325d',
    name_dcycle: 'net_revenue_used_to_calculate_ghg_intensity',
    datapoint_id: 'E1-6_34',
    esg_category: 'environmental',
    name_en: 'Net revenue used to calculate GHG intensity',
    name_es: 'Ingresos netos utilizados para calcular la intensidad de GEI',
    name_pt: 'Receita líquida usada para calcular a intensidade de GEE'
  },
  {
    id: '34d644d7-7105-4132-a6dc-dbdee3e99c41',
    name_dcycle: 'net_revenue_other_than_used_to_calculate_ghg_intensity',
    datapoint_id: 'E1-6_35',
    esg_category: 'environmental',
    name_en: 'Net revenue other than used to calculate GHG intensity',
    name_es: 'Ingresos netos distintos de los utilizados para calcular la intensidad de GEI',
    name_pt: 'Receita líquida que não seja usada para calcular a intensidade de GEE'
  },
  {
    id: 'b6874fd3-9ef1-4210-8a05-729e2fdfef84',
    name_dcycle:
      'disclosure_of_ghg_removals_and_storage_resulting_from_projects_developed_in_own_operations_or_contributed_to_in_upstream_and_downstream_value_chain',
    datapoint_id: 'E1-7_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of GHG removals and storage resulting from projects developed in own operations or contributed to in upstream and downstream value chain',
    name_es:
      'Divulgación de remociones y almacenamiento de GEI resultantes de proyectos desarrollados en operaciones propias o en los que se ha contribuido en la cadena de valor upstream y downstream.',
    name_pt:
      'Divulgação de remoções e armazenamento de GEE resultantes de projetos desenvolvidos em operações próprias ou para os quais contribuiu na cadeia de valor upstream e downstream'
  },
  {
    id: 'd559e6f8-74e0-4e17-be45-3e3da040bc90',
    name_dcycle:
      'disclosure_of_ghg_emission_reductions_or_removals_from_climate_change_mitigation_projects_outside_value_chain_financed_or_to_be_financed_through_any_purchase_of_carbon_credits',
    datapoint_id: 'E1-7_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of GHG emission reductions or removals from climate change mitigation projects outside value chain financed or to be financed through any purchase of carbon credits',
    name_es:
      'Divulgación de reducciones o eliminaciones de emisiones de GEI de proyectos de mitigación del cambio climático fuera de la cadena de valor financiados o por financiar mediante cualquier compra de créditos de carbono.',
    name_pt:
      'Divulgação de reduções ou remoções de emissões de GEE provenientes de projetos de mitigação das alterações climáticas fora da cadeia de valor financiados ou a serem financiados através de qualquer compra de créditos de carbono'
  },
  {
    id: 'a0ff8e24-da6d-4c29-812b-bceeba849184',
    name_dcycle: 'removals_and_carbon_credits_are_used',
    datapoint_id: 'E1-7_03',
    esg_category: 'environmental',
    name_en: 'Removals and carbon credits are used',
    name_es: 'Se utilizan eliminaciones y créditos de carbono.',
    name_pt: 'Remoções e créditos de carbono são usados'
  },
  {
    id: '00f46ccc-f91b-45b8-b1b6-4d4df2eed4d5',
    name_dcycle:
      'ghg_removals_and_storage_activity_by_undertaking_scope_(breakdown_by_own_operations_and_value_chain)_and_by_removal_and_storage_activity',
    datapoint_id: 'E1-7_04',
    esg_category: 'environmental',
    name_en:
      'GHG Removals and storage Activity by undertaking scope (breakdown by own operations and value chain) and by removal and storage activity',
    name_es:
      'Remoción y almacenamiento de GEI Actividad por alcance de la empresa (desglose por operaciones propias y cadena de valor) y por actividad de remoción y almacenamiento',
    name_pt:
      'Atividade de remoção e armazenamento de GEE por escopo do empreendimento (decomposição por operações próprias e cadeia de valor) e por atividade de remoção e armazenamento'
  },
  {
    id: 'fd0fd9e3-bdbf-4393-aa1c-e79495a11f1f',
    name_dcycle: 'total_ghg_removals_and_storage',
    datapoint_id: 'E1-7_05',
    esg_category: 'environmental',
    name_en: 'Total GHG removals and storage',
    name_es: 'Eliminación y almacenamiento total de GEI',
    name_pt: 'Total de remoções e armazenamento de GEE'
  },
  {
    id: '2075b640-4a00-45ee-94d8-3af929237db9',
    name_dcycle: 'ghg_emissions_associated_with_removal_activity',
    datapoint_id: 'E1-7_06',
    esg_category: 'environmental',
    name_en: 'GHG emissions associated with removal activity',
    name_es: 'Emisiones de GEI asociadas con la actividad de remoción',
    name_pt: 'Emissões de GEE associadas à atividade de remoção'
  },
  {
    id: '019159dd-9821-4053-b1c4-e9d2678904e4',
    name_dcycle: 'reversals',
    datapoint_id: 'E1-7_07',
    esg_category: 'environmental',
    name_en: 'Reversals',
    name_es: 'Reversiones',
    name_pt: 'Reversões'
  },
  {
    id: '58586aa0-dc56-4476-bafc-7f6c04d01547',
    name_dcycle:
      'disclosure_of_calculation_assumptions,_methodologies_and_frameworks_applied_(ghg_removals_and_storage)',
    datapoint_id: 'E1-7_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of calculation assumptions, methodologies and frameworks applied (GHG removals and storage)',
    name_es:
      'Divulgación de supuestos de cálculo, metodologías y marcos aplicados (eliminación y almacenamiento de GEI)',
    name_pt:
      'Divulgação das premissas de cálculo, metodologias e frameworks aplicados (remoções e armazenamento de GEE)'
  },
  {
    id: '7943aa35-44b5-4c21-990f-d20e8ced3630',
    name_dcycle:
      'removal_activity_has_been_converted_into_carbon_credits_and_sold_on_to_other_parties_on_voluntary_market',
    datapoint_id: 'E1-7_09',
    esg_category: 'environmental',
    name_en:
      'Removal activity has been converted into carbon credits and sold on to other parties on voluntary market',
    name_es:
      'La actividad de eliminación se ha convertido en créditos de carbono y se ha vendido a otras partes en el mercado voluntario.',
    name_pt:
      'A atividade de remoção foi convertida em créditos de carbono e vendida a outras partes no mercado voluntário'
  },
  {
    id: 'ab6dab7c-22b6-4eaf-ace4-abfa3e6c1f72',
    name_dcycle:
      'total_amount_of_carbon_credits_outside_value_chain_that_are_verified_against_recognised_quality_standards_and_cancelled',
    datapoint_id: 'E1-7_10',
    esg_category: 'environmental',
    name_en:
      'Total amount of carbon credits outside value chain that are verified against recognised quality standards and cancelled',
    name_es:
      'Cantidad total de créditos de carbono fuera de la cadena de valor que se verifican con estándares de calidad reconocidos y se cancelan',
    name_pt:
      'Quantidade total de créditos de carbono fora da cadeia de valor que são verificados em relação a padrões de qualidade reconhecidos e cancelados'
  },
  {
    id: 'eaebeb7c-ab97-4bbd-8315-b6b7fe76684d',
    name_dcycle:
      'total_amount_of_carbon_credits_outside_value_chain_planned_to_be_cancelled_in_future',
    datapoint_id: 'E1-7_11',
    esg_category: 'environmental',
    name_en: 'Total amount of carbon credits outside value chain planned to be cancelled in future',
    name_es:
      'Cantidad total de créditos de carbono fuera de la cadena de valor que se planea cancelar en el futuro',
    name_pt:
      'Quantidade total de créditos de carbono fora da cadeia de valor prevista para ser cancelada no futuro'
  },
  {
    id: '1e42b320-e612-494b-a1d1-028920a6d734',
    name_dcycle: 'disclosure_of_extent_of_use_and_quality_criteria_used_for_carbon_credits',
    datapoint_id: 'E1-7_12',
    esg_category: 'environmental',
    name_en: 'Disclosure of extent of use and quality criteria used for carbon credits',
    name_es:
      'Divulgación del alcance del uso y los criterios de calidad utilizados para los créditos de carbono.',
    name_pt:
      'Divulgação da extensão do uso e critérios de qualidade utilizados para créditos de carbono'
  },
  {
    id: 'a75c76d9-a7d5-4a7d-a3d6-69502904fd46',
    name_dcycle: 'percentage_of_reduction_projects',
    datapoint_id: 'E1-7_13',
    esg_category: 'environmental',
    name_en: 'Percentage of reduction projects',
    name_es: 'Porcentaje de proyectos de reducción',
    name_pt: 'Porcentagem de projetos de redução'
  },
  {
    id: '50391d6d-4be3-4afc-b262-2d2b8d5cd766',
    name_dcycle: 'percentage_of_removal_projects',
    datapoint_id: 'E1-7_14',
    esg_category: 'environmental',
    name_en: 'Percentage of removal projects',
    name_es: 'Porcentaje de proyectos de remoción',
    name_pt: 'Porcentagem de projetos de remoção'
  },
  {
    id: '1618ecfc-96af-459e-91c6-7204bb4aaa16',
    name_dcycle: 'type_of_carbon_credits_from_removal_projects',
    datapoint_id: 'E1-7_15',
    esg_category: 'environmental',
    name_en: 'Type of carbon credits from removal projects',
    name_es: 'Tipo de créditos de carbono de proyectos de eliminación',
    name_pt: 'Tipo de créditos de carbono provenientes de projetos de remoção'
  },
  {
    id: '60d3108a-ec41-41f2-a280-aa784e1b20ef',
    name_dcycle: 'percentage_for_recognised_quality_standard',
    datapoint_id: 'E1-7_16',
    esg_category: 'environmental',
    name_en: 'Percentage for recognised quality standard',
    name_es: 'Porcentaje para estándar de calidad reconocido',
    name_pt: 'Porcentagem para padrão de qualidade reconhecido'
  },
  {
    id: 'b615f2eb-0b00-4ead-8251-dcc2d4b94e8a',
    name_dcycle: 'percentage_issued_from_projects_in_european_union',
    datapoint_id: 'E1-7_17',
    esg_category: 'environmental',
    name_en: 'Percentage issued from projects in European Union',
    name_es: 'Porcentaje emitido por proyectos en la Unión Europea',
    name_pt: 'Percentagem emitida por projetos na União Europeia'
  },
  {
    id: '31c39a00-dc88-49ce-8807-227c3e1dcbbc',
    name_dcycle: 'percentage_that_qualifies_as_corresponding_adjustment',
    datapoint_id: 'E1-7_18',
    esg_category: 'environmental',
    name_en: 'Percentage that qualifies as corresponding adjustment',
    name_es: 'Porcentaje que califica como ajuste correspondiente',
    name_pt: 'Porcentagem que se qualifica como ajuste correspondente'
  },
  {
    id: '8ccab522-b4d6-4363-b056-5e2f2dadfdb8',
    name_dcycle: 'date_when_carbon_credits_outside_value_chain_are_planned_to_be_cancelled',
    datapoint_id: 'E1-7_19',
    esg_category: 'environmental',
    name_en: 'Date when carbon credits outside value chain are planned to be cancelled',
    name_es:
      'Fecha en la que se prevé cancelar los créditos de carbono fuera de la cadena de valor',
    name_pt: 'Data em que se planeja cancelar os créditos de carbono fora da cadeia de valor'
  },
  {
    id: 'b142ca33-2bbb-4343-9443-906564d0140a',
    name_dcycle:
      'explanation_of_scope,_methodologies_and_frameworks_applied_and_how_residual_ghg_emissions_are_intended_to_be_neutralised',
    datapoint_id: 'E1-7_20',
    esg_category: 'environmental',
    name_en:
      'Explanation of scope, methodologies and frameworks applied and how residual GHG emissions are intended to be neutralised',
    name_es:
      'Explicación del alcance, las metodologías y los marcos aplicados y cómo se pretende neutralizar las emisiones residuales de GEI.',
    name_pt:
      'Explicação do escopo, metodologias e estruturas aplicadas e como as emissões residuais de GEE devem ser neutralizadas'
  },
  {
    id: '4874ba09-629d-461b-ac66-4b7a8abd04b2',
    name_dcycle:
      'public_claims_of_ghg_neutrality_that_involve_use_of_carbon_credits_have_been_made',
    datapoint_id: 'E1-7_21',
    esg_category: 'environmental',
    name_en: 'Public claims of GHG neutrality that involve use of carbon credits have been made',
    name_es:
      'Se han hecho declaraciones públicas de neutralidad de GEI que implican el uso de créditos de carbono.',
    name_pt:
      'Foram feitas reivindicações públicas de neutralidade em termos de GEE que envolvem o uso de créditos de carbono'
  },
  {
    id: '5312076c-4755-49df-adf2-7c18c7afb561',
    name_dcycle:
      'public_claims_of_ghg_neutrality_that_involve_use_of_carbon_credits_are_accompanied_by_ghg_emission_reduction_targets',
    datapoint_id: 'E1-7_22',
    esg_category: 'environmental',
    name_en:
      'Public claims of GHG neutrality that involve use of carbon credits are accompanied by GHG emission reduction targets',
    name_es:
      'Las afirmaciones públicas de neutralidad de GEI que implican el uso de créditos de carbono van acompañadas de objetivos de reducción de emisiones de GEI.',
    name_pt:
      'As reivindicações públicas de neutralidade de GEE que envolvem o uso de créditos de carbono são acompanhadas por metas de redução de emissões de GEE'
  },
  {
    id: '1514f68d-f9ae-4a3e-af95-e1992e6b18c7',
    name_dcycle:
      'claims_of_ghg_neutrality_and_reliance_on_carbon_credits_neither_impede_nor_reduce_achievement_of_ghg_emission_reduction_targets_or_net_zero_target',
    datapoint_id: 'E1-7_23',
    esg_category: 'environmental',
    name_en:
      'Claims of GHG neutrality and reliance on carbon credits neither impede nor reduce achievement of GHG emission reduction targets or net zero target',
    name_es:
      'Las afirmaciones de neutralidad de GEI y dependencia de los créditos de carbono no impiden ni reducen el logro de los objetivos de reducción de emisiones de GEI o del objetivo de cero emisiones netas.',
    name_pt:
      'As reivindicações de neutralidade de GEE e a dependência de créditos de carbono não impedem nem reduzem o cumprimento das metas de redução de emissões de GEE ou da meta líquida zero'
  },
  {
    id: '82813e9d-519e-4842-96b8-b6fa11b87069',
    name_dcycle:
      'explanation_of_whether_and_how_public_claims_of_ghg_neutrality_that_involve_use_of_carbon_credits_are_accompanied_by_ghg_emission_reduction_targets_and_how_claims_of_ghg_neutrality_and_reliance_on_carbon_credits_neither_impede_nor_reduce_achievement_of_ghg_emission_reduction_targets_or_net_zero_target',
    datapoint_id: 'E1-7_24',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how public claims of GHG neutrality that involve use of carbon credits are accompanied by GHG emission reduction targets and how claims of GHG neutrality and reliance on carbon credits neither impede nor reduce achievement of GHG emission reduction targets or net zero target',
    name_es:
      'Explicación de si las afirmaciones públicas de neutralidad de GEI que implican el uso de créditos de carbono van acompañadas de objetivos de reducción de emisiones de GEI y de qué manera, y cómo las afirmaciones de neutralidad de GEI y la dependencia de los créditos de carbono no impiden ni reducen el logro de los objetivos de reducción de emisiones de GEI o el objetivo de cero emisiones netas.',
    name_pt:
      'Explicação sobre se e como as reivindicações públicas de neutralidade de GEE que envolvem o uso de créditos de carbono são acompanhadas por metas de redução de emissões de GEE e como as reivindicações de neutralidade de GEE e a dependência de créditos de carbono não impedem nem reduzem o cumprimento das metas de redução de emissões de GEE ou da meta líquida zero'
  },
  {
    id: '3cb65de9-870c-4833-be34-2d83dcbcaca2',
    name_dcycle: 'explanation_of_credibility_and_integrity_of_carbon_credits_used',
    datapoint_id: 'E1-7_25',
    esg_category: 'environmental',
    name_en: 'Explanation of credibility and integrity of carbon credits used',
    name_es: 'Explicación de la credibilidad e integridad de los créditos de carbono utilizados.',
    name_pt: 'Explicação da credibilidade e integridade dos créditos de carbono utilizados'
  },
  {
    id: '64aa384c-cab5-4cf9-b9bb-0606a741f880',
    name_dcycle: 'carbon_pricing_scheme_by_type',
    datapoint_id: 'E1-8_01',
    esg_category: 'environmental',
    name_en: 'Carbon pricing scheme by type',
    name_es: 'Esquema de fijación de precios del carbono por tipo',
    name_pt: 'Esquema de precificação de carbono por tipo'
  },
  {
    id: 'c65cfec4-9499-443e-aa41-455d9b63beeb',
    name_dcycle: 'type_of_internal_carbon_pricing_scheme',
    datapoint_id: 'E1-8_02',
    esg_category: 'environmental',
    name_en: 'Type of internal carbon pricing scheme',
    name_es: 'Tipo de sistema interno de fijación de precios del carbono',
    name_pt: 'Tipo de esquema interno de precificação de carbono'
  },
  {
    id: '019e7d84-f24d-4ed2-a5c3-68c586ff4542',
    name_dcycle: 'description_of_specific_scope_of_application_of_carbon_pricing_scheme',
    datapoint_id: 'E1-8_03',
    esg_category: 'environmental',
    name_en: 'Description of specific scope of application of carbon pricing scheme',
    name_es:
      'Descripción del ámbito de aplicación específico del sistema de fijación de precios del carbono',
    name_pt: 'Descrição do âmbito específico de aplicação do regime de fixação de preços do carbono'
  },
  {
    id: '6a028878-5e3e-4fec-8e37-e644f58850f1',
    name_dcycle: 'carbon_price_applied_for_each_metric_tonne_of_greenhouse_gas_emission',
    datapoint_id: 'E1-8_04',
    esg_category: 'environmental',
    name_en: 'Carbon price applied for each metric tonne of greenhouse gas emission',
    name_es:
      'Precio del carbono aplicado por cada tonelada métrica de emisión de gases de efecto invernadero',
    name_pt:
      'Preço do carbono aplicado para cada tonelada métrica de emissão de gases de efeito estufa'
  },
  {
    id: '5f5ae0b8-07ef-44f6-b8a5-4b0e69e58bbc',
    name_dcycle: 'description_of_critical_assumptions_made_to_determine_carbon_price_applied',
    datapoint_id: 'E1-8_05',
    esg_category: 'environmental',
    name_en: 'Description of critical assumptions made to determine carbon price applied',
    name_es:
      'Descripción de los supuestos críticos hechos para determinar el precio del carbono aplicado',
    name_pt: 'Descrição das premissas críticas feitas para determinar o preço do carbono aplicado'
  },
  {
    id: 'c5e004d0-43ec-4eac-ab18-4b4d72ca93e0',
    name_dcycle:
      'percentage_of_gross_scope_1_greenhouse_gas_emissions_covered_by_internal_carbon_pricing_scheme',
    datapoint_id: 'E1-8_06',
    esg_category: 'environmental',
    name_en:
      'Percentage of gross Scope 1 greenhouse gas emissions covered by internal carbon pricing scheme',
    name_es:
      'Porcentaje de emisiones brutas de gases de efecto invernadero de Alcance 1 cubiertas por el sistema interno de fijación de precios del carbono',
    name_pt:
      'Percentagem das emissões brutas de gases com efeito de estufa de Âmbito 1 cobertas pelo regime interno de precificação do carbono'
  },
  {
    id: 'a734564c-ae3c-4e7d-8de3-a90e0f07d677',
    name_dcycle:
      'percentage_of_gross_scope_2_greenhouse_gas_emissions_covered_by_internal_carbon_pricing_scheme',
    datapoint_id: 'E1-8_07',
    esg_category: 'environmental',
    name_en:
      'Percentage of gross Scope 2 greenhouse gas emissions covered by internal carbon pricing scheme',
    name_es:
      'Porcentaje de emisiones brutas de gases de efecto invernadero de alcance 2 cubiertas por el sistema interno de fijación de precios del carbono',
    name_pt:
      'Percentagem das emissões brutas de gases com efeito de estufa de Âmbito 2 cobertas pelo regime interno de precificação do carbono'
  },
  {
    id: '0df393bc-88ab-4c79-b141-49f355510114',
    name_dcycle:
      'percentage_of_gross_scope_3_greenhouse_gas_emissions_covered_by_internal_carbon_pricing_scheme',
    datapoint_id: 'E1-8_08',
    esg_category: 'environmental',
    name_en:
      'Percentage of gross Scope 3 greenhouse gas emissions covered by internal carbon pricing scheme',
    name_es:
      'Porcentaje de emisiones brutas de gases de efecto invernadero de Alcance 3 cubiertas por el sistema interno de fijación de precios del carbono',
    name_pt:
      'Percentagem das emissões brutas de gases com efeito de estufa de Âmbito 3 cobertas pelo regime interno de precificação do carbono'
  },
  {
    id: '124f003f-9133-4d0c-bc15-4cd7a923c9d9',
    name_dcycle:
      'disclosure_of_whether_and_how_carbon_price_used_in_internal_carbon_pricing_scheme_is_consistent_with_carbon_price_used_in_financial_statements',
    datapoint_id: 'E1-8_09',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how carbon price used in internal carbon pricing scheme is consistent with carbon price used in financial statements',
    name_es:
      'Divulgación de si el precio del carbono utilizado en el sistema interno de fijación de precios del carbono es coherente con el precio del carbono utilizado en los estados financieros y de qué manera.',
    name_pt:
      'Divulgação sobre se e como o preço do carbono utilizado no esquema interno de precificação do carbono é consistente com o preço do carbono utilizado nas demonstrações financeiras'
  },
  {
    id: '7c9d603e-50da-4806-b7d2-7a1a2713205e',
    name_dcycle:
      'assets_at_material_physical_risk_before_considering_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_01',
    esg_category: 'environmental',
    name_en:
      'Assets at material physical risk before considering climate change adaptation actions',
    name_es:
      'Activos en riesgo físico material antes de considerar acciones de adaptación al cambio climático',
    name_pt:
      'Ativos em risco físico material antes de considerar ações de adaptação às alterações climáticas'
  },
  {
    id: 'c4a85fe8-9d62-4ab2-9674-799d521cc063',
    name_dcycle:
      'assets_at_acute_material_physical_risk_before_considering_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_02',
    esg_category: 'environmental',
    name_en:
      'Assets at acute material physical risk before considering climate change adaptation actions',
    name_es:
      'Activos en riesgo físico material agudo antes de considerar acciones de adaptación al cambio climático',
    name_pt:
      'Ativos em risco físico material agudo antes de considerar ações de adaptação às alterações climáticas'
  },
  {
    id: '6f6754d1-c1a3-4999-8efe-727081ee5d42',
    name_dcycle:
      'assets_at_chronic_material_physical_risk_before_considering_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_03',
    esg_category: 'environmental',
    name_en:
      'Assets at chronic material physical risk before considering climate change adaptation actions',
    name_es:
      'Activos en riesgo físico material crónico antes de considerar acciones de adaptación al cambio climático',
    name_pt:
      'Ativos em risco físico material crónico antes de considerar ações de adaptação às alterações climáticas'
  },
  {
    id: '73ebcd8a-0191-4ea0-bcb1-3103a4f536fa',
    name_dcycle:
      'percentage_of_assets_at_material_physical_risk_before_considering_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_04',
    esg_category: 'environmental',
    name_en:
      'Percentage of assets at material physical risk before considering climate change adaptation actions',
    name_es:
      'Porcentaje de activos en riesgo físico material antes de considerar acciones de adaptación al cambio climático',
    name_pt:
      'Percentagem de ativos em risco físico material antes de serem consideradas ações de adaptação às alterações climáticas'
  },
  {
    id: '3441f01b-0833-4d18-ac82-68398e416f79',
    name_dcycle: 'disclosure_of_location_of_significant_assets_at_material_physical_risk',
    datapoint_id: 'E1-9_05',
    esg_category: 'environmental',
    name_en: 'Disclosure of location of significant assets at material physical risk',
    name_es: 'Divulgación de la ubicación de activos importantes con riesgo físico importante',
    name_pt: 'Divulgação da localização de ativos significativos com risco físico material'
  },
  {
    id: 'd60f10a9-85d9-4cf4-8b35-c1d09f6a2503',
    name_dcycle:
      'disclosure_of_location_of_its_significant_assets_at_material_physical_risk_(disaggregated_by_nuts_codes)',
    datapoint_id: 'E1-9_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of location of its significant assets at material physical risk (disaggregated by NUTS codes)',
    name_es:
      'Divulgación de la ubicación de sus activos importantes con riesgo físico importante (desagregados por códigos NUTS)',
    name_pt:
      'Divulgação da localização dos seus ativos significativos em risco físico material (desagregados por códigos NUTS)'
  },
  {
    id: '46cf52b7-4b0c-49db-95b3-8f38a2c2545e',
    name_dcycle:
      'percentage_of_assets_at_material_physical_risk_addressed_by_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_07',
    esg_category: 'environmental',
    name_en:
      'Percentage of assets at material physical risk addressed by climate change adaptation actions',
    name_es:
      'Porcentaje de activos en riesgo físico material abordados por acciones de adaptación al cambio climático',
    name_pt:
      'Percentagem de ativos em risco físico material abordados por ações de adaptação às alterações climáticas'
  },
  {
    id: 'ae8ab146-bfee-4544-ad05-b505bcfa4933',
    name_dcycle: 'net_revenue_from_business_activities_at_material_physical_risk',
    datapoint_id: 'E1-9_08',
    esg_category: 'environmental',
    name_en: 'Net revenue from business activities at material physical risk',
    name_es: 'Ingresos netos de actividades comerciales con riesgo físico material',
    name_pt: 'Receita líquida de atividades comerciais com risco físico material'
  },
  {
    id: '99103815-6871-4aa1-84c3-ed98653b5193',
    name_dcycle: 'percentage_of_net_revenue_from_business_activities_at_material_physical_risk',
    datapoint_id: 'E1-9_09',
    esg_category: 'environmental',
    name_en: 'Percentage of net revenue from business activities at material physical risk',
    name_es: 'Porcentaje de ingresos netos de actividades comerciales con riesgo físico material',
    name_pt: 'Percentagem da receita líquida de atividades empresariais com risco físico material'
  },
  {
    id: 'b42dc036-5ec9-49ab-83fa-d9b31fd5337d',
    name_dcycle:
      'disclosure_of_whether_and_how_anticipated_financial_effects_for_assets_and_business_activities_at_material_physical_risk_have_been_assessed',
    datapoint_id: 'E1-9_10',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how anticipated financial effects for assets and business activities at material physical risk have been assessed',
    name_es:
      'Divulgación de si se han evaluado y cómo se han evaluado los efectos financieros previstos para los activos y actividades comerciales con riesgo físico importante.',
    name_pt:
      'Divulgação sobre se e como os efeitos financeiros previstos para ativos e atividades comerciais com risco físico material foram avaliados'
  },
  {
    id: '906a27e5-3c6d-4b15-90b2-66e809922929',
    name_dcycle:
      'disclosure_of_whether_and_how_assessment_of_assets_and_business_activities_considered_to_be_at_material_physical_risk_relies_on_or_is_part_of_process_to_determine_material_physical_risk_and_to_determine_climate_scenarios',
    datapoint_id: 'E1-9_11',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how assessment of assets and business activities considered to be at material physical risk relies on or is part of process to determine material physical risk and to determine climate scenarios',
    name_es:
      'Divulgación de si y cómo la evaluación de activos y actividades comerciales consideradas en riesgo físico importante depende o es parte del proceso para determinar el riesgo físico importante y para determinar escenarios climáticos.',
    name_pt:
      'Divulgação sobre se e como a avaliação de ativos e atividades comerciais consideradas de risco físico material depende ou faz parte do processo para determinar o risco físico material e para determinar cenários climáticos'
  },
  {
    id: '38e39ef8-20ab-4d9d-b166-2fbc518f9f57',
    name_dcycle:
      'disclosure_of_risk_factors_for_net_revenue_from_business_activities_at_material_physical_risk',
    datapoint_id: 'E1-9_12',
    esg_category: 'environmental',
    name_en:
      'Disclosure of risk factors for net revenue from business activities at material physical risk',
    name_es:
      'Divulgación de factores de riesgo para ingresos netos de actividades comerciales con riesgo físico material',
    name_pt:
      'Divulgação de fatores de risco para receita líquida de atividades empresariais com risco físico material'
  },
  {
    id: 'c0a33782-4206-4718-916b-dc74097ca646',
    name_dcycle:
      'disclosure_of_magnitude_of_anticipated_financial_effects_in_terms_of_margin_erosion_for_business_activities_at_material_physical_risk',
    datapoint_id: 'E1-9_13',
    esg_category: 'environmental',
    name_en:
      'Disclosure of magnitude of anticipated financial effects in terms of margin erosion for business activities at material physical risk',
    name_es:
      'Divulgación de la magnitud de los efectos financieros previstos en términos de erosión de márgenes para actividades comerciales con riesgo físico importante',
    name_pt:
      'Divulgação da magnitude dos efeitos financeiros previstos em termos de erosão de margens para atividades comerciais com risco físico material'
  },
  {
    id: '2671a73c-dff1-49e1-ac5a-a3ce7b81676b',
    name_dcycle: 'assets_at_material_transition_risk_before_considering_climate_mitigation_actions',
    datapoint_id: 'E1-9_14',
    esg_category: 'environmental',
    name_en: 'Assets at material transition risk before considering climate mitigation actions',
    name_es:
      'Activos en riesgo de transición material antes de considerar acciones de mitigación climática',
    name_pt:
      'Ativos em risco de transição material antes de considerar ações de mitigação climática'
  },
  {
    id: '7ff0d7ec-c2b1-4e1e-9544-7fdad7675ad9',
    name_dcycle:
      'percentage_of_assets_at_material_transition_risk_before_considering_climate_mitigation_actions',
    datapoint_id: 'E1-9_15',
    esg_category: 'environmental',
    name_en:
      'Percentage of assets at material transition risk before considering climate mitigation actions',
    name_es:
      'Porcentaje de activos en riesgo de transición material antes de considerar acciones de mitigación climática',
    name_pt:
      'Percentagem de ativos em risco de transição material antes de considerar ações de mitigação climática'
  },
  {
    id: 'b415603d-6f09-4973-904d-eeaa53ea55de',
    name_dcycle:
      'percentage_of_assets_at_material_transition_risk_addressed_by_climate_change_mitigation_actions',
    datapoint_id: 'E1-9_16',
    esg_category: 'environmental',
    name_en:
      'Percentage of assets at material transition risk addressed by climate change mitigation actions',
    name_es:
      'Porcentaje de activos con riesgo de transición material abordados por acciones de mitigación del cambio climático',
    name_pt:
      'Percentagem de ativos em risco material de transição abordados por ações de mitigação das alterações climáticas'
  },
  {
    id: '487485f3-12ed-49aa-b292-af5043e1725a',
    name_dcycle: 'total_carrying_amount_of_real_estate_assets_by_energy_efficiency_classes',
    datapoint_id: 'E1-9_17',
    esg_category: 'environmental',
    name_en: 'Total carrying amount of real estate assets by energy efficiency classes',
    name_es:
      'Importe en libros total de los activos inmobiliarios por clases de eficiencia energética',
    name_pt: 'Valor contábil total de ativos imobiliários por classes de eficiência energética'
  },
  {
    id: '25c613cb-59d7-4694-999f-a01ac12a7741',
    name_dcycle:
      'disclosure_of_whether_and_how_potential_effects_on_future_financial_performance_and_position_for_assets_and_business_activities_at_material_transition_risk_have_been_assessed',
    datapoint_id: 'E1-9_18',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how potential effects on future financial performance and position for assets and business activities at material transition risk have been assessed',
    name_es:
      'Divulgación de si se han evaluado y cómo se han evaluado los efectos potenciales sobre el desempeño financiero futuro y la posición de los activos y actividades comerciales con riesgo de transición importante.',
    name_pt:
      'Divulgação sobre se e como foram avaliados os potenciais efeitos sobre o desempenho financeiro futuro e a posição dos ativos e atividades empresariais com risco de transição material'
  },
  {
    id: 'ceab2797-d9fd-4618-8adb-f82deb69d63c',
    name_dcycle:
      'disclosure_of_whether_and_how_assessment_of_assets_and_business_activities_considered_to_be_at_material_transition_risk_relies_on_or_is_part_of_process_to_determine_material_transition_risks_and_to_determine_scenarios',
    datapoint_id: 'E1-9_19',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how assessment of assets and business activities considered to be at material transition risk relies on or is part of process to determine material transition risks and to determine scenarios',
    name_es:
      'Divulgación de si, y cómo, la evaluación de activos y actividades comerciales consideradas con riesgo de transición importante depende o es parte del proceso para determinar riesgos de transición importantes y para determinar escenarios.',
    name_pt:
      'Divulgação sobre se e como a avaliação de ativos e atividades de negócios consideradas como estando em risco de transição material depende ou faz parte do processo para determinar riscos de transição materiais e para determinar cenários'
  },
  {
    id: 'ad00a667-34ed-4082-9711-c64eaf02703d',
    name_dcycle: 'estimated_amount_of_potentially_stranded_assets',
    datapoint_id: 'E1-9_20',
    esg_category: 'environmental',
    name_en: 'Estimated amount of potentially stranded assets',
    name_es: 'Cantidad estimada de activos potencialmente varados',
    name_pt: 'Quantidade estimada de ativos potencialmente irrecuperáveis'
  },
  {
    id: '5ab957cd-804e-40fd-85f0-ffd37807d685',
    name_dcycle:
      'percentage_of_estimated_share_of_potentially_stranded_assets_of_total_assets_at_material_transition_risk',
    datapoint_id: 'E1-9_21',
    esg_category: 'environmental',
    name_en:
      'Percentage of estimated share of potentially stranded assets of total assets at material transition risk',
    name_es:
      'Porcentaje de la proporción estimada de activos potencialmente varados del total de activos en riesgo de transición material',
    name_pt:
      'Percentagem da parcela estimada de ativos potencialmente irrecuperáveis ​​no total de ativos com risco de transição material'
  },
  {
    id: '94380d07-54d7-4ef1-b9b5-f627b8c4d36f',
    name_dcycle:
      'total_carrying_amount_of_real_estate_assets_for_which_energy_consumption_is_based_on_internal_estimates',
    datapoint_id: 'E1-9_22',
    esg_category: 'environmental',
    name_en:
      'Total carrying amount of real estate assets for which energy consumption is based on internal estimates',
    name_es:
      'Importe en libros total de los activos inmobiliarios cuyo consumo de energía se basa en estimaciones internas',
    name_pt:
      'Valor contábil total de ativos imobiliários cujo consumo de energia é baseado em estimativas internas'
  },
  {
    id: 'd3774f19-8b0a-409e-88bb-058e605b8cc7',
    name_dcycle:
      'liabilities_from_material_transition_risks_that_may_have_to_be_recognised_in_financial_statements',
    datapoint_id: 'E1-9_23',
    esg_category: 'environmental',
    name_en:
      'Liabilities from material transition risks that may have to be recognised in financial statements',
    name_es:
      'Pasivos por riesgos de transición importantes que pueden tener que reconocerse en los estados financieros',
    name_pt:
      'Passivos decorrentes de riscos de transição materiais que podem ter de ser reconhecidos nas demonstrações financeiras'
  },
  {
    id: '39a2ac3a-5479-42a6-b8e3-ed206e3548ae',
    name_dcycle:
      'number_of_scope_1_ghg_emission_allowances_within_regulated_emission_trading_schemes',
    datapoint_id: 'E1-9_24',
    esg_category: 'environmental',
    name_en: 'Number of Scope 1 GHG emission allowances within regulated emission trading schemes',
    name_es:
      'Número de derechos de emisión de GEI de alcance 1 dentro de esquemas regulados de comercio de derechos de emisión',
    name_pt:
      'Número de licenças de emissão de GEE de Escopo 1 dentro de esquemas regulamentados de comércio de emissões'
  },
  {
    id: '1287f733-c0ff-4b2c-b209-a14a83342756',
    name_dcycle:
      'number_of_emission_allowances_stored_(from_previous_allowances)_at_beginning_of_reporting_period',
    datapoint_id: 'E1-9_25',
    esg_category: 'environmental',
    name_en:
      'Number of emission allowances stored (from previous allowances) at beginning of reporting period',
    name_es:
      'Número de derechos de emisión almacenados (de derechos anteriores) al comienzo del período del informe',
    name_pt:
      'Número de licenças de emissão armazenadas (de licenças anteriores) no início do período de relatório'
  },
  {
    id: '45667520-48bf-4ac0-8383-89074115bec7',
    name_dcycle:
      'potential_future_liabilities,_based_on_existing_contractual_agreements,_associated_with_carbon_credits_planned_to_be_cancelled_in_near_future',
    datapoint_id: 'E1-9_26',
    esg_category: 'environmental',
    name_en:
      'Potential future liabilities, based on existing contractual agreements, associated with carbon credits planned to be cancelled in near future',
    name_es:
      'Posibles obligaciones futuras, basadas en acuerdos contractuales existentes, asociadas con créditos de carbono que se planea cancelar en un futuro próximo',
    name_pt:
      'Potenciais responsabilidades futuras, com base em acordos contratuais existentes, associadas a créditos de carbono planeados para serem cancelados num futuro próximo'
  },
  {
    id: 'e37075f5-e9b0-4be8-bf0d-bf3b8ee8d7de',
    name_dcycle: 'monetised_gross_scope_1_and_2_ghg_emissions',
    datapoint_id: 'E1-9_27',
    esg_category: 'environmental',
    name_en: 'Monetised gross Scope 1 and 2 GHG emissions',
    name_es: 'Emisiones brutas de GEI de alcance 1 y 2 monetizadas',
    name_pt: 'Emissões brutas monetizadas de GEE de Escopo 1 e 2'
  },
  {
    id: '4865675b-8993-4a97-8df0-e87cd7b0e56e',
    name_dcycle: 'monetised_total_ghg_emissions',
    datapoint_id: 'E1-9_28',
    esg_category: 'environmental',
    name_en: 'Monetised total GHG emissions',
    name_es: 'Emisiones totales de GEI monetizadas',
    name_pt: 'Emissões totais de GEE monetizadas'
  },
  {
    id: 'be9d1c39-a66e-401f-9593-72f9d1aa1b00',
    name_dcycle: 'net_revenue_from_business_activities_at_material_transition_risk',
    datapoint_id: 'E1-9_29',
    esg_category: 'environmental',
    name_en: 'Net revenue from business activities at material transition risk',
    name_es: 'Ingresos netos de actividades comerciales con riesgo de transición material',
    name_pt: 'Receita líquida de atividades comerciais com risco de transição material'
  },
  {
    id: 'f4daa136-affe-479b-ace7-4636314f0d8c',
    name_dcycle: 'net_revenue_from_customers_operating_in_coal-related_activities',
    datapoint_id: 'E1-9_30',
    esg_category: 'environmental',
    name_en: 'Net revenue from customers operating in coal-related activities',
    name_es: 'Ingresos netos de clientes que operan en actividades relacionadas con el carbón',
    name_pt: 'Receita líquida de clientes que operam em atividades relacionadas ao carvão'
  },
  {
    id: '4ff4ce54-11c9-4c7b-a3c0-6fcb7970b7f4',
    name_dcycle: 'net_revenue_from_customers_operating_in_oil-related_activities',
    datapoint_id: 'E1-9_31',
    esg_category: 'environmental',
    name_en: 'Net revenue from customers operating in oil-related activities',
    name_es: 'Ingresos netos de clientes que operan en actividades relacionadas con el petróleo',
    name_pt: 'Receita líquida de clientes que operam em atividades relacionadas ao petróleo'
  },
  {
    id: 'a8154c7c-0316-4040-96d2-2fa4535df6f8',
    name_dcycle: 'net_revenue_from_customers_operating_in_gas-related_activities',
    datapoint_id: 'E1-9_32',
    esg_category: 'environmental',
    name_en: 'Net revenue from customers operating in gas-related activities',
    name_es: 'Ingresos netos de clientes que operan en actividades relacionadas con el gas',
    name_pt: 'Receita líquida de clientes que operam em atividades relacionadas com o gás'
  },
  {
    id: '020efcc6-11ee-4bff-8de7-4af5b584826a',
    name_dcycle: 'percentage_of_net_revenue_from_customers_operating_in_coal-related_activities',
    datapoint_id: 'E1-9_33',
    esg_category: 'environmental',
    name_en: 'Percentage of net revenue from customers operating in coal-related activities',
    name_es:
      'Porcentaje de ingresos netos de clientes que operan en actividades relacionadas con el carbón',
    name_pt:
      'Percentagem da receita líquida de clientes que operam em atividades relacionadas com o carvão'
  },
  {
    id: 'b7ccc611-b7a8-427b-905b-c2fbb97097b3',
    name_dcycle: 'percentage_of_net_revenue_from_customers_operating_in_oil-related_activities',
    datapoint_id: 'E1-9_34',
    esg_category: 'environmental',
    name_en: 'Percentage of net revenue from customers operating in oil-related activities',
    name_es:
      'Porcentaje de ingresos netos de clientes que operan en actividades relacionadas con el petróleo',
    name_pt:
      'Percentual da receita líquida de clientes que operam em atividades relacionadas ao petróleo'
  },
  {
    id: '0f5b080d-4566-49e0-8e46-7d4e81499a41',
    name_dcycle: 'percentage_of_net_revenue_from_customers_operating_in_gas-related_activities',
    datapoint_id: 'E1-9_35',
    esg_category: 'environmental',
    name_en: 'Percentage of net revenue from customers operating in gas-related activities',
    name_es:
      'Porcentaje de ingresos netos de clientes que operan en actividades relacionadas con el gas',
    name_pt:
      'Percentagem da receita líquida de clientes que operam em atividades relacionadas com o gás'
  },
  {
    id: '1a421d1a-02cf-4960-8fd3-167b06af5a75',
    name_dcycle: 'percentage_of_net_revenue_from_business_activities_at_material_transition_risk',
    datapoint_id: 'E1-9_36',
    esg_category: 'environmental',
    name_en: 'Percentage of net revenue from business activities at material transition risk',
    name_es:
      'Porcentaje de ingresos netos de actividades comerciales con riesgo de transición material',
    name_pt:
      'Percentagem da receita líquida de atividades empresariais com risco de transição material'
  },
  {
    id: 'a4718375-c486-4efa-b1e3-8cb27e2d4c9d',
    name_dcycle:
      'disclosure_of_risk_factors_for_net_revenue_from_business_activities_at_material_transition_risk',
    datapoint_id: 'E1-9_37',
    esg_category: 'environmental',
    name_en:
      'Disclosure of risk factors for net revenue from business activities at material transition risk',
    name_es:
      'Divulgación de factores de riesgo para ingresos netos de actividades comerciales con riesgo de transición material',
    name_pt:
      'Divulgação de fatores de risco para receita líquida de atividades empresariais com risco de transição material'
  },
  {
    id: '8e64a39e-ca34-4c96-a999-348b37ab8326',
    name_dcycle:
      'disclosure_of_anticipated_financial_effects_in_terms_of_margin_erosion_for_business_activities_at_material_transition_risk',
    datapoint_id: 'E1-9_38',
    esg_category: 'environmental',
    name_en:
      'Disclosure of anticipated financial effects in terms of margin erosion for business activities at material transition risk',
    name_es:
      'Divulgación de los efectos financieros previstos en términos de erosión de márgenes para actividades comerciales con riesgo de transición importante',
    name_pt:
      'Divulgação dos efeitos financeiros previstos em termos de erosão de margens para atividades comerciais com risco de transição material'
  },
  {
    id: 'af073f84-eeb6-4a2c-a4ca-4ce0059856d3',
    name_dcycle:
      'disclosure_of_reconciliations_with_financial_statements_of_significant_amounts_of_assets_and_net_revenue_at_material_physical_risk',
    datapoint_id: 'E1-9_39',
    esg_category: 'environmental',
    name_en:
      'Disclosure of reconciliations with financial statements of significant amounts of assets and net revenue at material physical risk',
    name_es:
      'Información a revelar de conciliaciones con estados financieros de cantidades significativas de activos e ingresos netos con riesgo físico material',
    name_pt:
      'Divulgação de reconciliações com demonstrações financeiras de valores significativos de ativos e receitas líquidas com risco físico relevante'
  },
  {
    id: '906f4a62-8214-4631-abba-930bb341ca1b',
    name_dcycle:
      'disclosure_of_reconciliations_with_financial_statements_of_significant_amounts_of_assets,_liabilities_and_net_revenue_at_material_transition_risk',
    datapoint_id: 'E1-9_40',
    esg_category: 'environmental',
    name_en:
      'Disclosure of reconciliations with financial statements of significant amounts of assets, liabilities and net revenue at material transition risk',
    name_es:
      'Información a revelar de conciliaciones con estados financieros de cantidades significativas de activos, pasivos e ingresos netos con riesgo de transición importante',
    name_pt:
      'Divulgação de reconciliações com demonstrações financeiras de montantes significativos de ativos, passivos e receitas líquidas com risco de transição material'
  },
  {
    id: '275ba9df-61ef-45be-8879-5f50aa61b0d9',
    name_dcycle: 'expected_cost_savings_from_climate_change_mitigation_actions',
    datapoint_id: 'E1-9_41',
    esg_category: 'environmental',
    name_en: 'Expected cost savings from climate change mitigation actions',
    name_es: 'Ahorros de costos esperados de las acciones de mitigación del cambio climático',
    name_pt:
      'Esperadas poupanças de custos decorrentes de ações de mitigação das alterações climáticas'
  },
  {
    id: 'd8c9694c-f10b-45a9-82e8-b94a17d6b6b4',
    name_dcycle: 'expected_cost_savings_from_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_42',
    esg_category: 'environmental',
    name_en: 'Expected cost savings from climate change adaptation actions',
    name_es: 'Ahorros de costos esperados de las acciones de adaptación al cambio climático',
    name_pt:
      'Esperadas poupanças de custos decorrentes de ações de adaptação às alterações climáticas'
  },
  {
    id: 'c612e1d0-5478-47ad-afe4-1a623f4f1264',
    name_dcycle:
      'potential_market_size_of_low-carbon_products_and_services_or_adaptation_solutions_to_which_undertaking_has_or_may_have_access',
    datapoint_id: 'E1-9_43',
    esg_category: 'environmental',
    name_en:
      'Potential market size of low-carbon products and services or adaptation solutions to which undertaking has or may have access',
    name_es:
      'Tamaño potencial del mercado de productos y servicios con bajas emisiones de carbono o soluciones de adaptación a los que la empresa tiene o puede tener acceso',
    name_pt:
      'Dimensão potencial do mercado de produtos e serviços hipocarbónicos ou soluções de adaptação a que a empresa tem ou pode ter acesso'
  },
  {
    id: 'e165efbf-81d0-4e7f-b73c-6d1e11cd1ac8',
    name_dcycle:
      'expected_changes_to_net_revenue_from_low-carbon_products_and_services_or_adaptation_solutions_to_which_undertaking_has_or_may_have_access',
    datapoint_id: 'E1-9_44',
    esg_category: 'environmental',
    name_en:
      'Expected changes to net revenue from low-carbon products and services or adaptation solutions to which undertaking has or may have access',
    name_es:
      'Cambios esperados en los ingresos netos provenientes de productos y servicios con bajas emisiones de carbono o soluciones de adaptación a los que la empresa tiene o puede tener acceso',
    name_pt:
      'Mudanças esperadas na receita líquida de produtos e serviços hipocarbónicos ou soluções de adaptação a que a empresa tem ou pode ter acesso'
  },
  {
    id: '3204c4f4-0470-4c4b-9058-606b0340acfb',
    name_dcycle:
      'information_about_the_process_to_identify_actual_and_potential_pollution-related_impacts,_risks_and_opportuntities',
    datapoint_id: 'E2.IRO-1_01',
    esg_category: 'environmental',
    name_en:
      'Information about the process to identify actual and potential pollution-related impacts, risks and opportuntities',
    name_es:
      'Información sobre el proceso para identificar impactos, riesgos y oportunidades reales y potenciales relacionados con la contaminación.',
    name_pt:
      'Informações sobre o processo para identificar impactos, riscos e oportunidades reais e potenciais relacionados à poluição'
  },
  {
    id: '40aa5aa4-764f-41bb-ba0d-9167e5e9c143',
    name_dcycle: 'disclosure_of_whether_and_how_consultations_have_been_conducted_(pollution)',
    datapoint_id: 'E2.IRO-1_02',
    esg_category: 'environmental',
    name_en: 'Disclosure of whether and how consultations have been conducted (pollution)',
    name_es: 'Divulgación de si se han realizado consultas y cómo (contaminación)',
    name_pt: 'Divulgação de se e como as consultas foram realizadas (poluição)'
  },
  {
    id: '0a5f42d2-e9d4-4cba-9c05-ea9eb10a1934',
    name_dcycle: 'disclosure_of_results_of_materiality_assessment_(pollution)',
    datapoint_id: 'E2.IRO-1_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of results of materiality assessment (pollution)',
    name_es: 'Divulgación de resultados de la evaluación de materialidad (contaminación)',
    name_pt: 'Divulgação dos resultados da avaliação de materialidade (poluição)'
  },
  {
    id: '6bf81063-fe89-4aae-9294-1ee36cd9af5a',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_addresses_mitigating_negative_impacts_related_to_pollution_of_air,_water_and_soil',
    datapoint_id: 'E2-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy addresses mitigating negative impacts related to pollution of air, water and soil',
    name_es:
      'Divulgación de si la política aborda la mitigación de los impactos negativos relacionados con la contaminación del aire, el agua y el suelo y cómo lo hace.',
    name_pt:
      'Divulgação sobre se e como a política aborda a mitigação dos impactos negativos relacionados com a poluição do ar, da água e do solo'
  },
  {
    id: '1e263a7d-f2be-40a2-98bc-512a252defee',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_addresses_substituting_and_minimising_use_of_substances_of_concern_and_phasing_out_substances_of_very_high_concern',
    datapoint_id: 'E2-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy addresses substituting and minimising use of substances of concern and phasing out substances of very high concern',
    name_es:
      'Divulgación de si la política aborda la sustitución y minimización del uso de sustancias preocupantes y la eliminación gradual de sustancias extremadamente preocupantes, y de qué manera.',
    name_pt:
      'Divulgação de se e como a política aborda a substituição e minimização do uso de substâncias que suscitam preocupação e a eliminação progressiva de substâncias que suscitam elevada preocupação'
  },
  {
    id: '3d5505d7-3790-44e5-aa3d-d2c6a948bd97',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_addresses_avoiding_incidents_and_emergency_situations,_and_if_and_when_they_occur,_controlling_and_limiting_their_impact_on_people_and_environment',
    datapoint_id: 'E2-1_03',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy addresses avoiding incidents and emergency situations, and if and when they occur, controlling and limiting their impact on people and environment',
    name_es:
      'Divulgación de si y cómo la política aborda la prevención de incidentes y situaciones de emergencia, y si ocurren y cuándo ocurren, controlando y limitando su impacto en las personas y el medio ambiente.',
    name_pt:
      'Divulgação de se e como a política aborda a prevenção de incidentes e situações de emergência, e se e quando ocorrem, controlando e limitando o seu impacto nas pessoas e no ambiente'
  },
  {
    id: '138e3ca9-dc73-4736-9c97-b6d214919e39',
    name_dcycle:
      'disclosure_of_contextual_information_on_relations_between_policies_implemented_and_how_policies_contribute_to_eu_action_plan_towards_zero_pollution_for_air,_water_and_soil',
    datapoint_id: 'E2-1_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of contextual information on relations between policies implemented and how policies contribute to EU Action Plan Towards Zero Pollution for Air, Water and Soil',
    name_es:
      'Divulgación de información contextual sobre las relaciones entre las políticas implementadas y cómo las políticas contribuyen al Plan de Acción de la UE hacia la contaminación cero del aire, el agua y el suelo.',
    name_pt:
      'Divulgação de informações contextuais sobre as relações entre as políticas implementadas e como as políticas contribuem para o Plano de Acção da UE para a Poluição Zero do Ar, da Água e do Solo'
  },
  {
    id: '8e4fa552-463a-49d4-9291-d61cb8d5ef4f',
    name_dcycle: 'layer_in_mitigation_hierarchy_to_which_action_can_be_allocated_to_(pollution)',
    datapoint_id: 'E2-2_01',
    esg_category: 'environmental',
    name_en: 'Layer in mitigation hierarchy to which action can be allocated to (pollution)',
    name_es:
      'Capa en la jerarquía de mitigación a la que se pueden asignar acciones (contaminación)',
    name_pt: 'Camada na hierarquia de mitigação à qual a ação pode ser alocada (poluição)'
  },
  {
    id: '8b2742e7-ea78-4679-a0e1-041cf0ecc220',
    name_dcycle:
      'action_related_to_pollution_extends_to_upstream/downstream_value_chain_engagements',
    datapoint_id: 'E2-2_02',
    esg_category: 'environmental',
    name_en: 'Action related to pollution extends to upstream/downstream value chain engagements',
    name_es:
      'Las acciones relacionadas con la contaminación se extienden a los compromisos de las cadenas de valor ascendentes y descendentes.',
    name_pt:
      'As ações relacionadas com a poluição estendem-se aos compromissos a montante/jusante da cadeia de valor'
  },
  {
    id: 'e311b7d2-ff65-4c71-a6bd-3b8b64082221',
    name_dcycle: 'layer_in_mitigation_hierarchy_to_which_resources_can_be_allocated_to_(pollution)',
    datapoint_id: 'E2-2_03',
    esg_category: 'environmental',
    name_en: 'Layer in mitigation hierarchy to which resources can be allocated to (pollution)',
    name_es:
      'Capa en la jerarquía de mitigación a la que se pueden asignar recursos (contaminación)',
    name_pt: 'Camada na hierarquia de mitigação à qual os recursos podem ser alocados (poluição)'
  },
  {
    id: '20c8e6f5-6090-40af-91ed-72ab484eed28',
    name_dcycle:
      'information_about_action_plans_that_have_been_implemented_at_site-level_(pollution)',
    datapoint_id: 'E2-2_04',
    esg_category: 'environmental',
    name_en: 'Information about action plans that have been implemented at site-level (pollution)',
    name_es:
      'Información sobre planes de acción que se han implementado a nivel de sitio (contaminación)',
    name_pt: 'Informações sobre planos de ação que foram implementados a nível local (poluição)'
  },
  {
    id: '470466f9-460b-4e5c-a0f4-000804aa1c8a',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_prevention_and_control_of_air_pollutants_and_respective_specific_loads',
    datapoint_id: 'E2-3_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to prevention and control of air pollutants and respective specific loads',
    name_es:
      'Divulgación de si el objetivo se relaciona con la prevención y el control de los contaminantes del aire y sus respectivas cargas específicas, y de qué manera.',
    name_pt:
      'Divulgação sobre se e como a meta está relacionada com a prevenção e controlo de poluentes atmosféricos e respetivas cargas específicas'
  },
  {
    id: 'd32c2da7-6528-43fa-bf08-771b89e3cd53',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_prevention_and_control_of_emissions_to_water_and_respective_specific_loads',
    datapoint_id: 'E2-3_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to prevention and control of emissions to water and respective specific loads',
    name_es:
      'Divulgación de si y cómo el objetivo se relaciona con la prevención y el control de las emisiones al agua y las respectivas cargas específicas.',
    name_pt:
      'Divulgação sobre se e como a meta se relaciona com a prevenção e controlo de emissões para a água e respetivas cargas específicas'
  },
  {
    id: 'a9d2754a-5b60-47c1-8268-75f26c61a1b3',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_prevention_and_control_of_pollution_to_soil_and_respective_specific_loads',
    datapoint_id: 'E2-3_03',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to prevention and control of pollution to soil and respective specific loads',
    name_es:
      'Divulgación de si y cómo el objetivo se relaciona con la prevención y el control de la contaminación del suelo y las respectivas cargas específicas.',
    name_pt:
      'Divulgação sobre se e como a meta está relacionada com a prevenção e controlo da poluição do solo e respetivas cargas específicas'
  },
  {
    id: '6a1adca6-dfc1-4cfd-8e04-33f1597ce2d8',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_prevention_and_control_of_substances_of_concern_and_substances_of_very_high_concern',
    datapoint_id: 'E2-3_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to prevention and control of substances of concern and substances of very high concern',
    name_es:
      'Divulgación de si la meta se relaciona con la prevención y el control de sustancias preocupantes y sustancias extremadamente preocupantes, y de qué manera.',
    name_pt:
      'Divulgação sobre se e como a meta está relacionada com a prevenção e o controle de substâncias que suscitam preocupação e substâncias que suscitam elevada preocupação'
  },
  {
    id: 'c3c022fa-7a64-4bed-9bd3-c744b7b3abff',
    name_dcycle:
      'ecological_thresholds_and_entity-specific_allocations_were_taken_into_consideration_when_setting_pollution-related_target',
    datapoint_id: 'E2-3_05',
    esg_category: 'environmental',
    name_en:
      'Ecological thresholds and entity-specific allocations were taken into consideration when setting pollution-related target',
    name_es:
      'Se tuvieron en cuenta los umbrales ecológicos y las asignaciones específicas de cada entidad al establecer objetivos relacionados con la contaminación.',
    name_pt:
      'Os limiares ecológicos e as dotações específicas das entidades foram tidos em consideração ao definir metas relacionadas com a poluição'
  },
  {
    id: '92f62637-2b39-48c4-9ae4-e3aa47d6945f',
    name_dcycle:
      'disclosure_of_ecological_thresholds_identified_and_methodology_used_to_identify_ecological_thresholds_(pollution)',
    datapoint_id: 'E2-3_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of ecological thresholds identified and methodology used to identify ecological thresholds (pollution)',
    name_es:
      'Divulgación de umbrales ecológicos identificados y metodología utilizada para identificar umbrales ecológicos (contaminación)',
    name_pt:
      'Divulgação dos limiares ecológicos identificados e metodologia utilizada para identificar os limiares ecológicos (poluição)'
  },
  {
    id: '02d88961-fcc8-4475-8535-2a5419d1702c',
    name_dcycle:
      'disclosure_of_how_ecological_entity-specific_thresholds_were_determined_(pollution)',
    datapoint_id: 'E2-3_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of how ecological entity-specific thresholds were determined (pollution)',
    name_es:
      'Divulgación de cómo se determinaron los umbrales ecológicos específicos de la entidad (contaminación)',
    name_pt:
      'Divulgação de como foram determinados os limiares específicos da entidade ecológica (poluição)'
  },
  {
    id: '1b4eb859-4bba-45f3-b08b-6bf2875aace2',
    name_dcycle:
      'disclosure_of_how_responsibility_for_respecting_identified_ecological_thresholds_is_allocated_(pollution)',
    datapoint_id: 'E2-3_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how responsibility for respecting identified ecological thresholds is allocated (pollution)',
    name_es:
      'Divulgación de cómo se asigna la responsabilidad de respetar los umbrales ecológicos identificados (contaminación)',
    name_pt:
      'Divulgação de como é atribuída a responsabilidade pelo respeito dos limiares ecológicos identificados (poluição)'
  },
  {
    id: '3a597804-5584-4120-a0db-f08db0c5ef45',
    name_dcycle: 'pollution-related_target_is_mandatory_(required_by_legislation)/voluntary',
    datapoint_id: 'E2-3_09',
    esg_category: 'environmental',
    name_en: 'Pollution-related target is mandatory (required by legislation)/voluntary',
    name_es:
      'El objetivo relacionado con la contaminación es obligatorio (requerido por la legislación)/voluntario',
    name_pt: 'A meta relacionada à poluição é obrigatória (exigida pela legislação)/voluntária'
  },
  {
    id: '3854b8ec-f117-443e-ae3c-efa6a34ec1cf',
    name_dcycle:
      'pollution-related_target_addresses_shortcomings_related_to_substantial_contribution_criteria_for_pollution_prevention_and_control',
    datapoint_id: 'E2-3_10',
    esg_category: 'environmental',
    name_en:
      'Pollution-related target addresses shortcomings related to Substantial Contribution criteria for Pollution Prevention and Control',
    name_es:
      'La meta relacionada con la contaminación aborda las deficiencias relacionadas con los criterios de contribución sustancial para la prevención y el control de la contaminación.',
    name_pt:
      'A meta relacionada com a poluição aborda deficiências relacionadas com os critérios de contribuição substancial para a prevenção e controlo da poluição'
  },
  {
    id: 'f90b1917-c57e-46e6-8a2f-85ae5a50f6f1',
    name_dcycle: 'information_about_targets_that_have_been_implemented_at_site-level_(pollution)',
    datapoint_id: 'E2-3_11',
    esg_category: 'environmental',
    name_en: 'Information about targets that have been implemented at site-level (pollution)',
    name_es: 'Información sobre objetivos que se han implementado a nivel de sitio (contaminación)',
    name_pt: 'Informações sobre metas que foram implementadas no nível do local (poluição)'
  },
  {
    id: '803e9048-2a3a-4b5f-bd72-497457ed0fe8',
    name_dcycle:
      'pollution_of_air,_water_and_soil_[multiple_dimensions:_at_site_level_or_by_type_of_source,_by_sector_or_by_geographical_area',
    datapoint_id: 'E2-4_01',
    esg_category: 'environmental',
    name_en:
      'Pollution of air, water and soil [multiple dimensions: at site level or by type of source, by sector or by geographical area',
    name_es:
      'Contaminación del aire, el agua y el suelo [múltiples dimensiones: a nivel de sitio o por tipo de fuente, por sector o por área geográfica',
    name_pt:
      'Poluição do ar, da água e do solo [múltiplas dimensões: ao nível do local ou por tipo de fonte, por setor ou por área geográfica'
  },
  {
    id: '7ad00b20-0797-4aee-8815-600d2da36cdf',
    name_dcycle: 'emissions_to_air_by_pollutant',
    datapoint_id: 'E2-4_02',
    esg_category: 'environmental',
    name_en: 'Emissions to air by pollutant',
    name_es: 'Emisiones al aire por contaminante',
    name_pt: 'Emissões para a atmosfera por poluente'
  },
  {
    id: '4f42f3fd-0a88-4c19-81b7-e3e4c9713113',
    name_dcycle:
      'emissions_to_water_by_pollutant_[+_by_sectors/geographical_area/type_of_source/site_location]',
    datapoint_id: 'E2-4_03',
    esg_category: 'environmental',
    name_en:
      'Emissions to water by pollutant [+ by sectors/Geographical Area/Type of source/Site location]',
    name_es:
      'Emisiones al agua por contaminante [+ por sectores/Área Geográfica/Tipo de fuente/Ubicación del sitio]',
    name_pt:
      'Emissões para a água por poluente [+ por setores/Área geográfica/Tipo de fonte/Localização do local]'
  },
  {
    id: 'b59b4637-8bc4-4c08-9447-0fb60a4433c3',
    name_dcycle:
      'emissions_to_soil_by_pollutant_[+_by_sectors/geographical_area/type_of_source/site_location]',
    datapoint_id: 'E2-4_04',
    esg_category: 'environmental',
    name_en:
      'Emissions to soil by pollutant [+ by sectors/Geographical Area/Type of source/Site location]',
    name_es:
      'Emisiones al suelo por contaminante [+ por sectores/Área Geográfica/Tipo de fuente/Ubicación del sitio]',
    name_pt:
      'Emissões para o solo por poluente [+ por setores/Área geográfica/Tipo de fonte/Localização do local]'
  },
  {
    id: 'b11fe638-5335-4501-a125-78c26feb9430',
    name_dcycle: 'microplastics_generated_and_used',
    datapoint_id: 'E2-4_05',
    esg_category: 'environmental',
    name_en: 'Microplastics generated and used',
    name_es: 'Microplásticos generados y utilizados',
    name_pt: 'Microplásticos gerados e usados'
  },
  {
    id: '69341bb0-892f-444f-9698-27d28aec9d77',
    name_dcycle: 'microplastics_generated',
    datapoint_id: 'E2-4_06',
    esg_category: 'environmental',
    name_en: 'Microplastics generated',
    name_es: 'Microplásticos generados',
    name_pt: 'Microplásticos gerados'
  },
  {
    id: '5b2290d4-164b-47b4-831a-bed22e91b6bf',
    name_dcycle: 'microplastics_used',
    datapoint_id: 'E2-4_07',
    esg_category: 'environmental',
    name_en: 'Microplastics used',
    name_es: 'Microplásticos utilizados',
    name_pt: 'Microplásticos usados'
  },
  {
    id: 'd859db7a-16e4-40eb-b03f-c9ac01591f62',
    name_dcycle: 'description_of_changes_over_time_(pollution_of_air,_water_and_soil)',
    datapoint_id: 'E2-4_08',
    esg_category: 'environmental',
    name_en: 'Description of changes over time (pollution of air, water and soil)',
    name_es: 'Descripción de los cambios en el tiempo (contaminación del aire, agua y suelo)',
    name_pt: 'Descrição das mudanças ao longo do tempo (poluição do ar, da água e do solo)'
  },
  {
    id: 'fda5212f-4e9f-4d80-a473-946e4b0e7444',
    name_dcycle: 'description_of_measurement_methodologies_(pollution_of_air,_water_and_soil)',
    datapoint_id: 'E2-4_09',
    esg_category: 'environmental',
    name_en: 'Description of measurement methodologies (pollution of air, water and soil)',
    name_es: 'Descripción de metodologías de medición (contaminación del aire, agua y suelo)',
    name_pt: 'Descrição das metodologias de medição (poluição do ar, da água e do solo)'
  },
  {
    id: 'a402103f-8eb9-47ee-838a-f556f5f26a5e',
    name_dcycle:
      'description_of_process(es)_to_collect_data_for_pollution-related_accounting_and_reporting',
    datapoint_id: 'E2-4_10',
    esg_category: 'environmental',
    name_en:
      'Description of process(es) to collect data for pollution-related accounting and reporting',
    name_es:
      'Descripción del proceso(s) para recopilar datos para la contabilidad y presentación de informes relacionados con la contaminación',
    name_pt:
      'Descrição do(s) processo(s) de coleta de dados para contabilidade e relatórios relacionados à poluição'
  },
  {
    id: '4c7f4c23-14a3-4c0e-be36-93cca570f4cd',
    name_dcycle:
      'percentage_of_total_emissions_of_pollutants_to_water_occurring_in_areas_at_water_risk',
    datapoint_id: 'E2-4_11',
    esg_category: 'environmental',
    name_en:
      'Percentage of total emissions of pollutants to water occurring in areas at water risk',
    name_es:
      'Porcentaje del total de emisiones de contaminantes al agua que se producen en zonas con riesgo hídrico',
    name_pt:
      'Percentagem do total de emissões de poluentes para a água que ocorrem em áreas com risco hídrico'
  },
  {
    id: '7b6283d9-4784-4ed1-8246-87a436916e7e',
    name_dcycle:
      'percentage_of_total_emissions_of_pollutants_to_water_occurring_in_areas_of_high-water_stress',
    datapoint_id: 'E2-4_12',
    esg_category: 'environmental',
    name_en:
      'Percentage of total emissions of pollutants to water occurring in areas of high-water stress',
    name_es:
      'Porcentaje de emisiones totales de contaminantes al agua que se producen en zonas de alto estrés hídrico',
    name_pt:
      'Percentagem do total de emissões de poluentes para a água que ocorrem em áreas de elevado stress hídrico'
  },
  {
    id: '13534a57-ac0b-44f2-bb0e-9677155e9c79',
    name_dcycle:
      'percentage_of_total_emissions_of_pollutants_to_soil_occurring_in_areas_at_water_risk',
    datapoint_id: 'E2-4_13',
    esg_category: 'environmental',
    name_en: 'Percentage of total emissions of pollutants to soil occurring in areas at water risk',
    name_es:
      'Porcentaje del total de emisiones de contaminantes al suelo que se producen en zonas con riesgo hídrico',
    name_pt:
      'Percentual das emissões totais de poluentes para o solo que ocorrem em áreas com risco hídrico'
  },
  {
    id: 'ca601569-5048-4752-8afb-d39e22c49902',
    name_dcycle:
      'percentage_of_total_emissions_of_pollutants_to_soil_occurring_in_areas_of_high-water_stress',
    datapoint_id: 'E2-4_14',
    esg_category: 'environmental',
    name_en:
      'Percentage of total emissions of pollutants to soil occurring in areas of high-water stress',
    name_es:
      'Porcentaje de emisiones totales de contaminantes al suelo que ocurren en áreas de alto estrés hídrico',
    name_pt:
      'Percentagem do total de emissões de poluentes para o solo que ocorrem em áreas de elevado stress hídrico'
  },
  {
    id: 'a2440711-668a-48d2-9d2e-2ba7f812c8a8',
    name_dcycle: 'disclosure_of_reasons_for_choosing_inferior_methodology_to_quantify_emissions',
    datapoint_id: 'E2-4_15',
    esg_category: 'environmental',
    name_en: 'Disclosure of reasons for choosing inferior methodology to quantify emissions',
    name_es:
      'Divulgación de razones para elegir una metodología inferior para cuantificar las emisiones.',
    name_pt:
      'Divulgação dos motivos da escolha de metodologia inferior para quantificação de emissões'
  },
  {
    id: '92ef9373-17d0-4b22-9128-d27cb08a0634',
    name_dcycle:
      'disclosure_of_list_of_installations_operated_that_fall_under_ied_and_eu_bat_conclusions',
    datapoint_id: 'E2-4_16',
    esg_category: 'environmental',
    name_en:
      'Disclosure of list of installations operated that fall under IED and EU BAT Conclusions',
    name_es:
      'Divulgación de la lista de instalaciones operadas que se incluyen en el ámbito de los DEI y las conclusiones sobre las MTD de la UE',
    name_pt:
      'Divulgação da lista de instalações operadas que se enquadram nas Conclusões do IED e das MTD da UE'
  },
  {
    id: 'f682b92f-4dc8-47cc-b8fc-28f06d2b0b0c',
    name_dcycle:
      'disclosure_of_list_of_any_non-compliance_incidents_or_enforcement_actions_necessary_to_ensure_compliance_in_case_of_breaches_of_permit_conditions',
    datapoint_id: 'E2-4_17',
    esg_category: 'environmental',
    name_en:
      'Disclosure of list of any non-compliance incidents or enforcement actions necessary to ensure compliance in case of breaches of permit conditions',
    name_es:
      'Divulgación de una lista de incidentes de incumplimiento o acciones de cumplimiento necesarias para garantizar el cumplimiento en caso de incumplimiento de las condiciones del permiso.',
    name_pt:
      'Divulgação da lista de quaisquer incidentes de não conformidade ou ações de fiscalização necessárias para garantir a conformidade em caso de violação das condições de licença'
  },
  {
    id: '6cbff97b-2586-4d97-9ba7-82d04d20b9a5',
    name_dcycle:
      'disclosure_of_actual_performance_and_comparison_of_environmental_performance_against_emission_levels_associated_with_best_available_techniques_(bat-ael)_as_described_in_eu-bat_conclusions',
    datapoint_id: 'E2-4_18',
    esg_category: 'environmental',
    name_en:
      'Disclosure of actual performance and comparison of environmental performance against emission levels associated with best available techniques (BAT-AEL) as described in EU-BAT conclusions',
    name_es:
      'Divulgación del desempeño real y comparación del desempeño ambiental con los niveles de emisión asociados con las mejores técnicas disponibles (MTD-AEL) como se describe en las conclusiones de las MTD de la UE.',
    name_pt:
      'Divulgação do desempenho real e comparação do desempenho ambiental com os níveis de emissões associados às melhores técnicas disponíveis (MTD-AEL), conforme descrito nas conclusões da UE-MTD'
  },
  {
    id: 'b42aae38-8b81-4f7d-889b-754a76aa5120',
    name_dcycle:
      'disclosure_of_actual_performance_against_environmental_performance_levels_associated_with_best_available_techniques_(bat-aepls)_applicable_to_sector_and_installation',
    datapoint_id: 'E2-4_19',
    esg_category: 'environmental',
    name_en:
      'Disclosure of actual performance against environmental performance levels associated with best available techniques (BAT-AEPLs) applicable to sector and installation',
    name_es:
      'Divulgación del desempeño real frente a los niveles de desempeño ambiental asociados con las mejores técnicas disponibles (MTD-AEPL) aplicables al sector y la instalación.',
    name_pt:
      'Divulgação do desempenho real em relação aos níveis de desempenho ambiental associados às melhores técnicas disponíveis (MTD-AEPLs) aplicáveis ​​ao setor e à instalação'
  },
  {
    id: '2da00230-7f30-4935-8eae-3496d913152c',
    name_dcycle:
      'disclosure_of_list_of_any_compliance_schedules_or_derogations_granted_by_competent_authorities_according_to_article_15(4)_ied_that_are_associated_with_implementation_of_bat-aels',
    datapoint_id: 'E2-4_20',
    esg_category: 'environmental',
    name_en:
      'Disclosure of list of any compliance schedules or derogations granted by competent authorities according to Article 15(4) IED that are associated with implementation of BAT-AELs',
    name_es:
      'Divulgación de la lista de cronogramas de cumplimiento o derogaciones concedidos por las autoridades competentes de conformidad con el artículo 15, apartado 4, de la DEI que están asociados con la implementación de los NEA-MTD',
    name_pt:
      'Divulgação da lista de quaisquer calendários de conformidade ou derrogações concedidas pelas autoridades competentes nos termos do artigo 15.º, n.º 4, da DEI que estão associados à implementação de VEA-MTD'
  },
  {
    id: 'af1f8d92-051f-4bd7-bb65-9cc667fa8329',
    name_dcycle:
      'total_amount_of_substances_of_concern_that_are_generated_or_used_during_production_or_that_are_procured,_breakdown_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_01',
    esg_category: 'environmental',
    name_en:
      'Total amount of substances of concern that are generated or used during production or that are procured, breakdown by main hazard classes of substances of concern',
    name_es:
      'Cantidad total de sustancias preocupantes que se generan o utilizan durante la producción o que se adquieren, desglosadas por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade total de substâncias preocupantes geradas ou utilizadas durante a produção ou adquiridas, discriminadas pelas principais classes de perigo de substâncias preocupantes'
  },
  {
    id: '48e5c5f3-365d-4cea-be92-c3f5159b3050',
    name_dcycle:
      'total_amount_of_substances_of_concern_that_are_generated_or_used_during_production_or_that_are_procured',
    datapoint_id: 'E2-5_02',
    esg_category: 'environmental',
    name_en:
      'Total amount of substances of concern that are generated or used during production or that are procured',
    name_es:
      'Cantidad total de sustancias preocupantes que se generan o utilizan durante la producción o que se adquieren',
    name_pt:
      'Quantidade total de substâncias preocupantes geradas ou utilizadas durante a produção ou adquiridas'
  },
  {
    id: 'ded1a826-3ca3-487c-939e-c205f01a0575',
    name_dcycle:
      'total_amount_of_substances_of_concern_that_leave_facilities_as_emissions,_as_products,_or_as_part_of_products_or_services',
    datapoint_id: 'E2-5_03',
    esg_category: 'environmental',
    name_en:
      'Total amount of substances of concern that leave facilities as emissions, as products, or as part of products or services',
    name_es:
      'Cantidad total de sustancias preocupantes que salen de las instalaciones como emisiones, como productos o como parte de productos o servicios.',
    name_pt:
      'Quantidade total de substâncias preocupantes que saem das instalações como emissões, como produtos ou como parte de produtos ou serviços'
  },
  {
    id: '9e5d5252-b8b0-4cd5-9175-a4b38feb6c65',
    name_dcycle:
      'amount_of_substances_of_concern_that_leave_facilities_as_emissions_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_04',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of concern that leave facilities as emissions by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias preocupantes que salen de las instalaciones como emisiones por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias preocupantes que saem das instalações como emissões por principais classes de perigo de substâncias preocupantes'
  },
  {
    id: 'c18477c8-6642-4e93-9379-690774d0840c',
    name_dcycle:
      'amount_of_substances_of_concern_that_leave_facilities_as_products_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_05',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of concern that leave facilities as products by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias preocupantes que salen de las instalaciones como productos por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias preocupantes que saem das instalações como produtos, por principais classes de perigo de substâncias preocupantes'
  },
  {
    id: '353a5803-2be2-4745-b040-8db8b3b0e6d2',
    name_dcycle:
      'amount_of_substances_of_concern_that_leave_facilities_as_part_of_products_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_06',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of concern that leave facilities as part of products by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias preocupantes que salen de las instalaciones como parte de productos por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias preocupantes que saem das instalações como parte de produtos, por principais classes de perigo de substâncias preocupantes'
  },
  {
    id: '4494a0f8-b1f5-4bbd-87e9-62637c79c3be',
    name_dcycle:
      'amount_of_substances_of_concern_that_leave_facilities_as_services_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_07',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of concern that leave facilities as services by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias preocupantes que salen de las instalaciones como servicios por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias preocupantes que saem das instalações como serviços, por principais classes de perigo de substâncias preocupantes'
  },
  {
    id: 'ff3676c0-1329-4187-be37-6f865e352c3f',
    name_dcycle:
      'total_amount_of_substances_of_very_high_concern_that_are_generated_or_used_during_production_or_that_are_procured_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_08',
    esg_category: 'environmental',
    name_en:
      'Total amount of substances of very high concern that are generated or used during production or that are procured by main hazard classes of substances of concern',
    name_es:
      'Cantidad total de sustancias extremadamente preocupantes que se generan o utilizan durante la producción o que se obtienen según las principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade total de substâncias que suscitam elevada preocupação que são geradas ou utilizadas durante a produção ou que são adquiridas pelas principais classes de perigo de substâncias que suscitam preocupação'
  },
  {
    id: '08f71968-5cdc-4c40-a9ed-6a34cee6b3bb',
    name_dcycle:
      'total_amount_of_substances_of_very_high_concern_that_leave_facilities_as_emissions,_as_products,_or_as_part_of_products_or_services_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_09',
    esg_category: 'environmental',
    name_en:
      'Total amount of substances of very high concern that leave facilities as emissions, as products, or as part of products or services by main hazard classes of substances of concern',
    name_es:
      'Cantidad total de sustancias extremadamente preocupantes que salen de las instalaciones como emisiones, como productos o como parte de productos o servicios por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade total de substâncias que suscitam elevada preocupação que saem das instalações como emissões, como produtos ou como parte de produtos ou serviços, por principais classes de perigo de substâncias que suscitam preocupação'
  },
  {
    id: '79c1dd59-dfeb-4340-8c7f-24f8ec37b33e',
    name_dcycle:
      'amount_of_substances_of_very_high_concern_that_leave_facilities_as_emissions_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_10',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of very high concern that leave facilities as emissions by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias altamente preocupantes que salen de las instalaciones como emisiones por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias que suscitam elevada preocupação que saem das instalações como emissões pelas principais classes de perigo de substâncias que suscitam preocupação'
  },
  {
    id: '76688c62-f5f4-47e0-821a-58011c55c9ea',
    name_dcycle:
      'amount_of_substances_of_very_high_concern_that_leave_facilities_as_products_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_11',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of very high concern that leave facilities as products by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias extremadamente preocupantes que salen de las instalaciones como productos por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias que suscitam elevada preocupação que saem das instalações como produtos, por principais classes de perigo de substâncias que suscitam preocupação'
  },
  {
    id: '65d764bc-3c0b-49db-9ff7-13a62ef38238',
    name_dcycle:
      'amount_of_substances_of_very_high_concern_that_leave_facilities_as_part_of_products_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_12',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of very high concern that leave facilities as part of products by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias extremadamente preocupantes que salen de las instalaciones como parte de productos por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias que suscitam elevada preocupação que saem das instalações como parte dos produtos, discriminadas pelas principais classes de perigo das substâncias que suscitam preocupação'
  },
  {
    id: 'dd593508-5ae0-4b04-9c15-8b4062faa0e1',
    name_dcycle:
      'amount_of_substances_of_very_high_concern_that_leave_facilities_as_services_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_13',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of very high concern that leave facilities as services by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias extremadamente preocupantes que salen de las instalaciones como servicios por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias que suscitam elevada preocupação que saem das instalações como serviços, por principais classes de perigo de substâncias que suscitam preocupação'
  },
  {
    id: '7c23f01a-0f50-415a-86fd-8223981649be',
    name_dcycle:
      'disclosure_of_quantitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_pollution-related_impacts',
    datapoint_id: 'E2-6_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of quantitative information about anticipated financial effects of material risks and opportunities arising from pollution-related impacts',
    name_es:
      'Divulgación de información cuantitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos relacionados con la contaminación.',
    name_pt:
      'Divulgação de informações quantitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos relacionados à poluição'
  },
  {
    id: '4aecb521-f625-4879-b610-eae8e15bca4f',
    name_dcycle:
      'percentage_of_net_revenue_made_with_products_and_services_that_are_or_that_contain_substances_of_concern',
    datapoint_id: 'E2-6_02',
    esg_category: 'environmental',
    name_en:
      'Percentage of net revenue made with products and services that are or that contain substances of concern',
    name_es:
      'Porcentaje de ingresos netos obtenidos con productos y servicios que son o contienen sustancias preocupantes',
    name_pt:
      'Porcentagem da receita líquida obtida com produtos e serviços que são ou contêm substâncias preocupantes'
  },
  {
    id: '385d245a-77fb-43b6-a7d7-f03cd898e3f5',
    name_dcycle:
      'percentage_of_net_revenue_made_with_products_and_services_that_are_or_that_contain_substances_of_very_high_concern',
    datapoint_id: 'E2-6_03',
    esg_category: 'environmental',
    name_en:
      'Percentage of net revenue made with products and services that are or that contain substances of very high concern',
    name_es:
      'Porcentaje de ingresos netos obtenidos con productos y servicios que son o contienen sustancias extremadamente preocupantes',
    name_pt:
      'Percentagem da receita líquida obtida com produtos e serviços que são ou contêm substâncias que suscitam elevada preocupação'
  },
  {
    id: '3bb84ae9-ed86-400b-955b-8b7a10cf12b4',
    name_dcycle:
      'operating_expenditures_(opex)_in_conjunction_with_major_incidents_and_deposits_(pollution)',
    datapoint_id: 'E2-6_04',
    esg_category: 'environmental',
    name_en:
      'Operating expenditures (OpEx) in conjunction with major incidents and deposits (pollution)',
    name_es:
      'Gastos operativos (OpEx) en conjunto con incidentes importantes y depósitos (contaminación)',
    name_pt:
      'Despesas operacionais (OpEx) em conjunto com grandes incidentes e depósitos (poluição)'
  },
  {
    id: 'ce2ccf5a-9e9a-471b-9ad6-67e43f53388b',
    name_dcycle:
      'capital_expenditures_(capex)_in_conjunction_with_major_incidents_and_deposits_(pollution)',
    datapoint_id: 'E2-6_05',
    esg_category: 'environmental',
    name_en:
      'Capital expenditures (CapEx) in conjunction with major incidents and deposits (pollution)',
    name_es:
      'Gastos de capital (CapEx) en conjunto con incidentes importantes y depósitos (contaminación)',
    name_pt: 'Despesas de capital (CapEx) em conjunto com grandes incidentes e depósitos (poluição)'
  },
  {
    id: '9a82eee5-ac43-45b4-9c86-4b5ae1448810',
    name_dcycle: 'provisions_for_environmental_protection_and_remediation_costs_(pollution)',
    datapoint_id: 'E2-6_06',
    esg_category: 'environmental',
    name_en: 'Provisions for environmental protection and remediation costs (pollution)',
    name_es: 'Provisiones para protección ambiental y costos de remediación (contaminación)',
    name_pt: 'Provisões para proteção ambiental e custos de remediação (poluição)'
  },
  {
    id: 'd7d381d1-3a86-4e37-877a-08274629ef4d',
    name_dcycle:
      'disclosure_of_qualitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_pollution-related_impacts',
    datapoint_id: 'E2-6_07',
    esg_category: 'environmental',
    name_en:
      'Disclosure of qualitative information about anticipated financial effects of material risks and opportunities arising from pollution-related impacts',
    name_es:
      'Divulgación de información cualitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos relacionados con la contaminación.',
    name_pt:
      'Divulgação de informações qualitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos relacionados à poluição'
  },
  {
    id: '7f00a63a-9cc6-4a37-a5ce-d37b7835ce63',
    name_dcycle:
      'description_of_effects_considered,_related_impacts_and_time_horizons_in_which_they_are_likely_to_materialise_(pollution)',
    datapoint_id: 'E2-6_08',
    esg_category: 'environmental',
    name_en:
      'Description of effects considered, related impacts and time horizons in which they are likely to materialise (pollution)',
    name_es:
      'Descripción de los efectos considerados, impactos relacionados y horizontes temporales en los que es probable que se materialicen (contaminación)',
    name_pt:
      'Descrição dos efeitos considerados, impactos relacionados e horizontes temporais em que é provável que se materializem (poluição)'
  },
  {
    id: '935ac00b-48f8-4b27-bf39-7a9966cbc081',
    name_dcycle:
      'disclosure_of_critical_assumptions_used_to_quantify_anticipated_financial_effects,_sources_and_level_of_uncertainty_of_assumptions_(pollution)',
    datapoint_id: 'E2-6_09',
    esg_category: 'environmental',
    name_en:
      'Disclosure of critical assumptions used to quantify anticipated financial effects, sources and level of uncertainty of assumptions (pollution)',
    name_es:
      'Divulgación de supuestos críticos utilizados para cuantificar los efectos financieros previstos, las fuentes y el nivel de incertidumbre de los supuestos (contaminación)',
    name_pt:
      'Divulgação de premissas críticas usadas para quantificar os efeitos financeiros previstos, fontes e nível de incerteza das premissas (poluição)'
  },
  {
    id: '3079b7fc-5420-4152-aeac-338d9dcf7517',
    name_dcycle:
      'description_of_material_incidents_and_deposits_whereby_pollution_had_negative_impacts_on_environment_and_(or)_is_expected_to_have_negative_effects_on_financial_cash_flows,_financial_position_and_financial_performance',
    datapoint_id: 'E2-6_10',
    esg_category: 'environmental',
    name_en:
      'Description of material incidents and deposits whereby pollution had negative impacts on environment and (or) is expected to have negative effects on financial cash flows, financial position and financial performance',
    name_es:
      'Descripción de incidentes y depósitos materiales mediante los cuales la contaminación tuvo impactos negativos en el medio ambiente y (o) se espera que tenga efectos negativos en los flujos de efectivo financieros, la situación financiera y el desempeño financiero.',
    name_pt:
      'Descrição de incidentes e depósitos materiais em que a poluição teve impactos negativos no meio ambiente e (ou) se espera que tenha efeitos negativos nos fluxos de caixa financeiros, na posição financeira e no desempenho financeiro'
  },
  {
    id: 'e0cbcdd7-100b-4fd2-b8ea-f495bde45e7a',
    name_dcycle:
      'disclosure_of_assessment_of_related_products_and_services_at_risk_and_explanation_how_time_horizon_is_defined,_financial_amounts_are_estimated,_and_which_critical_assumptions_are_made_(pollution)',
    datapoint_id: 'E2-6_11',
    esg_category: 'environmental',
    name_en:
      'Disclosure of assessment of related products and services at risk and explanation how time horizon is defined, financial amounts are estimated, and which critical assumptions are made (pollution)',
    name_es:
      'Divulgación de la evaluación de productos y servicios relacionados en riesgo y explicación de cómo se define el horizonte temporal, se estiman los montos financieros y qué supuestos críticos se hacen (contaminación).',
    name_pt:
      'Divulgação da avaliação de produtos e serviços relacionados em risco e explicação de como o horizonte temporal é definido, os valores financeiros são estimados e quais premissas críticas são feitas (poluição)'
  },
  {
    id: 'c61a1907-d0d3-4d68-80b4-88e370f814d3',
    name_dcycle:
      'disclosure_of_whether_and_how_assets_and_activities_have_been_screened_in_order_to_identify_actual_and_potential_water_and_marine_resources-related_impacts,_risks_and_opportunities_in_own_operations_and_upstream_and_downstream_value_chain_and_methodologies,_assumptions_and_tools_used_in_screening_[text_block]',
    datapoint_id: 'E3.IRO-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how assets and activities have been screened in order to identify actual and potential water and marine resources-related impacts, risks and opportunities in own operations and upstream and downstream value chain and methodologies, assumptions and tools used in screening [text block]',
    name_es:
      'Divulgación de si se han examinado los activos y las actividades, y cómo, para identificar los impactos, riesgos y oportunidades reales y potenciales relacionados con el agua y los recursos marinos en las propias operaciones y en la cadena de valor ascendente y descendente, y las metodologías, suposiciones y herramientas utilizadas en la evaluación [bloque de texto ]',
    name_pt:
      'Divulgação de se e como os ativos e atividades foram avaliados, a fim de identificar impactos, riscos e oportunidades reais e potenciais relacionados com a água e os recursos marinhos nas próprias operações e na cadeia de valor a montante e a jusante e metodologias, pressupostos e ferramentas utilizadas na triagem [bloco de texto ]'
  },
  {
    id: 'adae3645-2ef3-421f-9d8d-f23265f7217a',
    name_dcycle:
      'disclosure_of_how_consultations_have_been_conducted_(water_and_marine_resources)_[text_block]',
    datapoint_id: 'E3.IRO-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how consultations have been conducted (water and marine resources) [text block]',
    name_es:
      'Divulgación de cómo se han llevado a cabo las consultas (agua y recursos marinos) [bloque de texto]',
    name_pt:
      'Divulgação de como as consultas foram conduzidas (recursos hídricos e marinhos) [bloco de texto]'
  },
  {
    id: '467427f6-9d41-41d9-a9fd-a7c754d6c75d',
    name_dcycle: 'disclosure_of_whether_and_how_policy_adresses_water_management',
    datapoint_id: 'E3-1_01',
    esg_category: 'environmental',
    name_en: 'Disclosure of whether and how policy adresses water management',
    name_es: 'Divulgación de si la política aborda la gestión del agua y cómo',
    name_pt: 'Divulgação sobre se e como a política aborda a gestão da água'
  },
  {
    id: 'b2a49970-65d4-4cf4-ada5-b0738ff28aa8',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_adresses_the_use_and_sourcing_of_water_and_marine_resources_in_own_operations',
    datapoint_id: 'E3-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy adresses the use and sourcing of water and marine resources in own operations',
    name_es:
      'Divulgación de si la política aborda el uso y abastecimiento de agua y recursos marinos en sus propias operaciones y cómo lo hace.',
    name_pt:
      'Divulgação sobre se e como a política aborda o uso e a obtenção de recursos hídricos e marinhos nas próprias operações'
  },
  {
    id: '141a2bcb-71c4-4b23-8086-fa4cee0c07dc',
    name_dcycle: 'disclosure_of_whether_and_how_policy_adresses_water_treatment',
    datapoint_id: 'E3-1_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of whether and how policy adresses water treatment',
    name_es: 'Divulgación de si la política aborda el tratamiento del agua y cómo lo hace.',
    name_pt: 'Divulgação sobre se e como a política aborda o tratamento da água'
  },
  {
    id: 'da5b8dfb-c16d-47e8-9176-306dd6f41c60',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_adresses_prevention_and_abatment_of_water_pollution',
    datapoint_id: 'E3-1_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy adresses prevention and abatment of water pollution',
    name_es:
      'Divulgación de si la política aborda la prevención y reducción de la contaminación del agua y cómo',
    name_pt:
      'Divulgação sobre se e como a política aborda a prevenção e redução da poluição da água'
  },
  {
    id: '9c279eea-90bf-431a-ac7c-62252db89269',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_adresses_product_and_service_design_in_view_of_addressing_water-related_issues_and_preservation_of_marine_resources',
    datapoint_id: 'E3-1_05',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy adresses product and service design in view of addressing water-related issues and preservation of marine resources',
    name_es:
      'Divulgación de si la política aborda el diseño de productos y servicios, y de qué manera, con miras a abordar cuestiones relacionadas con el agua y la preservación de los recursos marinos.',
    name_pt:
      'Divulgação sobre se e como a política aborda o design de produtos e serviços com vista a abordar questões relacionadas com a água e a preservação dos recursos marinhos'
  },
  {
    id: '1137a2cf-e64d-46cb-be19-93c0a3335147',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_adresses_commitment_to_reduce_material_water_consumption_in_areas_at_water_risk',
    datapoint_id: 'E3-1_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy adresses commitment to reduce material water consumption in areas at water risk',
    name_es:
      'Divulgación de si la política aborda el compromiso de reducir el consumo material de agua en áreas con riesgo hídrico y cómo lo hace.',
    name_pt:
      'Divulgação sobre se e como a política aborda o compromisso de reduzir o consumo material de água em áreas com risco hídrico'
  },
  {
    id: '61c37ab3-09b8-400d-bdc8-ef0d9b56f6bd',
    name_dcycle:
      'disclosure_of_reasons_for_not_having_adopted_policies_in_areas_of_high-water_stress',
    datapoint_id: 'E3-1_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of reasons for not having adopted policies in areas of high-water stress',
    name_es:
      'Divulgación de razones por las que no se han adoptado políticas en zonas de alto estrés hídrico',
    name_pt:
      'Divulgação das razões para não ter adotado políticas em áreas de alto estresse hídrico'
  },
  {
    id: '3664abde-0192-427c-acf7-4a3b7df9dcb5',
    name_dcycle:
      'disclosure_of_timeframe_in_which_policies_in_areas_of_high-water_stress_will_be_adopted',
    datapoint_id: 'E3-1_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of timeframe in which policies in areas of high-water stress will be adopted',
    name_es:
      'Divulgación del calendario en el que se adoptarán políticas en áreas de alto estrés hídrico',
    name_pt:
      'Divulgação do cronograma em que as políticas em áreas de alto estresse hídrico serão adotadas'
  },
  {
    id: '0edc157a-24ea-4583-b6a4-be57da4428b4',
    name_dcycle: 'policies_or_practices_related_to_sustainable_oceans_and_seas_have_been_adopted',
    datapoint_id: 'E3-1_09',
    esg_category: 'environmental',
    name_en: 'Policies or practices related to sustainable oceans and seas have been adopted',
    name_es: 'Se han adoptado políticas o prácticas relacionadas con océanos y mares sostenibles.',
    name_pt: 'Foram adotadas políticas ou práticas relacionadas com oceanos e mares sustentáveis'
  },
  {
    id: '687364e6-4587-46dc-b6b7-704f625e425f',
    name_dcycle:
      'the_policly_contributes_to_good_ecological_and_chemical_quality_of_surface_water_bodies_and_good_chemical_quality_and_quantity_of_groundwater_bodies,_in_order_to_protect_human_health,_water_supply,_natural_ecosystems_and_biodiversity,_the_good_environmental_status_of_marine_waters_and_the_protection_of_the_resource_base_upon_which_marine_related_activities_depend;',
    datapoint_id: 'E3-1_10',
    esg_category: 'environmental',
    name_en:
      'The policly contributes to good ecological and chemical quality of surface water bodies and good chemical quality and quantity of groundwater bodies, in order to protect human health, water supply, natural ecosystems and biodiversity, the good environmental status of marine waters and the protection of the resource base upon which marine related activities depend;',
    name_es:
      'La política contribuye a la buena calidad ecológica y química de las masas de agua superficiales y a la buena calidad y cantidad química de las masas de aguas subterráneas, con el fin de proteger la salud humana, el abastecimiento de agua, los ecosistemas naturales y la biodiversidad, el buen estado medioambiental de las aguas marinas y la protección del base de recursos de la que dependen las actividades relacionadas con el mar;',
    name_pt:
      'A política contribui para uma boa qualidade ecológica e química das massas de água superficiais e para uma boa qualidade e quantidade química das massas de água subterrâneas, a fim de proteger a saúde humana, o abastecimento de água, os ecossistemas naturais e a biodiversidade, o bom estado ambiental das águas marinhas e a protecção do base de recursos da qual dependem as atividades relacionadas com o mar;'
  },
  {
    id: 'c0c654fd-347f-44da-b580-d0eaca17fbc4',
    name_dcycle:
      'the_policy_minimise_material_impacts_and_risks_and_implement_mitigation_measures_that_aim_to_maintain_the_value_and_functionality_of_priority_services_and_to_increase_resource_efficiency_on_own_operations',
    datapoint_id: 'E3-1_11',
    esg_category: 'environmental',
    name_en:
      'The policy minimise material impacts and risks and implement mitigation measures that aim to maintain the value and functionality of priority services and to increase resource efficiency on own operations',
    name_es:
      'La política minimiza los impactos y riesgos materiales e implementa medidas de mitigación que tienen como objetivo mantener el valor y la funcionalidad de los servicios prioritarios y aumentar la eficiencia de los recursos en las operaciones propias.',
    name_pt:
      'A política minimiza impactos e riscos materiais e implementa medidas de mitigação que visam manter o valor e a funcionalidade dos serviços prioritários e aumentar a eficiência dos recursos nas próprias operações'
  },
  {
    id: '0fd8b20c-185d-4fad-a5b9-eaec10dda303',
    name_dcycle: 'the_policy_avoid_impacts_on_affected_communities.',
    datapoint_id: 'E3-1_12',
    esg_category: 'environmental',
    name_en: 'The policy avoid impacts on affected communities.',
    name_es: 'La política evita impactos en las comunidades afectadas.',
    name_pt: 'A política evita impactos nas comunidades afetadas.'
  },
  {
    id: '4f7c34f4-c411-483c-8369-4d6d983046ca',
    name_dcycle:
      'layer_in_mitigation_hierarchy_to_which_action_and_resources_can_be_allocated_to_(water_and_marine_resources)',
    datapoint_id: 'E3-2_01',
    esg_category: 'environmental',
    name_en:
      'Layer in mitigation hierarchy to which action and resources can be allocated to (water and marine resources)',
    name_es:
      'Capa en la jerarquía de mitigación a la que se pueden asignar acciones y recursos (recursos hídricos y marinos)',
    name_pt:
      'Camada na hierarquia de mitigação à qual as ações e recursos podem ser alocados (recursos hídricos e marinhos)'
  },
  {
    id: '9511a2a5-d9c1-4933-aad4-32186ace4861',
    name_dcycle: 'information_about_specific_collective_action_for_water_and_marine_resources',
    datapoint_id: 'E3-2_02',
    esg_category: 'environmental',
    name_en: 'Information about specific collective action for water and marine resources',
    name_es:
      'Información sobre acciones colectivas específicas para el agua y los recursos marinos',
    name_pt: 'Informações sobre ações coletivas específicas para recursos hídricos e marinhos'
  },
  {
    id: '0daca228-52a2-412a-864b-035a4b73af2c',
    name_dcycle: 'disclosure_of_actions_and_resources_in_relation_to_areas_at_water_risk',
    datapoint_id: 'E3-2_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of actions and resources in relation to areas at water risk',
    name_es: 'Divulgación de acciones y recursos en relación con áreas en riesgo hídrico',
    name_pt: 'Divulgação de ações e recursos em relação às áreas de risco hídrico'
  },
  {
    id: '7e22d2f4-98d9-4baf-bf21-b5bd3f05f901',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_management_of_material_impacts,_risks_and_opportunities_related_to_areas_at_water_risk',
    datapoint_id: 'E3-3_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to management of material impacts, risks and opportunities related to areas at water risk',
    name_es:
      'Divulgación de si y cómo el objetivo se relaciona con la gestión de impactos, riesgos y oportunidades materiales relacionados con áreas en riesgo hídrico.',
    name_pt:
      'Divulgação de se e como a meta se relaciona com a gestão de impactos, riscos e oportunidades materiais relacionados a áreas com risco hídrico'
  },
  {
    id: '1940a8af-e62a-47ee-8fd0-86634cca8a2c',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_responsible_management_of_marine_resources_impacts,_risks_and_opportunities',
    datapoint_id: 'E3-3_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to responsible management of marine resources impacts, risks and opportunities',
    name_es:
      'Divulgación de si la meta se relaciona con la gestión responsable de los impactos, riesgos y oportunidades de los recursos marinos y de qué manera.',
    name_pt:
      'Divulgação de se e como a meta se relaciona com a gestão responsável dos impactos, riscos e oportunidades dos recursos marinhos'
  },
  {
    id: 'b853f29f-8b4b-431d-a5bc-881db2ece8c5',
    name_dcycle: 'disclosure_of_whether_and_how_target_relates_to_reduction_of_water_consumption',
    datapoint_id: 'E3-3_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of whether and how target relates to reduction of water consumption',
    name_es:
      'Divulgación de si el objetivo se relaciona con la reducción del consumo de agua y cómo',
    name_pt: 'Divulgação sobre se e como a meta está relacionada à redução do consumo de água'
  },
  {
    id: '911cbb4c-0665-404e-843c-37dace647174',
    name_dcycle:
      '(local)_ecological_threshold_and_entity-specific_allocation_were_taken_into_consideration_when_setting_water_and_marine_resources_target',
    datapoint_id: 'E3-3_04',
    esg_category: 'environmental',
    name_en:
      '(Local) ecological threshold and entity-specific allocation were taken into consideration when setting water and marine resources target',
    name_es:
      'Se tuvieron en cuenta el umbral ecológico (local) y la asignación específica de la entidad al establecer el objetivo de recursos hídricos y marinos.',
    name_pt:
      'O limiar ecológico (local) e a alocação específica da entidade foram levados em consideração ao definir a meta para os recursos hídricos e marinhos'
  },
  {
    id: '8a925db2-1500-4f27-b40b-39903467cbec',
    name_dcycle:
      'disclosure_of_ecological_threshold_identified_and_methodology_used_to_identify_ecological_threshold_(water_and_marine_resources)',
    datapoint_id: 'E3-3_05',
    esg_category: 'environmental',
    name_en:
      'Disclosure of ecological threshold identified and methodology used to identify ecological threshold (water and marine resources)',
    name_es:
      'Divulgación del umbral ecológico identificado y metodología utilizada para identificar el umbral ecológico (agua y recursos marinos)',
    name_pt:
      'Divulgação do limiar ecológico identificado e metodologia utilizada para identificar o limiar ecológico (recursos hídricos e marinhos)'
  },
  {
    id: '9dd66db8-6ac0-49ea-aa87-61ca6ab09d48',
    name_dcycle:
      'disclosure_of_how_ecological_entity-specific_threshold_was_determined_(water_and_marine_resources)',
    datapoint_id: 'E3-3_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how ecological entity-specific threshold was determined (water and marine resources)',
    name_es:
      'Divulgación de cómo se determinó el umbral ecológico específico de la entidad (agua y recursos marinos)',
    name_pt:
      'Divulgação de como foi determinado o limite específico da entidade ecológica (recursos hídricos e marinhos)'
  },
  {
    id: '796fd32e-41c2-45b6-a22f-18910850d5a5',
    name_dcycle:
      'disclosure_of_how_responsibility_for_respecting_identified_ecological_threshold_is_allocated_(water_and_marine_resources)',
    datapoint_id: 'E3-3_07',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how responsibility for respecting identified ecological threshold is allocated (water and marine resources)',
    name_es:
      'Divulgación de cómo se asigna la responsabilidad de respetar el umbral ecológico identificado (agua y recursos marinos)',
    name_pt:
      'Divulgação de como é atribuída a responsabilidade pelo respeito do limiar ecológico identificado (recursos hídricos e marinhos)'
  },
  {
    id: 'a2c08482-b447-4505-85de-250517e51927',
    name_dcycle:
      'adopted_and_presented_water_and_marine_resources-related_target_is_mandatory_(based_on_legislation)',
    datapoint_id: 'E3-3_08',
    esg_category: 'environmental',
    name_en:
      'Adopted and presented water and marine resources-related target is mandatory (based on legislation)',
    name_es:
      'La meta adoptada y presentada relacionada con el agua y los recursos marinos es obligatoria (según la legislación)',
    name_pt:
      'A meta relativa à água e aos recursos marinhos adotada e apresentada é obrigatória (com base na legislação)'
  },
  {
    id: '96ac40f7-6c09-4cc5-a3a3-5727d96cb24c',
    name_dcycle: 'target_relates_to_reduction_of_water_withdrawals',
    datapoint_id: 'E3-3_09',
    esg_category: 'environmental',
    name_en: 'Target relates to reduction of water withdrawals',
    name_es: 'El objetivo se refiere a la reducción de las extracciones de agua.',
    name_pt: 'Meta diz respeito à redução de captação de água'
  },
  {
    id: '73c9888d-341f-44c8-885d-a9d355f95bab',
    name_dcycle: 'target_relates_to_reduction_of_water_discharges',
    datapoint_id: 'E3-3_10',
    esg_category: 'environmental',
    name_en: 'Target relates to reduction of water discharges',
    name_es: 'El objetivo se refiere a la reducción de los vertidos de agua.',
    name_pt: 'Meta refere-se à redução de descargas de água'
  },
  {
    id: '4b11dab6-a5d2-404e-bb53-dd6c1620b5fb',
    name_dcycle: 'total_water_consumption',
    datapoint_id: 'E3-4_01',
    esg_category: 'environmental',
    name_en: 'Total water consumption',
    name_es: 'Consumo total de agua',
    name_pt: 'Consumo total de água'
  },
  {
    id: 'c60af1e6-4137-4d6f-ab0d-738c94484ceb',
    name_dcycle:
      'total_water_consumption_in_areas_at_water_risk,_including_areas_of_high-water_stress',
    datapoint_id: 'E3-4_02',
    esg_category: 'environmental',
    name_en: 'Total water consumption in areas at water risk, including areas of high-water stress',
    name_es:
      'Consumo total de agua en áreas con riesgo hídrico, incluidas áreas de alto estrés hídrico',
    name_pt:
      'Consumo total de água em áreas com risco hídrico, incluindo áreas com alto estresse hídrico'
  },
  {
    id: 'd46b75d0-36e9-48f2-bb48-50cf8f82e837',
    name_dcycle: 'total_water_recycled_and_reused',
    datapoint_id: 'E3-4_03',
    esg_category: 'environmental',
    name_en: 'Total water recycled and reused',
    name_es: 'Agua total reciclada y reutilizada',
    name_pt: 'Total de água reciclada e reutilizada'
  },
  {
    id: 'c5ad93ce-b879-4cc3-be2c-e0534b51cd6d',
    name_dcycle: 'total_water_stored',
    datapoint_id: 'E3-4_04',
    esg_category: 'environmental',
    name_en: 'Total water stored',
    name_es: 'Agua total almacenada',
    name_pt: 'Total de água armazenada'
  },
  {
    id: 'ec11330a-ef0b-455f-8037-6b0c27da84a7',
    name_dcycle: 'changes_in_water_storage',
    datapoint_id: 'E3-4_05',
    esg_category: 'environmental',
    name_en: 'Changes in water storage',
    name_es: 'Cambios en el almacenamiento de agua.',
    name_pt: 'Mudanças no armazenamento de água'
  },
  {
    id: '05e91cc2-c17b-4882-b749-778bc3c69297',
    name_dcycle: 'disclosure_of_contextual_information_regarding_warter_consumption',
    datapoint_id: 'E3-4_06',
    esg_category: 'environmental',
    name_en: 'Disclosure of contextual information regarding warter consumption',
    name_es: 'Divulgación de información contextual sobre el consumo de agua.',
    name_pt: 'Divulgação de informações contextuais sobre o consumo de guerra'
  },
  {
    id: '00698cd2-a49e-4d0f-87d5-3adc2a8d4369',
    name_dcycle:
      'share_of_the_measure_obtained_from_direct_measurement,_from_sampling_and_extrapolation,_or_from_best_estimates',
    datapoint_id: 'E3-4_07',
    esg_category: 'environmental',
    name_en:
      'Share of the measure obtained from direct measurement, from sampling and extrapolation, or from best estimates',
    name_es:
      'Proporción de la medida obtenida de la medición directa, del muestreo y la extrapolación, o de las mejores estimaciones',
    name_pt:
      'Parcela da medida obtida por medição direta, por amostragem e extrapolação ou por melhores estimativas'
  },
  {
    id: '2b6068d5-e592-4aef-aea1-173e218686cc',
    name_dcycle: 'water_intensity_ratio',
    datapoint_id: 'E3-4_08',
    esg_category: 'environmental',
    name_en: 'Water intensity ratio',
    name_es: 'Relación de intensidad del agua',
    name_pt: 'Razão de intensidade de água'
  },
  {
    id: '4648a9e6-dea0-47a4-90f1-316fc7eb7cee',
    name_dcycle: 'water_consumption_-_sectors/segments_[table]',
    datapoint_id: 'E3-4_09',
    esg_category: 'environmental',
    name_en: 'Water consumption - sectors/SEGMENTS [table]',
    name_es: 'Consumo de agua - sectores/SEGMENTOS [tabla]',
    name_pt: 'Consumo de água – setores/SEGMENTOS [tabela]'
  },
  {
    id: 'e4ad0a78-c4e7-4875-bcbb-4a6c4a7f27a2',
    name_dcycle: 'additional_water_intensity_ratio',
    datapoint_id: 'E3-4_10',
    esg_category: 'environmental',
    name_en: 'Additional water intensity ratio',
    name_es: 'Relación de intensidad de agua adicional',
    name_pt: 'Proporção de intensidade de água adicional'
  },
  {
    id: '25bdc5d9-269a-4379-b4eb-98201c3a25b3',
    name_dcycle: 'total_water_withdrawals',
    datapoint_id: 'E3-4_11',
    esg_category: 'environmental',
    name_en: 'Total water withdrawals',
    name_es: 'Extracción total de agua',
    name_pt: 'Total de retiradas de água'
  },
  {
    id: '2eea7ecf-2a28-4ef3-a2b0-6f4ee9af2205',
    name_dcycle: 'total_water_discharges',
    datapoint_id: 'E3-4_12',
    esg_category: 'environmental',
    name_en: 'Total water discharges',
    name_es: 'Vertidos totales de agua',
    name_pt: 'Descargas totais de água'
  },
  {
    id: '38ff58ab-04d8-4566-b078-6231a5444f09',
    name_dcycle:
      'disclosure_of_quantitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_water_and_marine_resources-related_impacts',
    datapoint_id: 'E3-5_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of quantitative information about anticipated financial effects of material risks and opportunities arising from water and marine resources-related impacts',
    name_es:
      'Divulgación de información cuantitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos relacionados con el agua y los recursos marinos.',
    name_pt:
      'Divulgação de informações quantitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos relacionados à água e aos recursos marinhos'
  },
  {
    id: '4218e6aa-a1cd-4282-a7fa-cbb0ce803f37',
    name_dcycle:
      'disclosure_of_qualitative_information_of_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_water_and_marine_resources-related_impacts',
    datapoint_id: 'E3-5_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of qualitative information of anticipated financial effects of material risks and opportunities arising from water and marine resources-related impacts',
    name_es:
      'Divulgación de información cualitativa de los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos relacionados con el agua y los recursos marinos.',
    name_pt:
      'Divulgação de informações qualitativas sobre efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos relacionados à água e aos recursos marinhos'
  },
  {
    id: 'd6c3acf2-ac20-419c-bffd-a1260b911039',
    name_dcycle:
      'description_of_effects_considered_and_related_impacts_(water_and_marine_resources)',
    datapoint_id: 'E3-5_03',
    esg_category: 'environmental',
    name_en: 'Description of effects considered and related impacts (water and marine resources)',
    name_es:
      'Descripción de los efectos considerados e impactos relacionados (agua y recursos marinos)',
    name_pt:
      'Descrição dos efeitos considerados e impactos relacionados (recursos hídricos e marinhos)'
  },
  {
    id: '0423db98-3b01-4730-9eaf-d2412af95f8b',
    name_dcycle:
      'disclosure_of_critical_assumptions_used_in_estimates_of_financial_effects_of_material_risks_and_opportunities_arising_from_water_and_marine_resources-related_impacts',
    datapoint_id: 'E3-5_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of critical assumptions used in estimates of financial effects of material risks and opportunities arising from water and marine resources-related impacts',
    name_es:
      'Divulgación de supuestos críticos utilizados en las estimaciones de los efectos financieros de los riesgos y oportunidades materiales que surgen de los impactos relacionados con el agua y los recursos marinos.',
    name_pt:
      'Divulgação de premissas críticas utilizadas nas estimativas de efeitos financeiros de riscos e oportunidades materiais decorrentes de impactos relacionados à água e aos recursos marinhos'
  },
  {
    id: '733343f1-9446-4607-89fb-27a08b927369',
    name_dcycle:
      'description_of_related_products_and_services_at_risk_(water_and_marine_resources)',
    datapoint_id: 'E3-5_05',
    esg_category: 'environmental',
    name_en: 'Description of related products and services at risk (water and marine resources)',
    name_es:
      'Descripción de productos y servicios relacionados en riesgo (agua y recursos marinos)',
    name_pt:
      'Descrição dos produtos e serviços relacionados em risco (recursos hídricos e marinhos)'
  },
  {
    id: '863d342d-3262-4bd0-ac6f-7810d3cd6a4e',
    name_dcycle:
      'explanation_of_how_time_horizons_are_defined,_financial_amounts_are_estimated_and_critical_assumptions_made_(water_and_marine_resources)',
    datapoint_id: 'E3-5_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of how time horizons are defined, financial amounts are estimated and critical assumptions made (water and marine resources)',
    name_es:
      'Explicación de cómo se definen los horizontes temporales, se estiman los montos financieros y se hacen los supuestos críticos (recursos hídricos y marinos)',
    name_pt:
      'Explicação de como os horizontes temporais são definidos, os montantes financeiros são estimados e as suposições críticas são feitas (recursos hídricos e marinhos)'
  },
  {
    id: '19846a66-691e-4779-bfd3-9bba6ca2ffa2',
    name_dcycle: 'list_of_material_sites_in_own_operation',
    datapoint_id: 'E4.SBM-3_01',
    esg_category: 'environmental',
    name_en: 'List of material sites in own operation',
    name_es: 'Lista de sitios de materiales en operación propia',
    name_pt: 'Lista de sites de materiais em operação própria'
  },
  {
    id: 'e8038ab9-0c04-4ac8-b893-6c2fb301e847',
    name_dcycle: 'disclosure_of_activities_negatively_affecting_biodiversity_sensitive_areeas',
    datapoint_id: 'E4.SBM-3_02',
    esg_category: 'environmental',
    name_en: 'Disclosure of activities negatively affecting biodiversity sensitive areeas',
    name_es:
      'Divulgación de actividades que afectan negativamente áreas sensibles a la biodiversidad',
    name_pt: 'Divulgação de atividades que afetam negativamente áreas sensíveis à biodiversidade'
  },
  {
    id: 'a8e9e925-e315-4e1d-84af-87a1ffb8d90b',
    name_dcycle:
      'disclosure_of_list_of_material_sites_in_own_operations_based_on_results_of_identification_and_assessment_of_actual_and_potential_impacts_on_biodiversity_and_ecosystems',
    datapoint_id: 'E4.SBM-3_03',
    esg_category: 'environmental',
    name_en:
      'Disclosure of list of material sites in own operations based on results of identification and assessment of actual and potential impacts on biodiversity and ecosystems',
    name_es:
      'Divulgación de la lista de sitios materiales en operaciones propias con base en los resultados de la identificación y evaluación de los impactos reales y potenciales sobre la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação de lista de locais materiais em operações próprias com base em resultados de identificação e avaliação de impactos reais e potenciais na biodiversidade e nos ecossistemas'
  },
  {
    id: 'ff8d4d20-6c1e-4d83-933f-5a10cfa4bbd2',
    name_dcycle: 'disclosure_of_biodiversity-sensitive_areas_impacted',
    datapoint_id: 'E4.SBM-3_04',
    esg_category: 'environmental',
    name_en: 'Disclosure of biodiversity-sensitive areas impacted',
    name_es: 'Divulgación de áreas sensibles a la biodiversidad impactadas',
    name_pt: 'Divulgação de áreas sensíveis à biodiversidade impactadas'
  },
  {
    id: 'f2f637d9-dcfd-4fbb-a8eb-930e0dc0c735',
    name_dcycle:
      'material_negative_impacts_with_regards_to_land_degradation,_desertification_or_soil_sealing_have_been_identified',
    datapoint_id: 'E4.SBM-3_05',
    esg_category: 'environmental',
    name_en:
      'Material negative impacts with regards to land degradation, desertification or soil sealing have been identified',
    name_es:
      'Se han identificado impactos materiales negativos en relación con la degradación de la tierra, la desertificación o el sellado del suelo.',
    name_pt:
      'Foram identificados impactos negativos materiais no que diz respeito à degradação dos solos, à desertificação ou à impermeabilização dos solos'
  },
  {
    id: 'f92ce1da-9031-4c65-82fe-faca7d10293f',
    name_dcycle: 'own_operations_affect_threatened_species',
    datapoint_id: 'E4.SBM-3_06',
    esg_category: 'environmental',
    name_en: 'Own operations affect threatened species',
    name_es: 'Operaciones propias afectan a especies amenazadas',
    name_pt: 'Operações próprias afetam espécies ameaçadas'
  },
  {
    id: '40cf7c86-e52d-4004-a79a-c761ba9b9a22',
    name_dcycle:
      'disclosure_of_whether_and_how_actual_and_potential_impacts_on_biodiversity_and_ecosystems_at_own_site_locations_and_in_value_chain_have_been_identified_and_assessed',
    datapoint_id: 'E4.IRO-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how actual and potential impacts on biodiversity and ecosystems at own site locations and in value chain have been identified and assessed',
    name_es:
      'Divulgación de si y cómo se han identificado y evaluado los impactos reales y potenciales sobre la biodiversidad y los ecosistemas en las ubicaciones del propio sitio y en la cadena de valor.',
    name_pt:
      'Divulgação de se e como os impactos reais e potenciais sobre a biodiversidade e os ecossistemas nas próprias instalações e na cadeia de valor foram identificados e avaliados'
  },
  {
    id: 'fd648151-6a01-4519-ba35-8123e0cc4b54',
    name_dcycle:
      'disclosure_of_whether_and_how_dependencies_on_biodiversity_and_ecosystems_and_their_services_have_been_identified_and_assessed_at_own_site_locations_and_in_value_chain',
    datapoint_id: 'E4.IRO-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how dependencies on biodiversity and ecosystems and their services have been identified and assessed at own site locations and in value chain',
    name_es:
      'Divulgación de si y cómo se han identificado y evaluado las dependencias de la biodiversidad y los ecosistemas y sus servicios en las ubicaciones del propio sitio y en la cadena de valor.',
    name_pt:
      'Divulgação de se e como as dependências da biodiversidade e dos ecossistemas e dos seus serviços foram identificadas e avaliadas nas próprias instalações e na cadeia de valor'
  },
  {
    id: '4f8b9aca-66af-4068-a72d-6b91b13f4e5f',
    name_dcycle:
      'disclosure_of_whether_and_how_transition_and_physical_risks_and_opportunities_related_to_biodiversity_and_ecosystems_have_been_identified_and_assessed',
    datapoint_id: 'E4.IRO-1_03',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how transition and physical risks and opportunities related to biodiversity and ecosystems have been identified and assessed',
    name_es:
      'Divulgación de si se han identificado y evaluado los riesgos y oportunidades físicos y de transición relacionados con la biodiversidad y los ecosistemas, y de qué manera',
    name_pt:
      'Divulgação sobre se e como os riscos e oportunidades físicas e de transição relacionados com a biodiversidade e os ecossistemas foram identificados e avaliados'
  },
  {
    id: '91934419-9c8f-43c2-ba58-2a4c9ad44434',
    name_dcycle:
      'disclosure_of_whether_and_how_systemic_risks_have_been_considered_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4.IRO-1_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how systemic risks have been considered (biodiversity and ecosystems)',
    name_es:
      'Divulgación de si se han considerado los riesgos sistémicos (biodiversidad y ecosistemas) y cómo',
    name_pt:
      'Divulgação sobre se e como os riscos sistêmicos foram considerados (biodiversidade e ecossistemas)'
  },
  {
    id: '64b5749f-2977-49a4-a99b-fd17fa0a4952',
    name_dcycle:
      'disclosure_of_whether_and_how_consultations_with_affected_communities_on_sustainability_assessments_of_shared_biological_resources_and_ecosystems_have_been_conducted',
    datapoint_id: 'E4.IRO-1_05',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how consultations with affected communities on sustainability assessments of shared biological resources and ecosystems have been conducted',
    name_es:
      'Divulgación de si se han realizado consultas con las comunidades afectadas sobre evaluaciones de sostenibilidad de los recursos biológicos y ecosistemas compartidos y cómo',
    name_pt:
      'Divulgação sobre se e como foram realizadas consultas com as comunidades afetadas sobre avaliações de sustentabilidade de recursos biológicos e ecossistemas compartilhados'
  },
  {
    id: 'a97006d6-e8a9-4e41-94b4-dbb207d8f8d1',
    name_dcycle:
      'disclosure_of_whether_and_how_specific_sites,_raw_materials_production_or_sourcing_with_negative_or_potential_negative_impacts_on_affected_communities',
    datapoint_id: 'E4.IRO-1_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how specific sites, raw materials production or sourcing with negative or potential negative impacts on affected communities',
    name_es:
      'Divulgación de si sitios específicos, producción o abastecimiento de materias primas tienen impactos negativos o potenciales negativos en las comunidades afectadas y cómo.',
    name_pt:
      'Divulgação de se e como locais específicos, produção ou fornecimento de matérias-primas têm impactos negativos ou potenciais negativos nas comunidades afetadas'
  },
  {
    id: '0cf3622a-b0a4-429a-b14d-9089eeaba8f2',
    name_dcycle:
      'disclosure_of_whether_and_how_communities_were_involved_in_materiality_assessment',
    datapoint_id: 'E4.IRO-1_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of whether and how communities were involved in materiality assessment',
    name_es:
      'Divulgación de si las comunidades participaron en la evaluación de materialidad y de qué manera',
    name_pt:
      'Divulgação sobre se e como as comunidades foram envolvidas na avaliação de materialidade'
  },
  {
    id: 'fdaafbcc-8302-4dc9-a559-659ce624141d',
    name_dcycle:
      'disclosure_of_whether_and_how_negative_impacts_on_priority_ecosystem_services_of_relevance_to_affected_communities_may_be_avoided',
    datapoint_id: 'E4.IRO-1_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how negative impacts on priority ecosystem services of relevance to affected communities may be avoided',
    name_es:
      'Divulgación de si se pueden evitar, y cómo, los impactos negativos en los servicios ecosistémicos prioritarios de relevancia para las comunidades afectadas.',
    name_pt:
      'Divulgação de se e como os impactos negativos nos serviços ecossistêmicos prioritários de relevância para as comunidades afetadas podem ser evitados'
  },
  {
    id: '2451f839-0be4-4e09-800c-79fbda967565',
    name_dcycle:
      'disclosure_of_plans_to_minimise_unavoidable_negative_impacts_and_implement_mitigation_measures_that_aim_to_maintain_value_and_functionality_of_priority_services',
    datapoint_id: 'E4.IRO-1_09',
    esg_category: 'environmental',
    name_en:
      'Disclosure of plans to minimise unavoidable negative impacts and implement mitigation measures that aim to maintain value and functionality of priority services',
    name_es:
      'Divulgación de planes para minimizar los impactos negativos inevitables e implementar medidas de mitigación que tengan como objetivo mantener el valor y la funcionalidad de los servicios prioritarios.',
    name_pt:
      'Divulgação de planos para minimizar impactos negativos inevitáveis ​​e implementar medidas de mitigação que visam manter o valor e a funcionalidade dos serviços prioritários'
  },
  {
    id: 'cff70ff5-b136-42da-873a-6f7a2e0f9a86',
    name_dcycle:
      'disclosure_of_whether_and_how_tthe_business_model(s)_has_been_verified_using_range_of_biodiversity_and_ecosystems_scenarios,_or_other_scenarios_with_modelling_of_biodiversity_and_ecosystems_related_consequences,_with_different_possible_pathways',
    datapoint_id: 'E4.IRO-1_10',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how tthe business model(s) has been verified using range of biodiversity and ecosystems scenarios, or other scenarios with modelling of biodiversity and ecosystems related consequences, with different possible pathways',
    name_es:
      'Divulgación de si y cómo se han verificado los modelos de negocio utilizando una variedad de escenarios de biodiversidad y ecosistemas, u otros escenarios con modelado de consecuencias relacionadas con la biodiversidad y los ecosistemas, con diferentes caminos posibles.',
    name_pt:
      'Divulgação de se e como o(s) modelo(s) de negócios foram verificados usando uma variedade de cenários de biodiversidade e ecossistemas, ou outros cenários com modelagem de consequências relacionadas à biodiversidade e aos ecossistemas, com diferentes caminhos possíveis'
  },
  {
    id: 'f35f662e-c22a-4991-b2e0-cfa4234d143f',
    name_dcycle: 'disclosure_of_why_considered_scenarios_were_taken_into_consideration',
    datapoint_id: 'E4.IRO-1_11',
    esg_category: 'environmental',
    name_en: 'Disclosure of why considered scenarios were taken into consideration',
    name_es: 'Divulgación de por qué se tomaron en consideración los escenarios considerados',
    name_pt: 'Divulgação do motivo pelo qual os cenários considerados foram levados em consideração'
  },
  {
    id: '97f6dff2-9a92-4aac-bcae-c421057205ee',
    name_dcycle:
      'disclosure_of_how_considered_scenarios_are_updated_according_to_evolving_conditions_and_emerging_trends',
    datapoint_id: 'E4.IRO-1_12',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how considered scenarios are updated according to evolving conditions and emerging trends',
    name_es:
      'Divulgación de cómo se actualizan los escenarios considerados de acuerdo con las condiciones cambiantes y las tendencias emergentes.',
    name_pt:
      'Divulgação de como os cenários considerados são atualizados de acordo com a evolução das condições e tendências emergentes'
  },
  {
    id: '6a227195-dab8-4972-ad1a-adbd0e51844c',
    name_dcycle:
      'scenarios_are_informed_by_expectations_in_authoritative_intergovernmental_instruments_and_by_scientific_consensus',
    datapoint_id: 'E4.IRO-1_13',
    esg_category: 'environmental',
    name_en:
      'Scenarios are informed by expectations in authoritative intergovernmental instruments and by scientific consensus',
    name_es:
      'Los escenarios se basan en las expectativas contenidas en instrumentos intergubernamentales autorizados y en el consenso científico.',
    name_pt:
      'Os cenários são informados pelas expectativas em instrumentos intergovernamentais autorizados e pelo consenso científico'
  },
  {
    id: '060baf02-93b3-4710-8626-d308ee166969',
    name_dcycle: 'undertaking_has_sites_located_in_or_near_biodiversity-sensitive_areas',
    datapoint_id: 'E4.IRO-1_14',
    esg_category: 'environmental',
    name_en: 'Undertaking has sites located in or near biodiversity-sensitive areas',
    name_es: 'La empresa tiene sitios ubicados en o cerca de áreas sensibles a la biodiversidad',
    name_pt: 'A empresa possui locais localizados em ou perto de áreas sensíveis à biodiversidade'
  },
  {
    id: '95f7511b-d9f1-44be-992f-f6e6ddff1250',
    name_dcycle:
      'activities_related_to_sites_located_in_or_near_biodiversity-sensitive_areas_negatively_affect_these_areas_by_leading_to_deterioration_of_natural_habitats_and_habitats_of_species_and_to_disturbance_of_species_for_which_protected_area_has_been_designated',
    datapoint_id: 'E4.IRO-1_15',
    esg_category: 'environmental',
    name_en:
      'Activities related to sites located in or near biodiversity-sensitive areas negatively affect these areas by leading to deterioration of natural habitats and habitats of species and to disturbance of species for which protected area has been designated',
    name_es:
      'Las actividades relacionadas con sitios ubicados en o cerca de áreas sensibles a la biodiversidad afectan negativamente a estas áreas al provocar el deterioro de los hábitats naturales y de los hábitats de las especies y la perturbación de las especies para las cuales se ha designado un área protegida.',
    name_pt:
      'As atividades relacionadas com sítios localizados em ou perto de zonas sensíveis à biodiversidade afetam negativamente essas zonas, conduzindo à deterioração dos habitats naturais e dos habitats das espécies e à perturbação das espécies para as quais foram designadas áreas protegidas'
  },
  {
    id: '94b5dc79-282b-458f-b28b-b4eea269478e',
    name_dcycle:
      'it_has_been_concluded_that_it_is_necessary_to_implement_biodiversity_mitigation_measures',
    datapoint_id: 'E4.IRO-1_16',
    esg_category: 'environmental',
    name_en:
      'It has been concluded that it is necessary to implement biodiversity mitigation measures',
    name_es:
      'Se ha concluido que es necesario implementar medidas de mitigación de la biodiversidad',
    name_pt: 'Concluiu-se que é necessário implementar medidas de mitigação da biodiversidade'
  },
  {
    id: '11891f4f-a926-4cfc-a915-709d8682c6c4',
    name_dcycle:
      'disclosure_of_resilience_of_current_business_model(s)_and_strategy_to_biodiversity_and_ecosystems-related_physical,_transition_and_systemic_risks_and_opportunities',
    datapoint_id: 'E4-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of resilience of current business model(s) and strategy to biodiversity and ecosystems-related physical, transition and systemic risks and opportunities',
    name_es:
      'Divulgación de la resiliencia de los modelos de negocio actuales y la estrategia ante los riesgos y oportunidades físicos, de transición y sistémicos relacionados con la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação da resiliência do(s) atual(is) modelo(s) de negócios e estratégia para a biodiversidade e riscos e oportunidades físicos, de transição e sistêmicos relacionados aos ecossistemas'
  },
  {
    id: '943d42ce-11c7-41dd-84fa-2b1918e41e80',
    name_dcycle:
      'disclosure_of_scope_of_resilience_analysis_along_own_operations_and_related_upstream_and_downstream_value_chain',
    datapoint_id: 'E4-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of scope of resilience analysis along own operations and related upstream and downstream value chain',
    name_es:
      'Divulgación del alcance del análisis de resiliencia a lo largo de las operaciones propias y la cadena de valor ascendente y descendente relacionada.',
    name_pt:
      'Divulgação do escopo da análise de resiliência ao longo das próprias operações e da cadeia de valor upstream e downstream relacionada'
  },
  {
    id: '3741d9b9-a268-4e7a-82f1-d49a37587ee9',
    name_dcycle: 'disclosure_of_key_assumptions_made_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of key assumptions made (biodiversity and ecosystems)',
    name_es: 'Divulgación de supuestos clave formulados (biodiversidad y ecosistemas)',
    name_pt: 'Divulgação das principais suposições feitas (biodiversidade e ecossistemas)'
  },
  {
    id: 'fbd13420-ca4f-49f9-bde4-401f1ce36617',
    name_dcycle: 'disclosure_of_time_horizons_used_for_analysis_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_04',
    esg_category: 'environmental',
    name_en: 'Disclosure of time horizons used for analysis (biodiversity and ecosystems)',
    name_es:
      'Divulgación de los horizontes temporales utilizados para el análisis (biodiversidad y ecosistemas)',
    name_pt:
      'Divulgação dos horizontes temporais utilizados para análise (biodiversidade e ecossistemas)'
  },
  {
    id: '2f7c23fe-301d-4766-9f3f-7d04b80c9af1',
    name_dcycle: 'disclosure_of_results_of_resilience_analysis_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_05',
    esg_category: 'environmental',
    name_en: 'Disclosure of results of resilience analysis (biodiversity and ecosystems)',
    name_es: 'Divulgación de resultados de análisis de resiliencia (biodiversidad y ecosistemas)',
    name_pt: 'Divulgação dos resultados da análise de resiliência (biodiversidade e ecossistemas)'
  },
  {
    id: '9889457a-ec84-4e01-9354-68552e3ba6b7',
    name_dcycle: 'disclosure_of_involvement_of_stakeholders_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_06',
    esg_category: 'environmental',
    name_en: 'Disclosure of involvement of stakeholders (biodiversity and ecosystems)',
    name_es:
      'Divulgación de la participación de las partes interesadas (biodiversidad y ecosistemas)',
    name_pt: 'Divulgação do envolvimento das partes interessadas (biodiversidade e ecossistemas)'
  },
  {
    id: '15135d77-3632-4702-860a-6b51a85eedc5',
    name_dcycle:
      'disclosure_of_transition_plan_to_improve_and_achieve_alignment_of_its_business_model_and_strategy',
    datapoint_id: 'E4-1_07',
    esg_category: 'environmental',
    name_en:
      'Disclosure of transition plan to improve and achieve alignment of its business model and strategy',
    name_es:
      'Divulgación del plan de transición para mejorar y lograr la alineación de su modelo de negocio y estrategia.',
    name_pt:
      'Divulgação do plano de transição para melhorar e alinhar seu modelo de negócios e estratégia'
  },
  {
    id: 'a4e805aa-6a8b-4aec-9a45-52ef4d36bc66',
    name_dcycle:
      'explanation_of_how_strategy_and_business_model_will_be_adjusted_to_improve_and,_ultimately,_achieve_alignment_with_relevant_local,_national_and_global_public_policy_goals',
    datapoint_id: 'E4-1_08',
    esg_category: 'environmental',
    name_en:
      'Explanation of how strategy and business model will be adjusted to improve and, ultimately, achieve alignment with relevant local, national and global public policy goals',
    name_es:
      'Explicación de cómo se ajustarán la estrategia y el modelo de negocio para mejorar y, en última instancia, lograr la alineación con los objetivos relevantes de políticas públicas locales, nacionales y globales.',
    name_pt:
      'Explicação de como a estratégia e o modelo de negócios serão ajustados para melhorar e, em última análise, alcançar o alinhamento com os objetivos relevantes de políticas públicas locais, nacionais e globais'
  },
  {
    id: '6353f482-ce18-4822-a3e3-07c41535fa36',
    name_dcycle:
      'include_information_about_its_own_operations_and_explain_how_it_is_responding_to_material_impacts_in_its_related_value_chain',
    datapoint_id: 'E4-1_09',
    esg_category: 'environmental',
    name_en:
      'Include information about its own operations and explain how it is responding to material impacts in its related value chain',
    name_es:
      'Incluir información sobre sus propias operaciones y explicar cómo está respondiendo a los impactos materiales en su cadena de valor relacionada.',
    name_pt:
      'Incluir informações sobre suas próprias operações e explicar como está respondendo aos impactos materiais em sua cadeia de valor relacionada'
  },
  {
    id: '3da0a678-b238-4e03-b2aa-8b6a895a001a',
    name_dcycle: 'explanation_of_how_b_strategy_interacts_with_transition_plan',
    datapoint_id: 'E4-1_10',
    esg_category: 'environmental',
    name_en: 'Explanation of how b strategy interacts with transition plan',
    name_es: 'Explicación de cómo interactúa la estrategia b con el plan de transición',
    name_pt: 'Explicação de como a estratégia b interage com o plano de transição'
  },
  {
    id: '05cdb3e0-76ce-4e62-97b9-7f28fead9f56',
    name_dcycle:
      'disclosure_of_contribution_to_impact_drivers_and_possible_mitigation_actions_following_mitigation_hierarchy_and_main_path-dependencies_and_locked-in_assets_and_resources_that_are_associated_with_biodiversity_and_ecosystems_change',
    datapoint_id: 'E4-1_11',
    esg_category: 'environmental',
    name_en:
      'Disclosure of contribution to impact drivers and possible mitigation actions following mitigation hierarchy and main path-dependencies and locked-in assets and resources that are associated with biodiversity and ecosystems change',
    name_es:
      'Divulgación de la contribución a los impulsores del impacto y posibles acciones de mitigación siguiendo la jerarquía de mitigación y las principales dependencias de la ruta y los activos y recursos bloqueados que están asociados con el cambio de la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação da contribuição para os fatores de impacto e possíveis ações de mitigação seguindo a hierarquia de mitigação e as principais dependências de trajetória e ativos e recursos bloqueados que estão associados à biodiversidade e à mudança dos ecossistemas'
  },
  {
    id: 'f874124b-53c7-4331-b62c-aa6fda72d569',
    name_dcycle:
      'explanation_and_quantification_of_investments_and_funding_supporting_the_implementation_of_its_transition_plan',
    datapoint_id: 'E4-1_12',
    esg_category: 'environmental',
    name_en:
      'Explanation and quantification of investments and funding supporting the implementation of its transition plan',
    name_es:
      'Explicación y cuantificación de las inversiones y financiación que apoyan la implementación de su plan de transición.',
    name_pt:
      'Explicação e quantificação dos investimentos e financiamentos de apoio à implementação do seu plano de transição'
  },
  {
    id: 'd2a3c021-0b16-41ec-9446-6986f31eea21',
    name_dcycle:
      'disclosure_of_objectives_or_plans_for_aligning_economic_activities_(revenues,_capex)',
    datapoint_id: 'E4-1_13',
    esg_category: 'environmental',
    name_en: 'Disclosure of objectives or plans for aligning economic activities (revenues, CapEx)',
    name_es:
      'Divulgación de objetivos o planes para alinear actividades económicas (ingresos, CapEx)',
    name_pt:
      'Divulgação de objetivos ou planos de alinhamento das atividades económicas (receitas, CapEx)'
  },
  {
    id: '4fb11269-84ff-474d-a502-d1696e6f57ad',
    name_dcycle: 'biodiversity_offsets_are_part_of_transition_plan',
    datapoint_id: 'E4-1_14',
    esg_category: 'environmental',
    name_en: 'Biodiversity offsets are part of transition plan',
    name_es: 'Las compensaciones por biodiversidad son parte del plan de transición',
    name_pt: 'As compensações de biodiversidade fazem parte do plano de transição'
  },
  {
    id: '8771abe1-eb32-475d-a510-06713a549be6',
    name_dcycle:
      'information_about_how_process_of_implementing_and_updating_transition_plan_is_managed',
    datapoint_id: 'E4-1_15',
    esg_category: 'environmental',
    name_en:
      'Information about how process of implementing and updating transition plan is managed',
    name_es:
      'Información sobre cómo se gestiona el proceso de implementación y actualización del plan de transición.',
    name_pt:
      'Informações sobre como é gerenciado o processo de implementação e atualização do plano de transição'
  },
  {
    id: 'b1d1b355-30fb-47de-96ea-e1e96cfd506e',
    name_dcycle:
      'indication_of_metrics_and_related_tools_used_to_measure_progress_that_are_integrated_in_measurement_approach_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_16',
    esg_category: 'environmental',
    name_en:
      'Indication of metrics and related tools used to measure progress that are integrated in measurement approach (biodiversity and ecosystems)',
    name_es:
      'Indicación de métricas y herramientas relacionadas utilizadas para medir el progreso que están integradas en el enfoque de medición (biodiversidad y ecosistemas)',
    name_pt:
      'Indicação de métricas e ferramentas relacionadas usadas para medir o progresso que estão integradas na abordagem de medição (biodiversidade e ecossistemas)'
  },
  {
    id: '88ff1a1a-61b3-4fae-b721-c3bd16ed4c99',
    name_dcycle: 'administrative,_management_and_supervisory_bodies_have_approved_transition_plan',
    datapoint_id: 'E4-1_17',
    esg_category: 'environmental',
    name_en: 'Administrative, management and supervisory bodies have approved transition plan',
    name_es:
      'Los órganos de administración, dirección y supervisión han aprobado el plan de transición',
    name_pt: 'Órgãos de administração, gestão e fiscalização aprovaram plano de transição'
  },
  {
    id: '588782de-4768-4704-a144-6309b031e8c1',
    name_dcycle:
      'indication_of_current_challenges_and_limitations_to_draft_plan_in_relation_to_areas_of_significant_impact_and_actions_company_is_taking_to_address_them_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_18',
    esg_category: 'environmental',
    name_en:
      'Indication of current challenges and limitations to draft plan in relation to areas of significant impact and actions company is taking to address them (biodiversity and ecosystems)',
    name_es:
      'Indicación de los desafíos y limitaciones actuales del borrador del plan en relación con áreas de impacto significativo y las acciones que la empresa está tomando para abordarlas (biodiversidad y ecosistemas)',
    name_pt:
      'Indicação dos desafios e limitações atuais para elaborar o plano em relação a áreas de impacto significativo e ações que a empresa está tomando para enfrentá-los (biodiversidade e ecossistemas)'
  },
  {
    id: '6cc0ba57-7744-4bda-bd95-ac7c279740be',
    name_dcycle:
      'disclosure_on_whether_and_how_biodiversity_and_ecosystems-related_policies_relate_to_matters_reported_in_e4_ar4',
    datapoint_id: 'E4-2_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure on whether and how biodiversity and ecosystems-related policies relate to matters reported in E4 AR4',
    name_es:
      'Divulgación sobre si y cómo las políticas relacionadas con la biodiversidad y los ecosistemas se relacionan con los asuntos reportados en E4 AR4',
    name_pt:
      'Divulgação sobre se e como as políticas relacionadas com a biodiversidade e os ecossistemas se relacionam com as questões relatadas em E4 AR4'
  },
  {
    id: 'bfea7b15-13d9-4810-83f7-7de8aec18496',
    name_dcycle:
      'explanation_of_whether_and_how_biodiversity_and_ecosystems-related_policy_relates_to_material_biodiversity_and_ecosystems-related_impacts',
    datapoint_id: 'E4-2_02',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how biodiversity and ecosystems-related policy relates to material biodiversity and ecosystems-related impacts',
    name_es:
      'Explicación de si y cómo la política relacionada con la biodiversidad y los ecosistemas se relaciona con los impactos materiales relacionados con la biodiversidad y los ecosistemas.',
    name_pt:
      'Explicação sobre se e como a política relacionada com a biodiversidade e os ecossistemas se relaciona com a biodiversidade material e com os impactos relacionados com os ecossistemas'
  },
  {
    id: 'f63a7389-b0c4-49bf-acd3-25b571373b40',
    name_dcycle:
      'explanation_of_whether_and_how_biodiversity_and_ecosystems-related_policy_relates_to_material_dependencies_and_material_physical_and_transition_risks_and_opportunities',
    datapoint_id: 'E4-2_03',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how biodiversity and ecosystems-related policy relates to material dependencies and material physical and transition risks and opportunities',
    name_es:
      'Explicación de si y cómo la política relacionada con la biodiversidad y los ecosistemas se relaciona con dependencias materiales y riesgos y oportunidades materiales físicos y de transición.',
    name_pt:
      'Explicação sobre se e como a política relacionada à biodiversidade e aos ecossistemas se relaciona com dependências materiais e riscos e oportunidades materiais, físicos e de transição'
  },
  {
    id: '0f80d4aa-d645-4656-8233-7db6d0d78de0',
    name_dcycle:
      'explanation_of_whether_and_how_biodiversity_and_ecosystems-related_policy_supports_traceability_of_products,_components_and_raw_materials_with_significant_actual_or_potential_impacts_on_biodiversity_and_ecosystems_along_value_chain',
    datapoint_id: 'E4-2_04',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how biodiversity and ecosystems-related policy supports traceability of products, components and raw materials with significant actual or potential impacts on biodiversity and ecosystems along value chain',
    name_es:
      'Explicación de si las políticas relacionadas con la biodiversidad y los ecosistemas apoyan, y de qué manera, la trazabilidad de productos, componentes y materias primas con impactos significativos, reales o potenciales, sobre la biodiversidad y los ecosistemas a lo largo de la cadena de valor.',
    name_pt:
      'Explicação sobre se e como a política relacionada com a biodiversidade e os ecossistemas apoia a rastreabilidade de produtos, componentes e matérias-primas com impactos reais ou potenciais significativos na biodiversidade e nos ecossistemas ao longo da cadeia de valor'
  },
  {
    id: 'bca7e5fc-6a82-4e20-a921-149f89f75f78',
    name_dcycle:
      'explanation_of_whether_and_how_biodiversity_and_ecosystems-related_policy_addresses_production,_sourcing_or_consumption_from_ecosystems_that_are_managed_to_maintain_or_enhance_conditions_for_biodiversity',
    datapoint_id: 'E4-2_05',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how biodiversity and ecosystems-related policy addresses production, sourcing or consumption from ecosystems that are managed to maintain or enhance conditions for biodiversity',
    name_es:
      'Explicación de si las políticas relacionadas con la biodiversidad y los ecosistemas abordan, y de qué manera, la producción, el abastecimiento o el consumo de ecosistemas que se gestionan para mantener o mejorar las condiciones para la biodiversidad.',
    name_pt:
      'Explicação sobre se e como a política relacionada com a biodiversidade e os ecossistemas aborda a produção, o abastecimento ou o consumo de ecossistemas que são geridos para manter ou melhorar as condições para a biodiversidade'
  },
  {
    id: '54c241ec-24ef-4755-946f-33d162a90769',
    name_dcycle:
      'explanation_of_whether_and_how_biodiversity_and_ecosystems-related_policy_addresses_social_consequences_of_biodiversity_and_ecosystems-related_impacts',
    datapoint_id: 'E4-2_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how biodiversity and ecosystems-related policy addresses social consequences of biodiversity and ecosystems-related impacts',
    name_es:
      'Explicación de si las políticas relacionadas con la biodiversidad y los ecosistemas abordan las consecuencias sociales de los impactos relacionados con la biodiversidad y los ecosistemas y de qué manera',
    name_pt:
      'Explicação sobre se e como as políticas relacionadas à biodiversidade e aos ecossistemas abordam as consequências sociais da biodiversidade e dos impactos relacionados aos ecossistemas'
  },
  {
    id: 'd6694785-d0b7-45f2-97cd-802933569aac',
    name_dcycle:
      'disclosure_of_how_policy_refers_to_production,_sourcing_or_consumption_of_raw_materials',
    datapoint_id: 'E4-2_07',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how policy refers to production, sourcing or consumption of raw materials',
    name_es:
      'Divulgación de cómo la política se refiere a la producción, el abastecimiento o el consumo de materias primas.',
    name_pt:
      'Divulgação de como a política se refere à produção, fornecimento ou consumo de matérias-primas'
  },
  {
    id: '74efb5c1-6cda-4872-a05c-78bb71b6aa1d',
    name_dcycle:
      'disclosure_of_how_policy_refers_to_policies_limiting_procurement_from_suppliers_that_cannot_demonstrate_that_they_are_not_contributing_to_significant_conversion_of_protected_areas_or_key_biodiversity_areas',
    datapoint_id: 'E4-2_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how policy refers to policies limiting procurement from suppliers that cannot demonstrate that they are not contributing to significant conversion of protected areas or key biodiversity areas',
    name_es:
      'Divulgación de cómo la política se refiere a las políticas que limitan las adquisiciones de proveedores que no pueden demostrar que no están contribuyendo a una conversión significativa de áreas protegidas o áreas clave de biodiversidad.',
    name_pt:
      'Divulgação de como a política se refere a políticas que limitam a aquisição de fornecedores que não conseguem demonstrar que não estão contribuindo para a conversão significativa de áreas protegidas ou áreas-chave de biodiversidade'
  },
  {
    id: '0d32c000-65f7-4aa2-8f83-2a4e2a69ed50',
    name_dcycle:
      'disclosure_of_how_policy_refers_to_recognised_standards_or_third-party_certifications_overseen_by_regulators',
    datapoint_id: 'E4-2_09',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how policy refers to recognised standards or third-party certifications overseen by regulators',
    name_es:
      'Divulgación de cómo la política se refiere a estándares reconocidos o certificaciones de terceros supervisadas por los reguladores.',
    name_pt:
      'Divulgação de como a política se refere a padrões reconhecidos ou certificações de terceiros supervisionadas por reguladores'
  },
  {
    id: 'f2ab7b54-6786-49d6-a4aa-e7a4d1500f6d',
    name_dcycle:
      'disclosure_of_how_policy_addresses_raw_materials_originating_from_ecosystems_that_have_been_managed_to_maintain_or_enhance_conditions_for_biodiversity,_as_demonstrated_by_regular_monitoring_and_reporting_of_biodiversity_status_and_gains_or_losses',
    datapoint_id: 'E4-2_10',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how policy addresses raw materials originating from ecosystems that have been managed to maintain or enhance conditions for biodiversity, as demonstrated by regular monitoring and reporting of biodiversity status and gains or losses',
    name_es:
      'Divulgación de cómo las políticas abordan las materias primas provenientes de ecosistemas que han sido manejados para mantener o mejorar las condiciones para la biodiversidad, como lo demuestra el monitoreo y la presentación de informes periódicos sobre el estado y las ganancias o pérdidas de la biodiversidad.',
    name_pt:
      'Divulgação de como a política aborda as matérias-primas provenientes de ecossistemas que foram geridos para manter ou melhorar as condições para a biodiversidade, conforme demonstrado pela monitorização e relatórios regulares do estado da biodiversidade e dos ganhos ou perdas'
  },
  {
    id: 'ddb16a9a-7707-486f-a8e8-923fc891313e',
    name_dcycle: 'disclosure_of_how_the_policy_enables_to_a),_b),_c)_and_d)',
    datapoint_id: 'E4-2_11',
    esg_category: 'environmental',
    name_en: 'Disclosure of how the policy enables to a), b), c) and d)',
    name_es: 'Divulgación de cómo la póliza permite a), b), c) y d)',
    name_pt: 'Divulgação de como a política permite a), b), c) e d)'
  },
  {
    id: 'e7fa9ef5-0a20-4f86-914a-e5c8ef242fa5',
    name_dcycle:
      'third-party_standard_of_conduct_used_in_policy_is_objective_and_achievable_based_on_scientific_approach_to_identifying_issues_and_realistic_in_assessing_how_these_issues_can_be_addressed_under_variety_of_practical_circumstances',
    datapoint_id: 'E4-2_12',
    esg_category: 'environmental',
    name_en:
      'Third-party standard of conduct used in policy is objective and achievable based on scientific approach to identifying issues and realistic in assessing how these issues can be addressed under variety of practical circumstances',
    name_es:
      'El estándar de conducta de terceros utilizado en las políticas es objetivo y alcanzable basándose en un enfoque científico para identificar problemas y realista al evaluar cómo se pueden abordar estos problemas en una variedad de circunstancias prácticas.',
    name_pt:
      'O padrão de conduta de terceiros usado na política é objetivo e alcançável com base na abordagem científica para identificar problemas e realista na avaliação de como esses problemas podem ser abordados sob diversas circunstâncias práticas'
  },
  {
    id: '9c2668ff-c65e-4d0d-b150-3c03b9de5bcb',
    name_dcycle:
      'third-party_standard_of_conduct_used_in_policy_is_developed_or_maintained_through_process_of_ongoing_consultation_with_relevant_stakeholders_with_balanced_input_from_all_relevant_stakeholder_groups_with_no_group_holding_undue_authority_or_veto_power_over_content',
    datapoint_id: 'E4-2_13',
    esg_category: 'environmental',
    name_en:
      'Third-party standard of conduct used in policy is developed or maintained through process of ongoing consultation with relevant stakeholders with balanced input from all relevant stakeholder groups with no group holding undue authority or veto power over content',
    name_es:
      'El estándar de conducta de terceros utilizado en la política se desarrolla o mantiene a través de un proceso de consulta continua con las partes interesadas relevantes con aportes equilibrados de todos los grupos de partes interesadas relevantes, sin que ningún grupo tenga autoridad indebida o poder de veto sobre el contenido.',
    name_pt:
      'O padrão de conduta de terceiros usado na política é desenvolvido ou mantido por meio de um processo de consulta contínua com as partes interessadas relevantes, com contribuições equilibradas de todos os grupos de partes interessadas relevantes, sem que nenhum grupo detenha autoridade indevida ou poder de veto sobre o conteúdo'
  },
  {
    id: '2f01463e-716c-4d89-9985-7856e0d7e5cf',
    name_dcycle:
      'third-party_standard_of_conduct_used_in_policy_encourages_step-wise_approach_and_continuous_improvement_in_standard_and_its_application_of_better_management_practices_and_requires_establishment_of_meaningful_targets_and_specific_milestones_to_indicate_progress_against_principles_and_criteria_over_time',
    datapoint_id: 'E4-2_14',
    esg_category: 'environmental',
    name_en:
      'Third-party standard of conduct used in policy encourages step-wise approach and continuous improvement in standard and its application of better management practices and requires establishment of meaningful targets and specific milestones to indicate progress against principles and criteria over time',
    name_es:
      'El estándar de conducta de terceros utilizado en la política fomenta un enfoque gradual y una mejora continua en el estándar y su aplicación de mejores prácticas de gestión y requiere el establecimiento de objetivos significativos e hitos específicos para indicar el progreso con respecto a los principios y criterios a lo largo del tiempo.',
    name_pt:
      'O padrão de conduta de terceiros usado na política incentiva a abordagem gradual e a melhoria contínua do padrão e sua aplicação de melhores práticas de gestão e exige o estabelecimento de metas significativas e marcos específicos para indicar o progresso em relação aos princípios e critérios ao longo do tempo'
  },
  {
    id: 'd09ef319-ed5a-43d0-94e9-29c2c7743983',
    name_dcycle:
      'third-party_standard_of_conduct_used_in_policy_is_verifiable_through_independent_certifying_or_verifying_bodies,_which_have_defined_and_rigorous_assessment_procedures_that_avoid_conflicts_of_interest_and_are_compliant_with_iso_guidance_on_accreditation_and_verification_procedures_or_article_5(2)_of_regulation_(ec)_no_765/2008',
    datapoint_id: 'E4-2_15',
    esg_category: 'environmental',
    name_en:
      'Third-party standard of conduct used in policy is verifiable through independent certifying or verifying bodies, which have defined and rigorous assessment procedures that avoid conflicts of interest and are compliant with ISO guidance on accreditation and verification procedures or Article 5(2) of Regulation (EC) No 765/2008',
    name_es:
      'El estándar de conducta de terceros utilizado en la política es verificable a través de organismos certificadores o verificadores independientes, que tienen procedimientos de evaluación definidos y rigurosos que evitan conflictos de intereses y cumplen con la guía ISO sobre procedimientos de acreditación y verificación o el artículo 5(2) del Reglamento ( CE) nº 765/2008',
    name_pt:
      'O padrão de conduta de terceiros utilizado na política é verificável através de organismos independentes de certificação ou verificação, que possuem procedimentos de avaliação definidos e rigorosos que evitam conflitos de interesse e estão em conformidade com as orientações da ISO sobre procedimentos de acreditação e verificação ou com o Artigo 5 (2) do Regulamento ( CE) n.º 765/2008'
  },
  {
    id: 'bd9a39ad-849c-4ea4-9766-4f8a182443e9',
    name_dcycle:
      'third-party_standard_of_conduct_used_in_policy_conforms_to_iseal_code_of_good_practice',
    datapoint_id: 'E4-2_16',
    esg_category: 'environmental',
    name_en:
      'Third-party standard of conduct used in policy conforms to ISEAL Code of Good Practice',
    name_es:
      'El estándar de conducta de terceros utilizado en la política se ajusta al Código de buenas prácticas de ISEAL',
    name_pt:
      'O padrão de conduta de terceiros usado na política está em conformidade com o Código de Boas Práticas da ISEAL'
  },
  {
    id: 'cd0d3276-114a-4889-be55-d7fbf22f5a2f',
    name_dcycle:
      'biodiversity_and_ecosystem_protection_policy_covering_operational_sites_owned,_leased,_managed_in_or_near_protected_area_or_biodiversity-sensitive_area_outside_protected_areas_has_been_adopted',
    datapoint_id: 'E4-2_17',
    esg_category: 'environmental',
    name_en:
      'Biodiversity and ecosystem protection policy covering operational sites owned, leased, managed in or near protected area or biodiversity-sensitive area outside protected areas has been adopted',
    name_es:
      'Se ha adoptado una política de protección de la biodiversidad y los ecosistemas que cubre los sitios operativos de propiedad, arrendados o administrados dentro o cerca de un área protegida o un área sensible a la biodiversidad fuera de las áreas protegidas.',
    name_pt:
      'Foi adotada uma política de proteção da biodiversidade e dos ecossistemas que abrange locais operacionais pertencentes, arrendados ou geridos dentro ou perto de áreas protegidas ou áreas sensíveis à biodiversidade fora das áreas protegidas.'
  },
  {
    id: 'acbdbb0f-84eb-4f08-8a81-dff6a32c4f38',
    name_dcycle: 'sustainable_land_or_agriculture_practices_or_policies_have_been_adopted',
    datapoint_id: 'E4-2_18',
    esg_category: 'environmental',
    name_en: 'Sustainable land or agriculture practices or policies have been adopted',
    name_es:
      'Se han adoptado prácticas o políticas sostenibles en materia de tierras o agricultura.',
    name_pt: 'Práticas ou políticas sustentáveis ​​de terra ou agricultura foram adotadas'
  },
  {
    id: '60989053-9d49-4250-bbde-0f591098995e',
    name_dcycle: 'sustainable_oceans_or_seas_practices_or_policies_have_been_adopted',
    datapoint_id: 'E4-2_19',
    esg_category: 'environmental',
    name_en: 'Sustainable oceans or seas practices or policies have been adopted',
    name_es: 'Se han adoptado prácticas o políticas sostenibles en materia de océanos o mares.',
    name_pt: 'Práticas ou políticas sustentáveis ​​para oceanos ou mares foram adotadas'
  },
  {
    id: 'bf10312f-ba6a-4be7-9a3c-c9ba39342aaf',
    name_dcycle: 'policies_to_address_deforestation_have_been_adopted',
    datapoint_id: 'E4-2_20',
    esg_category: 'environmental',
    name_en: 'Policies to address deforestation have been adopted',
    name_es: 'Se han adoptado políticas para abordar la deforestación',
    name_pt: 'Políticas para enfrentar o desmatamento foram adotadas'
  },
  {
    id: '4f5f3dde-fb07-4786-8224-d324b3d58feb',
    name_dcycle:
      'disclosure_on_how_the_mitigation_hierarchy_has_been_applied_with_regard_to_biodiversity_and_ecosystem_actions',
    datapoint_id: 'E4-3_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure on how the mitigation hierarchy has been applied with regard to biodiversity and ecosystem actions',
    name_es:
      'Divulgación sobre cómo se ha aplicado la jerarquía de mitigación con respecto a las acciones de biodiversidad y ecosistemas.',
    name_pt:
      'Divulgação sobre como a hierarquia de mitigação foi aplicada em relação à biodiversidade e às ações ecossistêmicas'
  },
  {
    id: '2e0d8043-dc76-4df8-acfb-33f0e5ed5bf5',
    name_dcycle: 'biodiversity_offsets_were_used_in_action_plan',
    datapoint_id: 'E4-3_02',
    esg_category: 'environmental',
    name_en: 'Biodiversity offsets were used in action plan',
    name_es: 'Se utilizaron compensaciones de biodiversidad en el plan de acción',
    name_pt: 'Compensações de biodiversidade foram usadas em plano de ação'
  },
  {
    id: 'ae14ac06-4f65-49f0-8b85-111aa774e89a',
    name_dcycle: 'disclosure_of_aim_of_biodiversity_offset_and_key_performance_indicators_used',
    datapoint_id: 'E4-3_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of aim of biodiversity offset and key performance indicators used',
    name_es:
      'Divulgación del objetivo de la compensación por biodiversidad y de los indicadores clave de desempeño utilizados',
    name_pt:
      'Divulgação do objetivo da compensação de biodiversidade e dos principais indicadores de desempenho utilizados'
  },
  {
    id: 'c5a8a3c1-96ba-48c0-a0f9-d7e07cc494f9',
    name_dcycle: 'financing_effects_(direct_and_indirect_costs)_of_biodiversity_offsets',
    datapoint_id: 'E4-3_04',
    esg_category: 'environmental',
    name_en: 'Financing effects (direct and indirect costs) of biodiversity offsets',
    name_es:
      'Efectos financieros (costos directos e indirectos) de las compensaciones de biodiversidad',
    name_pt:
      'Efeitos de financiamento (custos diretos e indiretos) das compensações de biodiversidade'
  },
  {
    id: '038eaeeb-bf0a-4c7c-a690-dc9cd28d7378',
    name_dcycle:
      'explanation_of_rekationship_of_significant_capex_and_opex_required_to_impelement_actions_taken_or_planned_to_relevant_line_items_or_notes_in_the_financial_statements',
    datapoint_id: 'E4-3_05',
    esg_category: 'environmental',
    name_en:
      'Explanation of rekationship of significant Capex and Opex required to impelement actions taken or planned to relevant line items or notes in the financial statements',
    name_es:
      'Explicación de la relación de Capex y Opex significativos necesarios para implementar las acciones tomadas o planificadas para partidas o notas relevantes en los estados financieros.',
    name_pt:
      'Explicação do relacionamento de Capex e Opex significativos necessários para implementar ações tomadas ou planejadas para itens de linha ou notas relevantes nas demonstrações financeiras'
  },
  {
    id: '7faaf206-1c9c-4489-a32e-295cffd3b637',
    name_dcycle:
      'explanation_of_rekationship_of_significant_capex_and_opex_required_to_impelement_actions_taken_or_planned_to_key_performance_indicators_required_under_commission_delegated_regulation_(eu)_2021/2178',
    datapoint_id: 'E4-3_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of rekationship of significant Capex and Opex required to impelement actions taken or planned to key performance indicators required under Commission Delegated Regulation (EU) 2021/2178',
    name_es:
      'Explicación de la relación de Capex y Opex significativos necesarios para implementar las acciones tomadas o planificadas para los indicadores clave de rendimiento requeridos en virtud del Reglamento Delegado (UE) 2021/2178 de la Comisión.',
    name_pt:
      'Explicação da relação de Capex e Opex significativos necessários para implementar ações tomadas ou planejadas para os principais indicadores de desempenho exigidos pelo Regulamento Delegado (UE) 2021/2178 da Comissão'
  },
  {
    id: '1d5d9873-6713-4057-ba4b-1ada93a7b3b7',
    name_dcycle:
      'explanation_of_rekationship_of_significant_capex_and_opex_required_to_impelement_actions_taken_or_planned_to_capex_plan_required_under_commission_delegated_regulation_(eu)_2021/2178',
    datapoint_id: 'E4-3_07',
    esg_category: 'environmental',
    name_en:
      'Explanation of rekationship of significant Capex and Opex required to impelement actions taken or planned to Capex plan required under Commission Delegated Regulation (EU) 2021/2178',
    name_es:
      'Explicación de la relación de Capex y Opex significativos necesarios para implementar las acciones tomadas o planificadas según el plan de Capex requerido según el Reglamento Delegado (UE) 2021/2178 de la Comisión.',
    name_pt:
      'Explicação do relacionamento de Capex e Opex significativos necessários para implementar as ações tomadas ou planejadas para o plano de Capex exigido pelo Regulamento Delegado da Comissão (UE) 2021/2178'
  },
  {
    id: '7d8ff2cd-c2fb-4000-ae2d-de820a0a74e1',
    name_dcycle: 'description_of_biodiversity_offsets',
    datapoint_id: 'E4-3_08',
    esg_category: 'environmental',
    name_en: 'Description of biodiversity offsets',
    name_es: 'Descripción de las compensaciones por biodiversidad',
    name_pt: 'Descrição das compensações de biodiversidade'
  },
  {
    id: '18ce219b-8b46-4395-9833-39b1583ea8fd',
    name_dcycle:
      'description_of_whether_and_how_local_and_indigenous_knowledge_and_nature-based_solutions_have_been_incorporated_into_biodiversity_and_ecosystems-related_action',
    datapoint_id: 'E4-3_09',
    esg_category: 'environmental',
    name_en:
      'Description of whether and how local and indigenous knowledge and nature-based solutions have been incorporated into biodiversity and ecosystems-related action',
    name_es:
      'Descripción de si y cómo se han incorporado los conocimientos locales e indígenas y las soluciones basadas en la naturaleza en las acciones relacionadas con la biodiversidad y los ecosistemas.',
    name_pt:
      'Descrição de se e como o conhecimento local e indígena e as soluções baseadas na natureza foram incorporados na biodiversidade e nas ações relacionadas aos ecossistemas'
  },
  {
    id: 'f194c8fd-47a2-4d7b-96ec-52b7f56ee927',
    name_dcycle:
      'disclosure_of_key_stakeholders_involved_and_how_they_are_involved,_key_stakeholders_negatively_or_positively_impacted_by_action_and_how_they_are_impacted',
    datapoint_id: 'E4-3_10',
    esg_category: 'environmental',
    name_en:
      'Disclosure of key stakeholders involved and how they are involved, key stakeholders negatively or positively impacted by action and how they are impacted',
    name_es:
      'Divulgación de las partes interesadas clave involucradas y cómo participan, las partes interesadas clave afectadas negativa o positivamente por la acción y cómo se ven afectadas',
    name_pt:
      'Divulgação das principais partes interessadas envolvidas e como elas estão envolvidas, das principais partes interessadas impactadas negativa ou positivamente pela ação e como elas são impactadas'
  },
  {
    id: '8621a368-d0d8-43c9-8e9a-deff76bfad69',
    name_dcycle:
      'explanation_of_need_for_appropriate_consultations_and_need_to_respect_decisions_of_affected_communities',
    datapoint_id: 'E4-3_11',
    esg_category: 'environmental',
    name_en:
      'Explanation of need for appropriate consultations and need to respect decisions of affected communities',
    name_es:
      'Explicación de la necesidad de realizar consultas apropiadas y de respetar las decisiones de las comunidades afectadas',
    name_pt:
      'Explicação da necessidade de consultas apropriadas e da necessidade de respeitar as decisões das comunidades afetadas'
  },
  {
    id: 'cb6ad02e-eb34-4e49-8642-62d29f1ebcdf',
    name_dcycle:
      'description_of_whether_key_action_may_induce_significant_negative_sustainability_impacts_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-3_12',
    esg_category: 'environmental',
    name_en:
      'Description of whether key action may induce significant negative sustainability impacts (biodiversity and ecosystems)',
    name_es:
      'Descripción de si una acción clave puede inducir impactos negativos significativos en la sostenibilidad (biodiversidad y ecosistemas)',
    name_pt:
      'Descrição se a ação-chave pode induzir impactos negativos significativos na sustentabilidade (biodiversidade e ecossistemas)'
  },
  {
    id: 'd903a26e-56d3-4bc4-b12e-ee685433c734',
    name_dcycle:
      'explanation_of_whether_the_key_action_is_intended_to_be_a_one-time_initiative_or_systematic_practice',
    datapoint_id: 'E4-3_13',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether the key action is intended to be a one-time initiative or systematic practice',
    name_es:
      'Explicación de si la acción clave pretende ser una iniciativa única o una práctica sistemática',
    name_pt:
      'Explicação sobre se a ação-chave se destina a ser uma iniciativa única ou uma prática sistemática'
  },
  {
    id: '6d83f94a-f71f-4f97-ab46-1c5866b4131f',
    name_dcycle:
      'key_action_plan_is_carried_out_only_by_undertaking_(individual_action)_using_its_resources_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-3_14',
    esg_category: 'environmental',
    name_en:
      'Key action plan is carried out only by undertaking (individual action) using its resources (biodiversity and ecosystems)',
    name_es:
      'El plan de acción clave se lleva a cabo únicamente mediante la realización (acción individual) utilizando sus recursos (biodiversidad y ecosistemas).',
    name_pt:
      'O plano de ação principal é executado apenas através da realização (ação individual) utilizando os seus recursos (biodiversidade e ecossistemas)'
  },
  {
    id: 'a3181e93-ff88-42bb-b8ea-cdd73eb02262',
    name_dcycle:
      'key_action_plan_is_part_of_wider_action_plan_(collective_action),_of_which_undertaking_is_member_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-3_15',
    esg_category: 'environmental',
    name_en:
      'Key action plan is part of wider action plan (collective action), of which undertaking is member (biodiversity and ecosystems)',
    name_es:
      'El plan de acción clave forma parte de un plan de acción más amplio (acción colectiva) del que la empresa es miembro (biodiversidad y ecosistemas).',
    name_pt:
      'O plano de ação-chave faz parte de um plano de ação mais amplo (ação coletiva), do qual a empresa é membro (biodiversidade e ecossistemas)'
  },
  {
    id: '843c5bdd-48db-41fd-b60f-d07381aac9e2',
    name_dcycle:
      'additional_information_about_project,_its_sponsors_and_other_participants_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-3_16',
    esg_category: 'environmental',
    name_en:
      'Additional information about project, its sponsors and other participants (biodiversity and ecosystems)',
    name_es:
      'Información adicional sobre el proyecto, sus patrocinadores y otros participantes (biodiversidad y ecosistemas)',
    name_pt:
      'Informações adicionais sobre o projeto, seus patrocinadores e demais participantes (biodiversidade e ecossistemas)'
  },
  {
    id: 'f819476e-58d0-4e1c-8de0-28d12c8b9d2c',
    name_dcycle:
      'ecological_threshold_and_allocation_of_impacts_to_undertaking_were_applied_when_setting_target_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-4_01',
    esg_category: 'environmental',
    name_en:
      'Ecological threshold and allocation of impacts to undertaking were applied when setting target (biodiversity and ecosystems)',
    name_es:
      'El umbral ecológico y la asignación de impactos a la empresa se aplicaron al establecer el objetivo (biodiversidad y ecosistemas)',
    name_pt:
      'O limite ecológico e a alocação de impactos ao empreendimento foram aplicados na definição da meta (biodiversidade e ecossistemas)'
  },
  {
    id: 'cfeb56f0-8b90-4da9-b387-1560cc1c2952',
    name_dcycle:
      'disclosure_of_ecological_threshold_identified_and_methodology_used_to_identify_threshold_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-4_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of ecological threshold identified and methodology used to identify threshold (biodiversity and ecosystems)',
    name_es:
      'Divulgación del umbral ecológico identificado y metodología utilizada para identificar el umbral (biodiversidad y ecosistemas)',
    name_pt:
      'Divulgação do limiar ecológico identificado e metodologia utilizada para identificar o limiar (biodiversidade e ecossistemas)'
  },
  {
    id: 'f4aa7ffe-5c46-4813-93fd-88b56d6929ad',
    name_dcycle:
      'disclosure_of_how_entity-specific_threshold_was_determined_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-4_03',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how entity-specific threshold was determined (biodiversity and ecosystems)',
    name_es:
      'Divulgación de cómo se determinó el umbral específico de la entidad (biodiversidad y ecosistemas)',
    name_pt:
      'Divulgação de como o limite específico da entidade foi determinado (biodiversidade e ecossistemas)'
  },
  {
    id: '34fe4454-134c-44f6-96a9-63de6788167a',
    name_dcycle:
      'disclosure_of_how_responsibility_for_respecting_identified_ecological_threshold_is_allocated_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-4_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how responsibility for respecting identified ecological threshold is allocated (biodiversity and ecosystems)',
    name_es:
      'Divulgación de cómo se asigna la responsabilidad de respetar el umbral ecológico identificado (biodiversidad y ecosistemas)',
    name_pt:
      'Divulgação de como é atribuída a responsabilidade pelo respeito do limiar ecológico identificado (biodiversidade e ecossistemas)'
  },
  {
    id: 'deceb1f8-eeaf-4d32-9aa5-b0267f476b59',
    name_dcycle: 'target_is_informed_by_relevant_aspect_of_eu_biodiversity_strategy_for_2030',
    datapoint_id: 'E4-4_05',
    esg_category: 'environmental',
    name_en: 'Target is informed by relevant aspect of EU Biodiversity Strategy for 2030',
    name_es:
      'El objetivo se basa en aspectos relevantes de la Estrategia de Biodiversidad de la UE para 2030',
    name_pt:
      'A meta é informada por aspectos relevantes da Estratégia de Biodiversidade da UE para 2030'
  },
  {
    id: '5347bc2f-dfc7-438a-a868-50fc2ed0c5fb',
    name_dcycle:
      'disclosure_of_how_the_targets_relate_to_the_biodiversity_and_ecosystem_impacts,_dependencies,_risks_and_opportunities_identified_in_relation_to_own_operations_and_upstream_and_downstream_value_chain',
    datapoint_id: 'E4-4_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how the targets relate to the biodiversity and ecosystem impacts, dependencies, risks and opportunities identified in relation to own operations and upstream and downstream value chain',
    name_es:
      'Divulgación de cómo los objetivos se relacionan con los impactos, dependencias, riesgos y oportunidades de la biodiversidad y los ecosistemas identificados en relación con las operaciones propias y la cadena de valor upstream y downstream.',
    name_pt:
      'Divulgação de como as metas se relacionam com os impactos, dependências, riscos e oportunidades na biodiversidade e nos ecossistemas identificados em relação às próprias operações e à cadeia de valor a montante e a jusante'
  },
  {
    id: '669f6181-199a-4afd-b2a3-315a980cd0f2',
    name_dcycle: 'disclosure_of_the_geographical_scope_of_the_targets',
    datapoint_id: 'E4-4_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of the geographical scope of the targets',
    name_es: 'Divulgación del alcance geográfico de los objetivos',
    name_pt: 'Divulgação do âmbito geográfico dos alvos'
  },
  {
    id: '91b59f57-1d83-4a03-87e9-0a26d31a4c91',
    name_dcycle: 'biodiversity_offsets_were_used_in_setting_target',
    datapoint_id: 'E4-4_08',
    esg_category: 'environmental',
    name_en: 'Biodiversity offsets were used in setting target',
    name_es: 'Se utilizaron compensaciones de biodiversidad para establecer objetivos.',
    name_pt: 'As compensações de biodiversidade foram usadas na definição de metas'
  },
  {
    id: '4f129dd6-9850-42a3-8267-126088cdb446',
    name_dcycle:
      'layer_in_mitigation_hierarchy_to_which_target_can_be_allocated_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-4_09',
    esg_category: 'environmental',
    name_en:
      'Layer in mitigation hierarchy to which target can be allocated (biodiversity and ecosystems)',
    name_es:
      'Capa en la jerarquía de mitigación a la que se puede asignar el objetivo (biodiversidad y ecosistemas)',
    name_pt:
      'Camada na hierarquia de mitigação à qual a meta pode ser alocada (biodiversidade e ecossistemas)'
  },
  {
    id: '2789cb31-2c7f-4f7d-af7d-3a9de5e47a33',
    name_dcycle:
      'the_target_addresses_shortcomings_related_to_the_substantial_contribution_criteria',
    datapoint_id: 'E4-4_10',
    esg_category: 'environmental',
    name_en: 'The target addresses shortcomings related to the Substantial Contribution criteria',
    name_es:
      'La meta aborda las deficiencias relacionadas con los criterios de contribución sustancial.',
    name_pt: 'A meta aborda deficiências relacionadas com os critérios de contribuição substancial'
  },
  {
    id: '64285ec6-652d-4191-89dd-4a30b10ffd52',
    name_dcycle:
      'number_of_sites_owned,_leased_or_managed_in_or_near_protected_areas_or_key_biodiversity_areas_that_undertaking_is_negatively_affecting',
    datapoint_id: 'E4-5_01',
    esg_category: 'environmental',
    name_en:
      'Number of sites owned, leased or managed in or near protected areas or key biodiversity areas that undertaking is negatively affecting',
    name_es:
      'Número de sitios de propiedad, arrendados o administrados en o cerca de áreas protegidas o áreas clave de biodiversidad que la iniciativa está afectando negativamente',
    name_pt:
      'Número de locais pertencentes, arrendados ou geridos dentro ou perto de áreas protegidas ou áreas chave de biodiversidade que o empreendimento está a afectar negativamente'
  },
  {
    id: '61894b55-be50-438e-acc3-0e5898ada50d',
    name_dcycle:
      'area_of_sites_owned,_leased_or_managed_in_or_near_protected_areas_or_key_biodiversity_areas_that_undertaking_is_negatively_affecting',
    datapoint_id: 'E4-5_02',
    esg_category: 'environmental',
    name_en:
      'Area of sites owned, leased or managed in or near protected areas or key biodiversity areas that undertaking is negatively affecting',
    name_es:
      'Área de sitios de propiedad, arrendados o administrados en o cerca de áreas protegidas o áreas clave de biodiversidad que la empresa está afectando negativamente',
    name_pt:
      'Área de locais pertencentes, arrendados ou administrados dentro ou perto de áreas protegidas ou áreas-chave de biodiversidade que o empreendimento está afetando negativamente'
  },
  {
    id: 'e35bb50c-5158-46ed-bb84-e002de9b3619',
    name_dcycle: 'disclosure_of_land-use_based_on_life_cycle_assessment',
    datapoint_id: 'E4-5_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of land-use based on Life Cycle Assessment',
    name_es: 'Divulgación del uso de la tierra basada en la Evaluación del Ciclo de Vida',
    name_pt: 'Divulgação do uso da terra com base na Avaliação do Ciclo de Vida'
  },
  {
    id: '4673020c-f726-4090-bae8-c521b591a539',
    name_dcycle:
      'disclosure_of_metrics_considered_relevant_(land-use_change,_freshwater-use_change_and_(or)_sea-use_change)',
    datapoint_id: 'E4-5_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of metrics considered relevant (land-use change, freshwater-use change and (or) sea-use change)',
    name_es:
      'Divulgación de métricas consideradas relevantes (cambio de uso de la tierra, cambio de uso de agua dulce y (o) cambio de uso del mar)',
    name_pt:
      'Divulgação de métricas consideradas relevantes (mudança no uso do solo, mudança no uso da água doce e (ou) mudança no uso do mar)'
  },
  {
    id: '95404bcd-5402-4178-82cc-7ce15301113a',
    name_dcycle: 'disclosure_of_conversion_over_time_of_land_cover',
    datapoint_id: 'E4-5_05',
    esg_category: 'environmental',
    name_en: 'Disclosure of conversion over time of land cover',
    name_es: 'Divulgación de la conversión a lo largo del tiempo de la cobertura terrestre',
    name_pt: 'Divulgação da conversão ao longo do tempo da cobertura do solo'
  },
  {
    id: '06cd8867-0c85-4a67-8152-51990c818f61',
    name_dcycle: 'disclosure_of_changes_over_time_in_management_of_ecosystem',
    datapoint_id: 'E4-5_06',
    esg_category: 'environmental',
    name_en: 'Disclosure of changes over time in management of ecosystem',
    name_es: 'Divulgación de cambios a lo largo del tiempo en la gestión del ecosistema.',
    name_pt: 'Divulgação de mudanças ao longo do tempo na gestão do ecossistema'
  },
  {
    id: '4f239e58-ac0c-42ae-83ec-03761f70f4d7',
    name_dcycle: 'disclosure_of_changes_in_spatial_configuration_of_landscape',
    datapoint_id: 'E4-5_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of changes in spatial configuration of landscape',
    name_es: 'Divulgación de cambios en la configuración espacial del paisaje.',
    name_pt: 'Divulgação de mudanças na configuração espacial da paisagem'
  },
  {
    id: '63152bdd-f5b8-4510-9188-23c4ab557a93',
    name_dcycle: 'disclosure_of_changes_in_ecosystem_structural_connectivity',
    datapoint_id: 'E4-5_08',
    esg_category: 'environmental',
    name_en: 'Disclosure of changes in ecosystem structural connectivity',
    name_es: 'Divulgación de cambios en la conectividad estructural de los ecosistemas.',
    name_pt: 'Divulgação de mudanças na conectividade estrutural do ecossistema'
  },
  {
    id: '3d32ca47-74dc-49eb-b7a9-bc6a0be090d8',
    name_dcycle: 'disclosure_of_functional_connectivity',
    datapoint_id: 'E4-5_09',
    esg_category: 'environmental',
    name_en: 'Disclosure of functional connectivity',
    name_es: 'Divulgación de conectividad funcional.',
    name_pt: 'Divulgação de conectividade funcional'
  },
  {
    id: '3317997a-dfcf-42cc-bdee-3b08c1ac0f32',
    name_dcycle: 'total_use_of_land_area',
    datapoint_id: 'E4-5_10',
    esg_category: 'environmental',
    name_en: 'Total use of land area',
    name_es: 'Uso total de la superficie terrestre',
    name_pt: 'Uso total da área do terreno'
  },
  {
    id: 'd527a3cc-35fb-4dd6-b310-a22ff66685bc',
    name_dcycle: 'total_sealed_area',
    datapoint_id: 'E4-5_11',
    esg_category: 'environmental',
    name_en: 'Total sealed area',
    name_es: 'Área total sellada',
    name_pt: 'Área total selada'
  },
  {
    id: 'ba9ac85a-b0bc-4dcb-a2a6-a0c0abadc6c0',
    name_dcycle: 'nature-oriented_area_on_site',
    datapoint_id: 'E4-5_12',
    esg_category: 'environmental',
    name_en: 'Nature-oriented area on site',
    name_es: 'Área orientada a la naturaleza en el sitio',
    name_pt: 'Área voltada para a natureza no local'
  },
  {
    id: '35502676-8aee-4b5f-898e-e64950e18449',
    name_dcycle: 'nature-oriented_area_off_site',
    datapoint_id: 'E4-5_13',
    esg_category: 'environmental',
    name_en: 'Nature-oriented area off site',
    name_es: 'Área orientada a la naturaleza fuera del sitio',
    name_pt: 'Área voltada para a natureza fora do local'
  },
  {
    id: 'da3456a5-f121-4a48-9cc6-75b89f4628f6',
    name_dcycle:
      'disclosure_of_how_pathways_of_introduction_and_spread_of_invasive_alien_species_and_risks_posed_by_invasive_alien_species_are_managed',
    datapoint_id: 'E4-5_14',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how pathways of introduction and spread of invasive alien species and risks posed by invasive alien species are managed',
    name_es:
      'Divulgación de cómo se gestionan las vías de introducción y propagación de especies exóticas invasoras y los riesgos que plantean las especies exóticas invasoras.',
    name_pt:
      'Divulgação de como são gerenciadas as vias de introdução e disseminação de espécies exóticas invasoras e os riscos representados por espécies exóticas invasoras'
  },
  {
    id: 'd43ce070-47f0-41dc-a1bc-533f7d2d6835',
    name_dcycle: 'number_of_invasive_alien_species',
    datapoint_id: 'E4-5_15',
    esg_category: 'environmental',
    name_en: 'Number of invasive alien species',
    name_es: 'Número de especies exóticas invasoras',
    name_pt: 'Número de espécies exóticas invasoras'
  },
  {
    id: '7667c0e1-b1bf-4946-96ab-f9073c57f9b8',
    name_dcycle: 'area_covered_by_invasive_alien_species',
    datapoint_id: 'E4-5_16',
    esg_category: 'environmental',
    name_en: 'Area covered by invasive alien species',
    name_es: 'Área cubierta por especies exóticas invasoras',
    name_pt: 'Área coberta por espécies exóticas invasoras'
  },
  {
    id: '0abb4464-2db3-45f1-9c34-ef1107920fe3',
    name_dcycle: 'disclosure_of_metrics_considered_relevant_(state_of_species)',
    datapoint_id: 'E4-5_17',
    esg_category: 'environmental',
    name_en: 'Disclosure of metrics considered relevant (state of species)',
    name_es: 'Divulgación de métricas consideradas relevantes (estado de especies)',
    name_pt: 'Divulgação de métricas consideradas relevantes (estado da espécie)'
  },
  {
    id: 'af4dd3fa-ca51-4c25-adbe-375cc76a746b',
    name_dcycle:
      'disclosure_of_paragraph_in_another_environment-related_standard_in_which_metric_is_referred_to',
    datapoint_id: 'E4-5_18',
    esg_category: 'environmental',
    name_en:
      'Disclosure of paragraph in another environment-related standard in which metric is referred to',
    name_es:
      'Divulgación de un párrafo en otra norma relacionada con el medio ambiente en el que se hace referencia a la métrica',
    name_pt:
      'Divulgação de parágrafo em outra norma relacionada ao meio ambiente em que a métrica é referida'
  },
  {
    id: '527d2d3b-2430-45ee-a5fa-5bd27ebd6816',
    name_dcycle:
      'disclosure_of_population_size,_range_within_specific_ecosystems_and_extinction_risk',
    datapoint_id: 'E4-5_19',
    esg_category: 'environmental',
    name_en: 'Disclosure of population size, range within specific ecosystems and extinction risk',
    name_es:
      'Divulgación del tamaño de la población, rango dentro de ecosistemas específicos y riesgo de extinción.',
    name_pt:
      'Divulgação do tamanho da população, distribuição dentro de ecossistemas específicos e risco de extinção'
  },
  {
    id: 'c89d48b6-6521-40e8-b1a9-ce6cc49fb235',
    name_dcycle: 'disclosure_of_changes_in_number_of_individuals_of_species_within_specific_area',
    datapoint_id: 'E4-5_20',
    esg_category: 'environmental',
    name_en: 'Disclosure of changes in number of individuals of species within specific area',
    name_es:
      'Divulgación de cambios en el número de individuos de especies dentro de un área específica',
    name_pt:
      'Divulgação de mudanças no número de indivíduos de espécies dentro de uma área específica'
  },
  {
    id: '2844a793-4369-4e9e-9bd0-03fc543e444c',
    name_dcycle: 'information_about_species_at_global_extinction_risk',
    datapoint_id: 'E4-5_21',
    esg_category: 'environmental',
    name_en: 'Information about species at global extinction risk',
    name_es: 'Información sobre especies en riesgo de extinción global',
    name_pt: 'Informações sobre espécies em risco de extinção global'
  },
  {
    id: 'f4000245-1ef7-4c29-b674-d26cd5045db3',
    name_dcycle:
      'disclosure_of_threat_status_of_species_and_how_activities_or_pressures_may_affect_threat_status',
    datapoint_id: 'E4-5_22',
    esg_category: 'environmental',
    name_en:
      'Disclosure of threat status of species and how activities or pressures may affect threat status',
    name_es:
      'Divulgación del estado de amenaza de las especies y cómo las actividades o presiones pueden afectar el estado de amenaza',
    name_pt:
      'Divulgação do estado de ameaça das espécies e como as atividades ou pressões podem afetar o estado de ameaça'
  },
  {
    id: '712bd3cb-4b25-4521-989d-88a417db7d35',
    name_dcycle:
      "disclosure_of_change_in_relevant_habitat_for_threatened_species_as_proxy_for_impact_on_local_population's_extinction_risk",
    datapoint_id: 'E4-5_23',
    esg_category: 'environmental',
    name_en:
      "Disclosure of change in relevant habitat for threatened species as proxy for impact on local population's extinction risk",
    name_es:
      'Divulgación de cambios en el hábitat relevante para especies amenazadas como indicador del impacto en el riesgo de extinción de la población local.',
    name_pt:
      'Divulgação de mudanças no habitat relevante para espécies ameaçadas como proxy do impacto no risco de extinção da população local'
  },
  {
    id: 'e4410e44-5604-4eaa-b3f7-db5e2bf46173',
    name_dcycle: 'disclosure_of_ecosystem_area_coverage',
    datapoint_id: 'E4-5_24',
    esg_category: 'environmental',
    name_en: 'Disclosure of ecosystem area coverage',
    name_es: 'Divulgación de la cobertura del área del ecosistema',
    name_pt: 'Divulgação da cobertura da área do ecossistema'
  },
  {
    id: 'a7fd57ef-a630-4ca2-aebc-e533c2303cf0',
    name_dcycle: 'disclosure_of_quality_of_ecosystems_relative_to_pre-determined_reference_state',
    datapoint_id: 'E4-5_25',
    esg_category: 'environmental',
    name_en: 'Disclosure of quality of ecosystems relative to pre-determined reference state',
    name_es:
      'Divulgación de la calidad de los ecosistemas en relación con el estado de referencia predeterminado',
    name_pt:
      'Divulgação da qualidade dos ecossistemas em relação ao estado de referência pré-determinado'
  },
  {
    id: 'a549a2dc-90a1-4aeb-981c-3a7a61b3dce5',
    name_dcycle: 'disclosure_of_multiple_species_within_ecosystem',
    datapoint_id: 'E4-5_26',
    esg_category: 'environmental',
    name_en: 'Disclosure of multiple species within ecosystem',
    name_es: 'Divulgación de múltiples especies dentro del ecosistema.',
    name_pt: 'Divulgação de múltiplas espécies dentro do ecossistema'
  },
  {
    id: 'cd885fbf-aa6f-4135-a59d-0d7d04c5d3f5',
    name_dcycle: 'disclosure_of_structural_components_of_ecosystem_condition',
    datapoint_id: 'E4-5_27',
    esg_category: 'environmental',
    name_en: 'Disclosure of structural components of ecosystem condition',
    name_es: 'Divulgación de los componentes estructurales de la condición del ecosistema.',
    name_pt: 'Divulgação dos componentes estruturais da condição do ecossistema'
  },
  {
    id: '47368f06-8a89-401f-ac41-ce3cd548f8cd',
    name_dcycle:
      'disclosure_of_quantitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_biodiversity-_and_ecosystem-related_impacts_and_dependencies',
    datapoint_id: 'E4-6_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of quantitative information about anticipated financial effects of material risks and opportunities arising from biodiversity- and ecosystem-related impacts and dependencies',
    name_es:
      'Divulgación de información cuantitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos y dependencias relacionados con la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação de informações quantitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos e dependências relacionados à biodiversidade e ao ecossistema'
  },
  {
    id: '3a73b6d7-5424-4783-9be4-94db8d0a2649',
    name_dcycle:
      'disclosure_of_qualitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_biodiversity-_and_ecosystem-related_impacts_and_dependencies',
    datapoint_id: 'E4-6_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of qualitative information about anticipated financial effects of material risks and opportunities arising from biodiversity- and ecosystem-related impacts and dependencies',
    name_es:
      'Divulgación de información cualitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos y dependencias relacionados con la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação de informações qualitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos e dependências relacionados à biodiversidade e ao ecossistema'
  },
  {
    id: '7fb91029-7ba5-4be8-83a7-b076c2c282c4',
    name_dcycle:
      'description_of_effects_considered,_related_impacts_and_dependencies_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-6_03',
    esg_category: 'environmental',
    name_en:
      'Description of effects considered, related impacts and dependencies (biodiversity and ecosystems)',
    name_es:
      'Descripción de los efectos considerados, impactos relacionados y dependencias (biodiversidad y ecosistemas)',
    name_pt:
      'Descrição dos efeitos considerados, impactos relacionados e dependências (biodiversidade e ecossistemas)'
  },
  {
    id: 'bc1f68c4-a017-4172-97e2-8d0536d4d860',
    name_dcycle:
      'disclosure_of_critical_assumptions_used_in_estimates_of_financial_effects_of_material_risks_and_opportunities_arising_from_biodiversity-_and_ecosystem-related_impacts_and_dependencies',
    datapoint_id: 'E4-6_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of critical assumptions used in estimates of financial effects of material risks and opportunities arising from biodiversity- and ecosystem-related impacts and dependencies',
    name_es:
      'Divulgación de supuestos críticos utilizados en las estimaciones de los efectos financieros de los riesgos y oportunidades materiales que surgen de los impactos y dependencias relacionados con la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação de premissas críticas usadas nas estimativas de efeitos financeiros de riscos e oportunidades materiais decorrentes de impactos e dependências relacionados à biodiversidade e ao ecossistema'
  },
  {
    id: 'b3fbd8cf-d45f-4e67-98a7-54a943be587a',
    name_dcycle:
      'description_of_related_products_and_services_at_risk_(biodiversity_and_ecosystems)_over_the_short-,_medium-_and_long-term',
    datapoint_id: 'E4-6_05',
    esg_category: 'environmental',
    name_en:
      'Description of related products and services at risk (biodiversity and ecosystems) over the short-, medium- and long-term',
    name_es:
      'Descripción de productos y servicios relacionados en riesgo (biodiversidad y ecosistemas) a corto, mediano y largo plazo.',
    name_pt:
      'Descrição dos produtos e serviços relacionados em risco (biodiversidade e ecossistemas) no curto, médio e longo prazo'
  },
  {
    id: 'fca8602a-a46e-4711-b537-466de1e5618f',
    name_dcycle:
      'explanation_of_how_financial_amounts_are_estimated_and_critical_assumptions_made_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-6_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of how financial amounts are estimated and critical assumptions made (biodiversity and ecosystems)',
    name_es:
      'Explicación de cómo se estiman los montos financieros y se hacen los supuestos críticos (biodiversidad y ecosistemas)',
    name_pt:
      'Explicação de como os valores financeiros são estimados e as suposições críticas são feitas (biodiversidade e ecossistemas)'
  },
  {
    id: '16e011c8-a7f6-4f82-a519-6a87607d1816',
    name_dcycle:
      'disclosure_of_whether_the_undertaking_has_screened_its_assets_and_activities_in_order_to_identify_actual_and_potential_impacts,_risks_and_opportunities_in_own_operations_and_upstream_and_downstream_value_chain,_and_if_so,_methodologies,_assumptions_and_tools_used',
    datapoint_id: 'E5.IRO-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether the undertaking has screened its assets and activities in order to identify actual and potential impacts, risks and opportunities in own operations and upstream and downstream value chain, and if so, methodologies, assumptions and tools used',
    name_es:
      'Divulgación de si la empresa ha examinado sus activos y actividades para identificar impactos, riesgos y oportunidades reales y potenciales en sus propias operaciones y en la cadena de valor ascendente y descendente y, en caso afirmativo, metodologías, supuestos y herramientas utilizadas.',
    name_pt:
      'Divulgação sobre se a empresa examinou os seus ativos e atividades, a fim de identificar impactos, riscos e oportunidades reais e potenciais nas próprias operações e na cadeia de valor a montante e a jusante e, em caso afirmativo, metodologias, pressupostos e ferramentas utilizadas'
  },
  {
    id: '4acd5b7c-3d4d-43e9-b0c8-73c83bf424dc',
    name_dcycle:
      'disclosure_of_whether_and_how_the_undertaking_has_conducted_consultations_(resource_and_circular_economy)',
    datapoint_id: 'E5.IRO-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how the undertaking has conducted consultations (resource and circular economy)',
    name_es:
      'Divulgación de si la empresa ha realizado consultas y cómo (recursos y economía circular)',
    name_pt: 'Divulgação de se e como a empresa realizou consultas (recursos e economia circular)'
  },
  {
    id: 'b15b3947-0b17-4d9c-9d6f-8342f4c6b05e',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_addresses_transitioning_away_from_use_of_virgin_resources,_including_relative_increases_in_use_of_secondary_(recycled)_resources',
    datapoint_id: 'E5-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy addresses transitioning away from use of virgin resources, including relative increases in use of secondary (recycled) resources',
    name_es:
      'Divulgación de si la política aborda la transición hacia el abandono del uso de recursos vírgenes, incluidos los aumentos relativos en el uso de recursos secundarios (reciclados), y de qué manera.',
    name_pt:
      'Divulgação sobre se e como a política aborda a transição do uso de recursos virgens, incluindo aumentos relativos no uso de recursos secundários (reciclados)'
  },
  {
    id: '12fa4c4c-4d64-4b10-963e-2110df4dbeb1',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_addresses_sustainable_sourcing_and_use_of_renewable_resources',
    datapoint_id: 'E5-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy addresses sustainable sourcing and use of renewable resources',
    name_es:
      'Divulgación de si la política aborda el abastecimiento y el uso sostenible de recursos renovables y cómo lo hace.',
    name_pt:
      'Divulgação sobre se e como a política aborda o fornecimento e uso sustentável de recursos renováveis'
  },
  {
    id: '5852b4b1-0bda-419c-aa77-e5341860ce90',
    name_dcycle:
      'description_of_whether_and_how_policy_addresses_waste_hierarchy_(prevention,_preparing_for_re-use,_recycling,_other_recovery,_disposal)',
    datapoint_id: 'E5-1_03',
    esg_category: 'environmental',
    name_en:
      'Description of whether and how policy addresses waste hierarchy (prevention, preparing for re-use, recycling, other recovery, disposal)',
    name_es:
      'Descripción de si la política aborda la jerarquía de residuos y de qué manera (prevención, preparación para la reutilización, reciclaje, otras recuperaciones, eliminación)',
    name_pt:
      'Descrição de se e como a política aborda a hierarquia de resíduos (prevenção, preparação para reutilização, reciclagem, outras recuperações, eliminação)'
  },
  {
    id: '3d9e70ca-9fc2-40fc-886e-c8ed30c30481',
    name_dcycle:
      'description_of_whether_and_how_policy_addresses_prioritisation_of_strategies_to_avoid_or_minimise_waste_over_waste_treatment_strategies',
    datapoint_id: 'E5-1_04',
    esg_category: 'environmental',
    name_en:
      'Description of whether and how policy addresses prioritisation of strategies to avoid or minimise waste over waste treatment strategies',
    name_es:
      'Descripción de si la política aborda la priorización de estrategias para evitar o minimizar los residuos frente a las estrategias de tratamiento de residuos, y cómo hacerlo',
    name_pt:
      'Descrição de se e como a política aborda a priorização de estratégias para evitar ou minimizar resíduos em detrimento de estratégias de tratamento de resíduos'
  },
  {
    id: 'ca6526fa-ba68-4c72-80e5-f6d674c72d53',
    name_dcycle:
      'description_of_higher_levels_of_resource_efficiency_in_use_of_technical_and_biological_materials_and_water',
    datapoint_id: 'E5-2_01',
    esg_category: 'environmental',
    name_en:
      'Description of higher levels of resource efficiency in use of technical and biological materials and water',
    name_es:
      'Descripción de niveles más altos de eficiencia de recursos en el uso de materiales técnicos y biológicos y agua.',
    name_pt:
      'Descrição de níveis mais elevados de eficiência de recursos no uso de materiais técnicos e biológicos e água'
  },
  {
    id: '10c59b73-7434-480d-a1c3-4c1edd47201c',
    name_dcycle: 'description_of_higher_rates_of_use_of_secondary_raw_materials',
    datapoint_id: 'E5-2_02',
    esg_category: 'environmental',
    name_en: 'Description of higher rates of use of secondary raw materials',
    name_es: 'Descripción de tasas más altas de uso de materias primas secundarias',
    name_pt: 'Descrição de taxas mais elevadas de utilização de matérias-primas secundárias'
  },
  {
    id: '987d50a2-4134-460d-86e8-f9ce61ffc35d',
    name_dcycle: 'description_of_application_of_circular_design',
    datapoint_id: 'E5-2_03',
    esg_category: 'environmental',
    name_en: 'Description of application of circular design',
    name_es: 'Descripción de la aplicación del diseño circular.',
    name_pt: 'Descrição da aplicação do desenho circular'
  },
  {
    id: '778a697d-bbec-474a-af60-9909880119ed',
    name_dcycle: 'description_of_application_of_circular_business_practices',
    datapoint_id: 'E5-2_04',
    esg_category: 'environmental',
    name_en: 'Description of application of circular business practices',
    name_es: 'Descripción de la aplicación de prácticas comerciales circulares',
    name_pt: 'Descrição da aplicação de práticas comerciais circulares'
  },
  {
    id: '9dee0d17-dee7-4278-94e9-1cc3695d88c1',
    name_dcycle:
      "description_of_actions_taken_to_prevent_waste_generation_in_the_undertaking's_upstream_and_downstram_value_chain",
    datapoint_id: 'E5-2_05',
    esg_category: 'environmental',
    name_en:
      "Description of actions taken to prevent waste generation in the undertaking's upstream and downstram value chain",
    name_es:
      'Descripción de las acciones tomadas para prevenir la generación de residuos en la cadena de valor upstream y downstream de la empresa',
    name_pt:
      'Descrição das ações tomadas para prevenir a geração de resíduos na cadeia de valor a montante e a jusante da empresa'
  },
  {
    id: '85870c9c-3da8-42f0-916e-e29c4e44c941',
    name_dcycle: 'description_of_optimistation_of_waste_management',
    datapoint_id: 'E5-2_06',
    esg_category: 'environmental',
    name_en: 'Description of Optimistation of waste management',
    name_es: 'Descripción de Optimización de la gestión de residuos',
    name_pt: 'Descrição do Otimização da gestão de resíduos'
  },
  {
    id: '4f22183c-5230-4cfd-a3d8-b17092799299',
    name_dcycle:
      'information_about_collective_action_on_development_of_collaborations_or_initiatives_increasing_circularity_of_products_and_materials',
    datapoint_id: 'E5-2_07',
    esg_category: 'environmental',
    name_en:
      'Information about collective action on development of collaborations or initiatives increasing circularity of products and materials',
    name_es:
      'Información sobre acciones colectivas para el desarrollo de colaboraciones o iniciativas que aumenten la circularidad de productos y materiales.',
    name_pt:
      'Informações sobre ações coletivas no desenvolvimento de colaborações ou iniciativas que aumentem a circularidade de produtos e materiais'
  },
  {
    id: '3d631337-9859-48c2-9db5-678cfcf226d7',
    name_dcycle: 'description_of_contribution_to_circular_economy',
    datapoint_id: 'E5-2_08',
    esg_category: 'environmental',
    name_en: 'Description of contribution to circular economy',
    name_es: 'Descripción de la contribución a la economía circular',
    name_pt: 'Descrição da contribuição para a economia circular'
  },
  {
    id: '040b0f3e-3fa4-4a26-8675-54aba54c14a8',
    name_dcycle:
      'description_of_other_stakeholders_involved_in_collective_action_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-2_09',
    esg_category: 'environmental',
    name_en:
      'Description of other stakeholders involved in collective action (resource use and circular economy)',
    name_es:
      'Descripción de otros actores involucrados en la acción colectiva (uso de recursos y economía circular)',
    name_pt:
      'Descrição de outras partes interessadas envolvidas na ação coletiva (utilização de recursos e economia circular)'
  },
  {
    id: '3b65a0d4-0207-415b-b631-f994c640b140',
    name_dcycle: 'description_of_organisation_of_project_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-2_10',
    esg_category: 'environmental',
    name_en: 'Description of organisation of project (resource use and circular economy)',
    name_es: 'Descripción de la organización del proyecto (uso de recursos y economía circular)',
    name_pt: 'Descrição da organização do projeto (utilização de recursos e economia circular)'
  },
  {
    id: '856db694-e8bb-4fcd-8c42-ed417b2067e6',
    name_dcycle:
      'disclosure_of_how_target_relates_to_resources_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-3_01',
    esg_category: 'environmental',
    name_en: 'Disclosure of how target relates to resources (resource use and circular economy)',
    name_es:
      'Divulgación de cómo el objetivo se relaciona con los recursos (uso de recursos y economía circular)',
    name_pt:
      'Divulgação de como a meta se relaciona com os recursos (uso de recursos e economia circular)'
  },
  {
    id: 'ba30f2d7-9efd-4efa-8042-ddfd58f776f5',
    name_dcycle: 'disclosure_of_how_target_relates_to_increase_of_circular_design',
    datapoint_id: 'E5-3_02',
    esg_category: 'environmental',
    name_en: 'Disclosure of how target relates to increase of circular design',
    name_es: 'Divulgación de cómo el objetivo se relaciona con el aumento del diseño circular',
    name_pt: 'Divulgação de como a meta se relaciona com o aumento do design circular'
  },
  {
    id: 'ec45082f-d69c-4a45-becd-f9593b26df50',
    name_dcycle: 'disclosure_of_how_target_relates_to_increase_of_circular_material_use_rate',
    datapoint_id: 'E5-3_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of how target relates to increase of circular material use rate',
    name_es:
      'Divulgación de cómo el objetivo se relaciona con el aumento de la tasa de uso de material circular',
    name_pt:
      'Divulgação de como a meta se relaciona com o aumento da taxa de uso de materiais circulares'
  },
  {
    id: '61b6174d-91bd-4554-aac9-2038d0e195c1',
    name_dcycle: 'disclosure_of_how_target_relates_to_minimisation_of_primary_raw_material',
    datapoint_id: 'E5-3_04',
    esg_category: 'environmental',
    name_en: 'Disclosure of how target relates to minimisation of primary raw material',
    name_es:
      'Divulgación de cómo el objetivo se relaciona con la minimización de la materia prima primaria.',
    name_pt: 'Divulgação de como a meta se relaciona com a minimização de matéria-prima primária'
  },
  {
    id: 'ef2391ee-ac7e-4bb4-9c9b-e70f148ef52a',
    name_dcycle:
      'disclosure_of_how_target_relates_to_reversal_of_depletion_of_stock_of_renewable_resources',
    datapoint_id: 'E5-3_05',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how target relates to reversal of depletion of stock of renewable resources',
    name_es:
      'Divulgación de cómo el objetivo se relaciona con la reversión del agotamiento de las reservas de recursos renovables.',
    name_pt:
      'Divulgação de como a meta se relaciona com a reversão do esgotamento do estoque de recursos renováveis'
  },
  {
    id: 'b8736c67-1df9-4258-9078-77617a6b0da4',
    name_dcycle: 'target_relates_to_waste_management',
    datapoint_id: 'E5-3_06',
    esg_category: 'environmental',
    name_en: 'Target relates to waste management',
    name_es: 'El objetivo se relaciona con la gestión de residuos.',
    name_pt: 'Meta está relacionada à gestão de resíduos'
  },
  {
    id: 'c638a2ff-b989-4f9f-9812-958a079cef21',
    name_dcycle: 'disclosure_of_how_target_relates_to_waste_management',
    datapoint_id: 'E5-3_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of how target relates to waste management',
    name_es: 'Divulgación de cómo el objetivo se relaciona con la gestión de residuos.',
    name_pt: 'Divulgação de como a meta se relaciona com a gestão de resíduos'
  },
  {
    id: '2644f969-3368-472b-9cc2-5efeb89d3e0c',
    name_dcycle:
      'disclosure_of_how_target_relates_to_other_matters_related_to_resource_use_or_circular_economy',
    datapoint_id: 'E5-3_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how target relates to other matters related to resource use or circular economy',
    name_es:
      'Divulgación de cómo el objetivo se relaciona con otros asuntos relacionados con el uso de recursos o la economía circular.',
    name_pt:
      'Divulgação de como a meta se relaciona com outras questões relacionadas ao uso de recursos ou à economia circular'
  },
  {
    id: '83a5aff1-3153-407e-8980-e2aa63a75aae',
    name_dcycle: 'layer_in_waste_hierarchy_to_which_target_relates',
    datapoint_id: 'E5-3_09',
    esg_category: 'environmental',
    name_en: 'Layer in waste hierarchy to which target relates',
    name_es: 'Capa en la jerarquía de residuos con la que se relaciona el objetivo',
    name_pt: 'Camada na hierarquia de resíduos à qual o alvo se refere'
  },
  {
    id: '78020206-4893-4f67-99a2-6e0614c15e64',
    name_dcycle:
      'disclosure_of_ecological_threshold_identified_and_methodology_used_to_identify_ecological_threshold_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-3_10',
    esg_category: 'environmental',
    name_en:
      'Disclosure of ecological threshold identified and methodology used to identify ecological threshold (resource use and circular economy)',
    name_es:
      'Divulgación del umbral ecológico identificado y metodología utilizada para identificar el umbral ecológico (uso de recursos y economía circular)',
    name_pt:
      'Divulgação do limiar ecológico identificado e metodologia utilizada para identificar o limiar ecológico (utilização de recursos e economia circular)'
  },
  {
    id: '302d0a4c-db29-4c55-8842-b7d652312261',
    name_dcycle:
      'disclosure_of_how_ecological_entity-specific_threshold_was_determined_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-3_11',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how ecological entity-specific threshold was determined (resource use and circular economy)',
    name_es:
      'Divulgación de cómo se determinó el umbral ecológico específico de la entidad (uso de recursos y economía circular)',
    name_pt:
      'Divulgação de como foi determinado o limiar específico da entidade ecológica (utilização de recursos e economia circular)'
  },
  {
    id: 'fc93157c-e73c-4d3f-a2cd-7e035c82e00b',
    name_dcycle:
      'disclosure_of_how_responsibility_for_respecting_identified_ecological_threshold_is_allocated_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-3_12',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how responsibility for respecting identified ecological threshold is allocated (resource use and circular economy)',
    name_es:
      'Divulgación de cómo se asigna la responsabilidad de respetar el umbral ecológico identificado (uso de recursos y economía circular)',
    name_pt:
      'Divulgação de como é atribuída a responsabilidade pelo respeito do limiar ecológico identificado (utilização de recursos e economia circular)'
  },
  {
    id: '430aa27d-95fe-4465-aeec-bea2260aec21',
    name_dcycle: 'the_targets_being_set_and_presented_are_mandatory_(required_by_legislation)',
    datapoint_id: 'E5-3_13',
    esg_category: 'environmental',
    name_en: 'The targets being set and presented are mandatory (required by legislation)',
    name_es:
      'Los objetivos que se establecen y presentan son obligatorios (exigidos por la legislación)',
    name_pt: 'As metas definidas e apresentadas são obrigatórias (exigidas por legislação)'
  },
  {
    id: '2fa4ceef-b596-4fb1-afc5-1448512e77b5',
    name_dcycle: 'disclosure_of_information_on_material_resource_inflows',
    datapoint_id: 'E5-4_01',
    esg_category: 'environmental',
    name_en: 'Disclosure of information on material resource inflows',
    name_es: 'Divulgación de información sobre entradas de recursos materiales.',
    name_pt: 'Divulgação de informações sobre fluxos de recursos materiais'
  },
  {
    id: 'caa46598-8bfd-4faa-b1aa-5d35a0034ad5',
    name_dcycle:
      'overall_total_weight_of_products_and_technical_and_biological_materials_used_during_the_reporting_period',
    datapoint_id: 'E5-4_02',
    esg_category: 'environmental',
    name_en:
      'Overall total weight of products and technical and biological materials used during the reporting period',
    name_es:
      'Peso total general de productos y materiales técnicos y biológicos utilizados durante el período del informe.',
    name_pt:
      'Peso total global de produtos e materiais técnicos e biológicos utilizados durante o período coberto pelo relatório'
  },
  {
    id: '9232fb4c-51c5-4659-9cf0-ff1421770cf1',
    name_dcycle: 'percentage_of_biological_materials_(and_biofuels_used_for_non-energy_purposes)',
    datapoint_id: 'E5-4_03',
    esg_category: 'environmental',
    name_en: 'Percentage of biological materials (and biofuels used for non-energy purposes)',
    name_es:
      'Porcentaje de materiales biológicos (y biocombustibles utilizados con fines no energéticos)',
    name_pt:
      'Percentagem de materiais biológicos (e biocombustíveis utilizados para fins não energéticos)'
  },
  {
    id: 'c34057ac-0092-4418-a5c7-d58c8d01244e',
    name_dcycle:
      'the_absolute_weight_of_secondary_reused_or_recycled_components,_secondary_intermediary_products_and_secondary_materials_used_to_manufacture_the_undertaking’s_products_and_services_(including_packaging)',
    datapoint_id: 'E5-4_04',
    esg_category: 'environmental',
    name_en:
      'The absolute weight of secondary reused or recycled components, secondary intermediary products and secondary materials used to manufacture the undertaking’s products and services (including packaging)',
    name_es:
      'El peso absoluto de los componentes secundarios reutilizados o reciclados, los productos intermedios secundarios y los materiales secundarios utilizados para fabricar los productos y servicios de la empresa (incluido el embalaje).',
    name_pt:
      'O peso absoluto dos componentes secundários reutilizados ou reciclados, dos produtos intermediários secundários e dos materiais secundários utilizados no fabrico dos produtos e serviços da empresa (incluindo embalagens)'
  },
  {
    id: '6c22a599-b9ff-4e00-8c70-dfc194e49f7a',
    name_dcycle:
      'percentage_of_secondary_reused_or_recycled_components,_secondary_intermediary_products_and_secondary_materials',
    datapoint_id: 'E5-4_05',
    esg_category: 'environmental',
    name_en:
      'Percentage of secondary reused or recycled components, secondary intermediary products and secondary materials',
    name_es:
      'Porcentaje de componentes secundarios reutilizados o reciclados, productos intermedios secundarios y materiales secundarios',
    name_pt:
      'Porcentagem de componentes secundários reutilizados ou reciclados, produtos intermediários secundários e materiais secundários'
  },
  {
    id: 'd2710009-b37e-406a-b56e-331d7bfcbc1f',
    name_dcycle: 'description_of_methodologies_used_to_calculate_data_and_key_assumptions_used',
    datapoint_id: 'E5-4_06',
    esg_category: 'environmental',
    name_en: 'Description of methodologies used to calculate data and key assumptions used',
    name_es:
      'Descripción de las metodologías utilizadas para calcular los datos y supuestos clave utilizados.',
    name_pt:
      'Descrição das metodologias utilizadas para calcular os dados e principais premissas utilizadas'
  },
  {
    id: '477423a2-8372-4414-abd8-e9d77589fd46',
    name_dcycle: 'description_of_materials_that_are_sourced_from_by-products_or_waste_stream',
    datapoint_id: 'E5-4_07',
    esg_category: 'environmental',
    name_en: 'Description of materials that are sourced from by-products or waste stream',
    name_es: 'Descripción de materiales que provienen de subproductos o flujo de residuos.',
    name_pt: 'Descrição de materiais provenientes de subprodutos ou fluxo de resíduos'
  },
  {
    id: '10b3b79f-acdf-4245-a35f-d9975a1f1baf',
    name_dcycle: 'description_of_how_double_counting_was_avoided_and_of_choices_made',
    datapoint_id: 'E5-4_08',
    esg_category: 'environmental',
    name_en: 'Description of how double counting was avoided and of choices made',
    name_es: 'Descripción de cómo se evitó la doble contabilización y de las decisiones tomadas',
    name_pt: 'Descrição de como a dupla contagem foi evitada e das escolhas feitas'
  },
  {
    id: '51059eb2-ed47-48a9-bcea-a504f61b4914',
    name_dcycle:
      'description_of_the_key_products_and_materials_that_come_out_of_the_undertaking’s_production_process',
    datapoint_id: 'E5-5_01',
    esg_category: 'environmental',
    name_en:
      'Description of the key products and materials that come out of the undertaking’s production process',
    name_es:
      'Descripción de los productos y materiales clave que surgen del proceso productivo del emprendimiento.',
    name_pt:
      'Descrição dos principais produtos e materiais provenientes do processo produtivo da empresa'
  },
  {
    id: 'f93e9137-4868-4523-97ef-6db2a582d1d0',
    name_dcycle:
      'disclosure_of_the_expected_durability_of_the_products_placed_on_the_market,_in_relation_to_the_industry_average_for_each_product_group',
    datapoint_id: 'E5-5_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of the expected durability of the products placed on the market, in relation to the industry average for each product group',
    name_es:
      'Divulgación de la durabilidad esperada de los productos comercializados, en relación con el promedio de la industria para cada grupo de productos.',
    name_pt:
      'Divulgação da durabilidade esperada dos produtos colocados no mercado, em relação à média da indústria para cada grupo de produtos'
  },
  {
    id: '314cc9b1-fe88-4ecc-b52b-795d83164466',
    name_dcycle: 'disclosure_of_the_reparability_of_products',
    datapoint_id: 'E5-5_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of the reparability of products',
    name_es: 'Divulgación de la reparabilidad de los productos.',
    name_pt: 'Divulgação da reparabilidade dos produtos'
  },
  {
    id: '34540f01-c6dd-4614-b9b0-0be2d10f5b10',
    name_dcycle: 'the_rates_of_recyclable_content_in_products',
    datapoint_id: 'E5-5_04',
    esg_category: 'environmental',
    name_en: 'The rates of recyclable content in products',
    name_es: 'Las tasas de contenido reciclable en los productos.',
    name_pt: 'As taxas de conteúdo reciclável em produtos'
  },
  {
    id: 'b02cd4c3-7c10-4e0a-be68-3e418dfb408c',
    name_dcycle: 'the_rates_of_recyclable_content_in_products_packaging',
    datapoint_id: 'E5-5_05',
    esg_category: 'environmental',
    name_en: 'The rates of recyclable content in products packaging',
    name_es: 'Las tasas de contenido reciclable en los envases de productos.',
    name_pt: 'As taxas de conteúdo reciclável nas embalagens dos produtos'
  },
  {
    id: '1675d1f3-5479-4fef-8c7f-4c585690a140',
    name_dcycle: 'description_of_methodologies_used_to_calculate_data_(resource_outflows)',
    datapoint_id: 'E5-5_06',
    esg_category: 'environmental',
    name_en: 'Description of methodologies used to calculate data (resource outflows)',
    name_es:
      'Descripción de las metodologías utilizadas para calcular los datos (salidas de recursos)',
    name_pt: 'Descrição das metodologias utilizadas para cálculo de dados (saídas de recursos)'
  },
  {
    id: '2bcd5b12-b6f3-4b8a-96df-868b1fced5cf',
    name_dcycle: 'total_waste_generated',
    datapoint_id: 'E5-5_07',
    esg_category: 'environmental',
    name_en: 'Total Waste generated',
    name_es: 'Total de residuos generados',
    name_pt: 'Resíduos totais gerados'
  },
  {
    id: 'e81710a4-82cd-4225-b451-5ccf5f7ca320',
    name_dcycle:
      'waste_diverted_from_disposal,_breakdown_by_hazardous_and_non-hazardous_waste_and_treatment_type',
    datapoint_id: 'E5-5_08',
    esg_category: 'environmental',
    name_en:
      'Waste diverted from disposal, breakdown by hazardous and non-hazardous waste and treatment type',
    name_es:
      'Residuos desviados de su disposición final, desglosados ​​por residuos peligrosos y no peligrosos y tipo de tratamiento',
    name_pt:
      'Resíduos desviados de eliminação, discriminação por resíduos perigosos e não perigosos e tipo de tratamento'
  },
  {
    id: '7f524dcb-5c68-4e6a-b710-20483c8916bd',
    name_dcycle:
      'waste_directed_to_disposal,_breakdown_by_hazardous_and_non-hazardous_waste_and_treatment_type',
    datapoint_id: 'E5-5_09',
    esg_category: 'environmental',
    name_en:
      'Waste directed to disposal, breakdown by hazardous and non-hazardous waste and treatment type',
    name_es:
      'Residuos destinados a disposición, desglose por residuos peligrosos y no peligrosos y tipo de tratamiento',
    name_pt:
      'Resíduos destinados a eliminação, discriminação por resíduos perigosos e não perigosos e tipo de tratamento'
  },
  {
    id: 'dc6005d7-9907-49ec-b145-f8aa2b357ed6',
    name_dcycle: 'non-recycled_waste',
    datapoint_id: 'E5-5_10',
    esg_category: 'environmental',
    name_en: 'Non-recycled waste',
    name_es: 'Residuos no reciclados',
    name_pt: 'Resíduos não reciclados'
  },
  {
    id: '9bb045ea-374e-4b63-a7eb-716ce8a4a726',
    name_dcycle: 'percentage_of_non-recycled_waste',
    datapoint_id: 'E5-5_11',
    esg_category: 'environmental',
    name_en: 'Percentage of non-recycled waste',
    name_es: 'Porcentaje de residuos no reciclados',
    name_pt: 'Porcentagem de resíduos não reciclados'
  },
  {
    id: 'abfcac48-f388-4578-b193-0f98cefcf3ed',
    name_dcycle: 'disclosure_of_composition_of_waste',
    datapoint_id: 'E5-5_12',
    esg_category: 'environmental',
    name_en: 'Disclosure of composition of waste',
    name_es: 'Divulgación de la composición de los residuos.',
    name_pt: 'Divulgação da composição dos resíduos'
  },
  {
    id: 'a1a330ef-946e-4324-9e53-eeb0ec44ad2f',
    name_dcycle: "disclosure_of_waste_streams_relevant_to_undertaking's_sector_or_activities",
    datapoint_id: 'E5-5_13',
    esg_category: 'environmental',
    name_en: "Disclosure of waste streams relevant to undertaking's sector or activities",
    name_es:
      'Divulgación de flujos de residuos relevantes para el sector o las actividades de la empresa.',
    name_pt: 'Divulgação de fluxos de resíduos relevantes para o setor ou atividades da empresa'
  },
  {
    id: 'f8045604-c2b2-4bd7-8d02-742796e7de50',
    name_dcycle: 'disclosure_of_materials_that_are_present_in_waste',
    datapoint_id: 'E5-5_14',
    esg_category: 'environmental',
    name_en: 'Disclosure of materials that are present in waste',
    name_es: 'Divulgación de materiales que están presentes en los residuos.',
    name_pt: 'Divulgação de materiais que estão presentes nos resíduos'
  },
  {
    id: '6a4a6162-6242-4f5f-a030-c0a0b372e179',
    name_dcycle: 'total_amount_of_hazardous_waste',
    datapoint_id: 'E5-5_15',
    esg_category: 'environmental',
    name_en: 'Total amount of hazardous waste',
    name_es: 'Cantidad total de residuos peligrosos',
    name_pt: 'Quantidade total de resíduos perigosos'
  },
  {
    id: 'e94c1c0c-0bf2-4753-871a-e43132e4a874',
    name_dcycle: 'total_amount_of_radioactive_waste',
    datapoint_id: 'E5-5_16',
    esg_category: 'environmental',
    name_en: 'Total amount of radioactive waste',
    name_es: 'Cantidad total de residuos radiactivos',
    name_pt: 'Quantidade total de resíduos radioativos'
  },
  {
    id: '1438f4a7-41d4-46d5-8864-e5dd90e16f1f',
    name_dcycle: 'description_of_methodologies_used_to_calculate_data_(waste_generated)',
    datapoint_id: 'E5-5_17',
    esg_category: 'environmental',
    name_en: 'Description of methodologies used to calculate data (waste generated)',
    name_es: 'Descripción de metodologías utilizadas para el cálculo de datos (residuos generados)',
    name_pt: 'Descrição das metodologias utilizadas para cálculo dos dados (resíduos gerados)'
  },
  {
    id: '70b30853-892c-42e4-9035-716a402d387f',
    name_dcycle: 'disclosure_of_its_engagement_in_product_end-of-life_waste_management',
    datapoint_id: 'E5-5_18',
    esg_category: 'environmental',
    name_en: 'Disclosure of its engagement in product end-of-life waste management',
    name_es:
      'Divulgación de su compromiso con la gestión de residuos de productos al final de su vida útil',
    name_pt: 'Divulgação do seu envolvimento na gestão de resíduos de produtos em fim de vida'
  },
  {
    id: '1d0b3677-8226-4c84-b5ac-15c59da26cd6',
    name_dcycle:
      'disclosure_of_quantitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_resource_use_and_circular_economy-related_impacts',
    datapoint_id: 'E5-6_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of quantitative information about anticipated financial effects of material risks and opportunities arising from resource use and circular economy-related impacts',
    name_es:
      'Divulgación de información cuantitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen del uso de recursos y los impactos relacionados con la economía circular.',
    name_pt:
      'Divulgação de informações quantitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes do uso de recursos e impactos relacionados à economia circular'
  },
  {
    id: '0149a690-3ff6-495b-afad-539e5745d7f3',
    name_dcycle:
      'disclosure_of_qualitative_information_of_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_resource_use_and_circular_economy-related_impacts',
    datapoint_id: 'E5-6_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of qualitative information of anticipated financial effects of material risks and opportunities arising from resource use and circular economy-related impacts',
    name_es:
      'Divulgación de información cualitativa de los efectos financieros previstos de los riesgos y oportunidades materiales que surgen del uso de recursos y los impactos relacionados con la economía circular.',
    name_pt:
      'Divulgação de informações qualitativas sobre efeitos financeiros previstos de riscos e oportunidades materiais decorrentes do uso de recursos e impactos relacionados à economia circular'
  },
  {
    id: '5099085e-c363-4422-91f5-a24faeae2361',
    name_dcycle:
      'description_of_effects_considered_and_related_impacts_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-6_03',
    esg_category: 'environmental',
    name_en:
      'Description of effects considered and related impacts (resource use and circular economy)',
    name_es:
      'Descripción de los efectos considerados e impactos relacionados (uso de recursos y economía circular)',
    name_pt:
      'Descrição dos efeitos considerados e impactos relacionados (utilização de recursos e economia circular)'
  },
  {
    id: '36f3679f-904a-4f54-b6b9-0f11a22b9bf9',
    name_dcycle:
      'disclosure_of_critical_assumptions_used_in_estimates_of_financial_effects_of_material_risks_and_opportunities_arising_from_resource_use_and_circular_economy-related_impacts',
    datapoint_id: 'E5-6_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of critical assumptions used in estimates of financial effects of material risks and opportunities arising from resource use and circular economy-related impacts',
    name_es:
      'Divulgación de supuestos críticos utilizados en las estimaciones de los efectos financieros de los riesgos y oportunidades materiales que surgen del uso de recursos y los impactos relacionados con la economía circular.',
    name_pt:
      'Divulgação de pressupostos críticos utilizados nas estimativas de efeitos financeiros de riscos e oportunidades materiais decorrentes da utilização de recursos e impactos relacionados com a economia circular'
  },
  {
    id: '62d24e3c-b995-4784-a6b1-760bb2a1424f',
    name_dcycle:
      'description_of_related_products_and_services_at_risk_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-6_05',
    esg_category: 'environmental',
    name_en:
      'Description of related products and services at risk (resource use and circular economy)',
    name_es:
      'Descripción de productos y servicios relacionados en riesgo (uso de recursos y economía circular)',
    name_pt:
      'Descrição dos produtos e serviços relacionados em risco (utilização de recursos e economia circular)'
  },
  {
    id: '46e92ee8-e3bf-4fa4-b928-49f41e3e556c',
    name_dcycle:
      'explanation_of_how_time_horizons_are_defined,_financial_amounts_are_estimated_and_of_critical_assumptions_made_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-6_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of how time horizons are defined, financial amounts are estimated and of critical assumptions made (resource use and circular economy)',
    name_es:
      'Explicación de cómo se definen los horizontes temporales, se estiman los montos financieros y se realizan los supuestos críticos (uso de recursos y economía circular)',
    name_pt:
      'Explicação de como os horizontes temporais são definidos, os montantes financeiros são estimados e os pressupostos críticos assumidos (utilização de recursos e economia circular)'
  }
];

export const socialDatapoints = [
  {
    id: '5be1182b-e589-4709-8abc-eef5da130bb6',
    name_dcycle:
      'disclosure_of_whether_and_how_climate-related_considerations_are_factored_into_remuneration_of_members_of_administrative,_management_and_supervisory_bodies',
    datapoint_id: 'E1.GOV-3_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how climate-related considerations are factored into remuneration of members of administrative, management and supervisory bodies',
    name_es:
      'Divulgación de si y cómo se tienen en cuenta consideraciones relacionadas con el clima en la remuneración de los miembros de los órganos de administración, gestión y supervisión.',
    name_pt:
      'Divulgação sobre se e como as considerações relacionadas com o clima são tidas em conta na remuneração dos membros dos órgãos de administração, gestão e supervisão'
  },
  {
    id: '647808a3-d3dc-4b92-929d-6e14acbc94c0',
    name_dcycle:
      'percentage_of_remuneration_recognised_that_is_linked_to_climate_related_considerations',
    datapoint_id: 'E1.GOV-3_02',
    esg_category: 'environmental',
    name_en:
      'Percentage of remuneration recognised that is linked to climate related considerations',
    name_es:
      'Porcentaje de remuneración reconocida que está vinculada a consideraciones relacionadas con el clima',
    name_pt:
      'Percentagem de remuneração reconhecida que está ligada a considerações relacionadas com o clima'
  },
  {
    id: 'd8ad9c8d-d55e-4017-899d-8e235e1ed9c9',
    name_dcycle:
      'explanation_of_climate-related_considerations_that_are_factored_into_remuneration_of_members_of_administrative,_management_and_supervisory_bodies',
    datapoint_id: 'E1.GOV-3_03',
    esg_category: 'environmental',
    name_en:
      'Explanation of climate-related considerations that are factored into remuneration of members of administrative, management and supervisory bodies',
    name_es:
      'Explicación de las consideraciones relacionadas con el clima que se tienen en cuenta en la remuneración de los miembros de los órganos de administración, dirección y supervisión.',
    name_pt:
      'Explicação das considerações relacionadas com o clima que são tidas em conta na remuneração dos membros dos órgãos de administração, gestão e supervisão'
  },
  {
    id: 'dd9a21fe-8c43-4306-83a4-e36295b0f8bb',
    name_dcycle: 'disclosure_of_transition_plan_for_climate_change_mitigation',
    datapoint_id: 'E1-1_01',
    esg_category: 'environmental',
    name_en: 'Disclosure of transition plan for climate change mitigation',
    name_es: 'Divulgación del plan de transición para la mitigación del cambio climático',
    name_pt: 'Divulgação do plano de transição para mitigação das alterações climáticas'
  },
  {
    id: '167eaf79-d9d3-4d32-8657-d77f69e6810a',
    name_dcycle:
      'explanation_of_how_targets_are_compatible_with_limiting_of_global_warming_to_one_and_half_degrees_celsius_in_line_with_paris_agreement',
    datapoint_id: 'E1-1_02',
    esg_category: 'environmental',
    name_en:
      'Explanation of how targets are compatible with limiting of global warming to one and half degrees Celsius in line with Paris Agreement',
    name_es:
      'Explicación de cómo los objetivos son compatibles con la limitación del calentamiento global a un grado y medio Celsius de acuerdo con el Acuerdo de París',
    name_pt:
      'Explicação de como as metas são compatíveis com a limitação do aquecimento global a um grau e meio Celsius, em linha com o Acordo de Paris'
  },
  {
    id: '29e0c9c0-439a-40d9-ac23-1f38add307f5',
    name_dcycle: 'disclosure_of_decarbonisation_levers_and_key_action',
    datapoint_id: 'E1-1_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of decarbonisation levers and key action',
    name_es: 'Divulgación de palancas de descarbonización y acciones clave',
    name_pt: 'Divulgação de alavancas de descarbonização e ações-chave'
  },
  {
    id: '2bc8455e-3033-43a2-9299-e21211063f39',
    name_dcycle:
      'disclosure_of_significant_operational_expenditures_(opex)_and_(or)_capital_expenditures_(capex)_required_for_implementation_of_action_plan',
    datapoint_id: 'E1-1_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of significant operational expenditures (Opex) and (or) capital expenditures (Capex) required for implementation of action plan',
    name_es:
      'Divulgación de gastos operativos significativos (Opex) y (o) gastos de capital (Capex) necesarios para la implementación del plan de acción.',
    name_pt:
      'Divulgação de despesas operacionais significativas (Opex) e (ou) despesas de capital (Capex) necessárias para implementação do plano de ação'
  },
  {
    id: 'f69ba850-f283-4029-aeda-0abdcd3a6746',
    name_dcycle: 'financial_resources_allocated_to_action_plan_(opex)',
    datapoint_id: 'E1-1_05',
    esg_category: 'environmental',
    name_en: 'Financial resources allocated to action plan (OpEx)',
    name_es: 'Recursos financieros asignados al plan de acción (OpEx)',
    name_pt: 'Recursos financeiros alocados ao plano de ação (OpEx)'
  },
  {
    id: 'cbd16172-c0ec-4b09-9dea-aac0ceb4391f',
    name_dcycle: 'financial_resources_allocated_to_action_plan_(capex)',
    datapoint_id: 'E1-1_06',
    esg_category: 'environmental',
    name_en: 'Financial resources allocated to action plan (CapEx)',
    name_es: 'Recursos financieros asignados al plan de acción (CapEx)',
    name_pt: 'Recursos financeiros destinados ao plano de ação (CapEx)'
  },
  {
    id: '28dafa03-da76-49f1-97aa-c2542b98f290',
    name_dcycle:
      'explanation_of_potential_locked-in_ghg_emissions_from_key_assets_and_products_and_of_how_locked-in_ghg_emissions_may_jeopardise_achievement_of_ghg_emission_reduction_targets_and_drive_transition_risk',
    datapoint_id: 'E1-1_07',
    esg_category: 'environmental',
    name_en:
      'Explanation of potential locked-in GHG emissions from key assets and products and of how locked-in GHG emissions may jeopardise achievement of GHG emission reduction targets and drive transition risk',
    name_es:
      'Explicación de las posibles emisiones bloqueadas de GEI de activos y productos clave y de cómo las emisiones bloqueadas de GEI pueden poner en peligro el logro de los objetivos de reducción de emisiones de GEI e impulsar el riesgo de transición.',
    name_pt:
      'Explicação das potenciais emissões de GEE bloqueadas de ativos e produtos essenciais e de como as emissões de GEE bloqueadas podem comprometer o cumprimento das metas de redução de emissões de GEE e gerar riscos de transição'
  },
  {
    id: 'c6277a07-e6c4-47ca-b6fb-39f79abdbd92',
    name_dcycle:
      'explanation_of_any_objective_or_plans_(capex,_capex_plans,_opex)_for_aligning_economic_activities_(revenues,_capex,_opex)_with_criteria_established_in_commission_delegated_regulation_2021/2139',
    datapoint_id: 'E1-1_08',
    esg_category: 'environmental',
    name_en:
      'Explanation of any objective or plans (CapEx, CapEx plans, OpEx) for aligning economic activities (revenues, CapEx, OpEx) with criteria established in Commission Delegated Regulation 2021/2139',
    name_es:
      'Explicación de cualquier objetivo o plan (CapEx, planes CapEx, OpEx) para alinear las actividades económicas (ingresos, CapEx, OpEx) con los criterios establecidos en el Reglamento Delegado 2021/2139 de la Comisión.',
    name_pt:
      'Explicação de quaisquer objetivos ou planos (CapEx, planos CapEx, OpEx) para alinhar as atividades económicas (receitas, CapEx, OpEx) com os critérios estabelecidos no Regulamento Delegado 2021/2139 da Comissão'
  },
  {
    id: '99069c25-c6ac-4e8e-82b2-1e43441ec441',
    name_dcycle: 'significant_capex_for_coal-related_economic_activities',
    datapoint_id: 'E1-1_09',
    esg_category: 'environmental',
    name_en: 'Significant CapEx for coal-related economic activities',
    name_es: 'CapEx significativo para actividades económicas relacionadas con el carbón',
    name_pt: 'CapEx significativo para atividades económicas relacionadas com o carvão'
  },
  {
    id: '6d786c09-58de-4893-a502-deb042bda70a',
    name_dcycle: 'significant_capex_for_oil-related_economic_activities',
    datapoint_id: 'E1-1_10',
    esg_category: 'environmental',
    name_en: 'Significant CapEx for oil-related economic activities',
    name_es: 'CapEx significativo para actividades económicas relacionadas con el petróleo',
    name_pt: 'CapEx significativo para atividades económicas relacionadas com o petróleo'
  },
  {
    id: 'e619ae7c-8e5f-4467-accd-e602ad1ac875',
    name_dcycle: 'significant_capex_for_gas-related_economic_activities',
    datapoint_id: 'E1-1_11',
    esg_category: 'environmental',
    name_en: 'Significant CapEx for gas-related economic activities',
    name_es: 'CapEx significativo para actividades económicas relacionadas con el gas',
    name_pt: 'CapEx significativo para atividades económicas relacionadas com o gás'
  },
  {
    id: '3dcc788c-b0a4-4cec-ab5f-aead9a16031f',
    name_dcycle: 'undertaking_is_excluded_from_eu_paris-aligned_benchmarks',
    datapoint_id: 'E1-1_12',
    esg_category: 'environmental',
    name_en: 'Undertaking is excluded from EU Paris-aligned Benchmarks',
    name_es:
      'La empresa está excluida de los índices de referencia alineados con el Acuerdo de París de la UE',
    name_pt: 'Empresa é excluída dos benchmarks alinhados com Paris da UE'
  },
  {
    id: '716b45c4-9cb2-4a2f-a590-6dad0989c5ea',
    name_dcycle:
      'explanation_of_how_transition_plan_is_embedded_in_and_aligned_with_overall_business_strategy_and_financial_planning',
    datapoint_id: 'E1-1_13',
    esg_category: 'environmental',
    name_en:
      'Explanation of how transition plan is embedded in and aligned with overall business strategy and financial planning',
    name_es:
      'Explicación de cómo el plan de transición está integrado y alineado con la estrategia comercial general y la planificación financiera.',
    name_pt:
      'Explicação de como o plano de transição está incorporado e alinhado com a estratégia geral de negócios e o planejamento financeiro'
  },
  {
    id: '2e010dd9-f3bf-4a46-86b3-ee827f7845ed',
    name_dcycle: 'transition_plan_is_approved_by_administrative,_management_and_supervisory_bodies',
    datapoint_id: 'E1-1_14',
    esg_category: 'environmental',
    name_en: 'Transition plan is approved by administrative, management and supervisory bodies',
    name_es:
      'El plan de transición es aprobado por los órganos de administración, dirección y supervisión.',
    name_pt: 'Plano de transição é aprovado pelos órgãos de administração, gestão e fiscalização'
  },
  {
    id: '84ac6969-477c-45d3-ac0b-a52d61441f97',
    name_dcycle: 'explanation_of_progress_in_implementing_transition_plan',
    datapoint_id: 'E1-1_15',
    esg_category: 'environmental',
    name_en: 'Explanation of progress in implementing transition plan',
    name_es: 'Explicación del progreso en la implementación del plan de transición.',
    name_pt: 'Explicação do progresso na implementação do plano de transição'
  },
  {
    id: '5064aa42-ec9c-41f1-ac02-3bffec3cf797',
    name_dcycle:
      'date_of_adoption_of_transition_plan_for_undertakings_not_having_adopted_transition_plan_yet',
    datapoint_id: 'E1-1_16',
    esg_category: 'environmental',
    name_en:
      'Date of adoption of transition plan for undertakings not having adopted transition plan yet',
    name_es:
      'Fecha de adopción del plan de transición para las empresas que aún no lo han adoptado',
    name_pt:
      'Data de adoção do plano de transição para empresas que ainda não adotaram o plano de transição'
  },
  {
    id: 'fb7a76cb-aa15-4974-ad6d-bd5331841f87',
    name_dcycle: 'type_of_climate-related_risk',
    datapoint_id: 'E1.SBM-3_01',
    esg_category: 'environmental',
    name_en: 'Type of climate-related risk',
    name_es: 'Tipo de riesgo relacionado con el clima',
    name_pt: 'Tipo de risco relacionado ao clima'
  },
  {
    id: 'de015551-d0c5-4633-82f9-4448f57d7c1c',
    name_dcycle: 'description_of_scope_of_resilience_analysis',
    datapoint_id: 'E1.SBM-3_02',
    esg_category: 'environmental',
    name_en: 'Description of scope of resilience analysis',
    name_es: 'Descripción del alcance del análisis de resiliencia',
    name_pt: 'Descrição do escopo da análise de resiliência'
  },
  {
    id: 'ed1d3e58-f475-46da-8198-c6b1d7ceca8c',
    name_dcycle: 'disclosure_of_how_resilience_analysis_has_been_conducted',
    datapoint_id: 'E1.SBM-3_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of how resilience analysis has been conducted',
    name_es: 'Divulgación de cómo se ha realizado el análisis de resiliencia.',
    name_pt: 'Divulgação de como a análise de resiliência tem sido conduzida'
  },
  {
    id: '23a309a8-c038-4a87-88c0-2b635d96f580',
    name_dcycle: 'disclosure_of_how_resilience_analysis_has_been_conducted',
    datapoint_id: 'E1.SBM-3_04',
    esg_category: 'environmental',
    name_en: 'Disclosure of how resilience analysis has been conducted',
    name_es: 'Divulgación de cómo se ha realizado el análisis de resiliencia.',
    name_pt: 'Divulgação de como a análise de resiliência tem sido conduzida'
  },
  {
    id: '58f58180-b15f-418a-ae78-4342a68d761a',
    name_dcycle: 'time_horizons_applied_for_resilience_analysis',
    datapoint_id: 'E1.SBM-3_05',
    esg_category: 'environmental',
    name_en: 'Time horizons applied for resilience analysis',
    name_es: 'Horizontes temporales aplicados para el análisis de resiliencia',
    name_pt: 'Horizontes temporais aplicados para análise de resiliência'
  },
  {
    id: '0e59ceaf-213a-4733-87ff-f117827e3333',
    name_dcycle: 'description_of_results_of_resilience_analysis',
    datapoint_id: 'E1.SBM-3_06',
    esg_category: 'environmental',
    name_en: 'Description of results of resilience analysis',
    name_es: 'Descripción de los resultados del análisis de resiliencia',
    name_pt: 'Descrição dos resultados da análise de resiliência'
  },
  {
    id: 'cda7a6aa-42d7-42db-a8c3-befa40dfef13',
    name_dcycle:
      'description_of_ability_to_adjust_or_adapt_strategy_and_business_model_to_climate_change',
    datapoint_id: 'E1.SBM-3_07',
    esg_category: 'environmental',
    name_en:
      'Description of ability to adjust or adapt strategy and business model to climate change',
    name_es:
      'Descripción de la capacidad para ajustar o adaptar la estrategia y el modelo de negocio al cambio climático',
    name_pt:
      'Descrição da capacidade de ajustar ou adaptar a estratégia e o modelo de negócio às alterações climáticas'
  },
  {
    id: '3fa52266-80d2-43a6-a9c8-c9e186c7552b',
    name_dcycle: 'description_of_process_in_relation_to_impacts_on_climate_change',
    datapoint_id: 'E1.IRO-1_01',
    esg_category: 'environmental',
    name_en: 'Description of process in relation to impacts on climate change',
    name_es: 'Descripción del proceso en relación a los impactos sobre el cambio climático',
    name_pt: 'Descrição do processo em relação aos impactos nas mudanças climáticas'
  },
  {
    id: 'a90aecb5-ad99-4337-9137-ecd95593d459',
    name_dcycle:
      'description_of_process_in_relation_to_climate-related_physical_risks_in_own_operations_and_along_value_chain',
    datapoint_id: 'E1.IRO-1_02',
    esg_category: 'environmental',
    name_en:
      'Description of process in relation to climate-related physical risks in own operations and along value chain',
    name_es:
      'Descripción del proceso en relación con los riesgos físicos relacionados con el clima en las operaciones propias y a lo largo de la cadena de valor.',
    name_pt:
      'Descrição do processo em relação aos riscos físicos relacionados ao clima nas próprias operações e ao longo da cadeia de valor'
  },
  {
    id: '2581e155-9b69-4da4-a0a8-0a63735e0e2a',
    name_dcycle:
      'climate-related_hazards_have_been_identified_over_short-,_medium-_and_long-term_time_horizons',
    datapoint_id: 'E1.IRO-1_03',
    esg_category: 'environmental',
    name_en:
      'Climate-related hazards have been identified over short-, medium- and long-term time horizons',
    name_es:
      'Se han identificado peligros relacionados con el clima en horizontes temporales de corto, mediano y largo plazo.',
    name_pt:
      'Os perigos relacionados com o clima foram identificados em horizontes de curto, médio e longo prazo'
  },
  {
    id: '5a284855-ece8-46d1-9409-7e0fff76df86',
    name_dcycle:
      'undertaking_has_screened_whether_assets_and_business_activities_may_be_exposed_to_climate-related_hazards',
    datapoint_id: 'E1.IRO-1_04',
    esg_category: 'environmental',
    name_en:
      'Undertaking has screened whether assets and business activities may be exposed to climate-related hazards',
    name_es:
      'La empresa ha examinado si los activos y las actividades comerciales pueden estar expuestos a peligros relacionados con el clima.',
    name_pt:
      'A empresa examinou se os ativos e as atividades empresariais podem estar expostos a riscos relacionados com o clima'
  },
  {
    id: '05cf5828-3b2a-4b39-bc09-91bc12abd1cb',
    name_dcycle: 'short-,_medium-_and_long-term_time_horizons_have_been_defined',
    datapoint_id: 'E1.IRO-1_05',
    esg_category: 'environmental',
    name_en: 'Short-, medium- and long-term time horizons have been defined',
    name_es: 'Se han definido horizontes temporales a corto, medio y largo plazo.',
    name_pt: 'Foram definidos horizontes temporais de curto, médio e longo prazo'
  },
  {
    id: '4d0d6418-8dbe-4592-8715-c174de58b056',
    name_dcycle:
      'extent_to_which_assets_and_business_activities_may_be_exposed_and_are_sensitive_to_identified_climate-related_hazards_has_been_assessed',
    datapoint_id: 'E1.IRO-1_06',
    esg_category: 'environmental',
    name_en:
      'Extent to which assets and business activities may be exposed and are sensitive to identified climate-related hazards has been assessed',
    name_es:
      'Se ha evaluado el grado en que los activos y las actividades comerciales pueden estar expuestos y son sensibles a los peligros relacionados con el clima identificados.',
    name_pt:
      'Foi avaliada a extensão em que os ativos e as atividades empresariais podem estar expostos e são sensíveis aos perigos identificados relacionados com o clima.'
  },
  {
    id: '38964af8-1798-4e90-bb5e-a88c8e11f3c1',
    name_dcycle:
      'identification_of_climate-related_hazards_and_assessment_of_exposure_and_sensitivity_are_informed_by_high_emissions_climate_scenarios',
    datapoint_id: 'E1.IRO-1_07',
    esg_category: 'environmental',
    name_en:
      'Identification of climate-related hazards and assessment of exposure and sensitivity are informed by high emissions climate scenarios',
    name_es:
      'La identificación de peligros relacionados con el clima y la evaluación de la exposición y la sensibilidad se basan en escenarios climáticos de altas emisiones.',
    name_pt:
      'A identificação de perigos relacionados com o clima e a avaliação da exposição e sensibilidade são informadas por cenários climáticos de emissões elevadas'
  },
  {
    id: 'ec4c8dac-f496-450b-9196-eba6839e6d76',
    name_dcycle:
      'explanation_of_how_climate-related_scenario_analysis_has_been_used_to_inform_identification_and_assessment_of_physical_risks_over_short,_medium_and_long-term',
    datapoint_id: 'E1.IRO-1_08',
    esg_category: 'environmental',
    name_en:
      'Explanation of how climate-related scenario analysis has been used to inform identification and assessment of physical risks over short, medium and long-term',
    name_es:
      'Explicación de cómo se ha utilizado el análisis de escenarios relacionados con el clima para informar la identificación y evaluación de riesgos físicos a corto, mediano y largo plazo.',
    name_pt:
      'Explicação de como a análise de cenários relacionados ao clima tem sido usada para informar a identificação e avaliação de riscos físicos no curto, médio e longo prazo'
  },
  {
    id: 'f848012c-05df-4355-9e92-f91ecc21347f',
    name_dcycle:
      'description_of_process_in_relation_to_climate-related_transition_risks_and_opportunities_in_own_operations_and_along_value_chain',
    datapoint_id: 'E1.IRO-1_09',
    esg_category: 'environmental',
    name_en:
      'Description of process in relation to climate-related transition risks and opportunities in own operations and along value chain',
    name_es:
      'Descripción del proceso en relación con los riesgos y oportunidades de transición relacionados con el clima en las operaciones propias y a lo largo de la cadena de valor.',
    name_pt:
      'Descrição do processo em relação aos riscos e oportunidades de transição relacionados com o clima nas próprias operações e ao longo da cadeia de valor'
  },
  {
    id: 'b26ea4f6-580e-4189-b663-7478e212f9c1',
    name_dcycle:
      'transition_events_have_been_identified_over_short-,_medium-_and_long-term_time_horizons',
    datapoint_id: 'E1.IRO-1_10',
    esg_category: 'environmental',
    name_en:
      'Transition events have been identified over short-, medium- and long-term time horizons',
    name_es:
      'Se han identificado eventos de transición en horizontes temporales de corto, mediano y largo plazo.',
    name_pt: 'Eventos de transição foram identificados em horizontes de curto, médio e longo prazo'
  },
  {
    id: 'c978b6d0-6a1d-4191-95fa-dff2b4ca14b3',
    name_dcycle:
      'undertaking_has_screened_whether_assets_and_business_activities_may_be_exposed_to_transition_events',
    datapoint_id: 'E1.IRO-1_11',
    esg_category: 'environmental',
    name_en:
      'Undertaking has screened whether assets and business activities may be exposed to transition events',
    name_es:
      'La empresa ha examinado si los activos y las actividades comerciales pueden estar expuestos a eventos de transición.',
    name_pt:
      'A empresa analisou se os ativos e as atividades empresariais podem estar expostos a eventos de transição'
  },
  {
    id: '370295d2-c4d4-46a0-9b94-bcbe4981d2d8',
    name_dcycle:
      'extent_to_which_assets_and_business_activities_may_be_exposed_and_are_sensitive_to_identified_transition_events_has_been_assessed',
    datapoint_id: 'E1.IRO-1_12',
    esg_category: 'environmental',
    name_en:
      'Extent to which assets and business activities may be exposed and are sensitive to identified transition events has been assessed',
    name_es:
      'Se ha evaluado el grado en que los activos y las actividades comerciales pueden estar expuestos y son sensibles a eventos de transición identificados.',
    name_pt:
      'Foi avaliada a extensão em que os ativos e atividades comerciais podem estar expostos e são sensíveis a eventos de transição identificados'
  },
  {
    id: '5d29de55-a7da-48fa-83de-c11c173ddfd7',
    name_dcycle:
      'identification_of_transition_events_and_assessment_of_exposure_has_been_informed_by_climate-related_scenario_analysis',
    datapoint_id: 'E1.IRO-1_13',
    esg_category: 'environmental',
    name_en:
      'Identification of transition events and assessment of exposure has been informed by climate-related scenario analysis',
    name_es:
      'La identificación de eventos de transición y la evaluación de la exposición se han basado en análisis de escenarios relacionados con el clima.',
    name_pt:
      'A identificação de eventos de transição e a avaliação da exposição foram informadas pela análise de cenários relacionados com o clima'
  },
  {
    id: '94513e1a-c4c0-4ab6-8a37-1b2bb525c342',
    name_dcycle:
      'assets_and_business_activities_that_are_incompatible_with_or_need_significant_efforts_to_be_compatible_with_transition_to_climate-neutral_economy_have_been_identified',
    datapoint_id: 'E1.IRO-1_14',
    esg_category: 'environmental',
    name_en:
      'Assets and business activities that are incompatible with or need significant efforts to be compatible with transition to climate-neutral economy have been identified',
    name_es:
      'Se han identificado activos y actividades comerciales que son incompatibles con la transición a una economía climáticamente neutra o que necesitan esfuerzos significativos para ser compatibles con ella.',
    name_pt:
      'Foram identificados ativos e atividades empresariais que são incompatíveis ou que necessitam de esforços significativos para serem compatíveis com a transição para uma economia com impacto neutro no clima'
  },
  {
    id: 'b1df30ad-1691-4c89-8a4a-c76ecf37224e',
    name_dcycle:
      'explanation_of_how_climate-related_scenario_analysis_has_been_used_to_inform_identification_and_assessment_of_transition_risks_and_opportunities_over_short,_medium_and_long-term',
    datapoint_id: 'E1.IRO-1_15',
    esg_category: 'environmental',
    name_en:
      'Explanation of how climate-related scenario analysis has been used to inform identification and assessment of transition risks and opportunities over short, medium and long-term',
    name_es:
      'Explicación de cómo se ha utilizado el análisis de escenarios relacionados con el clima para informar la identificación y evaluación de los riesgos y oportunidades de transición a corto, mediano y largo plazo.',
    name_pt:
      'Explicação de como a análise de cenários relacionados ao clima tem sido usada para informar a identificação e avaliação de riscos e oportunidades de transição no curto, médio e longo prazo'
  },
  {
    id: '8a3318c1-7fba-4022-92ef-606e712b7f5c',
    name_dcycle:
      'explanation_of_how_climate_scenarios_used_are_compatible_with_critical_climate-related_assumptions_made_in_financial_statements',
    datapoint_id: 'E1.IRO-1_16',
    esg_category: 'environmental',
    name_en:
      'Explanation of how climate scenarios used are compatible with critical climate-related assumptions made in financial statements',
    name_es:
      'Explicación de cómo los escenarios climáticos utilizados son compatibles con los supuestos críticos relacionados con el clima hechos en los estados financieros.',
    name_pt:
      'Explicação de como os cenários climáticos utilizados são compatíveis com as premissas críticas relacionadas ao clima feitas nas demonstrações financeiras'
  },
  {
    id: 'ed19f583-a1ad-4e91-b914-98d43e4e8e98',
    name_dcycle: 'sustainability_matters_addressed_by_policy_for_climate_change',
    datapoint_id: 'E1-2_01',
    esg_category: 'environmental',
    name_en: 'Sustainability matters addressed by policy for climate change',
    name_es: 'Cuestiones de sostenibilidad abordadas por la política de cambio climático',
    name_pt: 'Questões de sustentabilidade abordadas pela política para as alterações climáticas'
  },
  {
    id: 'd6c6b91a-f2b6-4524-8927-9f37d9d72e68',
    name_dcycle: 'decarbonisation_lever_type',
    datapoint_id: 'E1-3_01',
    esg_category: 'environmental',
    name_en: 'Decarbonisation lever type',
    name_es: 'Tipo palanca de descarbonización',
    name_pt: 'Tipo de alavanca de descarbonização'
  },
  {
    id: '83a9a60e-d826-4344-9665-41741e3c2500',
    name_dcycle: 'adaptation_solution_type',
    datapoint_id: 'E1-3_02',
    esg_category: 'environmental',
    name_en: 'Adaptation solution type',
    name_es: 'Tipo de solución de adaptación',
    name_pt: 'Tipo de solução de adaptação'
  },
  {
    id: 'bba73b06-8f1a-4243-bc7b-f951d5378a86',
    name_dcycle: 'achieved_ghg_emission_reductions',
    datapoint_id: 'E1-3_03',
    esg_category: 'environmental',
    name_en: 'Achieved GHG emission reductions',
    name_es: 'Reducciones de emisiones de GEI logradas',
    name_pt: 'Reduções de emissões de GEE alcançadas'
  },
  {
    id: 'bd0163be-fca1-4773-adf5-915a1569154c',
    name_dcycle: 'expected_ghg_emission_reductions',
    datapoint_id: 'E1-3_04',
    esg_category: 'environmental',
    name_en: 'Expected GHG emission reductions',
    name_es: 'Reducciones esperadas de emisiones de GEI',
    name_pt: 'Reduções esperadas de emissões de GEE'
  },
  {
    id: '80448d89-ca70-4ca1-aedc-93dc3de726f9',
    name_dcycle:
      'explanation_of_extent_to_which_ability_to_implement_action_depends_on_availability_and_allocation_of_resources',
    datapoint_id: 'E1-3_05',
    esg_category: 'environmental',
    name_en:
      'Explanation of extent to which ability to implement action depends on availability and allocation of resources',
    name_es:
      'Explicación de hasta qué punto la capacidad de implementar acciones depende de la disponibilidad y asignación de recursos',
    name_pt:
      'Explicação de até que ponto a capacidade de implementar ações depende da disponibilidade e alocação de recursos'
  },
  {
    id: 'ab5a5d25-d319-444a-bad3-432a96900ad0',
    name_dcycle:
      'explanation_of_relationship_of_significant_capex_and_opex_required_to_implement_actions_taken_or_planned_to_relevant_line_items_or_notes_in_financial_statements',
    datapoint_id: 'E1-3_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of relationship of significant CapEx and OpEx required to implement actions taken or planned to relevant line items or notes in financial statements',
    name_es:
      'Explicación de la relación entre los gastos de capital y los gastos operativos significativos necesarios para implementar las acciones tomadas o planificadas con las partidas o notas relevantes de los estados financieros.',
    name_pt:
      'Explicação da relação de CapEx e OpEx significativos necessários para implementar ações tomadas ou planejadas para itens de linha ou notas relevantes nas demonstrações financeiras'
  },
  {
    id: 'ca20fd00-d262-4c2b-9420-8bb4b7782f4b',
    name_dcycle:
      'explanation_of_relationship_of_significant_capex_and_opex_required_to_implement_actions_taken_or_planned_to_key_performance_indicators_required_under_commission_delegated_regulation_(eu)_2021/2178',
    datapoint_id: 'E1-3_07',
    esg_category: 'environmental',
    name_en:
      'Explanation of relationship of significant CapEx and OpEx required to implement actions taken or planned to key performance indicators required under Commission Delegated Regulation (EU) 2021/2178',
    name_es:
      'Explicación de la relación de los gastos de capital y gastos operativos significativos necesarios para implementar las acciones tomadas o planificadas con los indicadores clave de rendimiento requeridos según el Reglamento Delegado (UE) 2021/2178 de la Comisión.',
    name_pt:
      'Explicação da relação entre CapEx e OpEx significativos necessários para implementar ações tomadas ou planeadas com os principais indicadores de desempenho exigidos pelo Regulamento Delegado (UE) 2021/2178 da Comissão'
  },
  {
    id: 'ced3aaa8-cf95-476e-a0ac-df6bcae77200',
    name_dcycle:
      'explanation_of_relationship_of_significant_capex_and_opex_required_to_implement_actions_taken_or_planned_to_capex_plan_required_by_commission_delegated_regulation_(eu)_2021/2178',
    datapoint_id: 'E1-3_08',
    esg_category: 'environmental',
    name_en:
      'Explanation of relationship of significant CapEx and OpEx required to implement actions taken or planned to CapEx plan required by Commission Delegated Regulation (EU) 2021/2178',
    name_es:
      'Explicación de la relación entre los CapEx y OpEx significativos necesarios para implementar las acciones tomadas o planificadas con el plan de CapEx requerido por el Reglamento Delegado (UE) 2021/2178 de la Comisión.',
    name_pt:
      'Explicação da relação entre CapEx e OpEx significativos necessários para implementar ações tomadas ou planeadas para o plano de CapEx exigido pelo Regulamento Delegado (UE) 2021/2178 da Comissão'
  },
  {
    id: '15dd7ee1-365f-47e2-9aca-ed0ba701ce73',
    name_dcycle:
      'disclosure_of_whether_and_how_ghg_emissions_reduction_targets_and_(or)_any_other_targets_have_been_set_to_manage_material_climate-related_impacts,_risks_and_opportunities',
    datapoint_id: 'E1-4_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how GHG emissions reduction targets and (or) any other targets have been set to manage material climate-related impacts, risks and opportunities',
    name_es:
      'Divulgación de si y cómo se han establecido objetivos de reducción de emisiones de GEI y (o) cualquier otro objetivo para gestionar los impactos, riesgos y oportunidades materiales relacionados con el clima.',
    name_pt:
      'Divulgação de se e como as metas de redução de emissões de GEE e (ou) quaisquer outras metas foram definidas para gerenciar impactos, riscos e oportunidades materiais relacionados ao clima'
  },
  {
    id: 'fc1a5f37-9a13-4a40-87fe-6dd30b9de410',
    name_dcycle:
      'tables:_multiple_dimensions_(baseline_year_and_targets;_ghg_types,_scope_3_categories,_decarbonisation_levers,_entity-specific_denominators_for_intensity_value)',
    datapoint_id: 'E1-4_02',
    esg_category: 'environmental',
    name_en:
      'Tables: Multiple Dimensions (baseline year and targets; GHG Types, Scope 3 Categories, Decarbonisation levers, entity-specific denominators for intensity value)',
    name_es:
      'Tablas: Dimensiones múltiples (año de referencia y objetivos; tipos de GEI, categorías de alcance 3, palancas de descarbonización, denominadores específicos de cada entidad para el valor de intensidad)',
    name_pt:
      'Tabelas: Dimensões Múltiplas (ano de referência e metas; Tipos de GEE, Categorias de Escopo 3, Alavancas de descarbonização, denominadores específicos da entidade para valor de intensidade)'
  },
  {
    id: '72f952a8-6845-4155-99eb-53671b38dff6',
    name_dcycle: 'absolute_value_of_total_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_03',
    esg_category: 'environmental',
    name_en: 'Absolute value of total Greenhouse gas emissions reduction',
    name_es: 'Valor absoluto de la reducción total de emisiones de gases de efecto invernadero',
    name_pt: 'Valor absoluto da redução total das emissões de gases de efeito estufa'
  },
  {
    id: '398decef-b10d-4026-99c3-b83140390d1a',
    name_dcycle:
      'percentage_of_total_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
    datapoint_id: 'E1-4_04',
    esg_category: 'environmental',
    name_en:
      'Percentage of total Greenhouse gas emissions reduction (as of emissions of base year)',
    name_es:
      'Porcentaje de reducción total de emisiones de gases de efecto invernadero (a partir de las emisiones del año base)',
    name_pt:
      'Porcentagem da redução total de emissões de gases de efeito estufa (a partir das emissões do ano base)'
  },
  {
    id: '59a74142-b1c0-4853-b7c2-48f8b4799f5c',
    name_dcycle: 'intensity_value_of_total_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_05',
    esg_category: 'environmental',
    name_en: 'Intensity value of total Greenhouse gas emissions reduction',
    name_es:
      'Valor de intensidad de la reducción total de emisiones de gases de efecto invernadero',
    name_pt: 'Valor da intensidade da redução total das emissões de gases de efeito estufa'
  },
  {
    id: 'e7ce0781-7fdf-4766-97bf-b202ed51b1f1',
    name_dcycle: 'absolute_value_of_scope_1_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_06',
    esg_category: 'environmental',
    name_en: 'Absolute value of Scope 1 Greenhouse gas emissions reduction',
    name_es:
      'Valor absoluto de la reducción de emisiones de gases de efecto invernadero de Alcance 1',
    name_pt: 'Valor absoluto da redução de emissões de gases de efeito estufa do Escopo 1'
  },
  {
    id: '89a6bc49-1da6-4e6d-8452-d93c04d7a9d9',
    name_dcycle:
      'percentage_of_scope_1_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
    datapoint_id: 'E1-4_07',
    esg_category: 'environmental',
    name_en:
      'Percentage of Scope 1 Greenhouse gas emissions reduction (as of emissions of base year)',
    name_es:
      'Porcentaje de reducción de emisiones de gases de efecto invernadero de Alcance 1 (a partir de las emisiones del año base)',
    name_pt:
      'Porcentagem de redução de emissões de gases de efeito estufa do Escopo 1 (a partir das emissões do ano base)'
  },
  {
    id: 'a737a2d7-4506-4bc8-b5c2-58a4451d420a',
    name_dcycle: 'intensity_value_of_scope_1_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_08',
    esg_category: 'environmental',
    name_en: 'Intensity value of Scope 1 Greenhouse gas emissions reduction',
    name_es:
      'Valor de intensidad de la reducción de emisiones de gases de efecto invernadero de Alcance 1',
    name_pt: 'Valor de intensidade da redução de emissões de gases de efeito estufa do Escopo 1'
  },
  {
    id: '63e99313-6520-4b37-a974-7fe9a27bdcc2',
    name_dcycle: 'absolute_value_of_location-based_scope_2_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_09',
    esg_category: 'environmental',
    name_en: 'Absolute value of location-based Scope 2 Greenhouse gas emissions reduction',
    name_es:
      'Valor absoluto de la reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en la ubicación',
    name_pt:
      'Valor absoluto da redução de emissões de gases de efeito estufa de Escopo 2 com base na localização'
  },
  {
    id: '2eb5b372-5cfb-474b-9b16-14bb2d1202ca',
    name_dcycle:
      'percentage_of_location-based_scope_2_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
    datapoint_id: 'E1-4_10',
    esg_category: 'environmental',
    name_en:
      'Percentage of location-based Scope 2 Greenhouse gas emissions reduction (as of emissions of base year)',
    name_es:
      'Porcentaje de reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en la ubicación (a partir de las emisiones del año base)',
    name_pt:
      'Porcentagem de redução de emissões de gases de efeito estufa de Escopo 2 com base na localização (a partir das emissões do ano base)'
  },
  {
    id: '27d7cf8a-19ec-4ef2-b189-722dad6d5573',
    name_dcycle: 'intensity_value_of_location-based_scope_2_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_11',
    esg_category: 'environmental',
    name_en: 'Intensity value of location-based Scope 2 Greenhouse gas emissions reduction',
    name_es:
      'Valor de intensidad de la reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en la ubicación',
    name_pt:
      'Valor de intensidade da redução de emissões de gases de efeito estufa de Escopo 2 com base na localização'
  },
  {
    id: '661b118d-86eb-445a-9060-33537b78687a',
    name_dcycle: 'absolute_value_of_market-based_scope_2_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_12',
    esg_category: 'environmental',
    name_en: 'Absolute value of market-based Scope 2 Greenhouse gas emissions reduction',
    name_es:
      'Valor absoluto de la reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en el mercado',
    name_pt:
      'Valor absoluto da redução de emissões de gases de efeito estufa de Escopo 2 com base no mercado'
  },
  {
    id: '09f641ab-d850-4ddb-bae3-b64e30ceab3b',
    name_dcycle:
      'percentage_of_market-based_scope_2_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
    datapoint_id: 'E1-4_13',
    esg_category: 'environmental',
    name_en:
      'Percentage of market-based Scope 2 Greenhouse gas emissions reduction (as of emissions of base year)',
    name_es:
      'Porcentaje de reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en el mercado (a partir de las emisiones del año base)',
    name_pt:
      'Porcentagem de redução de emissões de gases de efeito estufa de Escopo 2 com base no mercado (a partir das emissões do ano base)'
  },
  {
    id: '4cdbcb05-213b-484c-9b07-bc5a6ed45ad5',
    name_dcycle: 'intensity_value_of_market-based_scope_2_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_14',
    esg_category: 'environmental',
    name_en: 'Intensity value of market-based Scope 2 Greenhouse gas emissions reduction',
    name_es:
      'Valor de intensidad de la reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en el mercado',
    name_pt:
      'Valor da intensidade da redução de emissões de gases de efeito estufa de Escopo 2 com base no mercado'
  },
  {
    id: '8171542c-4e4c-44a0-a8a8-911a1497be46',
    name_dcycle: 'absolute_value_of_scope_3_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_15',
    esg_category: 'environmental',
    name_en: 'Absolute value of Scope 3 Greenhouse gas emissions reduction',
    name_es:
      'Valor absoluto de la reducción de emisiones de gases de efecto invernadero de Alcance 3',
    name_pt: 'Valor absoluto da redução de emissões de gases de efeito estufa do Escopo 3'
  },
  {
    id: '1a747fbf-e85b-45c1-939c-ae97d8326273',
    name_dcycle:
      'percentage_of_scope_3_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
    datapoint_id: 'E1-4_16',
    esg_category: 'environmental',
    name_en:
      'Percentage of Scope 3 Greenhouse gas emissions reduction (as of emissions of base year)',
    name_es:
      'Porcentaje de reducción de emisiones de gases de efecto invernadero de Alcance 3 (a partir de las emisiones del año base)',
    name_pt:
      'Porcentagem de redução de emissões de gases de efeito estufa do Escopo 3 (a partir das emissões do ano base)'
  },
  {
    id: '2a2140b1-7185-471b-a5e5-640251c98d68',
    name_dcycle: 'intensity_value_of_scope_3_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_17',
    esg_category: 'environmental',
    name_en: 'Intensity value of Scope 3 Greenhouse gas emissions reduction',
    name_es:
      'Valor de intensidad de la reducción de emisiones de gases de efecto invernadero de Alcance 3',
    name_pt: 'Valor de intensidade da redução de emissões de gases de efeito estufa do Escopo 3'
  },
  {
    id: '97da1db8-7adc-4e76-8c9f-69e81ba61535',
    name_dcycle:
      'explanation_of_how_consistency_of_ghg_emission_reduction_targets_with_ghg_inventory_boundaries_has_been_ensured',
    datapoint_id: 'E1-4_18',
    esg_category: 'environmental',
    name_en:
      'Explanation of how consistency of GHG emission reduction targets with GHG inventory boundaries has been ensured',
    name_es:
      'Explicación de cómo se ha garantizado la coherencia de los objetivos de reducción de emisiones de GEI con los límites del inventario de GEI',
    name_pt:
      'Explicação de como foi garantida a consistência das metas de redução de emissões de GEE com os limites do inventário de GEE'
  },
  {
    id: 'acd770ab-fa46-4e7e-87dd-5d0e8ca1d1ef',
    name_dcycle: 'disclosure_of_past_progress_made_in_meeting_target_before_current_base_year',
    datapoint_id: 'E1-4_19',
    esg_category: 'environmental',
    name_en: 'Disclosure of past progress made in meeting target before current base year',
    name_es:
      'Divulgación del progreso realizado en el pasado para cumplir la meta antes del año base actual',
    name_pt:
      'Divulgação dos progressos anteriores realizados no cumprimento da meta antes do ano base atual'
  },
  {
    id: '4088df8e-b070-4587-bed9-8ad8d9080e65',
    name_dcycle:
      'description_of_how_it_has_been_ensured_that_baseline_value_is_representative_in_terms_of_activities_covered_and_influences_from_external_factors',
    datapoint_id: 'E1-4_20',
    esg_category: 'environmental',
    name_en:
      'Description of how it has been ensured that baseline value is representative in terms of activities covered and influences from external factors',
    name_es:
      'Descripción de cómo se ha garantizado que el valor de referencia sea representativo en términos de las actividades cubiertas y las influencias de factores externos.',
    name_pt:
      'Descrição de como foi garantido que o valor de referência é representativo em termos de atividades abrangidas e influências de fatores externos'
  },
  {
    id: '9a23078c-c689-42cf-bc22-1b1616d8f3ca',
    name_dcycle:
      'description_of_how_new_baseline_value_affects_new_target,_its_achievement_and_presentation_of_progress_over_time',
    datapoint_id: 'E1-4_21',
    esg_category: 'environmental',
    name_en:
      'Description of how new baseline value affects new target, its achievement and presentation of progress over time',
    name_es:
      'Descripción de cómo el nuevo valor de referencia afecta el nuevo objetivo, su logro y presentación del progreso a lo largo del tiempo.',
    name_pt:
      'Descrição de como o novo valor da linha de base afeta a nova meta, seu alcance e apresentação do progresso ao longo do tempo'
  },
  {
    id: 'ef289fcc-015d-4bae-bb58-c849208eb761',
    name_dcycle:
      'ghg_emission_reduction_target_is_science_based_and_compatible_with_limiting_global_warming_to_one_and_half_degrees_celsius',
    datapoint_id: 'E1-4_22',
    esg_category: 'environmental',
    name_en:
      'GHG emission reduction target is science based and compatible with limiting global warming to one and half degrees Celsius',
    name_es:
      'El objetivo de reducción de emisiones de GEI tiene base científica y es compatible con limitar el calentamiento global a un grado y medio Celsius.',
    name_pt:
      'A meta de redução de emissões de GEE é baseada na ciência e é compatível com a limitação do aquecimento global a um grau e meio Celsius'
  },
  {
    id: '4237f7f8-bb8b-4921-8553-278646ba33cc',
    name_dcycle:
      'description_of_expected_decarbonisation_levers_and_their_overall_quantitative_contributions_to_achieve_ghg_emission_reduction_target',
    datapoint_id: 'E1-4_23',
    esg_category: 'environmental',
    name_en:
      'Description of expected decarbonisation levers and their overall quantitative contributions to achieve GHG emission reduction target',
    name_es:
      'Descripción de las palancas de descarbonización esperadas y sus contribuciones cuantitativas generales para lograr el objetivo de reducción de emisiones de GEI',
    name_pt:
      'Descrição das alavancas de descarbonização esperadas e das suas contribuições quantitativas globais para atingir a meta de redução das emissões de GEE'
  },
  {
    id: 'd06f33ea-2e51-4c4b-a8a4-ec56a9b2efe2',
    name_dcycle:
      'diverse_range_of_climate_scenarios_have_been_considered_to_detect_relevant_environmental,_societal,_technology,_market_and_policy-related_developments_and_determine_decarbonisation_levers',
    datapoint_id: 'E1-4_24',
    esg_category: 'environmental',
    name_en:
      'Diverse range of climate scenarios have been considered to detect relevant environmental, societal, technology, market and policy-related developments and determine decarbonisation levers',
    name_es:
      'Se ha considerado una amplia gama de escenarios climáticos para detectar desarrollos ambientales, sociales, tecnológicos, de mercado y políticos relevantes y determinar palancas de descarbonización.',
    name_pt:
      'Uma ampla gama de cenários climáticos foi considerada para detectar desenvolvimentos ambientais, sociais, tecnológicos, de mercado e políticos relevantes e determinar alavancas de descarbonização'
  },
  {
    id: 'cfcaad63-ec69-4f28-a295-21f5fbabed5e',
    name_dcycle: 'total_energy_consumption_related_to_own_operations',
    datapoint_id: 'E1-5_01',
    esg_category: 'environmental',
    name_en: 'Total energy consumption related to own operations',
    name_es: 'Consumo total de energía relacionado con operaciones propias.',
    name_pt: 'Consumo total de energia relacionado às operações próprias'
  },
  {
    id: 'bc9db2e6-3c17-4e22-8712-8cbe84bc0f7b',
    name_dcycle: 'total_energy_consumption_from_fossil_sources',
    datapoint_id: 'E1-5_02',
    esg_category: 'environmental',
    name_en: 'Total energy consumption from fossil sources',
    name_es: 'Consumo total de energía procedente de fuentes fósiles',
    name_pt: 'Consumo total de energia proveniente de fontes fósseis'
  },
  {
    id: 'e39819a3-5af5-4ceb-aa29-dc97934a7f5f',
    name_dcycle: 'total_energy_consumption_from_nuclear_sources',
    datapoint_id: 'E1-5_03',
    esg_category: 'environmental',
    name_en: 'Total energy consumption from nuclear sources',
    name_es: 'Consumo total de energía procedente de fuentes nucleares',
    name_pt: 'Consumo total de energia proveniente de fontes nucleares'
  },
  {
    id: '851b0bd6-f9c8-40d1-a6b8-7d3c549f21c7',
    name_dcycle:
      'percentage_of_energy_consumption_from_nuclear_sources_in_total_energy_consumption',
    datapoint_id: 'E1-5_04',
    esg_category: 'environmental',
    name_en: 'Percentage of energy consumption from nuclear sources in total energy consumption',
    name_es:
      'Porcentaje del consumo de energía procedente de fuentes nucleares sobre el consumo total de energía',
    name_pt:
      'Percentagem do consumo de energia proveniente de fontes nucleares no consumo total de energia'
  },
  {
    id: 'f5568f99-7bad-489d-9f27-ef3272c75a6d',
    name_dcycle: 'total_energy_consumption_from_renewable_sources',
    datapoint_id: 'E1-5_05',
    esg_category: 'environmental',
    name_en: 'Total energy consumption from renewable sources',
    name_es: 'Consumo total de energía procedente de fuentes renovables',
    name_pt: 'Consumo total de energia proveniente de fontes renováveis'
  },
  {
    id: '2951cb37-c21d-4f51-b809-a5915f5144f8',
    name_dcycle: 'fuel_consumption_from_renewable_sources',
    datapoint_id: 'E1-5_06',
    esg_category: 'environmental',
    name_en: 'Fuel consumption from renewable sources',
    name_es: 'Consumo de combustible procedente de fuentes renovables.',
    name_pt: 'Consumo de combustível proveniente de fontes renováveis'
  },
  {
    id: '565b1be5-5a80-4c5a-9c13-c98b32ff235f',
    name_dcycle:
      'consumption_of_purchased_or_acquired_electricity,_heat,_steam,_and_cooling_from_renewable_sources',
    datapoint_id: 'E1-5_07',
    esg_category: 'environmental',
    name_en:
      'Consumption of purchased or acquired electricity, heat, steam, and cooling from renewable sources',
    name_es:
      'Consumo de electricidad, calor, vapor y refrigeración comprados o adquiridos de fuentes renovables',
    name_pt:
      'Consumo de eletricidade, calor, vapor e resfriamento comprados ou adquiridos de fontes renováveis'
  },
  {
    id: 'e4307110-af79-42d3-a229-9529490a2533',
    name_dcycle: 'consumption_of_self-generated_non-fuel_renewable_energy',
    datapoint_id: 'E1-5_08',
    esg_category: 'environmental',
    name_en: 'Consumption of self-generated non-fuel renewable energy',
    name_es: 'Consumo de energía renovable autogenerada no combustible',
    name_pt: 'Consumo de energia renovável não combustível autogerada'
  },
  {
    id: '2d74a40f-dc5d-410d-b4d0-ec74f4ae3b2c',
    name_dcycle: 'percentage_of_renewable_sources_in_total_energy_consumption',
    datapoint_id: 'E1-5_09',
    esg_category: 'environmental',
    name_en: 'Percentage of renewable sources in total energy consumption',
    name_es: 'Porcentaje de fuentes renovables en el consumo total de energía',
    name_pt: 'Percentagem de fontes renováveis ​​no consumo total de energia'
  },
  {
    id: 'e37a66a0-ba84-4c7a-9050-025cd6451f9a',
    name_dcycle: 'fuel_consumption_from_coal_and_coal_products',
    datapoint_id: 'E1-5_10',
    esg_category: 'environmental',
    name_en: 'Fuel consumption from coal and coal products',
    name_es: 'Consumo de combustible procedente de carbón y productos del carbón.',
    name_pt: 'Consumo de combustível proveniente de carvão e produtos de carvão'
  },
  {
    id: 'a30c7eb0-81cc-4a51-b9a3-9cbf3334e3e4',
    name_dcycle: 'fuel_consumption_from_crude_oil_and_petroleum_products',
    datapoint_id: 'E1-5_11',
    esg_category: 'environmental',
    name_en: 'Fuel consumption from crude oil and petroleum products',
    name_es:
      'Consumo de combustible procedente de petróleo crudo y productos derivados del petróleo.',
    name_pt: 'Consumo de combustível proveniente de petróleo bruto e produtos petrolíferos'
  },
  {
    id: '6eefbfeb-a2fe-47e7-9373-dd9dca7251b8',
    name_dcycle: 'fuel_consumption_from_natural_gas',
    datapoint_id: 'E1-5_12',
    esg_category: 'environmental',
    name_en: 'Fuel consumption from natural gas',
    name_es: 'Consumo de combustible procedente de gas natural.',
    name_pt: 'Consumo de combustível proveniente de gás natural'
  },
  {
    id: '826c0a7f-0974-4b8e-b000-e710510172b8',
    name_dcycle: 'fuel_consumption_from_other_fossil_sources',
    datapoint_id: 'E1-5_13',
    esg_category: 'environmental',
    name_en: 'Fuel consumption from other fossil sources',
    name_es: 'Consumo de combustible de otras fuentes fósiles',
    name_pt: 'Consumo de combustível de outras fontes fósseis'
  },
  {
    id: '9cf9f66e-65f2-4206-bfe1-73e271586177',
    name_dcycle:
      'consumption_of_purchased_or_acquired_electricity,_heat,_steam,_or_cooling_from_fossil_sources',
    datapoint_id: 'E1-5_14',
    esg_category: 'environmental',
    name_en:
      'Consumption of purchased or acquired electricity, heat, steam, or cooling from fossil sources',
    name_es:
      'Consumo de electricidad, calor, vapor o refrigeración comprados o adquiridos de fuentes fósiles',
    name_pt:
      'Consumo de eletricidade, calor, vapor ou resfriamento comprados ou adquiridos de fontes fósseis'
  },
  {
    id: '8e2e53e5-850d-4c52-93cb-a9feabb4f95f',
    name_dcycle: 'percentage_of_fossil_sources_in_total_energy_consumption',
    datapoint_id: 'E1-5_15',
    esg_category: 'environmental',
    name_en: 'Percentage of fossil sources in total energy consumption',
    name_es: 'Porcentaje de fuentes fósiles en el consumo total de energía',
    name_pt: 'Percentagem de fontes fósseis no consumo total de energia'
  },
  {
    id: 'ed2558eb-3d24-483c-89f5-ae82ec4875f8',
    name_dcycle: 'non-renewable_energy_production',
    datapoint_id: 'E1-5_16',
    esg_category: 'environmental',
    name_en: 'Non-renewable energy production',
    name_es: 'Producción de energía no renovable',
    name_pt: 'Produção de energia não renovável'
  },
  {
    id: 'c55b6213-9a96-4465-b269-f119ec6ab57c',
    name_dcycle: 'renewable_energy_production',
    datapoint_id: 'E1-5_17',
    esg_category: 'environmental',
    name_en: 'Renewable energy production',
    name_es: 'Producción de energía renovable',
    name_pt: 'Produção de energia renovável'
  },
  {
    id: 'b9d1cd00-c4d2-4ff1-a2d2-3919da32ec80',
    name_dcycle:
      'energy_intensity_from_activities_in_high_climate_impact_sectors_(total_energy_consumption_per_net_revenue)',
    datapoint_id: 'E1-5_18',
    esg_category: 'environmental',
    name_en:
      'Energy intensity from activities in high climate impact sectors (total energy consumption per net revenue)',
    name_es:
      'Intensidad energética de actividades en sectores de alto impacto climático (consumo total de energía por ingreso neto)',
    name_pt:
      'Intensidade energética proveniente de atividades em setores de elevado impacto climático (consumo total de energia por receita líquida)'
  },
  {
    id: '41a20803-c3d8-4e02-97fe-34ec3ec19ab7',
    name_dcycle: 'total_energy_consumption_from_activities_in_high_climate_impact_sectors',
    datapoint_id: 'E1-5_19',
    esg_category: 'environmental',
    name_en: 'Total energy consumption from activities in high climate impact sectors',
    name_es: 'Consumo total de energía de actividades en sectores de alto impacto climático',
    name_pt:
      'Consumo total de energia proveniente de atividades em setores de elevado impacto climático'
  },
  {
    id: 'bc2bfe9d-35fd-4d53-8822-2112b35667f8',
    name_dcycle: 'high_climate_impact_sectors_used_to_determine_energy_intensity',
    datapoint_id: 'E1-5_20',
    esg_category: 'environmental',
    name_en: 'High climate impact sectors used to determine energy intensity',
    name_es:
      'Sectores de alto impacto climático utilizados para determinar la intensidad energética',
    name_pt: 'Setores de alto impacto climático usados ​​para determinar a intensidade energética'
  },
  {
    id: 'f07c343c-9c21-4dbd-b11f-06ee272e0641',
    name_dcycle:
      'disclosure_of_reconciliation_to_relevant_line_item_or_notes_in_financial_statements_of_net_revenue_from_activities_in_high_climate_impact_sectors',
    datapoint_id: 'E1-5_21',
    esg_category: 'environmental',
    name_en:
      'Disclosure of reconciliation to relevant line item or notes in financial statements of net revenue from activities in high climate impact sectors',
    name_es:
      'Divulgación de la conciliación con partidas o notas relevantes en los estados financieros de ingresos netos de actividades en sectores de alto impacto climático',
    name_pt:
      'Divulgação da reconciliação com itens de linha relevantes ou notas nas demonstrações financeiras da receita líquida de atividades em setores de alto impacto climático'
  },
  {
    id: '31c190a0-cc60-4ec0-8a9e-912e022d704e',
    name_dcycle: 'net_revenue_from_activities_in_high_climate_impact_sectors',
    datapoint_id: 'E1-5_22',
    esg_category: 'environmental',
    name_en: 'Net revenue from activities in high climate impact sectors',
    name_es: 'Ingresos netos de actividades en sectores de alto impacto climático',
    name_pt: 'Receita líquida de atividades em setores de alto impacto climático'
  },
  {
    id: '2592da5f-0adb-4f4b-95de-843f531b94f4',
    name_dcycle: 'net_revenue_from_activities_other_than_in_high_climate_impact_sectors',
    datapoint_id: 'E1-5_23',
    esg_category: 'environmental',
    name_en: 'Net revenue from activities other than in high climate impact sectors',
    name_es: 'Ingresos netos de actividades distintas a las de sectores de alto impacto climático',
    name_pt: 'Receita líquida de atividades que não sejam de setores de elevado impacto climático'
  },
  {
    id: 'c0c7781a-380b-4025-9782-603d98652362',
    name_dcycle: 'gross_scopes_1,_2,_3_and_total_ghg_emissions_-_ghg_emissions_per_scope_[table]',
    datapoint_id: 'E1-6_01',
    esg_category: 'environmental',
    name_en: 'Gross Scopes 1, 2, 3 and Total GHG emissions - GHG emissions per scope [table]',
    name_es:
      'Alcances brutos 1, 2, 3 y emisiones totales de GEI - Emisiones de GEI por alcance [tabla]',
    name_pt:
      'Emissões Brutas de Escopos 1, 2, 3 e Total de GEE - Emissões de GEE por escopo [tabela]'
  },
  {
    id: '6b1203ec-2c19-4c12-9270-e6cdf1126cd4',
    name_dcycle:
      'gross_scopes_1,_2,_3_and_total_ghg_emissions_-_financial_and_operational_control_[table]',
    datapoint_id: 'E1-6_02',
    esg_category: 'environmental',
    name_en:
      'Gross Scopes 1, 2, 3 and Total GHG emissions - financial and operational control [table]',
    name_es:
      'Alcances brutos 1, 2, 3 y emisiones totales de GEI - control financiero y operativo [tabla]',
    name_pt:
      'Emissões brutas de Escopos 1, 2, 3 e Total de GEE – controle financeiro e operacional [tabela]'
  },
  {
    id: 'c82bf531-6af0-4b86-83c3-807c7d208f32',
    name_dcycle:
      'disaggregation_of_ghg_emissions_-_by_country,_operating_segments,_economic_activity,_subsidiary,_ghg_category_or_source_type',
    datapoint_id: 'E1-6_03',
    esg_category: 'environmental',
    name_en:
      'Disaggregation of GHG emissions - by country, operating segments, economic activity, subsidiary, GHG category or source type',
    name_es:
      'Desagregación de emisiones de GEI - por país, segmentos operativos, actividad económica, subsidiaria, categoría de GEI o tipo de fuente',
    name_pt:
      'Desagregação das emissões de GEE – por país, segmentos operacionais, atividade económica, subsidiária, categoria de GEE ou tipo de fonte'
  },
  {
    id: 'e2fcccd4-5926-4834-950b-b579aeb9d8b0',
    name_dcycle:
      'gross_scopes_1,_2,_3_and_total_ghg_emissions_-_scope_3_ghg_emissions_(ghg_protocol)_[table]',
    datapoint_id: 'E1-6_04',
    esg_category: 'environmental',
    name_en:
      'Gross Scopes 1, 2, 3 and Total GHG emissions - Scope 3 GHG emissions (GHG Protocol) [table]',
    name_es:
      'Emisiones brutas de GEI de alcance 1, 2, 3 y totales de GEI - Emisiones de GEI de alcance 3 (Protocolo de GEI) [tabla]',
    name_pt:
      'Emissões brutas de GEE de Escopo 1, 2, 3 e Total - Emissões de GEE de Escopo 3 (GHG Protocol) [tabela]'
  },
  {
    id: 'b28b8bab-a5b0-487e-bfe6-f8fb89c9761a',
    name_dcycle:
      'gross_scopes_1,_2,_3_and_total_ghg_emissions_-_scope_3_ghg_emissions_(iso_14064-1)_[table]',
    datapoint_id: 'E1-6_05',
    esg_category: 'environmental',
    name_en:
      'Gross Scopes 1, 2, 3 and Total GHG emissions - Scope 3 GHG emissions (ISO 14064-1) [table]',
    name_es:
      'Emisiones brutas de GEI de alcance 1, 2, 3 y totales de GEI - Emisiones de GEI de alcance 3 (ISO 14064-1) [tabla]',
    name_pt:
      'Emissões brutas de GEE de Escopo 1, 2, 3 e Total - Emissões de GEE de Escopo 3 (ISO 14064-1) [tabela]'
  },
  {
    id: 'f14271d7-f701-4ebc-be3c-36ea927b327b',
    name_dcycle:
      'gross_scopes_1,_2,_3_and_total_ghg_emissions_-_total_ghg_emissions_-_value_chain_[table]',
    datapoint_id: 'E1-6_06',
    esg_category: 'environmental',
    name_en:
      'Gross Scopes 1, 2, 3 and Total GHG emissions - total GHG emissions - value chain [table]',
    name_es:
      'Alcances brutos 1, 2, 3 y emisiones totales de GEI - emisiones totales de GEI - cadena de valor [tabla]',
    name_pt:
      'Escopos brutos 1, 2, 3 e emissões totais de GEE - emissões totais de GEE - cadeia de valor [tabela]'
  },
  {
    id: '2502ab87-6d00-448a-9932-433338af0f7a',
    name_dcycle: 'gross_scope_1_greenhouse_gas_emissions',
    datapoint_id: 'E1-6_07',
    esg_category: 'environmental',
    name_en: 'Gross Scope 1 greenhouse gas emissions',
    name_es: 'Emisiones brutas de gases de efecto invernadero de alcance 1',
    name_pt: 'Emissões brutas de gases de efeito estufa de Escopo 1'
  },
  {
    id: 'b4802d24-57c6-49f7-b6a6-c62126e2379b',
    name_dcycle: 'percentage_of_scope_1_ghg_emissions_from_regulated_emission_trading_schemes',
    datapoint_id: 'E1-6_08',
    esg_category: 'environmental',
    name_en: 'Percentage of Scope 1 GHG emissions from regulated emission trading schemes',
    name_es:
      'Porcentaje de emisiones de GEI de Alcance 1 provenientes de esquemas regulados de comercio de derechos de emisión',
    name_pt:
      'Porcentagem de emissões de GEE de Escopo 1 provenientes de esquemas regulamentados de comércio de emissões'
  },
  {
    id: '9a71aa99-812f-4ccd-b41b-35ed6105115b',
    name_dcycle: 'gross_location-based_scope_2_greenhouse_gas_emissions',
    datapoint_id: 'E1-6_09',
    esg_category: 'environmental',
    name_en: 'Gross location-based Scope 2 greenhouse gas emissions',
    name_es: 'Emisiones brutas de gases de efecto invernadero de alcance 2 basadas en la ubicación',
    name_pt: 'Emissões brutas de gases de efeito estufa de Escopo 2 com base na localização'
  },
  {
    id: 'd430bef5-1f0f-48a7-8087-518f54c1ad65',
    name_dcycle: 'gross_market-based_scope_2_greenhouse_gas_emissions',
    datapoint_id: 'E1-6_10',
    esg_category: 'environmental',
    name_en: 'Gross market-based Scope 2 greenhouse gas emissions',
    name_es: 'Emisiones brutas de gases de efecto invernadero de alcance 2 basadas en el mercado',
    name_pt: 'Emissões brutas de gases de efeito estufa de Escopo 2 com base no mercado'
  },
  {
    id: 'c420172e-da1b-42b1-8e4d-fa94137f017e',
    name_dcycle: 'gross_scope_3_greenhouse_gas_emissions',
    datapoint_id: 'E1-6_11',
    esg_category: 'environmental',
    name_en: 'Gross Scope 3 greenhouse gas emissions',
    name_es: 'Emisiones brutas de gases de efecto invernadero de alcance 3',
    name_pt: 'Emissões brutas de gases de efeito estufa de Escopo 3'
  },
  {
    id: 'bfad23d9-cf34-471f-b6e0-3ad992b20f1f',
    name_dcycle: 'total_ghg_emissions_location_based',
    datapoint_id: 'E1-6_12',
    esg_category: 'environmental',
    name_en: 'Total GHG emissions location based',
    name_es: 'Ubicación de las emisiones totales de GEI según la ubicación',
    name_pt: 'Localização total das emissões de GEE com base'
  },
  {
    id: '367657d6-bbe9-4d71-bc8b-87a9ff4f73f4',
    name_dcycle: 'total_ghg_emissions_market_based',
    datapoint_id: 'E1-6_13',
    esg_category: 'environmental',
    name_en: 'Total GHG emissions market based',
    name_es: 'Total de emisiones de GEI basado en el mercado',
    name_pt: 'Total de emissões de GEE com base no mercado'
  },
  {
    id: '6117188a-79be-4720-9add-adaa28d39b74',
    name_dcycle:
      'disclosure_of_significant_changes_in_definition_of_what_constitutes_reporting_undertaking_and_its_value_chain_and_explanation_of_their_effect_on_year-to-year_comparability_of_reported_ghg_emissions',
    datapoint_id: 'E1-6_14',
    esg_category: 'environmental',
    name_en:
      'Disclosure of significant changes in definition of what constitutes reporting undertaking and its value chain and explanation of their effect on year-to-year comparability of reported GHG emissions',
    name_es:
      'Divulgación de cambios significativos en la definición de lo que constituye una empresa que informa y su cadena de valor y explicación de su efecto en la comparabilidad año tras año de las emisiones de GEI reportadas.',
    name_pt:
      'Divulgação de alterações significativas na definição do que constitui empresa relatora e da sua cadeia de valor e explicação do seu efeito na comparabilidade anual das emissões de GEE comunicadas'
  },
  {
    id: '305b5e67-47ef-4c2e-b0cc-bd226afbc9f7',
    name_dcycle:
      'disclosure_of_methodologies,_significant_assumptions_and_emissions_factors_used_to_calculate_or_measure_ghg_emissions',
    datapoint_id: 'E1-6_15',
    esg_category: 'environmental',
    name_en:
      'Disclosure of methodologies, significant assumptions and emissions factors used to calculate or measure GHG emissions',
    name_es:
      'Divulgación de metodologías, supuestos importantes y factores de emisión utilizados para calcular o medir las emisiones de GEI.',
    name_pt:
      'Divulgação de metodologias, premissas significativas e fatores de emissão utilizados para calcular ou medir emissões de GEE'
  },
  {
    id: 'ea244665-5dee-4ca5-b583-8667b439e099',
    name_dcycle:
      'disclosure_of_the_effects_of_significant_events_and_changes_in_circumstances_(relevant_to_its_ghg_emissions)_that_occur_between_the_reporting_dates_of_the_entities_in_its_value_chain_and_the_date_of_the_undertaking’s_general_purpose_financial_statements',
    datapoint_id: 'E1-6_16',
    esg_category: 'environmental',
    name_en:
      'Disclosure of the effects of significant events and changes in circumstances (relevant to its GHG emissions) that occur between the reporting dates of the entities in its value chain and the date of the undertaking’s general purpose financial statements',
    name_es:
      'Revelación de los efectos de eventos significativos y cambios en las circunstancias (relevantes para sus emisiones de GEI) que ocurren entre las fechas de presentación de informes de las entidades en su cadena de valor y la fecha de los estados financieros con propósito general de la empresa.',
    name_pt:
      'Divulgação dos efeitos de eventos significativos e alterações nas circunstâncias (relevantes para as suas emissões de GEE) que ocorrem entre as datas de relato das entidades na sua cadeia de valor e a data das demonstrações financeiras de finalidade geral da empresa'
  },
  {
    id: '131a4cbd-573d-4dac-8e7b-0124ca112fbc',
    name_dcycle:
      'biogenic_emissions_of_co2_from_the_combustion_or_bio-degradation_of_biomassnot_included_in_scope_1_ghg_emissions',
    datapoint_id: 'E1-6_17',
    esg_category: 'environmental',
    name_en:
      'biogenic emissions of CO2 from the combustion or bio-degradation of biomassnot included in Scope 1 GHG emissions',
    name_es:
      'Emisiones biogénicas de CO2 procedentes de la combustión o biodegradación de biomasa no incluidas en el alcance 1 de emisiones de GEI.',
    name_pt:
      'emissões biogênicas de CO2 provenientes da combustão ou biodegradação de biomassa não incluídas nas emissões de GEE do Escopo 1'
  },
  {
    id: '455a4cad-c00d-406d-9978-d1860b3066f3',
    name_dcycle: 'percentage_of_contractual_instruments,_scope_2_ghg_emissions',
    datapoint_id: 'E1-6_18',
    esg_category: 'environmental',
    name_en: 'Percentage of contractual instruments, Scope 2 GHG emissions',
    name_es: 'Porcentaje de instrumentos contractuales, emisiones de GEI de alcance 2',
    name_pt: 'Percentual de instrumentos contratuais, emissões de GEE de Escopo 2'
  },
  {
    id: '6608e475-9392-410f-8047-220db1e47f2d',
    name_dcycle: 'disclosure_of_types_of_contractual_instruments,_scope_2_ghg_emissions',
    datapoint_id: 'E1-6_19',
    esg_category: 'environmental',
    name_en: 'Disclosure of types of contractual instruments, Scope 2 GHG emissions',
    name_es: 'Divulgación de tipos de instrumentos contractuales, emisiones de GEI de Alcance 2',
    name_pt: 'Divulgação dos tipos de instrumentos contratuais, emissões de GEE Escopo 2'
  },
  {
    id: 'c9f13c4d-6b2e-4b85-a9c6-fdf7fc0974c4',
    name_dcycle:
      'percentage_of_market-based_scope_2_ghg_emissions_linked_to_purchased_electricity_bundled_with_instruments',
    datapoint_id: 'E1-6_20',
    esg_category: 'environmental',
    name_en:
      'Percentage of market-based Scope 2 GHG emissions linked to purchased electricity bundled with instruments',
    name_es:
      'Porcentaje de emisiones de GEI de alcance 2 basadas en el mercado vinculadas a la electricidad comprada junto con instrumentos',
    name_pt:
      'Percentagem de emissões de GEE de Âmbito 2 baseadas no mercado associadas à eletricidade adquirida em conjunto com instrumentos'
  },
  {
    id: '3481eebc-0811-4533-80c8-07b9614073fd',
    name_dcycle:
      'percentage_of_contractual_instruments_used_for_sale_and_purchase_of_energy_bundled_with_attributes_about_energy_generation_in_relation_to_scope_2_ghg_emissions',
    datapoint_id: 'E1-6_21',
    esg_category: 'environmental',
    name_en:
      'Percentage of contractual instruments used for sale and purchase of energy bundled with attributes about energy generation in relation to Scope 2 GHG emissions',
    name_es:
      'Porcentaje de instrumentos contractuales utilizados para la venta y compra de energía combinados con atributos sobre generación de energía en relación con las emisiones de GEI de Alcance 2',
    name_pt:
      'Percentual de instrumentos contratuais utilizados para venda e compra de energia agrupados com atributos sobre geração de energia em relação às emissões de GEE Escopo 2'
  },
  {
    id: '9c877f25-25fc-4124-952d-671214c42abd',
    name_dcycle:
      'percentage_of_contractual_instruments_used_for_sale_and_purchase_of_unbundled_energy_attribute_claims_in_relation_to_scope_2_ghg_emissions',
    datapoint_id: 'E1-6_22',
    esg_category: 'environmental',
    name_en:
      'Percentage of contractual instruments used for sale and purchase of unbundled energy attribute claims in relation to Scope 2 GHG emissions',
    name_es:
      'Porcentaje de instrumentos contractuales utilizados para la venta y compra de reclamaciones de atributos de energía desagregados en relación con las emisiones de GEI de Alcance 2',
    name_pt:
      'Percentual de instrumentos contratuais usados ​​para venda e compra de reivindicações de atributos de energia desagregados em relação às emissões de GEE de Escopo 2'
  },
  {
    id: '43d05274-7c52-4bde-9aa1-4d514c8a668d',
    name_dcycle:
      'disclosure_of_types_of_contractual_instruments_used_for_sale_and_purchase_of_energy_bundled_with_attributes_about_energy_generation_or_for_unbundled_energy_attribute_claims',
    datapoint_id: 'E1-6_23',
    esg_category: 'environmental',
    name_en:
      'Disclosure of types of contractual instruments used for sale and purchase of energy bundled with attributes about energy generation or for unbundled energy attribute claims',
    name_es:
      'Divulgación de tipos de instrumentos contractuales utilizados para la venta y compra de energía agrupada con atributos sobre generación de energía o para reclamaciones de atributos de energía desagregados.',
    name_pt:
      'Divulgação dos tipos de instrumentos contratuais utilizados para venda e compra de energia agrupada com atributos sobre geração de energia ou para reivindicações de atributos de energia desagregados'
  },
  {
    id: '946ca024-0108-4db4-a715-bd76dd202df8',
    name_dcycle:
      'biogenic_emissions_of_co2_from_combustion_or_bio-degradation_of_biomass_not_included_in_scope_2_ghg_emissions',
    datapoint_id: 'E1-6_24',
    esg_category: 'environmental',
    name_en:
      'Biogenic emissions of CO2 from combustion or bio-degradation of biomass not included in Scope 2 GHG emissions',
    name_es:
      'Emisiones biogénicas de CO2 procedentes de la combustión o biodegradación de biomasa no incluidas en las emisiones de GEI de Alcance 2',
    name_pt:
      'Emissões biogênicas de CO2 provenientes da combustão ou biodegradação de biomassa não incluídas nas emissões de GEE do Escopo 2'
  },
  {
    id: '120e3270-8e83-4599-8972-73fb7366d276',
    name_dcycle: 'percentage_of_ghg_scope_3_calculated_using_primary_data',
    datapoint_id: 'E1-6_25',
    esg_category: 'environmental',
    name_en: 'Percentage of GHG Scope 3 calculated using primary data',
    name_es: 'Porcentaje de GEI de Alcance 3 calculado utilizando datos primarios',
    name_pt: 'Porcentagem de GEE Escopo 3 calculada usando dados primários'
  },
  {
    id: '466445d3-53c2-462a-8412-3c80963d92a8',
    name_dcycle: 'disclosure_of_why_scope_3_ghg_emissions_category_has_been_excluded',
    datapoint_id: 'E1-6_26',
    esg_category: 'environmental',
    name_en: 'Disclosure of why Scope 3 GHG emissions category has been excluded',
    name_es: 'Divulgación de por qué se ha excluido la categoría de emisiones de GEI de Alcance 3',
    name_pt:
      'Divulgação do motivo pelo qual a categoria de emissões de GEE do Escopo 3 foi excluída'
  },
  {
    id: '978bea6d-0552-4b12-881e-4a5cc69f9634',
    name_dcycle: 'list_of_scope_3_ghg_emissions_categories_included_in_inventory',
    datapoint_id: 'E1-6_27',
    esg_category: 'environmental',
    name_en: 'List of Scope 3 GHG emissions categories included in inventory',
    name_es: 'Lista de categorías de emisiones de GEI de Alcance 3 incluidas en el inventario',
    name_pt: 'Lista de categorias de emissões de GEE de Escopo 3 incluídas no inventário'
  },
  {
    id: 'b535cdcb-87ba-4b70-8f5e-b1d7cfde8955',
    name_dcycle:
      'biogenic_emissions_of_co2_from_combustion_or_bio-degradation_of_biomass_that_occur_in_value_chain_not_included_in_scope_3_ghg_emissions',
    datapoint_id: 'E1-6_28',
    esg_category: 'environmental',
    name_en:
      'Biogenic emissions of CO2 from combustion or bio-degradation of biomass that occur in value chain not included in Scope 3 GHG emissions',
    name_es:
      'Emisiones biogénicas de CO2 procedentes de la combustión o biodegradación de biomasa que se producen en la cadena de valor no incluidas en las emisiones de GEI de Alcance 3',
    name_pt:
      'Emissões biogênicas de CO2 provenientes da combustão ou biodegradação de biomassa que ocorrem na cadeia de valor não incluídas nas emissões de GEE do Escopo 3'
  },
  {
    id: 'de2846ec-dc04-43fa-849b-3ad8e87bd4db',
    name_dcycle:
      'disclosure_of_reporting_boundaries_considered_and_calculation_methods_for_estimating_scope_3_ghg_emissions',
    datapoint_id: 'E1-6_29',
    esg_category: 'environmental',
    name_en:
      'Disclosure of reporting boundaries considered and calculation methods for estimating Scope 3 GHG emissions',
    name_es:
      'Divulgación de los límites de presentación de informes considerados y métodos de cálculo para estimar las emisiones de GEI de Alcance 3',
    name_pt:
      'Divulgação dos limites de relato considerados e métodos de cálculo para estimar as emissões de GEE de Escopo 3'
  },
  {
    id: 'df6d410e-d955-4805-b68d-bf31ba9ba6c3',
    name_dcycle: 'ghg_emissions_intensity,_location-based_(total_ghg_emissions_per_net_revenue)',
    datapoint_id: 'E1-6_30',
    esg_category: 'environmental',
    name_en: 'GHG emissions intensity, location-based (total GHG emissions per net revenue)',
    name_es:
      'Intensidad de las emisiones de GEI, basada en la ubicación (emisiones totales de GEI por ingreso neto)',
    name_pt:
      'Intensidade de emissões de GEE, com base na localização (total de emissões de GEE por receita líquida)'
  },
  {
    id: '29bce266-21e2-4d16-a9d2-0eb1361e1249',
    name_dcycle: 'ghg_emissions_intensity,_market-based_(total_ghg_emissions_per_net_revenue)',
    datapoint_id: 'E1-6_31',
    esg_category: 'environmental',
    name_en: 'GHG emissions intensity, market-based (total GHG emissions per net revenue)',
    name_es:
      'Intensidad de las emisiones de GEI, basada en el mercado (emisiones totales de GEI por ingreso neto)',
    name_pt:
      'Intensidade de emissões de GEE, com base no mercado (total de emissões de GEE por receita líquida)'
  },
  {
    id: 'fb2e6d01-58fc-4bb2-bea8-bd454ecf8405',
    name_dcycle:
      'disclosure_of_reconciliation_to_financial_statements_of_net_revenue_used_for_calculation_of_ghg_emissions_intensity',
    datapoint_id: 'E1-6_32',
    esg_category: 'environmental',
    name_en:
      'Disclosure of reconciliation to financial statements of net revenue used for calculation of GHG emissions intensity',
    name_es:
      'Divulgación de la conciliación con los estados financieros de los ingresos netos utilizados para el cálculo de la intensidad de las emisiones de GEI',
    name_pt:
      'Divulgação da conciliação com as demonstrações financeiras da receita líquida utilizada para cálculo da intensidade de emissões de GEE'
  },
  {
    id: 'edde6278-f83e-49da-9b42-80cdd35530a3',
    name_dcycle: 'net_revenue',
    datapoint_id: 'E1-6_33',
    esg_category: 'environmental',
    name_en: 'Net revenue',
    name_es: 'Ingresos netos',
    name_pt: 'Receita líquida'
  },
  {
    id: '2d666f15-5614-45b0-a83f-8e27314f325d',
    name_dcycle: 'net_revenue_used_to_calculate_ghg_intensity',
    datapoint_id: 'E1-6_34',
    esg_category: 'environmental',
    name_en: 'Net revenue used to calculate GHG intensity',
    name_es: 'Ingresos netos utilizados para calcular la intensidad de GEI',
    name_pt: 'Receita líquida usada para calcular a intensidade de GEE'
  },
  {
    id: '34d644d7-7105-4132-a6dc-dbdee3e99c41',
    name_dcycle: 'net_revenue_other_than_used_to_calculate_ghg_intensity',
    datapoint_id: 'E1-6_35',
    esg_category: 'environmental',
    name_en: 'Net revenue other than used to calculate GHG intensity',
    name_es: 'Ingresos netos distintos de los utilizados para calcular la intensidad de GEI',
    name_pt: 'Receita líquida que não seja usada para calcular a intensidade de GEE'
  },
  {
    id: 'b6874fd3-9ef1-4210-8a05-729e2fdfef84',
    name_dcycle:
      'disclosure_of_ghg_removals_and_storage_resulting_from_projects_developed_in_own_operations_or_contributed_to_in_upstream_and_downstream_value_chain',
    datapoint_id: 'E1-7_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of GHG removals and storage resulting from projects developed in own operations or contributed to in upstream and downstream value chain',
    name_es:
      'Divulgación de remociones y almacenamiento de GEI resultantes de proyectos desarrollados en operaciones propias o en los que se ha contribuido en la cadena de valor upstream y downstream.',
    name_pt:
      'Divulgação de remoções e armazenamento de GEE resultantes de projetos desenvolvidos em operações próprias ou para os quais contribuiu na cadeia de valor upstream e downstream'
  },
  {
    id: 'd559e6f8-74e0-4e17-be45-3e3da040bc90',
    name_dcycle:
      'disclosure_of_ghg_emission_reductions_or_removals_from_climate_change_mitigation_projects_outside_value_chain_financed_or_to_be_financed_through_any_purchase_of_carbon_credits',
    datapoint_id: 'E1-7_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of GHG emission reductions or removals from climate change mitigation projects outside value chain financed or to be financed through any purchase of carbon credits',
    name_es:
      'Divulgación de reducciones o eliminaciones de emisiones de GEI de proyectos de mitigación del cambio climático fuera de la cadena de valor financiados o por financiar mediante cualquier compra de créditos de carbono.',
    name_pt:
      'Divulgação de reduções ou remoções de emissões de GEE provenientes de projetos de mitigação das alterações climáticas fora da cadeia de valor financiados ou a serem financiados através de qualquer compra de créditos de carbono'
  },
  {
    id: 'a0ff8e24-da6d-4c29-812b-bceeba849184',
    name_dcycle: 'removals_and_carbon_credits_are_used',
    datapoint_id: 'E1-7_03',
    esg_category: 'environmental',
    name_en: 'Removals and carbon credits are used',
    name_es: 'Se utilizan eliminaciones y créditos de carbono.',
    name_pt: 'Remoções e créditos de carbono são usados'
  },
  {
    id: '00f46ccc-f91b-45b8-b1b6-4d4df2eed4d5',
    name_dcycle:
      'ghg_removals_and_storage_activity_by_undertaking_scope_(breakdown_by_own_operations_and_value_chain)_and_by_removal_and_storage_activity',
    datapoint_id: 'E1-7_04',
    esg_category: 'environmental',
    name_en:
      'GHG Removals and storage Activity by undertaking scope (breakdown by own operations and value chain) and by removal and storage activity',
    name_es:
      'Remoción y almacenamiento de GEI Actividad por alcance de la empresa (desglose por operaciones propias y cadena de valor) y por actividad de remoción y almacenamiento',
    name_pt:
      'Atividade de remoção e armazenamento de GEE por escopo do empreendimento (decomposição por operações próprias e cadeia de valor) e por atividade de remoção e armazenamento'
  },
  {
    id: 'fd0fd9e3-bdbf-4393-aa1c-e79495a11f1f',
    name_dcycle: 'total_ghg_removals_and_storage',
    datapoint_id: 'E1-7_05',
    esg_category: 'environmental',
    name_en: 'Total GHG removals and storage',
    name_es: 'Eliminación y almacenamiento total de GEI',
    name_pt: 'Total de remoções e armazenamento de GEE'
  },
  {
    id: '2075b640-4a00-45ee-94d8-3af929237db9',
    name_dcycle: 'ghg_emissions_associated_with_removal_activity',
    datapoint_id: 'E1-7_06',
    esg_category: 'environmental',
    name_en: 'GHG emissions associated with removal activity',
    name_es: 'Emisiones de GEI asociadas con la actividad de remoción',
    name_pt: 'Emissões de GEE associadas à atividade de remoção'
  },
  {
    id: '019159dd-9821-4053-b1c4-e9d2678904e4',
    name_dcycle: 'reversals',
    datapoint_id: 'E1-7_07',
    esg_category: 'environmental',
    name_en: 'Reversals',
    name_es: 'Reversiones',
    name_pt: 'Reversões'
  },
  {
    id: '58586aa0-dc56-4476-bafc-7f6c04d01547',
    name_dcycle:
      'disclosure_of_calculation_assumptions,_methodologies_and_frameworks_applied_(ghg_removals_and_storage)',
    datapoint_id: 'E1-7_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of calculation assumptions, methodologies and frameworks applied (GHG removals and storage)',
    name_es:
      'Divulgación de supuestos de cálculo, metodologías y marcos aplicados (eliminación y almacenamiento de GEI)',
    name_pt:
      'Divulgação das premissas de cálculo, metodologias e frameworks aplicados (remoções e armazenamento de GEE)'
  },
  {
    id: '7943aa35-44b5-4c21-990f-d20e8ced3630',
    name_dcycle:
      'removal_activity_has_been_converted_into_carbon_credits_and_sold_on_to_other_parties_on_voluntary_market',
    datapoint_id: 'E1-7_09',
    esg_category: 'environmental',
    name_en:
      'Removal activity has been converted into carbon credits and sold on to other parties on voluntary market',
    name_es:
      'La actividad de eliminación se ha convertido en créditos de carbono y se ha vendido a otras partes en el mercado voluntario.',
    name_pt:
      'A atividade de remoção foi convertida em créditos de carbono e vendida a outras partes no mercado voluntário'
  },
  {
    id: 'ab6dab7c-22b6-4eaf-ace4-abfa3e6c1f72',
    name_dcycle:
      'total_amount_of_carbon_credits_outside_value_chain_that_are_verified_against_recognised_quality_standards_and_cancelled',
    datapoint_id: 'E1-7_10',
    esg_category: 'environmental',
    name_en:
      'Total amount of carbon credits outside value chain that are verified against recognised quality standards and cancelled',
    name_es:
      'Cantidad total de créditos de carbono fuera de la cadena de valor que se verifican con estándares de calidad reconocidos y se cancelan',
    name_pt:
      'Quantidade total de créditos de carbono fora da cadeia de valor que são verificados em relação a padrões de qualidade reconhecidos e cancelados'
  },
  {
    id: 'eaebeb7c-ab97-4bbd-8315-b6b7fe76684d',
    name_dcycle:
      'total_amount_of_carbon_credits_outside_value_chain_planned_to_be_cancelled_in_future',
    datapoint_id: 'E1-7_11',
    esg_category: 'environmental',
    name_en: 'Total amount of carbon credits outside value chain planned to be cancelled in future',
    name_es:
      'Cantidad total de créditos de carbono fuera de la cadena de valor que se planea cancelar en el futuro',
    name_pt:
      'Quantidade total de créditos de carbono fora da cadeia de valor prevista para ser cancelada no futuro'
  },
  {
    id: '1e42b320-e612-494b-a1d1-028920a6d734',
    name_dcycle: 'disclosure_of_extent_of_use_and_quality_criteria_used_for_carbon_credits',
    datapoint_id: 'E1-7_12',
    esg_category: 'environmental',
    name_en: 'Disclosure of extent of use and quality criteria used for carbon credits',
    name_es:
      'Divulgación del alcance del uso y los criterios de calidad utilizados para los créditos de carbono.',
    name_pt:
      'Divulgação da extensão do uso e critérios de qualidade utilizados para créditos de carbono'
  },
  {
    id: 'a75c76d9-a7d5-4a7d-a3d6-69502904fd46',
    name_dcycle: 'percentage_of_reduction_projects',
    datapoint_id: 'E1-7_13',
    esg_category: 'environmental',
    name_en: 'Percentage of reduction projects',
    name_es: 'Porcentaje de proyectos de reducción',
    name_pt: 'Porcentagem de projetos de redução'
  },
  {
    id: '50391d6d-4be3-4afc-b262-2d2b8d5cd766',
    name_dcycle: 'percentage_of_removal_projects',
    datapoint_id: 'E1-7_14',
    esg_category: 'environmental',
    name_en: 'Percentage of removal projects',
    name_es: 'Porcentaje de proyectos de remoción',
    name_pt: 'Porcentagem de projetos de remoção'
  },
  {
    id: '1618ecfc-96af-459e-91c6-7204bb4aaa16',
    name_dcycle: 'type_of_carbon_credits_from_removal_projects',
    datapoint_id: 'E1-7_15',
    esg_category: 'environmental',
    name_en: 'Type of carbon credits from removal projects',
    name_es: 'Tipo de créditos de carbono de proyectos de eliminación',
    name_pt: 'Tipo de créditos de carbono provenientes de projetos de remoção'
  },
  {
    id: '60d3108a-ec41-41f2-a280-aa784e1b20ef',
    name_dcycle: 'percentage_for_recognised_quality_standard',
    datapoint_id: 'E1-7_16',
    esg_category: 'environmental',
    name_en: 'Percentage for recognised quality standard',
    name_es: 'Porcentaje para estándar de calidad reconocido',
    name_pt: 'Porcentagem para padrão de qualidade reconhecido'
  },
  {
    id: 'b615f2eb-0b00-4ead-8251-dcc2d4b94e8a',
    name_dcycle: 'percentage_issued_from_projects_in_european_union',
    datapoint_id: 'E1-7_17',
    esg_category: 'environmental',
    name_en: 'Percentage issued from projects in European Union',
    name_es: 'Porcentaje emitido por proyectos en la Unión Europea',
    name_pt: 'Percentagem emitida por projetos na União Europeia'
  },
  {
    id: '31c39a00-dc88-49ce-8807-227c3e1dcbbc',
    name_dcycle: 'percentage_that_qualifies_as_corresponding_adjustment',
    datapoint_id: 'E1-7_18',
    esg_category: 'environmental',
    name_en: 'Percentage that qualifies as corresponding adjustment',
    name_es: 'Porcentaje que califica como ajuste correspondiente',
    name_pt: 'Porcentagem que se qualifica como ajuste correspondente'
  },
  {
    id: '8ccab522-b4d6-4363-b056-5e2f2dadfdb8',
    name_dcycle: 'date_when_carbon_credits_outside_value_chain_are_planned_to_be_cancelled',
    datapoint_id: 'E1-7_19',
    esg_category: 'environmental',
    name_en: 'Date when carbon credits outside value chain are planned to be cancelled',
    name_es:
      'Fecha en la que se prevé cancelar los créditos de carbono fuera de la cadena de valor',
    name_pt: 'Data em que se planeja cancelar os créditos de carbono fora da cadeia de valor'
  },
  {
    id: 'b142ca33-2bbb-4343-9443-906564d0140a',
    name_dcycle:
      'explanation_of_scope,_methodologies_and_frameworks_applied_and_how_residual_ghg_emissions_are_intended_to_be_neutralised',
    datapoint_id: 'E1-7_20',
    esg_category: 'environmental',
    name_en:
      'Explanation of scope, methodologies and frameworks applied and how residual GHG emissions are intended to be neutralised',
    name_es:
      'Explicación del alcance, las metodologías y los marcos aplicados y cómo se pretende neutralizar las emisiones residuales de GEI.',
    name_pt:
      'Explicação do escopo, metodologias e estruturas aplicadas e como as emissões residuais de GEE devem ser neutralizadas'
  },
  {
    id: '4874ba09-629d-461b-ac66-4b7a8abd04b2',
    name_dcycle:
      'public_claims_of_ghg_neutrality_that_involve_use_of_carbon_credits_have_been_made',
    datapoint_id: 'E1-7_21',
    esg_category: 'environmental',
    name_en: 'Public claims of GHG neutrality that involve use of carbon credits have been made',
    name_es:
      'Se han hecho declaraciones públicas de neutralidad de GEI que implican el uso de créditos de carbono.',
    name_pt:
      'Foram feitas reivindicações públicas de neutralidade em termos de GEE que envolvem o uso de créditos de carbono'
  },
  {
    id: '5312076c-4755-49df-adf2-7c18c7afb561',
    name_dcycle:
      'public_claims_of_ghg_neutrality_that_involve_use_of_carbon_credits_are_accompanied_by_ghg_emission_reduction_targets',
    datapoint_id: 'E1-7_22',
    esg_category: 'environmental',
    name_en:
      'Public claims of GHG neutrality that involve use of carbon credits are accompanied by GHG emission reduction targets',
    name_es:
      'Las afirmaciones públicas de neutralidad de GEI que implican el uso de créditos de carbono van acompañadas de objetivos de reducción de emisiones de GEI.',
    name_pt:
      'As reivindicações públicas de neutralidade de GEE que envolvem o uso de créditos de carbono são acompanhadas por metas de redução de emissões de GEE'
  },
  {
    id: '1514f68d-f9ae-4a3e-af95-e1992e6b18c7',
    name_dcycle:
      'claims_of_ghg_neutrality_and_reliance_on_carbon_credits_neither_impede_nor_reduce_achievement_of_ghg_emission_reduction_targets_or_net_zero_target',
    datapoint_id: 'E1-7_23',
    esg_category: 'environmental',
    name_en:
      'Claims of GHG neutrality and reliance on carbon credits neither impede nor reduce achievement of GHG emission reduction targets or net zero target',
    name_es:
      'Las afirmaciones de neutralidad de GEI y dependencia de los créditos de carbono no impiden ni reducen el logro de los objetivos de reducción de emisiones de GEI o del objetivo de cero emisiones netas.',
    name_pt:
      'As reivindicações de neutralidade de GEE e a dependência de créditos de carbono não impedem nem reduzem o cumprimento das metas de redução de emissões de GEE ou da meta líquida zero'
  },
  {
    id: '82813e9d-519e-4842-96b8-b6fa11b87069',
    name_dcycle:
      'explanation_of_whether_and_how_public_claims_of_ghg_neutrality_that_involve_use_of_carbon_credits_are_accompanied_by_ghg_emission_reduction_targets_and_how_claims_of_ghg_neutrality_and_reliance_on_carbon_credits_neither_impede_nor_reduce_achievement_of_ghg_emission_reduction_targets_or_net_zero_target',
    datapoint_id: 'E1-7_24',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how public claims of GHG neutrality that involve use of carbon credits are accompanied by GHG emission reduction targets and how claims of GHG neutrality and reliance on carbon credits neither impede nor reduce achievement of GHG emission reduction targets or net zero target',
    name_es:
      'Explicación de si las afirmaciones públicas de neutralidad de GEI que implican el uso de créditos de carbono van acompañadas de objetivos de reducción de emisiones de GEI y de qué manera, y cómo las afirmaciones de neutralidad de GEI y la dependencia de los créditos de carbono no impiden ni reducen el logro de los objetivos de reducción de emisiones de GEI o el objetivo de cero emisiones netas.',
    name_pt:
      'Explicação sobre se e como as reivindicações públicas de neutralidade de GEE que envolvem o uso de créditos de carbono são acompanhadas por metas de redução de emissões de GEE e como as reivindicações de neutralidade de GEE e a dependência de créditos de carbono não impedem nem reduzem o cumprimento das metas de redução de emissões de GEE ou da meta líquida zero'
  },
  {
    id: '3cb65de9-870c-4833-be34-2d83dcbcaca2',
    name_dcycle: 'explanation_of_credibility_and_integrity_of_carbon_credits_used',
    datapoint_id: 'E1-7_25',
    esg_category: 'environmental',
    name_en: 'Explanation of credibility and integrity of carbon credits used',
    name_es: 'Explicación de la credibilidad e integridad de los créditos de carbono utilizados.',
    name_pt: 'Explicação da credibilidade e integridade dos créditos de carbono utilizados'
  },
  {
    id: '64aa384c-cab5-4cf9-b9bb-0606a741f880',
    name_dcycle: 'carbon_pricing_scheme_by_type',
    datapoint_id: 'E1-8_01',
    esg_category: 'environmental',
    name_en: 'Carbon pricing scheme by type',
    name_es: 'Esquema de fijación de precios del carbono por tipo',
    name_pt: 'Esquema de precificação de carbono por tipo'
  },
  {
    id: 'c65cfec4-9499-443e-aa41-455d9b63beeb',
    name_dcycle: 'type_of_internal_carbon_pricing_scheme',
    datapoint_id: 'E1-8_02',
    esg_category: 'environmental',
    name_en: 'Type of internal carbon pricing scheme',
    name_es: 'Tipo de sistema interno de fijación de precios del carbono',
    name_pt: 'Tipo de esquema interno de precificação de carbono'
  },
  {
    id: '019e7d84-f24d-4ed2-a5c3-68c586ff4542',
    name_dcycle: 'description_of_specific_scope_of_application_of_carbon_pricing_scheme',
    datapoint_id: 'E1-8_03',
    esg_category: 'environmental',
    name_en: 'Description of specific scope of application of carbon pricing scheme',
    name_es:
      'Descripción del ámbito de aplicación específico del sistema de fijación de precios del carbono',
    name_pt: 'Descrição do âmbito específico de aplicação do regime de fixação de preços do carbono'
  },
  {
    id: '6a028878-5e3e-4fec-8e37-e644f58850f1',
    name_dcycle: 'carbon_price_applied_for_each_metric_tonne_of_greenhouse_gas_emission',
    datapoint_id: 'E1-8_04',
    esg_category: 'environmental',
    name_en: 'Carbon price applied for each metric tonne of greenhouse gas emission',
    name_es:
      'Precio del carbono aplicado por cada tonelada métrica de emisión de gases de efecto invernadero',
    name_pt:
      'Preço do carbono aplicado para cada tonelada métrica de emissão de gases de efeito estufa'
  },
  {
    id: '5f5ae0b8-07ef-44f6-b8a5-4b0e69e58bbc',
    name_dcycle: 'description_of_critical_assumptions_made_to_determine_carbon_price_applied',
    datapoint_id: 'E1-8_05',
    esg_category: 'environmental',
    name_en: 'Description of critical assumptions made to determine carbon price applied',
    name_es:
      'Descripción de los supuestos críticos hechos para determinar el precio del carbono aplicado',
    name_pt: 'Descrição das premissas críticas feitas para determinar o preço do carbono aplicado'
  },
  {
    id: 'c5e004d0-43ec-4eac-ab18-4b4d72ca93e0',
    name_dcycle:
      'percentage_of_gross_scope_1_greenhouse_gas_emissions_covered_by_internal_carbon_pricing_scheme',
    datapoint_id: 'E1-8_06',
    esg_category: 'environmental',
    name_en:
      'Percentage of gross Scope 1 greenhouse gas emissions covered by internal carbon pricing scheme',
    name_es:
      'Porcentaje de emisiones brutas de gases de efecto invernadero de Alcance 1 cubiertas por el sistema interno de fijación de precios del carbono',
    name_pt:
      'Percentagem das emissões brutas de gases com efeito de estufa de Âmbito 1 cobertas pelo regime interno de precificação do carbono'
  },
  {
    id: 'a734564c-ae3c-4e7d-8de3-a90e0f07d677',
    name_dcycle:
      'percentage_of_gross_scope_2_greenhouse_gas_emissions_covered_by_internal_carbon_pricing_scheme',
    datapoint_id: 'E1-8_07',
    esg_category: 'environmental',
    name_en:
      'Percentage of gross Scope 2 greenhouse gas emissions covered by internal carbon pricing scheme',
    name_es:
      'Porcentaje de emisiones brutas de gases de efecto invernadero de alcance 2 cubiertas por el sistema interno de fijación de precios del carbono',
    name_pt:
      'Percentagem das emissões brutas de gases com efeito de estufa de Âmbito 2 cobertas pelo regime interno de precificação do carbono'
  },
  {
    id: '0df393bc-88ab-4c79-b141-49f355510114',
    name_dcycle:
      'percentage_of_gross_scope_3_greenhouse_gas_emissions_covered_by_internal_carbon_pricing_scheme',
    datapoint_id: 'E1-8_08',
    esg_category: 'environmental',
    name_en:
      'Percentage of gross Scope 3 greenhouse gas emissions covered by internal carbon pricing scheme',
    name_es:
      'Porcentaje de emisiones brutas de gases de efecto invernadero de Alcance 3 cubiertas por el sistema interno de fijación de precios del carbono',
    name_pt:
      'Percentagem das emissões brutas de gases com efeito de estufa de Âmbito 3 cobertas pelo regime interno de precificação do carbono'
  },
  {
    id: '124f003f-9133-4d0c-bc15-4cd7a923c9d9',
    name_dcycle:
      'disclosure_of_whether_and_how_carbon_price_used_in_internal_carbon_pricing_scheme_is_consistent_with_carbon_price_used_in_financial_statements',
    datapoint_id: 'E1-8_09',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how carbon price used in internal carbon pricing scheme is consistent with carbon price used in financial statements',
    name_es:
      'Divulgación de si el precio del carbono utilizado en el sistema interno de fijación de precios del carbono es coherente con el precio del carbono utilizado en los estados financieros y de qué manera.',
    name_pt:
      'Divulgação sobre se e como o preço do carbono utilizado no esquema interno de precificação do carbono é consistente com o preço do carbono utilizado nas demonstrações financeiras'
  },
  {
    id: '7c9d603e-50da-4806-b7d2-7a1a2713205e',
    name_dcycle:
      'assets_at_material_physical_risk_before_considering_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_01',
    esg_category: 'environmental',
    name_en:
      'Assets at material physical risk before considering climate change adaptation actions',
    name_es:
      'Activos en riesgo físico material antes de considerar acciones de adaptación al cambio climático',
    name_pt:
      'Ativos em risco físico material antes de considerar ações de adaptação às alterações climáticas'
  },
  {
    id: 'c4a85fe8-9d62-4ab2-9674-799d521cc063',
    name_dcycle:
      'assets_at_acute_material_physical_risk_before_considering_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_02',
    esg_category: 'environmental',
    name_en:
      'Assets at acute material physical risk before considering climate change adaptation actions',
    name_es:
      'Activos en riesgo físico material agudo antes de considerar acciones de adaptación al cambio climático',
    name_pt:
      'Ativos em risco físico material agudo antes de considerar ações de adaptação às alterações climáticas'
  },
  {
    id: '6f6754d1-c1a3-4999-8efe-727081ee5d42',
    name_dcycle:
      'assets_at_chronic_material_physical_risk_before_considering_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_03',
    esg_category: 'environmental',
    name_en:
      'Assets at chronic material physical risk before considering climate change adaptation actions',
    name_es:
      'Activos en riesgo físico material crónico antes de considerar acciones de adaptación al cambio climático',
    name_pt:
      'Ativos em risco físico material crónico antes de considerar ações de adaptação às alterações climáticas'
  },
  {
    id: '73ebcd8a-0191-4ea0-bcb1-3103a4f536fa',
    name_dcycle:
      'percentage_of_assets_at_material_physical_risk_before_considering_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_04',
    esg_category: 'environmental',
    name_en:
      'Percentage of assets at material physical risk before considering climate change adaptation actions',
    name_es:
      'Porcentaje de activos en riesgo físico material antes de considerar acciones de adaptación al cambio climático',
    name_pt:
      'Percentagem de ativos em risco físico material antes de serem consideradas ações de adaptação às alterações climáticas'
  },
  {
    id: '3441f01b-0833-4d18-ac82-68398e416f79',
    name_dcycle: 'disclosure_of_location_of_significant_assets_at_material_physical_risk',
    datapoint_id: 'E1-9_05',
    esg_category: 'environmental',
    name_en: 'Disclosure of location of significant assets at material physical risk',
    name_es: 'Divulgación de la ubicación de activos importantes con riesgo físico importante',
    name_pt: 'Divulgação da localização de ativos significativos com risco físico material'
  },
  {
    id: 'd60f10a9-85d9-4cf4-8b35-c1d09f6a2503',
    name_dcycle:
      'disclosure_of_location_of_its_significant_assets_at_material_physical_risk_(disaggregated_by_nuts_codes)',
    datapoint_id: 'E1-9_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of location of its significant assets at material physical risk (disaggregated by NUTS codes)',
    name_es:
      'Divulgación de la ubicación de sus activos importantes con riesgo físico importante (desagregados por códigos NUTS)',
    name_pt:
      'Divulgação da localização dos seus ativos significativos em risco físico material (desagregados por códigos NUTS)'
  },
  {
    id: '46cf52b7-4b0c-49db-95b3-8f38a2c2545e',
    name_dcycle:
      'percentage_of_assets_at_material_physical_risk_addressed_by_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_07',
    esg_category: 'environmental',
    name_en:
      'Percentage of assets at material physical risk addressed by climate change adaptation actions',
    name_es:
      'Porcentaje de activos en riesgo físico material abordados por acciones de adaptación al cambio climático',
    name_pt:
      'Percentagem de ativos em risco físico material abordados por ações de adaptação às alterações climáticas'
  },
  {
    id: 'ae8ab146-bfee-4544-ad05-b505bcfa4933',
    name_dcycle: 'net_revenue_from_business_activities_at_material_physical_risk',
    datapoint_id: 'E1-9_08',
    esg_category: 'environmental',
    name_en: 'Net revenue from business activities at material physical risk',
    name_es: 'Ingresos netos de actividades comerciales con riesgo físico material',
    name_pt: 'Receita líquida de atividades comerciais com risco físico material'
  },
  {
    id: '99103815-6871-4aa1-84c3-ed98653b5193',
    name_dcycle: 'percentage_of_net_revenue_from_business_activities_at_material_physical_risk',
    datapoint_id: 'E1-9_09',
    esg_category: 'environmental',
    name_en: 'Percentage of net revenue from business activities at material physical risk',
    name_es: 'Porcentaje de ingresos netos de actividades comerciales con riesgo físico material',
    name_pt: 'Percentagem da receita líquida de atividades empresariais com risco físico material'
  },
  {
    id: 'b42dc036-5ec9-49ab-83fa-d9b31fd5337d',
    name_dcycle:
      'disclosure_of_whether_and_how_anticipated_financial_effects_for_assets_and_business_activities_at_material_physical_risk_have_been_assessed',
    datapoint_id: 'E1-9_10',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how anticipated financial effects for assets and business activities at material physical risk have been assessed',
    name_es:
      'Divulgación de si se han evaluado y cómo se han evaluado los efectos financieros previstos para los activos y actividades comerciales con riesgo físico importante.',
    name_pt:
      'Divulgação sobre se e como os efeitos financeiros previstos para ativos e atividades comerciais com risco físico material foram avaliados'
  },
  {
    id: '906a27e5-3c6d-4b15-90b2-66e809922929',
    name_dcycle:
      'disclosure_of_whether_and_how_assessment_of_assets_and_business_activities_considered_to_be_at_material_physical_risk_relies_on_or_is_part_of_process_to_determine_material_physical_risk_and_to_determine_climate_scenarios',
    datapoint_id: 'E1-9_11',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how assessment of assets and business activities considered to be at material physical risk relies on or is part of process to determine material physical risk and to determine climate scenarios',
    name_es:
      'Divulgación de si y cómo la evaluación de activos y actividades comerciales consideradas en riesgo físico importante depende o es parte del proceso para determinar el riesgo físico importante y para determinar escenarios climáticos.',
    name_pt:
      'Divulgação sobre se e como a avaliação de ativos e atividades comerciais consideradas de risco físico material depende ou faz parte do processo para determinar o risco físico material e para determinar cenários climáticos'
  },
  {
    id: '38e39ef8-20ab-4d9d-b166-2fbc518f9f57',
    name_dcycle:
      'disclosure_of_risk_factors_for_net_revenue_from_business_activities_at_material_physical_risk',
    datapoint_id: 'E1-9_12',
    esg_category: 'environmental',
    name_en:
      'Disclosure of risk factors for net revenue from business activities at material physical risk',
    name_es:
      'Divulgación de factores de riesgo para ingresos netos de actividades comerciales con riesgo físico material',
    name_pt:
      'Divulgação de fatores de risco para receita líquida de atividades empresariais com risco físico material'
  },
  {
    id: 'c0a33782-4206-4718-916b-dc74097ca646',
    name_dcycle:
      'disclosure_of_magnitude_of_anticipated_financial_effects_in_terms_of_margin_erosion_for_business_activities_at_material_physical_risk',
    datapoint_id: 'E1-9_13',
    esg_category: 'environmental',
    name_en:
      'Disclosure of magnitude of anticipated financial effects in terms of margin erosion for business activities at material physical risk',
    name_es:
      'Divulgación de la magnitud de los efectos financieros previstos en términos de erosión de márgenes para actividades comerciales con riesgo físico importante',
    name_pt:
      'Divulgação da magnitude dos efeitos financeiros previstos em termos de erosão de margens para atividades comerciais com risco físico material'
  },
  {
    id: '2671a73c-dff1-49e1-ac5a-a3ce7b81676b',
    name_dcycle: 'assets_at_material_transition_risk_before_considering_climate_mitigation_actions',
    datapoint_id: 'E1-9_14',
    esg_category: 'environmental',
    name_en: 'Assets at material transition risk before considering climate mitigation actions',
    name_es:
      'Activos en riesgo de transición material antes de considerar acciones de mitigación climática',
    name_pt:
      'Ativos em risco de transição material antes de considerar ações de mitigação climática'
  },
  {
    id: '7ff0d7ec-c2b1-4e1e-9544-7fdad7675ad9',
    name_dcycle:
      'percentage_of_assets_at_material_transition_risk_before_considering_climate_mitigation_actions',
    datapoint_id: 'E1-9_15',
    esg_category: 'environmental',
    name_en:
      'Percentage of assets at material transition risk before considering climate mitigation actions',
    name_es:
      'Porcentaje de activos en riesgo de transición material antes de considerar acciones de mitigación climática',
    name_pt:
      'Percentagem de ativos em risco de transição material antes de considerar ações de mitigação climática'
  },
  {
    id: 'b415603d-6f09-4973-904d-eeaa53ea55de',
    name_dcycle:
      'percentage_of_assets_at_material_transition_risk_addressed_by_climate_change_mitigation_actions',
    datapoint_id: 'E1-9_16',
    esg_category: 'environmental',
    name_en:
      'Percentage of assets at material transition risk addressed by climate change mitigation actions',
    name_es:
      'Porcentaje de activos con riesgo de transición material abordados por acciones de mitigación del cambio climático',
    name_pt:
      'Percentagem de ativos em risco material de transição abordados por ações de mitigação das alterações climáticas'
  },
  {
    id: '487485f3-12ed-49aa-b292-af5043e1725a',
    name_dcycle: 'total_carrying_amount_of_real_estate_assets_by_energy_efficiency_classes',
    datapoint_id: 'E1-9_17',
    esg_category: 'environmental',
    name_en: 'Total carrying amount of real estate assets by energy efficiency classes',
    name_es:
      'Importe en libros total de los activos inmobiliarios por clases de eficiencia energética',
    name_pt: 'Valor contábil total de ativos imobiliários por classes de eficiência energética'
  },
  {
    id: '25c613cb-59d7-4694-999f-a01ac12a7741',
    name_dcycle:
      'disclosure_of_whether_and_how_potential_effects_on_future_financial_performance_and_position_for_assets_and_business_activities_at_material_transition_risk_have_been_assessed',
    datapoint_id: 'E1-9_18',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how potential effects on future financial performance and position for assets and business activities at material transition risk have been assessed',
    name_es:
      'Divulgación de si se han evaluado y cómo se han evaluado los efectos potenciales sobre el desempeño financiero futuro y la posición de los activos y actividades comerciales con riesgo de transición importante.',
    name_pt:
      'Divulgação sobre se e como foram avaliados os potenciais efeitos sobre o desempenho financeiro futuro e a posição dos ativos e atividades empresariais com risco de transição material'
  },
  {
    id: 'ceab2797-d9fd-4618-8adb-f82deb69d63c',
    name_dcycle:
      'disclosure_of_whether_and_how_assessment_of_assets_and_business_activities_considered_to_be_at_material_transition_risk_relies_on_or_is_part_of_process_to_determine_material_transition_risks_and_to_determine_scenarios',
    datapoint_id: 'E1-9_19',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how assessment of assets and business activities considered to be at material transition risk relies on or is part of process to determine material transition risks and to determine scenarios',
    name_es:
      'Divulgación de si, y cómo, la evaluación de activos y actividades comerciales consideradas con riesgo de transición importante depende o es parte del proceso para determinar riesgos de transición importantes y para determinar escenarios.',
    name_pt:
      'Divulgação sobre se e como a avaliação de ativos e atividades de negócios consideradas como estando em risco de transição material depende ou faz parte do processo para determinar riscos de transição materiais e para determinar cenários'
  },
  {
    id: 'ad00a667-34ed-4082-9711-c64eaf02703d',
    name_dcycle: 'estimated_amount_of_potentially_stranded_assets',
    datapoint_id: 'E1-9_20',
    esg_category: 'environmental',
    name_en: 'Estimated amount of potentially stranded assets',
    name_es: 'Cantidad estimada de activos potencialmente varados',
    name_pt: 'Quantidade estimada de ativos potencialmente irrecuperáveis'
  },
  {
    id: '5ab957cd-804e-40fd-85f0-ffd37807d685',
    name_dcycle:
      'percentage_of_estimated_share_of_potentially_stranded_assets_of_total_assets_at_material_transition_risk',
    datapoint_id: 'E1-9_21',
    esg_category: 'environmental',
    name_en:
      'Percentage of estimated share of potentially stranded assets of total assets at material transition risk',
    name_es:
      'Porcentaje de la proporción estimada de activos potencialmente varados del total de activos en riesgo de transición material',
    name_pt:
      'Percentagem da parcela estimada de ativos potencialmente irrecuperáveis ​​no total de ativos com risco de transição material'
  },
  {
    id: '94380d07-54d7-4ef1-b9b5-f627b8c4d36f',
    name_dcycle:
      'total_carrying_amount_of_real_estate_assets_for_which_energy_consumption_is_based_on_internal_estimates',
    datapoint_id: 'E1-9_22',
    esg_category: 'environmental',
    name_en:
      'Total carrying amount of real estate assets for which energy consumption is based on internal estimates',
    name_es:
      'Importe en libros total de los activos inmobiliarios cuyo consumo de energía se basa en estimaciones internas',
    name_pt:
      'Valor contábil total de ativos imobiliários cujo consumo de energia é baseado em estimativas internas'
  },
  {
    id: 'd3774f19-8b0a-409e-88bb-058e605b8cc7',
    name_dcycle:
      'liabilities_from_material_transition_risks_that_may_have_to_be_recognised_in_financial_statements',
    datapoint_id: 'E1-9_23',
    esg_category: 'environmental',
    name_en:
      'Liabilities from material transition risks that may have to be recognised in financial statements',
    name_es:
      'Pasivos por riesgos de transición importantes que pueden tener que reconocerse en los estados financieros',
    name_pt:
      'Passivos decorrentes de riscos de transição materiais que podem ter de ser reconhecidos nas demonstrações financeiras'
  },
  {
    id: '39a2ac3a-5479-42a6-b8e3-ed206e3548ae',
    name_dcycle:
      'number_of_scope_1_ghg_emission_allowances_within_regulated_emission_trading_schemes',
    datapoint_id: 'E1-9_24',
    esg_category: 'environmental',
    name_en: 'Number of Scope 1 GHG emission allowances within regulated emission trading schemes',
    name_es:
      'Número de derechos de emisión de GEI de alcance 1 dentro de esquemas regulados de comercio de derechos de emisión',
    name_pt:
      'Número de licenças de emissão de GEE de Escopo 1 dentro de esquemas regulamentados de comércio de emissões'
  },
  {
    id: '1287f733-c0ff-4b2c-b209-a14a83342756',
    name_dcycle:
      'number_of_emission_allowances_stored_(from_previous_allowances)_at_beginning_of_reporting_period',
    datapoint_id: 'E1-9_25',
    esg_category: 'environmental',
    name_en:
      'Number of emission allowances stored (from previous allowances) at beginning of reporting period',
    name_es:
      'Número de derechos de emisión almacenados (de derechos anteriores) al comienzo del período del informe',
    name_pt:
      'Número de licenças de emissão armazenadas (de licenças anteriores) no início do período de relatório'
  },
  {
    id: '45667520-48bf-4ac0-8383-89074115bec7',
    name_dcycle:
      'potential_future_liabilities,_based_on_existing_contractual_agreements,_associated_with_carbon_credits_planned_to_be_cancelled_in_near_future',
    datapoint_id: 'E1-9_26',
    esg_category: 'environmental',
    name_en:
      'Potential future liabilities, based on existing contractual agreements, associated with carbon credits planned to be cancelled in near future',
    name_es:
      'Posibles obligaciones futuras, basadas en acuerdos contractuales existentes, asociadas con créditos de carbono que se planea cancelar en un futuro próximo',
    name_pt:
      'Potenciais responsabilidades futuras, com base em acordos contratuais existentes, associadas a créditos de carbono planeados para serem cancelados num futuro próximo'
  },
  {
    id: 'e37075f5-e9b0-4be8-bf0d-bf3b8ee8d7de',
    name_dcycle: 'monetised_gross_scope_1_and_2_ghg_emissions',
    datapoint_id: 'E1-9_27',
    esg_category: 'environmental',
    name_en: 'Monetised gross Scope 1 and 2 GHG emissions',
    name_es: 'Emisiones brutas de GEI de alcance 1 y 2 monetizadas',
    name_pt: 'Emissões brutas monetizadas de GEE de Escopo 1 e 2'
  },
  {
    id: '4865675b-8993-4a97-8df0-e87cd7b0e56e',
    name_dcycle: 'monetised_total_ghg_emissions',
    datapoint_id: 'E1-9_28',
    esg_category: 'environmental',
    name_en: 'Monetised total GHG emissions',
    name_es: 'Emisiones totales de GEI monetizadas',
    name_pt: 'Emissões totais de GEE monetizadas'
  },
  {
    id: 'be9d1c39-a66e-401f-9593-72f9d1aa1b00',
    name_dcycle: 'net_revenue_from_business_activities_at_material_transition_risk',
    datapoint_id: 'E1-9_29',
    esg_category: 'environmental',
    name_en: 'Net revenue from business activities at material transition risk',
    name_es: 'Ingresos netos de actividades comerciales con riesgo de transición material',
    name_pt: 'Receita líquida de atividades comerciais com risco de transição material'
  },
  {
    id: 'f4daa136-affe-479b-ace7-4636314f0d8c',
    name_dcycle: 'net_revenue_from_customers_operating_in_coal-related_activities',
    datapoint_id: 'E1-9_30',
    esg_category: 'environmental',
    name_en: 'Net revenue from customers operating in coal-related activities',
    name_es: 'Ingresos netos de clientes que operan en actividades relacionadas con el carbón',
    name_pt: 'Receita líquida de clientes que operam em atividades relacionadas ao carvão'
  },
  {
    id: '4ff4ce54-11c9-4c7b-a3c0-6fcb7970b7f4',
    name_dcycle: 'net_revenue_from_customers_operating_in_oil-related_activities',
    datapoint_id: 'E1-9_31',
    esg_category: 'environmental',
    name_en: 'Net revenue from customers operating in oil-related activities',
    name_es: 'Ingresos netos de clientes que operan en actividades relacionadas con el petróleo',
    name_pt: 'Receita líquida de clientes que operam em atividades relacionadas ao petróleo'
  },
  {
    id: 'a8154c7c-0316-4040-96d2-2fa4535df6f8',
    name_dcycle: 'net_revenue_from_customers_operating_in_gas-related_activities',
    datapoint_id: 'E1-9_32',
    esg_category: 'environmental',
    name_en: 'Net revenue from customers operating in gas-related activities',
    name_es: 'Ingresos netos de clientes que operan en actividades relacionadas con el gas',
    name_pt: 'Receita líquida de clientes que operam em atividades relacionadas com o gás'
  },
  {
    id: '020efcc6-11ee-4bff-8de7-4af5b584826a',
    name_dcycle: 'percentage_of_net_revenue_from_customers_operating_in_coal-related_activities',
    datapoint_id: 'E1-9_33',
    esg_category: 'environmental',
    name_en: 'Percentage of net revenue from customers operating in coal-related activities',
    name_es:
      'Porcentaje de ingresos netos de clientes que operan en actividades relacionadas con el carbón',
    name_pt:
      'Percentagem da receita líquida de clientes que operam em atividades relacionadas com o carvão'
  },
  {
    id: 'b7ccc611-b7a8-427b-905b-c2fbb97097b3',
    name_dcycle: 'percentage_of_net_revenue_from_customers_operating_in_oil-related_activities',
    datapoint_id: 'E1-9_34',
    esg_category: 'environmental',
    name_en: 'Percentage of net revenue from customers operating in oil-related activities',
    name_es:
      'Porcentaje de ingresos netos de clientes que operan en actividades relacionadas con el petróleo',
    name_pt:
      'Percentual da receita líquida de clientes que operam em atividades relacionadas ao petróleo'
  },
  {
    id: '0f5b080d-4566-49e0-8e46-7d4e81499a41',
    name_dcycle: 'percentage_of_net_revenue_from_customers_operating_in_gas-related_activities',
    datapoint_id: 'E1-9_35',
    esg_category: 'environmental',
    name_en: 'Percentage of net revenue from customers operating in gas-related activities',
    name_es:
      'Porcentaje de ingresos netos de clientes que operan en actividades relacionadas con el gas',
    name_pt:
      'Percentagem da receita líquida de clientes que operam em atividades relacionadas com o gás'
  },
  {
    id: '1a421d1a-02cf-4960-8fd3-167b06af5a75',
    name_dcycle: 'percentage_of_net_revenue_from_business_activities_at_material_transition_risk',
    datapoint_id: 'E1-9_36',
    esg_category: 'environmental',
    name_en: 'Percentage of net revenue from business activities at material transition risk',
    name_es:
      'Porcentaje de ingresos netos de actividades comerciales con riesgo de transición material',
    name_pt:
      'Percentagem da receita líquida de atividades empresariais com risco de transição material'
  },
  {
    id: 'a4718375-c486-4efa-b1e3-8cb27e2d4c9d',
    name_dcycle:
      'disclosure_of_risk_factors_for_net_revenue_from_business_activities_at_material_transition_risk',
    datapoint_id: 'E1-9_37',
    esg_category: 'environmental',
    name_en:
      'Disclosure of risk factors for net revenue from business activities at material transition risk',
    name_es:
      'Divulgación de factores de riesgo para ingresos netos de actividades comerciales con riesgo de transición material',
    name_pt:
      'Divulgação de fatores de risco para receita líquida de atividades empresariais com risco de transição material'
  },
  {
    id: '8e64a39e-ca34-4c96-a999-348b37ab8326',
    name_dcycle:
      'disclosure_of_anticipated_financial_effects_in_terms_of_margin_erosion_for_business_activities_at_material_transition_risk',
    datapoint_id: 'E1-9_38',
    esg_category: 'environmental',
    name_en:
      'Disclosure of anticipated financial effects in terms of margin erosion for business activities at material transition risk',
    name_es:
      'Divulgación de los efectos financieros previstos en términos de erosión de márgenes para actividades comerciales con riesgo de transición importante',
    name_pt:
      'Divulgação dos efeitos financeiros previstos em termos de erosão de margens para atividades comerciais com risco de transição material'
  },
  {
    id: 'af073f84-eeb6-4a2c-a4ca-4ce0059856d3',
    name_dcycle:
      'disclosure_of_reconciliations_with_financial_statements_of_significant_amounts_of_assets_and_net_revenue_at_material_physical_risk',
    datapoint_id: 'E1-9_39',
    esg_category: 'environmental',
    name_en:
      'Disclosure of reconciliations with financial statements of significant amounts of assets and net revenue at material physical risk',
    name_es:
      'Información a revelar de conciliaciones con estados financieros de cantidades significativas de activos e ingresos netos con riesgo físico material',
    name_pt:
      'Divulgação de reconciliações com demonstrações financeiras de valores significativos de ativos e receitas líquidas com risco físico relevante'
  },
  {
    id: '906f4a62-8214-4631-abba-930bb341ca1b',
    name_dcycle:
      'disclosure_of_reconciliations_with_financial_statements_of_significant_amounts_of_assets,_liabilities_and_net_revenue_at_material_transition_risk',
    datapoint_id: 'E1-9_40',
    esg_category: 'environmental',
    name_en:
      'Disclosure of reconciliations with financial statements of significant amounts of assets, liabilities and net revenue at material transition risk',
    name_es:
      'Información a revelar de conciliaciones con estados financieros de cantidades significativas de activos, pasivos e ingresos netos con riesgo de transición importante',
    name_pt:
      'Divulgação de reconciliações com demonstrações financeiras de montantes significativos de ativos, passivos e receitas líquidas com risco de transição material'
  },
  {
    id: '275ba9df-61ef-45be-8879-5f50aa61b0d9',
    name_dcycle: 'expected_cost_savings_from_climate_change_mitigation_actions',
    datapoint_id: 'E1-9_41',
    esg_category: 'environmental',
    name_en: 'Expected cost savings from climate change mitigation actions',
    name_es: 'Ahorros de costos esperados de las acciones de mitigación del cambio climático',
    name_pt:
      'Esperadas poupanças de custos decorrentes de ações de mitigação das alterações climáticas'
  },
  {
    id: 'd8c9694c-f10b-45a9-82e8-b94a17d6b6b4',
    name_dcycle: 'expected_cost_savings_from_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_42',
    esg_category: 'environmental',
    name_en: 'Expected cost savings from climate change adaptation actions',
    name_es: 'Ahorros de costos esperados de las acciones de adaptación al cambio climático',
    name_pt:
      'Esperadas poupanças de custos decorrentes de ações de adaptação às alterações climáticas'
  },
  {
    id: 'c612e1d0-5478-47ad-afe4-1a623f4f1264',
    name_dcycle:
      'potential_market_size_of_low-carbon_products_and_services_or_adaptation_solutions_to_which_undertaking_has_or_may_have_access',
    datapoint_id: 'E1-9_43',
    esg_category: 'environmental',
    name_en:
      'Potential market size of low-carbon products and services or adaptation solutions to which undertaking has or may have access',
    name_es:
      'Tamaño potencial del mercado de productos y servicios con bajas emisiones de carbono o soluciones de adaptación a los que la empresa tiene o puede tener acceso',
    name_pt:
      'Dimensão potencial do mercado de produtos e serviços hipocarbónicos ou soluções de adaptação a que a empresa tem ou pode ter acesso'
  },
  {
    id: 'e165efbf-81d0-4e7f-b73c-6d1e11cd1ac8',
    name_dcycle:
      'expected_changes_to_net_revenue_from_low-carbon_products_and_services_or_adaptation_solutions_to_which_undertaking_has_or_may_have_access',
    datapoint_id: 'E1-9_44',
    esg_category: 'environmental',
    name_en:
      'Expected changes to net revenue from low-carbon products and services or adaptation solutions to which undertaking has or may have access',
    name_es:
      'Cambios esperados en los ingresos netos provenientes de productos y servicios con bajas emisiones de carbono o soluciones de adaptación a los que la empresa tiene o puede tener acceso',
    name_pt:
      'Mudanças esperadas na receita líquida de produtos e serviços hipocarbónicos ou soluções de adaptação a que a empresa tem ou pode ter acesso'
  },
  {
    id: '3204c4f4-0470-4c4b-9058-606b0340acfb',
    name_dcycle:
      'information_about_the_process_to_identify_actual_and_potential_pollution-related_impacts,_risks_and_opportuntities',
    datapoint_id: 'E2.IRO-1_01',
    esg_category: 'environmental',
    name_en:
      'Information about the process to identify actual and potential pollution-related impacts, risks and opportuntities',
    name_es:
      'Información sobre el proceso para identificar impactos, riesgos y oportunidades reales y potenciales relacionados con la contaminación.',
    name_pt:
      'Informações sobre o processo para identificar impactos, riscos e oportunidades reais e potenciais relacionados à poluição'
  },
  {
    id: '40aa5aa4-764f-41bb-ba0d-9167e5e9c143',
    name_dcycle: 'disclosure_of_whether_and_how_consultations_have_been_conducted_(pollution)',
    datapoint_id: 'E2.IRO-1_02',
    esg_category: 'environmental',
    name_en: 'Disclosure of whether and how consultations have been conducted (pollution)',
    name_es: 'Divulgación de si se han realizado consultas y cómo (contaminación)',
    name_pt: 'Divulgação de se e como as consultas foram realizadas (poluição)'
  },
  {
    id: '0a5f42d2-e9d4-4cba-9c05-ea9eb10a1934',
    name_dcycle: 'disclosure_of_results_of_materiality_assessment_(pollution)',
    datapoint_id: 'E2.IRO-1_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of results of materiality assessment (pollution)',
    name_es: 'Divulgación de resultados de la evaluación de materialidad (contaminación)',
    name_pt: 'Divulgação dos resultados da avaliação de materialidade (poluição)'
  },
  {
    id: '6bf81063-fe89-4aae-9294-1ee36cd9af5a',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_addresses_mitigating_negative_impacts_related_to_pollution_of_air,_water_and_soil',
    datapoint_id: 'E2-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy addresses mitigating negative impacts related to pollution of air, water and soil',
    name_es:
      'Divulgación de si la política aborda la mitigación de los impactos negativos relacionados con la contaminación del aire, el agua y el suelo y cómo lo hace.',
    name_pt:
      'Divulgação sobre se e como a política aborda a mitigação dos impactos negativos relacionados com a poluição do ar, da água e do solo'
  },
  {
    id: '1e263a7d-f2be-40a2-98bc-512a252defee',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_addresses_substituting_and_minimising_use_of_substances_of_concern_and_phasing_out_substances_of_very_high_concern',
    datapoint_id: 'E2-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy addresses substituting and minimising use of substances of concern and phasing out substances of very high concern',
    name_es:
      'Divulgación de si la política aborda la sustitución y minimización del uso de sustancias preocupantes y la eliminación gradual de sustancias extremadamente preocupantes, y de qué manera.',
    name_pt:
      'Divulgação de se e como a política aborda a substituição e minimização do uso de substâncias que suscitam preocupação e a eliminação progressiva de substâncias que suscitam elevada preocupação'
  },
  {
    id: '3d5505d7-3790-44e5-aa3d-d2c6a948bd97',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_addresses_avoiding_incidents_and_emergency_situations,_and_if_and_when_they_occur,_controlling_and_limiting_their_impact_on_people_and_environment',
    datapoint_id: 'E2-1_03',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy addresses avoiding incidents and emergency situations, and if and when they occur, controlling and limiting their impact on people and environment',
    name_es:
      'Divulgación de si y cómo la política aborda la prevención de incidentes y situaciones de emergencia, y si ocurren y cuándo ocurren, controlando y limitando su impacto en las personas y el medio ambiente.',
    name_pt:
      'Divulgação de se e como a política aborda a prevenção de incidentes e situações de emergência, e se e quando ocorrem, controlando e limitando o seu impacto nas pessoas e no ambiente'
  },
  {
    id: '138e3ca9-dc73-4736-9c97-b6d214919e39',
    name_dcycle:
      'disclosure_of_contextual_information_on_relations_between_policies_implemented_and_how_policies_contribute_to_eu_action_plan_towards_zero_pollution_for_air,_water_and_soil',
    datapoint_id: 'E2-1_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of contextual information on relations between policies implemented and how policies contribute to EU Action Plan Towards Zero Pollution for Air, Water and Soil',
    name_es:
      'Divulgación de información contextual sobre las relaciones entre las políticas implementadas y cómo las políticas contribuyen al Plan de Acción de la UE hacia la contaminación cero del aire, el agua y el suelo.',
    name_pt:
      'Divulgação de informações contextuais sobre as relações entre as políticas implementadas e como as políticas contribuem para o Plano de Acção da UE para a Poluição Zero do Ar, da Água e do Solo'
  },
  {
    id: '8e4fa552-463a-49d4-9291-d61cb8d5ef4f',
    name_dcycle: 'layer_in_mitigation_hierarchy_to_which_action_can_be_allocated_to_(pollution)',
    datapoint_id: 'E2-2_01',
    esg_category: 'environmental',
    name_en: 'Layer in mitigation hierarchy to which action can be allocated to (pollution)',
    name_es:
      'Capa en la jerarquía de mitigación a la que se pueden asignar acciones (contaminación)',
    name_pt: 'Camada na hierarquia de mitigação à qual a ação pode ser alocada (poluição)'
  },
  {
    id: '8b2742e7-ea78-4679-a0e1-041cf0ecc220',
    name_dcycle:
      'action_related_to_pollution_extends_to_upstream/downstream_value_chain_engagements',
    datapoint_id: 'E2-2_02',
    esg_category: 'environmental',
    name_en: 'Action related to pollution extends to upstream/downstream value chain engagements',
    name_es:
      'Las acciones relacionadas con la contaminación se extienden a los compromisos de las cadenas de valor ascendentes y descendentes.',
    name_pt:
      'As ações relacionadas com a poluição estendem-se aos compromissos a montante/jusante da cadeia de valor'
  },
  {
    id: 'e311b7d2-ff65-4c71-a6bd-3b8b64082221',
    name_dcycle: 'layer_in_mitigation_hierarchy_to_which_resources_can_be_allocated_to_(pollution)',
    datapoint_id: 'E2-2_03',
    esg_category: 'environmental',
    name_en: 'Layer in mitigation hierarchy to which resources can be allocated to (pollution)',
    name_es:
      'Capa en la jerarquía de mitigación a la que se pueden asignar recursos (contaminación)',
    name_pt: 'Camada na hierarquia de mitigação à qual os recursos podem ser alocados (poluição)'
  },
  {
    id: '20c8e6f5-6090-40af-91ed-72ab484eed28',
    name_dcycle:
      'information_about_action_plans_that_have_been_implemented_at_site-level_(pollution)',
    datapoint_id: 'E2-2_04',
    esg_category: 'environmental',
    name_en: 'Information about action plans that have been implemented at site-level (pollution)',
    name_es:
      'Información sobre planes de acción que se han implementado a nivel de sitio (contaminación)',
    name_pt: 'Informações sobre planos de ação que foram implementados a nível local (poluição)'
  },
  {
    id: '470466f9-460b-4e5c-a0f4-000804aa1c8a',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_prevention_and_control_of_air_pollutants_and_respective_specific_loads',
    datapoint_id: 'E2-3_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to prevention and control of air pollutants and respective specific loads',
    name_es:
      'Divulgación de si el objetivo se relaciona con la prevención y el control de los contaminantes del aire y sus respectivas cargas específicas, y de qué manera.',
    name_pt:
      'Divulgação sobre se e como a meta está relacionada com a prevenção e controlo de poluentes atmosféricos e respetivas cargas específicas'
  },
  {
    id: 'd32c2da7-6528-43fa-bf08-771b89e3cd53',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_prevention_and_control_of_emissions_to_water_and_respective_specific_loads',
    datapoint_id: 'E2-3_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to prevention and control of emissions to water and respective specific loads',
    name_es:
      'Divulgación de si y cómo el objetivo se relaciona con la prevención y el control de las emisiones al agua y las respectivas cargas específicas.',
    name_pt:
      'Divulgação sobre se e como a meta se relaciona com a prevenção e controlo de emissões para a água e respetivas cargas específicas'
  },
  {
    id: 'a9d2754a-5b60-47c1-8268-75f26c61a1b3',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_prevention_and_control_of_pollution_to_soil_and_respective_specific_loads',
    datapoint_id: 'E2-3_03',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to prevention and control of pollution to soil and respective specific loads',
    name_es:
      'Divulgación de si y cómo el objetivo se relaciona con la prevención y el control de la contaminación del suelo y las respectivas cargas específicas.',
    name_pt:
      'Divulgação sobre se e como a meta está relacionada com a prevenção e controlo da poluição do solo e respetivas cargas específicas'
  },
  {
    id: '6a1adca6-dfc1-4cfd-8e04-33f1597ce2d8',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_prevention_and_control_of_substances_of_concern_and_substances_of_very_high_concern',
    datapoint_id: 'E2-3_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to prevention and control of substances of concern and substances of very high concern',
    name_es:
      'Divulgación de si la meta se relaciona con la prevención y el control de sustancias preocupantes y sustancias extremadamente preocupantes, y de qué manera.',
    name_pt:
      'Divulgação sobre se e como a meta está relacionada com a prevenção e o controle de substâncias que suscitam preocupação e substâncias que suscitam elevada preocupação'
  },
  {
    id: 'c3c022fa-7a64-4bed-9bd3-c744b7b3abff',
    name_dcycle:
      'ecological_thresholds_and_entity-specific_allocations_were_taken_into_consideration_when_setting_pollution-related_target',
    datapoint_id: 'E2-3_05',
    esg_category: 'environmental',
    name_en:
      'Ecological thresholds and entity-specific allocations were taken into consideration when setting pollution-related target',
    name_es:
      'Se tuvieron en cuenta los umbrales ecológicos y las asignaciones específicas de cada entidad al establecer objetivos relacionados con la contaminación.',
    name_pt:
      'Os limiares ecológicos e as dotações específicas das entidades foram tidos em consideração ao definir metas relacionadas com a poluição'
  },
  {
    id: '92f62637-2b39-48c4-9ae4-e3aa47d6945f',
    name_dcycle:
      'disclosure_of_ecological_thresholds_identified_and_methodology_used_to_identify_ecological_thresholds_(pollution)',
    datapoint_id: 'E2-3_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of ecological thresholds identified and methodology used to identify ecological thresholds (pollution)',
    name_es:
      'Divulgación de umbrales ecológicos identificados y metodología utilizada para identificar umbrales ecológicos (contaminación)',
    name_pt:
      'Divulgação dos limiares ecológicos identificados e metodologia utilizada para identificar os limiares ecológicos (poluição)'
  },
  {
    id: '02d88961-fcc8-4475-8535-2a5419d1702c',
    name_dcycle:
      'disclosure_of_how_ecological_entity-specific_thresholds_were_determined_(pollution)',
    datapoint_id: 'E2-3_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of how ecological entity-specific thresholds were determined (pollution)',
    name_es:
      'Divulgación de cómo se determinaron los umbrales ecológicos específicos de la entidad (contaminación)',
    name_pt:
      'Divulgação de como foram determinados os limiares específicos da entidade ecológica (poluição)'
  },
  {
    id: '1b4eb859-4bba-45f3-b08b-6bf2875aace2',
    name_dcycle:
      'disclosure_of_how_responsibility_for_respecting_identified_ecological_thresholds_is_allocated_(pollution)',
    datapoint_id: 'E2-3_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how responsibility for respecting identified ecological thresholds is allocated (pollution)',
    name_es:
      'Divulgación de cómo se asigna la responsabilidad de respetar los umbrales ecológicos identificados (contaminación)',
    name_pt:
      'Divulgação de como é atribuída a responsabilidade pelo respeito dos limiares ecológicos identificados (poluição)'
  },
  {
    id: '3a597804-5584-4120-a0db-f08db0c5ef45',
    name_dcycle: 'pollution-related_target_is_mandatory_(required_by_legislation)/voluntary',
    datapoint_id: 'E2-3_09',
    esg_category: 'environmental',
    name_en: 'Pollution-related target is mandatory (required by legislation)/voluntary',
    name_es:
      'El objetivo relacionado con la contaminación es obligatorio (requerido por la legislación)/voluntario',
    name_pt: 'A meta relacionada à poluição é obrigatória (exigida pela legislação)/voluntária'
  },
  {
    id: '3854b8ec-f117-443e-ae3c-efa6a34ec1cf',
    name_dcycle:
      'pollution-related_target_addresses_shortcomings_related_to_substantial_contribution_criteria_for_pollution_prevention_and_control',
    datapoint_id: 'E2-3_10',
    esg_category: 'environmental',
    name_en:
      'Pollution-related target addresses shortcomings related to Substantial Contribution criteria for Pollution Prevention and Control',
    name_es:
      'La meta relacionada con la contaminación aborda las deficiencias relacionadas con los criterios de contribución sustancial para la prevención y el control de la contaminación.',
    name_pt:
      'A meta relacionada com a poluição aborda deficiências relacionadas com os critérios de contribuição substancial para a prevenção e controlo da poluição'
  },
  {
    id: 'f90b1917-c57e-46e6-8a2f-85ae5a50f6f1',
    name_dcycle: 'information_about_targets_that_have_been_implemented_at_site-level_(pollution)',
    datapoint_id: 'E2-3_11',
    esg_category: 'environmental',
    name_en: 'Information about targets that have been implemented at site-level (pollution)',
    name_es: 'Información sobre objetivos que se han implementado a nivel de sitio (contaminación)',
    name_pt: 'Informações sobre metas que foram implementadas no nível do local (poluição)'
  },
  {
    id: '803e9048-2a3a-4b5f-bd72-497457ed0fe8',
    name_dcycle:
      'pollution_of_air,_water_and_soil_[multiple_dimensions:_at_site_level_or_by_type_of_source,_by_sector_or_by_geographical_area',
    datapoint_id: 'E2-4_01',
    esg_category: 'environmental',
    name_en:
      'Pollution of air, water and soil [multiple dimensions: at site level or by type of source, by sector or by geographical area',
    name_es:
      'Contaminación del aire, el agua y el suelo [múltiples dimensiones: a nivel de sitio o por tipo de fuente, por sector o por área geográfica',
    name_pt:
      'Poluição do ar, da água e do solo [múltiplas dimensões: ao nível do local ou por tipo de fonte, por setor ou por área geográfica'
  },
  {
    id: '7ad00b20-0797-4aee-8815-600d2da36cdf',
    name_dcycle: 'emissions_to_air_by_pollutant',
    datapoint_id: 'E2-4_02',
    esg_category: 'environmental',
    name_en: 'Emissions to air by pollutant',
    name_es: 'Emisiones al aire por contaminante',
    name_pt: 'Emissões para a atmosfera por poluente'
  },
  {
    id: '4f42f3fd-0a88-4c19-81b7-e3e4c9713113',
    name_dcycle:
      'emissions_to_water_by_pollutant_[+_by_sectors/geographical_area/type_of_source/site_location]',
    datapoint_id: 'E2-4_03',
    esg_category: 'environmental',
    name_en:
      'Emissions to water by pollutant [+ by sectors/Geographical Area/Type of source/Site location]',
    name_es:
      'Emisiones al agua por contaminante [+ por sectores/Área Geográfica/Tipo de fuente/Ubicación del sitio]',
    name_pt:
      'Emissões para a água por poluente [+ por setores/Área geográfica/Tipo de fonte/Localização do local]'
  },
  {
    id: 'b59b4637-8bc4-4c08-9447-0fb60a4433c3',
    name_dcycle:
      'emissions_to_soil_by_pollutant_[+_by_sectors/geographical_area/type_of_source/site_location]',
    datapoint_id: 'E2-4_04',
    esg_category: 'environmental',
    name_en:
      'Emissions to soil by pollutant [+ by sectors/Geographical Area/Type of source/Site location]',
    name_es:
      'Emisiones al suelo por contaminante [+ por sectores/Área Geográfica/Tipo de fuente/Ubicación del sitio]',
    name_pt:
      'Emissões para o solo por poluente [+ por setores/Área geográfica/Tipo de fonte/Localização do local]'
  },
  {
    id: 'b11fe638-5335-4501-a125-78c26feb9430',
    name_dcycle: 'microplastics_generated_and_used',
    datapoint_id: 'E2-4_05',
    esg_category: 'environmental',
    name_en: 'Microplastics generated and used',
    name_es: 'Microplásticos generados y utilizados',
    name_pt: 'Microplásticos gerados e usados'
  },
  {
    id: '69341bb0-892f-444f-9698-27d28aec9d77',
    name_dcycle: 'microplastics_generated',
    datapoint_id: 'E2-4_06',
    esg_category: 'environmental',
    name_en: 'Microplastics generated',
    name_es: 'Microplásticos generados',
    name_pt: 'Microplásticos gerados'
  },
  {
    id: '5b2290d4-164b-47b4-831a-bed22e91b6bf',
    name_dcycle: 'microplastics_used',
    datapoint_id: 'E2-4_07',
    esg_category: 'environmental',
    name_en: 'Microplastics used',
    name_es: 'Microplásticos utilizados',
    name_pt: 'Microplásticos usados'
  },
  {
    id: 'd859db7a-16e4-40eb-b03f-c9ac01591f62',
    name_dcycle: 'description_of_changes_over_time_(pollution_of_air,_water_and_soil)',
    datapoint_id: 'E2-4_08',
    esg_category: 'environmental',
    name_en: 'Description of changes over time (pollution of air, water and soil)',
    name_es: 'Descripción de los cambios en el tiempo (contaminación del aire, agua y suelo)',
    name_pt: 'Descrição das mudanças ao longo do tempo (poluição do ar, da água e do solo)'
  },
  {
    id: 'fda5212f-4e9f-4d80-a473-946e4b0e7444',
    name_dcycle: 'description_of_measurement_methodologies_(pollution_of_air,_water_and_soil)',
    datapoint_id: 'E2-4_09',
    esg_category: 'environmental',
    name_en: 'Description of measurement methodologies (pollution of air, water and soil)',
    name_es: 'Descripción de metodologías de medición (contaminación del aire, agua y suelo)',
    name_pt: 'Descrição das metodologias de medição (poluição do ar, da água e do solo)'
  },
  {
    id: 'a402103f-8eb9-47ee-838a-f556f5f26a5e',
    name_dcycle:
      'description_of_process(es)_to_collect_data_for_pollution-related_accounting_and_reporting',
    datapoint_id: 'E2-4_10',
    esg_category: 'environmental',
    name_en:
      'Description of process(es) to collect data for pollution-related accounting and reporting',
    name_es:
      'Descripción del proceso(s) para recopilar datos para la contabilidad y presentación de informes relacionados con la contaminación',
    name_pt:
      'Descrição do(s) processo(s) de coleta de dados para contabilidade e relatórios relacionados à poluição'
  },
  {
    id: '4c7f4c23-14a3-4c0e-be36-93cca570f4cd',
    name_dcycle:
      'percentage_of_total_emissions_of_pollutants_to_water_occurring_in_areas_at_water_risk',
    datapoint_id: 'E2-4_11',
    esg_category: 'environmental',
    name_en:
      'Percentage of total emissions of pollutants to water occurring in areas at water risk',
    name_es:
      'Porcentaje del total de emisiones de contaminantes al agua que se producen en zonas con riesgo hídrico',
    name_pt:
      'Percentagem do total de emissões de poluentes para a água que ocorrem em áreas com risco hídrico'
  },
  {
    id: '7b6283d9-4784-4ed1-8246-87a436916e7e',
    name_dcycle:
      'percentage_of_total_emissions_of_pollutants_to_water_occurring_in_areas_of_high-water_stress',
    datapoint_id: 'E2-4_12',
    esg_category: 'environmental',
    name_en:
      'Percentage of total emissions of pollutants to water occurring in areas of high-water stress',
    name_es:
      'Porcentaje de emisiones totales de contaminantes al agua que se producen en zonas de alto estrés hídrico',
    name_pt:
      'Percentagem do total de emissões de poluentes para a água que ocorrem em áreas de elevado stress hídrico'
  },
  {
    id: '13534a57-ac0b-44f2-bb0e-9677155e9c79',
    name_dcycle:
      'percentage_of_total_emissions_of_pollutants_to_soil_occurring_in_areas_at_water_risk',
    datapoint_id: 'E2-4_13',
    esg_category: 'environmental',
    name_en: 'Percentage of total emissions of pollutants to soil occurring in areas at water risk',
    name_es:
      'Porcentaje del total de emisiones de contaminantes al suelo que se producen en zonas con riesgo hídrico',
    name_pt:
      'Percentual das emissões totais de poluentes para o solo que ocorrem em áreas com risco hídrico'
  },
  {
    id: 'ca601569-5048-4752-8afb-d39e22c49902',
    name_dcycle:
      'percentage_of_total_emissions_of_pollutants_to_soil_occurring_in_areas_of_high-water_stress',
    datapoint_id: 'E2-4_14',
    esg_category: 'environmental',
    name_en:
      'Percentage of total emissions of pollutants to soil occurring in areas of high-water stress',
    name_es:
      'Porcentaje de emisiones totales de contaminantes al suelo que ocurren en áreas de alto estrés hídrico',
    name_pt:
      'Percentagem do total de emissões de poluentes para o solo que ocorrem em áreas de elevado stress hídrico'
  },
  {
    id: 'a2440711-668a-48d2-9d2e-2ba7f812c8a8',
    name_dcycle: 'disclosure_of_reasons_for_choosing_inferior_methodology_to_quantify_emissions',
    datapoint_id: 'E2-4_15',
    esg_category: 'environmental',
    name_en: 'Disclosure of reasons for choosing inferior methodology to quantify emissions',
    name_es:
      'Divulgación de razones para elegir una metodología inferior para cuantificar las emisiones.',
    name_pt:
      'Divulgação dos motivos da escolha de metodologia inferior para quantificação de emissões'
  },
  {
    id: '92ef9373-17d0-4b22-9128-d27cb08a0634',
    name_dcycle:
      'disclosure_of_list_of_installations_operated_that_fall_under_ied_and_eu_bat_conclusions',
    datapoint_id: 'E2-4_16',
    esg_category: 'environmental',
    name_en:
      'Disclosure of list of installations operated that fall under IED and EU BAT Conclusions',
    name_es:
      'Divulgación de la lista de instalaciones operadas que se incluyen en el ámbito de los DEI y las conclusiones sobre las MTD de la UE',
    name_pt:
      'Divulgação da lista de instalações operadas que se enquadram nas Conclusões do IED e das MTD da UE'
  },
  {
    id: 'f682b92f-4dc8-47cc-b8fc-28f06d2b0b0c',
    name_dcycle:
      'disclosure_of_list_of_any_non-compliance_incidents_or_enforcement_actions_necessary_to_ensure_compliance_in_case_of_breaches_of_permit_conditions',
    datapoint_id: 'E2-4_17',
    esg_category: 'environmental',
    name_en:
      'Disclosure of list of any non-compliance incidents or enforcement actions necessary to ensure compliance in case of breaches of permit conditions',
    name_es:
      'Divulgación de una lista de incidentes de incumplimiento o acciones de cumplimiento necesarias para garantizar el cumplimiento en caso de incumplimiento de las condiciones del permiso.',
    name_pt:
      'Divulgação da lista de quaisquer incidentes de não conformidade ou ações de fiscalização necessárias para garantir a conformidade em caso de violação das condições de licença'
  },
  {
    id: '6cbff97b-2586-4d97-9ba7-82d04d20b9a5',
    name_dcycle:
      'disclosure_of_actual_performance_and_comparison_of_environmental_performance_against_emission_levels_associated_with_best_available_techniques_(bat-ael)_as_described_in_eu-bat_conclusions',
    datapoint_id: 'E2-4_18',
    esg_category: 'environmental',
    name_en:
      'Disclosure of actual performance and comparison of environmental performance against emission levels associated with best available techniques (BAT-AEL) as described in EU-BAT conclusions',
    name_es:
      'Divulgación del desempeño real y comparación del desempeño ambiental con los niveles de emisión asociados con las mejores técnicas disponibles (MTD-AEL) como se describe en las conclusiones de las MTD de la UE.',
    name_pt:
      'Divulgação do desempenho real e comparação do desempenho ambiental com os níveis de emissões associados às melhores técnicas disponíveis (MTD-AEL), conforme descrito nas conclusões da UE-MTD'
  },
  {
    id: 'b42aae38-8b81-4f7d-889b-754a76aa5120',
    name_dcycle:
      'disclosure_of_actual_performance_against_environmental_performance_levels_associated_with_best_available_techniques_(bat-aepls)_applicable_to_sector_and_installation',
    datapoint_id: 'E2-4_19',
    esg_category: 'environmental',
    name_en:
      'Disclosure of actual performance against environmental performance levels associated with best available techniques (BAT-AEPLs) applicable to sector and installation',
    name_es:
      'Divulgación del desempeño real frente a los niveles de desempeño ambiental asociados con las mejores técnicas disponibles (MTD-AEPL) aplicables al sector y la instalación.',
    name_pt:
      'Divulgação do desempenho real em relação aos níveis de desempenho ambiental associados às melhores técnicas disponíveis (MTD-AEPLs) aplicáveis ​​ao setor e à instalação'
  },
  {
    id: '2da00230-7f30-4935-8eae-3496d913152c',
    name_dcycle:
      'disclosure_of_list_of_any_compliance_schedules_or_derogations_granted_by_competent_authorities_according_to_article_15(4)_ied_that_are_associated_with_implementation_of_bat-aels',
    datapoint_id: 'E2-4_20',
    esg_category: 'environmental',
    name_en:
      'Disclosure of list of any compliance schedules or derogations granted by competent authorities according to Article 15(4) IED that are associated with implementation of BAT-AELs',
    name_es:
      'Divulgación de la lista de cronogramas de cumplimiento o derogaciones concedidos por las autoridades competentes de conformidad con el artículo 15, apartado 4, de la DEI que están asociados con la implementación de los NEA-MTD',
    name_pt:
      'Divulgação da lista de quaisquer calendários de conformidade ou derrogações concedidas pelas autoridades competentes nos termos do artigo 15.º, n.º 4, da DEI que estão associados à implementação de VEA-MTD'
  },
  {
    id: 'af1f8d92-051f-4bd7-bb65-9cc667fa8329',
    name_dcycle:
      'total_amount_of_substances_of_concern_that_are_generated_or_used_during_production_or_that_are_procured,_breakdown_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_01',
    esg_category: 'environmental',
    name_en:
      'Total amount of substances of concern that are generated or used during production or that are procured, breakdown by main hazard classes of substances of concern',
    name_es:
      'Cantidad total de sustancias preocupantes que se generan o utilizan durante la producción o que se adquieren, desglosadas por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade total de substâncias preocupantes geradas ou utilizadas durante a produção ou adquiridas, discriminadas pelas principais classes de perigo de substâncias preocupantes'
  },
  {
    id: '48e5c5f3-365d-4cea-be92-c3f5159b3050',
    name_dcycle:
      'total_amount_of_substances_of_concern_that_are_generated_or_used_during_production_or_that_are_procured',
    datapoint_id: 'E2-5_02',
    esg_category: 'environmental',
    name_en:
      'Total amount of substances of concern that are generated or used during production or that are procured',
    name_es:
      'Cantidad total de sustancias preocupantes que se generan o utilizan durante la producción o que se adquieren',
    name_pt:
      'Quantidade total de substâncias preocupantes geradas ou utilizadas durante a produção ou adquiridas'
  },
  {
    id: 'ded1a826-3ca3-487c-939e-c205f01a0575',
    name_dcycle:
      'total_amount_of_substances_of_concern_that_leave_facilities_as_emissions,_as_products,_or_as_part_of_products_or_services',
    datapoint_id: 'E2-5_03',
    esg_category: 'environmental',
    name_en:
      'Total amount of substances of concern that leave facilities as emissions, as products, or as part of products or services',
    name_es:
      'Cantidad total de sustancias preocupantes que salen de las instalaciones como emisiones, como productos o como parte de productos o servicios.',
    name_pt:
      'Quantidade total de substâncias preocupantes que saem das instalações como emissões, como produtos ou como parte de produtos ou serviços'
  },
  {
    id: '9e5d5252-b8b0-4cd5-9175-a4b38feb6c65',
    name_dcycle:
      'amount_of_substances_of_concern_that_leave_facilities_as_emissions_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_04',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of concern that leave facilities as emissions by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias preocupantes que salen de las instalaciones como emisiones por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias preocupantes que saem das instalações como emissões por principais classes de perigo de substâncias preocupantes'
  },
  {
    id: 'c18477c8-6642-4e93-9379-690774d0840c',
    name_dcycle:
      'amount_of_substances_of_concern_that_leave_facilities_as_products_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_05',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of concern that leave facilities as products by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias preocupantes que salen de las instalaciones como productos por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias preocupantes que saem das instalações como produtos, por principais classes de perigo de substâncias preocupantes'
  },
  {
    id: '353a5803-2be2-4745-b040-8db8b3b0e6d2',
    name_dcycle:
      'amount_of_substances_of_concern_that_leave_facilities_as_part_of_products_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_06',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of concern that leave facilities as part of products by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias preocupantes que salen de las instalaciones como parte de productos por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias preocupantes que saem das instalações como parte de produtos, por principais classes de perigo de substâncias preocupantes'
  },
  {
    id: '4494a0f8-b1f5-4bbd-87e9-62637c79c3be',
    name_dcycle:
      'amount_of_substances_of_concern_that_leave_facilities_as_services_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_07',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of concern that leave facilities as services by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias preocupantes que salen de las instalaciones como servicios por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias preocupantes que saem das instalações como serviços, por principais classes de perigo de substâncias preocupantes'
  },
  {
    id: 'ff3676c0-1329-4187-be37-6f865e352c3f',
    name_dcycle:
      'total_amount_of_substances_of_very_high_concern_that_are_generated_or_used_during_production_or_that_are_procured_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_08',
    esg_category: 'environmental',
    name_en:
      'Total amount of substances of very high concern that are generated or used during production or that are procured by main hazard classes of substances of concern',
    name_es:
      'Cantidad total de sustancias extremadamente preocupantes que se generan o utilizan durante la producción o que se obtienen según las principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade total de substâncias que suscitam elevada preocupação que são geradas ou utilizadas durante a produção ou que são adquiridas pelas principais classes de perigo de substâncias que suscitam preocupação'
  },
  {
    id: '08f71968-5cdc-4c40-a9ed-6a34cee6b3bb',
    name_dcycle:
      'total_amount_of_substances_of_very_high_concern_that_leave_facilities_as_emissions,_as_products,_or_as_part_of_products_or_services_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_09',
    esg_category: 'environmental',
    name_en:
      'Total amount of substances of very high concern that leave facilities as emissions, as products, or as part of products or services by main hazard classes of substances of concern',
    name_es:
      'Cantidad total de sustancias extremadamente preocupantes que salen de las instalaciones como emisiones, como productos o como parte de productos o servicios por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade total de substâncias que suscitam elevada preocupação que saem das instalações como emissões, como produtos ou como parte de produtos ou serviços, por principais classes de perigo de substâncias que suscitam preocupação'
  },
  {
    id: '79c1dd59-dfeb-4340-8c7f-24f8ec37b33e',
    name_dcycle:
      'amount_of_substances_of_very_high_concern_that_leave_facilities_as_emissions_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_10',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of very high concern that leave facilities as emissions by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias altamente preocupantes que salen de las instalaciones como emisiones por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias que suscitam elevada preocupação que saem das instalações como emissões pelas principais classes de perigo de substâncias que suscitam preocupação'
  },
  {
    id: '76688c62-f5f4-47e0-821a-58011c55c9ea',
    name_dcycle:
      'amount_of_substances_of_very_high_concern_that_leave_facilities_as_products_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_11',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of very high concern that leave facilities as products by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias extremadamente preocupantes que salen de las instalaciones como productos por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias que suscitam elevada preocupação que saem das instalações como produtos, por principais classes de perigo de substâncias que suscitam preocupação'
  },
  {
    id: '65d764bc-3c0b-49db-9ff7-13a62ef38238',
    name_dcycle:
      'amount_of_substances_of_very_high_concern_that_leave_facilities_as_part_of_products_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_12',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of very high concern that leave facilities as part of products by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias extremadamente preocupantes que salen de las instalaciones como parte de productos por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias que suscitam elevada preocupação que saem das instalações como parte dos produtos, discriminadas pelas principais classes de perigo das substâncias que suscitam preocupação'
  },
  {
    id: 'dd593508-5ae0-4b04-9c15-8b4062faa0e1',
    name_dcycle:
      'amount_of_substances_of_very_high_concern_that_leave_facilities_as_services_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_13',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of very high concern that leave facilities as services by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias extremadamente preocupantes que salen de las instalaciones como servicios por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias que suscitam elevada preocupação que saem das instalações como serviços, por principais classes de perigo de substâncias que suscitam preocupação'
  },
  {
    id: '7c23f01a-0f50-415a-86fd-8223981649be',
    name_dcycle:
      'disclosure_of_quantitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_pollution-related_impacts',
    datapoint_id: 'E2-6_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of quantitative information about anticipated financial effects of material risks and opportunities arising from pollution-related impacts',
    name_es:
      'Divulgación de información cuantitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos relacionados con la contaminación.',
    name_pt:
      'Divulgação de informações quantitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos relacionados à poluição'
  },
  {
    id: '4aecb521-f625-4879-b610-eae8e15bca4f',
    name_dcycle:
      'percentage_of_net_revenue_made_with_products_and_services_that_are_or_that_contain_substances_of_concern',
    datapoint_id: 'E2-6_02',
    esg_category: 'environmental',
    name_en:
      'Percentage of net revenue made with products and services that are or that contain substances of concern',
    name_es:
      'Porcentaje de ingresos netos obtenidos con productos y servicios que son o contienen sustancias preocupantes',
    name_pt:
      'Porcentagem da receita líquida obtida com produtos e serviços que são ou contêm substâncias preocupantes'
  },
  {
    id: '385d245a-77fb-43b6-a7d7-f03cd898e3f5',
    name_dcycle:
      'percentage_of_net_revenue_made_with_products_and_services_that_are_or_that_contain_substances_of_very_high_concern',
    datapoint_id: 'E2-6_03',
    esg_category: 'environmental',
    name_en:
      'Percentage of net revenue made with products and services that are or that contain substances of very high concern',
    name_es:
      'Porcentaje de ingresos netos obtenidos con productos y servicios que son o contienen sustancias extremadamente preocupantes',
    name_pt:
      'Percentagem da receita líquida obtida com produtos e serviços que são ou contêm substâncias que suscitam elevada preocupação'
  },
  {
    id: '3bb84ae9-ed86-400b-955b-8b7a10cf12b4',
    name_dcycle:
      'operating_expenditures_(opex)_in_conjunction_with_major_incidents_and_deposits_(pollution)',
    datapoint_id: 'E2-6_04',
    esg_category: 'environmental',
    name_en:
      'Operating expenditures (OpEx) in conjunction with major incidents and deposits (pollution)',
    name_es:
      'Gastos operativos (OpEx) en conjunto con incidentes importantes y depósitos (contaminación)',
    name_pt:
      'Despesas operacionais (OpEx) em conjunto com grandes incidentes e depósitos (poluição)'
  },
  {
    id: 'ce2ccf5a-9e9a-471b-9ad6-67e43f53388b',
    name_dcycle:
      'capital_expenditures_(capex)_in_conjunction_with_major_incidents_and_deposits_(pollution)',
    datapoint_id: 'E2-6_05',
    esg_category: 'environmental',
    name_en:
      'Capital expenditures (CapEx) in conjunction with major incidents and deposits (pollution)',
    name_es:
      'Gastos de capital (CapEx) en conjunto con incidentes importantes y depósitos (contaminación)',
    name_pt: 'Despesas de capital (CapEx) em conjunto com grandes incidentes e depósitos (poluição)'
  },
  {
    id: '9a82eee5-ac43-45b4-9c86-4b5ae1448810',
    name_dcycle: 'provisions_for_environmental_protection_and_remediation_costs_(pollution)',
    datapoint_id: 'E2-6_06',
    esg_category: 'environmental',
    name_en: 'Provisions for environmental protection and remediation costs (pollution)',
    name_es: 'Provisiones para protección ambiental y costos de remediación (contaminación)',
    name_pt: 'Provisões para proteção ambiental e custos de remediação (poluição)'
  },
  {
    id: 'd7d381d1-3a86-4e37-877a-08274629ef4d',
    name_dcycle:
      'disclosure_of_qualitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_pollution-related_impacts',
    datapoint_id: 'E2-6_07',
    esg_category: 'environmental',
    name_en:
      'Disclosure of qualitative information about anticipated financial effects of material risks and opportunities arising from pollution-related impacts',
    name_es:
      'Divulgación de información cualitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos relacionados con la contaminación.',
    name_pt:
      'Divulgação de informações qualitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos relacionados à poluição'
  },
  {
    id: '7f00a63a-9cc6-4a37-a5ce-d37b7835ce63',
    name_dcycle:
      'description_of_effects_considered,_related_impacts_and_time_horizons_in_which_they_are_likely_to_materialise_(pollution)',
    datapoint_id: 'E2-6_08',
    esg_category: 'environmental',
    name_en:
      'Description of effects considered, related impacts and time horizons in which they are likely to materialise (pollution)',
    name_es:
      'Descripción de los efectos considerados, impactos relacionados y horizontes temporales en los que es probable que se materialicen (contaminación)',
    name_pt:
      'Descrição dos efeitos considerados, impactos relacionados e horizontes temporais em que é provável que se materializem (poluição)'
  },
  {
    id: '935ac00b-48f8-4b27-bf39-7a9966cbc081',
    name_dcycle:
      'disclosure_of_critical_assumptions_used_to_quantify_anticipated_financial_effects,_sources_and_level_of_uncertainty_of_assumptions_(pollution)',
    datapoint_id: 'E2-6_09',
    esg_category: 'environmental',
    name_en:
      'Disclosure of critical assumptions used to quantify anticipated financial effects, sources and level of uncertainty of assumptions (pollution)',
    name_es:
      'Divulgación de supuestos críticos utilizados para cuantificar los efectos financieros previstos, las fuentes y el nivel de incertidumbre de los supuestos (contaminación)',
    name_pt:
      'Divulgação de premissas críticas usadas para quantificar os efeitos financeiros previstos, fontes e nível de incerteza das premissas (poluição)'
  },
  {
    id: '3079b7fc-5420-4152-aeac-338d9dcf7517',
    name_dcycle:
      'description_of_material_incidents_and_deposits_whereby_pollution_had_negative_impacts_on_environment_and_(or)_is_expected_to_have_negative_effects_on_financial_cash_flows,_financial_position_and_financial_performance',
    datapoint_id: 'E2-6_10',
    esg_category: 'environmental',
    name_en:
      'Description of material incidents and deposits whereby pollution had negative impacts on environment and (or) is expected to have negative effects on financial cash flows, financial position and financial performance',
    name_es:
      'Descripción de incidentes y depósitos materiales mediante los cuales la contaminación tuvo impactos negativos en el medio ambiente y (o) se espera que tenga efectos negativos en los flujos de efectivo financieros, la situación financiera y el desempeño financiero.',
    name_pt:
      'Descrição de incidentes e depósitos materiais em que a poluição teve impactos negativos no meio ambiente e (ou) se espera que tenha efeitos negativos nos fluxos de caixa financeiros, na posição financeira e no desempenho financeiro'
  },
  {
    id: 'e0cbcdd7-100b-4fd2-b8ea-f495bde45e7a',
    name_dcycle:
      'disclosure_of_assessment_of_related_products_and_services_at_risk_and_explanation_how_time_horizon_is_defined,_financial_amounts_are_estimated,_and_which_critical_assumptions_are_made_(pollution)',
    datapoint_id: 'E2-6_11',
    esg_category: 'environmental',
    name_en:
      'Disclosure of assessment of related products and services at risk and explanation how time horizon is defined, financial amounts are estimated, and which critical assumptions are made (pollution)',
    name_es:
      'Divulgación de la evaluación de productos y servicios relacionados en riesgo y explicación de cómo se define el horizonte temporal, se estiman los montos financieros y qué supuestos críticos se hacen (contaminación).',
    name_pt:
      'Divulgação da avaliação de produtos e serviços relacionados em risco e explicação de como o horizonte temporal é definido, os valores financeiros são estimados e quais premissas críticas são feitas (poluição)'
  },
  {
    id: 'c61a1907-d0d3-4d68-80b4-88e370f814d3',
    name_dcycle:
      'disclosure_of_whether_and_how_assets_and_activities_have_been_screened_in_order_to_identify_actual_and_potential_water_and_marine_resources-related_impacts,_risks_and_opportunities_in_own_operations_and_upstream_and_downstream_value_chain_and_methodologies,_assumptions_and_tools_used_in_screening_[text_block]',
    datapoint_id: 'E3.IRO-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how assets and activities have been screened in order to identify actual and potential water and marine resources-related impacts, risks and opportunities in own operations and upstream and downstream value chain and methodologies, assumptions and tools used in screening [text block]',
    name_es:
      'Divulgación de si se han examinado los activos y las actividades, y cómo, para identificar los impactos, riesgos y oportunidades reales y potenciales relacionados con el agua y los recursos marinos en las propias operaciones y en la cadena de valor ascendente y descendente, y las metodologías, suposiciones y herramientas utilizadas en la evaluación [bloque de texto ]',
    name_pt:
      'Divulgação de se e como os ativos e atividades foram avaliados, a fim de identificar impactos, riscos e oportunidades reais e potenciais relacionados com a água e os recursos marinhos nas próprias operações e na cadeia de valor a montante e a jusante e metodologias, pressupostos e ferramentas utilizadas na triagem [bloco de texto ]'
  },
  {
    id: 'adae3645-2ef3-421f-9d8d-f23265f7217a',
    name_dcycle:
      'disclosure_of_how_consultations_have_been_conducted_(water_and_marine_resources)_[text_block]',
    datapoint_id: 'E3.IRO-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how consultations have been conducted (water and marine resources) [text block]',
    name_es:
      'Divulgación de cómo se han llevado a cabo las consultas (agua y recursos marinos) [bloque de texto]',
    name_pt:
      'Divulgação de como as consultas foram conduzidas (recursos hídricos e marinhos) [bloco de texto]'
  },
  {
    id: '467427f6-9d41-41d9-a9fd-a7c754d6c75d',
    name_dcycle: 'disclosure_of_whether_and_how_policy_adresses_water_management',
    datapoint_id: 'E3-1_01',
    esg_category: 'environmental',
    name_en: 'Disclosure of whether and how policy adresses water management',
    name_es: 'Divulgación de si la política aborda la gestión del agua y cómo',
    name_pt: 'Divulgação sobre se e como a política aborda a gestão da água'
  },
  {
    id: 'b2a49970-65d4-4cf4-ada5-b0738ff28aa8',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_adresses_the_use_and_sourcing_of_water_and_marine_resources_in_own_operations',
    datapoint_id: 'E3-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy adresses the use and sourcing of water and marine resources in own operations',
    name_es:
      'Divulgación de si la política aborda el uso y abastecimiento de agua y recursos marinos en sus propias operaciones y cómo lo hace.',
    name_pt:
      'Divulgação sobre se e como a política aborda o uso e a obtenção de recursos hídricos e marinhos nas próprias operações'
  },
  {
    id: '141a2bcb-71c4-4b23-8086-fa4cee0c07dc',
    name_dcycle: 'disclosure_of_whether_and_how_policy_adresses_water_treatment',
    datapoint_id: 'E3-1_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of whether and how policy adresses water treatment',
    name_es: 'Divulgación de si la política aborda el tratamiento del agua y cómo lo hace.',
    name_pt: 'Divulgação sobre se e como a política aborda o tratamento da água'
  },
  {
    id: 'da5b8dfb-c16d-47e8-9176-306dd6f41c60',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_adresses_prevention_and_abatment_of_water_pollution',
    datapoint_id: 'E3-1_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy adresses prevention and abatment of water pollution',
    name_es:
      'Divulgación de si la política aborda la prevención y reducción de la contaminación del agua y cómo',
    name_pt:
      'Divulgação sobre se e como a política aborda a prevenção e redução da poluição da água'
  },
  {
    id: '9c279eea-90bf-431a-ac7c-62252db89269',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_adresses_product_and_service_design_in_view_of_addressing_water-related_issues_and_preservation_of_marine_resources',
    datapoint_id: 'E3-1_05',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy adresses product and service design in view of addressing water-related issues and preservation of marine resources',
    name_es:
      'Divulgación de si la política aborda el diseño de productos y servicios, y de qué manera, con miras a abordar cuestiones relacionadas con el agua y la preservación de los recursos marinos.',
    name_pt:
      'Divulgação sobre se e como a política aborda o design de produtos e serviços com vista a abordar questões relacionadas com a água e a preservação dos recursos marinhos'
  },
  {
    id: '1137a2cf-e64d-46cb-be19-93c0a3335147',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_adresses_commitment_to_reduce_material_water_consumption_in_areas_at_water_risk',
    datapoint_id: 'E3-1_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy adresses commitment to reduce material water consumption in areas at water risk',
    name_es:
      'Divulgación de si la política aborda el compromiso de reducir el consumo material de agua en áreas con riesgo hídrico y cómo lo hace.',
    name_pt:
      'Divulgação sobre se e como a política aborda o compromisso de reduzir o consumo material de água em áreas com risco hídrico'
  },
  {
    id: '61c37ab3-09b8-400d-bdc8-ef0d9b56f6bd',
    name_dcycle:
      'disclosure_of_reasons_for_not_having_adopted_policies_in_areas_of_high-water_stress',
    datapoint_id: 'E3-1_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of reasons for not having adopted policies in areas of high-water stress',
    name_es:
      'Divulgación de razones por las que no se han adoptado políticas en zonas de alto estrés hídrico',
    name_pt:
      'Divulgação das razões para não ter adotado políticas em áreas de alto estresse hídrico'
  },
  {
    id: '3664abde-0192-427c-acf7-4a3b7df9dcb5',
    name_dcycle:
      'disclosure_of_timeframe_in_which_policies_in_areas_of_high-water_stress_will_be_adopted',
    datapoint_id: 'E3-1_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of timeframe in which policies in areas of high-water stress will be adopted',
    name_es:
      'Divulgación del calendario en el que se adoptarán políticas en áreas de alto estrés hídrico',
    name_pt:
      'Divulgação do cronograma em que as políticas em áreas de alto estresse hídrico serão adotadas'
  },
  {
    id: '0edc157a-24ea-4583-b6a4-be57da4428b4',
    name_dcycle: 'policies_or_practices_related_to_sustainable_oceans_and_seas_have_been_adopted',
    datapoint_id: 'E3-1_09',
    esg_category: 'environmental',
    name_en: 'Policies or practices related to sustainable oceans and seas have been adopted',
    name_es: 'Se han adoptado políticas o prácticas relacionadas con océanos y mares sostenibles.',
    name_pt: 'Foram adotadas políticas ou práticas relacionadas com oceanos e mares sustentáveis'
  },
  {
    id: '687364e6-4587-46dc-b6b7-704f625e425f',
    name_dcycle:
      'the_policly_contributes_to_good_ecological_and_chemical_quality_of_surface_water_bodies_and_good_chemical_quality_and_quantity_of_groundwater_bodies,_in_order_to_protect_human_health,_water_supply,_natural_ecosystems_and_biodiversity,_the_good_environmental_status_of_marine_waters_and_the_protection_of_the_resource_base_upon_which_marine_related_activities_depend;',
    datapoint_id: 'E3-1_10',
    esg_category: 'environmental',
    name_en:
      'The policly contributes to good ecological and chemical quality of surface water bodies and good chemical quality and quantity of groundwater bodies, in order to protect human health, water supply, natural ecosystems and biodiversity, the good environmental status of marine waters and the protection of the resource base upon which marine related activities depend;',
    name_es:
      'La política contribuye a la buena calidad ecológica y química de las masas de agua superficiales y a la buena calidad y cantidad química de las masas de aguas subterráneas, con el fin de proteger la salud humana, el abastecimiento de agua, los ecosistemas naturales y la biodiversidad, el buen estado medioambiental de las aguas marinas y la protección del base de recursos de la que dependen las actividades relacionadas con el mar;',
    name_pt:
      'A política contribui para uma boa qualidade ecológica e química das massas de água superficiais e para uma boa qualidade e quantidade química das massas de água subterrâneas, a fim de proteger a saúde humana, o abastecimento de água, os ecossistemas naturais e a biodiversidade, o bom estado ambiental das águas marinhas e a protecção do base de recursos da qual dependem as atividades relacionadas com o mar;'
  },
  {
    id: 'c0c654fd-347f-44da-b580-d0eaca17fbc4',
    name_dcycle:
      'the_policy_minimise_material_impacts_and_risks_and_implement_mitigation_measures_that_aim_to_maintain_the_value_and_functionality_of_priority_services_and_to_increase_resource_efficiency_on_own_operations',
    datapoint_id: 'E3-1_11',
    esg_category: 'environmental',
    name_en:
      'The policy minimise material impacts and risks and implement mitigation measures that aim to maintain the value and functionality of priority services and to increase resource efficiency on own operations',
    name_es:
      'La política minimiza los impactos y riesgos materiales e implementa medidas de mitigación que tienen como objetivo mantener el valor y la funcionalidad de los servicios prioritarios y aumentar la eficiencia de los recursos en las operaciones propias.',
    name_pt:
      'A política minimiza impactos e riscos materiais e implementa medidas de mitigação que visam manter o valor e a funcionalidade dos serviços prioritários e aumentar a eficiência dos recursos nas próprias operações'
  },
  {
    id: '0fd8b20c-185d-4fad-a5b9-eaec10dda303',
    name_dcycle: 'the_policy_avoid_impacts_on_affected_communities.',
    datapoint_id: 'E3-1_12',
    esg_category: 'environmental',
    name_en: 'The policy avoid impacts on affected communities.',
    name_es: 'La política evita impactos en las comunidades afectadas.',
    name_pt: 'A política evita impactos nas comunidades afetadas.'
  },
  {
    id: '4f7c34f4-c411-483c-8369-4d6d983046ca',
    name_dcycle:
      'layer_in_mitigation_hierarchy_to_which_action_and_resources_can_be_allocated_to_(water_and_marine_resources)',
    datapoint_id: 'E3-2_01',
    esg_category: 'environmental',
    name_en:
      'Layer in mitigation hierarchy to which action and resources can be allocated to (water and marine resources)',
    name_es:
      'Capa en la jerarquía de mitigación a la que se pueden asignar acciones y recursos (recursos hídricos y marinos)',
    name_pt:
      'Camada na hierarquia de mitigação à qual as ações e recursos podem ser alocados (recursos hídricos e marinhos)'
  },
  {
    id: '9511a2a5-d9c1-4933-aad4-32186ace4861',
    name_dcycle: 'information_about_specific_collective_action_for_water_and_marine_resources',
    datapoint_id: 'E3-2_02',
    esg_category: 'environmental',
    name_en: 'Information about specific collective action for water and marine resources',
    name_es:
      'Información sobre acciones colectivas específicas para el agua y los recursos marinos',
    name_pt: 'Informações sobre ações coletivas específicas para recursos hídricos e marinhos'
  },
  {
    id: '0daca228-52a2-412a-864b-035a4b73af2c',
    name_dcycle: 'disclosure_of_actions_and_resources_in_relation_to_areas_at_water_risk',
    datapoint_id: 'E3-2_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of actions and resources in relation to areas at water risk',
    name_es: 'Divulgación de acciones y recursos en relación con áreas en riesgo hídrico',
    name_pt: 'Divulgação de ações e recursos em relação às áreas de risco hídrico'
  },
  {
    id: '7e22d2f4-98d9-4baf-bf21-b5bd3f05f901',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_management_of_material_impacts,_risks_and_opportunities_related_to_areas_at_water_risk',
    datapoint_id: 'E3-3_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to management of material impacts, risks and opportunities related to areas at water risk',
    name_es:
      'Divulgación de si y cómo el objetivo se relaciona con la gestión de impactos, riesgos y oportunidades materiales relacionados con áreas en riesgo hídrico.',
    name_pt:
      'Divulgação de se e como a meta se relaciona com a gestão de impactos, riscos e oportunidades materiais relacionados a áreas com risco hídrico'
  },
  {
    id: '1940a8af-e62a-47ee-8fd0-86634cca8a2c',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_responsible_management_of_marine_resources_impacts,_risks_and_opportunities',
    datapoint_id: 'E3-3_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to responsible management of marine resources impacts, risks and opportunities',
    name_es:
      'Divulgación de si la meta se relaciona con la gestión responsable de los impactos, riesgos y oportunidades de los recursos marinos y de qué manera.',
    name_pt:
      'Divulgação de se e como a meta se relaciona com a gestão responsável dos impactos, riscos e oportunidades dos recursos marinhos'
  },
  {
    id: 'b853f29f-8b4b-431d-a5bc-881db2ece8c5',
    name_dcycle: 'disclosure_of_whether_and_how_target_relates_to_reduction_of_water_consumption',
    datapoint_id: 'E3-3_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of whether and how target relates to reduction of water consumption',
    name_es:
      'Divulgación de si el objetivo se relaciona con la reducción del consumo de agua y cómo',
    name_pt: 'Divulgação sobre se e como a meta está relacionada à redução do consumo de água'
  },
  {
    id: '911cbb4c-0665-404e-843c-37dace647174',
    name_dcycle:
      '(local)_ecological_threshold_and_entity-specific_allocation_were_taken_into_consideration_when_setting_water_and_marine_resources_target',
    datapoint_id: 'E3-3_04',
    esg_category: 'environmental',
    name_en:
      '(Local) ecological threshold and entity-specific allocation were taken into consideration when setting water and marine resources target',
    name_es:
      'Se tuvieron en cuenta el umbral ecológico (local) y la asignación específica de la entidad al establecer el objetivo de recursos hídricos y marinos.',
    name_pt:
      'O limiar ecológico (local) e a alocação específica da entidade foram levados em consideração ao definir a meta para os recursos hídricos e marinhos'
  },
  {
    id: '8a925db2-1500-4f27-b40b-39903467cbec',
    name_dcycle:
      'disclosure_of_ecological_threshold_identified_and_methodology_used_to_identify_ecological_threshold_(water_and_marine_resources)',
    datapoint_id: 'E3-3_05',
    esg_category: 'environmental',
    name_en:
      'Disclosure of ecological threshold identified and methodology used to identify ecological threshold (water and marine resources)',
    name_es:
      'Divulgación del umbral ecológico identificado y metodología utilizada para identificar el umbral ecológico (agua y recursos marinos)',
    name_pt:
      'Divulgação do limiar ecológico identificado e metodologia utilizada para identificar o limiar ecológico (recursos hídricos e marinhos)'
  },
  {
    id: '9dd66db8-6ac0-49ea-aa87-61ca6ab09d48',
    name_dcycle:
      'disclosure_of_how_ecological_entity-specific_threshold_was_determined_(water_and_marine_resources)',
    datapoint_id: 'E3-3_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how ecological entity-specific threshold was determined (water and marine resources)',
    name_es:
      'Divulgación de cómo se determinó el umbral ecológico específico de la entidad (agua y recursos marinos)',
    name_pt:
      'Divulgação de como foi determinado o limite específico da entidade ecológica (recursos hídricos e marinhos)'
  },
  {
    id: '796fd32e-41c2-45b6-a22f-18910850d5a5',
    name_dcycle:
      'disclosure_of_how_responsibility_for_respecting_identified_ecological_threshold_is_allocated_(water_and_marine_resources)',
    datapoint_id: 'E3-3_07',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how responsibility for respecting identified ecological threshold is allocated (water and marine resources)',
    name_es:
      'Divulgación de cómo se asigna la responsabilidad de respetar el umbral ecológico identificado (agua y recursos marinos)',
    name_pt:
      'Divulgação de como é atribuída a responsabilidade pelo respeito do limiar ecológico identificado (recursos hídricos e marinhos)'
  },
  {
    id: 'a2c08482-b447-4505-85de-250517e51927',
    name_dcycle:
      'adopted_and_presented_water_and_marine_resources-related_target_is_mandatory_(based_on_legislation)',
    datapoint_id: 'E3-3_08',
    esg_category: 'environmental',
    name_en:
      'Adopted and presented water and marine resources-related target is mandatory (based on legislation)',
    name_es:
      'La meta adoptada y presentada relacionada con el agua y los recursos marinos es obligatoria (según la legislación)',
    name_pt:
      'A meta relativa à água e aos recursos marinhos adotada e apresentada é obrigatória (com base na legislação)'
  },
  {
    id: '96ac40f7-6c09-4cc5-a3a3-5727d96cb24c',
    name_dcycle: 'target_relates_to_reduction_of_water_withdrawals',
    datapoint_id: 'E3-3_09',
    esg_category: 'environmental',
    name_en: 'Target relates to reduction of water withdrawals',
    name_es: 'El objetivo se refiere a la reducción de las extracciones de agua.',
    name_pt: 'Meta diz respeito à redução de captação de água'
  },
  {
    id: '73c9888d-341f-44c8-885d-a9d355f95bab',
    name_dcycle: 'target_relates_to_reduction_of_water_discharges',
    datapoint_id: 'E3-3_10',
    esg_category: 'environmental',
    name_en: 'Target relates to reduction of water discharges',
    name_es: 'El objetivo se refiere a la reducción de los vertidos de agua.',
    name_pt: 'Meta refere-se à redução de descargas de água'
  },
  {
    id: '4b11dab6-a5d2-404e-bb53-dd6c1620b5fb',
    name_dcycle: 'total_water_consumption',
    datapoint_id: 'E3-4_01',
    esg_category: 'environmental',
    name_en: 'Total water consumption',
    name_es: 'Consumo total de agua',
    name_pt: 'Consumo total de água'
  },
  {
    id: 'c60af1e6-4137-4d6f-ab0d-738c94484ceb',
    name_dcycle:
      'total_water_consumption_in_areas_at_water_risk,_including_areas_of_high-water_stress',
    datapoint_id: 'E3-4_02',
    esg_category: 'environmental',
    name_en: 'Total water consumption in areas at water risk, including areas of high-water stress',
    name_es:
      'Consumo total de agua en áreas con riesgo hídrico, incluidas áreas de alto estrés hídrico',
    name_pt:
      'Consumo total de água em áreas com risco hídrico, incluindo áreas com alto estresse hídrico'
  },
  {
    id: 'd46b75d0-36e9-48f2-bb48-50cf8f82e837',
    name_dcycle: 'total_water_recycled_and_reused',
    datapoint_id: 'E3-4_03',
    esg_category: 'environmental',
    name_en: 'Total water recycled and reused',
    name_es: 'Agua total reciclada y reutilizada',
    name_pt: 'Total de água reciclada e reutilizada'
  },
  {
    id: 'c5ad93ce-b879-4cc3-be2c-e0534b51cd6d',
    name_dcycle: 'total_water_stored',
    datapoint_id: 'E3-4_04',
    esg_category: 'environmental',
    name_en: 'Total water stored',
    name_es: 'Agua total almacenada',
    name_pt: 'Total de água armazenada'
  },
  {
    id: 'ec11330a-ef0b-455f-8037-6b0c27da84a7',
    name_dcycle: 'changes_in_water_storage',
    datapoint_id: 'E3-4_05',
    esg_category: 'environmental',
    name_en: 'Changes in water storage',
    name_es: 'Cambios en el almacenamiento de agua.',
    name_pt: 'Mudanças no armazenamento de água'
  },
  {
    id: '05e91cc2-c17b-4882-b749-778bc3c69297',
    name_dcycle: 'disclosure_of_contextual_information_regarding_warter_consumption',
    datapoint_id: 'E3-4_06',
    esg_category: 'environmental',
    name_en: 'Disclosure of contextual information regarding warter consumption',
    name_es: 'Divulgación de información contextual sobre el consumo de agua.',
    name_pt: 'Divulgação de informações contextuais sobre o consumo de guerra'
  },
  {
    id: '00698cd2-a49e-4d0f-87d5-3adc2a8d4369',
    name_dcycle:
      'share_of_the_measure_obtained_from_direct_measurement,_from_sampling_and_extrapolation,_or_from_best_estimates',
    datapoint_id: 'E3-4_07',
    esg_category: 'environmental',
    name_en:
      'Share of the measure obtained from direct measurement, from sampling and extrapolation, or from best estimates',
    name_es:
      'Proporción de la medida obtenida de la medición directa, del muestreo y la extrapolación, o de las mejores estimaciones',
    name_pt:
      'Parcela da medida obtida por medição direta, por amostragem e extrapolação ou por melhores estimativas'
  },
  {
    id: '2b6068d5-e592-4aef-aea1-173e218686cc',
    name_dcycle: 'water_intensity_ratio',
    datapoint_id: 'E3-4_08',
    esg_category: 'environmental',
    name_en: 'Water intensity ratio',
    name_es: 'Relación de intensidad del agua',
    name_pt: 'Razão de intensidade de água'
  },
  {
    id: '4648a9e6-dea0-47a4-90f1-316fc7eb7cee',
    name_dcycle: 'water_consumption_-_sectors/segments_[table]',
    datapoint_id: 'E3-4_09',
    esg_category: 'environmental',
    name_en: 'Water consumption - sectors/SEGMENTS [table]',
    name_es: 'Consumo de agua - sectores/SEGMENTOS [tabla]',
    name_pt: 'Consumo de água – setores/SEGMENTOS [tabela]'
  },
  {
    id: 'e4ad0a78-c4e7-4875-bcbb-4a6c4a7f27a2',
    name_dcycle: 'additional_water_intensity_ratio',
    datapoint_id: 'E3-4_10',
    esg_category: 'environmental',
    name_en: 'Additional water intensity ratio',
    name_es: 'Relación de intensidad de agua adicional',
    name_pt: 'Proporção de intensidade de água adicional'
  },
  {
    id: '25bdc5d9-269a-4379-b4eb-98201c3a25b3',
    name_dcycle: 'total_water_withdrawals',
    datapoint_id: 'E3-4_11',
    esg_category: 'environmental',
    name_en: 'Total water withdrawals',
    name_es: 'Extracción total de agua',
    name_pt: 'Total de retiradas de água'
  },
  {
    id: '2eea7ecf-2a28-4ef3-a2b0-6f4ee9af2205',
    name_dcycle: 'total_water_discharges',
    datapoint_id: 'E3-4_12',
    esg_category: 'environmental',
    name_en: 'Total water discharges',
    name_es: 'Vertidos totales de agua',
    name_pt: 'Descargas totais de água'
  },
  {
    id: '38ff58ab-04d8-4566-b078-6231a5444f09',
    name_dcycle:
      'disclosure_of_quantitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_water_and_marine_resources-related_impacts',
    datapoint_id: 'E3-5_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of quantitative information about anticipated financial effects of material risks and opportunities arising from water and marine resources-related impacts',
    name_es:
      'Divulgación de información cuantitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos relacionados con el agua y los recursos marinos.',
    name_pt:
      'Divulgação de informações quantitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos relacionados à água e aos recursos marinhos'
  },
  {
    id: '4218e6aa-a1cd-4282-a7fa-cbb0ce803f37',
    name_dcycle:
      'disclosure_of_qualitative_information_of_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_water_and_marine_resources-related_impacts',
    datapoint_id: 'E3-5_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of qualitative information of anticipated financial effects of material risks and opportunities arising from water and marine resources-related impacts',
    name_es:
      'Divulgación de información cualitativa de los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos relacionados con el agua y los recursos marinos.',
    name_pt:
      'Divulgação de informações qualitativas sobre efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos relacionados à água e aos recursos marinhos'
  },
  {
    id: 'd6c3acf2-ac20-419c-bffd-a1260b911039',
    name_dcycle:
      'description_of_effects_considered_and_related_impacts_(water_and_marine_resources)',
    datapoint_id: 'E3-5_03',
    esg_category: 'environmental',
    name_en: 'Description of effects considered and related impacts (water and marine resources)',
    name_es:
      'Descripción de los efectos considerados e impactos relacionados (agua y recursos marinos)',
    name_pt:
      'Descrição dos efeitos considerados e impactos relacionados (recursos hídricos e marinhos)'
  },
  {
    id: '0423db98-3b01-4730-9eaf-d2412af95f8b',
    name_dcycle:
      'disclosure_of_critical_assumptions_used_in_estimates_of_financial_effects_of_material_risks_and_opportunities_arising_from_water_and_marine_resources-related_impacts',
    datapoint_id: 'E3-5_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of critical assumptions used in estimates of financial effects of material risks and opportunities arising from water and marine resources-related impacts',
    name_es:
      'Divulgación de supuestos críticos utilizados en las estimaciones de los efectos financieros de los riesgos y oportunidades materiales que surgen de los impactos relacionados con el agua y los recursos marinos.',
    name_pt:
      'Divulgação de premissas críticas utilizadas nas estimativas de efeitos financeiros de riscos e oportunidades materiais decorrentes de impactos relacionados à água e aos recursos marinhos'
  },
  {
    id: '733343f1-9446-4607-89fb-27a08b927369',
    name_dcycle:
      'description_of_related_products_and_services_at_risk_(water_and_marine_resources)',
    datapoint_id: 'E3-5_05',
    esg_category: 'environmental',
    name_en: 'Description of related products and services at risk (water and marine resources)',
    name_es:
      'Descripción de productos y servicios relacionados en riesgo (agua y recursos marinos)',
    name_pt:
      'Descrição dos produtos e serviços relacionados em risco (recursos hídricos e marinhos)'
  },
  {
    id: '863d342d-3262-4bd0-ac6f-7810d3cd6a4e',
    name_dcycle:
      'explanation_of_how_time_horizons_are_defined,_financial_amounts_are_estimated_and_critical_assumptions_made_(water_and_marine_resources)',
    datapoint_id: 'E3-5_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of how time horizons are defined, financial amounts are estimated and critical assumptions made (water and marine resources)',
    name_es:
      'Explicación de cómo se definen los horizontes temporales, se estiman los montos financieros y se hacen los supuestos críticos (recursos hídricos y marinos)',
    name_pt:
      'Explicação de como os horizontes temporais são definidos, os montantes financeiros são estimados e as suposições críticas são feitas (recursos hídricos e marinhos)'
  },
  {
    id: '19846a66-691e-4779-bfd3-9bba6ca2ffa2',
    name_dcycle: 'list_of_material_sites_in_own_operation',
    datapoint_id: 'E4.SBM-3_01',
    esg_category: 'environmental',
    name_en: 'List of material sites in own operation',
    name_es: 'Lista de sitios de materiales en operación propia',
    name_pt: 'Lista de sites de materiais em operação própria'
  },
  {
    id: 'e8038ab9-0c04-4ac8-b893-6c2fb301e847',
    name_dcycle: 'disclosure_of_activities_negatively_affecting_biodiversity_sensitive_areeas',
    datapoint_id: 'E4.SBM-3_02',
    esg_category: 'environmental',
    name_en: 'Disclosure of activities negatively affecting biodiversity sensitive areeas',
    name_es:
      'Divulgación de actividades que afectan negativamente áreas sensibles a la biodiversidad',
    name_pt: 'Divulgação de atividades que afetam negativamente áreas sensíveis à biodiversidade'
  },
  {
    id: 'a8e9e925-e315-4e1d-84af-87a1ffb8d90b',
    name_dcycle:
      'disclosure_of_list_of_material_sites_in_own_operations_based_on_results_of_identification_and_assessment_of_actual_and_potential_impacts_on_biodiversity_and_ecosystems',
    datapoint_id: 'E4.SBM-3_03',
    esg_category: 'environmental',
    name_en:
      'Disclosure of list of material sites in own operations based on results of identification and assessment of actual and potential impacts on biodiversity and ecosystems',
    name_es:
      'Divulgación de la lista de sitios materiales en operaciones propias con base en los resultados de la identificación y evaluación de los impactos reales y potenciales sobre la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação de lista de locais materiais em operações próprias com base em resultados de identificação e avaliação de impactos reais e potenciais na biodiversidade e nos ecossistemas'
  },
  {
    id: 'ff8d4d20-6c1e-4d83-933f-5a10cfa4bbd2',
    name_dcycle: 'disclosure_of_biodiversity-sensitive_areas_impacted',
    datapoint_id: 'E4.SBM-3_04',
    esg_category: 'environmental',
    name_en: 'Disclosure of biodiversity-sensitive areas impacted',
    name_es: 'Divulgación de áreas sensibles a la biodiversidad impactadas',
    name_pt: 'Divulgação de áreas sensíveis à biodiversidade impactadas'
  },
  {
    id: 'f2f637d9-dcfd-4fbb-a8eb-930e0dc0c735',
    name_dcycle:
      'material_negative_impacts_with_regards_to_land_degradation,_desertification_or_soil_sealing_have_been_identified',
    datapoint_id: 'E4.SBM-3_05',
    esg_category: 'environmental',
    name_en:
      'Material negative impacts with regards to land degradation, desertification or soil sealing have been identified',
    name_es:
      'Se han identificado impactos materiales negativos en relación con la degradación de la tierra, la desertificación o el sellado del suelo.',
    name_pt:
      'Foram identificados impactos negativos materiais no que diz respeito à degradação dos solos, à desertificação ou à impermeabilização dos solos'
  },
  {
    id: 'f92ce1da-9031-4c65-82fe-faca7d10293f',
    name_dcycle: 'own_operations_affect_threatened_species',
    datapoint_id: 'E4.SBM-3_06',
    esg_category: 'environmental',
    name_en: 'Own operations affect threatened species',
    name_es: 'Operaciones propias afectan a especies amenazadas',
    name_pt: 'Operações próprias afetam espécies ameaçadas'
  },
  {
    id: '40cf7c86-e52d-4004-a79a-c761ba9b9a22',
    name_dcycle:
      'disclosure_of_whether_and_how_actual_and_potential_impacts_on_biodiversity_and_ecosystems_at_own_site_locations_and_in_value_chain_have_been_identified_and_assessed',
    datapoint_id: 'E4.IRO-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how actual and potential impacts on biodiversity and ecosystems at own site locations and in value chain have been identified and assessed',
    name_es:
      'Divulgación de si y cómo se han identificado y evaluado los impactos reales y potenciales sobre la biodiversidad y los ecosistemas en las ubicaciones del propio sitio y en la cadena de valor.',
    name_pt:
      'Divulgação de se e como os impactos reais e potenciais sobre a biodiversidade e os ecossistemas nas próprias instalações e na cadeia de valor foram identificados e avaliados'
  },
  {
    id: 'fd648151-6a01-4519-ba35-8123e0cc4b54',
    name_dcycle:
      'disclosure_of_whether_and_how_dependencies_on_biodiversity_and_ecosystems_and_their_services_have_been_identified_and_assessed_at_own_site_locations_and_in_value_chain',
    datapoint_id: 'E4.IRO-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how dependencies on biodiversity and ecosystems and their services have been identified and assessed at own site locations and in value chain',
    name_es:
      'Divulgación de si y cómo se han identificado y evaluado las dependencias de la biodiversidad y los ecosistemas y sus servicios en las ubicaciones del propio sitio y en la cadena de valor.',
    name_pt:
      'Divulgação de se e como as dependências da biodiversidade e dos ecossistemas e dos seus serviços foram identificadas e avaliadas nas próprias instalações e na cadeia de valor'
  },
  {
    id: '4f8b9aca-66af-4068-a72d-6b91b13f4e5f',
    name_dcycle:
      'disclosure_of_whether_and_how_transition_and_physical_risks_and_opportunities_related_to_biodiversity_and_ecosystems_have_been_identified_and_assessed',
    datapoint_id: 'E4.IRO-1_03',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how transition and physical risks and opportunities related to biodiversity and ecosystems have been identified and assessed',
    name_es:
      'Divulgación de si se han identificado y evaluado los riesgos y oportunidades físicos y de transición relacionados con la biodiversidad y los ecosistemas, y de qué manera',
    name_pt:
      'Divulgação sobre se e como os riscos e oportunidades físicas e de transição relacionados com a biodiversidade e os ecossistemas foram identificados e avaliados'
  },
  {
    id: '91934419-9c8f-43c2-ba58-2a4c9ad44434',
    name_dcycle:
      'disclosure_of_whether_and_how_systemic_risks_have_been_considered_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4.IRO-1_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how systemic risks have been considered (biodiversity and ecosystems)',
    name_es:
      'Divulgación de si se han considerado los riesgos sistémicos (biodiversidad y ecosistemas) y cómo',
    name_pt:
      'Divulgação sobre se e como os riscos sistêmicos foram considerados (biodiversidade e ecossistemas)'
  },
  {
    id: '64b5749f-2977-49a4-a99b-fd17fa0a4952',
    name_dcycle:
      'disclosure_of_whether_and_how_consultations_with_affected_communities_on_sustainability_assessments_of_shared_biological_resources_and_ecosystems_have_been_conducted',
    datapoint_id: 'E4.IRO-1_05',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how consultations with affected communities on sustainability assessments of shared biological resources and ecosystems have been conducted',
    name_es:
      'Divulgación de si se han realizado consultas con las comunidades afectadas sobre evaluaciones de sostenibilidad de los recursos biológicos y ecosistemas compartidos y cómo',
    name_pt:
      'Divulgação sobre se e como foram realizadas consultas com as comunidades afetadas sobre avaliações de sustentabilidade de recursos biológicos e ecossistemas compartilhados'
  },
  {
    id: 'a97006d6-e8a9-4e41-94b4-dbb207d8f8d1',
    name_dcycle:
      'disclosure_of_whether_and_how_specific_sites,_raw_materials_production_or_sourcing_with_negative_or_potential_negative_impacts_on_affected_communities',
    datapoint_id: 'E4.IRO-1_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how specific sites, raw materials production or sourcing with negative or potential negative impacts on affected communities',
    name_es:
      'Divulgación de si sitios específicos, producción o abastecimiento de materias primas tienen impactos negativos o potenciales negativos en las comunidades afectadas y cómo.',
    name_pt:
      'Divulgação de se e como locais específicos, produção ou fornecimento de matérias-primas têm impactos negativos ou potenciais negativos nas comunidades afetadas'
  },
  {
    id: '0cf3622a-b0a4-429a-b14d-9089eeaba8f2',
    name_dcycle:
      'disclosure_of_whether_and_how_communities_were_involved_in_materiality_assessment',
    datapoint_id: 'E4.IRO-1_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of whether and how communities were involved in materiality assessment',
    name_es:
      'Divulgación de si las comunidades participaron en la evaluación de materialidad y de qué manera',
    name_pt:
      'Divulgação sobre se e como as comunidades foram envolvidas na avaliação de materialidade'
  },
  {
    id: 'fdaafbcc-8302-4dc9-a559-659ce624141d',
    name_dcycle:
      'disclosure_of_whether_and_how_negative_impacts_on_priority_ecosystem_services_of_relevance_to_affected_communities_may_be_avoided',
    datapoint_id: 'E4.IRO-1_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how negative impacts on priority ecosystem services of relevance to affected communities may be avoided',
    name_es:
      'Divulgación de si se pueden evitar, y cómo, los impactos negativos en los servicios ecosistémicos prioritarios de relevancia para las comunidades afectadas.',
    name_pt:
      'Divulgação de se e como os impactos negativos nos serviços ecossistêmicos prioritários de relevância para as comunidades afetadas podem ser evitados'
  },
  {
    id: '2451f839-0be4-4e09-800c-79fbda967565',
    name_dcycle:
      'disclosure_of_plans_to_minimise_unavoidable_negative_impacts_and_implement_mitigation_measures_that_aim_to_maintain_value_and_functionality_of_priority_services',
    datapoint_id: 'E4.IRO-1_09',
    esg_category: 'environmental',
    name_en:
      'Disclosure of plans to minimise unavoidable negative impacts and implement mitigation measures that aim to maintain value and functionality of priority services',
    name_es:
      'Divulgación de planes para minimizar los impactos negativos inevitables e implementar medidas de mitigación que tengan como objetivo mantener el valor y la funcionalidad de los servicios prioritarios.',
    name_pt:
      'Divulgação de planos para minimizar impactos negativos inevitáveis ​​e implementar medidas de mitigação que visam manter o valor e a funcionalidade dos serviços prioritários'
  },
  {
    id: 'cff70ff5-b136-42da-873a-6f7a2e0f9a86',
    name_dcycle:
      'disclosure_of_whether_and_how_tthe_business_model(s)_has_been_verified_using_range_of_biodiversity_and_ecosystems_scenarios,_or_other_scenarios_with_modelling_of_biodiversity_and_ecosystems_related_consequences,_with_different_possible_pathways',
    datapoint_id: 'E4.IRO-1_10',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how tthe business model(s) has been verified using range of biodiversity and ecosystems scenarios, or other scenarios with modelling of biodiversity and ecosystems related consequences, with different possible pathways',
    name_es:
      'Divulgación de si y cómo se han verificado los modelos de negocio utilizando una variedad de escenarios de biodiversidad y ecosistemas, u otros escenarios con modelado de consecuencias relacionadas con la biodiversidad y los ecosistemas, con diferentes caminos posibles.',
    name_pt:
      'Divulgação de se e como o(s) modelo(s) de negócios foram verificados usando uma variedade de cenários de biodiversidade e ecossistemas, ou outros cenários com modelagem de consequências relacionadas à biodiversidade e aos ecossistemas, com diferentes caminhos possíveis'
  },
  {
    id: 'f35f662e-c22a-4991-b2e0-cfa4234d143f',
    name_dcycle: 'disclosure_of_why_considered_scenarios_were_taken_into_consideration',
    datapoint_id: 'E4.IRO-1_11',
    esg_category: 'environmental',
    name_en: 'Disclosure of why considered scenarios were taken into consideration',
    name_es: 'Divulgación de por qué se tomaron en consideración los escenarios considerados',
    name_pt: 'Divulgação do motivo pelo qual os cenários considerados foram levados em consideração'
  },
  {
    id: '97f6dff2-9a92-4aac-bcae-c421057205ee',
    name_dcycle:
      'disclosure_of_how_considered_scenarios_are_updated_according_to_evolving_conditions_and_emerging_trends',
    datapoint_id: 'E4.IRO-1_12',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how considered scenarios are updated according to evolving conditions and emerging trends',
    name_es:
      'Divulgación de cómo se actualizan los escenarios considerados de acuerdo con las condiciones cambiantes y las tendencias emergentes.',
    name_pt:
      'Divulgação de como os cenários considerados são atualizados de acordo com a evolução das condições e tendências emergentes'
  },
  {
    id: '6a227195-dab8-4972-ad1a-adbd0e51844c',
    name_dcycle:
      'scenarios_are_informed_by_expectations_in_authoritative_intergovernmental_instruments_and_by_scientific_consensus',
    datapoint_id: 'E4.IRO-1_13',
    esg_category: 'environmental',
    name_en:
      'Scenarios are informed by expectations in authoritative intergovernmental instruments and by scientific consensus',
    name_es:
      'Los escenarios se basan en las expectativas contenidas en instrumentos intergubernamentales autorizados y en el consenso científico.',
    name_pt:
      'Os cenários são informados pelas expectativas em instrumentos intergovernamentais autorizados e pelo consenso científico'
  },
  {
    id: '060baf02-93b3-4710-8626-d308ee166969',
    name_dcycle: 'undertaking_has_sites_located_in_or_near_biodiversity-sensitive_areas',
    datapoint_id: 'E4.IRO-1_14',
    esg_category: 'environmental',
    name_en: 'Undertaking has sites located in or near biodiversity-sensitive areas',
    name_es: 'La empresa tiene sitios ubicados en o cerca de áreas sensibles a la biodiversidad',
    name_pt: 'A empresa possui locais localizados em ou perto de áreas sensíveis à biodiversidade'
  },
  {
    id: '95f7511b-d9f1-44be-992f-f6e6ddff1250',
    name_dcycle:
      'activities_related_to_sites_located_in_or_near_biodiversity-sensitive_areas_negatively_affect_these_areas_by_leading_to_deterioration_of_natural_habitats_and_habitats_of_species_and_to_disturbance_of_species_for_which_protected_area_has_been_designated',
    datapoint_id: 'E4.IRO-1_15',
    esg_category: 'environmental',
    name_en:
      'Activities related to sites located in or near biodiversity-sensitive areas negatively affect these areas by leading to deterioration of natural habitats and habitats of species and to disturbance of species for which protected area has been designated',
    name_es:
      'Las actividades relacionadas con sitios ubicados en o cerca de áreas sensibles a la biodiversidad afectan negativamente a estas áreas al provocar el deterioro de los hábitats naturales y de los hábitats de las especies y la perturbación de las especies para las cuales se ha designado un área protegida.',
    name_pt:
      'As atividades relacionadas com sítios localizados em ou perto de zonas sensíveis à biodiversidade afetam negativamente essas zonas, conduzindo à deterioração dos habitats naturais e dos habitats das espécies e à perturbação das espécies para as quais foram designadas áreas protegidas'
  },
  {
    id: '94b5dc79-282b-458f-b28b-b4eea269478e',
    name_dcycle:
      'it_has_been_concluded_that_it_is_necessary_to_implement_biodiversity_mitigation_measures',
    datapoint_id: 'E4.IRO-1_16',
    esg_category: 'environmental',
    name_en:
      'It has been concluded that it is necessary to implement biodiversity mitigation measures',
    name_es:
      'Se ha concluido que es necesario implementar medidas de mitigación de la biodiversidad',
    name_pt: 'Concluiu-se que é necessário implementar medidas de mitigação da biodiversidade'
  },
  {
    id: '11891f4f-a926-4cfc-a915-709d8682c6c4',
    name_dcycle:
      'disclosure_of_resilience_of_current_business_model(s)_and_strategy_to_biodiversity_and_ecosystems-related_physical,_transition_and_systemic_risks_and_opportunities',
    datapoint_id: 'E4-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of resilience of current business model(s) and strategy to biodiversity and ecosystems-related physical, transition and systemic risks and opportunities',
    name_es:
      'Divulgación de la resiliencia de los modelos de negocio actuales y la estrategia ante los riesgos y oportunidades físicos, de transición y sistémicos relacionados con la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação da resiliência do(s) atual(is) modelo(s) de negócios e estratégia para a biodiversidade e riscos e oportunidades físicos, de transição e sistêmicos relacionados aos ecossistemas'
  },
  {
    id: '943d42ce-11c7-41dd-84fa-2b1918e41e80',
    name_dcycle:
      'disclosure_of_scope_of_resilience_analysis_along_own_operations_and_related_upstream_and_downstream_value_chain',
    datapoint_id: 'E4-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of scope of resilience analysis along own operations and related upstream and downstream value chain',
    name_es:
      'Divulgación del alcance del análisis de resiliencia a lo largo de las operaciones propias y la cadena de valor ascendente y descendente relacionada.',
    name_pt:
      'Divulgação do escopo da análise de resiliência ao longo das próprias operações e da cadeia de valor upstream e downstream relacionada'
  },
  {
    id: '3741d9b9-a268-4e7a-82f1-d49a37587ee9',
    name_dcycle: 'disclosure_of_key_assumptions_made_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of key assumptions made (biodiversity and ecosystems)',
    name_es: 'Divulgación de supuestos clave formulados (biodiversidad y ecosistemas)',
    name_pt: 'Divulgação das principais suposições feitas (biodiversidade e ecossistemas)'
  },
  {
    id: 'fbd13420-ca4f-49f9-bde4-401f1ce36617',
    name_dcycle: 'disclosure_of_time_horizons_used_for_analysis_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_04',
    esg_category: 'environmental',
    name_en: 'Disclosure of time horizons used for analysis (biodiversity and ecosystems)',
    name_es:
      'Divulgación de los horizontes temporales utilizados para el análisis (biodiversidad y ecosistemas)',
    name_pt:
      'Divulgação dos horizontes temporais utilizados para análise (biodiversidade e ecossistemas)'
  },
  {
    id: '2f7c23fe-301d-4766-9f3f-7d04b80c9af1',
    name_dcycle: 'disclosure_of_results_of_resilience_analysis_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_05',
    esg_category: 'environmental',
    name_en: 'Disclosure of results of resilience analysis (biodiversity and ecosystems)',
    name_es: 'Divulgación de resultados de análisis de resiliencia (biodiversidad y ecosistemas)',
    name_pt: 'Divulgação dos resultados da análise de resiliência (biodiversidade e ecossistemas)'
  },
  {
    id: '9889457a-ec84-4e01-9354-68552e3ba6b7',
    name_dcycle: 'disclosure_of_involvement_of_stakeholders_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_06',
    esg_category: 'environmental',
    name_en: 'Disclosure of involvement of stakeholders (biodiversity and ecosystems)',
    name_es:
      'Divulgación de la participación de las partes interesadas (biodiversidad y ecosistemas)',
    name_pt: 'Divulgação do envolvimento das partes interessadas (biodiversidade e ecossistemas)'
  },
  {
    id: '15135d77-3632-4702-860a-6b51a85eedc5',
    name_dcycle:
      'disclosure_of_transition_plan_to_improve_and_achieve_alignment_of_its_business_model_and_strategy',
    datapoint_id: 'E4-1_07',
    esg_category: 'environmental',
    name_en:
      'Disclosure of transition plan to improve and achieve alignment of its business model and strategy',
    name_es:
      'Divulgación del plan de transición para mejorar y lograr la alineación de su modelo de negocio y estrategia.',
    name_pt:
      'Divulgação do plano de transição para melhorar e alinhar seu modelo de negócios e estratégia'
  },
  {
    id: 'a4e805aa-6a8b-4aec-9a45-52ef4d36bc66',
    name_dcycle:
      'explanation_of_how_strategy_and_business_model_will_be_adjusted_to_improve_and,_ultimately,_achieve_alignment_with_relevant_local,_national_and_global_public_policy_goals',
    datapoint_id: 'E4-1_08',
    esg_category: 'environmental',
    name_en:
      'Explanation of how strategy and business model will be adjusted to improve and, ultimately, achieve alignment with relevant local, national and global public policy goals',
    name_es:
      'Explicación de cómo se ajustarán la estrategia y el modelo de negocio para mejorar y, en última instancia, lograr la alineación con los objetivos relevantes de políticas públicas locales, nacionales y globales.',
    name_pt:
      'Explicação de como a estratégia e o modelo de negócios serão ajustados para melhorar e, em última análise, alcançar o alinhamento com os objetivos relevantes de políticas públicas locais, nacionais e globais'
  },
  {
    id: '6353f482-ce18-4822-a3e3-07c41535fa36',
    name_dcycle:
      'include_information_about_its_own_operations_and_explain_how_it_is_responding_to_material_impacts_in_its_related_value_chain',
    datapoint_id: 'E4-1_09',
    esg_category: 'environmental',
    name_en:
      'Include information about its own operations and explain how it is responding to material impacts in its related value chain',
    name_es:
      'Incluir información sobre sus propias operaciones y explicar cómo está respondiendo a los impactos materiales en su cadena de valor relacionada.',
    name_pt:
      'Incluir informações sobre suas próprias operações e explicar como está respondendo aos impactos materiais em sua cadeia de valor relacionada'
  },
  {
    id: '3da0a678-b238-4e03-b2aa-8b6a895a001a',
    name_dcycle: 'explanation_of_how_b_strategy_interacts_with_transition_plan',
    datapoint_id: 'E4-1_10',
    esg_category: 'environmental',
    name_en: 'Explanation of how b strategy interacts with transition plan',
    name_es: 'Explicación de cómo interactúa la estrategia b con el plan de transición',
    name_pt: 'Explicação de como a estratégia b interage com o plano de transição'
  },
  {
    id: '05cdb3e0-76ce-4e62-97b9-7f28fead9f56',
    name_dcycle:
      'disclosure_of_contribution_to_impact_drivers_and_possible_mitigation_actions_following_mitigation_hierarchy_and_main_path-dependencies_and_locked-in_assets_and_resources_that_are_associated_with_biodiversity_and_ecosystems_change',
    datapoint_id: 'E4-1_11',
    esg_category: 'environmental',
    name_en:
      'Disclosure of contribution to impact drivers and possible mitigation actions following mitigation hierarchy and main path-dependencies and locked-in assets and resources that are associated with biodiversity and ecosystems change',
    name_es:
      'Divulgación de la contribución a los impulsores del impacto y posibles acciones de mitigación siguiendo la jerarquía de mitigación y las principales dependencias de la ruta y los activos y recursos bloqueados que están asociados con el cambio de la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação da contribuição para os fatores de impacto e possíveis ações de mitigação seguindo a hierarquia de mitigação e as principais dependências de trajetória e ativos e recursos bloqueados que estão associados à biodiversidade e à mudança dos ecossistemas'
  },
  {
    id: 'f874124b-53c7-4331-b62c-aa6fda72d569',
    name_dcycle:
      'explanation_and_quantification_of_investments_and_funding_supporting_the_implementation_of_its_transition_plan',
    datapoint_id: 'E4-1_12',
    esg_category: 'environmental',
    name_en:
      'Explanation and quantification of investments and funding supporting the implementation of its transition plan',
    name_es:
      'Explicación y cuantificación de las inversiones y financiación que apoyan la implementación de su plan de transición.',
    name_pt:
      'Explicação e quantificação dos investimentos e financiamentos de apoio à implementação do seu plano de transição'
  },
  {
    id: 'd2a3c021-0b16-41ec-9446-6986f31eea21',
    name_dcycle:
      'disclosure_of_objectives_or_plans_for_aligning_economic_activities_(revenues,_capex)',
    datapoint_id: 'E4-1_13',
    esg_category: 'environmental',
    name_en: 'Disclosure of objectives or plans for aligning economic activities (revenues, CapEx)',
    name_es:
      'Divulgación de objetivos o planes para alinear actividades económicas (ingresos, CapEx)',
    name_pt:
      'Divulgação de objetivos ou planos de alinhamento das atividades económicas (receitas, CapEx)'
  },
  {
    id: '4fb11269-84ff-474d-a502-d1696e6f57ad',
    name_dcycle: 'biodiversity_offsets_are_part_of_transition_plan',
    datapoint_id: 'E4-1_14',
    esg_category: 'environmental',
    name_en: 'Biodiversity offsets are part of transition plan',
    name_es: 'Las compensaciones por biodiversidad son parte del plan de transición',
    name_pt: 'As compensações de biodiversidade fazem parte do plano de transição'
  },
  {
    id: '8771abe1-eb32-475d-a510-06713a549be6',
    name_dcycle:
      'information_about_how_process_of_implementing_and_updating_transition_plan_is_managed',
    datapoint_id: 'E4-1_15',
    esg_category: 'environmental',
    name_en:
      'Information about how process of implementing and updating transition plan is managed',
    name_es:
      'Información sobre cómo se gestiona el proceso de implementación y actualización del plan de transición.',
    name_pt:
      'Informações sobre como é gerenciado o processo de implementação e atualização do plano de transição'
  },
  {
    id: 'b1d1b355-30fb-47de-96ea-e1e96cfd506e',
    name_dcycle:
      'indication_of_metrics_and_related_tools_used_to_measure_progress_that_are_integrated_in_measurement_approach_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_16',
    esg_category: 'environmental',
    name_en:
      'Indication of metrics and related tools used to measure progress that are integrated in measurement approach (biodiversity and ecosystems)',
    name_es:
      'Indicación de métricas y herramientas relacionadas utilizadas para medir el progreso que están integradas en el enfoque de medición (biodiversidad y ecosistemas)',
    name_pt:
      'Indicação de métricas e ferramentas relacionadas usadas para medir o progresso que estão integradas na abordagem de medição (biodiversidade e ecossistemas)'
  },
  {
    id: '88ff1a1a-61b3-4fae-b721-c3bd16ed4c99',
    name_dcycle: 'administrative,_management_and_supervisory_bodies_have_approved_transition_plan',
    datapoint_id: 'E4-1_17',
    esg_category: 'environmental',
    name_en: 'Administrative, management and supervisory bodies have approved transition plan',
    name_es:
      'Los órganos de administración, dirección y supervisión han aprobado el plan de transición',
    name_pt: 'Órgãos de administração, gestão e fiscalização aprovaram plano de transição'
  },
  {
    id: '588782de-4768-4704-a144-6309b031e8c1',
    name_dcycle:
      'indication_of_current_challenges_and_limitations_to_draft_plan_in_relation_to_areas_of_significant_impact_and_actions_company_is_taking_to_address_them_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_18',
    esg_category: 'environmental',
    name_en:
      'Indication of current challenges and limitations to draft plan in relation to areas of significant impact and actions company is taking to address them (biodiversity and ecosystems)',
    name_es:
      'Indicación de los desafíos y limitaciones actuales del borrador del plan en relación con áreas de impacto significativo y las acciones que la empresa está tomando para abordarlas (biodiversidad y ecosistemas)',
    name_pt:
      'Indicação dos desafios e limitações atuais para elaborar o plano em relação a áreas de impacto significativo e ações que a empresa está tomando para enfrentá-los (biodiversidade e ecossistemas)'
  },
  {
    id: '6cc0ba57-7744-4bda-bd95-ac7c279740be',
    name_dcycle:
      'disclosure_on_whether_and_how_biodiversity_and_ecosystems-related_policies_relate_to_matters_reported_in_e4_ar4',
    datapoint_id: 'E4-2_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure on whether and how biodiversity and ecosystems-related policies relate to matters reported in E4 AR4',
    name_es:
      'Divulgación sobre si y cómo las políticas relacionadas con la biodiversidad y los ecosistemas se relacionan con los asuntos reportados en E4 AR4',
    name_pt:
      'Divulgação sobre se e como as políticas relacionadas com a biodiversidade e os ecossistemas se relacionam com as questões relatadas em E4 AR4'
  },
  {
    id: 'bfea7b15-13d9-4810-83f7-7de8aec18496',
    name_dcycle:
      'explanation_of_whether_and_how_biodiversity_and_ecosystems-related_policy_relates_to_material_biodiversity_and_ecosystems-related_impacts',
    datapoint_id: 'E4-2_02',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how biodiversity and ecosystems-related policy relates to material biodiversity and ecosystems-related impacts',
    name_es:
      'Explicación de si y cómo la política relacionada con la biodiversidad y los ecosistemas se relaciona con los impactos materiales relacionados con la biodiversidad y los ecosistemas.',
    name_pt:
      'Explicação sobre se e como a política relacionada com a biodiversidade e os ecossistemas se relaciona com a biodiversidade material e com os impactos relacionados com os ecossistemas'
  },
  {
    id: 'f63a7389-b0c4-49bf-acd3-25b571373b40',
    name_dcycle:
      'explanation_of_whether_and_how_biodiversity_and_ecosystems-related_policy_relates_to_material_dependencies_and_material_physical_and_transition_risks_and_opportunities',
    datapoint_id: 'E4-2_03',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how biodiversity and ecosystems-related policy relates to material dependencies and material physical and transition risks and opportunities',
    name_es:
      'Explicación de si y cómo la política relacionada con la biodiversidad y los ecosistemas se relaciona con dependencias materiales y riesgos y oportunidades materiales físicos y de transición.',
    name_pt:
      'Explicação sobre se e como a política relacionada à biodiversidade e aos ecossistemas se relaciona com dependências materiais e riscos e oportunidades materiais, físicos e de transição'
  },
  {
    id: '0f80d4aa-d645-4656-8233-7db6d0d78de0',
    name_dcycle:
      'explanation_of_whether_and_how_biodiversity_and_ecosystems-related_policy_supports_traceability_of_products,_components_and_raw_materials_with_significant_actual_or_potential_impacts_on_biodiversity_and_ecosystems_along_value_chain',
    datapoint_id: 'E4-2_04',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how biodiversity and ecosystems-related policy supports traceability of products, components and raw materials with significant actual or potential impacts on biodiversity and ecosystems along value chain',
    name_es:
      'Explicación de si las políticas relacionadas con la biodiversidad y los ecosistemas apoyan, y de qué manera, la trazabilidad de productos, componentes y materias primas con impactos significativos, reales o potenciales, sobre la biodiversidad y los ecosistemas a lo largo de la cadena de valor.',
    name_pt:
      'Explicação sobre se e como a política relacionada com a biodiversidade e os ecossistemas apoia a rastreabilidade de produtos, componentes e matérias-primas com impactos reais ou potenciais significativos na biodiversidade e nos ecossistemas ao longo da cadeia de valor'
  },
  {
    id: 'bca7e5fc-6a82-4e20-a921-149f89f75f78',
    name_dcycle:
      'explanation_of_whether_and_how_biodiversity_and_ecosystems-related_policy_addresses_production,_sourcing_or_consumption_from_ecosystems_that_are_managed_to_maintain_or_enhance_conditions_for_biodiversity',
    datapoint_id: 'E4-2_05',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how biodiversity and ecosystems-related policy addresses production, sourcing or consumption from ecosystems that are managed to maintain or enhance conditions for biodiversity',
    name_es:
      'Explicación de si las políticas relacionadas con la biodiversidad y los ecosistemas abordan, y de qué manera, la producción, el abastecimiento o el consumo de ecosistemas que se gestionan para mantener o mejorar las condiciones para la biodiversidad.',
    name_pt:
      'Explicação sobre se e como a política relacionada com a biodiversidade e os ecossistemas aborda a produção, o abastecimento ou o consumo de ecossistemas que são geridos para manter ou melhorar as condições para a biodiversidade'
  },
  {
    id: '54c241ec-24ef-4755-946f-33d162a90769',
    name_dcycle:
      'explanation_of_whether_and_how_biodiversity_and_ecosystems-related_policy_addresses_social_consequences_of_biodiversity_and_ecosystems-related_impacts',
    datapoint_id: 'E4-2_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how biodiversity and ecosystems-related policy addresses social consequences of biodiversity and ecosystems-related impacts',
    name_es:
      'Explicación de si las políticas relacionadas con la biodiversidad y los ecosistemas abordan las consecuencias sociales de los impactos relacionados con la biodiversidad y los ecosistemas y de qué manera',
    name_pt:
      'Explicação sobre se e como as políticas relacionadas à biodiversidade e aos ecossistemas abordam as consequências sociais da biodiversidade e dos impactos relacionados aos ecossistemas'
  },
  {
    id: 'd6694785-d0b7-45f2-97cd-802933569aac',
    name_dcycle:
      'disclosure_of_how_policy_refers_to_production,_sourcing_or_consumption_of_raw_materials',
    datapoint_id: 'E4-2_07',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how policy refers to production, sourcing or consumption of raw materials',
    name_es:
      'Divulgación de cómo la política se refiere a la producción, el abastecimiento o el consumo de materias primas.',
    name_pt:
      'Divulgação de como a política se refere à produção, fornecimento ou consumo de matérias-primas'
  },
  {
    id: '74efb5c1-6cda-4872-a05c-78bb71b6aa1d',
    name_dcycle:
      'disclosure_of_how_policy_refers_to_policies_limiting_procurement_from_suppliers_that_cannot_demonstrate_that_they_are_not_contributing_to_significant_conversion_of_protected_areas_or_key_biodiversity_areas',
    datapoint_id: 'E4-2_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how policy refers to policies limiting procurement from suppliers that cannot demonstrate that they are not contributing to significant conversion of protected areas or key biodiversity areas',
    name_es:
      'Divulgación de cómo la política se refiere a las políticas que limitan las adquisiciones de proveedores que no pueden demostrar que no están contribuyendo a una conversión significativa de áreas protegidas o áreas clave de biodiversidad.',
    name_pt:
      'Divulgação de como a política se refere a políticas que limitam a aquisição de fornecedores que não conseguem demonstrar que não estão contribuindo para a conversão significativa de áreas protegidas ou áreas-chave de biodiversidade'
  },
  {
    id: '0d32c000-65f7-4aa2-8f83-2a4e2a69ed50',
    name_dcycle:
      'disclosure_of_how_policy_refers_to_recognised_standards_or_third-party_certifications_overseen_by_regulators',
    datapoint_id: 'E4-2_09',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how policy refers to recognised standards or third-party certifications overseen by regulators',
    name_es:
      'Divulgación de cómo la política se refiere a estándares reconocidos o certificaciones de terceros supervisadas por los reguladores.',
    name_pt:
      'Divulgação de como a política se refere a padrões reconhecidos ou certificações de terceiros supervisionadas por reguladores'
  },
  {
    id: 'f2ab7b54-6786-49d6-a4aa-e7a4d1500f6d',
    name_dcycle:
      'disclosure_of_how_policy_addresses_raw_materials_originating_from_ecosystems_that_have_been_managed_to_maintain_or_enhance_conditions_for_biodiversity,_as_demonstrated_by_regular_monitoring_and_reporting_of_biodiversity_status_and_gains_or_losses',
    datapoint_id: 'E4-2_10',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how policy addresses raw materials originating from ecosystems that have been managed to maintain or enhance conditions for biodiversity, as demonstrated by regular monitoring and reporting of biodiversity status and gains or losses',
    name_es:
      'Divulgación de cómo las políticas abordan las materias primas provenientes de ecosistemas que han sido manejados para mantener o mejorar las condiciones para la biodiversidad, como lo demuestra el monitoreo y la presentación de informes periódicos sobre el estado y las ganancias o pérdidas de la biodiversidad.',
    name_pt:
      'Divulgação de como a política aborda as matérias-primas provenientes de ecossistemas que foram geridos para manter ou melhorar as condições para a biodiversidade, conforme demonstrado pela monitorização e relatórios regulares do estado da biodiversidade e dos ganhos ou perdas'
  },
  {
    id: 'ddb16a9a-7707-486f-a8e8-923fc891313e',
    name_dcycle: 'disclosure_of_how_the_policy_enables_to_a),_b),_c)_and_d)',
    datapoint_id: 'E4-2_11',
    esg_category: 'environmental',
    name_en: 'Disclosure of how the policy enables to a), b), c) and d)',
    name_es: 'Divulgación de cómo la póliza permite a), b), c) y d)',
    name_pt: 'Divulgação de como a política permite a), b), c) e d)'
  },
  {
    id: 'e7fa9ef5-0a20-4f86-914a-e5c8ef242fa5',
    name_dcycle:
      'third-party_standard_of_conduct_used_in_policy_is_objective_and_achievable_based_on_scientific_approach_to_identifying_issues_and_realistic_in_assessing_how_these_issues_can_be_addressed_under_variety_of_practical_circumstances',
    datapoint_id: 'E4-2_12',
    esg_category: 'environmental',
    name_en:
      'Third-party standard of conduct used in policy is objective and achievable based on scientific approach to identifying issues and realistic in assessing how these issues can be addressed under variety of practical circumstances',
    name_es:
      'El estándar de conducta de terceros utilizado en las políticas es objetivo y alcanzable basándose en un enfoque científico para identificar problemas y realista al evaluar cómo se pueden abordar estos problemas en una variedad de circunstancias prácticas.',
    name_pt:
      'O padrão de conduta de terceiros usado na política é objetivo e alcançável com base na abordagem científica para identificar problemas e realista na avaliação de como esses problemas podem ser abordados sob diversas circunstâncias práticas'
  },
  {
    id: '9c2668ff-c65e-4d0d-b150-3c03b9de5bcb',
    name_dcycle:
      'third-party_standard_of_conduct_used_in_policy_is_developed_or_maintained_through_process_of_ongoing_consultation_with_relevant_stakeholders_with_balanced_input_from_all_relevant_stakeholder_groups_with_no_group_holding_undue_authority_or_veto_power_over_content',
    datapoint_id: 'E4-2_13',
    esg_category: 'environmental',
    name_en:
      'Third-party standard of conduct used in policy is developed or maintained through process of ongoing consultation with relevant stakeholders with balanced input from all relevant stakeholder groups with no group holding undue authority or veto power over content',
    name_es:
      'El estándar de conducta de terceros utilizado en la política se desarrolla o mantiene a través de un proceso de consulta continua con las partes interesadas relevantes con aportes equilibrados de todos los grupos de partes interesadas relevantes, sin que ningún grupo tenga autoridad indebida o poder de veto sobre el contenido.',
    name_pt:
      'O padrão de conduta de terceiros usado na política é desenvolvido ou mantido por meio de um processo de consulta contínua com as partes interessadas relevantes, com contribuições equilibradas de todos os grupos de partes interessadas relevantes, sem que nenhum grupo detenha autoridade indevida ou poder de veto sobre o conteúdo'
  },
  {
    id: '2f01463e-716c-4d89-9985-7856e0d7e5cf',
    name_dcycle:
      'third-party_standard_of_conduct_used_in_policy_encourages_step-wise_approach_and_continuous_improvement_in_standard_and_its_application_of_better_management_practices_and_requires_establishment_of_meaningful_targets_and_specific_milestones_to_indicate_progress_against_principles_and_criteria_over_time',
    datapoint_id: 'E4-2_14',
    esg_category: 'environmental',
    name_en:
      'Third-party standard of conduct used in policy encourages step-wise approach and continuous improvement in standard and its application of better management practices and requires establishment of meaningful targets and specific milestones to indicate progress against principles and criteria over time',
    name_es:
      'El estándar de conducta de terceros utilizado en la política fomenta un enfoque gradual y una mejora continua en el estándar y su aplicación de mejores prácticas de gestión y requiere el establecimiento de objetivos significativos e hitos específicos para indicar el progreso con respecto a los principios y criterios a lo largo del tiempo.',
    name_pt:
      'O padrão de conduta de terceiros usado na política incentiva a abordagem gradual e a melhoria contínua do padrão e sua aplicação de melhores práticas de gestão e exige o estabelecimento de metas significativas e marcos específicos para indicar o progresso em relação aos princípios e critérios ao longo do tempo'
  },
  {
    id: 'd09ef319-ed5a-43d0-94e9-29c2c7743983',
    name_dcycle:
      'third-party_standard_of_conduct_used_in_policy_is_verifiable_through_independent_certifying_or_verifying_bodies,_which_have_defined_and_rigorous_assessment_procedures_that_avoid_conflicts_of_interest_and_are_compliant_with_iso_guidance_on_accreditation_and_verification_procedures_or_article_5(2)_of_regulation_(ec)_no_765/2008',
    datapoint_id: 'E4-2_15',
    esg_category: 'environmental',
    name_en:
      'Third-party standard of conduct used in policy is verifiable through independent certifying or verifying bodies, which have defined and rigorous assessment procedures that avoid conflicts of interest and are compliant with ISO guidance on accreditation and verification procedures or Article 5(2) of Regulation (EC) No 765/2008',
    name_es:
      'El estándar de conducta de terceros utilizado en la política es verificable a través de organismos certificadores o verificadores independientes, que tienen procedimientos de evaluación definidos y rigurosos que evitan conflictos de intereses y cumplen con la guía ISO sobre procedimientos de acreditación y verificación o el artículo 5(2) del Reglamento ( CE) nº 765/2008',
    name_pt:
      'O padrão de conduta de terceiros utilizado na política é verificável através de organismos independentes de certificação ou verificação, que possuem procedimentos de avaliação definidos e rigorosos que evitam conflitos de interesse e estão em conformidade com as orientações da ISO sobre procedimentos de acreditação e verificação ou com o Artigo 5 (2) do Regulamento ( CE) n.º 765/2008'
  },
  {
    id: 'bd9a39ad-849c-4ea4-9766-4f8a182443e9',
    name_dcycle:
      'third-party_standard_of_conduct_used_in_policy_conforms_to_iseal_code_of_good_practice',
    datapoint_id: 'E4-2_16',
    esg_category: 'environmental',
    name_en:
      'Third-party standard of conduct used in policy conforms to ISEAL Code of Good Practice',
    name_es:
      'El estándar de conducta de terceros utilizado en la política se ajusta al Código de buenas prácticas de ISEAL',
    name_pt:
      'O padrão de conduta de terceiros usado na política está em conformidade com o Código de Boas Práticas da ISEAL'
  },
  {
    id: 'cd0d3276-114a-4889-be55-d7fbf22f5a2f',
    name_dcycle:
      'biodiversity_and_ecosystem_protection_policy_covering_operational_sites_owned,_leased,_managed_in_or_near_protected_area_or_biodiversity-sensitive_area_outside_protected_areas_has_been_adopted',
    datapoint_id: 'E4-2_17',
    esg_category: 'environmental',
    name_en:
      'Biodiversity and ecosystem protection policy covering operational sites owned, leased, managed in or near protected area or biodiversity-sensitive area outside protected areas has been adopted',
    name_es:
      'Se ha adoptado una política de protección de la biodiversidad y los ecosistemas que cubre los sitios operativos de propiedad, arrendados o administrados dentro o cerca de un área protegida o un área sensible a la biodiversidad fuera de las áreas protegidas.',
    name_pt:
      'Foi adotada uma política de proteção da biodiversidade e dos ecossistemas que abrange locais operacionais pertencentes, arrendados ou geridos dentro ou perto de áreas protegidas ou áreas sensíveis à biodiversidade fora das áreas protegidas.'
  },
  {
    id: 'acbdbb0f-84eb-4f08-8a81-dff6a32c4f38',
    name_dcycle: 'sustainable_land_or_agriculture_practices_or_policies_have_been_adopted',
    datapoint_id: 'E4-2_18',
    esg_category: 'environmental',
    name_en: 'Sustainable land or agriculture practices or policies have been adopted',
    name_es:
      'Se han adoptado prácticas o políticas sostenibles en materia de tierras o agricultura.',
    name_pt: 'Práticas ou políticas sustentáveis ​​de terra ou agricultura foram adotadas'
  },
  {
    id: '60989053-9d49-4250-bbde-0f591098995e',
    name_dcycle: 'sustainable_oceans_or_seas_practices_or_policies_have_been_adopted',
    datapoint_id: 'E4-2_19',
    esg_category: 'environmental',
    name_en: 'Sustainable oceans or seas practices or policies have been adopted',
    name_es: 'Se han adoptado prácticas o políticas sostenibles en materia de océanos o mares.',
    name_pt: 'Práticas ou políticas sustentáveis ​​para oceanos ou mares foram adotadas'
  },
  {
    id: 'bf10312f-ba6a-4be7-9a3c-c9ba39342aaf',
    name_dcycle: 'policies_to_address_deforestation_have_been_adopted',
    datapoint_id: 'E4-2_20',
    esg_category: 'environmental',
    name_en: 'Policies to address deforestation have been adopted',
    name_es: 'Se han adoptado políticas para abordar la deforestación',
    name_pt: 'Políticas para enfrentar o desmatamento foram adotadas'
  },
  {
    id: '4f5f3dde-fb07-4786-8224-d324b3d58feb',
    name_dcycle:
      'disclosure_on_how_the_mitigation_hierarchy_has_been_applied_with_regard_to_biodiversity_and_ecosystem_actions',
    datapoint_id: 'E4-3_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure on how the mitigation hierarchy has been applied with regard to biodiversity and ecosystem actions',
    name_es:
      'Divulgación sobre cómo se ha aplicado la jerarquía de mitigación con respecto a las acciones de biodiversidad y ecosistemas.',
    name_pt:
      'Divulgação sobre como a hierarquia de mitigação foi aplicada em relação à biodiversidade e às ações ecossistêmicas'
  },
  {
    id: '2e0d8043-dc76-4df8-acfb-33f0e5ed5bf5',
    name_dcycle: 'biodiversity_offsets_were_used_in_action_plan',
    datapoint_id: 'E4-3_02',
    esg_category: 'environmental',
    name_en: 'Biodiversity offsets were used in action plan',
    name_es: 'Se utilizaron compensaciones de biodiversidad en el plan de acción',
    name_pt: 'Compensações de biodiversidade foram usadas em plano de ação'
  },
  {
    id: 'ae14ac06-4f65-49f0-8b85-111aa774e89a',
    name_dcycle: 'disclosure_of_aim_of_biodiversity_offset_and_key_performance_indicators_used',
    datapoint_id: 'E4-3_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of aim of biodiversity offset and key performance indicators used',
    name_es:
      'Divulgación del objetivo de la compensación por biodiversidad y de los indicadores clave de desempeño utilizados',
    name_pt:
      'Divulgação do objetivo da compensação de biodiversidade e dos principais indicadores de desempenho utilizados'
  },
  {
    id: 'c5a8a3c1-96ba-48c0-a0f9-d7e07cc494f9',
    name_dcycle: 'financing_effects_(direct_and_indirect_costs)_of_biodiversity_offsets',
    datapoint_id: 'E4-3_04',
    esg_category: 'environmental',
    name_en: 'Financing effects (direct and indirect costs) of biodiversity offsets',
    name_es:
      'Efectos financieros (costos directos e indirectos) de las compensaciones de biodiversidad',
    name_pt:
      'Efeitos de financiamento (custos diretos e indiretos) das compensações de biodiversidade'
  },
  {
    id: '038eaeeb-bf0a-4c7c-a690-dc9cd28d7378',
    name_dcycle:
      'explanation_of_rekationship_of_significant_capex_and_opex_required_to_impelement_actions_taken_or_planned_to_relevant_line_items_or_notes_in_the_financial_statements',
    datapoint_id: 'E4-3_05',
    esg_category: 'environmental',
    name_en:
      'Explanation of rekationship of significant Capex and Opex required to impelement actions taken or planned to relevant line items or notes in the financial statements',
    name_es:
      'Explicación de la relación de Capex y Opex significativos necesarios para implementar las acciones tomadas o planificadas para partidas o notas relevantes en los estados financieros.',
    name_pt:
      'Explicação do relacionamento de Capex e Opex significativos necessários para implementar ações tomadas ou planejadas para itens de linha ou notas relevantes nas demonstrações financeiras'
  },
  {
    id: '7faaf206-1c9c-4489-a32e-295cffd3b637',
    name_dcycle:
      'explanation_of_rekationship_of_significant_capex_and_opex_required_to_impelement_actions_taken_or_planned_to_key_performance_indicators_required_under_commission_delegated_regulation_(eu)_2021/2178',
    datapoint_id: 'E4-3_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of rekationship of significant Capex and Opex required to impelement actions taken or planned to key performance indicators required under Commission Delegated Regulation (EU) 2021/2178',
    name_es:
      'Explicación de la relación de Capex y Opex significativos necesarios para implementar las acciones tomadas o planificadas para los indicadores clave de rendimiento requeridos en virtud del Reglamento Delegado (UE) 2021/2178 de la Comisión.',
    name_pt:
      'Explicação da relação de Capex e Opex significativos necessários para implementar ações tomadas ou planejadas para os principais indicadores de desempenho exigidos pelo Regulamento Delegado (UE) 2021/2178 da Comissão'
  },
  {
    id: '1d5d9873-6713-4057-ba4b-1ada93a7b3b7',
    name_dcycle:
      'explanation_of_rekationship_of_significant_capex_and_opex_required_to_impelement_actions_taken_or_planned_to_capex_plan_required_under_commission_delegated_regulation_(eu)_2021/2178',
    datapoint_id: 'E4-3_07',
    esg_category: 'environmental',
    name_en:
      'Explanation of rekationship of significant Capex and Opex required to impelement actions taken or planned to Capex plan required under Commission Delegated Regulation (EU) 2021/2178',
    name_es:
      'Explicación de la relación de Capex y Opex significativos necesarios para implementar las acciones tomadas o planificadas según el plan de Capex requerido según el Reglamento Delegado (UE) 2021/2178 de la Comisión.',
    name_pt:
      'Explicação do relacionamento de Capex e Opex significativos necessários para implementar as ações tomadas ou planejadas para o plano de Capex exigido pelo Regulamento Delegado da Comissão (UE) 2021/2178'
  },
  {
    id: '7d8ff2cd-c2fb-4000-ae2d-de820a0a74e1',
    name_dcycle: 'description_of_biodiversity_offsets',
    datapoint_id: 'E4-3_08',
    esg_category: 'environmental',
    name_en: 'Description of biodiversity offsets',
    name_es: 'Descripción de las compensaciones por biodiversidad',
    name_pt: 'Descrição das compensações de biodiversidade'
  },
  {
    id: '18ce219b-8b46-4395-9833-39b1583ea8fd',
    name_dcycle:
      'description_of_whether_and_how_local_and_indigenous_knowledge_and_nature-based_solutions_have_been_incorporated_into_biodiversity_and_ecosystems-related_action',
    datapoint_id: 'E4-3_09',
    esg_category: 'environmental',
    name_en:
      'Description of whether and how local and indigenous knowledge and nature-based solutions have been incorporated into biodiversity and ecosystems-related action',
    name_es:
      'Descripción de si y cómo se han incorporado los conocimientos locales e indígenas y las soluciones basadas en la naturaleza en las acciones relacionadas con la biodiversidad y los ecosistemas.',
    name_pt:
      'Descrição de se e como o conhecimento local e indígena e as soluções baseadas na natureza foram incorporados na biodiversidade e nas ações relacionadas aos ecossistemas'
  },
  {
    id: 'f194c8fd-47a2-4d7b-96ec-52b7f56ee927',
    name_dcycle:
      'disclosure_of_key_stakeholders_involved_and_how_they_are_involved,_key_stakeholders_negatively_or_positively_impacted_by_action_and_how_they_are_impacted',
    datapoint_id: 'E4-3_10',
    esg_category: 'environmental',
    name_en:
      'Disclosure of key stakeholders involved and how they are involved, key stakeholders negatively or positively impacted by action and how they are impacted',
    name_es:
      'Divulgación de las partes interesadas clave involucradas y cómo participan, las partes interesadas clave afectadas negativa o positivamente por la acción y cómo se ven afectadas',
    name_pt:
      'Divulgação das principais partes interessadas envolvidas e como elas estão envolvidas, das principais partes interessadas impactadas negativa ou positivamente pela ação e como elas são impactadas'
  },
  {
    id: '8621a368-d0d8-43c9-8e9a-deff76bfad69',
    name_dcycle:
      'explanation_of_need_for_appropriate_consultations_and_need_to_respect_decisions_of_affected_communities',
    datapoint_id: 'E4-3_11',
    esg_category: 'environmental',
    name_en:
      'Explanation of need for appropriate consultations and need to respect decisions of affected communities',
    name_es:
      'Explicación de la necesidad de realizar consultas apropiadas y de respetar las decisiones de las comunidades afectadas',
    name_pt:
      'Explicação da necessidade de consultas apropriadas e da necessidade de respeitar as decisões das comunidades afetadas'
  },
  {
    id: 'cb6ad02e-eb34-4e49-8642-62d29f1ebcdf',
    name_dcycle:
      'description_of_whether_key_action_may_induce_significant_negative_sustainability_impacts_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-3_12',
    esg_category: 'environmental',
    name_en:
      'Description of whether key action may induce significant negative sustainability impacts (biodiversity and ecosystems)',
    name_es:
      'Descripción de si una acción clave puede inducir impactos negativos significativos en la sostenibilidad (biodiversidad y ecosistemas)',
    name_pt:
      'Descrição se a ação-chave pode induzir impactos negativos significativos na sustentabilidade (biodiversidade e ecossistemas)'
  },
  {
    id: 'd903a26e-56d3-4bc4-b12e-ee685433c734',
    name_dcycle:
      'explanation_of_whether_the_key_action_is_intended_to_be_a_one-time_initiative_or_systematic_practice',
    datapoint_id: 'E4-3_13',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether the key action is intended to be a one-time initiative or systematic practice',
    name_es:
      'Explicación de si la acción clave pretende ser una iniciativa única o una práctica sistemática',
    name_pt:
      'Explicação sobre se a ação-chave se destina a ser uma iniciativa única ou uma prática sistemática'
  },
  {
    id: '6d83f94a-f71f-4f97-ab46-1c5866b4131f',
    name_dcycle:
      'key_action_plan_is_carried_out_only_by_undertaking_(individual_action)_using_its_resources_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-3_14',
    esg_category: 'environmental',
    name_en:
      'Key action plan is carried out only by undertaking (individual action) using its resources (biodiversity and ecosystems)',
    name_es:
      'El plan de acción clave se lleva a cabo únicamente mediante la realización (acción individual) utilizando sus recursos (biodiversidad y ecosistemas).',
    name_pt:
      'O plano de ação principal é executado apenas através da realização (ação individual) utilizando os seus recursos (biodiversidade e ecossistemas)'
  },
  {
    id: 'a3181e93-ff88-42bb-b8ea-cdd73eb02262',
    name_dcycle:
      'key_action_plan_is_part_of_wider_action_plan_(collective_action),_of_which_undertaking_is_member_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-3_15',
    esg_category: 'environmental',
    name_en:
      'Key action plan is part of wider action plan (collective action), of which undertaking is member (biodiversity and ecosystems)',
    name_es:
      'El plan de acción clave forma parte de un plan de acción más amplio (acción colectiva) del que la empresa es miembro (biodiversidad y ecosistemas).',
    name_pt:
      'O plano de ação-chave faz parte de um plano de ação mais amplo (ação coletiva), do qual a empresa é membro (biodiversidade e ecossistemas)'
  },
  {
    id: '843c5bdd-48db-41fd-b60f-d07381aac9e2',
    name_dcycle:
      'additional_information_about_project,_its_sponsors_and_other_participants_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-3_16',
    esg_category: 'environmental',
    name_en:
      'Additional information about project, its sponsors and other participants (biodiversity and ecosystems)',
    name_es:
      'Información adicional sobre el proyecto, sus patrocinadores y otros participantes (biodiversidad y ecosistemas)',
    name_pt:
      'Informações adicionais sobre o projeto, seus patrocinadores e demais participantes (biodiversidade e ecossistemas)'
  },
  {
    id: 'f819476e-58d0-4e1c-8de0-28d12c8b9d2c',
    name_dcycle:
      'ecological_threshold_and_allocation_of_impacts_to_undertaking_were_applied_when_setting_target_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-4_01',
    esg_category: 'environmental',
    name_en:
      'Ecological threshold and allocation of impacts to undertaking were applied when setting target (biodiversity and ecosystems)',
    name_es:
      'El umbral ecológico y la asignación de impactos a la empresa se aplicaron al establecer el objetivo (biodiversidad y ecosistemas)',
    name_pt:
      'O limite ecológico e a alocação de impactos ao empreendimento foram aplicados na definição da meta (biodiversidade e ecossistemas)'
  },
  {
    id: 'cfeb56f0-8b90-4da9-b387-1560cc1c2952',
    name_dcycle:
      'disclosure_of_ecological_threshold_identified_and_methodology_used_to_identify_threshold_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-4_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of ecological threshold identified and methodology used to identify threshold (biodiversity and ecosystems)',
    name_es:
      'Divulgación del umbral ecológico identificado y metodología utilizada para identificar el umbral (biodiversidad y ecosistemas)',
    name_pt:
      'Divulgação do limiar ecológico identificado e metodologia utilizada para identificar o limiar (biodiversidade e ecossistemas)'
  },
  {
    id: 'f4aa7ffe-5c46-4813-93fd-88b56d6929ad',
    name_dcycle:
      'disclosure_of_how_entity-specific_threshold_was_determined_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-4_03',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how entity-specific threshold was determined (biodiversity and ecosystems)',
    name_es:
      'Divulgación de cómo se determinó el umbral específico de la entidad (biodiversidad y ecosistemas)',
    name_pt:
      'Divulgação de como o limite específico da entidade foi determinado (biodiversidade e ecossistemas)'
  },
  {
    id: '34fe4454-134c-44f6-96a9-63de6788167a',
    name_dcycle:
      'disclosure_of_how_responsibility_for_respecting_identified_ecological_threshold_is_allocated_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-4_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how responsibility for respecting identified ecological threshold is allocated (biodiversity and ecosystems)',
    name_es:
      'Divulgación de cómo se asigna la responsabilidad de respetar el umbral ecológico identificado (biodiversidad y ecosistemas)',
    name_pt:
      'Divulgação de como é atribuída a responsabilidade pelo respeito do limiar ecológico identificado (biodiversidade e ecossistemas)'
  },
  {
    id: 'deceb1f8-eeaf-4d32-9aa5-b0267f476b59',
    name_dcycle: 'target_is_informed_by_relevant_aspect_of_eu_biodiversity_strategy_for_2030',
    datapoint_id: 'E4-4_05',
    esg_category: 'environmental',
    name_en: 'Target is informed by relevant aspect of EU Biodiversity Strategy for 2030',
    name_es:
      'El objetivo se basa en aspectos relevantes de la Estrategia de Biodiversidad de la UE para 2030',
    name_pt:
      'A meta é informada por aspectos relevantes da Estratégia de Biodiversidade da UE para 2030'
  },
  {
    id: '5347bc2f-dfc7-438a-a868-50fc2ed0c5fb',
    name_dcycle:
      'disclosure_of_how_the_targets_relate_to_the_biodiversity_and_ecosystem_impacts,_dependencies,_risks_and_opportunities_identified_in_relation_to_own_operations_and_upstream_and_downstream_value_chain',
    datapoint_id: 'E4-4_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how the targets relate to the biodiversity and ecosystem impacts, dependencies, risks and opportunities identified in relation to own operations and upstream and downstream value chain',
    name_es:
      'Divulgación de cómo los objetivos se relacionan con los impactos, dependencias, riesgos y oportunidades de la biodiversidad y los ecosistemas identificados en relación con las operaciones propias y la cadena de valor upstream y downstream.',
    name_pt:
      'Divulgação de como as metas se relacionam com os impactos, dependências, riscos e oportunidades na biodiversidade e nos ecossistemas identificados em relação às próprias operações e à cadeia de valor a montante e a jusante'
  },
  {
    id: '669f6181-199a-4afd-b2a3-315a980cd0f2',
    name_dcycle: 'disclosure_of_the_geographical_scope_of_the_targets',
    datapoint_id: 'E4-4_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of the geographical scope of the targets',
    name_es: 'Divulgación del alcance geográfico de los objetivos',
    name_pt: 'Divulgação do âmbito geográfico dos alvos'
  },
  {
    id: '91b59f57-1d83-4a03-87e9-0a26d31a4c91',
    name_dcycle: 'biodiversity_offsets_were_used_in_setting_target',
    datapoint_id: 'E4-4_08',
    esg_category: 'environmental',
    name_en: 'Biodiversity offsets were used in setting target',
    name_es: 'Se utilizaron compensaciones de biodiversidad para establecer objetivos.',
    name_pt: 'As compensações de biodiversidade foram usadas na definição de metas'
  },
  {
    id: '4f129dd6-9850-42a3-8267-126088cdb446',
    name_dcycle:
      'layer_in_mitigation_hierarchy_to_which_target_can_be_allocated_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-4_09',
    esg_category: 'environmental',
    name_en:
      'Layer in mitigation hierarchy to which target can be allocated (biodiversity and ecosystems)',
    name_es:
      'Capa en la jerarquía de mitigación a la que se puede asignar el objetivo (biodiversidad y ecosistemas)',
    name_pt:
      'Camada na hierarquia de mitigação à qual a meta pode ser alocada (biodiversidade e ecossistemas)'
  },
  {
    id: '2789cb31-2c7f-4f7d-af7d-3a9de5e47a33',
    name_dcycle:
      'the_target_addresses_shortcomings_related_to_the_substantial_contribution_criteria',
    datapoint_id: 'E4-4_10',
    esg_category: 'environmental',
    name_en: 'The target addresses shortcomings related to the Substantial Contribution criteria',
    name_es:
      'La meta aborda las deficiencias relacionadas con los criterios de contribución sustancial.',
    name_pt: 'A meta aborda deficiências relacionadas com os critérios de contribuição substancial'
  },
  {
    id: '64285ec6-652d-4191-89dd-4a30b10ffd52',
    name_dcycle:
      'number_of_sites_owned,_leased_or_managed_in_or_near_protected_areas_or_key_biodiversity_areas_that_undertaking_is_negatively_affecting',
    datapoint_id: 'E4-5_01',
    esg_category: 'environmental',
    name_en:
      'Number of sites owned, leased or managed in or near protected areas or key biodiversity areas that undertaking is negatively affecting',
    name_es:
      'Número de sitios de propiedad, arrendados o administrados en o cerca de áreas protegidas o áreas clave de biodiversidad que la iniciativa está afectando negativamente',
    name_pt:
      'Número de locais pertencentes, arrendados ou geridos dentro ou perto de áreas protegidas ou áreas chave de biodiversidade que o empreendimento está a afectar negativamente'
  },
  {
    id: '61894b55-be50-438e-acc3-0e5898ada50d',
    name_dcycle:
      'area_of_sites_owned,_leased_or_managed_in_or_near_protected_areas_or_key_biodiversity_areas_that_undertaking_is_negatively_affecting',
    datapoint_id: 'E4-5_02',
    esg_category: 'environmental',
    name_en:
      'Area of sites owned, leased or managed in or near protected areas or key biodiversity areas that undertaking is negatively affecting',
    name_es:
      'Área de sitios de propiedad, arrendados o administrados en o cerca de áreas protegidas o áreas clave de biodiversidad que la empresa está afectando negativamente',
    name_pt:
      'Área de locais pertencentes, arrendados ou administrados dentro ou perto de áreas protegidas ou áreas-chave de biodiversidade que o empreendimento está afetando negativamente'
  },
  {
    id: 'e35bb50c-5158-46ed-bb84-e002de9b3619',
    name_dcycle: 'disclosure_of_land-use_based_on_life_cycle_assessment',
    datapoint_id: 'E4-5_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of land-use based on Life Cycle Assessment',
    name_es: 'Divulgación del uso de la tierra basada en la Evaluación del Ciclo de Vida',
    name_pt: 'Divulgação do uso da terra com base na Avaliação do Ciclo de Vida'
  },
  {
    id: '4673020c-f726-4090-bae8-c521b591a539',
    name_dcycle:
      'disclosure_of_metrics_considered_relevant_(land-use_change,_freshwater-use_change_and_(or)_sea-use_change)',
    datapoint_id: 'E4-5_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of metrics considered relevant (land-use change, freshwater-use change and (or) sea-use change)',
    name_es:
      'Divulgación de métricas consideradas relevantes (cambio de uso de la tierra, cambio de uso de agua dulce y (o) cambio de uso del mar)',
    name_pt:
      'Divulgação de métricas consideradas relevantes (mudança no uso do solo, mudança no uso da água doce e (ou) mudança no uso do mar)'
  },
  {
    id: '95404bcd-5402-4178-82cc-7ce15301113a',
    name_dcycle: 'disclosure_of_conversion_over_time_of_land_cover',
    datapoint_id: 'E4-5_05',
    esg_category: 'environmental',
    name_en: 'Disclosure of conversion over time of land cover',
    name_es: 'Divulgación de la conversión a lo largo del tiempo de la cobertura terrestre',
    name_pt: 'Divulgação da conversão ao longo do tempo da cobertura do solo'
  },
  {
    id: '06cd8867-0c85-4a67-8152-51990c818f61',
    name_dcycle: 'disclosure_of_changes_over_time_in_management_of_ecosystem',
    datapoint_id: 'E4-5_06',
    esg_category: 'environmental',
    name_en: 'Disclosure of changes over time in management of ecosystem',
    name_es: 'Divulgación de cambios a lo largo del tiempo en la gestión del ecosistema.',
    name_pt: 'Divulgação de mudanças ao longo do tempo na gestão do ecossistema'
  },
  {
    id: '4f239e58-ac0c-42ae-83ec-03761f70f4d7',
    name_dcycle: 'disclosure_of_changes_in_spatial_configuration_of_landscape',
    datapoint_id: 'E4-5_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of changes in spatial configuration of landscape',
    name_es: 'Divulgación de cambios en la configuración espacial del paisaje.',
    name_pt: 'Divulgação de mudanças na configuração espacial da paisagem'
  },
  {
    id: '63152bdd-f5b8-4510-9188-23c4ab557a93',
    name_dcycle: 'disclosure_of_changes_in_ecosystem_structural_connectivity',
    datapoint_id: 'E4-5_08',
    esg_category: 'environmental',
    name_en: 'Disclosure of changes in ecosystem structural connectivity',
    name_es: 'Divulgación de cambios en la conectividad estructural de los ecosistemas.',
    name_pt: 'Divulgação de mudanças na conectividade estrutural do ecossistema'
  },
  {
    id: '3d32ca47-74dc-49eb-b7a9-bc6a0be090d8',
    name_dcycle: 'disclosure_of_functional_connectivity',
    datapoint_id: 'E4-5_09',
    esg_category: 'environmental',
    name_en: 'Disclosure of functional connectivity',
    name_es: 'Divulgación de conectividad funcional.',
    name_pt: 'Divulgação de conectividade funcional'
  },
  {
    id: '3317997a-dfcf-42cc-bdee-3b08c1ac0f32',
    name_dcycle: 'total_use_of_land_area',
    datapoint_id: 'E4-5_10',
    esg_category: 'environmental',
    name_en: 'Total use of land area',
    name_es: 'Uso total de la superficie terrestre',
    name_pt: 'Uso total da área do terreno'
  },
  {
    id: 'd527a3cc-35fb-4dd6-b310-a22ff66685bc',
    name_dcycle: 'total_sealed_area',
    datapoint_id: 'E4-5_11',
    esg_category: 'environmental',
    name_en: 'Total sealed area',
    name_es: 'Área total sellada',
    name_pt: 'Área total selada'
  },
  {
    id: 'ba9ac85a-b0bc-4dcb-a2a6-a0c0abadc6c0',
    name_dcycle: 'nature-oriented_area_on_site',
    datapoint_id: 'E4-5_12',
    esg_category: 'environmental',
    name_en: 'Nature-oriented area on site',
    name_es: 'Área orientada a la naturaleza en el sitio',
    name_pt: 'Área voltada para a natureza no local'
  },
  {
    id: '35502676-8aee-4b5f-898e-e64950e18449',
    name_dcycle: 'nature-oriented_area_off_site',
    datapoint_id: 'E4-5_13',
    esg_category: 'environmental',
    name_en: 'Nature-oriented area off site',
    name_es: 'Área orientada a la naturaleza fuera del sitio',
    name_pt: 'Área voltada para a natureza fora do local'
  },
  {
    id: 'da3456a5-f121-4a48-9cc6-75b89f4628f6',
    name_dcycle:
      'disclosure_of_how_pathways_of_introduction_and_spread_of_invasive_alien_species_and_risks_posed_by_invasive_alien_species_are_managed',
    datapoint_id: 'E4-5_14',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how pathways of introduction and spread of invasive alien species and risks posed by invasive alien species are managed',
    name_es:
      'Divulgación de cómo se gestionan las vías de introducción y propagación de especies exóticas invasoras y los riesgos que plantean las especies exóticas invasoras.',
    name_pt:
      'Divulgação de como são gerenciadas as vias de introdução e disseminação de espécies exóticas invasoras e os riscos representados por espécies exóticas invasoras'
  },
  {
    id: 'd43ce070-47f0-41dc-a1bc-533f7d2d6835',
    name_dcycle: 'number_of_invasive_alien_species',
    datapoint_id: 'E4-5_15',
    esg_category: 'environmental',
    name_en: 'Number of invasive alien species',
    name_es: 'Número de especies exóticas invasoras',
    name_pt: 'Número de espécies exóticas invasoras'
  },
  {
    id: '7667c0e1-b1bf-4946-96ab-f9073c57f9b8',
    name_dcycle: 'area_covered_by_invasive_alien_species',
    datapoint_id: 'E4-5_16',
    esg_category: 'environmental',
    name_en: 'Area covered by invasive alien species',
    name_es: 'Área cubierta por especies exóticas invasoras',
    name_pt: 'Área coberta por espécies exóticas invasoras'
  },
  {
    id: '0abb4464-2db3-45f1-9c34-ef1107920fe3',
    name_dcycle: 'disclosure_of_metrics_considered_relevant_(state_of_species)',
    datapoint_id: 'E4-5_17',
    esg_category: 'environmental',
    name_en: 'Disclosure of metrics considered relevant (state of species)',
    name_es: 'Divulgación de métricas consideradas relevantes (estado de especies)',
    name_pt: 'Divulgação de métricas consideradas relevantes (estado da espécie)'
  },
  {
    id: 'af4dd3fa-ca51-4c25-adbe-375cc76a746b',
    name_dcycle:
      'disclosure_of_paragraph_in_another_environment-related_standard_in_which_metric_is_referred_to',
    datapoint_id: 'E4-5_18',
    esg_category: 'environmental',
    name_en:
      'Disclosure of paragraph in another environment-related standard in which metric is referred to',
    name_es:
      'Divulgación de un párrafo en otra norma relacionada con el medio ambiente en el que se hace referencia a la métrica',
    name_pt:
      'Divulgação de parágrafo em outra norma relacionada ao meio ambiente em que a métrica é referida'
  },
  {
    id: '527d2d3b-2430-45ee-a5fa-5bd27ebd6816',
    name_dcycle:
      'disclosure_of_population_size,_range_within_specific_ecosystems_and_extinction_risk',
    datapoint_id: 'E4-5_19',
    esg_category: 'environmental',
    name_en: 'Disclosure of population size, range within specific ecosystems and extinction risk',
    name_es:
      'Divulgación del tamaño de la población, rango dentro de ecosistemas específicos y riesgo de extinción.',
    name_pt:
      'Divulgação do tamanho da população, distribuição dentro de ecossistemas específicos e risco de extinção'
  },
  {
    id: 'c89d48b6-6521-40e8-b1a9-ce6cc49fb235',
    name_dcycle: 'disclosure_of_changes_in_number_of_individuals_of_species_within_specific_area',
    datapoint_id: 'E4-5_20',
    esg_category: 'environmental',
    name_en: 'Disclosure of changes in number of individuals of species within specific area',
    name_es:
      'Divulgación de cambios en el número de individuos de especies dentro de un área específica',
    name_pt:
      'Divulgação de mudanças no número de indivíduos de espécies dentro de uma área específica'
  },
  {
    id: '2844a793-4369-4e9e-9bd0-03fc543e444c',
    name_dcycle: 'information_about_species_at_global_extinction_risk',
    datapoint_id: 'E4-5_21',
    esg_category: 'environmental',
    name_en: 'Information about species at global extinction risk',
    name_es: 'Información sobre especies en riesgo de extinción global',
    name_pt: 'Informações sobre espécies em risco de extinção global'
  },
  {
    id: 'f4000245-1ef7-4c29-b674-d26cd5045db3',
    name_dcycle:
      'disclosure_of_threat_status_of_species_and_how_activities_or_pressures_may_affect_threat_status',
    datapoint_id: 'E4-5_22',
    esg_category: 'environmental',
    name_en:
      'Disclosure of threat status of species and how activities or pressures may affect threat status',
    name_es:
      'Divulgación del estado de amenaza de las especies y cómo las actividades o presiones pueden afectar el estado de amenaza',
    name_pt:
      'Divulgação do estado de ameaça das espécies e como as atividades ou pressões podem afetar o estado de ameaça'
  },
  {
    id: '712bd3cb-4b25-4521-989d-88a417db7d35',
    name_dcycle:
      "disclosure_of_change_in_relevant_habitat_for_threatened_species_as_proxy_for_impact_on_local_population's_extinction_risk",
    datapoint_id: 'E4-5_23',
    esg_category: 'environmental',
    name_en:
      "Disclosure of change in relevant habitat for threatened species as proxy for impact on local population's extinction risk",
    name_es:
      'Divulgación de cambios en el hábitat relevante para especies amenazadas como indicador del impacto en el riesgo de extinción de la población local.',
    name_pt:
      'Divulgação de mudanças no habitat relevante para espécies ameaçadas como proxy do impacto no risco de extinção da população local'
  },
  {
    id: 'e4410e44-5604-4eaa-b3f7-db5e2bf46173',
    name_dcycle: 'disclosure_of_ecosystem_area_coverage',
    datapoint_id: 'E4-5_24',
    esg_category: 'environmental',
    name_en: 'Disclosure of ecosystem area coverage',
    name_es: 'Divulgación de la cobertura del área del ecosistema',
    name_pt: 'Divulgação da cobertura da área do ecossistema'
  },
  {
    id: 'a7fd57ef-a630-4ca2-aebc-e533c2303cf0',
    name_dcycle: 'disclosure_of_quality_of_ecosystems_relative_to_pre-determined_reference_state',
    datapoint_id: 'E4-5_25',
    esg_category: 'environmental',
    name_en: 'Disclosure of quality of ecosystems relative to pre-determined reference state',
    name_es:
      'Divulgación de la calidad de los ecosistemas en relación con el estado de referencia predeterminado',
    name_pt:
      'Divulgação da qualidade dos ecossistemas em relação ao estado de referência pré-determinado'
  },
  {
    id: 'a549a2dc-90a1-4aeb-981c-3a7a61b3dce5',
    name_dcycle: 'disclosure_of_multiple_species_within_ecosystem',
    datapoint_id: 'E4-5_26',
    esg_category: 'environmental',
    name_en: 'Disclosure of multiple species within ecosystem',
    name_es: 'Divulgación de múltiples especies dentro del ecosistema.',
    name_pt: 'Divulgação de múltiplas espécies dentro do ecossistema'
  },
  {
    id: 'cd885fbf-aa6f-4135-a59d-0d7d04c5d3f5',
    name_dcycle: 'disclosure_of_structural_components_of_ecosystem_condition',
    datapoint_id: 'E4-5_27',
    esg_category: 'environmental',
    name_en: 'Disclosure of structural components of ecosystem condition',
    name_es: 'Divulgación de los componentes estructurales de la condición del ecosistema.',
    name_pt: 'Divulgação dos componentes estruturais da condição do ecossistema'
  },
  {
    id: '47368f06-8a89-401f-ac41-ce3cd548f8cd',
    name_dcycle:
      'disclosure_of_quantitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_biodiversity-_and_ecosystem-related_impacts_and_dependencies',
    datapoint_id: 'E4-6_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of quantitative information about anticipated financial effects of material risks and opportunities arising from biodiversity- and ecosystem-related impacts and dependencies',
    name_es:
      'Divulgación de información cuantitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos y dependencias relacionados con la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação de informações quantitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos e dependências relacionados à biodiversidade e ao ecossistema'
  },
  {
    id: '3a73b6d7-5424-4783-9be4-94db8d0a2649',
    name_dcycle:
      'disclosure_of_qualitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_biodiversity-_and_ecosystem-related_impacts_and_dependencies',
    datapoint_id: 'E4-6_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of qualitative information about anticipated financial effects of material risks and opportunities arising from biodiversity- and ecosystem-related impacts and dependencies',
    name_es:
      'Divulgación de información cualitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos y dependencias relacionados con la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação de informações qualitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos e dependências relacionados à biodiversidade e ao ecossistema'
  },
  {
    id: '7fb91029-7ba5-4be8-83a7-b076c2c282c4',
    name_dcycle:
      'description_of_effects_considered,_related_impacts_and_dependencies_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-6_03',
    esg_category: 'environmental',
    name_en:
      'Description of effects considered, related impacts and dependencies (biodiversity and ecosystems)',
    name_es:
      'Descripción de los efectos considerados, impactos relacionados y dependencias (biodiversidad y ecosistemas)',
    name_pt:
      'Descrição dos efeitos considerados, impactos relacionados e dependências (biodiversidade e ecossistemas)'
  },
  {
    id: 'bc1f68c4-a017-4172-97e2-8d0536d4d860',
    name_dcycle:
      'disclosure_of_critical_assumptions_used_in_estimates_of_financial_effects_of_material_risks_and_opportunities_arising_from_biodiversity-_and_ecosystem-related_impacts_and_dependencies',
    datapoint_id: 'E4-6_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of critical assumptions used in estimates of financial effects of material risks and opportunities arising from biodiversity- and ecosystem-related impacts and dependencies',
    name_es:
      'Divulgación de supuestos críticos utilizados en las estimaciones de los efectos financieros de los riesgos y oportunidades materiales que surgen de los impactos y dependencias relacionados con la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação de premissas críticas usadas nas estimativas de efeitos financeiros de riscos e oportunidades materiais decorrentes de impactos e dependências relacionados à biodiversidade e ao ecossistema'
  },
  {
    id: 'b3fbd8cf-d45f-4e67-98a7-54a943be587a',
    name_dcycle:
      'description_of_related_products_and_services_at_risk_(biodiversity_and_ecosystems)_over_the_short-,_medium-_and_long-term',
    datapoint_id: 'E4-6_05',
    esg_category: 'environmental',
    name_en:
      'Description of related products and services at risk (biodiversity and ecosystems) over the short-, medium- and long-term',
    name_es:
      'Descripción de productos y servicios relacionados en riesgo (biodiversidad y ecosistemas) a corto, mediano y largo plazo.',
    name_pt:
      'Descrição dos produtos e serviços relacionados em risco (biodiversidade e ecossistemas) no curto, médio e longo prazo'
  },
  {
    id: 'fca8602a-a46e-4711-b537-466de1e5618f',
    name_dcycle:
      'explanation_of_how_financial_amounts_are_estimated_and_critical_assumptions_made_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-6_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of how financial amounts are estimated and critical assumptions made (biodiversity and ecosystems)',
    name_es:
      'Explicación de cómo se estiman los montos financieros y se hacen los supuestos críticos (biodiversidad y ecosistemas)',
    name_pt:
      'Explicação de como os valores financeiros são estimados e as suposições críticas são feitas (biodiversidade e ecossistemas)'
  },
  {
    id: '16e011c8-a7f6-4f82-a519-6a87607d1816',
    name_dcycle:
      'disclosure_of_whether_the_undertaking_has_screened_its_assets_and_activities_in_order_to_identify_actual_and_potential_impacts,_risks_and_opportunities_in_own_operations_and_upstream_and_downstream_value_chain,_and_if_so,_methodologies,_assumptions_and_tools_used',
    datapoint_id: 'E5.IRO-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether the undertaking has screened its assets and activities in order to identify actual and potential impacts, risks and opportunities in own operations and upstream and downstream value chain, and if so, methodologies, assumptions and tools used',
    name_es:
      'Divulgación de si la empresa ha examinado sus activos y actividades para identificar impactos, riesgos y oportunidades reales y potenciales en sus propias operaciones y en la cadena de valor ascendente y descendente y, en caso afirmativo, metodologías, supuestos y herramientas utilizadas.',
    name_pt:
      'Divulgação sobre se a empresa examinou os seus ativos e atividades, a fim de identificar impactos, riscos e oportunidades reais e potenciais nas próprias operações e na cadeia de valor a montante e a jusante e, em caso afirmativo, metodologias, pressupostos e ferramentas utilizadas'
  },
  {
    id: '4acd5b7c-3d4d-43e9-b0c8-73c83bf424dc',
    name_dcycle:
      'disclosure_of_whether_and_how_the_undertaking_has_conducted_consultations_(resource_and_circular_economy)',
    datapoint_id: 'E5.IRO-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how the undertaking has conducted consultations (resource and circular economy)',
    name_es:
      'Divulgación de si la empresa ha realizado consultas y cómo (recursos y economía circular)',
    name_pt: 'Divulgação de se e como a empresa realizou consultas (recursos e economia circular)'
  },
  {
    id: 'b15b3947-0b17-4d9c-9d6f-8342f4c6b05e',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_addresses_transitioning_away_from_use_of_virgin_resources,_including_relative_increases_in_use_of_secondary_(recycled)_resources',
    datapoint_id: 'E5-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy addresses transitioning away from use of virgin resources, including relative increases in use of secondary (recycled) resources',
    name_es:
      'Divulgación de si la política aborda la transición hacia el abandono del uso de recursos vírgenes, incluidos los aumentos relativos en el uso de recursos secundarios (reciclados), y de qué manera.',
    name_pt:
      'Divulgação sobre se e como a política aborda a transição do uso de recursos virgens, incluindo aumentos relativos no uso de recursos secundários (reciclados)'
  },
  {
    id: '12fa4c4c-4d64-4b10-963e-2110df4dbeb1',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_addresses_sustainable_sourcing_and_use_of_renewable_resources',
    datapoint_id: 'E5-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy addresses sustainable sourcing and use of renewable resources',
    name_es:
      'Divulgación de si la política aborda el abastecimiento y el uso sostenible de recursos renovables y cómo lo hace.',
    name_pt:
      'Divulgação sobre se e como a política aborda o fornecimento e uso sustentável de recursos renováveis'
  },
  {
    id: '5852b4b1-0bda-419c-aa77-e5341860ce90',
    name_dcycle:
      'description_of_whether_and_how_policy_addresses_waste_hierarchy_(prevention,_preparing_for_re-use,_recycling,_other_recovery,_disposal)',
    datapoint_id: 'E5-1_03',
    esg_category: 'environmental',
    name_en:
      'Description of whether and how policy addresses waste hierarchy (prevention, preparing for re-use, recycling, other recovery, disposal)',
    name_es:
      'Descripción de si la política aborda la jerarquía de residuos y de qué manera (prevención, preparación para la reutilización, reciclaje, otras recuperaciones, eliminación)',
    name_pt:
      'Descrição de se e como a política aborda a hierarquia de resíduos (prevenção, preparação para reutilização, reciclagem, outras recuperações, eliminação)'
  },
  {
    id: '3d9e70ca-9fc2-40fc-886e-c8ed30c30481',
    name_dcycle:
      'description_of_whether_and_how_policy_addresses_prioritisation_of_strategies_to_avoid_or_minimise_waste_over_waste_treatment_strategies',
    datapoint_id: 'E5-1_04',
    esg_category: 'environmental',
    name_en:
      'Description of whether and how policy addresses prioritisation of strategies to avoid or minimise waste over waste treatment strategies',
    name_es:
      'Descripción de si la política aborda la priorización de estrategias para evitar o minimizar los residuos frente a las estrategias de tratamiento de residuos, y cómo hacerlo',
    name_pt:
      'Descrição de se e como a política aborda a priorização de estratégias para evitar ou minimizar resíduos em detrimento de estratégias de tratamento de resíduos'
  },
  {
    id: 'ca6526fa-ba68-4c72-80e5-f6d674c72d53',
    name_dcycle:
      'description_of_higher_levels_of_resource_efficiency_in_use_of_technical_and_biological_materials_and_water',
    datapoint_id: 'E5-2_01',
    esg_category: 'environmental',
    name_en:
      'Description of higher levels of resource efficiency in use of technical and biological materials and water',
    name_es:
      'Descripción de niveles más altos de eficiencia de recursos en el uso de materiales técnicos y biológicos y agua.',
    name_pt:
      'Descrição de níveis mais elevados de eficiência de recursos no uso de materiais técnicos e biológicos e água'
  },
  {
    id: '10c59b73-7434-480d-a1c3-4c1edd47201c',
    name_dcycle: 'description_of_higher_rates_of_use_of_secondary_raw_materials',
    datapoint_id: 'E5-2_02',
    esg_category: 'environmental',
    name_en: 'Description of higher rates of use of secondary raw materials',
    name_es: 'Descripción de tasas más altas de uso de materias primas secundarias',
    name_pt: 'Descrição de taxas mais elevadas de utilização de matérias-primas secundárias'
  },
  {
    id: '987d50a2-4134-460d-86e8-f9ce61ffc35d',
    name_dcycle: 'description_of_application_of_circular_design',
    datapoint_id: 'E5-2_03',
    esg_category: 'environmental',
    name_en: 'Description of application of circular design',
    name_es: 'Descripción de la aplicación del diseño circular.',
    name_pt: 'Descrição da aplicação do desenho circular'
  },
  {
    id: '778a697d-bbec-474a-af60-9909880119ed',
    name_dcycle: 'description_of_application_of_circular_business_practices',
    datapoint_id: 'E5-2_04',
    esg_category: 'environmental',
    name_en: 'Description of application of circular business practices',
    name_es: 'Descripción de la aplicación de prácticas comerciales circulares',
    name_pt: 'Descrição da aplicação de práticas comerciais circulares'
  },
  {
    id: '9dee0d17-dee7-4278-94e9-1cc3695d88c1',
    name_dcycle:
      "description_of_actions_taken_to_prevent_waste_generation_in_the_undertaking's_upstream_and_downstram_value_chain",
    datapoint_id: 'E5-2_05',
    esg_category: 'environmental',
    name_en:
      "Description of actions taken to prevent waste generation in the undertaking's upstream and downstram value chain",
    name_es:
      'Descripción de las acciones tomadas para prevenir la generación de residuos en la cadena de valor upstream y downstream de la empresa',
    name_pt:
      'Descrição das ações tomadas para prevenir a geração de resíduos na cadeia de valor a montante e a jusante da empresa'
  },
  {
    id: '85870c9c-3da8-42f0-916e-e29c4e44c941',
    name_dcycle: 'description_of_optimistation_of_waste_management',
    datapoint_id: 'E5-2_06',
    esg_category: 'environmental',
    name_en: 'Description of Optimistation of waste management',
    name_es: 'Descripción de Optimización de la gestión de residuos',
    name_pt: 'Descrição do Otimização da gestão de resíduos'
  },
  {
    id: '4f22183c-5230-4cfd-a3d8-b17092799299',
    name_dcycle:
      'information_about_collective_action_on_development_of_collaborations_or_initiatives_increasing_circularity_of_products_and_materials',
    datapoint_id: 'E5-2_07',
    esg_category: 'environmental',
    name_en:
      'Information about collective action on development of collaborations or initiatives increasing circularity of products and materials',
    name_es:
      'Información sobre acciones colectivas para el desarrollo de colaboraciones o iniciativas que aumenten la circularidad de productos y materiales.',
    name_pt:
      'Informações sobre ações coletivas no desenvolvimento de colaborações ou iniciativas que aumentem a circularidade de produtos e materiais'
  },
  {
    id: '3d631337-9859-48c2-9db5-678cfcf226d7',
    name_dcycle: 'description_of_contribution_to_circular_economy',
    datapoint_id: 'E5-2_08',
    esg_category: 'environmental',
    name_en: 'Description of contribution to circular economy',
    name_es: 'Descripción de la contribución a la economía circular',
    name_pt: 'Descrição da contribuição para a economia circular'
  },
  {
    id: '040b0f3e-3fa4-4a26-8675-54aba54c14a8',
    name_dcycle:
      'description_of_other_stakeholders_involved_in_collective_action_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-2_09',
    esg_category: 'environmental',
    name_en:
      'Description of other stakeholders involved in collective action (resource use and circular economy)',
    name_es:
      'Descripción de otros actores involucrados en la acción colectiva (uso de recursos y economía circular)',
    name_pt:
      'Descrição de outras partes interessadas envolvidas na ação coletiva (utilização de recursos e economia circular)'
  },
  {
    id: '3b65a0d4-0207-415b-b631-f994c640b140',
    name_dcycle: 'description_of_organisation_of_project_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-2_10',
    esg_category: 'environmental',
    name_en: 'Description of organisation of project (resource use and circular economy)',
    name_es: 'Descripción de la organización del proyecto (uso de recursos y economía circular)',
    name_pt: 'Descrição da organização do projeto (utilização de recursos e economia circular)'
  },
  {
    id: '856db694-e8bb-4fcd-8c42-ed417b2067e6',
    name_dcycle:
      'disclosure_of_how_target_relates_to_resources_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-3_01',
    esg_category: 'environmental',
    name_en: 'Disclosure of how target relates to resources (resource use and circular economy)',
    name_es:
      'Divulgación de cómo el objetivo se relaciona con los recursos (uso de recursos y economía circular)',
    name_pt:
      'Divulgação de como a meta se relaciona com os recursos (uso de recursos e economia circular)'
  },
  {
    id: 'ba30f2d7-9efd-4efa-8042-ddfd58f776f5',
    name_dcycle: 'disclosure_of_how_target_relates_to_increase_of_circular_design',
    datapoint_id: 'E5-3_02',
    esg_category: 'environmental',
    name_en: 'Disclosure of how target relates to increase of circular design',
    name_es: 'Divulgación de cómo el objetivo se relaciona con el aumento del diseño circular',
    name_pt: 'Divulgação de como a meta se relaciona com o aumento do design circular'
  },
  {
    id: 'ec45082f-d69c-4a45-becd-f9593b26df50',
    name_dcycle: 'disclosure_of_how_target_relates_to_increase_of_circular_material_use_rate',
    datapoint_id: 'E5-3_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of how target relates to increase of circular material use rate',
    name_es:
      'Divulgación de cómo el objetivo se relaciona con el aumento de la tasa de uso de material circular',
    name_pt:
      'Divulgação de como a meta se relaciona com o aumento da taxa de uso de materiais circulares'
  },
  {
    id: '61b6174d-91bd-4554-aac9-2038d0e195c1',
    name_dcycle: 'disclosure_of_how_target_relates_to_minimisation_of_primary_raw_material',
    datapoint_id: 'E5-3_04',
    esg_category: 'environmental',
    name_en: 'Disclosure of how target relates to minimisation of primary raw material',
    name_es:
      'Divulgación de cómo el objetivo se relaciona con la minimización de la materia prima primaria.',
    name_pt: 'Divulgação de como a meta se relaciona com a minimização de matéria-prima primária'
  },
  {
    id: 'ef2391ee-ac7e-4bb4-9c9b-e70f148ef52a',
    name_dcycle:
      'disclosure_of_how_target_relates_to_reversal_of_depletion_of_stock_of_renewable_resources',
    datapoint_id: 'E5-3_05',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how target relates to reversal of depletion of stock of renewable resources',
    name_es:
      'Divulgación de cómo el objetivo se relaciona con la reversión del agotamiento de las reservas de recursos renovables.',
    name_pt:
      'Divulgação de como a meta se relaciona com a reversão do esgotamento do estoque de recursos renováveis'
  },
  {
    id: 'b8736c67-1df9-4258-9078-77617a6b0da4',
    name_dcycle: 'target_relates_to_waste_management',
    datapoint_id: 'E5-3_06',
    esg_category: 'environmental',
    name_en: 'Target relates to waste management',
    name_es: 'El objetivo se relaciona con la gestión de residuos.',
    name_pt: 'Meta está relacionada à gestão de resíduos'
  },
  {
    id: 'c638a2ff-b989-4f9f-9812-958a079cef21',
    name_dcycle: 'disclosure_of_how_target_relates_to_waste_management',
    datapoint_id: 'E5-3_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of how target relates to waste management',
    name_es: 'Divulgación de cómo el objetivo se relaciona con la gestión de residuos.',
    name_pt: 'Divulgação de como a meta se relaciona com a gestão de resíduos'
  },
  {
    id: '2644f969-3368-472b-9cc2-5efeb89d3e0c',
    name_dcycle:
      'disclosure_of_how_target_relates_to_other_matters_related_to_resource_use_or_circular_economy',
    datapoint_id: 'E5-3_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how target relates to other matters related to resource use or circular economy',
    name_es:
      'Divulgación de cómo el objetivo se relaciona con otros asuntos relacionados con el uso de recursos o la economía circular.',
    name_pt:
      'Divulgação de como a meta se relaciona com outras questões relacionadas ao uso de recursos ou à economia circular'
  },
  {
    id: '83a5aff1-3153-407e-8980-e2aa63a75aae',
    name_dcycle: 'layer_in_waste_hierarchy_to_which_target_relates',
    datapoint_id: 'E5-3_09',
    esg_category: 'environmental',
    name_en: 'Layer in waste hierarchy to which target relates',
    name_es: 'Capa en la jerarquía de residuos con la que se relaciona el objetivo',
    name_pt: 'Camada na hierarquia de resíduos à qual o alvo se refere'
  },
  {
    id: '78020206-4893-4f67-99a2-6e0614c15e64',
    name_dcycle:
      'disclosure_of_ecological_threshold_identified_and_methodology_used_to_identify_ecological_threshold_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-3_10',
    esg_category: 'environmental',
    name_en:
      'Disclosure of ecological threshold identified and methodology used to identify ecological threshold (resource use and circular economy)',
    name_es:
      'Divulgación del umbral ecológico identificado y metodología utilizada para identificar el umbral ecológico (uso de recursos y economía circular)',
    name_pt:
      'Divulgação do limiar ecológico identificado e metodologia utilizada para identificar o limiar ecológico (utilização de recursos e economia circular)'
  },
  {
    id: '302d0a4c-db29-4c55-8842-b7d652312261',
    name_dcycle:
      'disclosure_of_how_ecological_entity-specific_threshold_was_determined_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-3_11',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how ecological entity-specific threshold was determined (resource use and circular economy)',
    name_es:
      'Divulgación de cómo se determinó el umbral ecológico específico de la entidad (uso de recursos y economía circular)',
    name_pt:
      'Divulgação de como foi determinado o limiar específico da entidade ecológica (utilização de recursos e economia circular)'
  },
  {
    id: 'fc93157c-e73c-4d3f-a2cd-7e035c82e00b',
    name_dcycle:
      'disclosure_of_how_responsibility_for_respecting_identified_ecological_threshold_is_allocated_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-3_12',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how responsibility for respecting identified ecological threshold is allocated (resource use and circular economy)',
    name_es:
      'Divulgación de cómo se asigna la responsabilidad de respetar el umbral ecológico identificado (uso de recursos y economía circular)',
    name_pt:
      'Divulgação de como é atribuída a responsabilidade pelo respeito do limiar ecológico identificado (utilização de recursos e economia circular)'
  },
  {
    id: '430aa27d-95fe-4465-aeec-bea2260aec21',
    name_dcycle: 'the_targets_being_set_and_presented_are_mandatory_(required_by_legislation)',
    datapoint_id: 'E5-3_13',
    esg_category: 'environmental',
    name_en: 'The targets being set and presented are mandatory (required by legislation)',
    name_es:
      'Los objetivos que se establecen y presentan son obligatorios (exigidos por la legislación)',
    name_pt: 'As metas definidas e apresentadas são obrigatórias (exigidas por legislação)'
  },
  {
    id: '2fa4ceef-b596-4fb1-afc5-1448512e77b5',
    name_dcycle: 'disclosure_of_information_on_material_resource_inflows',
    datapoint_id: 'E5-4_01',
    esg_category: 'environmental',
    name_en: 'Disclosure of information on material resource inflows',
    name_es: 'Divulgación de información sobre entradas de recursos materiales.',
    name_pt: 'Divulgação de informações sobre fluxos de recursos materiais'
  },
  {
    id: 'caa46598-8bfd-4faa-b1aa-5d35a0034ad5',
    name_dcycle:
      'overall_total_weight_of_products_and_technical_and_biological_materials_used_during_the_reporting_period',
    datapoint_id: 'E5-4_02',
    esg_category: 'environmental',
    name_en:
      'Overall total weight of products and technical and biological materials used during the reporting period',
    name_es:
      'Peso total general de productos y materiales técnicos y biológicos utilizados durante el período del informe.',
    name_pt:
      'Peso total global de produtos e materiais técnicos e biológicos utilizados durante o período coberto pelo relatório'
  },
  {
    id: '9232fb4c-51c5-4659-9cf0-ff1421770cf1',
    name_dcycle: 'percentage_of_biological_materials_(and_biofuels_used_for_non-energy_purposes)',
    datapoint_id: 'E5-4_03',
    esg_category: 'environmental',
    name_en: 'Percentage of biological materials (and biofuels used for non-energy purposes)',
    name_es:
      'Porcentaje de materiales biológicos (y biocombustibles utilizados con fines no energéticos)',
    name_pt:
      'Percentagem de materiais biológicos (e biocombustíveis utilizados para fins não energéticos)'
  },
  {
    id: 'c34057ac-0092-4418-a5c7-d58c8d01244e',
    name_dcycle:
      'the_absolute_weight_of_secondary_reused_or_recycled_components,_secondary_intermediary_products_and_secondary_materials_used_to_manufacture_the_undertaking’s_products_and_services_(including_packaging)',
    datapoint_id: 'E5-4_04',
    esg_category: 'environmental',
    name_en:
      'The absolute weight of secondary reused or recycled components, secondary intermediary products and secondary materials used to manufacture the undertaking’s products and services (including packaging)',
    name_es:
      'El peso absoluto de los componentes secundarios reutilizados o reciclados, los productos intermedios secundarios y los materiales secundarios utilizados para fabricar los productos y servicios de la empresa (incluido el embalaje).',
    name_pt:
      'O peso absoluto dos componentes secundários reutilizados ou reciclados, dos produtos intermediários secundários e dos materiais secundários utilizados no fabrico dos produtos e serviços da empresa (incluindo embalagens)'
  },
  {
    id: '6c22a599-b9ff-4e00-8c70-dfc194e49f7a',
    name_dcycle:
      'percentage_of_secondary_reused_or_recycled_components,_secondary_intermediary_products_and_secondary_materials',
    datapoint_id: 'E5-4_05',
    esg_category: 'environmental',
    name_en:
      'Percentage of secondary reused or recycled components, secondary intermediary products and secondary materials',
    name_es:
      'Porcentaje de componentes secundarios reutilizados o reciclados, productos intermedios secundarios y materiales secundarios',
    name_pt:
      'Porcentagem de componentes secundários reutilizados ou reciclados, produtos intermediários secundários e materiais secundários'
  },
  {
    id: 'd2710009-b37e-406a-b56e-331d7bfcbc1f',
    name_dcycle: 'description_of_methodologies_used_to_calculate_data_and_key_assumptions_used',
    datapoint_id: 'E5-4_06',
    esg_category: 'environmental',
    name_en: 'Description of methodologies used to calculate data and key assumptions used',
    name_es:
      'Descripción de las metodologías utilizadas para calcular los datos y supuestos clave utilizados.',
    name_pt:
      'Descrição das metodologias utilizadas para calcular os dados e principais premissas utilizadas'
  },
  {
    id: '477423a2-8372-4414-abd8-e9d77589fd46',
    name_dcycle: 'description_of_materials_that_are_sourced_from_by-products_or_waste_stream',
    datapoint_id: 'E5-4_07',
    esg_category: 'environmental',
    name_en: 'Description of materials that are sourced from by-products or waste stream',
    name_es: 'Descripción de materiales que provienen de subproductos o flujo de residuos.',
    name_pt: 'Descrição de materiais provenientes de subprodutos ou fluxo de resíduos'
  },
  {
    id: '10b3b79f-acdf-4245-a35f-d9975a1f1baf',
    name_dcycle: 'description_of_how_double_counting_was_avoided_and_of_choices_made',
    datapoint_id: 'E5-4_08',
    esg_category: 'environmental',
    name_en: 'Description of how double counting was avoided and of choices made',
    name_es: 'Descripción de cómo se evitó la doble contabilización y de las decisiones tomadas',
    name_pt: 'Descrição de como a dupla contagem foi evitada e das escolhas feitas'
  },
  {
    id: '51059eb2-ed47-48a9-bcea-a504f61b4914',
    name_dcycle:
      'description_of_the_key_products_and_materials_that_come_out_of_the_undertaking’s_production_process',
    datapoint_id: 'E5-5_01',
    esg_category: 'environmental',
    name_en:
      'Description of the key products and materials that come out of the undertaking’s production process',
    name_es:
      'Descripción de los productos y materiales clave que surgen del proceso productivo del emprendimiento.',
    name_pt:
      'Descrição dos principais produtos e materiais provenientes do processo produtivo da empresa'
  },
  {
    id: 'f93e9137-4868-4523-97ef-6db2a582d1d0',
    name_dcycle:
      'disclosure_of_the_expected_durability_of_the_products_placed_on_the_market,_in_relation_to_the_industry_average_for_each_product_group',
    datapoint_id: 'E5-5_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of the expected durability of the products placed on the market, in relation to the industry average for each product group',
    name_es:
      'Divulgación de la durabilidad esperada de los productos comercializados, en relación con el promedio de la industria para cada grupo de productos.',
    name_pt:
      'Divulgação da durabilidade esperada dos produtos colocados no mercado, em relação à média da indústria para cada grupo de produtos'
  },
  {
    id: '314cc9b1-fe88-4ecc-b52b-795d83164466',
    name_dcycle: 'disclosure_of_the_reparability_of_products',
    datapoint_id: 'E5-5_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of the reparability of products',
    name_es: 'Divulgación de la reparabilidad de los productos.',
    name_pt: 'Divulgação da reparabilidade dos produtos'
  },
  {
    id: '34540f01-c6dd-4614-b9b0-0be2d10f5b10',
    name_dcycle: 'the_rates_of_recyclable_content_in_products',
    datapoint_id: 'E5-5_04',
    esg_category: 'environmental',
    name_en: 'The rates of recyclable content in products',
    name_es: 'Las tasas de contenido reciclable en los productos.',
    name_pt: 'As taxas de conteúdo reciclável em produtos'
  },
  {
    id: 'b02cd4c3-7c10-4e0a-be68-3e418dfb408c',
    name_dcycle: 'the_rates_of_recyclable_content_in_products_packaging',
    datapoint_id: 'E5-5_05',
    esg_category: 'environmental',
    name_en: 'The rates of recyclable content in products packaging',
    name_es: 'Las tasas de contenido reciclable en los envases de productos.',
    name_pt: 'As taxas de conteúdo reciclável nas embalagens dos produtos'
  },
  {
    id: '1675d1f3-5479-4fef-8c7f-4c585690a140',
    name_dcycle: 'description_of_methodologies_used_to_calculate_data_(resource_outflows)',
    datapoint_id: 'E5-5_06',
    esg_category: 'environmental',
    name_en: 'Description of methodologies used to calculate data (resource outflows)',
    name_es:
      'Descripción de las metodologías utilizadas para calcular los datos (salidas de recursos)',
    name_pt: 'Descrição das metodologias utilizadas para cálculo de dados (saídas de recursos)'
  },
  {
    id: '2bcd5b12-b6f3-4b8a-96df-868b1fced5cf',
    name_dcycle: 'total_waste_generated',
    datapoint_id: 'E5-5_07',
    esg_category: 'environmental',
    name_en: 'Total Waste generated',
    name_es: 'Total de residuos generados',
    name_pt: 'Resíduos totais gerados'
  },
  {
    id: 'e81710a4-82cd-4225-b451-5ccf5f7ca320',
    name_dcycle:
      'waste_diverted_from_disposal,_breakdown_by_hazardous_and_non-hazardous_waste_and_treatment_type',
    datapoint_id: 'E5-5_08',
    esg_category: 'environmental',
    name_en:
      'Waste diverted from disposal, breakdown by hazardous and non-hazardous waste and treatment type',
    name_es:
      'Residuos desviados de su disposición final, desglosados ​​por residuos peligrosos y no peligrosos y tipo de tratamiento',
    name_pt:
      'Resíduos desviados de eliminação, discriminação por resíduos perigosos e não perigosos e tipo de tratamento'
  },
  {
    id: '7f524dcb-5c68-4e6a-b710-20483c8916bd',
    name_dcycle:
      'waste_directed_to_disposal,_breakdown_by_hazardous_and_non-hazardous_waste_and_treatment_type',
    datapoint_id: 'E5-5_09',
    esg_category: 'environmental',
    name_en:
      'Waste directed to disposal, breakdown by hazardous and non-hazardous waste and treatment type',
    name_es:
      'Residuos destinados a disposición, desglose por residuos peligrosos y no peligrosos y tipo de tratamiento',
    name_pt:
      'Resíduos destinados a eliminação, discriminação por resíduos perigosos e não perigosos e tipo de tratamento'
  },
  {
    id: 'dc6005d7-9907-49ec-b145-f8aa2b357ed6',
    name_dcycle: 'non-recycled_waste',
    datapoint_id: 'E5-5_10',
    esg_category: 'environmental',
    name_en: 'Non-recycled waste',
    name_es: 'Residuos no reciclados',
    name_pt: 'Resíduos não reciclados'
  },
  {
    id: '9bb045ea-374e-4b63-a7eb-716ce8a4a726',
    name_dcycle: 'percentage_of_non-recycled_waste',
    datapoint_id: 'E5-5_11',
    esg_category: 'environmental',
    name_en: 'Percentage of non-recycled waste',
    name_es: 'Porcentaje de residuos no reciclados',
    name_pt: 'Porcentagem de resíduos não reciclados'
  },
  {
    id: 'abfcac48-f388-4578-b193-0f98cefcf3ed',
    name_dcycle: 'disclosure_of_composition_of_waste',
    datapoint_id: 'E5-5_12',
    esg_category: 'environmental',
    name_en: 'Disclosure of composition of waste',
    name_es: 'Divulgación de la composición de los residuos.',
    name_pt: 'Divulgação da composição dos resíduos'
  },
  {
    id: 'a1a330ef-946e-4324-9e53-eeb0ec44ad2f',
    name_dcycle: "disclosure_of_waste_streams_relevant_to_undertaking's_sector_or_activities",
    datapoint_id: 'E5-5_13',
    esg_category: 'environmental',
    name_en: "Disclosure of waste streams relevant to undertaking's sector or activities",
    name_es:
      'Divulgación de flujos de residuos relevantes para el sector o las actividades de la empresa.',
    name_pt: 'Divulgação de fluxos de resíduos relevantes para o setor ou atividades da empresa'
  },
  {
    id: 'f8045604-c2b2-4bd7-8d02-742796e7de50',
    name_dcycle: 'disclosure_of_materials_that_are_present_in_waste',
    datapoint_id: 'E5-5_14',
    esg_category: 'environmental',
    name_en: 'Disclosure of materials that are present in waste',
    name_es: 'Divulgación de materiales que están presentes en los residuos.',
    name_pt: 'Divulgação de materiais que estão presentes nos resíduos'
  },
  {
    id: '6a4a6162-6242-4f5f-a030-c0a0b372e179',
    name_dcycle: 'total_amount_of_hazardous_waste',
    datapoint_id: 'E5-5_15',
    esg_category: 'environmental',
    name_en: 'Total amount of hazardous waste',
    name_es: 'Cantidad total de residuos peligrosos',
    name_pt: 'Quantidade total de resíduos perigosos'
  },
  {
    id: 'e94c1c0c-0bf2-4753-871a-e43132e4a874',
    name_dcycle: 'total_amount_of_radioactive_waste',
    datapoint_id: 'E5-5_16',
    esg_category: 'environmental',
    name_en: 'Total amount of radioactive waste',
    name_es: 'Cantidad total de residuos radiactivos',
    name_pt: 'Quantidade total de resíduos radioativos'
  },
  {
    id: '1438f4a7-41d4-46d5-8864-e5dd90e16f1f',
    name_dcycle: 'description_of_methodologies_used_to_calculate_data_(waste_generated)',
    datapoint_id: 'E5-5_17',
    esg_category: 'environmental',
    name_en: 'Description of methodologies used to calculate data (waste generated)',
    name_es: 'Descripción de metodologías utilizadas para el cálculo de datos (residuos generados)',
    name_pt: 'Descrição das metodologias utilizadas para cálculo dos dados (resíduos gerados)'
  },
  {
    id: '70b30853-892c-42e4-9035-716a402d387f',
    name_dcycle: 'disclosure_of_its_engagement_in_product_end-of-life_waste_management',
    datapoint_id: 'E5-5_18',
    esg_category: 'environmental',
    name_en: 'Disclosure of its engagement in product end-of-life waste management',
    name_es:
      'Divulgación de su compromiso con la gestión de residuos de productos al final de su vida útil',
    name_pt: 'Divulgação do seu envolvimento na gestão de resíduos de produtos em fim de vida'
  },
  {
    id: '1d0b3677-8226-4c84-b5ac-15c59da26cd6',
    name_dcycle:
      'disclosure_of_quantitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_resource_use_and_circular_economy-related_impacts',
    datapoint_id: 'E5-6_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of quantitative information about anticipated financial effects of material risks and opportunities arising from resource use and circular economy-related impacts',
    name_es:
      'Divulgación de información cuantitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen del uso de recursos y los impactos relacionados con la economía circular.',
    name_pt:
      'Divulgação de informações quantitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes do uso de recursos e impactos relacionados à economia circular'
  },
  {
    id: '0149a690-3ff6-495b-afad-539e5745d7f3',
    name_dcycle:
      'disclosure_of_qualitative_information_of_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_resource_use_and_circular_economy-related_impacts',
    datapoint_id: 'E5-6_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of qualitative information of anticipated financial effects of material risks and opportunities arising from resource use and circular economy-related impacts',
    name_es:
      'Divulgación de información cualitativa de los efectos financieros previstos de los riesgos y oportunidades materiales que surgen del uso de recursos y los impactos relacionados con la economía circular.',
    name_pt:
      'Divulgação de informações qualitativas sobre efeitos financeiros previstos de riscos e oportunidades materiais decorrentes do uso de recursos e impactos relacionados à economia circular'
  },
  {
    id: '5099085e-c363-4422-91f5-a24faeae2361',
    name_dcycle:
      'description_of_effects_considered_and_related_impacts_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-6_03',
    esg_category: 'environmental',
    name_en:
      'Description of effects considered and related impacts (resource use and circular economy)',
    name_es:
      'Descripción de los efectos considerados e impactos relacionados (uso de recursos y economía circular)',
    name_pt:
      'Descrição dos efeitos considerados e impactos relacionados (utilização de recursos e economia circular)'
  },
  {
    id: '36f3679f-904a-4f54-b6b9-0f11a22b9bf9',
    name_dcycle:
      'disclosure_of_critical_assumptions_used_in_estimates_of_financial_effects_of_material_risks_and_opportunities_arising_from_resource_use_and_circular_economy-related_impacts',
    datapoint_id: 'E5-6_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of critical assumptions used in estimates of financial effects of material risks and opportunities arising from resource use and circular economy-related impacts',
    name_es:
      'Divulgación de supuestos críticos utilizados en las estimaciones de los efectos financieros de los riesgos y oportunidades materiales que surgen del uso de recursos y los impactos relacionados con la economía circular.',
    name_pt:
      'Divulgação de pressupostos críticos utilizados nas estimativas de efeitos financeiros de riscos e oportunidades materiais decorrentes da utilização de recursos e impactos relacionados com a economia circular'
  },
  {
    id: '62d24e3c-b995-4784-a6b1-760bb2a1424f',
    name_dcycle:
      'description_of_related_products_and_services_at_risk_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-6_05',
    esg_category: 'environmental',
    name_en:
      'Description of related products and services at risk (resource use and circular economy)',
    name_es:
      'Descripción de productos y servicios relacionados en riesgo (uso de recursos y economía circular)',
    name_pt:
      'Descrição dos produtos e serviços relacionados em risco (utilização de recursos e economia circular)'
  },
  {
    id: '46e92ee8-e3bf-4fa4-b928-49f41e3e556c',
    name_dcycle:
      'explanation_of_how_time_horizons_are_defined,_financial_amounts_are_estimated_and_of_critical_assumptions_made_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-6_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of how time horizons are defined, financial amounts are estimated and of critical assumptions made (resource use and circular economy)',
    name_es:
      'Explicación de cómo se definen los horizontes temporales, se estiman los montos financieros y se realizan los supuestos críticos (uso de recursos y economía circular)',
    name_pt:
      'Explicação de como os horizontes temporais são definidos, os montantes financeiros são estimados e os pressupostos críticos assumidos (utilização de recursos e economia circular)'
  }
];

export const governanceDatapoints = [
  {
    id: '5be1182b-e589-4709-8abc-eef5da130bb6',
    name_dcycle:
      'disclosure_of_whether_and_how_climate-related_considerations_are_factored_into_remuneration_of_members_of_administrative,_management_and_supervisory_bodies',
    datapoint_id: 'E1.GOV-3_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how climate-related considerations are factored into remuneration of members of administrative, management and supervisory bodies',
    name_es:
      'Divulgación de si y cómo se tienen en cuenta consideraciones relacionadas con el clima en la remuneración de los miembros de los órganos de administración, gestión y supervisión.',
    name_pt:
      'Divulgação sobre se e como as considerações relacionadas com o clima sãotidas em conta na remuneração dos membros dos órgãos de administração, gestão e supervisão'
  },
  {
    id: '647808a3-d3dc-4b92-929d-6e14acbc94c0',
    name_dcycle:
      'percentage_of_remuneration_recognised_that_is_linked_to_climate_related_considerations',
    datapoint_id: 'E1.GOV-3_02',
    esg_category: 'environmental',
    name_en:
      'Percentage of remuneration recognised that is linked to climate related considerations',
    name_es:
      'Porcentaje de remuneración reconocida que está vinculada a consideraciones relacionadas con el clima',
    name_pt:
      'Percentagem de remuneração reconhecida que está ligada a considerações relacionadas com o clima'
  },
  {
    id: 'd8ad9c8d-d55e-4017-899d-8e235e1ed9c9',
    name_dcycle:
      'explanation_of_climate-related_considerations_that_are_factored_into_remuneration_of_members_of_administrative,_management_and_supervisory_bodies',
    datapoint_id: 'E1.GOV-3_03',
    esg_category: 'environmental',
    name_en:
      'Explanation of climate-related considerations that are factored into remuneration of members of administrative, management and supervisory bodies',
    name_es:
      'Explicación de las consideraciones relacionadas con el clima que se tienen en cuenta en la remuneración de los miembros de los órganos de administración, dirección y supervisión.',
    name_pt:
      'Explicação das considerações relacionadas com o clima que são tidas em conta na remuneração dos membros dos órgãos de administração, gestão e supervisão'
  },
  {
    id: 'dd9a21fe-8c43-4306-83a4-e36295b0f8bb',
    name_dcycle: 'disclosure_of_transition_plan_for_climate_change_mitigation',
    datapoint_id: 'E1-1_01',
    esg_category: 'environmental',
    name_en: 'Disclosure of transition plan for climate change mitigation',
    name_es: 'Divulgación del plan de transición para la mitigación del cambio climático',
    name_pt: 'Divulgação do plano de transição para mitigação das alterações climáticas'
  },
  {
    id: '167eaf79-d9d3-4d32-8657-d77f69e6810a',
    name_dcycle:
      'explanation_of_how_targets_are_compatible_with_limiting_of_global_warming_to_one_and_half_degrees_celsius_in_line_with_paris_agreement',
    datapoint_id: 'E1-1_02',
    esg_category: 'environmental',
    name_en:
      'Explanation of how targets are compatible with limiting of global warming to one and half degrees Celsius in line with Paris Agreement',
    name_es:
      'Explicación de cómo los objetivos son compatibles con la limitación del calentamiento global a un grado y medio Celsius de acuerdo con el Acuerdo de París',
    name_pt:
      'Explicação de como as metas são compatíveis com a limitação do aquecimento global a um grau e meio Celsius, em linha com o Acordo de Paris'
  },
  {
    id: '29e0c9c0-439a-40d9-ac23-1f38add307f5',
    name_dcycle: 'disclosure_of_decarbonisation_levers_and_key_action',
    datapoint_id: 'E1-1_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of decarbonisation levers and key action',
    name_es: 'Divulgación de palancas de descarbonización y acciones clave',
    name_pt: 'Divulgação de alavancas de descarbonização e ações-chave'
  },
  {
    id: '2bc8455e-3033-43a2-9299-e21211063f39',
    name_dcycle:
      'disclosure_of_significant_operational_expenditures_(opex)_and_(or)_capital_expenditures_(capex)_required_for_implementation_of_action_plan',
    datapoint_id: 'E1-1_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of significant operational expenditures (Opex) and (or) capital expenditures (Capex) required for implementation of action plan',
    name_es:
      'Divulgación de gastos operativos significativos (Opex) y (o) gastos de capital (Capex) necesarios para la implementación del plan de acción.',
    name_pt:
      'Divulgação de despesas operacionais significativas (Opex) e (ou) despesas de capital (Capex) necessárias para implementação do plano de ação'
  },
  {
    id: 'f69ba850-f283-4029-aeda-0abdcd3a6746',
    name_dcycle: 'financial_resources_allocated_to_action_plan_(opex)',
    datapoint_id: 'E1-1_05',
    esg_category: 'environmental',
    name_en: 'Financial resources allocated to action plan (OpEx)',
    name_es: 'Recursos financieros asignados al plan de acción (OpEx)',
    name_pt: 'Recursos financeiros alocados ao plano de ação (OpEx)'
  },
  {
    id: 'cbd16172-c0ec-4b09-9dea-aac0ceb4391f',
    name_dcycle: 'financial_resources_allocated_to_action_plan_(capex)',
    datapoint_id: 'E1-1_06',
    esg_category: 'environmental',
    name_en: 'Financial resources allocated to action plan (CapEx)',
    name_es: 'Recursos financieros asignados al plan de acción (CapEx)',
    name_pt: 'Recursos financeiros destinados ao plano de ação (CapEx)'
  },
  {
    id: '28dafa03-da76-49f1-97aa-c2542b98f290',
    name_dcycle:
      'explanation_of_potential_locked-in_ghg_emissions_from_key_assets_and_products_and_of_how_locked-in_ghg_emissions_may_jeopardise_achievement_of_ghg_emission_reduction_targets_and_drive_transition_risk',
    datapoint_id: 'E1-1_07',
    esg_category: 'environmental',
    name_en:
      'Explanation of potential locked-in GHG emissions from key assets and products and of how locked-in GHG emissions may jeopardise achievement of GHG emission reduction targets and drive transition risk',
    name_es:
      'Explicación de las posibles emisiones bloqueadas de GEI de activos y productos clave y de cómo las emisiones bloqueadas de GEI pueden poner en peligro el logro de los objetivos de reducción de emisiones de GEI e impulsar el riesgo de transición.',
    name_pt:
      'Explicação das potenciais emissões de GEE bloqueadas de ativos e produtos essenciais e de como as emissões de GEE bloqueadas podem comprometer o cumprimento das metas de redução de emissões de GEE e gerar riscos de transição'
  },
  {
    id: 'c6277a07-e6c4-47ca-b6fb-39f79abdbd92',
    name_dcycle:
      'explanation_of_any_objective_or_plans_(capex,_capex_plans,_opex)_for_aligning_economic_activities_(revenues,_capex,_opex)_with_criteria_established_in_commission_delegated_regulation_2021/2139',
    datapoint_id: 'E1-1_08',
    esg_category: 'environmental',
    name_en:
      'Explanation of any objective or plans (CapEx, CapEx plans, OpEx) for aligning economic activities (revenues, CapEx, OpEx) with criteria established in Commission Delegated Regulation 2021/2139',
    name_es:
      'Explicación de cualquier objetivo o plan (CapEx, planes CapEx, OpEx) para alinear las actividades económicas (ingresos, CapEx, OpEx) con los criterios establecidos en el Reglamento Delegado 2021/2139 de la Comisión.',
    name_pt:
      'Explicação de quaisquer objetivos ou planos (CapEx, planos CapEx, OpEx) para alinhar as atividades económicas (receitas, CapEx, OpEx) com os critérios estabelecidos no Regulamento Delegado 2021/2139 da Comissão'
  },
  {
    id: '99069c25-c6ac-4e8e-82b2-1e43441ec441',
    name_dcycle: 'significant_capex_for_coal-related_economic_activities',
    datapoint_id: 'E1-1_09',
    esg_category: 'environmental',
    name_en: 'Significant CapEx for coal-related economic activities',
    name_es: 'CapEx significativo para actividades económicas relacionadas con el carbón',
    name_pt: 'CapEx significativo para atividades económicas relacionadas com o carvão'
  },
  {
    id: '6d786c09-58de-4893-a502-deb042bda70a',
    name_dcycle: 'significant_capex_for_oil-related_economic_activities',
    datapoint_id: 'E1-1_10',
    esg_category: 'environmental',
    name_en: 'Significant CapEx for oil-related economic activities',
    name_es: 'CapEx significativo para actividades económicas relacionadas con el petróleo',
    name_pt: 'CapEx significativo para atividades económicas relacionadas com o petróleo'
  },
  {
    id: 'e619ae7c-8e5f-4467-accd-e602ad1ac875',
    name_dcycle: 'significant_capex_for_gas-related_economic_activities',
    datapoint_id: 'E1-1_11',
    esg_category: 'environmental',
    name_en: 'Significant CapEx for gas-related economic activities',
    name_es: 'CapEx significativo para actividades económicas relacionadas con el gas',
    name_pt: 'CapEx significativo para atividades económicas relacionadas com o gás'
  },
  {
    id: '3dcc788c-b0a4-4cec-ab5f-aead9a16031f',
    name_dcycle: 'undertaking_is_excluded_from_eu_paris-aligned_benchmarks',
    datapoint_id: 'E1-1_12',
    esg_category: 'environmental',
    name_en: 'Undertaking is excluded from EU Paris-aligned Benchmarks',
    name_es:
      'La empresa está excluida de los índices de referencia alineados con el Acuerdo de París de la UE',
    name_pt: 'Empresa é excluída dos benchmarks alinhados com Paris da UE'
  },
  {
    id: '716b45c4-9cb2-4a2f-a590-6dad0989c5ea',
    name_dcycle:
      'explanation_of_how_transition_plan_is_embedded_in_and_aligned_with_overall_business_strategy_and_financial_planning',
    datapoint_id: 'E1-1_13',
    esg_category: 'environmental',
    name_en:
      'Explanation of how transition plan is embedded in and aligned with overall business strategy and financial planning',
    name_es:
      'Explicación de cómo el plan de transición está integrado y alineado con la estrategia comercial general y la planificación financiera.',
    name_pt:
      'Explicação de como o plano de transição está incorporado e alinhado com a estratégia geral de negócios e o planejamento financeiro'
  },
  {
    id: '2e010dd9-f3bf-4a46-86b3-ee827f7845ed',
    name_dcycle: 'transition_plan_is_approved_by_administrative,_management_and_supervisory_bodies',
    datapoint_id: 'E1-1_14',
    esg_category: 'environmental',
    name_en: 'Transition plan is approved by administrative, management and supervisory bodies',
    name_es:
      'El plan de transición es aprobado por los órganos de administración, dirección y supervisión.',
    name_pt: 'Plano de transição é aprovado pelos órgãos de administração, gestão e fiscalização'
  },
  {
    id: '84ac6969-477c-45d3-ac0b-a52d61441f97',
    name_dcycle: 'explanation_of_progress_in_implementing_transition_plan',
    datapoint_id: 'E1-1_15',
    esg_category: 'environmental',
    name_en: 'Explanation of progress in implementing transition plan',
    name_es: 'Explicación del progreso en la implementación del plan de transición.',
    name_pt: 'Explicação do progresso na implementação do plano de transição'
  },
  {
    id: '5064aa42-ec9c-41f1-ac02-3bffec3cf797',
    name_dcycle:
      'date_of_adoption_of_transition_plan_for_undertakings_not_having_adopted_transition_plan_yet',
    datapoint_id: 'E1-1_16',
    esg_category: 'environmental',
    name_en:
      'Date of adoption of transition plan for undertakings not having adopted transition plan yet',
    name_es:
      'Fecha de adopción del plan de transición para las empresas que aún no lo han adoptado',
    name_pt:
      'Data de adoção do plano de transição para empresas que ainda não adotaram o plano de transição'
  },
  {
    id: 'fb7a76cb-aa15-4974-ad6d-bd5331841f87',
    name_dcycle: 'type_of_climate-related_risk',
    datapoint_id: 'E1.SBM-3_01',
    esg_category: 'environmental',
    name_en: 'Type of climate-related risk',
    name_es: 'Tipo de riesgo relacionado con el clima',
    name_pt: 'Tipo de risco relacionado ao clima'
  },
  {
    id: 'de015551-d0c5-4633-82f9-4448f57d7c1c',
    name_dcycle: 'description_of_scope_of_resilience_analysis',
    datapoint_id: 'E1.SBM-3_02',
    esg_category: 'environmental',
    name_en: 'Description of scope of resilience analysis',
    name_es: 'Descripción del alcance del análisis de resiliencia',
    name_pt: 'Descrição do escopo da análise de resiliência'
  },
  {
    id: 'ed1d3e58-f475-46da-8198-c6b1d7ceca8c',
    name_dcycle: 'disclosure_of_how_resilience_analysis_has_been_conducted',
    datapoint_id: 'E1.SBM-3_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of how resilience analysis has been conducted',
    name_es: 'Divulgación de cómo se ha realizado el análisis de resiliencia.',
    name_pt: 'Divulgação de como a análise de resiliência tem sido conduzida'
  },
  {
    id: '23a309a8-c038-4a87-88c0-2b635d96f580',
    name_dcycle: 'disclosure_of_how_resilience_analysis_has_been_conducted',
    datapoint_id: 'E1.SBM-3_04',
    esg_category: 'environmental',
    name_en: 'Disclosure of how resilience analysis has been conducted',
    name_es: 'Divulgación de cómo se ha realizado el análisis de resiliencia.',
    name_pt: 'Divulgação de como a análise de resiliência tem sido conduzida'
  },
  {
    id: '58f58180-b15f-418a-ae78-4342a68d761a',
    name_dcycle: 'time_horizons_applied_for_resilience_analysis',
    datapoint_id: 'E1.SBM-3_05',
    esg_category: 'environmental',
    name_en: 'Time horizons applied for resilience analysis',
    name_es: 'Horizontes temporales aplicados para el análisis de resiliencia',
    name_pt: 'Horizontes temporais aplicados para análise de resiliência'
  },
  {
    id: '0e59ceaf-213a-4733-87ff-f117827e3333',
    name_dcycle: 'description_of_results_of_resilience_analysis',
    datapoint_id: 'E1.SBM-3_06',
    esg_category: 'environmental',
    name_en: 'Description of results of resilience analysis',
    name_es: 'Descripción de los resultados del análisis de resiliencia',
    name_pt: 'Descrição dos resultados da análise de resiliência'
  },
  {
    id: 'cda7a6aa-42d7-42db-a8c3-befa40dfef13',
    name_dcycle:
      'description_of_ability_to_adjust_or_adapt_strategy_and_business_model_to_climate_change',
    datapoint_id: 'E1.SBM-3_07',
    esg_category: 'environmental',
    name_en:
      'Description of ability to adjust or adapt strategy and business model to climate change',
    name_es:
      'Descripción de la capacidad para ajustar o adaptar la estrategia y el modelo de negocio al cambio climático',
    name_pt:
      'Descrição da capacidade de ajustar ou adaptar a estratégia e o modelo de negócio às alterações climáticas'
  },
  {
    id: '3fa52266-80d2-43a6-a9c8-c9e186c7552b',
    name_dcycle: 'description_of_process_in_relation_to_impacts_on_climate_change',
    datapoint_id: 'E1.IRO-1_01',
    esg_category: 'environmental',
    name_en: 'Description of process in relation to impacts on climate change',
    name_es: 'Descripción del proceso en relación a los impactos sobre el cambio climático',
    name_pt: 'Descrição do processo em relação aos impactos nas mudanças climáticas'
  },
  {
    id: 'a90aecb5-ad99-4337-9137-ecd95593d459',
    name_dcycle:
      'description_of_process_in_relation_to_climate-related_physical_risks_in_own_operations_and_along_value_chain',
    datapoint_id: 'E1.IRO-1_02',
    esg_category: 'environmental',
    name_en:
      'Description of process in relation to climate-related physical risks in own operations and along value chain',
    name_es:
      'Descripción del proceso en relación con los riesgos físicos relacionados con el clima en las operaciones propias y a lo largo de la cadena de valor.',
    name_pt:
      'Descrição do processo em relação aos riscos físicos relacionados ao clima nas próprias operações e ao longo da cadeia de valor'
  },
  {
    id: '2581e155-9b69-4da4-a0a8-0a63735e0e2a',
    name_dcycle:
      'climate-related_hazards_have_been_identified_over_short-,_medium-_and_long-term_time_horizons',
    datapoint_id: 'E1.IRO-1_03',
    esg_category: 'environmental',
    name_en:
      'Climate-related hazards have been identified over short-, medium- and long-term time horizons',
    name_es:
      'Se han identificado peligros relacionados con el clima en horizontes temporales de corto, mediano y largo plazo.',
    name_pt:
      'Os perigos relacionados com o clima foram identificados em horizontes de curto, médio e longo prazo'
  },
  {
    id: '5a284855-ece8-46d1-9409-7e0fff76df86',
    name_dcycle:
      'undertaking_has_screened_whether_assets_and_business_activities_may_be_exposed_to_climate-related_hazards',
    datapoint_id: 'E1.IRO-1_04',
    esg_category: 'environmental',
    name_en:
      'Undertaking has screened whether assets and business activities may be exposed to climate-related hazards',
    name_es:
      'La empresa ha examinado si los activos y las actividades comerciales pueden estar expuestos a peligros relacionados con el clima.',
    name_pt:
      'A empresa examinou se os ativos e as atividades empresariais podem estar expostos a riscos relacionados com o clima'
  },
  {
    id: '05cf5828-3b2a-4b39-bc09-91bc12abd1cb',
    name_dcycle: 'short-,_medium-_and_long-term_time_horizons_have_been_defined',
    datapoint_id: 'E1.IRO-1_05',
    esg_category: 'environmental',
    name_en: 'Short-, medium- and long-term time horizons have been defined',
    name_es: 'Se han definido horizontes temporales a corto, medio y largo plazo.',
    name_pt: 'Foram definidos horizontes temporais de curto, médio e longo prazo'
  },
  {
    id: '4d0d6418-8dbe-4592-8715-c174de58b056',
    name_dcycle:
      'extent_to_which_assets_and_business_activities_may_be_exposed_and_are_sensitive_to_identified_climate-related_hazards_has_been_assessed',
    datapoint_id: 'E1.IRO-1_06',
    esg_category: 'environmental',
    name_en:
      'Extent to which assets and business activities may be exposed and are sensitive to identified climate-related hazards has been assessed',
    name_es:
      'Se ha evaluado el grado en que los activos y las actividades comerciales pueden estar expuestos y son sensibles a los peligros relacionados con el clima identificados.',
    name_pt:
      'Foi avaliada a extensão em que os ativos e as atividades empresariais podem estar expostos e são sensíveis aos perigos identificados relacionados com o clima.'
  },
  {
    id: '38964af8-1798-4e90-bb5e-a88c8e11f3c1',
    name_dcycle:
      'identification_of_climate-related_hazards_and_assessment_of_exposure_and_sensitivity_are_informed_by_high_emissions_climate_scenarios',
    datapoint_id: 'E1.IRO-1_07',
    esg_category: 'environmental',
    name_en:
      'Identification of climate-related hazards and assessment of exposure and sensitivity are informed by high emissions climate scenarios',
    name_es:
      'La identificación de peligros relacionados con el clima y la evaluación de la exposición y la sensibilidad se basan en escenarios climáticos de altas emisiones.',
    name_pt:
      'A identificação de perigos relacionados com o clima e a avaliação da exposição e sensibilidade são informadas por cenários climáticos de emissões elevadas'
  },
  {
    id: 'ec4c8dac-f496-450b-9196-eba6839e6d76',
    name_dcycle:
      'explanation_of_how_climate-related_scenario_analysis_has_been_used_to_inform_identification_and_assessment_of_physical_risks_over_short,_medium_and_long-term',
    datapoint_id: 'E1.IRO-1_08',
    esg_category: 'environmental',
    name_en:
      'Explanation of how climate-related scenario analysis has been used to inform identification and assessment of physical risks over short, medium and long-term',
    name_es:
      'Explicación de cómo se ha utilizado el análisis de escenarios relacionados con el clima para informar la identificación y evaluación de riesgos físicos a corto, mediano y largo plazo.',
    name_pt:
      'Explicação de como a análise de cenários relacionados ao clima tem sido usada para informar a identificação e avaliação de riscos físicos no curto, médio e longo prazo'
  },
  {
    id: 'f848012c-05df-4355-9e92-f91ecc21347f',
    name_dcycle:
      'description_of_process_in_relation_to_climate-related_transition_risks_and_opportunities_in_own_operations_and_along_value_chain',
    datapoint_id: 'E1.IRO-1_09',
    esg_category: 'environmental',
    name_en:
      'Description of process in relation to climate-related transition risks and opportunities in own operations and along value chain',
    name_es:
      'Descripción del proceso en relación con los riesgos y oportunidades de transición relacionados con el clima en las operaciones propias y a lo largo de la cadena de valor.',
    name_pt:
      'Descrição do processo em relação aos riscos e oportunidades de transição relacionados com o clima nas próprias operações e ao longo da cadeia de valor'
  },
  {
    id: 'b26ea4f6-580e-4189-b663-7478e212f9c1',
    name_dcycle:
      'transition_events_have_been_identified_over_short-,_medium-_and_long-term_time_horizons',
    datapoint_id: 'E1.IRO-1_10',
    esg_category: 'environmental',
    name_en:
      'Transition events have been identified over short-, medium- and long-term time horizons',
    name_es:
      'Se han identificado eventos de transición en horizontes temporales de corto, mediano y largo plazo.',
    name_pt: 'Eventos de transição foram identificados em horizontes de curto, médio e longo prazo'
  },
  {
    id: 'c978b6d0-6a1d-4191-95fa-dff2b4ca14b3',
    name_dcycle:
      'undertaking_has_screened_whether_assets_and_business_activities_may_be_exposed_to_transition_events',
    datapoint_id: 'E1.IRO-1_11',
    esg_category: 'environmental',
    name_en:
      'Undertaking has screened whether assets and business activities may be exposed to transition events',
    name_es:
      'La empresa ha examinado si los activos y las actividades comerciales pueden estar expuestos a eventos de transición.',
    name_pt:
      'A empresa analisou se os ativos e as atividades empresariais podem estar expostos a eventos de transição'
  },
  {
    id: '370295d2-c4d4-46a0-9b94-bcbe4981d2d8',
    name_dcycle:
      'extent_to_which_assets_and_business_activities_may_be_exposed_and_are_sensitive_to_identified_transition_events_has_been_assessed',
    datapoint_id: 'E1.IRO-1_12',
    esg_category: 'environmental',
    name_en:
      'Extent to which assets and business activities may be exposed and are sensitive to identified transition events has been assessed',
    name_es:
      'Se ha evaluado el grado en que los activos y las actividades comerciales pueden estar expuestos y son sensibles a eventos de transición identificados.',
    name_pt:
      'Foi avaliada a extensão em que os ativos e atividades comerciais podem estar expostos e são sensíveis a eventos de transição identificados'
  },
  {
    id: '5d29de55-a7da-48fa-83de-c11c173ddfd7',
    name_dcycle:
      'identification_of_transition_events_and_assessment_of_exposure_has_been_informed_by_climate-related_scenario_analysis',
    datapoint_id: 'E1.IRO-1_13',
    esg_category: 'environmental',
    name_en:
      'Identification of transition events and assessment of exposure has been informed by climate-related scenario analysis',
    name_es:
      'La identificación de eventos de transición y la evaluación de la exposición se han basado en análisis de escenarios relacionados con el clima.',
    name_pt:
      'A identificação de eventos de transição e a avaliação da exposição foram informadas pela análise de cenários relacionados com o clima'
  },
  {
    id: '94513e1a-c4c0-4ab6-8a37-1b2bb525c342',
    name_dcycle:
      'assets_and_business_activities_that_are_incompatible_with_or_need_significant_efforts_to_be_compatible_with_transition_to_climate-neutral_economy_have_been_identified',
    datapoint_id: 'E1.IRO-1_14',
    esg_category: 'environmental',
    name_en:
      'Assets and business activities that are incompatible with or need significant efforts to be compatible with transition to climate-neutral economy have been identified',
    name_es:
      'Se han identificado activos y actividades comerciales que son incompatibles con la transición a una economía climáticamente neutra o que necesitan esfuerzos significativos para ser compatibles con ella.',
    name_pt:
      'Foram identificados ativos e atividades empresariais que são incompatíveis ou que necessitam de esforços significativos para serem compatíveis com a transição para uma economia com impacto neutro no clima'
  },
  {
    id: 'b1df30ad-1691-4c89-8a4a-c76ecf37224e',
    name_dcycle:
      'explanation_of_how_climate-related_scenario_analysis_has_been_used_to_inform_identification_and_assessment_of_transition_risks_and_opportunities_over_short,_medium_and_long-term',
    datapoint_id: 'E1.IRO-1_15',
    esg_category: 'environmental',
    name_en:
      'Explanation of how climate-related scenario analysis has been used to inform identification and assessment of transition risks and opportunities over short, medium and long-term',
    name_es:
      'Explicación de cómo se ha utilizado el análisis de escenarios relacionados con el clima para informar la identificación y evaluación de los riesgos y oportunidades de transición a corto, mediano y largo plazo.',
    name_pt:
      'Explicação de como a análise de cenários relacionados ao clima tem sido usada para informar a identificação e avaliação de riscos e oportunidades de transição no curto, médio e longo prazo'
  },
  {
    id: '8a3318c1-7fba-4022-92ef-606e712b7f5c',
    name_dcycle:
      'explanation_of_how_climate_scenarios_used_are_compatible_with_critical_climate-related_assumptions_made_in_financial_statements',
    datapoint_id: 'E1.IRO-1_16',
    esg_category: 'environmental',
    name_en:
      'Explanation of how climate scenarios used are compatible with critical climate-related assumptions made in financial statements',
    name_es:
      'Explicación de cómo los escenarios climáticos utilizados son compatibles con los supuestos críticos relacionados con el clima hechos en los estados financieros.',
    name_pt:
      'Explicação de como os cenários climáticos utilizados são compatíveis com as premissas críticas relacionadas ao clima feitas nas demonstrações financeiras'
  },
  {
    id: 'ed19f583-a1ad-4e91-b914-98d43e4e8e98',
    name_dcycle: 'sustainability_matters_addressed_by_policy_for_climate_change',
    datapoint_id: 'E1-2_01',
    esg_category: 'environmental',
    name_en: 'Sustainability matters addressed by policy for climate change',
    name_es: 'Cuestiones de sostenibilidad abordadas por la política de cambio climático',
    name_pt: 'Questões de sustentabilidade abordadas pela política para as alterações climáticas'
  },
  {
    id: 'd6c6b91a-f2b6-4524-8927-9f37d9d72e68',
    name_dcycle: 'decarbonisation_lever_type',
    datapoint_id: 'E1-3_01',
    esg_category: 'environmental',
    name_en: 'Decarbonisation lever type',
    name_es: 'Tipo palanca de descarbonización',
    name_pt: 'Tipo de alavanca de descarbonização'
  },
  {
    id: '83a9a60e-d826-4344-9665-41741e3c2500',
    name_dcycle: 'adaptation_solution_type',
    datapoint_id: 'E1-3_02',
    esg_category: 'environmental',
    name_en: 'Adaptation solution type',
    name_es: 'Tipo de solución de adaptación',
    name_pt: 'Tipo de solução de adaptação'
  },
  {
    id: 'bba73b06-8f1a-4243-bc7b-f951d5378a86',
    name_dcycle: 'achieved_ghg_emission_reductions',
    datapoint_id: 'E1-3_03',
    esg_category: 'environmental',
    name_en: 'Achieved GHG emission reductions',
    name_es: 'Reducciones de emisiones de GEI logradas',
    name_pt: 'Reduções de emissões de GEE alcançadas'
  },
  {
    id: 'bd0163be-fca1-4773-adf5-915a1569154c',
    name_dcycle: 'expected_ghg_emission_reductions',
    datapoint_id: 'E1-3_04',
    esg_category: 'environmental',
    name_en: 'Expected GHG emission reductions',
    name_es: 'Reducciones esperadas de emisiones de GEI',
    name_pt: 'Reduções esperadas de emissões de GEE'
  },
  {
    id: '80448d89-ca70-4ca1-aedc-93dc3de726f9',
    name_dcycle:
      'explanation_of_extent_to_which_ability_to_implement_action_depends_on_availability_and_allocation_of_resources',
    datapoint_id: 'E1-3_05',
    esg_category: 'environmental',
    name_en:
      'Explanation of extent to which ability to implement action depends on availability and allocation of resources',
    name_es:
      'Explicación de hasta qué punto la capacidad de implementar acciones depende de la disponibilidad y asignación de recursos',
    name_pt:
      'Explicação de até que ponto a capacidade de implementar ações depende da disponibilidade e alocação de recursos'
  },
  {
    id: 'ab5a5d25-d319-444a-bad3-432a96900ad0',
    name_dcycle:
      'explanation_of_relationship_of_significant_capex_and_opex_required_to_implement_actions_taken_or_planned_to_relevant_line_items_or_notes_in_financial_statements',
    datapoint_id: 'E1-3_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of relationship of significant CapEx and OpEx required to implement actions taken or planned to relevant line items or notes in financial statements',
    name_es:
      'Explicación de la relación entre los gastos de capital y los gastos operativos significativos necesarios para implementar las acciones tomadas o planificadas con las partidas o notas relevantes de los estados financieros.',
    name_pt:
      'Explicação da relação de CapEx e OpEx significativos necessários para implementar ações tomadas ou planejadas para itens de linha ou notas relevantes nas demonstrações financeiras'
  },
  {
    id: 'ca20fd00-d262-4c2b-9420-8bb4b7782f4b',
    name_dcycle:
      'explanation_of_relationship_of_significant_capex_and_opex_required_to_implement_actions_taken_or_planned_to_key_performance_indicators_required_under_commission_delegated_regulation_(eu)_2021/2178',
    datapoint_id: 'E1-3_07',
    esg_category: 'environmental',
    name_en:
      'Explanation of relationship of significant CapEx and OpEx required to implement actions taken or planned to key performance indicators required under Commission Delegated Regulation (EU) 2021/2178',
    name_es:
      'Explicación de la relación de los gastos de capital y gastos operativos significativos necesarios para implementar las acciones tomadas o planificadas con los indicadores clave de rendimiento requeridos según el Reglamento Delegado (UE) 2021/2178 de la Comisión.',
    name_pt:
      'Explicação da relação entre CapEx e OpEx significativos necessários para implementar ações tomadas ou planeadas com os principais indicadores de desempenho exigidos pelo Regulamento Delegado (UE) 2021/2178 da Comissão'
  },
  {
    id: 'ced3aaa8-cf95-476e-a0ac-df6bcae77200',
    name_dcycle:
      'explanation_of_relationship_of_significant_capex_and_opex_required_to_implement_actions_taken_or_planned_to_capex_plan_required_by_commission_delegated_regulation_(eu)_2021/2178',
    datapoint_id: 'E1-3_08',
    esg_category: 'environmental',
    name_en:
      'Explanation of relationship of significant CapEx and OpEx required to implement actions taken or planned to CapEx plan required by Commission Delegated Regulation (EU) 2021/2178',
    name_es:
      'Explicación de la relación entre los CapEx y OpEx significativos necesarios para implementar las acciones tomadas o planificadas con el plan de CapEx requerido por el Reglamento Delegado (UE) 2021/2178 de la Comisión.',
    name_pt:
      'Explicação da relação entre CapEx e OpEx significativos necessários para implementar ações tomadas ou planeadas para o plano de CapEx exigido pelo Regulamento Delegado (UE) 2021/2178 da Comissão'
  },
  {
    id: '15dd7ee1-365f-47e2-9aca-ed0ba701ce73',
    name_dcycle:
      'disclosure_of_whether_and_how_ghg_emissions_reduction_targets_and_(or)_any_other_targets_have_been_set_to_manage_material_climate-related_impacts,_risks_and_opportunities',
    datapoint_id: 'E1-4_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how GHG emissions reduction targets and (or) any other targets have been set to manage material climate-related impacts, risks and opportunities',
    name_es:
      'Divulgación de si y cómo se han establecido objetivos de reducción de emisiones de GEI y (o) cualquier otro objetivo para gestionar los impactos, riesgos y oportunidades materiales relacionados con el clima.',
    name_pt:
      'Divulgação de se e como as metas de redução de emissões de GEE e (ou) quaisquer outras metas foram definidas para gerenciar impactos, riscos e oportunidades materiais relacionados ao clima'
  },
  {
    id: 'fc1a5f37-9a13-4a40-87fe-6dd30b9de410',
    name_dcycle:
      'tables:_multiple_dimensions_(baseline_year_and_targets;_ghg_types,_scope_3_categories,_decarbonisation_levers,_entity-specific_denominators_for_intensity_value)',
    datapoint_id: 'E1-4_02',
    esg_category: 'environmental',
    name_en:
      'Tables: Multiple Dimensions (baseline year and targets; GHG Types, Scope 3 Categories, Decarbonisation levers, entity-specific denominators for intensity value)',
    name_es:
      'Tablas: Dimensiones múltiples (año de referencia y objetivos; tipos de GEI, categorías de alcance 3, palancas de descarbonización, denominadores específicos de cada entidad para el valor de intensidad)',
    name_pt:
      'Tabelas: Dimensões Múltiplas (ano de referência e metas; Tipos de GEE, Categorias de Escopo 3, Alavancas de descarbonização, denominadores específicos da entidade para valor de intensidade)'
  },
  {
    id: '72f952a8-6845-4155-99eb-53671b38dff6',
    name_dcycle: 'absolute_value_of_total_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_03',
    esg_category: 'environmental',
    name_en: 'Absolute value of total Greenhouse gas emissions reduction',
    name_es: 'Valor absoluto de la reducción total de emisiones de gases de efecto invernadero',
    name_pt: 'Valor absoluto da redução total das emissões de gases de efeito estufa'
  },
  {
    id: '398decef-b10d-4026-99c3-b83140390d1a',
    name_dcycle:
      'percentage_of_total_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
    datapoint_id: 'E1-4_04',
    esg_category: 'environmental',
    name_en:
      'Percentage of total Greenhouse gas emissions reduction (as of emissions of base year)',
    name_es:
      'Porcentaje de reducción total de emisiones de gases de efecto invernadero (a partir de las emisiones del año base)',
    name_pt:
      'Porcentagem da redução total de emissões de gases de efeito estufa (a partir das emissões do ano base)'
  },
  {
    id: '59a74142-b1c0-4853-b7c2-48f8b4799f5c',
    name_dcycle: 'intensity_value_of_total_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_05',
    esg_category: 'environmental',
    name_en: 'Intensity value of total Greenhouse gas emissions reduction',
    name_es:
      'Valor de intensidad de la reducción total de emisiones de gases de efecto invernadero',
    name_pt: 'Valor da intensidade da redução total das emissões de gases de efeito estufa'
  },
  {
    id: 'e7ce0781-7fdf-4766-97bf-b202ed51b1f1',
    name_dcycle: 'absolute_value_of_scope_1_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_06',
    esg_category: 'environmental',
    name_en: 'Absolute value of Scope 1 Greenhouse gas emissions reduction',
    name_es:
      'Valor absoluto de la reducción de emisiones de gases de efecto invernadero de Alcance 1',
    name_pt: 'Valor absoluto da redução de emissões de gases de efeito estufa do Escopo 1'
  },
  {
    id: '89a6bc49-1da6-4e6d-8452-d93c04d7a9d9',
    name_dcycle:
      'percentage_of_scope_1_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
    datapoint_id: 'E1-4_07',
    esg_category: 'environmental',
    name_en:
      'Percentage of Scope 1 Greenhouse gas emissions reduction (as of emissions of base year)',
    name_es:
      'Porcentaje de reducción de emisiones de gases de efecto invernadero de Alcance 1 (a partir de las emisiones del año base)',
    name_pt:
      'Porcentagem de redução de emissões de gases de efeito estufa do Escopo 1 (a partir das emissões do ano base)'
  },
  {
    id: 'a737a2d7-4506-4bc8-b5c2-58a4451d420a',
    name_dcycle: 'intensity_value_of_scope_1_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_08',
    esg_category: 'environmental',
    name_en: 'Intensity value of Scope 1 Greenhouse gas emissions reduction',
    name_es:
      'Valor de intensidad de la reducción de emisiones de gases de efecto invernadero de Alcance 1',
    name_pt: 'Valor de intensidade da redução de emissões de gases de efeito estufa do Escopo 1'
  },
  {
    id: '63e99313-6520-4b37-a974-7fe9a27bdcc2',
    name_dcycle: 'absolute_value_of_location-based_scope_2_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_09',
    esg_category: 'environmental',
    name_en: 'Absolute value of location-based Scope 2 Greenhouse gas emissions reduction',
    name_es:
      'Valor absoluto de la reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en la ubicación',
    name_pt:
      'Valor absoluto da redução de emissões de gases de efeito estufa de Escopo 2 com base na localização'
  },
  {
    id: '2eb5b372-5cfb-474b-9b16-14bb2d1202ca',
    name_dcycle:
      'percentage_of_location-based_scope_2_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
    datapoint_id: 'E1-4_10',
    esg_category: 'environmental',
    name_en:
      'Percentage of location-based Scope 2 Greenhouse gas emissions reduction (as of emissions of base year)',
    name_es:
      'Porcentaje de reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en la ubicación (a partir de las emisiones del año base)',
    name_pt:
      'Porcentagem de redução de emissões de gases de efeito estufa de Escopo 2 com base na localização (a partir das emissões do ano base)'
  },
  {
    id: '27d7cf8a-19ec-4ef2-b189-722dad6d5573',
    name_dcycle: 'intensity_value_of_location-based_scope_2_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_11',
    esg_category: 'environmental',
    name_en: 'Intensity value of location-based Scope 2 Greenhouse gas emissions reduction',
    name_es:
      'Valor de intensidad de la reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en la ubicación',
    name_pt:
      'Valor de intensidade da redução de emissões de gases de efeito estufa de Escopo 2 com base na localização'
  },
  {
    id: '661b118d-86eb-445a-9060-33537b78687a',
    name_dcycle: 'absolute_value_of_market-based_scope_2_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_12',
    esg_category: 'environmental',
    name_en: 'Absolute value of market-based Scope 2 Greenhouse gas emissions reduction',
    name_es:
      'Valor absoluto de la reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en el mercado',
    name_pt:
      'Valor absoluto da redução de emissões de gases de efeito estufa de Escopo 2 com base no mercado'
  },
  {
    id: '09f641ab-d850-4ddb-bae3-b64e30ceab3b',
    name_dcycle:
      'percentage_of_market-based_scope_2_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
    datapoint_id: 'E1-4_13',
    esg_category: 'environmental',
    name_en:
      'Percentage of market-based Scope 2 Greenhouse gas emissions reduction (as of emissions of base year)',
    name_es:
      'Porcentaje de reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en el mercado (a partir de las emisiones del año base)',
    name_pt:
      'Porcentagem de redução de emissões de gases de efeito estufa de Escopo 2 com base no mercado (a partir das emissões do ano base)'
  },
  {
    id: '4cdbcb05-213b-484c-9b07-bc5a6ed45ad5',
    name_dcycle: 'intensity_value_of_market-based_scope_2_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_14',
    esg_category: 'environmental',
    name_en: 'Intensity value of market-based Scope 2 Greenhouse gas emissions reduction',
    name_es:
      'Valor de intensidad de la reducción de emisiones de gases de efecto invernadero de Alcance 2 basada en el mercado',
    name_pt:
      'Valor da intensidade da redução de emissões de gases de efeito estufa de Escopo 2 com base no mercado'
  },
  {
    id: '8171542c-4e4c-44a0-a8a8-911a1497be46',
    name_dcycle: 'absolute_value_of_scope_3_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_15',
    esg_category: 'environmental',
    name_en: 'Absolute value of Scope 3 Greenhouse gas emissions reduction',
    name_es:
      'Valor absoluto de la reducción de emisiones de gases de efecto invernadero de Alcance 3',
    name_pt: 'Valor absoluto da redução de emissões de gases de efeito estufa do Escopo 3'
  },
  {
    id: '1a747fbf-e85b-45c1-939c-ae97d8326273',
    name_dcycle:
      'percentage_of_scope_3_greenhouse_gas_emissions_reduction_(as_of_emissions_of_base_year)',
    datapoint_id: 'E1-4_16',
    esg_category: 'environmental',
    name_en:
      'Percentage of Scope 3 Greenhouse gas emissions reduction (as of emissions of base year)',
    name_es:
      'Porcentaje de reducción de emisiones de gases de efecto invernadero de Alcance 3 (a partir de las emisiones del año base)',
    name_pt:
      'Porcentagem de redução de emissões de gases de efeito estufa do Escopo 3 (a partir das emissões do ano base)'
  },
  {
    id: '2a2140b1-7185-471b-a5e5-640251c98d68',
    name_dcycle: 'intensity_value_of_scope_3_greenhouse_gas_emissions_reduction',
    datapoint_id: 'E1-4_17',
    esg_category: 'environmental',
    name_en: 'Intensity value of Scope 3 Greenhouse gas emissions reduction',
    name_es:
      'Valor de intensidad de la reducción de emisiones de gases de efecto invernadero de Alcance 3',
    name_pt: 'Valor de intensidade da redução de emissões de gases de efeito estufa do Escopo 3'
  },
  {
    id: '97da1db8-7adc-4e76-8c9f-69e81ba61535',
    name_dcycle:
      'explanation_of_how_consistency_of_ghg_emission_reduction_targets_with_ghg_inventory_boundaries_has_been_ensured',
    datapoint_id: 'E1-4_18',
    esg_category: 'environmental',
    name_en:
      'Explanation of how consistency of GHG emission reduction targets with GHG inventory boundaries has been ensured',
    name_es:
      'Explicación de cómo se ha garantizado la coherencia de los objetivos de reducción de emisiones de GEI con los límites del inventario de GEI',
    name_pt:
      'Explicação de como foi garantida a consistência das metas de redução de emissões de GEE com os limites do inventário de GEE'
  },
  {
    id: 'acd770ab-fa46-4e7e-87dd-5d0e8ca1d1ef',
    name_dcycle: 'disclosure_of_past_progress_made_in_meeting_target_before_current_base_year',
    datapoint_id: 'E1-4_19',
    esg_category: 'environmental',
    name_en: 'Disclosure of past progress made in meeting target before current base year',
    name_es:
      'Divulgación del progreso realizado en el pasado para cumplir la meta antes del año base actual',
    name_pt:
      'Divulgação dos progressos anteriores realizados no cumprimento da meta antes do ano base atual'
  },
  {
    id: '4088df8e-b070-4587-bed9-8ad8d9080e65',
    name_dcycle:
      'description_of_how_it_has_been_ensured_that_baseline_value_is_representative_in_terms_of_activities_covered_and_influences_from_external_factors',
    datapoint_id: 'E1-4_20',
    esg_category: 'environmental',
    name_en:
      'Description of how it has been ensured that baseline value is representative in terms of activities covered and influences from external factors',
    name_es:
      'Descripción de cómo se ha garantizado que el valor de referencia sea representativo en términos de las actividades cubiertas y las influencias de factores externos.',
    name_pt:
      'Descrição de como foi garantido que o valor de referência é representativo em termos de atividades abrangidas e influências de fatores externos'
  },
  {
    id: '9a23078c-c689-42cf-bc22-1b1616d8f3ca',
    name_dcycle:
      'description_of_how_new_baseline_value_affects_new_target,_its_achievement_and_presentation_of_progress_over_time',
    datapoint_id: 'E1-4_21',
    esg_category: 'environmental',
    name_en:
      'Description of how new baseline value affects new target, its achievement and presentation of progress over time',
    name_es:
      'Descripción de cómo el nuevo valor de referencia afecta el nuevo objetivo, su logro y presentación del progreso a lo largo del tiempo.',
    name_pt:
      'Descrição de como o novo valor da linha de base afeta a nova meta, seu alcance e apresentação do progresso ao longo do tempo'
  },
  {
    id: 'ef289fcc-015d-4bae-bb58-c849208eb761',
    name_dcycle:
      'ghg_emission_reduction_target_is_science_based_and_compatible_with_limiting_global_warming_to_one_and_half_degrees_celsius',
    datapoint_id: 'E1-4_22',
    esg_category: 'environmental',
    name_en:
      'GHG emission reduction target is science based and compatible with limiting global warming to one and half degrees Celsius',
    name_es:
      'El objetivo de reducción de emisiones de GEI tiene base científica y es compatible con limitar el calentamiento global a un grado y medio Celsius.',
    name_pt:
      'A meta de redução de emissões de GEE é baseada na ciência e é compatível com a limitação do aquecimento global a um grau e meio Celsius'
  },
  {
    id: '4237f7f8-bb8b-4921-8553-278646ba33cc',
    name_dcycle:
      'description_of_expected_decarbonisation_levers_and_their_overall_quantitative_contributions_to_achieve_ghg_emission_reduction_target',
    datapoint_id: 'E1-4_23',
    esg_category: 'environmental',
    name_en:
      'Description of expected decarbonisation levers and their overall quantitative contributions to achieve GHG emission reduction target',
    name_es:
      'Descripción de las palancas de descarbonización esperadas y sus contribuciones cuantitativas generales para lograr el objetivo de reducción de emisiones de GEI',
    name_pt:
      'Descrição das alavancas de descarbonização esperadas e das suas contribuições quantitativas globais para atingir a meta de redução das emissões de GEE'
  },
  {
    id: 'd06f33ea-2e51-4c4b-a8a4-ec56a9b2efe2',
    name_dcycle:
      'diverse_range_of_climate_scenarios_have_been_considered_to_detect_relevant_environmental,_societal,_technology,_market_and_policy-related_developments_and_determine_decarbonisation_levers',
    datapoint_id: 'E1-4_24',
    esg_category: 'environmental',
    name_en:
      'Diverse range of climate scenarios have been considered to detect relevant environmental, societal, technology, market and policy-related developments and determine decarbonisation levers',
    name_es:
      'Se ha considerado una amplia gama de escenarios climáticos para detectar desarrollos ambientales, sociales, tecnológicos, de mercado y políticos relevantes y determinar palancas de descarbonización.',
    name_pt:
      'Uma ampla gama de cenários climáticos foi considerada para detectar desenvolvimentos ambientais, sociais, tecnológicos, de mercado e políticos relevantes e determinar alavancas de descarbonização'
  },
  {
    id: 'cfcaad63-ec69-4f28-a295-21f5fbabed5e',
    name_dcycle: 'total_energy_consumption_related_to_own_operations',
    datapoint_id: 'E1-5_01',
    esg_category: 'environmental',
    name_en: 'Total energy consumption related to own operations',
    name_es: 'Consumo total de energía relacionado con operaciones propias.',
    name_pt: 'Consumo total de energia relacionado às operações próprias'
  },
  {
    id: 'bc9db2e6-3c17-4e22-8712-8cbe84bc0f7b',
    name_dcycle: 'total_energy_consumption_from_fossil_sources',
    datapoint_id: 'E1-5_02',
    esg_category: 'environmental',
    name_en: 'Total energy consumption from fossil sources',
    name_es: 'Consumo total de energía procedente de fuentes fósiles',
    name_pt: 'Consumo total de energia proveniente de fontes fósseis'
  },
  {
    id: 'e39819a3-5af5-4ceb-aa29-dc97934a7f5f',
    name_dcycle: 'total_energy_consumption_from_nuclear_sources',
    datapoint_id: 'E1-5_03',
    esg_category: 'environmental',
    name_en: 'Total energy consumption from nuclear sources',
    name_es: 'Consumo total de energía procedente de fuentes nucleares',
    name_pt: 'Consumo total de energia proveniente de fontes nucleares'
  },
  {
    id: '851b0bd6-f9c8-40d1-a6b8-7d3c549f21c7',
    name_dcycle:
      'percentage_of_energy_consumption_from_nuclear_sources_in_total_energy_consumption',
    datapoint_id: 'E1-5_04',
    esg_category: 'environmental',
    name_en: 'Percentage of energy consumption from nuclear sources in total energy consumption',
    name_es:
      'Porcentaje del consumo de energía procedente de fuentes nucleares sobre el consumo total de energía',
    name_pt:
      'Percentagem do consumo de energia proveniente de fontes nucleares no consumo total de energia'
  },
  {
    id: 'f5568f99-7bad-489d-9f27-ef3272c75a6d',
    name_dcycle: 'total_energy_consumption_from_renewable_sources',
    datapoint_id: 'E1-5_05',
    esg_category: 'environmental',
    name_en: 'Total energy consumption from renewable sources',
    name_es: 'Consumo total de energía procedente de fuentes renovables',
    name_pt: 'Consumo total de energia proveniente de fontes renováveis'
  },
  {
    id: '2951cb37-c21d-4f51-b809-a5915f5144f8',
    name_dcycle: 'fuel_consumption_from_renewable_sources',
    datapoint_id: 'E1-5_06',
    esg_category: 'environmental',
    name_en: 'Fuel consumption from renewable sources',
    name_es: 'Consumo de combustible procedente de fuentes renovables.',
    name_pt: 'Consumo de combustível proveniente de fontes renováveis'
  },
  {
    id: '565b1be5-5a80-4c5a-9c13-c98b32ff235f',
    name_dcycle:
      'consumption_of_purchased_or_acquired_electricity,_heat,_steam,_and_cooling_from_renewable_sources',
    datapoint_id: 'E1-5_07',
    esg_category: 'environmental',
    name_en:
      'Consumption of purchased or acquired electricity, heat, steam, and cooling from renewable sources',
    name_es:
      'Consumo de electricidad, calor, vapor y refrigeración comprados o adquiridos de fuentes renovables',
    name_pt:
      'Consumo de eletricidade, calor, vapor e resfriamento comprados ou adquiridos de fontes renováveis'
  },
  {
    id: 'e4307110-af79-42d3-a229-9529490a2533',
    name_dcycle: 'consumption_of_self-generated_non-fuel_renewable_energy',
    datapoint_id: 'E1-5_08',
    esg_category: 'environmental',
    name_en: 'Consumption of self-generated non-fuel renewable energy',
    name_es: 'Consumo de energía renovable autogenerada no combustible',
    name_pt: 'Consumo de energia renovável não combustível autogerada'
  },
  {
    id: '2d74a40f-dc5d-410d-b4d0-ec74f4ae3b2c',
    name_dcycle: 'percentage_of_renewable_sources_in_total_energy_consumption',
    datapoint_id: 'E1-5_09',
    esg_category: 'environmental',
    name_en: 'Percentage of renewable sources in total energy consumption',
    name_es: 'Porcentaje de fuentes renovables en el consumo total de energía',
    name_pt: 'Percentagem de fontes renováveis ​​no consumo total de energia'
  },
  {
    id: 'e37a66a0-ba84-4c7a-9050-025cd6451f9a',
    name_dcycle: 'fuel_consumption_from_coal_and_coal_products',
    datapoint_id: 'E1-5_10',
    esg_category: 'environmental',
    name_en: 'Fuel consumption from coal and coal products',
    name_es: 'Consumo de combustible procedente de carbón y productos del carbón.',
    name_pt: 'Consumo de combustível proveniente de carvão e produtos de carvão'
  },
  {
    id: 'a30c7eb0-81cc-4a51-b9a3-9cbf3334e3e4',
    name_dcycle: 'fuel_consumption_from_crude_oil_and_petroleum_products',
    datapoint_id: 'E1-5_11',
    esg_category: 'environmental',
    name_en: 'Fuel consumption from crude oil and petroleum products',
    name_es:
      'Consumo de combustible procedente de petróleo crudo y productos derivados del petróleo.',
    name_pt: 'Consumo de combustível proveniente de petróleo bruto e produtos petrolíferos'
  },
  {
    id: '6eefbfeb-a2fe-47e7-9373-dd9dca7251b8',
    name_dcycle: 'fuel_consumption_from_natural_gas',
    datapoint_id: 'E1-5_12',
    esg_category: 'environmental',
    name_en: 'Fuel consumption from natural gas',
    name_es: 'Consumo de combustible procedente de gas natural.',
    name_pt: 'Consumo de combustível proveniente de gás natural'
  },
  {
    id: '826c0a7f-0974-4b8e-b000-e710510172b8',
    name_dcycle: 'fuel_consumption_from_other_fossil_sources',
    datapoint_id: 'E1-5_13',
    esg_category: 'environmental',
    name_en: 'Fuel consumption from other fossil sources',
    name_es: 'Consumo de combustible de otras fuentes fósiles',
    name_pt: 'Consumo de combustível de outras fontes fósseis'
  },
  {
    id: '9cf9f66e-65f2-4206-bfe1-73e271586177',
    name_dcycle:
      'consumption_of_purchased_or_acquired_electricity,_heat,_steam,_or_cooling_from_fossil_sources',
    datapoint_id: 'E1-5_14',
    esg_category: 'environmental',
    name_en:
      'Consumption of purchased or acquired electricity, heat, steam, or cooling from fossil sources',
    name_es:
      'Consumo de electricidad, calor, vapor o refrigeración comprados o adquiridos de fuentes fósiles',
    name_pt:
      'Consumo de eletricidade, calor, vapor ou resfriamento comprados ou adquiridos de fontes fósseis'
  },
  {
    id: '8e2e53e5-850d-4c52-93cb-a9feabb4f95f',
    name_dcycle: 'percentage_of_fossil_sources_in_total_energy_consumption',
    datapoint_id: 'E1-5_15',
    esg_category: 'environmental',
    name_en: 'Percentage of fossil sources in total energy consumption',
    name_es: 'Porcentaje de fuentes fósiles en el consumo total de energía',
    name_pt: 'Percentagem de fontes fósseis no consumo total de energia'
  },
  {
    id: 'ed2558eb-3d24-483c-89f5-ae82ec4875f8',
    name_dcycle: 'non-renewable_energy_production',
    datapoint_id: 'E1-5_16',
    esg_category: 'environmental',
    name_en: 'Non-renewable energy production',
    name_es: 'Producción de energía no renovable',
    name_pt: 'Produção de energia não renovável'
  },
  {
    id: 'c55b6213-9a96-4465-b269-f119ec6ab57c',
    name_dcycle: 'renewable_energy_production',
    datapoint_id: 'E1-5_17',
    esg_category: 'environmental',
    name_en: 'Renewable energy production',
    name_es: 'Producción de energía renovable',
    name_pt: 'Produção de energia renovável'
  },
  {
    id: 'b9d1cd00-c4d2-4ff1-a2d2-3919da32ec80',
    name_dcycle:
      'energy_intensity_from_activities_in_high_climate_impact_sectors_(total_energy_consumption_per_net_revenue)',
    datapoint_id: 'E1-5_18',
    esg_category: 'environmental',
    name_en:
      'Energy intensity from activities in high climate impact sectors (total energy consumption per net revenue)',
    name_es:
      'Intensidad energética de actividades en sectores de alto impacto climático (consumo total de energía por ingreso neto)',
    name_pt:
      'Intensidade energética proveniente de atividades em setores de elevado impacto climático (consumo total de energia por receita líquida)'
  },
  {
    id: '41a20803-c3d8-4e02-97fe-34ec3ec19ab7',
    name_dcycle: 'total_energy_consumption_from_activities_in_high_climate_impact_sectors',
    datapoint_id: 'E1-5_19',
    esg_category: 'environmental',
    name_en: 'Total energy consumption from activities in high climate impact sectors',
    name_es: 'Consumo total de energía de actividades en sectores de alto impacto climático',
    name_pt:
      'Consumo total de energia proveniente de atividades em setores de elevado impacto climático'
  },
  {
    id: 'bc2bfe9d-35fd-4d53-8822-2112b35667f8',
    name_dcycle: 'high_climate_impact_sectors_used_to_determine_energy_intensity',
    datapoint_id: 'E1-5_20',
    esg_category: 'environmental',
    name_en: 'High climate impact sectors used to determine energy intensity',
    name_es:
      'Sectores de alto impacto climático utilizados para determinar la intensidad energética',
    name_pt: 'Setores de alto impacto climático usados ​​para determinar a intensidade energética'
  },
  {
    id: 'f07c343c-9c21-4dbd-b11f-06ee272e0641',
    name_dcycle:
      'disclosure_of_reconciliation_to_relevant_line_item_or_notes_in_financial_statements_of_net_revenue_from_activities_in_high_climate_impact_sectors',
    datapoint_id: 'E1-5_21',
    esg_category: 'environmental',
    name_en:
      'Disclosure of reconciliation to relevant line item or notes in financial statements of net revenue from activities in high climate impact sectors',
    name_es:
      'Divulgación de la conciliación con partidas o notas relevantes en los estados financieros de ingresos netos de actividades en sectores de alto impacto climático',
    name_pt:
      'Divulgação da reconciliação com itens de linha relevantes ou notas nas demonstrações financeiras da receita líquida de atividades em setores de alto impacto climático'
  },
  {
    id: '31c190a0-cc60-4ec0-8a9e-912e022d704e',
    name_dcycle: 'net_revenue_from_activities_in_high_climate_impact_sectors',
    datapoint_id: 'E1-5_22',
    esg_category: 'environmental',
    name_en: 'Net revenue from activities in high climate impact sectors',
    name_es: 'Ingresos netos de actividades en sectores de alto impacto climático',
    name_pt: 'Receita líquida de atividades em setores de alto impacto climático'
  },
  {
    id: '2592da5f-0adb-4f4b-95de-843f531b94f4',
    name_dcycle: 'net_revenue_from_activities_other_than_in_high_climate_impact_sectors',
    datapoint_id: 'E1-5_23',
    esg_category: 'environmental',
    name_en: 'Net revenue from activities other than in high climate impact sectors',
    name_es: 'Ingresos netos de actividades distintas a las de sectores de alto impacto climático',
    name_pt: 'Receita líquida de atividades que não sejam de setores de elevado impacto climático'
  },
  {
    id: 'c0c7781a-380b-4025-9782-603d98652362',
    name_dcycle: 'gross_scopes_1,_2,_3_and_total_ghg_emissions_-_ghg_emissions_per_scope_[table]',
    datapoint_id: 'E1-6_01',
    esg_category: 'environmental',
    name_en: 'Gross Scopes 1, 2, 3 and Total GHG emissions - GHG emissions per scope [table]',
    name_es:
      'Alcances brutos 1, 2, 3 y emisiones totales de GEI - Emisiones de GEI por alcance [tabla]',
    name_pt:
      'Emissões Brutas de Escopos 1, 2, 3 e Total de GEE - Emissões de GEE por escopo [tabela]'
  },
  {
    id: '6b1203ec-2c19-4c12-9270-e6cdf1126cd4',
    name_dcycle:
      'gross_scopes_1,_2,_3_and_total_ghg_emissions_-_financial_and_operational_control_[table]',
    datapoint_id: 'E1-6_02',
    esg_category: 'environmental',
    name_en:
      'Gross Scopes 1, 2, 3 and Total GHG emissions - financial and operational control [table]',
    name_es:
      'Alcances brutos 1, 2, 3 y emisiones totales de GEI - control financiero y operativo [tabla]',
    name_pt:
      'Emissões brutas de Escopos 1, 2, 3 e Total de GEE – controle financeiro e operacional [tabela]'
  },
  {
    id: 'c82bf531-6af0-4b86-83c3-807c7d208f32',
    name_dcycle:
      'disaggregation_of_ghg_emissions_-_by_country,_operating_segments,_economic_activity,_subsidiary,_ghg_category_or_source_type',
    datapoint_id: 'E1-6_03',
    esg_category: 'environmental',
    name_en:
      'Disaggregation of GHG emissions - by country, operating segments, economic activity, subsidiary, GHG category or source type',
    name_es:
      'Desagregación de emisiones de GEI - por país, segmentos operativos, actividad económica, subsidiaria, categoría de GEI o tipo de fuente',
    name_pt:
      'Desagregação das emissões de GEE – por país, segmentos operacionais, atividade económica, subsidiária, categoria de GEE ou tipo de fonte'
  },
  {
    id: 'e2fcccd4-5926-4834-950b-b579aeb9d8b0',
    name_dcycle:
      'gross_scopes_1,_2,_3_and_total_ghg_emissions_-_scope_3_ghg_emissions_(ghg_protocol)_[table]',
    datapoint_id: 'E1-6_04',
    esg_category: 'environmental',
    name_en:
      'Gross Scopes 1, 2, 3 and Total GHG emissions - Scope 3 GHG emissions (GHG Protocol) [table]',
    name_es:
      'Emisiones brutas de GEI de alcance 1, 2, 3 y totales de GEI - Emisiones de GEI de alcance 3 (Protocolo de GEI) [tabla]',
    name_pt:
      'Emissões brutas de GEE de Escopo 1, 2, 3 e Total - Emissões de GEE de Escopo 3 (GHG Protocol) [tabela]'
  },
  {
    id: 'b28b8bab-a5b0-487e-bfe6-f8fb89c9761a',
    name_dcycle:
      'gross_scopes_1,_2,_3_and_total_ghg_emissions_-_scope_3_ghg_emissions_(iso_14064-1)_[table]',
    datapoint_id: 'E1-6_05',
    esg_category: 'environmental',
    name_en:
      'Gross Scopes 1, 2, 3 and Total GHG emissions - Scope 3 GHG emissions (ISO 14064-1) [table]',
    name_es:
      'Emisiones brutas de GEI de alcance 1, 2, 3 y totales de GEI - Emisiones de GEI de alcance 3 (ISO 14064-1) [tabla]',
    name_pt:
      'Emissões brutas de GEE de Escopo 1, 2, 3 e Total - Emissões de GEE de Escopo 3 (ISO 14064-1) [tabela]'
  },
  {
    id: 'f14271d7-f701-4ebc-be3c-36ea927b327b',
    name_dcycle:
      'gross_scopes_1,_2,_3_and_total_ghg_emissions_-_total_ghg_emissions_-_value_chain_[table]',
    datapoint_id: 'E1-6_06',
    esg_category: 'environmental',
    name_en:
      'Gross Scopes 1, 2, 3 and Total GHG emissions - total GHG emissions - value chain [table]',
    name_es:
      'Alcances brutos 1, 2, 3 y emisiones totales de GEI - emisiones totales de GEI - cadena de valor [tabla]',
    name_pt:
      'Escopos brutos 1, 2, 3 e emissões totais de GEE - emissões totais de GEE - cadeia de valor [tabela]'
  },
  {
    id: '2502ab87-6d00-448a-9932-433338af0f7a',
    name_dcycle: 'gross_scope_1_greenhouse_gas_emissions',
    datapoint_id: 'E1-6_07',
    esg_category: 'environmental',
    name_en: 'Gross Scope 1 greenhouse gas emissions',
    name_es: 'Emisiones brutas de gases de efecto invernadero de alcance 1',
    name_pt: 'Emissões brutas de gases de efeito estufa de Escopo 1'
  },
  {
    id: 'b4802d24-57c6-49f7-b6a6-c62126e2379b',
    name_dcycle: 'percentage_of_scope_1_ghg_emissions_from_regulated_emission_trading_schemes',
    datapoint_id: 'E1-6_08',
    esg_category: 'environmental',
    name_en: 'Percentage of Scope 1 GHG emissions from regulated emission trading schemes',
    name_es:
      'Porcentaje de emisiones de GEI de Alcance 1 provenientes de esquemas regulados de comercio de derechos de emisión',
    name_pt:
      'Porcentagem de emissões de GEE de Escopo 1 provenientes de esquemas regulamentados de comércio de emissões'
  },
  {
    id: '9a71aa99-812f-4ccd-b41b-35ed6105115b',
    name_dcycle: 'gross_location-based_scope_2_greenhouse_gas_emissions',
    datapoint_id: 'E1-6_09',
    esg_category: 'environmental',
    name_en: 'Gross location-based Scope 2 greenhouse gas emissions',
    name_es: 'Emisiones brutas de gases de efecto invernadero de alcance 2 basadas en la ubicación',
    name_pt: 'Emissões brutas de gases de efeito estufa de Escopo 2 com base na localização'
  },
  {
    id: 'd430bef5-1f0f-48a7-8087-518f54c1ad65',
    name_dcycle: 'gross_market-based_scope_2_greenhouse_gas_emissions',
    datapoint_id: 'E1-6_10',
    esg_category: 'environmental',
    name_en: 'Gross market-based Scope 2 greenhouse gas emissions',
    name_es: 'Emisiones brutas de gases de efecto invernadero de alcance 2 basadas en el mercado',
    name_pt: 'Emissões brutas de gases de efeito estufa de Escopo 2 com base no mercado'
  },
  {
    id: 'c420172e-da1b-42b1-8e4d-fa94137f017e',
    name_dcycle: 'gross_scope_3_greenhouse_gas_emissions',
    datapoint_id: 'E1-6_11',
    esg_category: 'environmental',
    name_en: 'Gross Scope 3 greenhouse gas emissions',
    name_es: 'Emisiones brutas de gases de efecto invernadero de alcance 3',
    name_pt: 'Emissões brutas de gases de efeito estufa de Escopo 3'
  },
  {
    id: 'bfad23d9-cf34-471f-b6e0-3ad992b20f1f',
    name_dcycle: 'total_ghg_emissions_location_based',
    datapoint_id: 'E1-6_12',
    esg_category: 'environmental',
    name_en: 'Total GHG emissions location based',
    name_es: 'Ubicación de las emisiones totales de GEI según la ubicación',
    name_pt: 'Localização total das emissões de GEE com base'
  },
  {
    id: '367657d6-bbe9-4d71-bc8b-87a9ff4f73f4',
    name_dcycle: 'total_ghg_emissions_market_based',
    datapoint_id: 'E1-6_13',
    esg_category: 'environmental',
    name_en: 'Total GHG emissions market based',
    name_es: 'Total de emisiones de GEI basado en el mercado',
    name_pt: 'Total de emissões de GEE com base no mercado'
  },
  {
    id: '6117188a-79be-4720-9add-adaa28d39b74',
    name_dcycle:
      'disclosure_of_significant_changes_in_definition_of_what_constitutes_reporting_undertaking_and_its_value_chain_and_explanation_of_their_effect_on_year-to-year_comparability_of_reported_ghg_emissions',
    datapoint_id: 'E1-6_14',
    esg_category: 'environmental',
    name_en:
      'Disclosure of significant changes in definition of what constitutes reporting undertaking and its value chain and explanation of their effect on year-to-year comparability of reported GHG emissions',
    name_es:
      'Divulgación de cambios significativos en la definición de lo que constituye una empresa que informa y su cadena de valor y explicación de su efecto en la comparabilidad año tras año de las emisiones de GEI reportadas.',
    name_pt:
      'Divulgação de alterações significativas na definição do que constitui empresa relatora e da sua cadeia de valor e explicação do seu efeito na comparabilidade anual das emissões de GEE comunicadas'
  },
  {
    id: '305b5e67-47ef-4c2e-b0cc-bd226afbc9f7',
    name_dcycle:
      'disclosure_of_methodologies,_significant_assumptions_and_emissions_factors_used_to_calculate_or_measure_ghg_emissions',
    datapoint_id: 'E1-6_15',
    esg_category: 'environmental',
    name_en:
      'Disclosure of methodologies, significant assumptions and emissions factors used to calculate or measure GHG emissions',
    name_es:
      'Divulgación de metodologías, supuestos importantes y factores de emisión utilizados para calcular o medir las emisiones de GEI.',
    name_pt:
      'Divulgação de metodologias, premissas significativas e fatores de emissão utilizados para calcular ou medir emissões de GEE'
  },
  {
    id: 'ea244665-5dee-4ca5-b583-8667b439e099',
    name_dcycle:
      'disclosure_of_the_effects_of_significant_events_and_changes_in_circumstances_(relevant_to_its_ghg_emissions)_that_occur_between_the_reporting_dates_of_the_entities_in_its_value_chain_and_the_date_of_the_undertaking’s_general_purpose_financial_statements',
    datapoint_id: 'E1-6_16',
    esg_category: 'environmental',
    name_en:
      'Disclosure of the effects of significant events and changes in circumstances (relevant to its GHG emissions) that occur between the reporting dates of the entities in its value chain and the date of the undertaking’s general purpose financial statements',
    name_es:
      'Revelación de los efectos de eventos significativos y cambios en las circunstancias (relevantes para sus emisiones de GEI) que ocurren entre las fechas de presentación de informes de las entidades en su cadena de valor y la fecha de los estados financieros con propósito general de la empresa.',
    name_pt:
      'Divulgação dos efeitos de eventos significativos e alterações nas circunstâncias (relevantes para as suas emissões de GEE) que ocorrem entre as datas de relato das entidades na sua cadeia de valor e a data das demonstrações financeiras de finalidade geral da empresa'
  },
  {
    id: '131a4cbd-573d-4dac-8e7b-0124ca112fbc',
    name_dcycle:
      'biogenic_emissions_of_co2_from_the_combustion_or_bio-degradation_of_biomassnot_included_in_scope_1_ghg_emissions',
    datapoint_id: 'E1-6_17',
    esg_category: 'environmental',
    name_en:
      'biogenic emissions of CO2 from the combustion or bio-degradation of biomassnot included in Scope 1 GHG emissions',
    name_es:
      'Emisiones biogénicas de CO2 procedentes de la combustión o biodegradación de biomasa no incluidas en el alcance 1 de emisiones de GEI.',
    name_pt:
      'emissões biogênicas de CO2 provenientes da combustão ou biodegradação de biomassa não incluídas nas emissões de GEE do Escopo 1'
  },
  {
    id: '455a4cad-c00d-406d-9978-d1860b3066f3',
    name_dcycle: 'percentage_of_contractual_instruments,_scope_2_ghg_emissions',
    datapoint_id: 'E1-6_18',
    esg_category: 'environmental',
    name_en: 'Percentage of contractual instruments, Scope 2 GHG emissions',
    name_es: 'Porcentaje de instrumentos contractuales, emisiones de GEI de alcance 2',
    name_pt: 'Percentual de instrumentos contratuais, emissões de GEE de Escopo 2'
  },
  {
    id: '6608e475-9392-410f-8047-220db1e47f2d',
    name_dcycle: 'disclosure_of_types_of_contractual_instruments,_scope_2_ghg_emissions',
    datapoint_id: 'E1-6_19',
    esg_category: 'environmental',
    name_en: 'Disclosure of types of contractual instruments, Scope 2 GHG emissions',
    name_es: 'Divulgación de tipos de instrumentos contractuales, emisiones de GEI de Alcance 2',
    name_pt: 'Divulgação dos tipos de instrumentos contratuais, emissões de GEE Escopo 2'
  },
  {
    id: 'c9f13c4d-6b2e-4b85-a9c6-fdf7fc0974c4',
    name_dcycle:
      'percentage_of_market-based_scope_2_ghg_emissions_linked_to_purchased_electricity_bundled_with_instruments',
    datapoint_id: 'E1-6_20',
    esg_category: 'environmental',
    name_en:
      'Percentage of market-based Scope 2 GHG emissions linked to purchased electricity bundled with instruments',
    name_es:
      'Porcentaje de emisiones de GEI de alcance 2 basadas en el mercado vinculadas a la electricidad comprada junto con instrumentos',
    name_pt:
      'Percentagem de emissões de GEE de Âmbito 2 baseadas no mercado associadas à eletricidade adquirida em conjunto com instrumentos'
  },
  {
    id: '3481eebc-0811-4533-80c8-07b9614073fd',
    name_dcycle:
      'percentage_of_contractual_instruments_used_for_sale_and_purchase_of_energy_bundled_with_attributes_about_energy_generation_in_relation_to_scope_2_ghg_emissions',
    datapoint_id: 'E1-6_21',
    esg_category: 'environmental',
    name_en:
      'Percentage of contractual instruments used for sale and purchase of energy bundled with attributes about energy generation in relation to Scope 2 GHG emissions',
    name_es:
      'Porcentaje de instrumentos contractuales utilizados para la venta y compra de energía combinados con atributos sobre generación de energía en relación con las emisiones de GEI de Alcance 2',
    name_pt:
      'Percentual de instrumentos contratuais utilizados para venda e compra de energia agrupados com atributos sobre geração de energia em relação às emissões de GEE Escopo 2'
  },
  {
    id: '9c877f25-25fc-4124-952d-671214c42abd',
    name_dcycle:
      'percentage_of_contractual_instruments_used_for_sale_and_purchase_of_unbundled_energy_attribute_claims_in_relation_to_scope_2_ghg_emissions',
    datapoint_id: 'E1-6_22',
    esg_category: 'environmental',
    name_en:
      'Percentage of contractual instruments used for sale and purchase of unbundled energy attribute claims in relation to Scope 2 GHG emissions',
    name_es:
      'Porcentaje de instrumentos contractuales utilizados para la venta y compra de reclamaciones de atributos de energía desagregados en relación con las emisiones de GEI de Alcance 2',
    name_pt:
      'Percentual de instrumentos contratuais usados ​​para venda e compra de reivindicações de atributos de energia desagregados em relação às emissões de GEE de Escopo 2'
  },
  {
    id: '43d05274-7c52-4bde-9aa1-4d514c8a668d',
    name_dcycle:
      'disclosure_of_types_of_contractual_instruments_used_for_sale_and_purchase_of_energy_bundled_with_attributes_about_energy_generation_or_for_unbundled_energy_attribute_claims',
    datapoint_id: 'E1-6_23',
    esg_category: 'environmental',
    name_en:
      'Disclosure of types of contractual instruments used for sale and purchase of energy bundled with attributes about energy generation or for unbundled energy attribute claims',
    name_es:
      'Divulgación de tipos de instrumentos contractuales utilizados para la venta y compra de energía agrupada con atributos sobre generación de energía o para reclamaciones de atributos de energía desagregados.',
    name_pt:
      'Divulgação dos tipos de instrumentos contratuais utilizados para venda e compra de energia agrupada com atributos sobre geração de energia ou para reivindicações de atributos de energia desagregados'
  },
  {
    id: '946ca024-0108-4db4-a715-bd76dd202df8',
    name_dcycle:
      'biogenic_emissions_of_co2_from_combustion_or_bio-degradation_of_biomass_not_included_in_scope_2_ghg_emissions',
    datapoint_id: 'E1-6_24',
    esg_category: 'environmental',
    name_en:
      'Biogenic emissions of CO2 from combustion or bio-degradation of biomass not included in Scope 2 GHG emissions',
    name_es:
      'Emisiones biogénicas de CO2 procedentes de la combustión o biodegradación de biomasa no incluidas en las emisiones de GEI de Alcance 2',
    name_pt:
      'Emissões biogênicas de CO2 provenientes da combustão ou biodegradação de biomassa não incluídas nas emissões de GEE do Escopo 2'
  },
  {
    id: '120e3270-8e83-4599-8972-73fb7366d276',
    name_dcycle: 'percentage_of_ghg_scope_3_calculated_using_primary_data',
    datapoint_id: 'E1-6_25',
    esg_category: 'environmental',
    name_en: 'Percentage of GHG Scope 3 calculated using primary data',
    name_es: 'Porcentaje de GEI de Alcance 3 calculado utilizando datos primarios',
    name_pt: 'Porcentagem de GEE Escopo 3 calculada usando dados primários'
  },
  {
    id: '466445d3-53c2-462a-8412-3c80963d92a8',
    name_dcycle: 'disclosure_of_why_scope_3_ghg_emissions_category_has_been_excluded',
    datapoint_id: 'E1-6_26',
    esg_category: 'environmental',
    name_en: 'Disclosure of why Scope 3 GHG emissions category has been excluded',
    name_es: 'Divulgación de por qué se ha excluido la categoría de emisiones de GEI de Alcance 3',
    name_pt:
      'Divulgação do motivo pelo qual a categoria de emissões de GEE do Escopo 3 foi excluída'
  },
  {
    id: '978bea6d-0552-4b12-881e-4a5cc69f9634',
    name_dcycle: 'list_of_scope_3_ghg_emissions_categories_included_in_inventory',
    datapoint_id: 'E1-6_27',
    esg_category: 'environmental',
    name_en: 'List of Scope 3 GHG emissions categories included in inventory',
    name_es: 'Lista de categorías de emisiones de GEI de Alcance 3 incluidas en el inventario',
    name_pt: 'Lista de categorias de emissões de GEE de Escopo 3 incluídas no inventário'
  },
  {
    id: 'b535cdcb-87ba-4b70-8f5e-b1d7cfde8955',
    name_dcycle:
      'biogenic_emissions_of_co2_from_combustion_or_bio-degradation_of_biomass_that_occur_in_value_chain_not_included_in_scope_3_ghg_emissions',
    datapoint_id: 'E1-6_28',
    esg_category: 'environmental',
    name_en:
      'Biogenic emissions of CO2 from combustion or bio-degradation of biomass that occur in value chain not included in Scope 3 GHG emissions',
    name_es:
      'Emisiones biogénicas de CO2 procedentes de la combustión o biodegradación de biomasa que se producen en la cadena de valor no incluidas en las emisiones de GEI de Alcance 3',
    name_pt:
      'Emissões biogênicas de CO2 provenientes da combustão ou biodegradação de biomassa que ocorrem na cadeia de valor não incluídas nas emissões de GEE do Escopo 3'
  },
  {
    id: 'de2846ec-dc04-43fa-849b-3ad8e87bd4db',
    name_dcycle:
      'disclosure_of_reporting_boundaries_considered_and_calculation_methods_for_estimating_scope_3_ghg_emissions',
    datapoint_id: 'E1-6_29',
    esg_category: 'environmental',
    name_en:
      'Disclosure of reporting boundaries considered and calculation methods for estimating Scope 3 GHG emissions',
    name_es:
      'Divulgación de los límites de presentación de informes considerados y métodos de cálculo para estimar las emisiones de GEI de Alcance 3',
    name_pt:
      'Divulgação dos limites de relato considerados e métodos de cálculo para estimar as emissões de GEE de Escopo 3'
  },
  {
    id: 'df6d410e-d955-4805-b68d-bf31ba9ba6c3',
    name_dcycle: 'ghg_emissions_intensity,_location-based_(total_ghg_emissions_per_net_revenue)',
    datapoint_id: 'E1-6_30',
    esg_category: 'environmental',
    name_en: 'GHG emissions intensity, location-based (total GHG emissions per net revenue)',
    name_es:
      'Intensidad de las emisiones de GEI, basada en la ubicación (emisiones totales de GEI por ingreso neto)',
    name_pt:
      'Intensidade de emissões de GEE, com base na localização (total de emissões de GEE por receita líquida)'
  },
  {
    id: '29bce266-21e2-4d16-a9d2-0eb1361e1249',
    name_dcycle: 'ghg_emissions_intensity,_market-based_(total_ghg_emissions_per_net_revenue)',
    datapoint_id: 'E1-6_31',
    esg_category: 'environmental',
    name_en: 'GHG emissions intensity, market-based (total GHG emissions per net revenue)',
    name_es:
      'Intensidad de las emisiones de GEI, basada en el mercado (emisiones totales de GEI por ingreso neto)',
    name_pt:
      'Intensidade de emissões de GEE, com base no mercado (total de emissões de GEE por receita líquida)'
  },
  {
    id: 'fb2e6d01-58fc-4bb2-bea8-bd454ecf8405',
    name_dcycle:
      'disclosure_of_reconciliation_to_financial_statements_of_net_revenue_used_for_calculation_of_ghg_emissions_intensity',
    datapoint_id: 'E1-6_32',
    esg_category: 'environmental',
    name_en:
      'Disclosure of reconciliation to financial statements of net revenue used for calculation of GHG emissions intensity',
    name_es:
      'Divulgación de la conciliación con los estados financieros de los ingresos netos utilizados para el cálculo de la intensidad de las emisiones de GEI',
    name_pt:
      'Divulgação da conciliação com as demonstrações financeiras da receita líquida utilizada para cálculo da intensidade de emissões de GEE'
  },
  {
    id: 'edde6278-f83e-49da-9b42-80cdd35530a3',
    name_dcycle: 'net_revenue',
    datapoint_id: 'E1-6_33',
    esg_category: 'environmental',
    name_en: 'Net revenue',
    name_es: 'Ingresos netos',
    name_pt: 'Receita líquida'
  },
  {
    id: '2d666f15-5614-45b0-a83f-8e27314f325d',
    name_dcycle: 'net_revenue_used_to_calculate_ghg_intensity',
    datapoint_id: 'E1-6_34',
    esg_category: 'environmental',
    name_en: 'Net revenue used to calculate GHG intensity',
    name_es: 'Ingresos netos utilizados para calcular la intensidad de GEI',
    name_pt: 'Receita líquida usada para calcular a intensidade de GEE'
  },
  {
    id: '34d644d7-7105-4132-a6dc-dbdee3e99c41',
    name_dcycle: 'net_revenue_other_than_used_to_calculate_ghg_intensity',
    datapoint_id: 'E1-6_35',
    esg_category: 'environmental',
    name_en: 'Net revenue other than used to calculate GHG intensity',
    name_es: 'Ingresos netos distintos de los utilizados para calcular la intensidad de GEI',
    name_pt: 'Receita líquida que não seja usada para calcular a intensidade de GEE'
  },
  {
    id: 'b6874fd3-9ef1-4210-8a05-729e2fdfef84',
    name_dcycle:
      'disclosure_of_ghg_removals_and_storage_resulting_from_projects_developed_in_own_operations_or_contributed_to_in_upstream_and_downstream_value_chain',
    datapoint_id: 'E1-7_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of GHG removals and storage resulting from projects developed in own operations or contributed to in upstream and downstream value chain',
    name_es:
      'Divulgación de remociones y almacenamiento de GEI resultantes de proyectos desarrollados en operaciones propias o en los que se ha contribuido en la cadena de valor upstream y downstream.',
    name_pt:
      'Divulgação de remoções e armazenamento de GEE resultantes de projetos desenvolvidos em operações próprias ou para os quais contribuiu na cadeia de valor upstream e downstream'
  },
  {
    id: 'd559e6f8-74e0-4e17-be45-3e3da040bc90',
    name_dcycle:
      'disclosure_of_ghg_emission_reductions_or_removals_from_climate_change_mitigation_projects_outside_value_chain_financed_or_to_be_financed_through_any_purchase_of_carbon_credits',
    datapoint_id: 'E1-7_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of GHG emission reductions or removals from climate change mitigation projects outside value chain financed or to be financed through any purchase of carbon credits',
    name_es:
      'Divulgación de reducciones o eliminaciones de emisiones de GEI de proyectos de mitigación del cambio climático fuera de la cadena de valor financiados o por financiar mediante cualquier compra de créditos de carbono.',
    name_pt:
      'Divulgação de reduções ou remoções de emissões de GEE provenientes de projetos de mitigação das alterações climáticas fora da cadeia de valor financiados ou a serem financiados através de qualquer compra de créditos de carbono'
  },
  {
    id: 'a0ff8e24-da6d-4c29-812b-bceeba849184',
    name_dcycle: 'removals_and_carbon_credits_are_used',
    datapoint_id: 'E1-7_03',
    esg_category: 'environmental',
    name_en: 'Removals and carbon credits are used',
    name_es: 'Se utilizan eliminaciones y créditos de carbono.',
    name_pt: 'Remoções e créditos de carbono são usados'
  },
  {
    id: '00f46ccc-f91b-45b8-b1b6-4d4df2eed4d5',
    name_dcycle:
      'ghg_removals_and_storage_activity_by_undertaking_scope_(breakdown_by_own_operations_and_value_chain)_and_by_removal_and_storage_activity',
    datapoint_id: 'E1-7_04',
    esg_category: 'environmental',
    name_en:
      'GHG Removals and storage Activity by undertaking scope (breakdown by own operations and value chain) and by removal and storage activity',
    name_es:
      'Remoción y almacenamiento de GEI Actividad por alcance de la empresa (desglose por operaciones propias y cadena de valor) y por actividad de remoción y almacenamiento',
    name_pt:
      'Atividade de remoção e armazenamento de GEE por escopo do empreendimento (decomposição por operações próprias e cadeia de valor) e por atividade de remoção e armazenamento'
  },
  {
    id: 'fd0fd9e3-bdbf-4393-aa1c-e79495a11f1f',
    name_dcycle: 'total_ghg_removals_and_storage',
    datapoint_id: 'E1-7_05',
    esg_category: 'environmental',
    name_en: 'Total GHG removals and storage',
    name_es: 'Eliminación y almacenamiento total de GEI',
    name_pt: 'Total de remoções e armazenamento de GEE'
  },
  {
    id: '2075b640-4a00-45ee-94d8-3af929237db9',
    name_dcycle: 'ghg_emissions_associated_with_removal_activity',
    datapoint_id: 'E1-7_06',
    esg_category: 'environmental',
    name_en: 'GHG emissions associated with removal activity',
    name_es: 'Emisiones de GEI asociadas con la actividad de remoción',
    name_pt: 'Emissões de GEE associadas à atividade de remoção'
  },
  {
    id: '019159dd-9821-4053-b1c4-e9d2678904e4',
    name_dcycle: 'reversals',
    datapoint_id: 'E1-7_07',
    esg_category: 'environmental',
    name_en: 'Reversals',
    name_es: 'Reversiones',
    name_pt: 'Reversões'
  },
  {
    id: '58586aa0-dc56-4476-bafc-7f6c04d01547',
    name_dcycle:
      'disclosure_of_calculation_assumptions,_methodologies_and_frameworks_applied_(ghg_removals_and_storage)',
    datapoint_id: 'E1-7_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of calculation assumptions, methodologies and frameworks applied (GHG removals and storage)',
    name_es:
      'Divulgación de supuestos de cálculo, metodologías y marcos aplicados (eliminación y almacenamiento de GEI)',
    name_pt:
      'Divulgação das premissas de cálculo, metodologias e frameworks aplicados (remoções e armazenamento de GEE)'
  },
  {
    id: '7943aa35-44b5-4c21-990f-d20e8ced3630',
    name_dcycle:
      'removal_activity_has_been_converted_into_carbon_credits_and_sold_on_to_other_parties_on_voluntary_market',
    datapoint_id: 'E1-7_09',
    esg_category: 'environmental',
    name_en:
      'Removal activity has been converted into carbon credits and sold on to other parties on voluntary market',
    name_es:
      'La actividad de eliminación se ha convertido en créditos de carbono y se ha vendido a otras partes en el mercado voluntario.',
    name_pt:
      'A atividade de remoção foi convertida em créditos de carbono e vendida a outras partes no mercado voluntário'
  },
  {
    id: 'ab6dab7c-22b6-4eaf-ace4-abfa3e6c1f72',
    name_dcycle:
      'total_amount_of_carbon_credits_outside_value_chain_that_are_verified_against_recognised_quality_standards_and_cancelled',
    datapoint_id: 'E1-7_10',
    esg_category: 'environmental',
    name_en:
      'Total amount of carbon credits outside value chain that are verified against recognised quality standards and cancelled',
    name_es:
      'Cantidad total de créditos de carbono fuera de la cadena de valor que se verifican con estándares de calidad reconocidos y se cancelan',
    name_pt:
      'Quantidade total de créditos de carbono fora da cadeia de valor que são verificados em relação a padrões de qualidade reconhecidos e cancelados'
  },
  {
    id: 'eaebeb7c-ab97-4bbd-8315-b6b7fe76684d',
    name_dcycle:
      'total_amount_of_carbon_credits_outside_value_chain_planned_to_be_cancelled_in_future',
    datapoint_id: 'E1-7_11',
    esg_category: 'environmental',
    name_en: 'Total amount of carbon credits outside value chain planned to be cancelled in future',
    name_es:
      'Cantidad total de créditos de carbono fuera de la cadena de valor que se planea cancelar en el futuro',
    name_pt:
      'Quantidade total de créditos de carbono fora da cadeia de valor prevista para ser cancelada no futuro'
  },
  {
    id: '1e42b320-e612-494b-a1d1-028920a6d734',
    name_dcycle: 'disclosure_of_extent_of_use_and_quality_criteria_used_for_carbon_credits',
    datapoint_id: 'E1-7_12',
    esg_category: 'environmental',
    name_en: 'Disclosure of extent of use and quality criteria used for carbon credits',
    name_es:
      'Divulgación del alcance del uso y los criterios de calidad utilizados para los créditos de carbono.',
    name_pt:
      'Divulgação da extensão do uso e critérios de qualidade utilizados para créditos de carbono'
  },
  {
    id: 'a75c76d9-a7d5-4a7d-a3d6-69502904fd46',
    name_dcycle: 'percentage_of_reduction_projects',
    datapoint_id: 'E1-7_13',
    esg_category: 'environmental',
    name_en: 'Percentage of reduction projects',
    name_es: 'Porcentaje de proyectos de reducción',
    name_pt: 'Porcentagem de projetos de redução'
  },
  {
    id: '50391d6d-4be3-4afc-b262-2d2b8d5cd766',
    name_dcycle: 'percentage_of_removal_projects',
    datapoint_id: 'E1-7_14',
    esg_category: 'environmental',
    name_en: 'Percentage of removal projects',
    name_es: 'Porcentaje de proyectos de remoción',
    name_pt: 'Porcentagem de projetos de remoção'
  },
  {
    id: '1618ecfc-96af-459e-91c6-7204bb4aaa16',
    name_dcycle: 'type_of_carbon_credits_from_removal_projects',
    datapoint_id: 'E1-7_15',
    esg_category: 'environmental',
    name_en: 'Type of carbon credits from removal projects',
    name_es: 'Tipo de créditos de carbono de proyectos de eliminación',
    name_pt: 'Tipo de créditos de carbono provenientes de projetos de remoção'
  },
  {
    id: '60d3108a-ec41-41f2-a280-aa784e1b20ef',
    name_dcycle: 'percentage_for_recognised_quality_standard',
    datapoint_id: 'E1-7_16',
    esg_category: 'environmental',
    name_en: 'Percentage for recognised quality standard',
    name_es: 'Porcentaje para estándar de calidad reconocido',
    name_pt: 'Porcentagem para padrão de qualidade reconhecido'
  },
  {
    id: 'b615f2eb-0b00-4ead-8251-dcc2d4b94e8a',
    name_dcycle: 'percentage_issued_from_projects_in_european_union',
    datapoint_id: 'E1-7_17',
    esg_category: 'environmental',
    name_en: 'Percentage issued from projects in European Union',
    name_es: 'Porcentaje emitido por proyectos en la Unión Europea',
    name_pt: 'Percentagem emitida por projetos na União Europeia'
  },
  {
    id: '31c39a00-dc88-49ce-8807-227c3e1dcbbc',
    name_dcycle: 'percentage_that_qualifies_as_corresponding_adjustment',
    datapoint_id: 'E1-7_18',
    esg_category: 'environmental',
    name_en: 'Percentage that qualifies as corresponding adjustment',
    name_es: 'Porcentaje que califica como ajuste correspondiente',
    name_pt: 'Porcentagem que se qualifica como ajuste correspondente'
  },
  {
    id: '8ccab522-b4d6-4363-b056-5e2f2dadfdb8',
    name_dcycle: 'date_when_carbon_credits_outside_value_chain_are_planned_to_be_cancelled',
    datapoint_id: 'E1-7_19',
    esg_category: 'environmental',
    name_en: 'Date when carbon credits outside value chain are planned to be cancelled',
    name_es:
      'Fecha en la que se prevé cancelar los créditos de carbono fuera de la cadena de valor',
    name_pt: 'Data em que se planeja cancelar os créditos de carbono fora da cadeia de valor'
  },
  {
    id: 'b142ca33-2bbb-4343-9443-906564d0140a',
    name_dcycle:
      'explanation_of_scope,_methodologies_and_frameworks_applied_and_how_residual_ghg_emissions_are_intended_to_be_neutralised',
    datapoint_id: 'E1-7_20',
    esg_category: 'environmental',
    name_en:
      'Explanation of scope, methodologies and frameworks applied and how residual GHG emissions are intended to be neutralised',
    name_es:
      'Explicación del alcance, las metodologías y los marcos aplicados y cómo se pretende neutralizar las emisiones residuales de GEI.',
    name_pt:
      'Explicação do escopo, metodologias e estruturas aplicadas e como as emissões residuais de GEE devem ser neutralizadas'
  },
  {
    id: '4874ba09-629d-461b-ac66-4b7a8abd04b2',
    name_dcycle:
      'public_claims_of_ghg_neutrality_that_involve_use_of_carbon_credits_have_been_made',
    datapoint_id: 'E1-7_21',
    esg_category: 'environmental',
    name_en: 'Public claims of GHG neutrality that involve use of carbon credits have been made',
    name_es:
      'Se han hecho declaraciones públicas de neutralidad de GEI que implican el uso de créditos de carbono.',
    name_pt:
      'Foram feitas reivindicações públicas de neutralidade em termos de GEE que envolvem o uso de créditos de carbono'
  },
  {
    id: '5312076c-4755-49df-adf2-7c18c7afb561',
    name_dcycle:
      'public_claims_of_ghg_neutrality_that_involve_use_of_carbon_credits_are_accompanied_by_ghg_emission_reduction_targets',
    datapoint_id: 'E1-7_22',
    esg_category: 'environmental',
    name_en:
      'Public claims of GHG neutrality that involve use of carbon credits are accompanied by GHG emission reduction targets',
    name_es:
      'Las afirmaciones públicas de neutralidad de GEI que implican el uso de créditos de carbono van acompañadas de objetivos de reducción de emisiones de GEI.',
    name_pt:
      'As reivindicações públicas de neutralidade de GEE que envolvem o uso de créditos de carbono são acompanhadas por metas de redução de emissões de GEE'
  },
  {
    id: '1514f68d-f9ae-4a3e-af95-e1992e6b18c7',
    name_dcycle:
      'claims_of_ghg_neutrality_and_reliance_on_carbon_credits_neither_impede_nor_reduce_achievement_of_ghg_emission_reduction_targets_or_net_zero_target',
    datapoint_id: 'E1-7_23',
    esg_category: 'environmental',
    name_en:
      'Claims of GHG neutrality and reliance on carbon credits neither impede nor reduce achievement of GHG emission reduction targets or net zero target',
    name_es:
      'Las afirmaciones de neutralidad de GEI y dependencia de los créditos de carbono no impiden ni reducen el logro de los objetivos de reducción de emisiones de GEI o del objetivo de cero emisiones netas.',
    name_pt:
      'As reivindicações de neutralidade de GEE e a dependência de créditos de carbono não impedem nem reduzem o cumprimento das metas de redução de emissões de GEE ou da meta líquida zero'
  },
  {
    id: '82813e9d-519e-4842-96b8-b6fa11b87069',
    name_dcycle:
      'explanation_of_whether_and_how_public_claims_of_ghg_neutrality_that_involve_use_of_carbon_credits_are_accompanied_by_ghg_emission_reduction_targets_and_how_claims_of_ghg_neutrality_and_reliance_on_carbon_credits_neither_impede_nor_reduce_achievement_of_ghg_emission_reduction_targets_or_net_zero_target',
    datapoint_id: 'E1-7_24',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how public claims of GHG neutrality that involve use of carbon credits are accompanied by GHG emission reduction targets and how claims of GHG neutrality and reliance on carbon credits neither impede nor reduce achievement of GHG emission reduction targets or net zero target',
    name_es:
      'Explicación de si las afirmaciones públicas de neutralidad de GEI que implican el uso de créditos de carbono van acompañadas de objetivos de reducción de emisiones de GEI y de qué manera, y cómo las afirmaciones de neutralidad de GEI y la dependencia de los créditos de carbono no impiden ni reducen el logro de los objetivos de reducción de emisiones de GEI o el objetivo de cero emisiones netas.',
    name_pt:
      'Explicação sobre se e como as reivindicações públicas de neutralidade de GEE que envolvem o uso de créditos de carbono são acompanhadas por metas de redução de emissões de GEE e como as reivindicações de neutralidade de GEE e a dependência de créditos de carbono não impedem nem reduzem o cumprimento das metas de redução de emissões de GEE ou da meta líquida zero'
  },
  {
    id: '3cb65de9-870c-4833-be34-2d83dcbcaca2',
    name_dcycle: 'explanation_of_credibility_and_integrity_of_carbon_credits_used',
    datapoint_id: 'E1-7_25',
    esg_category: 'environmental',
    name_en: 'Explanation of credibility and integrity of carbon credits used',
    name_es: 'Explicación de la credibilidad e integridad de los créditos de carbono utilizados.',
    name_pt: 'Explicação da credibilidade e integridade dos créditos de carbono utilizados'
  },
  {
    id: '64aa384c-cab5-4cf9-b9bb-0606a741f880',
    name_dcycle: 'carbon_pricing_scheme_by_type',
    datapoint_id: 'E1-8_01',
    esg_category: 'environmental',
    name_en: 'Carbon pricing scheme by type',
    name_es: 'Esquema de fijación de precios del carbono por tipo',
    name_pt: 'Esquema de precificação de carbono por tipo'
  },
  {
    id: 'c65cfec4-9499-443e-aa41-455d9b63beeb',
    name_dcycle: 'type_of_internal_carbon_pricing_scheme',
    datapoint_id: 'E1-8_02',
    esg_category: 'environmental',
    name_en: 'Type of internal carbon pricing scheme',
    name_es: 'Tipo de sistema interno de fijación de precios del carbono',
    name_pt: 'Tipo de esquema interno de precificação de carbono'
  },
  {
    id: '019e7d84-f24d-4ed2-a5c3-68c586ff4542',
    name_dcycle: 'description_of_specific_scope_of_application_of_carbon_pricing_scheme',
    datapoint_id: 'E1-8_03',
    esg_category: 'environmental',
    name_en: 'Description of specific scope of application of carbon pricing scheme',
    name_es:
      'Descripción del ámbito de aplicación específico del sistema de fijación de precios del carbono',
    name_pt: 'Descrição do âmbito específico de aplicação do regime de fixação de preços do carbono'
  },
  {
    id: '6a028878-5e3e-4fec-8e37-e644f58850f1',
    name_dcycle: 'carbon_price_applied_for_each_metric_tonne_of_greenhouse_gas_emission',
    datapoint_id: 'E1-8_04',
    esg_category: 'environmental',
    name_en: 'Carbon price applied for each metric tonne of greenhouse gas emission',
    name_es:
      'Precio del carbono aplicado por cada tonelada métrica de emisión de gases de efecto invernadero',
    name_pt:
      'Preço do carbono aplicado para cada tonelada métrica de emissão de gases de efeito estufa'
  },
  {
    id: '5f5ae0b8-07ef-44f6-b8a5-4b0e69e58bbc',
    name_dcycle: 'description_of_critical_assumptions_made_to_determine_carbon_price_applied',
    datapoint_id: 'E1-8_05',
    esg_category: 'environmental',
    name_en: 'Description of critical assumptions made to determine carbon price applied',
    name_es:
      'Descripción de los supuestos críticos hechos para determinar el precio del carbono aplicado',
    name_pt: 'Descrição das premissas críticas feitas para determinar o preço do carbono aplicado'
  },
  {
    id: 'c5e004d0-43ec-4eac-ab18-4b4d72ca93e0',
    name_dcycle:
      'percentage_of_gross_scope_1_greenhouse_gas_emissions_covered_by_internal_carbon_pricing_scheme',
    datapoint_id: 'E1-8_06',
    esg_category: 'environmental',
    name_en:
      'Percentage of gross Scope 1 greenhouse gas emissions covered by internal carbon pricing scheme',
    name_es:
      'Porcentaje de emisiones brutas de gases de efecto invernadero de Alcance 1 cubiertas por el sistema interno de fijación de precios del carbono',
    name_pt:
      'Percentagem das emissões brutas de gases com efeito de estufa de Âmbito 1 cobertas pelo regime interno de precificação do carbono'
  },
  {
    id: 'a734564c-ae3c-4e7d-8de3-a90e0f07d677',
    name_dcycle:
      'percentage_of_gross_scope_2_greenhouse_gas_emissions_covered_by_internal_carbon_pricing_scheme',
    datapoint_id: 'E1-8_07',
    esg_category: 'environmental',
    name_en:
      'Percentage of gross Scope 2 greenhouse gas emissions covered by internal carbon pricing scheme',
    name_es:
      'Porcentaje de emisiones brutas de gases de efecto invernadero de alcance 2 cubiertas por el sistema interno de fijación de precios del carbono',
    name_pt:
      'Percentagem das emissões brutas de gases com efeito de estufa de Âmbito 2 cobertas pelo regime interno de precificação do carbono'
  },
  {
    id: '0df393bc-88ab-4c79-b141-49f355510114',
    name_dcycle:
      'percentage_of_gross_scope_3_greenhouse_gas_emissions_covered_by_internal_carbon_pricing_scheme',
    datapoint_id: 'E1-8_08',
    esg_category: 'environmental',
    name_en:
      'Percentage of gross Scope 3 greenhouse gas emissions covered by internal carbon pricing scheme',
    name_es:
      'Porcentaje de emisiones brutas de gases de efecto invernadero de Alcance 3 cubiertas por el sistema interno de fijación de precios del carbono',
    name_pt:
      'Percentagem das emissões brutas de gases com efeito de estufa de Âmbito 3 cobertas pelo regime interno de precificação do carbono'
  },
  {
    id: '124f003f-9133-4d0c-bc15-4cd7a923c9d9',
    name_dcycle:
      'disclosure_of_whether_and_how_carbon_price_used_in_internal_carbon_pricing_scheme_is_consistent_with_carbon_price_used_in_financial_statements',
    datapoint_id: 'E1-8_09',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how carbon price used in internal carbon pricing scheme is consistent with carbon price used in financial statements',
    name_es:
      'Divulgación de si el precio del carbono utilizado en el sistema interno de fijación de precios del carbono es coherente con el precio del carbono utilizado en los estados financieros y de qué manera.',
    name_pt:
      'Divulgação sobre se e como o preço do carbono utilizado no esquema interno de precificação do carbono é consistente com o preço do carbono utilizado nas demonstrações financeiras'
  },
  {
    id: '7c9d603e-50da-4806-b7d2-7a1a2713205e',
    name_dcycle:
      'assets_at_material_physical_risk_before_considering_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_01',
    esg_category: 'environmental',
    name_en:
      'Assets at material physical risk before considering climate change adaptation actions',
    name_es:
      'Activos en riesgo físico material antes de considerar acciones de adaptación al cambio climático',
    name_pt:
      'Ativos em risco físico material antes de considerar ações de adaptação às alterações climáticas'
  },
  {
    id: 'c4a85fe8-9d62-4ab2-9674-799d521cc063',
    name_dcycle:
      'assets_at_acute_material_physical_risk_before_considering_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_02',
    esg_category: 'environmental',
    name_en:
      'Assets at acute material physical risk before considering climate change adaptation actions',
    name_es:
      'Activos en riesgo físico material agudo antes de considerar acciones de adaptación al cambio climático',
    name_pt:
      'Ativos em risco físico material agudo antes de considerar ações de adaptação às alterações climáticas'
  },
  {
    id: '6f6754d1-c1a3-4999-8efe-727081ee5d42',
    name_dcycle:
      'assets_at_chronic_material_physical_risk_before_considering_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_03',
    esg_category: 'environmental',
    name_en:
      'Assets at chronic material physical risk before considering climate change adaptation actions',
    name_es:
      'Activos en riesgo físico material crónico antes de considerar acciones de adaptación al cambio climático',
    name_pt:
      'Ativos em risco físico material crónico antes de considerar ações de adaptação às alterações climáticas'
  },
  {
    id: '73ebcd8a-0191-4ea0-bcb1-3103a4f536fa',
    name_dcycle:
      'percentage_of_assets_at_material_physical_risk_before_considering_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_04',
    esg_category: 'environmental',
    name_en:
      'Percentage of assets at material physical risk before considering climate change adaptation actions',
    name_es:
      'Porcentaje de activos en riesgo físico material antes de considerar acciones de adaptación al cambio climático',
    name_pt:
      'Percentagem de ativos em risco físico material antes de serem consideradas ações de adaptação às alterações climáticas'
  },
  {
    id: '3441f01b-0833-4d18-ac82-68398e416f79',
    name_dcycle: 'disclosure_of_location_of_significant_assets_at_material_physical_risk',
    datapoint_id: 'E1-9_05',
    esg_category: 'environmental',
    name_en: 'Disclosure of location of significant assets at material physical risk',
    name_es: 'Divulgación de la ubicación de activos importantes con riesgo físico importante',
    name_pt: 'Divulgação da localização de ativos significativos com risco físico material'
  },
  {
    id: 'd60f10a9-85d9-4cf4-8b35-c1d09f6a2503',
    name_dcycle:
      'disclosure_of_location_of_its_significant_assets_at_material_physical_risk_(disaggregated_by_nuts_codes)',
    datapoint_id: 'E1-9_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of location of its significant assets at material physical risk (disaggregated by NUTS codes)',
    name_es:
      'Divulgación de la ubicación de sus activos importantes con riesgo físico importante (desagregados por códigos NUTS)',
    name_pt:
      'Divulgação da localização dos seus ativos significativos em risco físico material (desagregados por códigos NUTS)'
  },
  {
    id: '46cf52b7-4b0c-49db-95b3-8f38a2c2545e',
    name_dcycle:
      'percentage_of_assets_at_material_physical_risk_addressed_by_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_07',
    esg_category: 'environmental',
    name_en:
      'Percentage of assets at material physical risk addressed by climate change adaptation actions',
    name_es:
      'Porcentaje de activos en riesgo físico material abordados por acciones de adaptación al cambio climático',
    name_pt:
      'Percentagem de ativos em risco físico material abordados por ações de adaptação às alterações climáticas'
  },
  {
    id: 'ae8ab146-bfee-4544-ad05-b505bcfa4933',
    name_dcycle: 'net_revenue_from_business_activities_at_material_physical_risk',
    datapoint_id: 'E1-9_08',
    esg_category: 'environmental',
    name_en: 'Net revenue from business activities at material physical risk',
    name_es: 'Ingresos netos de actividades comerciales con riesgo físico material',
    name_pt: 'Receita líquida de atividades comerciais com risco físico material'
  },
  {
    id: '99103815-6871-4aa1-84c3-ed98653b5193',
    name_dcycle: 'percentage_of_net_revenue_from_business_activities_at_material_physical_risk',
    datapoint_id: 'E1-9_09',
    esg_category: 'environmental',
    name_en: 'Percentage of net revenue from business activities at material physical risk',
    name_es: 'Porcentaje de ingresos netos de actividades comerciales con riesgo físico material',
    name_pt: 'Percentagem da receita líquida de atividades empresariais com risco físico material'
  },
  {
    id: 'b42dc036-5ec9-49ab-83fa-d9b31fd5337d',
    name_dcycle:
      'disclosure_of_whether_and_how_anticipated_financial_effects_for_assets_and_business_activities_at_material_physical_risk_have_been_assessed',
    datapoint_id: 'E1-9_10',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how anticipated financial effects for assets and business activities at material physical risk have been assessed',
    name_es:
      'Divulgación de si se han evaluado y cómo se han evaluado los efectos financieros previstos para los activos y actividades comerciales con riesgo físico importante.',
    name_pt:
      'Divulgação sobre se e como os efeitos financeiros previstos para ativos e atividades comerciais com risco físico material foram avaliados'
  },
  {
    id: '906a27e5-3c6d-4b15-90b2-66e809922929',
    name_dcycle:
      'disclosure_of_whether_and_how_assessment_of_assets_and_business_activities_considered_to_be_at_material_physical_risk_relies_on_or_is_part_of_process_to_determine_material_physical_risk_and_to_determine_climate_scenarios',
    datapoint_id: 'E1-9_11',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how assessment of assets and business activities considered to be at material physical risk relies on or is part of process to determine material physical risk and to determine climate scenarios',
    name_es:
      'Divulgación de si y cómo la evaluación de activos y actividades comerciales consideradas en riesgo físico importante depende o es parte del proceso para determinar el riesgo físico importante y para determinar escenarios climáticos.',
    name_pt:
      'Divulgação sobre se e como a avaliação de ativos e atividades comerciais consideradas de risco físico material depende ou faz parte do processo para determinar o risco físico material e para determinar cenários climáticos'
  },
  {
    id: '38e39ef8-20ab-4d9d-b166-2fbc518f9f57',
    name_dcycle:
      'disclosure_of_risk_factors_for_net_revenue_from_business_activities_at_material_physical_risk',
    datapoint_id: 'E1-9_12',
    esg_category: 'environmental',
    name_en:
      'Disclosure of risk factors for net revenue from business activities at material physical risk',
    name_es:
      'Divulgación de factores de riesgo para ingresos netos de actividades comerciales con riesgo físico material',
    name_pt:
      'Divulgação de fatores de risco para receita líquida de atividades empresariais com risco físico material'
  },
  {
    id: 'c0a33782-4206-4718-916b-dc74097ca646',
    name_dcycle:
      'disclosure_of_magnitude_of_anticipated_financial_effects_in_terms_of_margin_erosion_for_business_activities_at_material_physical_risk',
    datapoint_id: 'E1-9_13',
    esg_category: 'environmental',
    name_en:
      'Disclosure of magnitude of anticipated financial effects in terms of margin erosion for business activities at material physical risk',
    name_es:
      'Divulgación de la magnitud de los efectos financieros previstos en términos de erosión de márgenes para actividades comerciales con riesgo físico importante',
    name_pt:
      'Divulgação da magnitude dos efeitos financeiros previstos em termos de erosão de margens para atividades comerciais com risco físico material'
  },
  {
    id: '2671a73c-dff1-49e1-ac5a-a3ce7b81676b',
    name_dcycle: 'assets_at_material_transition_risk_before_considering_climate_mitigation_actions',
    datapoint_id: 'E1-9_14',
    esg_category: 'environmental',
    name_en: 'Assets at material transition risk before considering climate mitigation actions',
    name_es:
      'Activos en riesgo de transición material antes de considerar acciones de mitigación climática',
    name_pt:
      'Ativos em risco de transição material antes de considerar ações de mitigação climática'
  },
  {
    id: '7ff0d7ec-c2b1-4e1e-9544-7fdad7675ad9',
    name_dcycle:
      'percentage_of_assets_at_material_transition_risk_before_considering_climate_mitigation_actions',
    datapoint_id: 'E1-9_15',
    esg_category: 'environmental',
    name_en:
      'Percentage of assets at material transition risk before considering climate mitigation actions',
    name_es:
      'Porcentaje de activos en riesgo de transición material antes de considerar acciones de mitigación climática',
    name_pt:
      'Percentagem de ativos em risco de transição material antes de considerar ações de mitigação climática'
  },
  {
    id: 'b415603d-6f09-4973-904d-eeaa53ea55de',
    name_dcycle:
      'percentage_of_assets_at_material_transition_risk_addressed_by_climate_change_mitigation_actions',
    datapoint_id: 'E1-9_16',
    esg_category: 'environmental',
    name_en:
      'Percentage of assets at material transition risk addressed by climate change mitigation actions',
    name_es:
      'Porcentaje de activos con riesgo de transición material abordados por acciones de mitigación del cambio climático',
    name_pt:
      'Percentagem de ativos em risco material de transição abordados por ações de mitigação das alterações climáticas'
  },
  {
    id: '487485f3-12ed-49aa-b292-af5043e1725a',
    name_dcycle: 'total_carrying_amount_of_real_estate_assets_by_energy_efficiency_classes',
    datapoint_id: 'E1-9_17',
    esg_category: 'environmental',
    name_en: 'Total carrying amount of real estate assets by energy efficiency classes',
    name_es:
      'Importe en libros total de los activos inmobiliarios por clases de eficiencia energética',
    name_pt: 'Valor contábil total de ativos imobiliários por classes de eficiência energética'
  },
  {
    id: '25c613cb-59d7-4694-999f-a01ac12a7741',
    name_dcycle:
      'disclosure_of_whether_and_how_potential_effects_on_future_financial_performance_and_position_for_assets_and_business_activities_at_material_transition_risk_have_been_assessed',
    datapoint_id: 'E1-9_18',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how potential effects on future financial performance and position for assets and business activities at material transition risk have been assessed',
    name_es:
      'Divulgación de si se han evaluado y cómo se han evaluado los efectos potenciales sobre el desempeño financiero futuro y la posición de los activos y actividades comerciales con riesgo de transición importante.',
    name_pt:
      'Divulgação sobre se e como foram avaliados os potenciais efeitos sobre o desempenho financeiro futuro e a posição dos ativos e atividades empresariais com risco de transição material'
  },
  {
    id: 'ceab2797-d9fd-4618-8adb-f82deb69d63c',
    name_dcycle:
      'disclosure_of_whether_and_how_assessment_of_assets_and_business_activities_considered_to_be_at_material_transition_risk_relies_on_or_is_part_of_process_to_determine_material_transition_risks_and_to_determine_scenarios',
    datapoint_id: 'E1-9_19',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how assessment of assets and business activities considered to be at material transition risk relies on or is part of process to determine material transition risks and to determine scenarios',
    name_es:
      'Divulgación de si, y cómo, la evaluación de activos y actividades comerciales consideradas con riesgo de transición importante depende o es parte del proceso para determinar riesgos de transición importantes y para determinar escenarios.',
    name_pt:
      'Divulgação sobre se e como a avaliação de ativos e atividades de negócios consideradas como estando em risco de transição material depende ou faz parte do processo para determinar riscos de transição materiais e para determinar cenários'
  },
  {
    id: 'ad00a667-34ed-4082-9711-c64eaf02703d',
    name_dcycle: 'estimated_amount_of_potentially_stranded_assets',
    datapoint_id: 'E1-9_20',
    esg_category: 'environmental',
    name_en: 'Estimated amount of potentially stranded assets',
    name_es: 'Cantidad estimada de activos potencialmente varados',
    name_pt: 'Quantidade estimada de ativos potencialmente irrecuperáveis'
  },
  {
    id: '5ab957cd-804e-40fd-85f0-ffd37807d685',
    name_dcycle:
      'percentage_of_estimated_share_of_potentially_stranded_assets_of_total_assets_at_material_transition_risk',
    datapoint_id: 'E1-9_21',
    esg_category: 'environmental',
    name_en:
      'Percentage of estimated share of potentially stranded assets of total assets at material transition risk',
    name_es:
      'Porcentaje de la proporción estimada de activos potencialmente varados del total de activos en riesgo de transición material',
    name_pt:
      'Percentagem da parcela estimada de ativos potencialmente irrecuperáveis ​​no total de ativos com risco de transição material'
  },
  {
    id: '94380d07-54d7-4ef1-b9b5-f627b8c4d36f',
    name_dcycle:
      'total_carrying_amount_of_real_estate_assets_for_which_energy_consumption_is_based_on_internal_estimates',
    datapoint_id: 'E1-9_22',
    esg_category: 'environmental',
    name_en:
      'Total carrying amount of real estate assets for which energy consumption is based on internal estimates',
    name_es:
      'Importe en libros total de los activos inmobiliarios cuyo consumo de energía se basa en estimaciones internas',
    name_pt:
      'Valor contábil total de ativos imobiliários cujo consumo de energia é baseado em estimativas internas'
  },
  {
    id: 'd3774f19-8b0a-409e-88bb-058e605b8cc7',
    name_dcycle:
      'liabilities_from_material_transition_risks_that_may_have_to_be_recognised_in_financial_statements',
    datapoint_id: 'E1-9_23',
    esg_category: 'environmental',
    name_en:
      'Liabilities from material transition risks that may have to be recognised in financial statements',
    name_es:
      'Pasivos por riesgos de transición importantes que pueden tener que reconocerse en los estados financieros',
    name_pt:
      'Passivos decorrentes de riscos de transição materiais que podem ter de ser reconhecidos nas demonstrações financeiras'
  },
  {
    id: '39a2ac3a-5479-42a6-b8e3-ed206e3548ae',
    name_dcycle:
      'number_of_scope_1_ghg_emission_allowances_within_regulated_emission_trading_schemes',
    datapoint_id: 'E1-9_24',
    esg_category: 'environmental',
    name_en: 'Number of Scope 1 GHG emission allowances within regulated emission trading schemes',
    name_es:
      'Número de derechos de emisión de GEI de alcance 1 dentro de esquemas regulados de comercio de derechos de emisión',
    name_pt:
      'Número de licenças de emissão de GEE de Escopo 1 dentro de esquemas regulamentados de comércio de emissões'
  },
  {
    id: '1287f733-c0ff-4b2c-b209-a14a83342756',
    name_dcycle:
      'number_of_emission_allowances_stored_(from_previous_allowances)_at_beginning_of_reporting_period',
    datapoint_id: 'E1-9_25',
    esg_category: 'environmental',
    name_en:
      'Number of emission allowances stored (from previous allowances) at beginning of reporting period',
    name_es:
      'Número de derechos de emisión almacenados (de derechos anteriores) al comienzo del período del informe',
    name_pt:
      'Número de licenças de emissão armazenadas (de licenças anteriores) no início do período de relatório'
  },
  {
    id: '45667520-48bf-4ac0-8383-89074115bec7',
    name_dcycle:
      'potential_future_liabilities,_based_on_existing_contractual_agreements,_associated_with_carbon_credits_planned_to_be_cancelled_in_near_future',
    datapoint_id: 'E1-9_26',
    esg_category: 'environmental',
    name_en:
      'Potential future liabilities, based on existing contractual agreements, associated with carbon credits planned to be cancelled in near future',
    name_es:
      'Posibles obligaciones futuras, basadas en acuerdos contractuales existentes, asociadas con créditos de carbono que se planea cancelar en un futuro próximo',
    name_pt:
      'Potenciais responsabilidades futuras, com base em acordos contratuais existentes, associadas a créditos de carbono planeados para serem cancelados num futuro próximo'
  },
  {
    id: 'e37075f5-e9b0-4be8-bf0d-bf3b8ee8d7de',
    name_dcycle: 'monetised_gross_scope_1_and_2_ghg_emissions',
    datapoint_id: 'E1-9_27',
    esg_category: 'environmental',
    name_en: 'Monetised gross Scope 1 and 2 GHG emissions',
    name_es: 'Emisiones brutas de GEI de alcance 1 y 2 monetizadas',
    name_pt: 'Emissões brutas monetizadas de GEE de Escopo 1 e 2'
  },
  {
    id: '4865675b-8993-4a97-8df0-e87cd7b0e56e',
    name_dcycle: 'monetised_total_ghg_emissions',
    datapoint_id: 'E1-9_28',
    esg_category: 'environmental',
    name_en: 'Monetised total GHG emissions',
    name_es: 'Emisiones totales de GEI monetizadas',
    name_pt: 'Emissões totais de GEE monetizadas'
  },
  {
    id: 'be9d1c39-a66e-401f-9593-72f9d1aa1b00',
    name_dcycle: 'net_revenue_from_business_activities_at_material_transition_risk',
    datapoint_id: 'E1-9_29',
    esg_category: 'environmental',
    name_en: 'Net revenue from business activities at material transition risk',
    name_es: 'Ingresos netos de actividades comerciales con riesgo de transición material',
    name_pt: 'Receita líquida de atividades comerciais com risco de transição material'
  },
  {
    id: 'f4daa136-affe-479b-ace7-4636314f0d8c',
    name_dcycle: 'net_revenue_from_customers_operating_in_coal-related_activities',
    datapoint_id: 'E1-9_30',
    esg_category: 'environmental',
    name_en: 'Net revenue from customers operating in coal-related activities',
    name_es: 'Ingresos netos de clientes que operan en actividades relacionadas con el carbón',
    name_pt: 'Receita líquida de clientes que operam em atividades relacionadas ao carvão'
  },
  {
    id: '4ff4ce54-11c9-4c7b-a3c0-6fcb7970b7f4',
    name_dcycle: 'net_revenue_from_customers_operating_in_oil-related_activities',
    datapoint_id: 'E1-9_31',
    esg_category: 'environmental',
    name_en: 'Net revenue from customers operating in oil-related activities',
    name_es: 'Ingresos netos de clientes que operan en actividades relacionadas con el petróleo',
    name_pt: 'Receita líquida de clientes que operam em atividades relacionadas ao petróleo'
  },
  {
    id: 'a8154c7c-0316-4040-96d2-2fa4535df6f8',
    name_dcycle: 'net_revenue_from_customers_operating_in_gas-related_activities',
    datapoint_id: 'E1-9_32',
    esg_category: 'environmental',
    name_en: 'Net revenue from customers operating in gas-related activities',
    name_es: 'Ingresos netos de clientes que operan en actividades relacionadas con el gas',
    name_pt: 'Receita líquida de clientes que operam em atividades relacionadas com o gás'
  },
  {
    id: '020efcc6-11ee-4bff-8de7-4af5b584826a',
    name_dcycle: 'percentage_of_net_revenue_from_customers_operating_in_coal-related_activities',
    datapoint_id: 'E1-9_33',
    esg_category: 'environmental',
    name_en: 'Percentage of net revenue from customers operating in coal-related activities',
    name_es:
      'Porcentaje de ingresos netos de clientes que operan en actividades relacionadas con el carbón',
    name_pt:
      'Percentagem da receita líquida de clientes que operam em atividades relacionadas com o carvão'
  },
  {
    id: 'b7ccc611-b7a8-427b-905b-c2fbb97097b3',
    name_dcycle: 'percentage_of_net_revenue_from_customers_operating_in_oil-related_activities',
    datapoint_id: 'E1-9_34',
    esg_category: 'environmental',
    name_en: 'Percentage of net revenue from customers operating in oil-related activities',
    name_es:
      'Porcentaje de ingresos netos de clientes que operan en actividades relacionadas con el petróleo',
    name_pt:
      'Percentual da receita líquida de clientes que operam em atividades relacionadas ao petróleo'
  },
  {
    id: '0f5b080d-4566-49e0-8e46-7d4e81499a41',
    name_dcycle: 'percentage_of_net_revenue_from_customers_operating_in_gas-related_activities',
    datapoint_id: 'E1-9_35',
    esg_category: 'environmental',
    name_en: 'Percentage of net revenue from customers operating in gas-related activities',
    name_es:
      'Porcentaje de ingresos netos de clientes que operan en actividades relacionadas con el gas',
    name_pt:
      'Percentagem da receita líquida de clientes que operam em atividades relacionadas com o gás'
  },
  {
    id: '1a421d1a-02cf-4960-8fd3-167b06af5a75',
    name_dcycle: 'percentage_of_net_revenue_from_business_activities_at_material_transition_risk',
    datapoint_id: 'E1-9_36',
    esg_category: 'environmental',
    name_en: 'Percentage of net revenue from business activities at material transition risk',
    name_es:
      'Porcentaje de ingresos netos de actividades comerciales con riesgo de transición material',
    name_pt:
      'Percentagem da receita líquida de atividades empresariais com risco de transição material'
  },
  {
    id: 'a4718375-c486-4efa-b1e3-8cb27e2d4c9d',
    name_dcycle:
      'disclosure_of_risk_factors_for_net_revenue_from_business_activities_at_material_transition_risk',
    datapoint_id: 'E1-9_37',
    esg_category: 'environmental',
    name_en:
      'Disclosure of risk factors for net revenue from business activities at material transition risk',
    name_es:
      'Divulgación de factores de riesgo para ingresos netos de actividades comerciales con riesgo de transición material',
    name_pt:
      'Divulgação de fatores de risco para receita líquida de atividades empresariais com risco de transição material'
  },
  {
    id: '8e64a39e-ca34-4c96-a999-348b37ab8326',
    name_dcycle:
      'disclosure_of_anticipated_financial_effects_in_terms_of_margin_erosion_for_business_activities_at_material_transition_risk',
    datapoint_id: 'E1-9_38',
    esg_category: 'environmental',
    name_en:
      'Disclosure of anticipated financial effects in terms of margin erosion for business activities at material transition risk',
    name_es:
      'Divulgación de los efectos financieros previstos en términos de erosión de márgenes para actividades comerciales con riesgo de transición importante',
    name_pt:
      'Divulgação dos efeitos financeiros previstos em termos de erosão de margens para atividades comerciais com risco de transição material'
  },
  {
    id: 'af073f84-eeb6-4a2c-a4ca-4ce0059856d3',
    name_dcycle:
      'disclosure_of_reconciliations_with_financial_statements_of_significant_amounts_of_assets_and_net_revenue_at_material_physical_risk',
    datapoint_id: 'E1-9_39',
    esg_category: 'environmental',
    name_en:
      'Disclosure of reconciliations with financial statements of significant amounts of assets and net revenue at material physical risk',
    name_es:
      'Información a revelar de conciliaciones con estados financieros de cantidades significativas de activos e ingresos netos con riesgo físico material',
    name_pt:
      'Divulgação de reconciliações com demonstrações financeiras de valores significativos de ativos e receitas líquidas com risco físico relevante'
  },
  {
    id: '906f4a62-8214-4631-abba-930bb341ca1b',
    name_dcycle:
      'disclosure_of_reconciliations_with_financial_statements_of_significant_amounts_of_assets,_liabilities_and_net_revenue_at_material_transition_risk',
    datapoint_id: 'E1-9_40',
    esg_category: 'environmental',
    name_en:
      'Disclosure of reconciliations with financial statements of significant amounts of assets, liabilities and net revenue at material transition risk',
    name_es:
      'Información a revelar de conciliaciones con estados financieros de cantidades significativas de activos, pasivos e ingresos netos con riesgo de transición importante',
    name_pt:
      'Divulgação de reconciliações com demonstrações financeiras de montantes significativos de ativos, passivos e receitas líquidas com risco de transição material'
  },
  {
    id: '275ba9df-61ef-45be-8879-5f50aa61b0d9',
    name_dcycle: 'expected_cost_savings_from_climate_change_mitigation_actions',
    datapoint_id: 'E1-9_41',
    esg_category: 'environmental',
    name_en: 'Expected cost savings from climate change mitigation actions',
    name_es: 'Ahorros de costos esperados de las acciones de mitigación del cambio climático',
    name_pt:
      'Esperadas poupanças de custos decorrentes de ações de mitigação das alterações climáticas'
  },
  {
    id: 'd8c9694c-f10b-45a9-82e8-b94a17d6b6b4',
    name_dcycle: 'expected_cost_savings_from_climate_change_adaptation_actions',
    datapoint_id: 'E1-9_42',
    esg_category: 'environmental',
    name_en: 'Expected cost savings from climate change adaptation actions',
    name_es: 'Ahorros de costos esperados de las acciones de adaptación al cambio climático',
    name_pt:
      'Esperadas poupanças de custos decorrentes de ações de adaptação às alterações climáticas'
  },
  {
    id: 'c612e1d0-5478-47ad-afe4-1a623f4f1264',
    name_dcycle:
      'potential_market_size_of_low-carbon_products_and_services_or_adaptation_solutions_to_which_undertaking_has_or_may_have_access',
    datapoint_id: 'E1-9_43',
    esg_category: 'environmental',
    name_en:
      'Potential market size of low-carbon products and services or adaptation solutions to which undertaking has or may have access',
    name_es:
      'Tamaño potencial del mercado de productos y servicios con bajas emisiones de carbono o soluciones de adaptación a los que la empresa tiene o puede tener acceso',
    name_pt:
      'Dimensão potencial do mercado de produtos e serviços hipocarbónicos ou soluções de adaptação a que a empresa tem ou pode ter acesso'
  },
  {
    id: 'e165efbf-81d0-4e7f-b73c-6d1e11cd1ac8',
    name_dcycle:
      'expected_changes_to_net_revenue_from_low-carbon_products_and_services_or_adaptation_solutions_to_which_undertaking_has_or_may_have_access',
    datapoint_id: 'E1-9_44',
    esg_category: 'environmental',
    name_en:
      'Expected changes to net revenue from low-carbon products and services or adaptation solutions to which undertaking has or may have access',
    name_es:
      'Cambios esperados en los ingresos netos provenientes de productos y servicios con bajas emisiones de carbono o soluciones de adaptación a los que la empresa tiene o puede tener acceso',
    name_pt:
      'Mudanças esperadas na receita líquida de produtos e serviços hipocarbónicos ou soluções de adaptação a que a empresa tem ou pode ter acesso'
  },
  {
    id: '3204c4f4-0470-4c4b-9058-606b0340acfb',
    name_dcycle:
      'information_about_the_process_to_identify_actual_and_potential_pollution-related_impacts,_risks_and_opportuntities',
    datapoint_id: 'E2.IRO-1_01',
    esg_category: 'environmental',
    name_en:
      'Information about the process to identify actual and potential pollution-related impacts, risks and opportuntities',
    name_es:
      'Información sobre el proceso para identificar impactos, riesgos y oportunidades reales y potenciales relacionados con la contaminación.',
    name_pt:
      'Informações sobre o processo para identificar impactos, riscos e oportunidades reais e potenciais relacionados à poluição'
  },
  {
    id: '40aa5aa4-764f-41bb-ba0d-9167e5e9c143',
    name_dcycle: 'disclosure_of_whether_and_how_consultations_have_been_conducted_(pollution)',
    datapoint_id: 'E2.IRO-1_02',
    esg_category: 'environmental',
    name_en: 'Disclosure of whether and how consultations have been conducted (pollution)',
    name_es: 'Divulgación de si se han realizado consultas y cómo (contaminación)',
    name_pt: 'Divulgação de se e como as consultas foram realizadas (poluição)'
  },
  {
    id: '0a5f42d2-e9d4-4cba-9c05-ea9eb10a1934',
    name_dcycle: 'disclosure_of_results_of_materiality_assessment_(pollution)',
    datapoint_id: 'E2.IRO-1_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of results of materiality assessment (pollution)',
    name_es: 'Divulgación de resultados de la evaluación de materialidad (contaminación)',
    name_pt: 'Divulgação dos resultados da avaliação de materialidade (poluição)'
  },
  {
    id: '6bf81063-fe89-4aae-9294-1ee36cd9af5a',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_addresses_mitigating_negative_impacts_related_to_pollution_of_air,_water_and_soil',
    datapoint_id: 'E2-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy addresses mitigating negative impacts related to pollution of air, water and soil',
    name_es:
      'Divulgación de si la política aborda la mitigación de los impactos negativos relacionados con la contaminación del aire, el agua y el suelo y cómo lo hace.',
    name_pt:
      'Divulgação sobre se e como a política aborda a mitigação dos impactos negativos relacionados com a poluição do ar, da água e do solo'
  },
  {
    id: '1e263a7d-f2be-40a2-98bc-512a252defee',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_addresses_substituting_and_minimising_use_of_substances_of_concern_and_phasing_out_substances_of_very_high_concern',
    datapoint_id: 'E2-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy addresses substituting and minimising use of substances of concern and phasing out substances of very high concern',
    name_es:
      'Divulgación de si la política aborda la sustitución y minimización del uso de sustancias preocupantes y la eliminación gradual de sustancias extremadamente preocupantes, y de qué manera.',
    name_pt:
      'Divulgação de se e como a política aborda a substituição e minimização do uso de substâncias que suscitam preocupação e a eliminação progressiva de substâncias que suscitam elevada preocupação'
  },
  {
    id: '3d5505d7-3790-44e5-aa3d-d2c6a948bd97',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_addresses_avoiding_incidents_and_emergency_situations,_and_if_and_when_they_occur,_controlling_and_limiting_their_impact_on_people_and_environment',
    datapoint_id: 'E2-1_03',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy addresses avoiding incidents and emergency situations, and if and when they occur, controlling and limiting their impact on people and environment',
    name_es:
      'Divulgación de si y cómo la política aborda la prevención de incidentes y situaciones de emergencia, y si ocurren y cuándo ocurren, controlando y limitando su impacto en las personas y el medio ambiente.',
    name_pt:
      'Divulgação de se e como a política aborda a prevenção de incidentes e situações de emergência, e se e quando ocorrem, controlando e limitando o seu impacto nas pessoas e no ambiente'
  },
  {
    id: '138e3ca9-dc73-4736-9c97-b6d214919e39',
    name_dcycle:
      'disclosure_of_contextual_information_on_relations_between_policies_implemented_and_how_policies_contribute_to_eu_action_plan_towards_zero_pollution_for_air,_water_and_soil',
    datapoint_id: 'E2-1_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of contextual information on relations between policies implemented and how policies contribute to EU Action Plan Towards Zero Pollution for Air, Water and Soil',
    name_es:
      'Divulgación de información contextual sobre las relaciones entre las políticas implementadas y cómo las políticas contribuyen al Plan de Acción de la UE hacia la contaminación cero del aire, el agua y el suelo.',
    name_pt:
      'Divulgação de informações contextuais sobre as relações entre as políticas implementadas e como as políticas contribuem para o Plano de Acção da UE para a Poluição Zero do Ar, da Água e do Solo'
  },
  {
    id: '8e4fa552-463a-49d4-9291-d61cb8d5ef4f',
    name_dcycle: 'layer_in_mitigation_hierarchy_to_which_action_can_be_allocated_to_(pollution)',
    datapoint_id: 'E2-2_01',
    esg_category: 'environmental',
    name_en: 'Layer in mitigation hierarchy to which action can be allocated to (pollution)',
    name_es:
      'Capa en la jerarquía de mitigación a la que se pueden asignar acciones (contaminación)',
    name_pt: 'Camada na hierarquia de mitigação à qual a ação pode ser alocada (poluição)'
  },
  {
    id: '8b2742e7-ea78-4679-a0e1-041cf0ecc220',
    name_dcycle:
      'action_related_to_pollution_extends_to_upstream/downstream_value_chain_engagements',
    datapoint_id: 'E2-2_02',
    esg_category: 'environmental',
    name_en: 'Action related to pollution extends to upstream/downstream value chain engagements',
    name_es:
      'Las acciones relacionadas con la contaminación se extienden a los compromisos de las cadenas de valor ascendentes y descendentes.',
    name_pt:
      'As ações relacionadas com a poluição estendem-se aos compromissos a montante/jusante da cadeia de valor'
  },
  {
    id: 'e311b7d2-ff65-4c71-a6bd-3b8b64082221',
    name_dcycle: 'layer_in_mitigation_hierarchy_to_which_resources_can_be_allocated_to_(pollution)',
    datapoint_id: 'E2-2_03',
    esg_category: 'environmental',
    name_en: 'Layer in mitigation hierarchy to which resources can be allocated to (pollution)',
    name_es:
      'Capa en la jerarquía de mitigación a la que se pueden asignar recursos (contaminación)',
    name_pt: 'Camada na hierarquia de mitigação à qual os recursos podem ser alocados (poluição)'
  },
  {
    id: '20c8e6f5-6090-40af-91ed-72ab484eed28',
    name_dcycle:
      'information_about_action_plans_that_have_been_implemented_at_site-level_(pollution)',
    datapoint_id: 'E2-2_04',
    esg_category: 'environmental',
    name_en: 'Information about action plans that have been implemented at site-level (pollution)',
    name_es:
      'Información sobre planes de acción que se han implementado a nivel de sitio (contaminación)',
    name_pt: 'Informações sobre planos de ação que foram implementados a nível local (poluição)'
  },
  {
    id: '470466f9-460b-4e5c-a0f4-000804aa1c8a',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_prevention_and_control_of_air_pollutants_and_respective_specific_loads',
    datapoint_id: 'E2-3_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to prevention and control of air pollutants and respective specific loads',
    name_es:
      'Divulgación de si el objetivo se relaciona con la prevención y el control de los contaminantes del aire y sus respectivas cargas específicas, y de qué manera.',
    name_pt:
      'Divulgação sobre se e como a meta está relacionada com a prevenção e controlo de poluentes atmosféricos e respetivas cargas específicas'
  },
  {
    id: 'd32c2da7-6528-43fa-bf08-771b89e3cd53',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_prevention_and_control_of_emissions_to_water_and_respective_specific_loads',
    datapoint_id: 'E2-3_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to prevention and control of emissions to water and respective specific loads',
    name_es:
      'Divulgación de si y cómo el objetivo se relaciona con la prevención y el control de las emisiones al agua y las respectivas cargas específicas.',
    name_pt:
      'Divulgação sobre se e como a meta se relaciona com a prevenção e controlo de emissões para a água e respetivas cargas específicas'
  },
  {
    id: 'a9d2754a-5b60-47c1-8268-75f26c61a1b3',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_prevention_and_control_of_pollution_to_soil_and_respective_specific_loads',
    datapoint_id: 'E2-3_03',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to prevention and control of pollution to soil and respective specific loads',
    name_es:
      'Divulgación de si y cómo el objetivo se relaciona con la prevención y el control de la contaminación del suelo y las respectivas cargas específicas.',
    name_pt:
      'Divulgação sobre se e como a meta está relacionada com a prevenção e controlo da poluição do solo e respetivas cargas específicas'
  },
  {
    id: '6a1adca6-dfc1-4cfd-8e04-33f1597ce2d8',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_prevention_and_control_of_substances_of_concern_and_substances_of_very_high_concern',
    datapoint_id: 'E2-3_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to prevention and control of substances of concern and substances of very high concern',
    name_es:
      'Divulgación de si la meta se relaciona con la prevención y el control de sustancias preocupantes y sustancias extremadamente preocupantes, y de qué manera.',
    name_pt:
      'Divulgação sobre se e como a meta está relacionada com a prevenção e o controle de substâncias que suscitam preocupação e substâncias que suscitam elevada preocupação'
  },
  {
    id: 'c3c022fa-7a64-4bed-9bd3-c744b7b3abff',
    name_dcycle:
      'ecological_thresholds_and_entity-specific_allocations_were_taken_into_consideration_when_setting_pollution-related_target',
    datapoint_id: 'E2-3_05',
    esg_category: 'environmental',
    name_en:
      'Ecological thresholds and entity-specific allocations were taken into consideration when setting pollution-related target',
    name_es:
      'Se tuvieron en cuenta los umbrales ecológicos y las asignaciones específicas de cada entidad al establecer objetivos relacionados con la contaminación.',
    name_pt:
      'Os limiares ecológicos e as dotações específicas das entidades foram tidos em consideração ao definir metas relacionadas com a poluição'
  },
  {
    id: '92f62637-2b39-48c4-9ae4-e3aa47d6945f',
    name_dcycle:
      'disclosure_of_ecological_thresholds_identified_and_methodology_used_to_identify_ecological_thresholds_(pollution)',
    datapoint_id: 'E2-3_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of ecological thresholds identified and methodology used to identify ecological thresholds (pollution)',
    name_es:
      'Divulgación de umbrales ecológicos identificados y metodología utilizada para identificar umbrales ecológicos (contaminación)',
    name_pt:
      'Divulgação dos limiares ecológicos identificados e metodologia utilizada para identificar os limiares ecológicos (poluição)'
  },
  {
    id: '02d88961-fcc8-4475-8535-2a5419d1702c',
    name_dcycle:
      'disclosure_of_how_ecological_entity-specific_thresholds_were_determined_(pollution)',
    datapoint_id: 'E2-3_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of how ecological entity-specific thresholds were determined (pollution)',
    name_es:
      'Divulgación de cómo se determinaron los umbrales ecológicos específicos de la entidad (contaminación)',
    name_pt:
      'Divulgação de como foram determinados os limiares específicos da entidade ecológica (poluição)'
  },
  {
    id: '1b4eb859-4bba-45f3-b08b-6bf2875aace2',
    name_dcycle:
      'disclosure_of_how_responsibility_for_respecting_identified_ecological_thresholds_is_allocated_(pollution)',
    datapoint_id: 'E2-3_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how responsibility for respecting identified ecological thresholds is allocated (pollution)',
    name_es:
      'Divulgación de cómo se asigna la responsabilidad de respetar los umbrales ecológicos identificados (contaminación)',
    name_pt:
      'Divulgação de como é atribuída a responsabilidade pelo respeito dos limiares ecológicos identificados (poluição)'
  },
  {
    id: '3a597804-5584-4120-a0db-f08db0c5ef45',
    name_dcycle: 'pollution-related_target_is_mandatory_(required_by_legislation)/voluntary',
    datapoint_id: 'E2-3_09',
    esg_category: 'environmental',
    name_en: 'Pollution-related target is mandatory (required by legislation)/voluntary',
    name_es:
      'El objetivo relacionado con la contaminación es obligatorio (requerido por la legislación)/voluntario',
    name_pt: 'A meta relacionada à poluição é obrigatória (exigida pela legislação)/voluntária'
  },
  {
    id: '3854b8ec-f117-443e-ae3c-efa6a34ec1cf',
    name_dcycle:
      'pollution-related_target_addresses_shortcomings_related_to_substantial_contribution_criteria_for_pollution_prevention_and_control',
    datapoint_id: 'E2-3_10',
    esg_category: 'environmental',
    name_en:
      'Pollution-related target addresses shortcomings related to Substantial Contribution criteria for Pollution Prevention and Control',
    name_es:
      'La meta relacionada con la contaminación aborda las deficiencias relacionadas con los criterios de contribución sustancial para la prevención y el control de la contaminación.',
    name_pt:
      'A meta relacionada com a poluição aborda deficiências relacionadas com os critérios de contribuição substancial para a prevenção e controlo da poluição'
  },
  {
    id: 'f90b1917-c57e-46e6-8a2f-85ae5a50f6f1',
    name_dcycle: 'information_about_targets_that_have_been_implemented_at_site-level_(pollution)',
    datapoint_id: 'E2-3_11',
    esg_category: 'environmental',
    name_en: 'Information about targets that have been implemented at site-level (pollution)',
    name_es: 'Información sobre objetivos que se han implementado a nivel de sitio (contaminación)',
    name_pt: 'Informações sobre metas que foram implementadas no nível do local (poluição)'
  },
  {
    id: '803e9048-2a3a-4b5f-bd72-497457ed0fe8',
    name_dcycle:
      'pollution_of_air,_water_and_soil_[multiple_dimensions:_at_site_level_or_by_type_of_source,_by_sector_or_by_geographical_area',
    datapoint_id: 'E2-4_01',
    esg_category: 'environmental',
    name_en:
      'Pollution of air, water and soil [multiple dimensions: at site level or by type of source, by sector or by geographical area',
    name_es:
      'Contaminación del aire, el agua y el suelo [múltiples dimensiones: a nivel de sitio o por tipo de fuente, por sector o por área geográfica',
    name_pt:
      'Poluição do ar, da água e do solo [múltiplas dimensões: ao nível do local ou por tipo de fonte, por setor ou por área geográfica'
  },
  {
    id: '7ad00b20-0797-4aee-8815-600d2da36cdf',
    name_dcycle: 'emissions_to_air_by_pollutant',
    datapoint_id: 'E2-4_02',
    esg_category: 'environmental',
    name_en: 'Emissions to air by pollutant',
    name_es: 'Emisiones al aire por contaminante',
    name_pt: 'Emissões para a atmosfera por poluente'
  },
  {
    id: '4f42f3fd-0a88-4c19-81b7-e3e4c9713113',
    name_dcycle:
      'emissions_to_water_by_pollutant_[+_by_sectors/geographical_area/type_of_source/site_location]',
    datapoint_id: 'E2-4_03',
    esg_category: 'environmental',
    name_en:
      'Emissions to water by pollutant [+ by sectors/Geographical Area/Type of source/Site location]',
    name_es:
      'Emisiones al agua por contaminante [+ por sectores/Área Geográfica/Tipo de fuente/Ubicación del sitio]',
    name_pt:
      'Emissões para a água por poluente [+ por setores/Área geográfica/Tipo de fonte/Localização do local]'
  },
  {
    id: 'b59b4637-8bc4-4c08-9447-0fb60a4433c3',
    name_dcycle:
      'emissions_to_soil_by_pollutant_[+_by_sectors/geographical_area/type_of_source/site_location]',
    datapoint_id: 'E2-4_04',
    esg_category: 'environmental',
    name_en:
      'Emissions to soil by pollutant [+ by sectors/Geographical Area/Type of source/Site location]',
    name_es:
      'Emisiones al suelo por contaminante [+ por sectores/Área Geográfica/Tipo de fuente/Ubicación del sitio]',
    name_pt:
      'Emissões para o solo por poluente [+ por setores/Área geográfica/Tipo de fonte/Localização do local]'
  },
  {
    id: 'b11fe638-5335-4501-a125-78c26feb9430',
    name_dcycle: 'microplastics_generated_and_used',
    datapoint_id: 'E2-4_05',
    esg_category: 'environmental',
    name_en: 'Microplastics generated and used',
    name_es: 'Microplásticos generados y utilizados',
    name_pt: 'Microplásticos gerados e usados'
  },
  {
    id: '69341bb0-892f-444f-9698-27d28aec9d77',
    name_dcycle: 'microplastics_generated',
    datapoint_id: 'E2-4_06',
    esg_category: 'environmental',
    name_en: 'Microplastics generated',
    name_es: 'Microplásticos generados',
    name_pt: 'Microplásticos gerados'
  },
  {
    id: '5b2290d4-164b-47b4-831a-bed22e91b6bf',
    name_dcycle: 'microplastics_used',
    datapoint_id: 'E2-4_07',
    esg_category: 'environmental',
    name_en: 'Microplastics used',
    name_es: 'Microplásticos utilizados',
    name_pt: 'Microplásticos usados'
  },
  {
    id: 'd859db7a-16e4-40eb-b03f-c9ac01591f62',
    name_dcycle: 'description_of_changes_over_time_(pollution_of_air,_water_and_soil)',
    datapoint_id: 'E2-4_08',
    esg_category: 'environmental',
    name_en: 'Description of changes over time (pollution of air, water and soil)',
    name_es: 'Descripción de los cambios en el tiempo (contaminación del aire, agua y suelo)',
    name_pt: 'Descrição das mudanças ao longo do tempo (poluição do ar, da água e do solo)'
  },
  {
    id: 'fda5212f-4e9f-4d80-a473-946e4b0e7444',
    name_dcycle: 'description_of_measurement_methodologies_(pollution_of_air,_water_and_soil)',
    datapoint_id: 'E2-4_09',
    esg_category: 'environmental',
    name_en: 'Description of measurement methodologies (pollution of air, water and soil)',
    name_es: 'Descripción de metodologías de medición (contaminación del aire, agua y suelo)',
    name_pt: 'Descrição das metodologias de medição (poluição do ar, da água e do solo)'
  },
  {
    id: 'a402103f-8eb9-47ee-838a-f556f5f26a5e',
    name_dcycle:
      'description_of_process(es)_to_collect_data_for_pollution-related_accounting_and_reporting',
    datapoint_id: 'E2-4_10',
    esg_category: 'environmental',
    name_en:
      'Description of process(es) to collect data for pollution-related accounting and reporting',
    name_es:
      'Descripción del proceso(s) para recopilar datos para la contabilidad y presentación de informes relacionados con la contaminación',
    name_pt:
      'Descrição do(s) processo(s) de coleta de dados para contabilidade e relatórios relacionados à poluição'
  },
  {
    id: '4c7f4c23-14a3-4c0e-be36-93cca570f4cd',
    name_dcycle:
      'percentage_of_total_emissions_of_pollutants_to_water_occurring_in_areas_at_water_risk',
    datapoint_id: 'E2-4_11',
    esg_category: 'environmental',
    name_en:
      'Percentage of total emissions of pollutants to water occurring in areas at water risk',
    name_es:
      'Porcentaje del total de emisiones de contaminantes al agua que se producen en zonas con riesgo hídrico',
    name_pt:
      'Percentagem do total de emissões de poluentes para a água que ocorrem em áreas com risco hídrico'
  },
  {
    id: '7b6283d9-4784-4ed1-8246-87a436916e7e',
    name_dcycle:
      'percentage_of_total_emissions_of_pollutants_to_water_occurring_in_areas_of_high-water_stress',
    datapoint_id: 'E2-4_12',
    esg_category: 'environmental',
    name_en:
      'Percentage of total emissions of pollutants to water occurring in areas of high-water stress',
    name_es:
      'Porcentaje de emisiones totales de contaminantes al agua que se producen en zonas de alto estrés hídrico',
    name_pt:
      'Percentagem do total de emissões de poluentes para a água que ocorrem em áreas de elevado stress hídrico'
  },
  {
    id: '13534a57-ac0b-44f2-bb0e-9677155e9c79',
    name_dcycle:
      'percentage_of_total_emissions_of_pollutants_to_soil_occurring_in_areas_at_water_risk',
    datapoint_id: 'E2-4_13',
    esg_category: 'environmental',
    name_en: 'Percentage of total emissions of pollutants to soil occurring in areas at water risk',
    name_es:
      'Porcentaje del total de emisiones de contaminantes al suelo que se producen en zonas con riesgo hídrico',
    name_pt:
      'Percentual das emissões totais de poluentes para o solo que ocorrem em áreas com risco hídrico'
  },
  {
    id: 'ca601569-5048-4752-8afb-d39e22c49902',
    name_dcycle:
      'percentage_of_total_emissions_of_pollutants_to_soil_occurring_in_areas_of_high-water_stress',
    datapoint_id: 'E2-4_14',
    esg_category: 'environmental',
    name_en:
      'Percentage of total emissions of pollutants to soil occurring in areas of high-water stress',
    name_es:
      'Porcentaje de emisiones totales de contaminantes al suelo que ocurren en áreas de alto estrés hídrico',
    name_pt:
      'Percentagem do total de emissões de poluentes para o solo que ocorrem em áreas de elevado stress hídrico'
  },
  {
    id: 'a2440711-668a-48d2-9d2e-2ba7f812c8a8',
    name_dcycle: 'disclosure_of_reasons_for_choosing_inferior_methodology_to_quantify_emissions',
    datapoint_id: 'E2-4_15',
    esg_category: 'environmental',
    name_en: 'Disclosure of reasons for choosing inferior methodology to quantify emissions',
    name_es:
      'Divulgación de razones para elegir una metodología inferior para cuantificar las emisiones.',
    name_pt:
      'Divulgação dos motivos da escolha de metodologia inferior para quantificação de emissões'
  },
  {
    id: '92ef9373-17d0-4b22-9128-d27cb08a0634',
    name_dcycle:
      'disclosure_of_list_of_installations_operated_that_fall_under_ied_and_eu_bat_conclusions',
    datapoint_id: 'E2-4_16',
    esg_category: 'environmental',
    name_en:
      'Disclosure of list of installations operated that fall under IED and EU BAT Conclusions',
    name_es:
      'Divulgación de la lista de instalaciones operadas que se incluyen en el ámbito de los DEI y las conclusiones sobre las MTD de la UE',
    name_pt:
      'Divulgação da lista de instalações operadas que se enquadram nas Conclusões do IED e das MTD da UE'
  },
  {
    id: 'f682b92f-4dc8-47cc-b8fc-28f06d2b0b0c',
    name_dcycle:
      'disclosure_of_list_of_any_non-compliance_incidents_or_enforcement_actions_necessary_to_ensure_compliance_in_case_of_breaches_of_permit_conditions',
    datapoint_id: 'E2-4_17',
    esg_category: 'environmental',
    name_en:
      'Disclosure of list of any non-compliance incidents or enforcement actions necessary to ensure compliance in case of breaches of permit conditions',
    name_es:
      'Divulgación de una lista de incidentes de incumplimiento o acciones de cumplimiento necesarias para garantizar el cumplimiento en caso de incumplimiento de las condiciones del permiso.',
    name_pt:
      'Divulgação da lista de quaisquer incidentes de não conformidade ou ações de fiscalização necessárias para garantir a conformidade em caso de violação das condições de licença'
  },
  {
    id: '6cbff97b-2586-4d97-9ba7-82d04d20b9a5',
    name_dcycle:
      'disclosure_of_actual_performance_and_comparison_of_environmental_performance_against_emission_levels_associated_with_best_available_techniques_(bat-ael)_as_described_in_eu-bat_conclusions',
    datapoint_id: 'E2-4_18',
    esg_category: 'environmental',
    name_en:
      'Disclosure of actual performance and comparison of environmental performance against emission levels associated with best available techniques (BAT-AEL) as described in EU-BAT conclusions',
    name_es:
      'Divulgación del desempeño real y comparación del desempeño ambiental con los niveles de emisión asociados con las mejores técnicas disponibles (MTD-AEL) como se describe en las conclusiones de las MTD de la UE.',
    name_pt:
      'Divulgação do desempenho real e comparação do desempenho ambiental com os níveis de emissões associados às melhores técnicas disponíveis (MTD-AEL), conforme descrito nas conclusões da UE-MTD'
  },
  {
    id: 'b42aae38-8b81-4f7d-889b-754a76aa5120',
    name_dcycle:
      'disclosure_of_actual_performance_against_environmental_performance_levels_associated_with_best_available_techniques_(bat-aepls)_applicable_to_sector_and_installation',
    datapoint_id: 'E2-4_19',
    esg_category: 'environmental',
    name_en:
      'Disclosure of actual performance against environmental performance levels associated with best available techniques (BAT-AEPLs) applicable to sector and installation',
    name_es:
      'Divulgación del desempeño real frente a los niveles de desempeño ambiental asociados con las mejores técnicas disponibles (MTD-AEPL) aplicables al sector y la instalación.',
    name_pt:
      'Divulgação do desempenho real em relação aos níveis de desempenho ambiental associados às melhores técnicas disponíveis (MTD-AEPLs) aplicáveis ​​ao setor e à instalação'
  },
  {
    id: '2da00230-7f30-4935-8eae-3496d913152c',
    name_dcycle:
      'disclosure_of_list_of_any_compliance_schedules_or_derogations_granted_by_competent_authorities_according_to_article_15(4)_ied_that_are_associated_with_implementation_of_bat-aels',
    datapoint_id: 'E2-4_20',
    esg_category: 'environmental',
    name_en:
      'Disclosure of list of any compliance schedules or derogations granted by competent authorities according to Article 15(4) IED that are associated with implementation of BAT-AELs',
    name_es:
      'Divulgación de la lista de cronogramas de cumplimiento o derogaciones concedidos por las autoridades competentes de conformidad con el artículo 15, apartado 4, de la DEI que están asociados con la implementación de los NEA-MTD',
    name_pt:
      'Divulgação da lista de quaisquer calendários de conformidade ou derrogações concedidas pelas autoridades competentes nos termos do artigo 15.º, n.º 4, da DEI que estão associados à implementação de VEA-MTD'
  },
  {
    id: 'af1f8d92-051f-4bd7-bb65-9cc667fa8329',
    name_dcycle:
      'total_amount_of_substances_of_concern_that_are_generated_or_used_during_production_or_that_are_procured,_breakdown_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_01',
    esg_category: 'environmental',
    name_en:
      'Total amount of substances of concern that are generated or used during production or that are procured, breakdown by main hazard classes of substances of concern',
    name_es:
      'Cantidad total de sustancias preocupantes que se generan o utilizan durante la producción o que se adquieren, desglosadas por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade total de substâncias preocupantes geradas ou utilizadas durante a produção ou adquiridas, discriminadas pelas principais classes de perigo de substâncias preocupantes'
  },
  {
    id: '48e5c5f3-365d-4cea-be92-c3f5159b3050',
    name_dcycle:
      'total_amount_of_substances_of_concern_that_are_generated_or_used_during_production_or_that_are_procured',
    datapoint_id: 'E2-5_02',
    esg_category: 'environmental',
    name_en:
      'Total amount of substances of concern that are generated or used during production or that are procured',
    name_es:
      'Cantidad total de sustancias preocupantes que se generan o utilizan durante la producción o que se adquieren',
    name_pt:
      'Quantidade total de substâncias preocupantes geradas ou utilizadas durante a produção ou adquiridas'
  },
  {
    id: 'ded1a826-3ca3-487c-939e-c205f01a0575',
    name_dcycle:
      'total_amount_of_substances_of_concern_that_leave_facilities_as_emissions,_as_products,_or_as_part_of_products_or_services',
    datapoint_id: 'E2-5_03',
    esg_category: 'environmental',
    name_en:
      'Total amount of substances of concern that leave facilities as emissions, as products, or as part of products or services',
    name_es:
      'Cantidad total de sustancias preocupantes que salen de las instalaciones como emisiones, como productos o como parte de productos o servicios.',
    name_pt:
      'Quantidade total de substâncias preocupantes que saem das instalações como emissões, como produtos ou como parte de produtos ou serviços'
  },
  {
    id: '9e5d5252-b8b0-4cd5-9175-a4b38feb6c65',
    name_dcycle:
      'amount_of_substances_of_concern_that_leave_facilities_as_emissions_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_04',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of concern that leave facilities as emissions by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias preocupantes que salen de las instalaciones como emisiones por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias preocupantes que saem das instalações como emissões por principais classes de perigo de substâncias preocupantes'
  },
  {
    id: 'c18477c8-6642-4e93-9379-690774d0840c',
    name_dcycle:
      'amount_of_substances_of_concern_that_leave_facilities_as_products_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_05',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of concern that leave facilities as products by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias preocupantes que salen de las instalaciones como productos por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias preocupantes que saem das instalações como produtos, por principais classes de perigo de substâncias preocupantes'
  },
  {
    id: '353a5803-2be2-4745-b040-8db8b3b0e6d2',
    name_dcycle:
      'amount_of_substances_of_concern_that_leave_facilities_as_part_of_products_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_06',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of concern that leave facilities as part of products by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias preocupantes que salen de las instalaciones como parte de productos por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias preocupantes que saem das instalações como parte de produtos, por principais classes de perigo de substâncias preocupantes'
  },
  {
    id: '4494a0f8-b1f5-4bbd-87e9-62637c79c3be',
    name_dcycle:
      'amount_of_substances_of_concern_that_leave_facilities_as_services_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_07',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of concern that leave facilities as services by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias preocupantes que salen de las instalaciones como servicios por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias preocupantes que saem das instalações como serviços, por principais classes de perigo de substâncias preocupantes'
  },
  {
    id: 'ff3676c0-1329-4187-be37-6f865e352c3f',
    name_dcycle:
      'total_amount_of_substances_of_very_high_concern_that_are_generated_or_used_during_production_or_that_are_procured_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_08',
    esg_category: 'environmental',
    name_en:
      'Total amount of substances of very high concern that are generated or used during production or that are procured by main hazard classes of substances of concern',
    name_es:
      'Cantidad total de sustancias extremadamente preocupantes que se generan o utilizan durante la producción o que se obtienen según las principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade total de substâncias que suscitam elevada preocupação que são geradas ou utilizadas durante a produção ou que são adquiridas pelas principais classes de perigo de substâncias que suscitam preocupação'
  },
  {
    id: '08f71968-5cdc-4c40-a9ed-6a34cee6b3bb',
    name_dcycle:
      'total_amount_of_substances_of_very_high_concern_that_leave_facilities_as_emissions,_as_products,_or_as_part_of_products_or_services_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_09',
    esg_category: 'environmental',
    name_en:
      'Total amount of substances of very high concern that leave facilities as emissions, as products, or as part of products or services by main hazard classes of substances of concern',
    name_es:
      'Cantidad total de sustancias extremadamente preocupantes que salen de las instalaciones como emisiones, como productos o como parte de productos o servicios por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade total de substâncias que suscitam elevada preocupação que saem das instalações como emissões, como produtos ou como parte de produtos ou serviços, por principais classes de perigo de substâncias que suscitam preocupação'
  },
  {
    id: '79c1dd59-dfeb-4340-8c7f-24f8ec37b33e',
    name_dcycle:
      'amount_of_substances_of_very_high_concern_that_leave_facilities_as_emissions_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_10',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of very high concern that leave facilities as emissions by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias altamente preocupantes que salen de las instalaciones como emisiones por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias que suscitam elevada preocupação que saem das instalações como emissões pelas principais classes de perigo de substâncias que suscitam preocupação'
  },
  {
    id: '76688c62-f5f4-47e0-821a-58011c55c9ea',
    name_dcycle:
      'amount_of_substances_of_very_high_concern_that_leave_facilities_as_products_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_11',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of very high concern that leave facilities as products by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias extremadamente preocupantes que salen de las instalaciones como productos por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias que suscitam elevada preocupação que saem das instalações como produtos, por principais classes de perigo de substâncias que suscitam preocupação'
  },
  {
    id: '65d764bc-3c0b-49db-9ff7-13a62ef38238',
    name_dcycle:
      'amount_of_substances_of_very_high_concern_that_leave_facilities_as_part_of_products_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_12',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of very high concern that leave facilities as part of products by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias extremadamente preocupantes que salen de las instalaciones como parte de productos por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias que suscitam elevada preocupação que saem das instalações como parte dos produtos, discriminadas pelas principais classes de perigo das substâncias que suscitam preocupação'
  },
  {
    id: 'dd593508-5ae0-4b04-9c15-8b4062faa0e1',
    name_dcycle:
      'amount_of_substances_of_very_high_concern_that_leave_facilities_as_services_by_main_hazard_classes_of_substances_of_concern',
    datapoint_id: 'E2-5_13',
    esg_category: 'environmental',
    name_en:
      'Amount of substances of very high concern that leave facilities as services by main hazard classes of substances of concern',
    name_es:
      'Cantidad de sustancias extremadamente preocupantes que salen de las instalaciones como servicios por principales clases de peligro de sustancias preocupantes',
    name_pt:
      'Quantidade de substâncias que suscitam elevada preocupação que saem das instalações como serviços, por principais classes de perigo de substâncias que suscitam preocupação'
  },
  {
    id: '7c23f01a-0f50-415a-86fd-8223981649be',
    name_dcycle:
      'disclosure_of_quantitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_pollution-related_impacts',
    datapoint_id: 'E2-6_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of quantitative information about anticipated financial effects of material risks and opportunities arising from pollution-related impacts',
    name_es:
      'Divulgación de información cuantitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos relacionados con la contaminación.',
    name_pt:
      'Divulgação de informações quantitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos relacionados à poluição'
  },
  {
    id: '4aecb521-f625-4879-b610-eae8e15bca4f',
    name_dcycle:
      'percentage_of_net_revenue_made_with_products_and_services_that_are_or_that_contain_substances_of_concern',
    datapoint_id: 'E2-6_02',
    esg_category: 'environmental',
    name_en:
      'Percentage of net revenue made with products and services that are or that contain substances of concern',
    name_es:
      'Porcentaje de ingresos netos obtenidos con productos y servicios que son o contienen sustancias preocupantes',
    name_pt:
      'Porcentagem da receita líquida obtida com produtos e serviços que são ou contêm substâncias preocupantes'
  },
  {
    id: '385d245a-77fb-43b6-a7d7-f03cd898e3f5',
    name_dcycle:
      'percentage_of_net_revenue_made_with_products_and_services_that_are_or_that_contain_substances_of_very_high_concern',
    datapoint_id: 'E2-6_03',
    esg_category: 'environmental',
    name_en:
      'Percentage of net revenue made with products and services that are or that contain substances of very high concern',
    name_es:
      'Porcentaje de ingresos netos obtenidos con productos y servicios que son o contienen sustancias extremadamente preocupantes',
    name_pt:
      'Percentagem da receita líquida obtida com produtos e serviços que são ou contêm substâncias que suscitam elevada preocupação'
  },
  {
    id: '3bb84ae9-ed86-400b-955b-8b7a10cf12b4',
    name_dcycle:
      'operating_expenditures_(opex)_in_conjunction_with_major_incidents_and_deposits_(pollution)',
    datapoint_id: 'E2-6_04',
    esg_category: 'environmental',
    name_en:
      'Operating expenditures (OpEx) in conjunction with major incidents and deposits (pollution)',
    name_es:
      'Gastos operativos (OpEx) en conjunto con incidentes importantes y depósitos (contaminación)',
    name_pt:
      'Despesas operacionais (OpEx) em conjunto com grandes incidentes e depósitos (poluição)'
  },
  {
    id: 'ce2ccf5a-9e9a-471b-9ad6-67e43f53388b',
    name_dcycle:
      'capital_expenditures_(capex)_in_conjunction_with_major_incidents_and_deposits_(pollution)',
    datapoint_id: 'E2-6_05',
    esg_category: 'environmental',
    name_en:
      'Capital expenditures (CapEx) in conjunction with major incidents and deposits (pollution)',
    name_es:
      'Gastos de capital (CapEx) en conjunto con incidentes importantes y depósitos (contaminación)',
    name_pt: 'Despesas de capital (CapEx) em conjunto com grandes incidentes e depósitos (poluição)'
  },
  {
    id: '9a82eee5-ac43-45b4-9c86-4b5ae1448810',
    name_dcycle: 'provisions_for_environmental_protection_and_remediation_costs_(pollution)',
    datapoint_id: 'E2-6_06',
    esg_category: 'environmental',
    name_en: 'Provisions for environmental protection and remediation costs (pollution)',
    name_es: 'Provisiones para protección ambiental y costos de remediación (contaminación)',
    name_pt: 'Provisões para proteção ambiental e custos de remediação (poluição)'
  },
  {
    id: 'd7d381d1-3a86-4e37-877a-08274629ef4d',
    name_dcycle:
      'disclosure_of_qualitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_pollution-related_impacts',
    datapoint_id: 'E2-6_07',
    esg_category: 'environmental',
    name_en:
      'Disclosure of qualitative information about anticipated financial effects of material risks and opportunities arising from pollution-related impacts',
    name_es:
      'Divulgación de información cualitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos relacionados con la contaminación.',
    name_pt:
      'Divulgação de informações qualitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos relacionados à poluição'
  },
  {
    id: '7f00a63a-9cc6-4a37-a5ce-d37b7835ce63',
    name_dcycle:
      'description_of_effects_considered,_related_impacts_and_time_horizons_in_which_they_are_likely_to_materialise_(pollution)',
    datapoint_id: 'E2-6_08',
    esg_category: 'environmental',
    name_en:
      'Description of effects considered, related impacts and time horizons in which they are likely to materialise (pollution)',
    name_es:
      'Descripción de los efectos considerados, impactos relacionados y horizontes temporales en los que es probable que se materialicen (contaminación)',
    name_pt:
      'Descrição dos efeitos considerados, impactos relacionados e horizontes temporais em que é provável que se materializem (poluição)'
  },
  {
    id: '935ac00b-48f8-4b27-bf39-7a9966cbc081',
    name_dcycle:
      'disclosure_of_critical_assumptions_used_to_quantify_anticipated_financial_effects,_sources_and_level_of_uncertainty_of_assumptions_(pollution)',
    datapoint_id: 'E2-6_09',
    esg_category: 'environmental',
    name_en:
      'Disclosure of critical assumptions used to quantify anticipated financial effects, sources and level of uncertainty of assumptions (pollution)',
    name_es:
      'Divulgación de supuestos críticos utilizados para cuantificar los efectos financieros previstos, las fuentes y el nivel de incertidumbre de los supuestos (contaminación)',
    name_pt:
      'Divulgação de premissas críticas usadas para quantificar os efeitos financeiros previstos, fontes e nível de incerteza das premissas (poluição)'
  },
  {
    id: '3079b7fc-5420-4152-aeac-338d9dcf7517',
    name_dcycle:
      'description_of_material_incidents_and_deposits_whereby_pollution_had_negative_impacts_on_environment_and_(or)_is_expected_to_have_negative_effects_on_financial_cash_flows,_financial_position_and_financial_performance',
    datapoint_id: 'E2-6_10',
    esg_category: 'environmental',
    name_en:
      'Description of material incidents and deposits whereby pollution had negative impacts on environment and (or) is expected to have negative effects on financial cash flows, financial position and financial performance',
    name_es:
      'Descripción de incidentes y depósitos materiales mediante los cuales la contaminación tuvo impactos negativos en el medio ambiente y (o) se espera que tenga efectos negativos en los flujos de efectivo financieros, la situación financiera y el desempeño financiero.',
    name_pt:
      'Descrição de incidentes e depósitos materiais em que a poluição teve impactos negativos no meio ambiente e (ou) se espera que tenha efeitos negativos nos fluxos de caixa financeiros, na posição financeira e no desempenho financeiro'
  },
  {
    id: 'e0cbcdd7-100b-4fd2-b8ea-f495bde45e7a',
    name_dcycle:
      'disclosure_of_assessment_of_related_products_and_services_at_risk_and_explanation_how_time_horizon_is_defined,_financial_amounts_are_estimated,_and_which_critical_assumptions_are_made_(pollution)',
    datapoint_id: 'E2-6_11',
    esg_category: 'environmental',
    name_en:
      'Disclosure of assessment of related products and services at risk and explanation how time horizon is defined, financial amounts are estimated, and which critical assumptions are made (pollution)',
    name_es:
      'Divulgación de la evaluación de productos y servicios relacionados en riesgo y explicación de cómo se define el horizonte temporal, se estiman los montos financieros y qué supuestos críticos se hacen (contaminación).',
    name_pt:
      'Divulgação da avaliação de produtos e serviços relacionados em risco e explicação de como o horizonte temporal é definido, os valores financeiros são estimados e quais premissas críticas são feitas (poluição)'
  },
  {
    id: 'c61a1907-d0d3-4d68-80b4-88e370f814d3',
    name_dcycle:
      'disclosure_of_whether_and_how_assets_and_activities_have_been_screened_in_order_to_identify_actual_and_potential_water_and_marine_resources-related_impacts,_risks_and_opportunities_in_own_operations_and_upstream_and_downstream_value_chain_and_methodologies,_assumptions_and_tools_used_in_screening_[text_block]',
    datapoint_id: 'E3.IRO-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how assets and activities have been screened in order to identify actual and potential water and marine resources-related impacts, risks and opportunities in own operations and upstream and downstream value chain and methodologies, assumptions and tools used in screening [text block]',
    name_es:
      'Divulgación de si se han examinado los activos y las actividades, y cómo, para identificar los impactos, riesgos y oportunidades reales y potenciales relacionados con el agua y los recursos marinos en las propias operaciones y en la cadena de valor ascendente y descendente, y las metodologías, suposiciones y herramientas utilizadas en la evaluación [bloque de texto ]',
    name_pt:
      'Divulgação de se e como os ativos e atividades foram avaliados, a fim de identificar impactos, riscos e oportunidades reais e potenciais relacionados com a água e os recursos marinhos nas próprias operações e na cadeia de valor a montante e a jusante e metodologias, pressupostos e ferramentas utilizadas na triagem [bloco de texto ]'
  },
  {
    id: 'adae3645-2ef3-421f-9d8d-f23265f7217a',
    name_dcycle:
      'disclosure_of_how_consultations_have_been_conducted_(water_and_marine_resources)_[text_block]',
    datapoint_id: 'E3.IRO-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how consultations have been conducted (water and marine resources) [text block]',
    name_es:
      'Divulgación de cómo se han llevado a cabo las consultas (agua y recursos marinos) [bloque de texto]',
    name_pt:
      'Divulgação de como as consultas foram conduzidas (recursos hídricos e marinhos) [bloco de texto]'
  },
  {
    id: '467427f6-9d41-41d9-a9fd-a7c754d6c75d',
    name_dcycle: 'disclosure_of_whether_and_how_policy_adresses_water_management',
    datapoint_id: 'E3-1_01',
    esg_category: 'environmental',
    name_en: 'Disclosure of whether and how policy adresses water management',
    name_es: 'Divulgación de si la política aborda la gestión del agua y cómo',
    name_pt: 'Divulgação sobre se e como a política aborda a gestão da água'
  },
  {
    id: 'b2a49970-65d4-4cf4-ada5-b0738ff28aa8',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_adresses_the_use_and_sourcing_of_water_and_marine_resources_in_own_operations',
    datapoint_id: 'E3-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy adresses the use and sourcing of water and marine resources in own operations',
    name_es:
      'Divulgación de si la política aborda el uso y abastecimiento de agua y recursos marinos en sus propias operaciones y cómo lo hace.',
    name_pt:
      'Divulgação sobre se e como a política aborda o uso e a obtenção de recursos hídricos e marinhos nas próprias operações'
  },
  {
    id: '141a2bcb-71c4-4b23-8086-fa4cee0c07dc',
    name_dcycle: 'disclosure_of_whether_and_how_policy_adresses_water_treatment',
    datapoint_id: 'E3-1_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of whether and how policy adresses water treatment',
    name_es: 'Divulgación de si la política aborda el tratamiento del agua y cómo lo hace.',
    name_pt: 'Divulgação sobre se e como a política aborda o tratamento da água'
  },
  {
    id: 'da5b8dfb-c16d-47e8-9176-306dd6f41c60',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_adresses_prevention_and_abatment_of_water_pollution',
    datapoint_id: 'E3-1_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy adresses prevention and abatment of water pollution',
    name_es:
      'Divulgación de si la política aborda la prevención y reducción de la contaminación del agua y cómo',
    name_pt:
      'Divulgação sobre se e como a política aborda a prevenção e redução da poluição da água'
  },
  {
    id: '9c279eea-90bf-431a-ac7c-62252db89269',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_adresses_product_and_service_design_in_view_of_addressing_water-related_issues_and_preservation_of_marine_resources',
    datapoint_id: 'E3-1_05',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy adresses product and service design in view of addressing water-related issues and preservation of marine resources',
    name_es:
      'Divulgación de si la política aborda el diseño de productos y servicios, y de qué manera, con miras a abordar cuestiones relacionadas con el agua y la preservación de los recursos marinos.',
    name_pt:
      'Divulgação sobre se e como a política aborda o design de produtos e serviços com vista a abordar questões relacionadas com a água e a preservação dos recursos marinhos'
  },
  {
    id: '1137a2cf-e64d-46cb-be19-93c0a3335147',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_adresses_commitment_to_reduce_material_water_consumption_in_areas_at_water_risk',
    datapoint_id: 'E3-1_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy adresses commitment to reduce material water consumption in areas at water risk',
    name_es:
      'Divulgación de si la política aborda el compromiso de reducir el consumo material de agua en áreas con riesgo hídrico y cómo lo hace.',
    name_pt:
      'Divulgação sobre se e como a política aborda o compromisso de reduzir o consumo material de água em áreas com risco hídrico'
  },
  {
    id: '61c37ab3-09b8-400d-bdc8-ef0d9b56f6bd',
    name_dcycle:
      'disclosure_of_reasons_for_not_having_adopted_policies_in_areas_of_high-water_stress',
    datapoint_id: 'E3-1_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of reasons for not having adopted policies in areas of high-water stress',
    name_es:
      'Divulgación de razones por las que no se han adoptado políticas en zonas de alto estrés hídrico',
    name_pt:
      'Divulgação das razões para não ter adotado políticas em áreas de alto estresse hídrico'
  },
  {
    id: '3664abde-0192-427c-acf7-4a3b7df9dcb5',
    name_dcycle:
      'disclosure_of_timeframe_in_which_policies_in_areas_of_high-water_stress_will_be_adopted',
    datapoint_id: 'E3-1_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of timeframe in which policies in areas of high-water stress will be adopted',
    name_es:
      'Divulgación del calendario en el que se adoptarán políticas en áreas de alto estrés hídrico',
    name_pt:
      'Divulgação do cronograma em que as políticas em áreas de alto estresse hídrico serão adotadas'
  },
  {
    id: '0edc157a-24ea-4583-b6a4-be57da4428b4',
    name_dcycle: 'policies_or_practices_related_to_sustainable_oceans_and_seas_have_been_adopted',
    datapoint_id: 'E3-1_09',
    esg_category: 'environmental',
    name_en: 'Policies or practices related to sustainable oceans and seas have been adopted',
    name_es: 'Se han adoptado políticas o prácticas relacionadas con océanos y mares sostenibles.',
    name_pt: 'Foram adotadas políticas ou práticas relacionadas com oceanos e mares sustentáveis'
  },
  {
    id: '687364e6-4587-46dc-b6b7-704f625e425f',
    name_dcycle:
      'the_policly_contributes_to_good_ecological_and_chemical_quality_of_surface_water_bodies_and_good_chemical_quality_and_quantity_of_groundwater_bodies,_in_order_to_protect_human_health,_water_supply,_natural_ecosystems_and_biodiversity,_the_good_environmental_status_of_marine_waters_and_the_protection_of_the_resource_base_upon_which_marine_related_activities_depend;',
    datapoint_id: 'E3-1_10',
    esg_category: 'environmental',
    name_en:
      'The policly contributes to good ecological and chemical quality of surface water bodies and good chemical quality and quantity of groundwater bodies, in order to protect human health, water supply, natural ecosystems and biodiversity, the good environmental status of marine waters and the protection of the resource base upon which marine related activities depend;',
    name_es:
      'La política contribuye a la buena calidad ecológica y química de las masas de agua superficiales y a la buena calidad y cantidad química de las masas de aguas subterráneas, con el fin de proteger la salud humana, el abastecimiento de agua, los ecosistemas naturales y la biodiversidad, el buen estado medioambiental de las aguas marinas y la protección del base de recursos de la que dependen las actividades relacionadas con el mar;',
    name_pt:
      'A política contribui para uma boa qualidade ecológica e química das massas de água superficiais e para uma boa qualidade e quantidade química das massas de água subterrâneas, a fim de proteger a saúde humana, o abastecimento de água, os ecossistemas naturais e a biodiversidade, o bom estado ambiental das águas marinhas e a protecção do base de recursos da qual dependem as atividades relacionadas com o mar;'
  },
  {
    id: 'c0c654fd-347f-44da-b580-d0eaca17fbc4',
    name_dcycle:
      'the_policy_minimise_material_impacts_and_risks_and_implement_mitigation_measures_that_aim_to_maintain_the_value_and_functionality_of_priority_services_and_to_increase_resource_efficiency_on_own_operations',
    datapoint_id: 'E3-1_11',
    esg_category: 'environmental',
    name_en:
      'The policy minimise material impacts and risks and implement mitigation measures that aim to maintain the value and functionality of priority services and to increase resource efficiency on own operations',
    name_es:
      'La política minimiza los impactos y riesgos materiales e implementa medidas de mitigación que tienen como objetivo mantener el valor y la funcionalidad de los servicios prioritarios y aumentar la eficiencia de los recursos en las operaciones propias.',
    name_pt:
      'A política minimiza impactos e riscos materiais e implementa medidas de mitigação que visam manter o valor e a funcionalidade dos serviços prioritários e aumentar a eficiência dos recursos nas próprias operações'
  },
  {
    id: '0fd8b20c-185d-4fad-a5b9-eaec10dda303',
    name_dcycle: 'the_policy_avoid_impacts_on_affected_communities.',
    datapoint_id: 'E3-1_12',
    esg_category: 'environmental',
    name_en: 'The policy avoid impacts on affected communities.',
    name_es: 'La política evita impactos en las comunidades afectadas.',
    name_pt: 'A política evita impactos nas comunidades afetadas.'
  },
  {
    id: '4f7c34f4-c411-483c-8369-4d6d983046ca',
    name_dcycle:
      'layer_in_mitigation_hierarchy_to_which_action_and_resources_can_be_allocated_to_(water_and_marine_resources)',
    datapoint_id: 'E3-2_01',
    esg_category: 'environmental',
    name_en:
      'Layer in mitigation hierarchy to which action and resources can be allocated to (water and marine resources)',
    name_es:
      'Capa en la jerarquía de mitigación a la que se pueden asignar acciones y recursos (recursos hídricos y marinos)',
    name_pt:
      'Camada na hierarquia de mitigação à qual as ações e recursos podem ser alocados (recursos hídricos e marinhos)'
  },
  {
    id: '9511a2a5-d9c1-4933-aad4-32186ace4861',
    name_dcycle: 'information_about_specific_collective_action_for_water_and_marine_resources',
    datapoint_id: 'E3-2_02',
    esg_category: 'environmental',
    name_en: 'Information about specific collective action for water and marine resources',
    name_es:
      'Información sobre acciones colectivas específicas para el agua y los recursos marinos',
    name_pt: 'Informações sobre ações coletivas específicas para recursos hídricos e marinhos'
  },
  {
    id: '0daca228-52a2-412a-864b-035a4b73af2c',
    name_dcycle: 'disclosure_of_actions_and_resources_in_relation_to_areas_at_water_risk',
    datapoint_id: 'E3-2_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of actions and resources in relation to areas at water risk',
    name_es: 'Divulgación de acciones y recursos en relación con áreas en riesgo hídrico',
    name_pt: 'Divulgação de ações e recursos em relação às áreas de risco hídrico'
  },
  {
    id: '7e22d2f4-98d9-4baf-bf21-b5bd3f05f901',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_management_of_material_impacts,_risks_and_opportunities_related_to_areas_at_water_risk',
    datapoint_id: 'E3-3_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to management of material impacts, risks and opportunities related to areas at water risk',
    name_es:
      'Divulgación de si y cómo el objetivo se relaciona con la gestión de impactos, riesgos y oportunidades materiales relacionados con áreas en riesgo hídrico.',
    name_pt:
      'Divulgação de se e como a meta se relaciona com a gestão de impactos, riscos e oportunidades materiais relacionados a áreas com risco hídrico'
  },
  {
    id: '1940a8af-e62a-47ee-8fd0-86634cca8a2c',
    name_dcycle:
      'disclosure_of_whether_and_how_target_relates_to_responsible_management_of_marine_resources_impacts,_risks_and_opportunities',
    datapoint_id: 'E3-3_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how target relates to responsible management of marine resources impacts, risks and opportunities',
    name_es:
      'Divulgación de si la meta se relaciona con la gestión responsable de los impactos, riesgos y oportunidades de los recursos marinos y de qué manera.',
    name_pt:
      'Divulgação de se e como a meta se relaciona com a gestão responsável dos impactos, riscos e oportunidades dos recursos marinhos'
  },
  {
    id: 'b853f29f-8b4b-431d-a5bc-881db2ece8c5',
    name_dcycle: 'disclosure_of_whether_and_how_target_relates_to_reduction_of_water_consumption',
    datapoint_id: 'E3-3_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of whether and how target relates to reduction of water consumption',
    name_es:
      'Divulgación de si el objetivo se relaciona con la reducción del consumo de agua y cómo',
    name_pt: 'Divulgação sobre se e como a meta está relacionada à redução do consumo de água'
  },
  {
    id: '911cbb4c-0665-404e-843c-37dace647174',
    name_dcycle:
      '(local)_ecological_threshold_and_entity-specific_allocation_were_taken_into_consideration_when_setting_water_and_marine_resources_target',
    datapoint_id: 'E3-3_04',
    esg_category: 'environmental',
    name_en:
      '(Local) ecological threshold and entity-specific allocation were taken into consideration when setting water and marine resources target',
    name_es:
      'Se tuvieron en cuenta el umbral ecológico (local) y la asignación específica de la entidad al establecer el objetivo de recursos hídricos y marinos.',
    name_pt:
      'O limiar ecológico (local) e a alocação específica da entidade foram levados em consideração ao definir a meta para os recursos hídricos e marinhos'
  },
  {
    id: '8a925db2-1500-4f27-b40b-39903467cbec',
    name_dcycle:
      'disclosure_of_ecological_threshold_identified_and_methodology_used_to_identify_ecological_threshold_(water_and_marine_resources)',
    datapoint_id: 'E3-3_05',
    esg_category: 'environmental',
    name_en:
      'Disclosure of ecological threshold identified and methodology used to identify ecological threshold (water and marine resources)',
    name_es:
      'Divulgación del umbral ecológico identificado y metodología utilizada para identificar el umbral ecológico (agua y recursos marinos)',
    name_pt:
      'Divulgação do limiar ecológico identificado e metodologia utilizada para identificar o limiar ecológico (recursos hídricos e marinhos)'
  },
  {
    id: '9dd66db8-6ac0-49ea-aa87-61ca6ab09d48',
    name_dcycle:
      'disclosure_of_how_ecological_entity-specific_threshold_was_determined_(water_and_marine_resources)',
    datapoint_id: 'E3-3_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how ecological entity-specific threshold was determined (water and marine resources)',
    name_es:
      'Divulgación de cómo se determinó el umbral ecológico específico de la entidad (agua y recursos marinos)',
    name_pt:
      'Divulgação de como foi determinado o limite específico da entidade ecológica (recursos hídricos e marinhos)'
  },
  {
    id: '796fd32e-41c2-45b6-a22f-18910850d5a5',
    name_dcycle:
      'disclosure_of_how_responsibility_for_respecting_identified_ecological_threshold_is_allocated_(water_and_marine_resources)',
    datapoint_id: 'E3-3_07',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how responsibility for respecting identified ecological threshold is allocated (water and marine resources)',
    name_es:
      'Divulgación de cómo se asigna la responsabilidad de respetar el umbral ecológico identificado (agua y recursos marinos)',
    name_pt:
      'Divulgação de como é atribuída a responsabilidade pelo respeito do limiar ecológico identificado (recursos hídricos e marinhos)'
  },
  {
    id: 'a2c08482-b447-4505-85de-250517e51927',
    name_dcycle:
      'adopted_and_presented_water_and_marine_resources-related_target_is_mandatory_(based_on_legislation)',
    datapoint_id: 'E3-3_08',
    esg_category: 'environmental',
    name_en:
      'Adopted and presented water and marine resources-related target is mandatory (based on legislation)',
    name_es:
      'La meta adoptada y presentada relacionada con el agua y los recursos marinos es obligatoria (según la legislación)',
    name_pt:
      'A meta relativa à água e aos recursos marinhos adotada e apresentada é obrigatória (com base na legislação)'
  },
  {
    id: '96ac40f7-6c09-4cc5-a3a3-5727d96cb24c',
    name_dcycle: 'target_relates_to_reduction_of_water_withdrawals',
    datapoint_id: 'E3-3_09',
    esg_category: 'environmental',
    name_en: 'Target relates to reduction of water withdrawals',
    name_es: 'El objetivo se refiere a la reducción de las extracciones de agua.',
    name_pt: 'Meta diz respeito à redução de captação de água'
  },
  {
    id: '73c9888d-341f-44c8-885d-a9d355f95bab',
    name_dcycle: 'target_relates_to_reduction_of_water_discharges',
    datapoint_id: 'E3-3_10',
    esg_category: 'environmental',
    name_en: 'Target relates to reduction of water discharges',
    name_es: 'El objetivo se refiere a la reducción de los vertidos de agua.',
    name_pt: 'Meta refere-se à redução de descargas de água'
  },
  {
    id: '4b11dab6-a5d2-404e-bb53-dd6c1620b5fb',
    name_dcycle: 'total_water_consumption',
    datapoint_id: 'E3-4_01',
    esg_category: 'environmental',
    name_en: 'Total water consumption',
    name_es: 'Consumo total de agua',
    name_pt: 'Consumo total de água'
  },
  {
    id: 'c60af1e6-4137-4d6f-ab0d-738c94484ceb',
    name_dcycle:
      'total_water_consumption_in_areas_at_water_risk,_including_areas_of_high-water_stress',
    datapoint_id: 'E3-4_02',
    esg_category: 'environmental',
    name_en: 'Total water consumption in areas at water risk, including areas of high-water stress',
    name_es:
      'Consumo total de agua en áreas con riesgo hídrico, incluidas áreas de alto estrés hídrico',
    name_pt:
      'Consumo total de água em áreas com risco hídrico, incluindo áreas com alto estresse hídrico'
  },
  {
    id: 'd46b75d0-36e9-48f2-bb48-50cf8f82e837',
    name_dcycle: 'total_water_recycled_and_reused',
    datapoint_id: 'E3-4_03',
    esg_category: 'environmental',
    name_en: 'Total water recycled and reused',
    name_es: 'Agua total reciclada y reutilizada',
    name_pt: 'Total de água reciclada e reutilizada'
  },
  {
    id: 'c5ad93ce-b879-4cc3-be2c-e0534b51cd6d',
    name_dcycle: 'total_water_stored',
    datapoint_id: 'E3-4_04',
    esg_category: 'environmental',
    name_en: 'Total water stored',
    name_es: 'Agua total almacenada',
    name_pt: 'Total de água armazenada'
  },
  {
    id: 'ec11330a-ef0b-455f-8037-6b0c27da84a7',
    name_dcycle: 'changes_in_water_storage',
    datapoint_id: 'E3-4_05',
    esg_category: 'environmental',
    name_en: 'Changes in water storage',
    name_es: 'Cambios en el almacenamiento de agua.',
    name_pt: 'Mudanças no armazenamento de água'
  },
  {
    id: '05e91cc2-c17b-4882-b749-778bc3c69297',
    name_dcycle: 'disclosure_of_contextual_information_regarding_warter_consumption',
    datapoint_id: 'E3-4_06',
    esg_category: 'environmental',
    name_en: 'Disclosure of contextual information regarding warter consumption',
    name_es: 'Divulgación de información contextual sobre el consumo de agua.',
    name_pt: 'Divulgação de informações contextuais sobre o consumo de guerra'
  },
  {
    id: '00698cd2-a49e-4d0f-87d5-3adc2a8d4369',
    name_dcycle:
      'share_of_the_measure_obtained_from_direct_measurement,_from_sampling_and_extrapolation,_or_from_best_estimates',
    datapoint_id: 'E3-4_07',
    esg_category: 'environmental',
    name_en:
      'Share of the measure obtained from direct measurement, from sampling and extrapolation, or from best estimates',
    name_es:
      'Proporción de la medida obtenida de la medición directa, del muestreo y la extrapolación, o de las mejores estimaciones',
    name_pt:
      'Parcela da medida obtida por medição direta, por amostragem e extrapolação ou por melhores estimativas'
  },
  {
    id: '2b6068d5-e592-4aef-aea1-173e218686cc',
    name_dcycle: 'water_intensity_ratio',
    datapoint_id: 'E3-4_08',
    esg_category: 'environmental',
    name_en: 'Water intensity ratio',
    name_es: 'Relación de intensidad del agua',
    name_pt: 'Razão de intensidade de água'
  },
  {
    id: '4648a9e6-dea0-47a4-90f1-316fc7eb7cee',
    name_dcycle: 'water_consumption_-_sectors/segments_[table]',
    datapoint_id: 'E3-4_09',
    esg_category: 'environmental',
    name_en: 'Water consumption - sectors/SEGMENTS [table]',
    name_es: 'Consumo de agua - sectores/SEGMENTOS [tabla]',
    name_pt: 'Consumo de água – setores/SEGMENTOS [tabela]'
  },
  {
    id: 'e4ad0a78-c4e7-4875-bcbb-4a6c4a7f27a2',
    name_dcycle: 'additional_water_intensity_ratio',
    datapoint_id: 'E3-4_10',
    esg_category: 'environmental',
    name_en: 'Additional water intensity ratio',
    name_es: 'Relación de intensidad de agua adicional',
    name_pt: 'Proporção de intensidade de água adicional'
  },
  {
    id: '25bdc5d9-269a-4379-b4eb-98201c3a25b3',
    name_dcycle: 'total_water_withdrawals',
    datapoint_id: 'E3-4_11',
    esg_category: 'environmental',
    name_en: 'Total water withdrawals',
    name_es: 'Extracción total de agua',
    name_pt: 'Total de retiradas de água'
  },
  {
    id: '2eea7ecf-2a28-4ef3-a2b0-6f4ee9af2205',
    name_dcycle: 'total_water_discharges',
    datapoint_id: 'E3-4_12',
    esg_category: 'environmental',
    name_en: 'Total water discharges',
    name_es: 'Vertidos totales de agua',
    name_pt: 'Descargas totais de água'
  },
  {
    id: '38ff58ab-04d8-4566-b078-6231a5444f09',
    name_dcycle:
      'disclosure_of_quantitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_water_and_marine_resources-related_impacts',
    datapoint_id: 'E3-5_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of quantitative information about anticipated financial effects of material risks and opportunities arising from water and marine resources-related impacts',
    name_es:
      'Divulgación de información cuantitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos relacionados con el agua y los recursos marinos.',
    name_pt:
      'Divulgação de informações quantitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos relacionados à água e aos recursos marinhos'
  },
  {
    id: '4218e6aa-a1cd-4282-a7fa-cbb0ce803f37',
    name_dcycle:
      'disclosure_of_qualitative_information_of_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_water_and_marine_resources-related_impacts',
    datapoint_id: 'E3-5_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of qualitative information of anticipated financial effects of material risks and opportunities arising from water and marine resources-related impacts',
    name_es:
      'Divulgación de información cualitativa de los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos relacionados con el agua y los recursos marinos.',
    name_pt:
      'Divulgação de informações qualitativas sobre efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos relacionados à água e aos recursos marinhos'
  },
  {
    id: 'd6c3acf2-ac20-419c-bffd-a1260b911039',
    name_dcycle:
      'description_of_effects_considered_and_related_impacts_(water_and_marine_resources)',
    datapoint_id: 'E3-5_03',
    esg_category: 'environmental',
    name_en: 'Description of effects considered and related impacts (water and marine resources)',
    name_es:
      'Descripción de los efectos considerados e impactos relacionados (agua y recursos marinos)',
    name_pt:
      'Descrição dos efeitos considerados e impactos relacionados (recursos hídricos e marinhos)'
  },
  {
    id: '0423db98-3b01-4730-9eaf-d2412af95f8b',
    name_dcycle:
      'disclosure_of_critical_assumptions_used_in_estimates_of_financial_effects_of_material_risks_and_opportunities_arising_from_water_and_marine_resources-related_impacts',
    datapoint_id: 'E3-5_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of critical assumptions used in estimates of financial effects of material risks and opportunities arising from water and marine resources-related impacts',
    name_es:
      'Divulgación de supuestos críticos utilizados en las estimaciones de los efectos financieros de los riesgos y oportunidades materiales que surgen de los impactos relacionados con el agua y los recursos marinos.',
    name_pt:
      'Divulgação de premissas críticas utilizadas nas estimativas de efeitos financeiros de riscos e oportunidades materiais decorrentes de impactos relacionados à água e aos recursos marinhos'
  },
  {
    id: '733343f1-9446-4607-89fb-27a08b927369',
    name_dcycle:
      'description_of_related_products_and_services_at_risk_(water_and_marine_resources)',
    datapoint_id: 'E3-5_05',
    esg_category: 'environmental',
    name_en: 'Description of related products and services at risk (water and marine resources)',
    name_es:
      'Descripción de productos y servicios relacionados en riesgo (agua y recursos marinos)',
    name_pt:
      'Descrição dos produtos e serviços relacionados em risco (recursos hídricos e marinhos)'
  },
  {
    id: '863d342d-3262-4bd0-ac6f-7810d3cd6a4e',
    name_dcycle:
      'explanation_of_how_time_horizons_are_defined,_financial_amounts_are_estimated_and_critical_assumptions_made_(water_and_marine_resources)',
    datapoint_id: 'E3-5_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of how time horizons are defined, financial amounts are estimated and critical assumptions made (water and marine resources)',
    name_es:
      'Explicación de cómo se definen los horizontes temporales, se estiman los montos financieros y se hacen los supuestos críticos (recursos hídricos y marinos)',
    name_pt:
      'Explicação de como os horizontes temporais são definidos, os montantes financeiros são estimados e as suposições críticas são feitas (recursos hídricos e marinhos)'
  },
  {
    id: '19846a66-691e-4779-bfd3-9bba6ca2ffa2',
    name_dcycle: 'list_of_material_sites_in_own_operation',
    datapoint_id: 'E4.SBM-3_01',
    esg_category: 'environmental',
    name_en: 'List of material sites in own operation',
    name_es: 'Lista de sitios de materiales en operación propia',
    name_pt: 'Lista de sites de materiais em operação própria'
  },
  {
    id: 'e8038ab9-0c04-4ac8-b893-6c2fb301e847',
    name_dcycle: 'disclosure_of_activities_negatively_affecting_biodiversity_sensitive_areeas',
    datapoint_id: 'E4.SBM-3_02',
    esg_category: 'environmental',
    name_en: 'Disclosure of activities negatively affecting biodiversity sensitive areeas',
    name_es:
      'Divulgación de actividades que afectan negativamente áreas sensibles a la biodiversidad',
    name_pt: 'Divulgação de atividades que afetam negativamente áreas sensíveis à biodiversidade'
  },
  {
    id: 'a8e9e925-e315-4e1d-84af-87a1ffb8d90b',
    name_dcycle:
      'disclosure_of_list_of_material_sites_in_own_operations_based_on_results_of_identification_and_assessment_of_actual_and_potential_impacts_on_biodiversity_and_ecosystems',
    datapoint_id: 'E4.SBM-3_03',
    esg_category: 'environmental',
    name_en:
      'Disclosure of list of material sites in own operations based on results of identification and assessment of actual and potential impacts on biodiversity and ecosystems',
    name_es:
      'Divulgación de la lista de sitios materiales en operaciones propias con base en los resultados de la identificación y evaluación de los impactos reales y potenciales sobre la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação de lista de locais materiais em operações próprias com base em resultados de identificação e avaliação de impactos reais e potenciais na biodiversidade e nos ecossistemas'
  },
  {
    id: 'ff8d4d20-6c1e-4d83-933f-5a10cfa4bbd2',
    name_dcycle: 'disclosure_of_biodiversity-sensitive_areas_impacted',
    datapoint_id: 'E4.SBM-3_04',
    esg_category: 'environmental',
    name_en: 'Disclosure of biodiversity-sensitive areas impacted',
    name_es: 'Divulgación de áreas sensibles a la biodiversidad impactadas',
    name_pt: 'Divulgação de áreas sensíveis à biodiversidade impactadas'
  },
  {
    id: 'f2f637d9-dcfd-4fbb-a8eb-930e0dc0c735',
    name_dcycle:
      'material_negative_impacts_with_regards_to_land_degradation,_desertification_or_soil_sealing_have_been_identified',
    datapoint_id: 'E4.SBM-3_05',
    esg_category: 'environmental',
    name_en:
      'Material negative impacts with regards to land degradation, desertification or soil sealing have been identified',
    name_es:
      'Se han identificado impactos materiales negativos en relación con la degradación de la tierra, la desertificación o el sellado del suelo.',
    name_pt:
      'Foram identificados impactos negativos materiais no que diz respeito à degradação dos solos, à desertificação ou à impermeabilização dos solos'
  },
  {
    id: 'f92ce1da-9031-4c65-82fe-faca7d10293f',
    name_dcycle: 'own_operations_affect_threatened_species',
    datapoint_id: 'E4.SBM-3_06',
    esg_category: 'environmental',
    name_en: 'Own operations affect threatened species',
    name_es: 'Operaciones propias afectan a especies amenazadas',
    name_pt: 'Operações próprias afetam espécies ameaçadas'
  },
  {
    id: '40cf7c86-e52d-4004-a79a-c761ba9b9a22',
    name_dcycle:
      'disclosure_of_whether_and_how_actual_and_potential_impacts_on_biodiversity_and_ecosystems_at_own_site_locations_and_in_value_chain_have_been_identified_and_assessed',
    datapoint_id: 'E4.IRO-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how actual and potential impacts on biodiversity and ecosystems at own site locations and in value chain have been identified and assessed',
    name_es:
      'Divulgación de si y cómo se han identificado y evaluado los impactos reales y potenciales sobre la biodiversidad y los ecosistemas en las ubicaciones del propio sitio y en la cadena de valor.',
    name_pt:
      'Divulgação de se e como os impactos reais e potenciais sobre a biodiversidade e os ecossistemas nas próprias instalações e na cadeia de valor foram identificados e avaliados'
  },
  {
    id: 'fd648151-6a01-4519-ba35-8123e0cc4b54',
    name_dcycle:
      'disclosure_of_whether_and_how_dependencies_on_biodiversity_and_ecosystems_and_their_services_have_been_identified_and_assessed_at_own_site_locations_and_in_value_chain',
    datapoint_id: 'E4.IRO-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how dependencies on biodiversity and ecosystems and their services have been identified and assessed at own site locations and in value chain',
    name_es:
      'Divulgación de si y cómo se han identificado y evaluado las dependencias de la biodiversidad y los ecosistemas y sus servicios en las ubicaciones del propio sitio y en la cadena de valor.',
    name_pt:
      'Divulgação de se e como as dependências da biodiversidade e dos ecossistemas e dos seus serviços foram identificadas e avaliadas nas próprias instalações e na cadeia de valor'
  },
  {
    id: '4f8b9aca-66af-4068-a72d-6b91b13f4e5f',
    name_dcycle:
      'disclosure_of_whether_and_how_transition_and_physical_risks_and_opportunities_related_to_biodiversity_and_ecosystems_have_been_identified_and_assessed',
    datapoint_id: 'E4.IRO-1_03',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how transition and physical risks and opportunities related to biodiversity and ecosystems have been identified and assessed',
    name_es:
      'Divulgación de si se han identificado y evaluado los riesgos y oportunidades físicos y de transición relacionados con la biodiversidad y los ecosistemas, y de qué manera',
    name_pt:
      'Divulgação sobre se e como os riscos e oportunidades físicas e de transição relacionados com a biodiversidade e os ecossistemas foram identificados e avaliados'
  },
  {
    id: '91934419-9c8f-43c2-ba58-2a4c9ad44434',
    name_dcycle:
      'disclosure_of_whether_and_how_systemic_risks_have_been_considered_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4.IRO-1_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how systemic risks have been considered (biodiversity and ecosystems)',
    name_es:
      'Divulgación de si se han considerado los riesgos sistémicos (biodiversidad y ecosistemas) y cómo',
    name_pt:
      'Divulgação sobre se e como os riscos sistêmicos foram considerados (biodiversidade e ecossistemas)'
  },
  {
    id: '64b5749f-2977-49a4-a99b-fd17fa0a4952',
    name_dcycle:
      'disclosure_of_whether_and_how_consultations_with_affected_communities_on_sustainability_assessments_of_shared_biological_resources_and_ecosystems_have_been_conducted',
    datapoint_id: 'E4.IRO-1_05',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how consultations with affected communities on sustainability assessments of shared biological resources and ecosystems have been conducted',
    name_es:
      'Divulgación de si se han realizado consultas con las comunidades afectadas sobre evaluaciones de sostenibilidad de los recursos biológicos y ecosistemas compartidos y cómo',
    name_pt:
      'Divulgação sobre se e como foram realizadas consultas com as comunidades afetadas sobre avaliações de sustentabilidade de recursos biológicos e ecossistemas compartilhados'
  },
  {
    id: 'a97006d6-e8a9-4e41-94b4-dbb207d8f8d1',
    name_dcycle:
      'disclosure_of_whether_and_how_specific_sites,_raw_materials_production_or_sourcing_with_negative_or_potential_negative_impacts_on_affected_communities',
    datapoint_id: 'E4.IRO-1_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how specific sites, raw materials production or sourcing with negative or potential negative impacts on affected communities',
    name_es:
      'Divulgación de si sitios específicos, producción o abastecimiento de materias primas tienen impactos negativos o potenciales negativos en las comunidades afectadas y cómo.',
    name_pt:
      'Divulgação de se e como locais específicos, produção ou fornecimento de matérias-primas têm impactos negativos ou potenciais negativos nas comunidades afetadas'
  },
  {
    id: '0cf3622a-b0a4-429a-b14d-9089eeaba8f2',
    name_dcycle:
      'disclosure_of_whether_and_how_communities_were_involved_in_materiality_assessment',
    datapoint_id: 'E4.IRO-1_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of whether and how communities were involved in materiality assessment',
    name_es:
      'Divulgación de si las comunidades participaron en la evaluación de materialidad y de qué manera',
    name_pt:
      'Divulgação sobre se e como as comunidades foram envolvidas na avaliação de materialidade'
  },
  {
    id: 'fdaafbcc-8302-4dc9-a559-659ce624141d',
    name_dcycle:
      'disclosure_of_whether_and_how_negative_impacts_on_priority_ecosystem_services_of_relevance_to_affected_communities_may_be_avoided',
    datapoint_id: 'E4.IRO-1_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how negative impacts on priority ecosystem services of relevance to affected communities may be avoided',
    name_es:
      'Divulgación de si se pueden evitar, y cómo, los impactos negativos en los servicios ecosistémicos prioritarios de relevancia para las comunidades afectadas.',
    name_pt:
      'Divulgação de se e como os impactos negativos nos serviços ecossistêmicos prioritários de relevância para as comunidades afetadas podem ser evitados'
  },
  {
    id: '2451f839-0be4-4e09-800c-79fbda967565',
    name_dcycle:
      'disclosure_of_plans_to_minimise_unavoidable_negative_impacts_and_implement_mitigation_measures_that_aim_to_maintain_value_and_functionality_of_priority_services',
    datapoint_id: 'E4.IRO-1_09',
    esg_category: 'environmental',
    name_en:
      'Disclosure of plans to minimise unavoidable negative impacts and implement mitigation measures that aim to maintain value and functionality of priority services',
    name_es:
      'Divulgación de planes para minimizar los impactos negativos inevitables e implementar medidas de mitigación que tengan como objetivo mantener el valor y la funcionalidad de los servicios prioritarios.',
    name_pt:
      'Divulgação de planos para minimizar impactos negativos inevitáveis ​​e implementar medidas de mitigação que visam manter o valor e a funcionalidade dos serviços prioritários'
  },
  {
    id: 'cff70ff5-b136-42da-873a-6f7a2e0f9a86',
    name_dcycle:
      'disclosure_of_whether_and_how_tthe_business_model(s)_has_been_verified_using_range_of_biodiversity_and_ecosystems_scenarios,_or_other_scenarios_with_modelling_of_biodiversity_and_ecosystems_related_consequences,_with_different_possible_pathways',
    datapoint_id: 'E4.IRO-1_10',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how tthe business model(s) has been verified using range of biodiversity and ecosystems scenarios, or other scenarios with modelling of biodiversity and ecosystems related consequences, with different possible pathways',
    name_es:
      'Divulgación de si y cómo se han verificado los modelos de negocio utilizando una variedad de escenarios de biodiversidad y ecosistemas, u otros escenarios con modelado de consecuencias relacionadas con la biodiversidad y los ecosistemas, con diferentes caminos posibles.',
    name_pt:
      'Divulgação de se e como o(s) modelo(s) de negócios foram verificados usando uma variedade de cenários de biodiversidade e ecossistemas, ou outros cenários com modelagem de consequências relacionadas à biodiversidade e aos ecossistemas, com diferentes caminhos possíveis'
  },
  {
    id: 'f35f662e-c22a-4991-b2e0-cfa4234d143f',
    name_dcycle: 'disclosure_of_why_considered_scenarios_were_taken_into_consideration',
    datapoint_id: 'E4.IRO-1_11',
    esg_category: 'environmental',
    name_en: 'Disclosure of why considered scenarios were taken into consideration',
    name_es: 'Divulgación de por qué se tomaron en consideración los escenarios considerados',
    name_pt: 'Divulgação do motivo pelo qual os cenários considerados foram levados em consideração'
  },
  {
    id: '97f6dff2-9a92-4aac-bcae-c421057205ee',
    name_dcycle:
      'disclosure_of_how_considered_scenarios_are_updated_according_to_evolving_conditions_and_emerging_trends',
    datapoint_id: 'E4.IRO-1_12',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how considered scenarios are updated according to evolving conditions and emerging trends',
    name_es:
      'Divulgación de cómo se actualizan los escenarios considerados de acuerdo con las condiciones cambiantes y las tendencias emergentes.',
    name_pt:
      'Divulgação de como os cenários considerados são atualizados de acordo com a evolução das condições e tendências emergentes'
  },
  {
    id: '6a227195-dab8-4972-ad1a-adbd0e51844c',
    name_dcycle:
      'scenarios_are_informed_by_expectations_in_authoritative_intergovernmental_instruments_and_by_scientific_consensus',
    datapoint_id: 'E4.IRO-1_13',
    esg_category: 'environmental',
    name_en:
      'Scenarios are informed by expectations in authoritative intergovernmental instruments and by scientific consensus',
    name_es:
      'Los escenarios se basan en las expectativas contenidas en instrumentos intergubernamentales autorizados y en el consenso científico.',
    name_pt:
      'Os cenários são informados pelas expectativas em instrumentos intergovernamentais autorizados e pelo consenso científico'
  },
  {
    id: '060baf02-93b3-4710-8626-d308ee166969',
    name_dcycle: 'undertaking_has_sites_located_in_or_near_biodiversity-sensitive_areas',
    datapoint_id: 'E4.IRO-1_14',
    esg_category: 'environmental',
    name_en: 'Undertaking has sites located in or near biodiversity-sensitive areas',
    name_es: 'La empresa tiene sitios ubicados en o cerca de áreas sensibles a la biodiversidad',
    name_pt: 'A empresa possui locais localizados em ou perto de áreas sensíveis à biodiversidade'
  },
  {
    id: '95f7511b-d9f1-44be-992f-f6e6ddff1250',
    name_dcycle:
      'activities_related_to_sites_located_in_or_near_biodiversity-sensitive_areas_negatively_affect_these_areas_by_leading_to_deterioration_of_natural_habitats_and_habitats_of_species_and_to_disturbance_of_species_for_which_protected_area_has_been_designated',
    datapoint_id: 'E4.IRO-1_15',
    esg_category: 'environmental',
    name_en:
      'Activities related to sites located in or near biodiversity-sensitive areas negatively affect these areas by leading to deterioration of natural habitats and habitats of species and to disturbance of species for which protected area has been designated',
    name_es:
      'Las actividades relacionadas con sitios ubicados en o cerca de áreas sensibles a la biodiversidad afectan negativamente a estas áreas al provocar el deterioro de los hábitats naturales y de los hábitats de las especies y la perturbación de las especies para las cuales se ha designado un área protegida.',
    name_pt:
      'As atividades relacionadas com sítios localizados em ou perto de zonas sensíveis à biodiversidade afetam negativamente essas zonas, conduzindo à deterioração dos habitats naturais e dos habitats das espécies e à perturbação das espécies para as quais foram designadas áreas protegidas'
  },
  {
    id: '94b5dc79-282b-458f-b28b-b4eea269478e',
    name_dcycle:
      'it_has_been_concluded_that_it_is_necessary_to_implement_biodiversity_mitigation_measures',
    datapoint_id: 'E4.IRO-1_16',
    esg_category: 'environmental',
    name_en:
      'It has been concluded that it is necessary to implement biodiversity mitigation measures',
    name_es:
      'Se ha concluido que es necesario implementar medidas de mitigación de la biodiversidad',
    name_pt: 'Concluiu-se que é necessário implementar medidas de mitigação da biodiversidade'
  },
  {
    id: '11891f4f-a926-4cfc-a915-709d8682c6c4',
    name_dcycle:
      'disclosure_of_resilience_of_current_business_model(s)_and_strategy_to_biodiversity_and_ecosystems-related_physical,_transition_and_systemic_risks_and_opportunities',
    datapoint_id: 'E4-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of resilience of current business model(s) and strategy to biodiversity and ecosystems-related physical, transition and systemic risks and opportunities',
    name_es:
      'Divulgación de la resiliencia de los modelos de negocio actuales y la estrategia ante los riesgos y oportunidades físicos, de transición y sistémicos relacionados con la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação da resiliência do(s) atual(is) modelo(s) de negócios e estratégia para a biodiversidade e riscos e oportunidades físicos, de transição e sistêmicos relacionados aos ecossistemas'
  },
  {
    id: '943d42ce-11c7-41dd-84fa-2b1918e41e80',
    name_dcycle:
      'disclosure_of_scope_of_resilience_analysis_along_own_operations_and_related_upstream_and_downstream_value_chain',
    datapoint_id: 'E4-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of scope of resilience analysis along own operations and related upstream and downstream value chain',
    name_es:
      'Divulgación del alcance del análisis de resiliencia a lo largo de las operaciones propias y la cadena de valor ascendente y descendente relacionada.',
    name_pt:
      'Divulgação do escopo da análise de resiliência ao longo das próprias operações e da cadeia de valor upstream e downstream relacionada'
  },
  {
    id: '3741d9b9-a268-4e7a-82f1-d49a37587ee9',
    name_dcycle: 'disclosure_of_key_assumptions_made_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of key assumptions made (biodiversity and ecosystems)',
    name_es: 'Divulgación de supuestos clave formulados (biodiversidad y ecosistemas)',
    name_pt: 'Divulgação das principais suposições feitas (biodiversidade e ecossistemas)'
  },
  {
    id: 'fbd13420-ca4f-49f9-bde4-401f1ce36617',
    name_dcycle: 'disclosure_of_time_horizons_used_for_analysis_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_04',
    esg_category: 'environmental',
    name_en: 'Disclosure of time horizons used for analysis (biodiversity and ecosystems)',
    name_es:
      'Divulgación de los horizontes temporales utilizados para el análisis (biodiversidad y ecosistemas)',
    name_pt:
      'Divulgação dos horizontes temporais utilizados para análise (biodiversidade e ecossistemas)'
  },
  {
    id: '2f7c23fe-301d-4766-9f3f-7d04b80c9af1',
    name_dcycle: 'disclosure_of_results_of_resilience_analysis_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_05',
    esg_category: 'environmental',
    name_en: 'Disclosure of results of resilience analysis (biodiversity and ecosystems)',
    name_es: 'Divulgación de resultados de análisis de resiliencia (biodiversidad y ecosistemas)',
    name_pt: 'Divulgação dos resultados da análise de resiliência (biodiversidade e ecossistemas)'
  },
  {
    id: '9889457a-ec84-4e01-9354-68552e3ba6b7',
    name_dcycle: 'disclosure_of_involvement_of_stakeholders_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_06',
    esg_category: 'environmental',
    name_en: 'Disclosure of involvement of stakeholders (biodiversity and ecosystems)',
    name_es:
      'Divulgación de la participación de las partes interesadas (biodiversidad y ecosistemas)',
    name_pt: 'Divulgação do envolvimento das partes interessadas (biodiversidade e ecossistemas)'
  },
  {
    id: '15135d77-3632-4702-860a-6b51a85eedc5',
    name_dcycle:
      'disclosure_of_transition_plan_to_improve_and_achieve_alignment_of_its_business_model_and_strategy',
    datapoint_id: 'E4-1_07',
    esg_category: 'environmental',
    name_en:
      'Disclosure of transition plan to improve and achieve alignment of its business model and strategy',
    name_es:
      'Divulgación del plan de transición para mejorar y lograr la alineación de su modelo de negocio y estrategia.',
    name_pt:
      'Divulgação do plano de transição para melhorar e alinhar seu modelo de negócios e estratégia'
  },
  {
    id: 'a4e805aa-6a8b-4aec-9a45-52ef4d36bc66',
    name_dcycle:
      'explanation_of_how_strategy_and_business_model_will_be_adjusted_to_improve_and,_ultimately,_achieve_alignment_with_relevant_local,_national_and_global_public_policy_goals',
    datapoint_id: 'E4-1_08',
    esg_category: 'environmental',
    name_en:
      'Explanation of how strategy and business model will be adjusted to improve and, ultimately, achieve alignment with relevant local, national and global public policy goals',
    name_es:
      'Explicación de cómo se ajustarán la estrategia y el modelo de negocio para mejorar y, en última instancia, lograr la alineación con los objetivos relevantes de políticas públicas locales, nacionales y globales.',
    name_pt:
      'Explicação de como a estratégia e o modelo de negócios serão ajustados para melhorar e, em última análise, alcançar o alinhamento com os objetivos relevantes de políticas públicas locais, nacionais e globais'
  },
  {
    id: '6353f482-ce18-4822-a3e3-07c41535fa36',
    name_dcycle:
      'include_information_about_its_own_operations_and_explain_how_it_is_responding_to_material_impacts_in_its_related_value_chain',
    datapoint_id: 'E4-1_09',
    esg_category: 'environmental',
    name_en:
      'Include information about its own operations and explain how it is responding to material impacts in its related value chain',
    name_es:
      'Incluir información sobre sus propias operaciones y explicar cómo está respondiendo a los impactos materiales en su cadena de valor relacionada.',
    name_pt:
      'Incluir informações sobre suas próprias operações e explicar como está respondendo aos impactos materiais em sua cadeia de valor relacionada'
  },
  {
    id: '3da0a678-b238-4e03-b2aa-8b6a895a001a',
    name_dcycle: 'explanation_of_how_b_strategy_interacts_with_transition_plan',
    datapoint_id: 'E4-1_10',
    esg_category: 'environmental',
    name_en: 'Explanation of how b strategy interacts with transition plan',
    name_es: 'Explicación de cómo interactúa la estrategia b con el plan de transición',
    name_pt: 'Explicação de como a estratégia b interage com o plano de transição'
  },
  {
    id: '05cdb3e0-76ce-4e62-97b9-7f28fead9f56',
    name_dcycle:
      'disclosure_of_contribution_to_impact_drivers_and_possible_mitigation_actions_following_mitigation_hierarchy_and_main_path-dependencies_and_locked-in_assets_and_resources_that_are_associated_with_biodiversity_and_ecosystems_change',
    datapoint_id: 'E4-1_11',
    esg_category: 'environmental',
    name_en:
      'Disclosure of contribution to impact drivers and possible mitigation actions following mitigation hierarchy and main path-dependencies and locked-in assets and resources that are associated with biodiversity and ecosystems change',
    name_es:
      'Divulgación de la contribución a los impulsores del impacto y posibles acciones de mitigación siguiendo la jerarquía de mitigación y las principales dependencias de la ruta y los activos y recursos bloqueados que están asociados con el cambio de la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação da contribuição para os fatores de impacto e possíveis ações de mitigação seguindo a hierarquia de mitigação e as principais dependências de trajetória e ativos e recursos bloqueados que estão associados à biodiversidade e à mudança dos ecossistemas'
  },
  {
    id: 'f874124b-53c7-4331-b62c-aa6fda72d569',
    name_dcycle:
      'explanation_and_quantification_of_investments_and_funding_supporting_the_implementation_of_its_transition_plan',
    datapoint_id: 'E4-1_12',
    esg_category: 'environmental',
    name_en:
      'Explanation and quantification of investments and funding supporting the implementation of its transition plan',
    name_es:
      'Explicación y cuantificación de las inversiones y financiación que apoyan la implementación de su plan de transición.',
    name_pt:
      'Explicação e quantificação dos investimentos e financiamentos de apoio à implementação do seu plano de transição'
  },
  {
    id: 'd2a3c021-0b16-41ec-9446-6986f31eea21',
    name_dcycle:
      'disclosure_of_objectives_or_plans_for_aligning_economic_activities_(revenues,_capex)',
    datapoint_id: 'E4-1_13',
    esg_category: 'environmental',
    name_en: 'Disclosure of objectives or plans for aligning economic activities (revenues, CapEx)',
    name_es:
      'Divulgación de objetivos o planes para alinear actividades económicas (ingresos, CapEx)',
    name_pt:
      'Divulgação de objetivos ou planos de alinhamento das atividades económicas (receitas, CapEx)'
  },
  {
    id: '4fb11269-84ff-474d-a502-d1696e6f57ad',
    name_dcycle: 'biodiversity_offsets_are_part_of_transition_plan',
    datapoint_id: 'E4-1_14',
    esg_category: 'environmental',
    name_en: 'Biodiversity offsets are part of transition plan',
    name_es: 'Las compensaciones por biodiversidad son parte del plan de transición',
    name_pt: 'As compensações de biodiversidade fazem parte do plano de transição'
  },
  {
    id: '8771abe1-eb32-475d-a510-06713a549be6',
    name_dcycle:
      'information_about_how_process_of_implementing_and_updating_transition_plan_is_managed',
    datapoint_id: 'E4-1_15',
    esg_category: 'environmental',
    name_en:
      'Information about how process of implementing and updating transition plan is managed',
    name_es:
      'Información sobre cómo se gestiona el proceso de implementación y actualización del plan de transición.',
    name_pt:
      'Informações sobre como é gerenciado o processo de implementação e atualização do plano de transição'
  },
  {
    id: 'b1d1b355-30fb-47de-96ea-e1e96cfd506e',
    name_dcycle:
      'indication_of_metrics_and_related_tools_used_to_measure_progress_that_are_integrated_in_measurement_approach_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_16',
    esg_category: 'environmental',
    name_en:
      'Indication of metrics and related tools used to measure progress that are integrated in measurement approach (biodiversity and ecosystems)',
    name_es:
      'Indicación de métricas y herramientas relacionadas utilizadas para medir el progreso que están integradas en el enfoque de medición (biodiversidad y ecosistemas)',
    name_pt:
      'Indicação de métricas e ferramentas relacionadas usadas para medir o progresso que estão integradas na abordagem de medição (biodiversidade e ecossistemas)'
  },
  {
    id: '88ff1a1a-61b3-4fae-b721-c3bd16ed4c99',
    name_dcycle: 'administrative,_management_and_supervisory_bodies_have_approved_transition_plan',
    datapoint_id: 'E4-1_17',
    esg_category: 'environmental',
    name_en: 'Administrative, management and supervisory bodies have approved transition plan',
    name_es:
      'Los órganos de administración, dirección y supervisión han aprobado el plan de transición',
    name_pt: 'Órgãos de administração, gestão e fiscalização aprovaram plano de transição'
  },
  {
    id: '588782de-4768-4704-a144-6309b031e8c1',
    name_dcycle:
      'indication_of_current_challenges_and_limitations_to_draft_plan_in_relation_to_areas_of_significant_impact_and_actions_company_is_taking_to_address_them_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-1_18',
    esg_category: 'environmental',
    name_en:
      'Indication of current challenges and limitations to draft plan in relation to areas of significant impact and actions company is taking to address them (biodiversity and ecosystems)',
    name_es:
      'Indicación de los desafíos y limitaciones actuales del borrador del plan en relación con áreas de impacto significativo y las acciones que la empresa está tomando para abordarlas (biodiversidad y ecosistemas)',
    name_pt:
      'Indicação dos desafios e limitações atuais para elaborar o plano em relação a áreas de impacto significativo e ações que a empresa está tomando para enfrentá-los (biodiversidade e ecossistemas)'
  },
  {
    id: '6cc0ba57-7744-4bda-bd95-ac7c279740be',
    name_dcycle:
      'disclosure_on_whether_and_how_biodiversity_and_ecosystems-related_policies_relate_to_matters_reported_in_e4_ar4',
    datapoint_id: 'E4-2_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure on whether and how biodiversity and ecosystems-related policies relate to matters reported in E4 AR4',
    name_es:
      'Divulgación sobre si y cómo las políticas relacionadas con la biodiversidad y los ecosistemas se relacionan con los asuntos reportados en E4 AR4',
    name_pt:
      'Divulgação sobre se e como as políticas relacionadas com a biodiversidade e os ecossistemas se relacionam com as questões relatadas em E4 AR4'
  },
  {
    id: 'bfea7b15-13d9-4810-83f7-7de8aec18496',
    name_dcycle:
      'explanation_of_whether_and_how_biodiversity_and_ecosystems-related_policy_relates_to_material_biodiversity_and_ecosystems-related_impacts',
    datapoint_id: 'E4-2_02',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how biodiversity and ecosystems-related policy relates to material biodiversity and ecosystems-related impacts',
    name_es:
      'Explicación de si y cómo la política relacionada con la biodiversidad y los ecosistemas se relaciona con los impactos materiales relacionados con la biodiversidad y los ecosistemas.',
    name_pt:
      'Explicação sobre se e como a política relacionada com a biodiversidade e os ecossistemas se relaciona com a biodiversidade material e com os impactos relacionados com os ecossistemas'
  },
  {
    id: 'f63a7389-b0c4-49bf-acd3-25b571373b40',
    name_dcycle:
      'explanation_of_whether_and_how_biodiversity_and_ecosystems-related_policy_relates_to_material_dependencies_and_material_physical_and_transition_risks_and_opportunities',
    datapoint_id: 'E4-2_03',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how biodiversity and ecosystems-related policy relates to material dependencies and material physical and transition risks and opportunities',
    name_es:
      'Explicación de si y cómo la política relacionada con la biodiversidad y los ecosistemas se relaciona con dependencias materiales y riesgos y oportunidades materiales físicos y de transición.',
    name_pt:
      'Explicação sobre se e como a política relacionada à biodiversidade e aos ecossistemas se relaciona com dependências materiais e riscos e oportunidades materiais, físicos e de transição'
  },
  {
    id: '0f80d4aa-d645-4656-8233-7db6d0d78de0',
    name_dcycle:
      'explanation_of_whether_and_how_biodiversity_and_ecosystems-related_policy_supports_traceability_of_products,_components_and_raw_materials_with_significant_actual_or_potential_impacts_on_biodiversity_and_ecosystems_along_value_chain',
    datapoint_id: 'E4-2_04',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how biodiversity and ecosystems-related policy supports traceability of products, components and raw materials with significant actual or potential impacts on biodiversity and ecosystems along value chain',
    name_es:
      'Explicación de si las políticas relacionadas con la biodiversidad y los ecosistemas apoyan, y de qué manera, la trazabilidad de productos, componentes y materias primas con impactos significativos, reales o potenciales, sobre la biodiversidad y los ecosistemas a lo largo de la cadena de valor.',
    name_pt:
      'Explicação sobre se e como a política relacionada com a biodiversidade e os ecossistemas apoia a rastreabilidade de produtos, componentes e matérias-primas com impactos reais ou potenciais significativos na biodiversidade e nos ecossistemas ao longo da cadeia de valor'
  },
  {
    id: 'bca7e5fc-6a82-4e20-a921-149f89f75f78',
    name_dcycle:
      'explanation_of_whether_and_how_biodiversity_and_ecosystems-related_policy_addresses_production,_sourcing_or_consumption_from_ecosystems_that_are_managed_to_maintain_or_enhance_conditions_for_biodiversity',
    datapoint_id: 'E4-2_05',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how biodiversity and ecosystems-related policy addresses production, sourcing or consumption from ecosystems that are managed to maintain or enhance conditions for biodiversity',
    name_es:
      'Explicación de si las políticas relacionadas con la biodiversidad y los ecosistemas abordan, y de qué manera, la producción, el abastecimiento o el consumo de ecosistemas que se gestionan para mantener o mejorar las condiciones para la biodiversidad.',
    name_pt:
      'Explicação sobre se e como a política relacionada com a biodiversidade e os ecossistemas aborda a produção, o abastecimento ou o consumo de ecossistemas que são geridos para manter ou melhorar as condições para a biodiversidade'
  },
  {
    id: '54c241ec-24ef-4755-946f-33d162a90769',
    name_dcycle:
      'explanation_of_whether_and_how_biodiversity_and_ecosystems-related_policy_addresses_social_consequences_of_biodiversity_and_ecosystems-related_impacts',
    datapoint_id: 'E4-2_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether and how biodiversity and ecosystems-related policy addresses social consequences of biodiversity and ecosystems-related impacts',
    name_es:
      'Explicación de si las políticas relacionadas con la biodiversidad y los ecosistemas abordan las consecuencias sociales de los impactos relacionados con la biodiversidad y los ecosistemas y de qué manera',
    name_pt:
      'Explicação sobre se e como as políticas relacionadas à biodiversidade e aos ecossistemas abordam as consequências sociais da biodiversidade e dos impactos relacionados aos ecossistemas'
  },
  {
    id: 'd6694785-d0b7-45f2-97cd-802933569aac',
    name_dcycle:
      'disclosure_of_how_policy_refers_to_production,_sourcing_or_consumption_of_raw_materials',
    datapoint_id: 'E4-2_07',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how policy refers to production, sourcing or consumption of raw materials',
    name_es:
      'Divulgación de cómo la política se refiere a la producción, el abastecimiento o el consumo de materias primas.',
    name_pt:
      'Divulgação de como a política se refere à produção, fornecimento ou consumo de matérias-primas'
  },
  {
    id: '74efb5c1-6cda-4872-a05c-78bb71b6aa1d',
    name_dcycle:
      'disclosure_of_how_policy_refers_to_policies_limiting_procurement_from_suppliers_that_cannot_demonstrate_that_they_are_not_contributing_to_significant_conversion_of_protected_areas_or_key_biodiversity_areas',
    datapoint_id: 'E4-2_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how policy refers to policies limiting procurement from suppliers that cannot demonstrate that they are not contributing to significant conversion of protected areas or key biodiversity areas',
    name_es:
      'Divulgación de cómo la política se refiere a las políticas que limitan las adquisiciones de proveedores que no pueden demostrar que no están contribuyendo a una conversión significativa de áreas protegidas o áreas clave de biodiversidad.',
    name_pt:
      'Divulgação de como a política se refere a políticas que limitam a aquisição de fornecedores que não conseguem demonstrar que não estão contribuindo para a conversão significativa de áreas protegidas ou áreas-chave de biodiversidade'
  },
  {
    id: '0d32c000-65f7-4aa2-8f83-2a4e2a69ed50',
    name_dcycle:
      'disclosure_of_how_policy_refers_to_recognised_standards_or_third-party_certifications_overseen_by_regulators',
    datapoint_id: 'E4-2_09',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how policy refers to recognised standards or third-party certifications overseen by regulators',
    name_es:
      'Divulgación de cómo la política se refiere a estándares reconocidos o certificaciones de terceros supervisadas por los reguladores.',
    name_pt:
      'Divulgação de como a política se refere a padrões reconhecidos ou certificações de terceiros supervisionadas por reguladores'
  },
  {
    id: 'f2ab7b54-6786-49d6-a4aa-e7a4d1500f6d',
    name_dcycle:
      'disclosure_of_how_policy_addresses_raw_materials_originating_from_ecosystems_that_have_been_managed_to_maintain_or_enhance_conditions_for_biodiversity,_as_demonstrated_by_regular_monitoring_and_reporting_of_biodiversity_status_and_gains_or_losses',
    datapoint_id: 'E4-2_10',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how policy addresses raw materials originating from ecosystems that have been managed to maintain or enhance conditions for biodiversity, as demonstrated by regular monitoring and reporting of biodiversity status and gains or losses',
    name_es:
      'Divulgación de cómo las políticas abordan las materias primas provenientes de ecosistemas que han sido manejados para mantener o mejorar las condiciones para la biodiversidad, como lo demuestra el monitoreo y la presentación de informes periódicos sobre el estado y las ganancias o pérdidas de la biodiversidad.',
    name_pt:
      'Divulgação de como a política aborda as matérias-primas provenientes de ecossistemas que foram geridos para manter ou melhorar as condições para a biodiversidade, conforme demonstrado pela monitorização e relatórios regulares do estado da biodiversidade e dos ganhos ou perdas'
  },
  {
    id: 'ddb16a9a-7707-486f-a8e8-923fc891313e',
    name_dcycle: 'disclosure_of_how_the_policy_enables_to_a),_b),_c)_and_d)',
    datapoint_id: 'E4-2_11',
    esg_category: 'environmental',
    name_en: 'Disclosure of how the policy enables to a), b), c) and d)',
    name_es: 'Divulgación de cómo la póliza permite a), b), c) y d)',
    name_pt: 'Divulgação de como a política permite a), b), c) e d)'
  },
  {
    id: 'e7fa9ef5-0a20-4f86-914a-e5c8ef242fa5',
    name_dcycle:
      'third-party_standard_of_conduct_used_in_policy_is_objective_and_achievable_based_on_scientific_approach_to_identifying_issues_and_realistic_in_assessing_how_these_issues_can_be_addressed_under_variety_of_practical_circumstances',
    datapoint_id: 'E4-2_12',
    esg_category: 'environmental',
    name_en:
      'Third-party standard of conduct used in policy is objective and achievable based on scientific approach to identifying issues and realistic in assessing how these issues can be addressed under variety of practical circumstances',
    name_es:
      'El estándar de conducta de terceros utilizado en las políticas es objetivo y alcanzable basándose en un enfoque científico para identificar problemas y realista al evaluar cómo se pueden abordar estos problemas en una variedad de circunstancias prácticas.',
    name_pt:
      'O padrão de conduta de terceiros usado na política é objetivo e alcançável com base na abordagem científica para identificar problemas e realista na avaliação de como esses problemas podem ser abordados sob diversas circunstâncias práticas'
  },
  {
    id: '9c2668ff-c65e-4d0d-b150-3c03b9de5bcb',
    name_dcycle:
      'third-party_standard_of_conduct_used_in_policy_is_developed_or_maintained_through_process_of_ongoing_consultation_with_relevant_stakeholders_with_balanced_input_from_all_relevant_stakeholder_groups_with_no_group_holding_undue_authority_or_veto_power_over_content',
    datapoint_id: 'E4-2_13',
    esg_category: 'environmental',
    name_en:
      'Third-party standard of conduct used in policy is developed or maintained through process of ongoing consultation with relevant stakeholders with balanced input from all relevant stakeholder groups with no group holding undue authority or veto power over content',
    name_es:
      'El estándar de conducta de terceros utilizado en la política se desarrolla o mantiene a través de un proceso de consulta continua con las partes interesadas relevantes con aportes equilibrados de todos los grupos de partes interesadas relevantes, sin que ningún grupo tenga autoridad indebida o poder de veto sobre el contenido.',
    name_pt:
      'O padrão de conduta de terceiros usado na política é desenvolvido ou mantido por meio de um processo de consulta contínua com as partes interessadas relevantes, com contribuições equilibradas de todos os grupos de partes interessadas relevantes, sem que nenhum grupo detenha autoridade indevida ou poder de veto sobre o conteúdo'
  },
  {
    id: '2f01463e-716c-4d89-9985-7856e0d7e5cf',
    name_dcycle:
      'third-party_standard_of_conduct_used_in_policy_encourages_step-wise_approach_and_continuous_improvement_in_standard_and_its_application_of_better_management_practices_and_requires_establishment_of_meaningful_targets_and_specific_milestones_to_indicate_progress_against_principles_and_criteria_over_time',
    datapoint_id: 'E4-2_14',
    esg_category: 'environmental',
    name_en:
      'Third-party standard of conduct used in policy encourages step-wise approach and continuous improvement in standard and its application of better management practices and requires establishment of meaningful targets and specific milestones to indicate progress against principles and criteria over time',
    name_es:
      'El estándar de conducta de terceros utilizado en la política fomenta un enfoque gradual y una mejora continua en el estándar y su aplicación de mejores prácticas de gestión y requiere el establecimiento de objetivos significativos e hitos específicos para indicar el progreso con respecto a los principios y criterios a lo largo del tiempo.',
    name_pt:
      'O padrão de conduta de terceiros usado na política incentiva a abordagem gradual e a melhoria contínua do padrão e sua aplicação de melhores práticas de gestão e exige o estabelecimento de metas significativas e marcos específicos para indicar o progresso em relação aos princípios e critérios ao longo do tempo'
  },
  {
    id: 'd09ef319-ed5a-43d0-94e9-29c2c7743983',
    name_dcycle:
      'third-party_standard_of_conduct_used_in_policy_is_verifiable_through_independent_certifying_or_verifying_bodies,_which_have_defined_and_rigorous_assessment_procedures_that_avoid_conflicts_of_interest_and_are_compliant_with_iso_guidance_on_accreditation_and_verification_procedures_or_article_5(2)_of_regulation_(ec)_no_765/2008',
    datapoint_id: 'E4-2_15',
    esg_category: 'environmental',
    name_en:
      'Third-party standard of conduct used in policy is verifiable through independent certifying or verifying bodies, which have defined and rigorous assessment procedures that avoid conflicts of interest and are compliant with ISO guidance on accreditation and verification procedures or Article 5(2) of Regulation (EC) No 765/2008',
    name_es:
      'El estándar de conducta de terceros utilizado en la política es verificable a través de organismos certificadores o verificadores independientes, que tienen procedimientos de evaluación definidos y rigurosos que evitan conflictos de intereses y cumplen con la guía ISO sobre procedimientos de acreditación y verificación o el artículo 5(2) del Reglamento ( CE) nº 765/2008',
    name_pt:
      'O padrão de conduta de terceiros utilizado na política é verificável através de organismos independentes de certificação ou verificação, que possuem procedimentos de avaliação definidos e rigorosos que evitam conflitos de interesse e estão em conformidade com as orientações da ISO sobre procedimentos de acreditação e verificação ou com o Artigo 5 (2) do Regulamento ( CE) n.º 765/2008'
  },
  {
    id: 'bd9a39ad-849c-4ea4-9766-4f8a182443e9',
    name_dcycle:
      'third-party_standard_of_conduct_used_in_policy_conforms_to_iseal_code_of_good_practice',
    datapoint_id: 'E4-2_16',
    esg_category: 'environmental',
    name_en:
      'Third-party standard of conduct used in policy conforms to ISEAL Code of Good Practice',
    name_es:
      'El estándar de conducta de terceros utilizado en la política se ajusta al Código de buenas prácticas de ISEAL',
    name_pt:
      'O padrão de conduta de terceiros usado na política está em conformidade com o Código de Boas Práticas da ISEAL'
  },
  {
    id: 'cd0d3276-114a-4889-be55-d7fbf22f5a2f',
    name_dcycle:
      'biodiversity_and_ecosystem_protection_policy_covering_operational_sites_owned,_leased,_managed_in_or_near_protected_area_or_biodiversity-sensitive_area_outside_protected_areas_has_been_adopted',
    datapoint_id: 'E4-2_17',
    esg_category: 'environmental',
    name_en:
      'Biodiversity and ecosystem protection policy covering operational sites owned, leased, managed in or near protected area or biodiversity-sensitive area outside protected areas has been adopted',
    name_es:
      'Se ha adoptado una política de protección de la biodiversidad y los ecosistemas que cubre los sitios operativos de propiedad, arrendados o administrados dentro o cerca de un área protegida o un área sensible a la biodiversidad fuera de las áreas protegidas.',
    name_pt:
      'Foi adotada uma política de proteção da biodiversidade e dos ecossistemas que abrange locais operacionais pertencentes, arrendados ou geridos dentro ou perto de áreas protegidas ou áreas sensíveis à biodiversidade fora das áreas protegidas.'
  },
  {
    id: 'acbdbb0f-84eb-4f08-8a81-dff6a32c4f38',
    name_dcycle: 'sustainable_land_or_agriculture_practices_or_policies_have_been_adopted',
    datapoint_id: 'E4-2_18',
    esg_category: 'environmental',
    name_en: 'Sustainable land or agriculture practices or policies have been adopted',
    name_es:
      'Se han adoptado prácticas o políticas sostenibles en materia de tierras o agricultura.',
    name_pt: 'Práticas ou políticas sustentáveis ​​de terra ou agricultura foram adotadas'
  },
  {
    id: '60989053-9d49-4250-bbde-0f591098995e',
    name_dcycle: 'sustainable_oceans_or_seas_practices_or_policies_have_been_adopted',
    datapoint_id: 'E4-2_19',
    esg_category: 'environmental',
    name_en: 'Sustainable oceans or seas practices or policies have been adopted',
    name_es: 'Se han adoptado prácticas o políticas sostenibles en materia de océanos o mares.',
    name_pt: 'Práticas ou políticas sustentáveis ​​para oceanos ou mares foram adotadas'
  },
  {
    id: 'bf10312f-ba6a-4be7-9a3c-c9ba39342aaf',
    name_dcycle: 'policies_to_address_deforestation_have_been_adopted',
    datapoint_id: 'E4-2_20',
    esg_category: 'environmental',
    name_en: 'Policies to address deforestation have been adopted',
    name_es: 'Se han adoptado políticas para abordar la deforestación',
    name_pt: 'Políticas para enfrentar o desmatamento foram adotadas'
  },
  {
    id: '4f5f3dde-fb07-4786-8224-d324b3d58feb',
    name_dcycle:
      'disclosure_on_how_the_mitigation_hierarchy_has_been_applied_with_regard_to_biodiversity_and_ecosystem_actions',
    datapoint_id: 'E4-3_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure on how the mitigation hierarchy has been applied with regard to biodiversity and ecosystem actions',
    name_es:
      'Divulgación sobre cómo se ha aplicado la jerarquía de mitigación con respecto a las acciones de biodiversidad y ecosistemas.',
    name_pt:
      'Divulgação sobre como a hierarquia de mitigação foi aplicada em relação à biodiversidade e às ações ecossistêmicas'
  },
  {
    id: '2e0d8043-dc76-4df8-acfb-33f0e5ed5bf5',
    name_dcycle: 'biodiversity_offsets_were_used_in_action_plan',
    datapoint_id: 'E4-3_02',
    esg_category: 'environmental',
    name_en: 'Biodiversity offsets were used in action plan',
    name_es: 'Se utilizaron compensaciones de biodiversidad en el plan de acción',
    name_pt: 'Compensações de biodiversidade foram usadas em plano de ação'
  },
  {
    id: 'ae14ac06-4f65-49f0-8b85-111aa774e89a',
    name_dcycle: 'disclosure_of_aim_of_biodiversity_offset_and_key_performance_indicators_used',
    datapoint_id: 'E4-3_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of aim of biodiversity offset and key performance indicators used',
    name_es:
      'Divulgación del objetivo de la compensación por biodiversidad y de los indicadores clave de desempeño utilizados',
    name_pt:
      'Divulgação do objetivo da compensação de biodiversidade e dos principais indicadores de desempenho utilizados'
  },
  {
    id: 'c5a8a3c1-96ba-48c0-a0f9-d7e07cc494f9',
    name_dcycle: 'financing_effects_(direct_and_indirect_costs)_of_biodiversity_offsets',
    datapoint_id: 'E4-3_04',
    esg_category: 'environmental',
    name_en: 'Financing effects (direct and indirect costs) of biodiversity offsets',
    name_es:
      'Efectos financieros (costos directos e indirectos) de las compensaciones de biodiversidad',
    name_pt:
      'Efeitos de financiamento (custos diretos e indiretos) das compensações de biodiversidade'
  },
  {
    id: '038eaeeb-bf0a-4c7c-a690-dc9cd28d7378',
    name_dcycle:
      'explanation_of_rekationship_of_significant_capex_and_opex_required_to_impelement_actions_taken_or_planned_to_relevant_line_items_or_notes_in_the_financial_statements',
    datapoint_id: 'E4-3_05',
    esg_category: 'environmental',
    name_en:
      'Explanation of rekationship of significant Capex and Opex required to impelement actions taken or planned to relevant line items or notes in the financial statements',
    name_es:
      'Explicación de la relación de Capex y Opex significativos necesarios para implementar las acciones tomadas o planificadas para partidas o notas relevantes en los estados financieros.',
    name_pt:
      'Explicação do relacionamento de Capex e Opex significativos necessários para implementar ações tomadas ou planejadas para itens de linha ou notas relevantes nas demonstrações financeiras'
  },
  {
    id: '7faaf206-1c9c-4489-a32e-295cffd3b637',
    name_dcycle:
      'explanation_of_rekationship_of_significant_capex_and_opex_required_to_impelement_actions_taken_or_planned_to_key_performance_indicators_required_under_commission_delegated_regulation_(eu)_2021/2178',
    datapoint_id: 'E4-3_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of rekationship of significant Capex and Opex required to impelement actions taken or planned to key performance indicators required under Commission Delegated Regulation (EU) 2021/2178',
    name_es:
      'Explicación de la relación de Capex y Opex significativos necesarios para implementar las acciones tomadas o planificadas para los indicadores clave de rendimiento requeridos en virtud del Reglamento Delegado (UE) 2021/2178 de la Comisión.',
    name_pt:
      'Explicação da relação de Capex e Opex significativos necessários para implementar ações tomadas ou planejadas para os principais indicadores de desempenho exigidos pelo Regulamento Delegado (UE) 2021/2178 da Comissão'
  },
  {
    id: '1d5d9873-6713-4057-ba4b-1ada93a7b3b7',
    name_dcycle:
      'explanation_of_rekationship_of_significant_capex_and_opex_required_to_impelement_actions_taken_or_planned_to_capex_plan_required_under_commission_delegated_regulation_(eu)_2021/2178',
    datapoint_id: 'E4-3_07',
    esg_category: 'environmental',
    name_en:
      'Explanation of rekationship of significant Capex and Opex required to impelement actions taken or planned to Capex plan required under Commission Delegated Regulation (EU) 2021/2178',
    name_es:
      'Explicación de la relación de Capex y Opex significativos necesarios para implementar las acciones tomadas o planificadas según el plan de Capex requerido según el Reglamento Delegado (UE) 2021/2178 de la Comisión.',
    name_pt:
      'Explicação do relacionamento de Capex e Opex significativos necessários para implementar as ações tomadas ou planejadas para o plano de Capex exigido pelo Regulamento Delegado da Comissão (UE) 2021/2178'
  },
  {
    id: '7d8ff2cd-c2fb-4000-ae2d-de820a0a74e1',
    name_dcycle: 'description_of_biodiversity_offsets',
    datapoint_id: 'E4-3_08',
    esg_category: 'environmental',
    name_en: 'Description of biodiversity offsets',
    name_es: 'Descripción de las compensaciones por biodiversidad',
    name_pt: 'Descrição das compensações de biodiversidade'
  },
  {
    id: '18ce219b-8b46-4395-9833-39b1583ea8fd',
    name_dcycle:
      'description_of_whether_and_how_local_and_indigenous_knowledge_and_nature-based_solutions_have_been_incorporated_into_biodiversity_and_ecosystems-related_action',
    datapoint_id: 'E4-3_09',
    esg_category: 'environmental',
    name_en:
      'Description of whether and how local and indigenous knowledge and nature-based solutions have been incorporated into biodiversity and ecosystems-related action',
    name_es:
      'Descripción de si y cómo se han incorporado los conocimientos locales e indígenas y las soluciones basadas en la naturaleza en las acciones relacionadas con la biodiversidad y los ecosistemas.',
    name_pt:
      'Descrição de se e como o conhecimento local e indígena e as soluções baseadas na natureza foram incorporados na biodiversidade e nas ações relacionadas aos ecossistemas'
  },
  {
    id: 'f194c8fd-47a2-4d7b-96ec-52b7f56ee927',
    name_dcycle:
      'disclosure_of_key_stakeholders_involved_and_how_they_are_involved,_key_stakeholders_negatively_or_positively_impacted_by_action_and_how_they_are_impacted',
    datapoint_id: 'E4-3_10',
    esg_category: 'environmental',
    name_en:
      'Disclosure of key stakeholders involved and how they are involved, key stakeholders negatively or positively impacted by action and how they are impacted',
    name_es:
      'Divulgación de las partes interesadas clave involucradas y cómo participan, las partes interesadas clave afectadas negativa o positivamente por la acción y cómo se ven afectadas',
    name_pt:
      'Divulgação das principais partes interessadas envolvidas e como elas estão envolvidas, das principais partes interessadas impactadas negativa ou positivamente pela ação e como elas são impactadas'
  },
  {
    id: '8621a368-d0d8-43c9-8e9a-deff76bfad69',
    name_dcycle:
      'explanation_of_need_for_appropriate_consultations_and_need_to_respect_decisions_of_affected_communities',
    datapoint_id: 'E4-3_11',
    esg_category: 'environmental',
    name_en:
      'Explanation of need for appropriate consultations and need to respect decisions of affected communities',
    name_es:
      'Explicación de la necesidad de realizar consultas apropiadas y de respetar las decisiones de las comunidades afectadas',
    name_pt:
      'Explicação da necessidade de consultas apropriadas e da necessidade de respeitar as decisões das comunidades afetadas'
  },
  {
    id: 'cb6ad02e-eb34-4e49-8642-62d29f1ebcdf',
    name_dcycle:
      'description_of_whether_key_action_may_induce_significant_negative_sustainability_impacts_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-3_12',
    esg_category: 'environmental',
    name_en:
      'Description of whether key action may induce significant negative sustainability impacts (biodiversity and ecosystems)',
    name_es:
      'Descripción de si una acción clave puede inducir impactos negativos significativos en la sostenibilidad (biodiversidad y ecosistemas)',
    name_pt:
      'Descrição se a ação-chave pode induzir impactos negativos significativos na sustentabilidade (biodiversidade e ecossistemas)'
  },
  {
    id: 'd903a26e-56d3-4bc4-b12e-ee685433c734',
    name_dcycle:
      'explanation_of_whether_the_key_action_is_intended_to_be_a_one-time_initiative_or_systematic_practice',
    datapoint_id: 'E4-3_13',
    esg_category: 'environmental',
    name_en:
      'Explanation of whether the key action is intended to be a one-time initiative or systematic practice',
    name_es:
      'Explicación de si la acción clave pretende ser una iniciativa única o una práctica sistemática',
    name_pt:
      'Explicação sobre se a ação-chave se destina a ser uma iniciativa única ou uma prática sistemática'
  },
  {
    id: '6d83f94a-f71f-4f97-ab46-1c5866b4131f',
    name_dcycle:
      'key_action_plan_is_carried_out_only_by_undertaking_(individual_action)_using_its_resources_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-3_14',
    esg_category: 'environmental',
    name_en:
      'Key action plan is carried out only by undertaking (individual action) using its resources (biodiversity and ecosystems)',
    name_es:
      'El plan de acción clave se lleva a cabo únicamente mediante la realización (acción individual) utilizando sus recursos (biodiversidad y ecosistemas).',
    name_pt:
      'O plano de ação principal é executado apenas através da realização (ação individual) utilizando os seus recursos (biodiversidade e ecossistemas)'
  },
  {
    id: 'a3181e93-ff88-42bb-b8ea-cdd73eb02262',
    name_dcycle:
      'key_action_plan_is_part_of_wider_action_plan_(collective_action),_of_which_undertaking_is_member_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-3_15',
    esg_category: 'environmental',
    name_en:
      'Key action plan is part of wider action plan (collective action), of which undertaking is member (biodiversity and ecosystems)',
    name_es:
      'El plan de acción clave forma parte de un plan de acción más amplio (acción colectiva) del que la empresa es miembro (biodiversidad y ecosistemas).',
    name_pt:
      'O plano de ação-chave faz parte de um plano de ação mais amplo (ação coletiva), do qual a empresa é membro (biodiversidade e ecossistemas)'
  },
  {
    id: '843c5bdd-48db-41fd-b60f-d07381aac9e2',
    name_dcycle:
      'additional_information_about_project,_its_sponsors_and_other_participants_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-3_16',
    esg_category: 'environmental',
    name_en:
      'Additional information about project, its sponsors and other participants (biodiversity and ecosystems)',
    name_es:
      'Información adicional sobre el proyecto, sus patrocinadores y otros participantes (biodiversidad y ecosistemas)',
    name_pt:
      'Informações adicionais sobre o projeto, seus patrocinadores e demais participantes (biodiversidade e ecossistemas)'
  },
  {
    id: 'f819476e-58d0-4e1c-8de0-28d12c8b9d2c',
    name_dcycle:
      'ecological_threshold_and_allocation_of_impacts_to_undertaking_were_applied_when_setting_target_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-4_01',
    esg_category: 'environmental',
    name_en:
      'Ecological threshold and allocation of impacts to undertaking were applied when setting target (biodiversity and ecosystems)',
    name_es:
      'El umbral ecológico y la asignación de impactos a la empresa se aplicaron al establecer el objetivo (biodiversidad y ecosistemas)',
    name_pt:
      'O limite ecológico e a alocação de impactos ao empreendimento foram aplicados na definição da meta (biodiversidade e ecossistemas)'
  },
  {
    id: 'cfeb56f0-8b90-4da9-b387-1560cc1c2952',
    name_dcycle:
      'disclosure_of_ecological_threshold_identified_and_methodology_used_to_identify_threshold_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-4_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of ecological threshold identified and methodology used to identify threshold (biodiversity and ecosystems)',
    name_es:
      'Divulgación del umbral ecológico identificado y metodología utilizada para identificar el umbral (biodiversidad y ecosistemas)',
    name_pt:
      'Divulgação do limiar ecológico identificado e metodologia utilizada para identificar o limiar (biodiversidade e ecossistemas)'
  },
  {
    id: 'f4aa7ffe-5c46-4813-93fd-88b56d6929ad',
    name_dcycle:
      'disclosure_of_how_entity-specific_threshold_was_determined_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-4_03',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how entity-specific threshold was determined (biodiversity and ecosystems)',
    name_es:
      'Divulgación de cómo se determinó el umbral específico de la entidad (biodiversidad y ecosistemas)',
    name_pt:
      'Divulgação de como o limite específico da entidade foi determinado (biodiversidade e ecossistemas)'
  },
  {
    id: '34fe4454-134c-44f6-96a9-63de6788167a',
    name_dcycle:
      'disclosure_of_how_responsibility_for_respecting_identified_ecological_threshold_is_allocated_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-4_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how responsibility for respecting identified ecological threshold is allocated (biodiversity and ecosystems)',
    name_es:
      'Divulgación de cómo se asigna la responsabilidad de respetar el umbral ecológico identificado (biodiversidad y ecosistemas)',
    name_pt:
      'Divulgação de como é atribuída a responsabilidade pelo respeito do limiar ecológico identificado (biodiversidade e ecossistemas)'
  },
  {
    id: 'deceb1f8-eeaf-4d32-9aa5-b0267f476b59',
    name_dcycle: 'target_is_informed_by_relevant_aspect_of_eu_biodiversity_strategy_for_2030',
    datapoint_id: 'E4-4_05',
    esg_category: 'environmental',
    name_en: 'Target is informed by relevant aspect of EU Biodiversity Strategy for 2030',
    name_es:
      'El objetivo se basa en aspectos relevantes de la Estrategia de Biodiversidad de la UE para 2030',
    name_pt:
      'A meta é informada por aspectos relevantes da Estratégia de Biodiversidade da UE para 2030'
  },
  {
    id: '5347bc2f-dfc7-438a-a868-50fc2ed0c5fb',
    name_dcycle:
      'disclosure_of_how_the_targets_relate_to_the_biodiversity_and_ecosystem_impacts,_dependencies,_risks_and_opportunities_identified_in_relation_to_own_operations_and_upstream_and_downstream_value_chain',
    datapoint_id: 'E4-4_06',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how the targets relate to the biodiversity and ecosystem impacts, dependencies, risks and opportunities identified in relation to own operations and upstream and downstream value chain',
    name_es:
      'Divulgación de cómo los objetivos se relacionan con los impactos, dependencias, riesgos y oportunidades de la biodiversidad y los ecosistemas identificados en relación con las operaciones propias y la cadena de valor upstream y downstream.',
    name_pt:
      'Divulgação de como as metas se relacionam com os impactos, dependências, riscos e oportunidades na biodiversidade e nos ecossistemas identificados em relação às próprias operações e à cadeia de valor a montante e a jusante'
  },
  {
    id: '669f6181-199a-4afd-b2a3-315a980cd0f2',
    name_dcycle: 'disclosure_of_the_geographical_scope_of_the_targets',
    datapoint_id: 'E4-4_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of the geographical scope of the targets',
    name_es: 'Divulgación del alcance geográfico de los objetivos',
    name_pt: 'Divulgação do âmbito geográfico dos alvos'
  },
  {
    id: '91b59f57-1d83-4a03-87e9-0a26d31a4c91',
    name_dcycle: 'biodiversity_offsets_were_used_in_setting_target',
    datapoint_id: 'E4-4_08',
    esg_category: 'environmental',
    name_en: 'Biodiversity offsets were used in setting target',
    name_es: 'Se utilizaron compensaciones de biodiversidad para establecer objetivos.',
    name_pt: 'As compensações de biodiversidade foram usadas na definição de metas'
  },
  {
    id: '4f129dd6-9850-42a3-8267-126088cdb446',
    name_dcycle:
      'layer_in_mitigation_hierarchy_to_which_target_can_be_allocated_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-4_09',
    esg_category: 'environmental',
    name_en:
      'Layer in mitigation hierarchy to which target can be allocated (biodiversity and ecosystems)',
    name_es:
      'Capa en la jerarquía de mitigación a la que se puede asignar el objetivo (biodiversidad y ecosistemas)',
    name_pt:
      'Camada na hierarquia de mitigação à qual a meta pode ser alocada (biodiversidade e ecossistemas)'
  },
  {
    id: '2789cb31-2c7f-4f7d-af7d-3a9de5e47a33',
    name_dcycle:
      'the_target_addresses_shortcomings_related_to_the_substantial_contribution_criteria',
    datapoint_id: 'E4-4_10',
    esg_category: 'environmental',
    name_en: 'The target addresses shortcomings related to the Substantial Contribution criteria',
    name_es:
      'La meta aborda las deficiencias relacionadas con los criterios de contribución sustancial.',
    name_pt: 'A meta aborda deficiências relacionadas com os critérios de contribuição substancial'
  },
  {
    id: '64285ec6-652d-4191-89dd-4a30b10ffd52',
    name_dcycle:
      'number_of_sites_owned,_leased_or_managed_in_or_near_protected_areas_or_key_biodiversity_areas_that_undertaking_is_negatively_affecting',
    datapoint_id: 'E4-5_01',
    esg_category: 'environmental',
    name_en:
      'Number of sites owned, leased or managed in or near protected areas or key biodiversity areas that undertaking is negatively affecting',
    name_es:
      'Número de sitios de propiedad, arrendados o administrados en o cerca de áreas protegidas o áreas clave de biodiversidad que la iniciativa está afectando negativamente',
    name_pt:
      'Número de locais pertencentes, arrendados ou geridos dentro ou perto de áreas protegidas ou áreas chave de biodiversidade que o empreendimento está a afectar negativamente'
  },
  {
    id: '61894b55-be50-438e-acc3-0e5898ada50d',
    name_dcycle:
      'area_of_sites_owned,_leased_or_managed_in_or_near_protected_areas_or_key_biodiversity_areas_that_undertaking_is_negatively_affecting',
    datapoint_id: 'E4-5_02',
    esg_category: 'environmental',
    name_en:
      'Area of sites owned, leased or managed in or near protected areas or key biodiversity areas that undertaking is negatively affecting',
    name_es:
      'Área de sitios de propiedad, arrendados o administrados en o cerca de áreas protegidas o áreas clave de biodiversidad que la empresa está afectando negativamente',
    name_pt:
      'Área de locais pertencentes, arrendados ou administrados dentro ou perto de áreas protegidas ou áreas-chave de biodiversidade que o empreendimento está afetando negativamente'
  },
  {
    id: 'e35bb50c-5158-46ed-bb84-e002de9b3619',
    name_dcycle: 'disclosure_of_land-use_based_on_life_cycle_assessment',
    datapoint_id: 'E4-5_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of land-use based on Life Cycle Assessment',
    name_es: 'Divulgación del uso de la tierra basada en la Evaluación del Ciclo de Vida',
    name_pt: 'Divulgação do uso da terra com base na Avaliação do Ciclo de Vida'
  },
  {
    id: '4673020c-f726-4090-bae8-c521b591a539',
    name_dcycle:
      'disclosure_of_metrics_considered_relevant_(land-use_change,_freshwater-use_change_and_(or)_sea-use_change)',
    datapoint_id: 'E4-5_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of metrics considered relevant (land-use change, freshwater-use change and (or) sea-use change)',
    name_es:
      'Divulgación de métricas consideradas relevantes (cambio de uso de la tierra, cambio de uso de agua dulce y (o) cambio de uso del mar)',
    name_pt:
      'Divulgação de métricas consideradas relevantes (mudança no uso do solo, mudança no uso da água doce e (ou) mudança no uso do mar)'
  },
  {
    id: '95404bcd-5402-4178-82cc-7ce15301113a',
    name_dcycle: 'disclosure_of_conversion_over_time_of_land_cover',
    datapoint_id: 'E4-5_05',
    esg_category: 'environmental',
    name_en: 'Disclosure of conversion over time of land cover',
    name_es: 'Divulgación de la conversión a lo largo del tiempo de la cobertura terrestre',
    name_pt: 'Divulgação da conversão ao longo do tempo da cobertura do solo'
  },
  {
    id: '06cd8867-0c85-4a67-8152-51990c818f61',
    name_dcycle: 'disclosure_of_changes_over_time_in_management_of_ecosystem',
    datapoint_id: 'E4-5_06',
    esg_category: 'environmental',
    name_en: 'Disclosure of changes over time in management of ecosystem',
    name_es: 'Divulgación de cambios a lo largo del tiempo en la gestión del ecosistema.',
    name_pt: 'Divulgação de mudanças ao longo do tempo na gestão do ecossistema'
  },
  {
    id: '4f239e58-ac0c-42ae-83ec-03761f70f4d7',
    name_dcycle: 'disclosure_of_changes_in_spatial_configuration_of_landscape',
    datapoint_id: 'E4-5_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of changes in spatial configuration of landscape',
    name_es: 'Divulgación de cambios en la configuración espacial del paisaje.',
    name_pt: 'Divulgação de mudanças na configuração espacial da paisagem'
  },
  {
    id: '63152bdd-f5b8-4510-9188-23c4ab557a93',
    name_dcycle: 'disclosure_of_changes_in_ecosystem_structural_connectivity',
    datapoint_id: 'E4-5_08',
    esg_category: 'environmental',
    name_en: 'Disclosure of changes in ecosystem structural connectivity',
    name_es: 'Divulgación de cambios en la conectividad estructural de los ecosistemas.',
    name_pt: 'Divulgação de mudanças na conectividade estrutural do ecossistema'
  },
  {
    id: '3d32ca47-74dc-49eb-b7a9-bc6a0be090d8',
    name_dcycle: 'disclosure_of_functional_connectivity',
    datapoint_id: 'E4-5_09',
    esg_category: 'environmental',
    name_en: 'Disclosure of functional connectivity',
    name_es: 'Divulgación de conectividad funcional.',
    name_pt: 'Divulgação de conectividade funcional'
  },
  {
    id: '3317997a-dfcf-42cc-bdee-3b08c1ac0f32',
    name_dcycle: 'total_use_of_land_area',
    datapoint_id: 'E4-5_10',
    esg_category: 'environmental',
    name_en: 'Total use of land area',
    name_es: 'Uso total de la superficie terrestre',
    name_pt: 'Uso total da área do terreno'
  },
  {
    id: 'd527a3cc-35fb-4dd6-b310-a22ff66685bc',
    name_dcycle: 'total_sealed_area',
    datapoint_id: 'E4-5_11',
    esg_category: 'environmental',
    name_en: 'Total sealed area',
    name_es: 'Área total sellada',
    name_pt: 'Área total selada'
  },
  {
    id: 'ba9ac85a-b0bc-4dcb-a2a6-a0c0abadc6c0',
    name_dcycle: 'nature-oriented_area_on_site',
    datapoint_id: 'E4-5_12',
    esg_category: 'environmental',
    name_en: 'Nature-oriented area on site',
    name_es: 'Área orientada a la naturaleza en el sitio',
    name_pt: 'Área voltada para a natureza no local'
  },
  {
    id: '35502676-8aee-4b5f-898e-e64950e18449',
    name_dcycle: 'nature-oriented_area_off_site',
    datapoint_id: 'E4-5_13',
    esg_category: 'environmental',
    name_en: 'Nature-oriented area off site',
    name_es: 'Área orientada a la naturaleza fuera del sitio',
    name_pt: 'Área voltada para a natureza fora do local'
  },
  {
    id: 'da3456a5-f121-4a48-9cc6-75b89f4628f6',
    name_dcycle:
      'disclosure_of_how_pathways_of_introduction_and_spread_of_invasive_alien_species_and_risks_posed_by_invasive_alien_species_are_managed',
    datapoint_id: 'E4-5_14',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how pathways of introduction and spread of invasive alien species and risks posed by invasive alien species are managed',
    name_es:
      'Divulgación de cómo se gestionan las vías de introducción y propagación de especies exóticas invasoras y los riesgos que plantean las especies exóticas invasoras.',
    name_pt:
      'Divulgação de como são gerenciadas as vias de introdução e disseminação de espécies exóticas invasoras e os riscos representados por espécies exóticas invasoras'
  },
  {
    id: 'd43ce070-47f0-41dc-a1bc-533f7d2d6835',
    name_dcycle: 'number_of_invasive_alien_species',
    datapoint_id: 'E4-5_15',
    esg_category: 'environmental',
    name_en: 'Number of invasive alien species',
    name_es: 'Número de especies exóticas invasoras',
    name_pt: 'Número de espécies exóticas invasoras'
  },
  {
    id: '7667c0e1-b1bf-4946-96ab-f9073c57f9b8',
    name_dcycle: 'area_covered_by_invasive_alien_species',
    datapoint_id: 'E4-5_16',
    esg_category: 'environmental',
    name_en: 'Area covered by invasive alien species',
    name_es: 'Área cubierta por especies exóticas invasoras',
    name_pt: 'Área coberta por espécies exóticas invasoras'
  },
  {
    id: '0abb4464-2db3-45f1-9c34-ef1107920fe3',
    name_dcycle: 'disclosure_of_metrics_considered_relevant_(state_of_species)',
    datapoint_id: 'E4-5_17',
    esg_category: 'environmental',
    name_en: 'Disclosure of metrics considered relevant (state of species)',
    name_es: 'Divulgación de métricas consideradas relevantes (estado de especies)',
    name_pt: 'Divulgação de métricas consideradas relevantes (estado da espécie)'
  },
  {
    id: 'af4dd3fa-ca51-4c25-adbe-375cc76a746b',
    name_dcycle:
      'disclosure_of_paragraph_in_another_environment-related_standard_in_which_metric_is_referred_to',
    datapoint_id: 'E4-5_18',
    esg_category: 'environmental',
    name_en:
      'Disclosure of paragraph in another environment-related standard in which metric is referred to',
    name_es:
      'Divulgación de un párrafo en otra norma relacionada con el medio ambiente en el que se hace referencia a la métrica',
    name_pt:
      'Divulgação de parágrafo em outra norma relacionada ao meio ambiente em que a métrica é referida'
  },
  {
    id: '527d2d3b-2430-45ee-a5fa-5bd27ebd6816',
    name_dcycle:
      'disclosure_of_population_size,_range_within_specific_ecosystems_and_extinction_risk',
    datapoint_id: 'E4-5_19',
    esg_category: 'environmental',
    name_en: 'Disclosure of population size, range within specific ecosystems and extinction risk',
    name_es:
      'Divulgación del tamaño de la población, rango dentro de ecosistemas específicos y riesgo de extinción.',
    name_pt:
      'Divulgação do tamanho da população, distribuição dentro de ecossistemas específicos e risco de extinção'
  },
  {
    id: 'c89d48b6-6521-40e8-b1a9-ce6cc49fb235',
    name_dcycle: 'disclosure_of_changes_in_number_of_individuals_of_species_within_specific_area',
    datapoint_id: 'E4-5_20',
    esg_category: 'environmental',
    name_en: 'Disclosure of changes in number of individuals of species within specific area',
    name_es:
      'Divulgación de cambios en el número de individuos de especies dentro de un área específica',
    name_pt:
      'Divulgação de mudanças no número de indivíduos de espécies dentro de uma área específica'
  },
  {
    id: '2844a793-4369-4e9e-9bd0-03fc543e444c',
    name_dcycle: 'information_about_species_at_global_extinction_risk',
    datapoint_id: 'E4-5_21',
    esg_category: 'environmental',
    name_en: 'Information about species at global extinction risk',
    name_es: 'Información sobre especies en riesgo de extinción global',
    name_pt: 'Informações sobre espécies em risco de extinção global'
  },
  {
    id: 'f4000245-1ef7-4c29-b674-d26cd5045db3',
    name_dcycle:
      'disclosure_of_threat_status_of_species_and_how_activities_or_pressures_may_affect_threat_status',
    datapoint_id: 'E4-5_22',
    esg_category: 'environmental',
    name_en:
      'Disclosure of threat status of species and how activities or pressures may affect threat status',
    name_es:
      'Divulgación del estado de amenaza de las especies y cómo las actividades o presiones pueden afectar el estado de amenaza',
    name_pt:
      'Divulgação do estado de ameaça das espécies e como as atividades ou pressões podem afetar o estado de ameaça'
  },
  {
    id: '712bd3cb-4b25-4521-989d-88a417db7d35',
    name_dcycle:
      "disclosure_of_change_in_relevant_habitat_for_threatened_species_as_proxy_for_impact_on_local_population's_extinction_risk",
    datapoint_id: 'E4-5_23',
    esg_category: 'environmental',
    name_en:
      "Disclosure of change in relevant habitat for threatened species as proxy for impact on local population's extinction risk",
    name_es:
      'Divulgación de cambios en el hábitat relevante para especies amenazadas como indicador del impacto en el riesgo de extinción de la población local.',
    name_pt:
      'Divulgação de mudanças no habitat relevante para espécies ameaçadas como proxy do impacto no risco de extinção da população local'
  },
  {
    id: 'e4410e44-5604-4eaa-b3f7-db5e2bf46173',
    name_dcycle: 'disclosure_of_ecosystem_area_coverage',
    datapoint_id: 'E4-5_24',
    esg_category: 'environmental',
    name_en: 'Disclosure of ecosystem area coverage',
    name_es: 'Divulgación de la cobertura del área del ecosistema',
    name_pt: 'Divulgação da cobertura da área do ecossistema'
  },
  {
    id: 'a7fd57ef-a630-4ca2-aebc-e533c2303cf0',
    name_dcycle: 'disclosure_of_quality_of_ecosystems_relative_to_pre-determined_reference_state',
    datapoint_id: 'E4-5_25',
    esg_category: 'environmental',
    name_en: 'Disclosure of quality of ecosystems relative to pre-determined reference state',
    name_es:
      'Divulgación de la calidad de los ecosistemas en relación con el estado de referencia predeterminado',
    name_pt:
      'Divulgação da qualidade dos ecossistemas em relação ao estado de referência pré-determinado'
  },
  {
    id: 'a549a2dc-90a1-4aeb-981c-3a7a61b3dce5',
    name_dcycle: 'disclosure_of_multiple_species_within_ecosystem',
    datapoint_id: 'E4-5_26',
    esg_category: 'environmental',
    name_en: 'Disclosure of multiple species within ecosystem',
    name_es: 'Divulgación de múltiples especies dentro del ecosistema.',
    name_pt: 'Divulgação de múltiplas espécies dentro do ecossistema'
  },
  {
    id: 'cd885fbf-aa6f-4135-a59d-0d7d04c5d3f5',
    name_dcycle: 'disclosure_of_structural_components_of_ecosystem_condition',
    datapoint_id: 'E4-5_27',
    esg_category: 'environmental',
    name_en: 'Disclosure of structural components of ecosystem condition',
    name_es: 'Divulgación de los componentes estructurales de la condición del ecosistema.',
    name_pt: 'Divulgação dos componentes estruturais da condição do ecossistema'
  },
  {
    id: '47368f06-8a89-401f-ac41-ce3cd548f8cd',
    name_dcycle:
      'disclosure_of_quantitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_biodiversity-_and_ecosystem-related_impacts_and_dependencies',
    datapoint_id: 'E4-6_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of quantitative information about anticipated financial effects of material risks and opportunities arising from biodiversity- and ecosystem-related impacts and dependencies',
    name_es:
      'Divulgación de información cuantitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos y dependencias relacionados con la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação de informações quantitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos e dependências relacionados à biodiversidade e ao ecossistema'
  },
  {
    id: '3a73b6d7-5424-4783-9be4-94db8d0a2649',
    name_dcycle:
      'disclosure_of_qualitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_biodiversity-_and_ecosystem-related_impacts_and_dependencies',
    datapoint_id: 'E4-6_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of qualitative information about anticipated financial effects of material risks and opportunities arising from biodiversity- and ecosystem-related impacts and dependencies',
    name_es:
      'Divulgación de información cualitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen de los impactos y dependencias relacionados con la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação de informações qualitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes de impactos e dependências relacionados à biodiversidade e ao ecossistema'
  },
  {
    id: '7fb91029-7ba5-4be8-83a7-b076c2c282c4',
    name_dcycle:
      'description_of_effects_considered,_related_impacts_and_dependencies_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-6_03',
    esg_category: 'environmental',
    name_en:
      'Description of effects considered, related impacts and dependencies (biodiversity and ecosystems)',
    name_es:
      'Descripción de los efectos considerados, impactos relacionados y dependencias (biodiversidad y ecosistemas)',
    name_pt:
      'Descrição dos efeitos considerados, impactos relacionados e dependências (biodiversidade e ecossistemas)'
  },
  {
    id: 'bc1f68c4-a017-4172-97e2-8d0536d4d860',
    name_dcycle:
      'disclosure_of_critical_assumptions_used_in_estimates_of_financial_effects_of_material_risks_and_opportunities_arising_from_biodiversity-_and_ecosystem-related_impacts_and_dependencies',
    datapoint_id: 'E4-6_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of critical assumptions used in estimates of financial effects of material risks and opportunities arising from biodiversity- and ecosystem-related impacts and dependencies',
    name_es:
      'Divulgación de supuestos críticos utilizados en las estimaciones de los efectos financieros de los riesgos y oportunidades materiales que surgen de los impactos y dependencias relacionados con la biodiversidad y los ecosistemas.',
    name_pt:
      'Divulgação de premissas críticas usadas nas estimativas de efeitos financeiros de riscos e oportunidades materiais decorrentes de impactos e dependências relacionados à biodiversidade e ao ecossistema'
  },
  {
    id: 'b3fbd8cf-d45f-4e67-98a7-54a943be587a',
    name_dcycle:
      'description_of_related_products_and_services_at_risk_(biodiversity_and_ecosystems)_over_the_short-,_medium-_and_long-term',
    datapoint_id: 'E4-6_05',
    esg_category: 'environmental',
    name_en:
      'Description of related products and services at risk (biodiversity and ecosystems) over the short-, medium- and long-term',
    name_es:
      'Descripción de productos y servicios relacionados en riesgo (biodiversidad y ecosistemas) a corto, mediano y largo plazo.',
    name_pt:
      'Descrição dos produtos e serviços relacionados em risco (biodiversidade e ecossistemas) no curto, médio e longo prazo'
  },
  {
    id: 'fca8602a-a46e-4711-b537-466de1e5618f',
    name_dcycle:
      'explanation_of_how_financial_amounts_are_estimated_and_critical_assumptions_made_(biodiversity_and_ecosystems)',
    datapoint_id: 'E4-6_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of how financial amounts are estimated and critical assumptions made (biodiversity and ecosystems)',
    name_es:
      'Explicación de cómo se estiman los montos financieros y se hacen los supuestos críticos (biodiversidad y ecosistemas)',
    name_pt:
      'Explicação de como os valores financeiros são estimados e as suposições críticas são feitas (biodiversidade e ecossistemas)'
  },
  {
    id: '16e011c8-a7f6-4f82-a519-6a87607d1816',
    name_dcycle:
      'disclosure_of_whether_the_undertaking_has_screened_its_assets_and_activities_in_order_to_identify_actual_and_potential_impacts,_risks_and_opportunities_in_own_operations_and_upstream_and_downstream_value_chain,_and_if_so,_methodologies,_assumptions_and_tools_used',
    datapoint_id: 'E5.IRO-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether the undertaking has screened its assets and activities in order to identify actual and potential impacts, risks and opportunities in own operations and upstream and downstream value chain, and if so, methodologies, assumptions and tools used',
    name_es:
      'Divulgación de si la empresa ha examinado sus activos y actividades para identificar impactos, riesgos y oportunidades reales y potenciales en sus propias operaciones y en la cadena de valor ascendente y descendente y, en caso afirmativo, metodologías, supuestos y herramientas utilizadas.',
    name_pt:
      'Divulgação sobre se a empresa examinou os seus ativos e atividades, a fim de identificar impactos, riscos e oportunidades reais e potenciais nas próprias operações e na cadeia de valor a montante e a jusante e, em caso afirmativo, metodologias, pressupostos e ferramentas utilizadas'
  },
  {
    id: '4acd5b7c-3d4d-43e9-b0c8-73c83bf424dc',
    name_dcycle:
      'disclosure_of_whether_and_how_the_undertaking_has_conducted_consultations_(resource_and_circular_economy)',
    datapoint_id: 'E5.IRO-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how the undertaking has conducted consultations (resource and circular economy)',
    name_es:
      'Divulgación de si la empresa ha realizado consultas y cómo (recursos y economía circular)',
    name_pt: 'Divulgação de se e como a empresa realizou consultas (recursos e economia circular)'
  },
  {
    id: 'b15b3947-0b17-4d9c-9d6f-8342f4c6b05e',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_addresses_transitioning_away_from_use_of_virgin_resources,_including_relative_increases_in_use_of_secondary_(recycled)_resources',
    datapoint_id: 'E5-1_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy addresses transitioning away from use of virgin resources, including relative increases in use of secondary (recycled) resources',
    name_es:
      'Divulgación de si la política aborda la transición hacia el abandono del uso de recursos vírgenes, incluidos los aumentos relativos en el uso de recursos secundarios (reciclados), y de qué manera.',
    name_pt:
      'Divulgação sobre se e como a política aborda a transição do uso de recursos virgens, incluindo aumentos relativos no uso de recursos secundários (reciclados)'
  },
  {
    id: '12fa4c4c-4d64-4b10-963e-2110df4dbeb1',
    name_dcycle:
      'disclosure_of_whether_and_how_policy_addresses_sustainable_sourcing_and_use_of_renewable_resources',
    datapoint_id: 'E5-1_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of whether and how policy addresses sustainable sourcing and use of renewable resources',
    name_es:
      'Divulgación de si la política aborda el abastecimiento y el uso sostenible de recursos renovables y cómo lo hace.',
    name_pt:
      'Divulgação sobre se e como a política aborda o fornecimento e uso sustentável de recursos renováveis'
  },
  {
    id: '5852b4b1-0bda-419c-aa77-e5341860ce90',
    name_dcycle:
      'description_of_whether_and_how_policy_addresses_waste_hierarchy_(prevention,_preparing_for_re-use,_recycling,_other_recovery,_disposal)',
    datapoint_id: 'E5-1_03',
    esg_category: 'environmental',
    name_en:
      'Description of whether and how policy addresses waste hierarchy (prevention, preparing for re-use, recycling, other recovery, disposal)',
    name_es:
      'Descripción de si la política aborda la jerarquía de residuos y de qué manera (prevención, preparación para la reutilización, reciclaje, otras recuperaciones, eliminación)',
    name_pt:
      'Descrição de se e como a política aborda a hierarquia de resíduos (prevenção, preparação para reutilização, reciclagem, outras recuperações, eliminação)'
  },
  {
    id: '3d9e70ca-9fc2-40fc-886e-c8ed30c30481',
    name_dcycle:
      'description_of_whether_and_how_policy_addresses_prioritisation_of_strategies_to_avoid_or_minimise_waste_over_waste_treatment_strategies',
    datapoint_id: 'E5-1_04',
    esg_category: 'environmental',
    name_en:
      'Description of whether and how policy addresses prioritisation of strategies to avoid or minimise waste over waste treatment strategies',
    name_es:
      'Descripción de si la política aborda la priorización de estrategias para evitar o minimizar los residuos frente a las estrategias de tratamiento de residuos, y cómo hacerlo',
    name_pt:
      'Descrição de se e como a política aborda a priorização de estratégias para evitar ou minimizar resíduos em detrimento de estratégias de tratamento de resíduos'
  },
  {
    id: 'ca6526fa-ba68-4c72-80e5-f6d674c72d53',
    name_dcycle:
      'description_of_higher_levels_of_resource_efficiency_in_use_of_technical_and_biological_materials_and_water',
    datapoint_id: 'E5-2_01',
    esg_category: 'environmental',
    name_en:
      'Description of higher levels of resource efficiency in use of technical and biological materials and water',
    name_es:
      'Descripción de niveles más altos de eficiencia de recursos en el uso de materiales técnicos y biológicos y agua.',
    name_pt:
      'Descrição de níveis mais elevados de eficiência de recursos no uso de materiais técnicos e biológicos e água'
  },
  {
    id: '10c59b73-7434-480d-a1c3-4c1edd47201c',
    name_dcycle: 'description_of_higher_rates_of_use_of_secondary_raw_materials',
    datapoint_id: 'E5-2_02',
    esg_category: 'environmental',
    name_en: 'Description of higher rates of use of secondary raw materials',
    name_es: 'Descripción de tasas más altas de uso de materias primas secundarias',
    name_pt: 'Descrição de taxas mais elevadas de utilização de matérias-primas secundárias'
  },
  {
    id: '987d50a2-4134-460d-86e8-f9ce61ffc35d',
    name_dcycle: 'description_of_application_of_circular_design',
    datapoint_id: 'E5-2_03',
    esg_category: 'environmental',
    name_en: 'Description of application of circular design',
    name_es: 'Descripción de la aplicación del diseño circular.',
    name_pt: 'Descrição da aplicação do desenho circular'
  },
  {
    id: '778a697d-bbec-474a-af60-9909880119ed',
    name_dcycle: 'description_of_application_of_circular_business_practices',
    datapoint_id: 'E5-2_04',
    esg_category: 'environmental',
    name_en: 'Description of application of circular business practices',
    name_es: 'Descripción de la aplicación de prácticas comerciales circulares',
    name_pt: 'Descrição da aplicação de práticas comerciais circulares'
  },
  {
    id: '9dee0d17-dee7-4278-94e9-1cc3695d88c1',
    name_dcycle:
      "description_of_actions_taken_to_prevent_waste_generation_in_the_undertaking's_upstream_and_downstram_value_chain",
    datapoint_id: 'E5-2_05',
    esg_category: 'environmental',
    name_en:
      "Description of actions taken to prevent waste generation in the undertaking's upstream and downstram value chain",
    name_es:
      'Descripción de las acciones tomadas para prevenir la generación de residuos en la cadena de valor upstream y downstream de la empresa',
    name_pt:
      'Descrição das ações tomadas para prevenir a geração de resíduos na cadeia de valor a montante e a jusante da empresa'
  },
  {
    id: '85870c9c-3da8-42f0-916e-e29c4e44c941',
    name_dcycle: 'description_of_optimistation_of_waste_management',
    datapoint_id: 'E5-2_06',
    esg_category: 'environmental',
    name_en: 'Description of Optimistation of waste management',
    name_es: 'Descripción de Optimización de la gestión de residuos',
    name_pt: 'Descrição do Otimização da gestão de resíduos'
  },
  {
    id: '4f22183c-5230-4cfd-a3d8-b17092799299',
    name_dcycle:
      'information_about_collective_action_on_development_of_collaborations_or_initiatives_increasing_circularity_of_products_and_materials',
    datapoint_id: 'E5-2_07',
    esg_category: 'environmental',
    name_en:
      'Information about collective action on development of collaborations or initiatives increasing circularity of products and materials',
    name_es:
      'Información sobre acciones colectivas para el desarrollo de colaboraciones o iniciativas que aumenten la circularidad de productos y materiales.',
    name_pt:
      'Informações sobre ações coletivas no desenvolvimento de colaborações ou iniciativas que aumentem a circularidade de produtos e materiais'
  },
  {
    id: '3d631337-9859-48c2-9db5-678cfcf226d7',
    name_dcycle: 'description_of_contribution_to_circular_economy',
    datapoint_id: 'E5-2_08',
    esg_category: 'environmental',
    name_en: 'Description of contribution to circular economy',
    name_es: 'Descripción de la contribución a la economía circular',
    name_pt: 'Descrição da contribuição para a economia circular'
  },
  {
    id: '040b0f3e-3fa4-4a26-8675-54aba54c14a8',
    name_dcycle:
      'description_of_other_stakeholders_involved_in_collective_action_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-2_09',
    esg_category: 'environmental',
    name_en:
      'Description of other stakeholders involved in collective action (resource use and circular economy)',
    name_es:
      'Descripción de otros actores involucrados en la acción colectiva (uso de recursos y economía circular)',
    name_pt:
      'Descrição de outras partes interessadas envolvidas na ação coletiva (utilização de recursos e economia circular)'
  },
  {
    id: '3b65a0d4-0207-415b-b631-f994c640b140',
    name_dcycle: 'description_of_organisation_of_project_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-2_10',
    esg_category: 'environmental',
    name_en: 'Description of organisation of project (resource use and circular economy)',
    name_es: 'Descripción de la organización del proyecto (uso de recursos y economía circular)',
    name_pt: 'Descrição da organização do projeto (utilização de recursos e economia circular)'
  },
  {
    id: '856db694-e8bb-4fcd-8c42-ed417b2067e6',
    name_dcycle:
      'disclosure_of_how_target_relates_to_resources_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-3_01',
    esg_category: 'environmental',
    name_en: 'Disclosure of how target relates to resources (resource use and circular economy)',
    name_es:
      'Divulgación de cómo el objetivo se relaciona con los recursos (uso de recursos y economía circular)',
    name_pt:
      'Divulgação de como a meta se relaciona com os recursos (uso de recursos e economia circular)'
  },
  {
    id: 'ba30f2d7-9efd-4efa-8042-ddfd58f776f5',
    name_dcycle: 'disclosure_of_how_target_relates_to_increase_of_circular_design',
    datapoint_id: 'E5-3_02',
    esg_category: 'environmental',
    name_en: 'Disclosure of how target relates to increase of circular design',
    name_es: 'Divulgación de cómo el objetivo se relaciona con el aumento del diseño circular',
    name_pt: 'Divulgação de como a meta se relaciona com o aumento do design circular'
  },
  {
    id: 'ec45082f-d69c-4a45-becd-f9593b26df50',
    name_dcycle: 'disclosure_of_how_target_relates_to_increase_of_circular_material_use_rate',
    datapoint_id: 'E5-3_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of how target relates to increase of circular material use rate',
    name_es:
      'Divulgación de cómo el objetivo se relaciona con el aumento de la tasa de uso de material circular',
    name_pt:
      'Divulgação de como a meta se relaciona com o aumento da taxa de uso de materiais circulares'
  },
  {
    id: '61b6174d-91bd-4554-aac9-2038d0e195c1',
    name_dcycle: 'disclosure_of_how_target_relates_to_minimisation_of_primary_raw_material',
    datapoint_id: 'E5-3_04',
    esg_category: 'environmental',
    name_en: 'Disclosure of how target relates to minimisation of primary raw material',
    name_es:
      'Divulgación de cómo el objetivo se relaciona con la minimización de la materia prima primaria.',
    name_pt: 'Divulgação de como a meta se relaciona com a minimização de matéria-prima primária'
  },
  {
    id: 'ef2391ee-ac7e-4bb4-9c9b-e70f148ef52a',
    name_dcycle:
      'disclosure_of_how_target_relates_to_reversal_of_depletion_of_stock_of_renewable_resources',
    datapoint_id: 'E5-3_05',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how target relates to reversal of depletion of stock of renewable resources',
    name_es:
      'Divulgación de cómo el objetivo se relaciona con la reversión del agotamiento de las reservas de recursos renovables.',
    name_pt:
      'Divulgação de como a meta se relaciona com a reversão do esgotamento do estoque de recursos renováveis'
  },
  {
    id: 'b8736c67-1df9-4258-9078-77617a6b0da4',
    name_dcycle: 'target_relates_to_waste_management',
    datapoint_id: 'E5-3_06',
    esg_category: 'environmental',
    name_en: 'Target relates to waste management',
    name_es: 'El objetivo se relaciona con la gestión de residuos.',
    name_pt: 'Meta está relacionada à gestão de resíduos'
  },
  {
    id: 'c638a2ff-b989-4f9f-9812-958a079cef21',
    name_dcycle: 'disclosure_of_how_target_relates_to_waste_management',
    datapoint_id: 'E5-3_07',
    esg_category: 'environmental',
    name_en: 'Disclosure of how target relates to waste management',
    name_es: 'Divulgación de cómo el objetivo se relaciona con la gestión de residuos.',
    name_pt: 'Divulgação de como a meta se relaciona com a gestão de resíduos'
  },
  {
    id: '2644f969-3368-472b-9cc2-5efeb89d3e0c',
    name_dcycle:
      'disclosure_of_how_target_relates_to_other_matters_related_to_resource_use_or_circular_economy',
    datapoint_id: 'E5-3_08',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how target relates to other matters related to resource use or circular economy',
    name_es:
      'Divulgación de cómo el objetivo se relaciona con otros asuntos relacionados con el uso de recursos o la economía circular.',
    name_pt:
      'Divulgação de como a meta se relaciona com outras questões relacionadas ao uso de recursos ou à economia circular'
  },
  {
    id: '83a5aff1-3153-407e-8980-e2aa63a75aae',
    name_dcycle: 'layer_in_waste_hierarchy_to_which_target_relates',
    datapoint_id: 'E5-3_09',
    esg_category: 'environmental',
    name_en: 'Layer in waste hierarchy to which target relates',
    name_es: 'Capa en la jerarquía de residuos con la que se relaciona el objetivo',
    name_pt: 'Camada na hierarquia de resíduos à qual o alvo se refere'
  },
  {
    id: '78020206-4893-4f67-99a2-6e0614c15e64',
    name_dcycle:
      'disclosure_of_ecological_threshold_identified_and_methodology_used_to_identify_ecological_threshold_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-3_10',
    esg_category: 'environmental',
    name_en:
      'Disclosure of ecological threshold identified and methodology used to identify ecological threshold (resource use and circular economy)',
    name_es:
      'Divulgación del umbral ecológico identificado y metodología utilizada para identificar el umbral ecológico (uso de recursos y economía circular)',
    name_pt:
      'Divulgação do limiar ecológico identificado e metodologia utilizada para identificar o limiar ecológico (utilização de recursos e economia circular)'
  },
  {
    id: '302d0a4c-db29-4c55-8842-b7d652312261',
    name_dcycle:
      'disclosure_of_how_ecological_entity-specific_threshold_was_determined_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-3_11',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how ecological entity-specific threshold was determined (resource use and circular economy)',
    name_es:
      'Divulgación de cómo se determinó el umbral ecológico específico de la entidad (uso de recursos y economía circular)',
    name_pt:
      'Divulgação de como foi determinado o limiar específico da entidade ecológica (utilização de recursos e economia circular)'
  },
  {
    id: 'fc93157c-e73c-4d3f-a2cd-7e035c82e00b',
    name_dcycle:
      'disclosure_of_how_responsibility_for_respecting_identified_ecological_threshold_is_allocated_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-3_12',
    esg_category: 'environmental',
    name_en:
      'Disclosure of how responsibility for respecting identified ecological threshold is allocated (resource use and circular economy)',
    name_es:
      'Divulgación de cómo se asigna la responsabilidad de respetar el umbral ecológico identificado (uso de recursos y economía circular)',
    name_pt:
      'Divulgação de como é atribuída a responsabilidade pelo respeito do limiar ecológico identificado (utilização de recursos e economia circular)'
  },
  {
    id: '430aa27d-95fe-4465-aeec-bea2260aec21',
    name_dcycle: 'the_targets_being_set_and_presented_are_mandatory_(required_by_legislation)',
    datapoint_id: 'E5-3_13',
    esg_category: 'environmental',
    name_en: 'The targets being set and presented are mandatory (required by legislation)',
    name_es:
      'Los objetivos que se establecen y presentan son obligatorios (exigidos por la legislación)',
    name_pt: 'As metas definidas e apresentadas são obrigatórias (exigidas por legislação)'
  },
  {
    id: '2fa4ceef-b596-4fb1-afc5-1448512e77b5',
    name_dcycle: 'disclosure_of_information_on_material_resource_inflows',
    datapoint_id: 'E5-4_01',
    esg_category: 'environmental',
    name_en: 'Disclosure of information on material resource inflows',
    name_es: 'Divulgación de información sobre entradas de recursos materiales.',
    name_pt: 'Divulgação de informações sobre fluxos de recursos materiais'
  },
  {
    id: 'caa46598-8bfd-4faa-b1aa-5d35a0034ad5',
    name_dcycle:
      'overall_total_weight_of_products_and_technical_and_biological_materials_used_during_the_reporting_period',
    datapoint_id: 'E5-4_02',
    esg_category: 'environmental',
    name_en:
      'Overall total weight of products and technical and biological materials used during the reporting period',
    name_es:
      'Peso total general de productos y materiales técnicos y biológicos utilizados durante el período del informe.',
    name_pt:
      'Peso total global de produtos e materiais técnicos e biológicos utilizados durante o período coberto pelo relatório'
  },
  {
    id: '9232fb4c-51c5-4659-9cf0-ff1421770cf1',
    name_dcycle: 'percentage_of_biological_materials_(and_biofuels_used_for_non-energy_purposes)',
    datapoint_id: 'E5-4_03',
    esg_category: 'environmental',
    name_en: 'Percentage of biological materials (and biofuels used for non-energy purposes)',
    name_es:
      'Porcentaje de materiales biológicos (y biocombustibles utilizados con fines no energéticos)',
    name_pt:
      'Percentagem de materiais biológicos (e biocombustíveis utilizados para fins não energéticos)'
  },
  {
    id: 'c34057ac-0092-4418-a5c7-d58c8d01244e',
    name_dcycle:
      'the_absolute_weight_of_secondary_reused_or_recycled_components,_secondary_intermediary_products_and_secondary_materials_used_to_manufacture_the_undertaking’s_products_and_services_(including_packaging)',
    datapoint_id: 'E5-4_04',
    esg_category: 'environmental',
    name_en:
      'The absolute weight of secondary reused or recycled components, secondary intermediary products and secondary materials used to manufacture the undertaking’s products and services (including packaging)',
    name_es:
      'El peso absoluto de los componentes secundarios reutilizados o reciclados, los productos intermedios secundarios y los materiales secundarios utilizados para fabricar los productos y servicios de la empresa (incluido el embalaje).',
    name_pt:
      'O peso absoluto dos componentes secundários reutilizados ou reciclados, dos produtos intermediários secundários e dos materiais secundários utilizados no fabrico dos produtos e serviços da empresa (incluindo embalagens)'
  },
  {
    id: '6c22a599-b9ff-4e00-8c70-dfc194e49f7a',
    name_dcycle:
      'percentage_of_secondary_reused_or_recycled_components,_secondary_intermediary_products_and_secondary_materials',
    datapoint_id: 'E5-4_05',
    esg_category: 'environmental',
    name_en:
      'Percentage of secondary reused or recycled components, secondary intermediary products and secondary materials',
    name_es:
      'Porcentaje de componentes secundarios reutilizados o reciclados, productos intermedios secundarios y materiales secundarios',
    name_pt:
      'Porcentagem de componentes secundários reutilizados ou reciclados, produtos intermediários secundários e materiais secundários'
  },
  {
    id: 'd2710009-b37e-406a-b56e-331d7bfcbc1f',
    name_dcycle: 'description_of_methodologies_used_to_calculate_data_and_key_assumptions_used',
    datapoint_id: 'E5-4_06',
    esg_category: 'environmental',
    name_en: 'Description of methodologies used to calculate data and key assumptions used',
    name_es:
      'Descripción de las metodologías utilizadas para calcular los datos y supuestos clave utilizados.',
    name_pt:
      'Descrição das metodologias utilizadas para calcular os dados e principais premissas utilizadas'
  },
  {
    id: '477423a2-8372-4414-abd8-e9d77589fd46',
    name_dcycle: 'description_of_materials_that_are_sourced_from_by-products_or_waste_stream',
    datapoint_id: 'E5-4_07',
    esg_category: 'environmental',
    name_en: 'Description of materials that are sourced from by-products or waste stream',
    name_es: 'Descripción de materiales que provienen de subproductos o flujo de residuos.',
    name_pt: 'Descrição de materiais provenientes de subprodutos ou fluxo de resíduos'
  },
  {
    id: '10b3b79f-acdf-4245-a35f-d9975a1f1baf',
    name_dcycle: 'description_of_how_double_counting_was_avoided_and_of_choices_made',
    datapoint_id: 'E5-4_08',
    esg_category: 'environmental',
    name_en: 'Description of how double counting was avoided and of choices made',
    name_es: 'Descripción de cómo se evitó la doble contabilización y de las decisiones tomadas',
    name_pt: 'Descrição de como a dupla contagem foi evitada e das escolhas feitas'
  },
  {
    id: '51059eb2-ed47-48a9-bcea-a504f61b4914',
    name_dcycle:
      'description_of_the_key_products_and_materials_that_come_out_of_the_undertaking’s_production_process',
    datapoint_id: 'E5-5_01',
    esg_category: 'environmental',
    name_en:
      'Description of the key products and materials that come out of the undertaking’s production process',
    name_es:
      'Descripción de los productos y materiales clave que surgen del proceso productivo del emprendimiento.',
    name_pt:
      'Descrição dos principais produtos e materiais provenientes do processo produtivo da empresa'
  },
  {
    id: 'f93e9137-4868-4523-97ef-6db2a582d1d0',
    name_dcycle:
      'disclosure_of_the_expected_durability_of_the_products_placed_on_the_market,_in_relation_to_the_industry_average_for_each_product_group',
    datapoint_id: 'E5-5_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of the expected durability of the products placed on the market, in relation to the industry average for each product group',
    name_es:
      'Divulgación de la durabilidad esperada de los productos comercializados, en relación con el promedio de la industria para cada grupo de productos.',
    name_pt:
      'Divulgação da durabilidade esperada dos produtos colocados no mercado, em relação à média da indústria para cada grupo de produtos'
  },
  {
    id: '314cc9b1-fe88-4ecc-b52b-795d83164466',
    name_dcycle: 'disclosure_of_the_reparability_of_products',
    datapoint_id: 'E5-5_03',
    esg_category: 'environmental',
    name_en: 'Disclosure of the reparability of products',
    name_es: 'Divulgación de la reparabilidad de los productos.',
    name_pt: 'Divulgação da reparabilidade dos produtos'
  },
  {
    id: '34540f01-c6dd-4614-b9b0-0be2d10f5b10',
    name_dcycle: 'the_rates_of_recyclable_content_in_products',
    datapoint_id: 'E5-5_04',
    esg_category: 'environmental',
    name_en: 'The rates of recyclable content in products',
    name_es: 'Las tasas de contenido reciclable en los productos.',
    name_pt: 'As taxas de conteúdo reciclável em produtos'
  },
  {
    id: 'b02cd4c3-7c10-4e0a-be68-3e418dfb408c',
    name_dcycle: 'the_rates_of_recyclable_content_in_products_packaging',
    datapoint_id: 'E5-5_05',
    esg_category: 'environmental',
    name_en: 'The rates of recyclable content in products packaging',
    name_es: 'Las tasas de contenido reciclable en los envases de productos.',
    name_pt: 'As taxas de conteúdo reciclável nas embalagens dos produtos'
  },
  {
    id: '1675d1f3-5479-4fef-8c7f-4c585690a140',
    name_dcycle: 'description_of_methodologies_used_to_calculate_data_(resource_outflows)',
    datapoint_id: 'E5-5_06',
    esg_category: 'environmental',
    name_en: 'Description of methodologies used to calculate data (resource outflows)',
    name_es:
      'Descripción de las metodologías utilizadas para calcular los datos (salidas de recursos)',
    name_pt: 'Descrição das metodologias utilizadas para cálculo de dados (saídas de recursos)'
  },
  {
    id: '2bcd5b12-b6f3-4b8a-96df-868b1fced5cf',
    name_dcycle: 'total_waste_generated',
    datapoint_id: 'E5-5_07',
    esg_category: 'environmental',
    name_en: 'Total Waste generated',
    name_es: 'Total de residuos generados',
    name_pt: 'Resíduos totais gerados'
  },
  {
    id: 'e81710a4-82cd-4225-b451-5ccf5f7ca320',
    name_dcycle:
      'waste_diverted_from_disposal,_breakdown_by_hazardous_and_non-hazardous_waste_and_treatment_type',
    datapoint_id: 'E5-5_08',
    esg_category: 'environmental',
    name_en:
      'Waste diverted from disposal, breakdown by hazardous and non-hazardous waste and treatment type',
    name_es:
      'Residuos desviados de su disposición final, desglosados ​​por residuos peligrosos y no peligrosos y tipo de tratamiento',
    name_pt:
      'Resíduos desviados de eliminação, discriminação por resíduos perigosos e não perigosos e tipo de tratamento'
  },
  {
    id: '7f524dcb-5c68-4e6a-b710-20483c8916bd',
    name_dcycle:
      'waste_directed_to_disposal,_breakdown_by_hazardous_and_non-hazardous_waste_and_treatment_type',
    datapoint_id: 'E5-5_09',
    esg_category: 'environmental',
    name_en:
      'Waste directed to disposal, breakdown by hazardous and non-hazardous waste and treatment type',
    name_es:
      'Residuos destinados a disposición, desglose por residuos peligrosos y no peligrosos y tipo de tratamiento',
    name_pt:
      'Resíduos destinados a eliminação, discriminação por resíduos perigosos e não perigosos e tipo de tratamento'
  },
  {
    id: 'dc6005d7-9907-49ec-b145-f8aa2b357ed6',
    name_dcycle: 'non-recycled_waste',
    datapoint_id: 'E5-5_10',
    esg_category: 'environmental',
    name_en: 'Non-recycled waste',
    name_es: 'Residuos no reciclados',
    name_pt: 'Resíduos não reciclados'
  },
  {
    id: '9bb045ea-374e-4b63-a7eb-716ce8a4a726',
    name_dcycle: 'percentage_of_non-recycled_waste',
    datapoint_id: 'E5-5_11',
    esg_category: 'environmental',
    name_en: 'Percentage of non-recycled waste',
    name_es: 'Porcentaje de residuos no reciclados',
    name_pt: 'Porcentagem de resíduos não reciclados'
  },
  {
    id: 'abfcac48-f388-4578-b193-0f98cefcf3ed',
    name_dcycle: 'disclosure_of_composition_of_waste',
    datapoint_id: 'E5-5_12',
    esg_category: 'environmental',
    name_en: 'Disclosure of composition of waste',
    name_es: 'Divulgación de la composición de los residuos.',
    name_pt: 'Divulgação da composição dos resíduos'
  },
  {
    id: 'a1a330ef-946e-4324-9e53-eeb0ec44ad2f',
    name_dcycle: "disclosure_of_waste_streams_relevant_to_undertaking's_sector_or_activities",
    datapoint_id: 'E5-5_13',
    esg_category: 'environmental',
    name_en: "Disclosure of waste streams relevant to undertaking's sector or activities",
    name_es:
      'Divulgación de flujos de residuos relevantes para el sector o las actividades de la empresa.',
    name_pt: 'Divulgação de fluxos de resíduos relevantes para o setor ou atividades da empresa'
  },
  {
    id: 'f8045604-c2b2-4bd7-8d02-742796e7de50',
    name_dcycle: 'disclosure_of_materials_that_are_present_in_waste',
    datapoint_id: 'E5-5_14',
    esg_category: 'environmental',
    name_en: 'Disclosure of materials that are present in waste',
    name_es: 'Divulgación de materiales que están presentes en los residuos.',
    name_pt: 'Divulgação de materiais que estão presentes nos resíduos'
  },
  {
    id: '6a4a6162-6242-4f5f-a030-c0a0b372e179',
    name_dcycle: 'total_amount_of_hazardous_waste',
    datapoint_id: 'E5-5_15',
    esg_category: 'environmental',
    name_en: 'Total amount of hazardous waste',
    name_es: 'Cantidad total de residuos peligrosos',
    name_pt: 'Quantidade total de resíduos perigosos'
  },
  {
    id: 'e94c1c0c-0bf2-4753-871a-e43132e4a874',
    name_dcycle: 'total_amount_of_radioactive_waste',
    datapoint_id: 'E5-5_16',
    esg_category: 'environmental',
    name_en: 'Total amount of radioactive waste',
    name_es: 'Cantidad total de residuos radiactivos',
    name_pt: 'Quantidade total de resíduos radioativos'
  },
  {
    id: '1438f4a7-41d4-46d5-8864-e5dd90e16f1f',
    name_dcycle: 'description_of_methodologies_used_to_calculate_data_(waste_generated)',
    datapoint_id: 'E5-5_17',
    esg_category: 'environmental',
    name_en: 'Description of methodologies used to calculate data (waste generated)',
    name_es: 'Descripción de metodologías utilizadas para el cálculo de datos (residuos generados)',
    name_pt: 'Descrição das metodologias utilizadas para cálculo dos dados (resíduos gerados)'
  },
  {
    id: '70b30853-892c-42e4-9035-716a402d387f',
    name_dcycle: 'disclosure_of_its_engagement_in_product_end-of-life_waste_management',
    datapoint_id: 'E5-5_18',
    esg_category: 'environmental',
    name_en: 'Disclosure of its engagement in product end-of-life waste management',
    name_es:
      'Divulgación de su compromiso con la gestión de residuos de productos al final de su vida útil',
    name_pt: 'Divulgação do seu envolvimento na gestão de resíduos de produtos em fim de vida'
  },
  {
    id: '1d0b3677-8226-4c84-b5ac-15c59da26cd6',
    name_dcycle:
      'disclosure_of_quantitative_information_about_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_resource_use_and_circular_economy-related_impacts',
    datapoint_id: 'E5-6_01',
    esg_category: 'environmental',
    name_en:
      'Disclosure of quantitative information about anticipated financial effects of material risks and opportunities arising from resource use and circular economy-related impacts',
    name_es:
      'Divulgación de información cuantitativa sobre los efectos financieros previstos de los riesgos y oportunidades materiales que surgen del uso de recursos y los impactos relacionados con la economía circular.',
    name_pt:
      'Divulgação de informações quantitativas sobre os efeitos financeiros previstos de riscos e oportunidades materiais decorrentes do uso de recursos e impactos relacionados à economia circular'
  },
  {
    id: '0149a690-3ff6-495b-afad-539e5745d7f3',
    name_dcycle:
      'disclosure_of_qualitative_information_of_anticipated_financial_effects_of_material_risks_and_opportunities_arising_from_resource_use_and_circular_economy-related_impacts',
    datapoint_id: 'E5-6_02',
    esg_category: 'environmental',
    name_en:
      'Disclosure of qualitative information of anticipated financial effects of material risks and opportunities arising from resource use and circular economy-related impacts',
    name_es:
      'Divulgación de información cualitativa de los efectos financieros previstos de los riesgos y oportunidades materiales que surgen del uso de recursos y los impactos relacionados con la economía circular.',
    name_pt:
      'Divulgação de informações qualitativas sobre efeitos financeiros previstos de riscos e oportunidades materiais decorrentes do uso de recursos e impactos relacionados à economia circular'
  },
  {
    id: '5099085e-c363-4422-91f5-a24faeae2361',
    name_dcycle:
      'description_of_effects_considered_and_related_impacts_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-6_03',
    esg_category: 'environmental',
    name_en:
      'Description of effects considered and related impacts (resource use and circular economy)',
    name_es:
      'Descripción de los efectos considerados e impactos relacionados (uso de recursos y economía circular)',
    name_pt:
      'Descrição dos efeitos considerados e impactos relacionados (utilização de recursos e economia circular)'
  },
  {
    id: '36f3679f-904a-4f54-b6b9-0f11a22b9bf9',
    name_dcycle:
      'disclosure_of_critical_assumptions_used_in_estimates_of_financial_effects_of_material_risks_and_opportunities_arising_from_resource_use_and_circular_economy-related_impacts',
    datapoint_id: 'E5-6_04',
    esg_category: 'environmental',
    name_en:
      'Disclosure of critical assumptions used in estimates of financial effects of material risks and opportunities arising from resource use and circular economy-related impacts',
    name_es:
      'Divulgación de supuestos críticos utilizados en las estimaciones de los efectos financieros de los riesgos y oportunidades materiales que surgen del uso de recursos y los impactos relacionados con la economía circular.',
    name_pt:
      'Divulgação de pressupostos críticos utilizados nas estimativas de efeitos financeiros de riscos e oportunidades materiais decorrentes da utilização de recursos e impactos relacionados com a economia circular'
  },
  {
    id: '62d24e3c-b995-4784-a6b1-760bb2a1424f',
    name_dcycle:
      'description_of_related_products_and_services_at_risk_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-6_05',
    esg_category: 'environmental',
    name_en:
      'Description of related products and services at risk (resource use and circular economy)',
    name_es:
      'Descripción de productos y servicios relacionados en riesgo (uso de recursos y economía circular)',
    name_pt:
      'Descrição dos produtos e serviços relacionados em risco (utilização de recursos e economia circular)'
  },
  {
    id: '46e92ee8-e3bf-4fa4-b928-49f41e3e556c',
    name_dcycle:
      'explanation_of_how_time_horizons_are_defined,_financial_amounts_are_estimated_and_of_critical_assumptions_made_(resource_use_and_circular_economy)',
    datapoint_id: 'E5-6_06',
    esg_category: 'environmental',
    name_en:
      'Explanation of how time horizons are defined, financial amounts are estimated and of critical assumptions made (resource use and circular economy)',
    name_es:
      'Explicación de cómo se definen los horizontes temporales, se estiman los montos financieros y se realizan los supuestos críticos (uso de recursos y economía circular)',
    name_pt:
      'Explicação de como os horizontes temporais são definidos, os montantes financeiros são estimados e os pressupostos críticos assumidos (utilização de recursos e economia circular)'
  }
];
