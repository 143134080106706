import axios, { AxiosPromise, Method } from 'axios';
import controller from '../environments/controller';
import { authGetAccessToken } from '../services/authService';
import { localStorageGetItem } from './localStorage';
const environment = controller();

if (process.env.REACT_APP_ENVIRONMENT !== 'local' && process.env.STORYBOOK_RUNNING !== 'true') {
  // Request interceptor for API calls
  axios.interceptors.request.use(
    async (config) => {
      // IN FUTURE: How is this resolving when session is invalid
      const token = await authGetAccessToken();
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
}

export default function apiFetch<T = any>(
  method: Method,
  url: string,
  bodyForm?: LooseObject | null,
  customHeaders?: LooseObject | null,
  params?: LooseObject | null,
  timeout?: number | null,
  responseType?: string,
  signal?: AbortSignal
): AxiosPromise<T> {
  const headers: LooseObject = {
    Accept: 'application/json',
    'x-organization-id': localStorage.getItem('x-organization-id')
      ? localStorage.getItem('x-organization-id')
      : '',
    'x-partner': process.env.REACT_APP_PARTNER,
    'x-user-email':
      process.env.REACT_APP_ENVIRONMENT === 'local' ? localStorageGetItem('x-user-email') : '',
    'x-user-id':
      process.env.REACT_APP_ENVIRONMENT === 'local' ? localStorageGetItem('x-user-id') : ''
  };

  if (
    method.toUpperCase() === 'POST' ||
    method.toUpperCase() === 'PUT' ||
    method.toUpperCase() === 'PATCH'
  ) {
    headers['Content-Type'] = 'application/json';
  }

  if (customHeaders) {
    Object.keys(customHeaders).forEach((key) => {
      headers[key] = customHeaders[key];
    });
  }
  const config: LooseObject = {
    method,
    url: `${environment.backendUrl}${url}`,
    headers,
    data: bodyForm || null,
    params: params || null,
    timeout: timeout || null
  };
  if (responseType) {
    config['responseType'] = responseType;
  }
  if (signal) {
    config['signal'] = signal;
  }
  return axios(config);
}
