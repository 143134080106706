import { ColumnAPI, DropdownOptionAPI } from 'nuvo-react';
import { useNuvoCountries } from '../../../../../../customHooks/useNuvoCountries';
import { useLCATranslation } from '../../../../../../customHooks/translations/useLCATranslation';
import { useTranslation } from 'react-i18next';

export const useColumns = (data?: LCAMaterial[]) => {
  const { countriesNuvo } = useNuvoCountries();
  const { t: globalT } = useTranslation();
  const { t } = useLCATranslation();

  const materials_grouped_by_supplier = data?.reduce((acc, curr) => {
    const supplier = curr.supplier.toLowerCase();
    if (acc[supplier]) {
      acc[supplier].push(curr.name);
      return acc;
    }

    acc[supplier] = [curr.name];

    return acc;
  }, {} as Record<string, string[]>);

  const groups_by_country =
    data?.reduce((acc, curr) => {
      const country = curr.country.toLowerCase();

      if (acc[country]) {
        if (!acc[country].materials.includes(curr.name)) {
          acc[country].materials.push(curr.name);
        }

        if (!acc[country].suppliers.includes(curr.supplier)) {
          acc[country].suppliers.push(curr.supplier);
        }
        return acc;
      }

      acc[country] = {
        name: countriesNuvo.find((item) => item.value === country)?.label ?? country,
        materials: [curr.name],
        suppliers: [curr.supplier]
      };

      return acc;
    }, {} as Record<string, { name: string; materials: string[]; suppliers: string[] }>) ?? {};

  const groups_by_unit =
    data?.reduce((acc, curr) => {
      const units = curr.unit_list;

      for (const unit of units) {
        if (acc[unit.unit_id]) {
          if (!acc[unit.unit_id].materials.includes(curr.name)) {
            acc[unit.unit_id].materials.push(curr.name);
          }

          if (!acc[unit.unit_id].suppliers.includes(curr.supplier)) {
            acc[unit.unit_id].suppliers.push(curr.supplier);
          }
          continue;
        }

        acc[unit.unit_id] = {
          name: globalT(`units.${unit.unit_name}`),
          materials: [curr.name],
          suppliers: [curr.supplier]
        };
      }

      return acc;
    }, {} as Record<string, { name: string; materials: string[]; suppliers: string[] }>) ?? {};

  const columns: ColumnAPI[] = [
    {
      key: 'material',
      label: t('massiveUpload.material.material'),
      description: t('massiveUpload.material.materialDescription'),
      columnType: 'category',
      validations: [
        {
          validate: 'required',
          errorMessage: t('massiveUpload.material.errors.requiredMaterial')
        }
      ],
      dropdownOptions:
        data
          ?.filter(
            (material, index, array) =>
              array.findIndex((other_mat) => other_mat.name === material.name) === index
          )
          .map(
            (material): DropdownOptionAPI => ({
              label: material.name,
              type: 'string',
              value: material.name
            })
          ) || []
    },
    {
      key: 'supplier',
      label: t('massiveUpload.material.supplier'),
      description: t('massiveUpload.material.supplierDescription'),
      columnType: 'category',
      validations: [
        {
          validate: 'required'
        }
      ],
      dropdownOptions: data?.reduce((acc, curr) => {
        if (acc.some((option) => option.value === curr.supplier)) {
          return acc;
        }

        acc.push({
          label: curr.supplier,
          type: 'string',
          value: curr.supplier,
          validations: [
            {
              validate: {
                EQ: { material: materials_grouped_by_supplier?.[curr.supplier.toLowerCase()] }
              },
              errorMessage: t('massiveUpload.material.errors.invalidSupplier')
            }
          ]
        });

        return acc;
      }, [] as DropdownOptionAPI[])
    },
    {
      key: 'country',
      label: t('massiveUpload.material.country'),
      description: t('massiveUpload.material.countryDescription'),
      columnType: 'category',
      validations: [
        {
          validate: 'required'
        }
      ],
      dropdownOptions: Object.entries(groups_by_country).reduce(
        (acc, [country_id, country_info]) => {
          acc.push({
            label: country_info.name,
            type: 'string',
            value: country_id,
            validations: [
              {
                validate: {
                  EQ: {
                    material: country_info.materials,
                    supplier: country_info.suppliers
                  }
                },
                errorMessage: t('massiveUpload.material.errors.invalidCountry')
              }
            ]
          });

          return acc;
        },
        [] as DropdownOptionAPI[]
      )
    },
    {
      key: 'quantity',
      label: t('massiveUpload.material.quantity'),
      description: t('massiveUpload.material.quantityDescription'),
      columnType: 'float',
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'unit',
      label: t('massiveUpload.material.unit'),
      description: t('massiveUpload.material.unitDescription'),
      columnType: 'category',
      validations: [
        {
          validate: 'required'
        }
      ],
      dropdownOptions: Object.entries(groups_by_unit).reduce((acc, [unit_id, unit_info]) => {
        acc.push({
          label: unit_info.name,
          type: 'string',
          value: unit_id,
          validations: [
            {
              validate: {
                EQ: {
                  material: unit_info.materials,
                  supplier: unit_info.suppliers
                }
              },
              errorMessage: t('massiveUpload.material.errors.invalidUnit')
            }
          ]
        });

        return acc;
      }, [] as DropdownOptionAPI[])
    },
    {
      key: 'tag',
      description: t('massiveUpload.material.tagDescription'),
      label: t('massiveUpload.material.tag'),
      columnType: 'string'
    }
  ];

  return { columns };
};
