import { ReactNode, useEffect, useMemo } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { UserContext } from '../../../context/userContext';
import { authCheckSession } from '../../../services/authService';
import { User } from '../../../types/entities/user';
import LoaderTables from '../../ui/loaders/loaderTables/LoaderTables';
import ErrorPageNotFound from '../errorPage/ErrorPageNotFound/ErrorPageNotFound';
import InteractiveTutorial from '../interactiveTutorial/mainTutorial/InteractiveTutorial';

type TemplateState = {
  auth: {
    user: User;
  };
};

type CompanyTypes = 'company' | 'group_fund';

type OwnProps = {
  children: ReactNode[] | ReactNode;
  featureFlag?: boolean | undefined;
  companyTypes?: CompanyTypes[];
  allowedInFundRole?: boolean;
};

const mapStateToProps = (state: TemplateState) => {
  return {
    user: state.auth.user
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & OwnProps;

function PrivateRoute({
  children,
  featureFlag = true,
  companyTypes,
  user,
  allowedInFundRole = false
}: Props) {
  const navigate = useNavigate();

  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );

  async function checkAuth() {
    try {
      const isAuthenticated = await authCheckSession();

      const hasInvites = user.invites && user.invites.length > 0;
      if (!isAuthenticated || !user) {
        navigate(ROUTES.LOGIN);
        return;
      }

      if (user.onboarding_done && foundOrganization) {
        return;
      }

      if (user.onboarding_done && !foundOrganization && !hasInvites) {
        navigate(`${ROUTES.REGISTER_COMPANY}/${user.id}`);
        return;
      }

      if (!hasInvites) {
        navigate(`${ROUTES.REGISTER}/${user.id}`);
        return;
      }

      const foundInvite = user?.invites?.find(
        (invite) => invite.role === 'child' && invite.status === 'pending'
      );
      if (foundInvite) {
        navigate(`${ROUTES.REGISTER}/${user.id}`);
        return;
      }

      navigate(`${ROUTES.REGISTER_INVITED}/${user.id}`);
      return;
    } catch (err) {
      navigate(ROUTES.LOGIN);
    }
  }

  useEffect(() => {
    checkAuth();
  }, []);

  const getCompanyType = () => {
    if (foundOrganization?.is_group_fund) return 'group_fund';
    return 'company';
  };

  const companyType = useMemo(() => getCompanyType(), [user?.selectedOrganization]);

  if (featureFlag === undefined) return <LoaderTables />;
  if (featureFlag === false) return <ErrorPageNotFound />;
  if (!!companyTypes && !companyTypes?.includes(companyType)) return <ErrorPageNotFound />;

  const companyRole = foundOrganization?.role;
  if (companyRole === 'fund' && !allowedInFundRole) return <ErrorPageNotFound />;

  return (
    <UserContext.Provider value={user}>
      <InteractiveTutorial>{children}</InteractiveTutorial>
    </UserContext.Provider>
  );
}

export default connector(PrivateRoute);
