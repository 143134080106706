import React, { ChangeEvent, forwardRef } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';

interface Props extends Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  value?: string;
  onChange: (value: string) => void;
  error?: ErrorType;
}

const Base = forwardRef<
  HTMLTextAreaElement,
  React.InputHTMLAttributes<HTMLTextAreaElement> & { hasError?: boolean }
>(({ hasError, ...rest }, ref) => {
  const borderClass = hasError ? 'error-border-color' : 'input-border-color';

  return <textarea {...rest} ref={ref} className={`font-base textareav2 ${borderClass}`} />;
});

Base.displayName = 'TextArea';

const InputTextArea = ({ value, onChange, error, ...rest }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'input' });

  const handleOnChangeValue = (e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value);

  const placeholder = !value && rest?.placeholder ? rest.placeholder : t('noData');

  return (
    <div>
      <Base
        {...rest}
        placeholder={placeholder}
        value={value}
        onChange={handleOnChangeValue}
        hasError={Boolean(error)}
      />
    </div>
  );
};

InputTextArea.Base = Base;

export default InputTextArea;
