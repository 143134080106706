import { ReactNode, useState } from 'react';
import AccordionHandler from './AccordionHandler';
import { useTranslation } from 'react-i18next';
import Icon from '../icon/Icon';

export type AccordionItemType = {
  label: ReactNode;
  content: ReactNode;
  iconLeft?: boolean;
  highlight?: boolean;
  active?: boolean;
  closeFromDown?: boolean;
};

const AccordionItem = ({
  label,
  content,
  iconLeft,
  highlight,
  active,
  closeFromDown
}: AccordionItemType) => {
  const [isActive, setActive] = useState(active);
  const { t } = useTranslation();
  const imgDown = <Icon icon='chevron_down' color='gradient' />;
  const imgUp = <Icon icon='chevron_up' color='gradient' />;
  return (
    <>
      {((!isActive && closeFromDown) || !closeFromDown) && (
        <AccordionHandler
          label={label}
          iconLeft={iconLeft}
          highlight={highlight}
          active={isActive}
          imgSrc={
            (!isActive && closeFromDown && imgDown) ||
            (isActive && !closeFromDown && imgDown) ||
            imgUp ||
            imgDown
          }
          setActive={setActive}
        />
      )}
      {isActive && content}
      {closeFromDown && isActive && (
        <AccordionHandler
          label={t('general.filters.show_less')}
          iconLeft={iconLeft}
          highlight={highlight}
          active={isActive}
          setActive={setActive}
          imgSrc={imgUp}
        />
      )}
    </>
  );
};

export default AccordionItem;
