import { useEffect, useState } from 'react';
import SelectRaw from './selectRaw/SelectRaw';
import './styles.scss';
import useOptionsFiltered from './hooks/useOptionsFiltered';
import useDebounceValue from 'customHooks/useDebounceValue';

type Props = Omit<React.ComponentProps<typeof SelectRaw>, 'totalOptions'> & {
  sort?: boolean;
  optional?: boolean;
  searchBackendCallback?: (searchValue: string) => Promise<void>;
  removeSelectOption?: boolean;
};

const Select = ({ sort, optional, searchBackendCallback, removeSelectOption, ...props }: Props) => {
  const [searchValue, setSearchValue] = useState<string>();
  const onChangeSearchValue = (value: string) => setSearchValue(value);

  let options = props.options;

  let debounceValue: string | undefined = undefined;

  if (!searchBackendCallback) {
    options = useOptionsFiltered({
      options: props.options,
      sort,
      optional,
      searchValue: searchValue || ''
    });
  } else {
    debounceValue = useDebounceValue(searchValue);
  }

  useEffect(() => {
    if (debounceValue !== undefined && searchBackendCallback) {
      searchBackendCallback(debounceValue);
    }
  }, [debounceValue]);

  return (
    <SelectRaw
      {...props}
      removeSelectOption={removeSelectOption}
      options={options}
      searchValue={searchValue}
      onChangeSearchValue={onChangeSearchValue}
    />
  );
};

export default Select;
