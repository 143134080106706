import { HowToUploadStep } from 'components/ui/howToUpload/HowToUploadStep';

const Layout: React.FC<React.ComponentProps<typeof HowToUploadStep.Layout>> = ({
  className,
  ...rest
}) => {
  const classes = ['px-4'];

  if (className) {
    classes.push(className);
  }

  return <HowToUploadStep.Layout {...rest} className={classes.join(' ')} />;
};

export const HowToUploadStepLCAMaterials = {
  Layout,
  Image: HowToUploadStep.Image,
  TextWrapper: HowToUploadStep.TextWrapper,
  Title: HowToUploadStep.Title,
  Subtitle: HowToUploadStep.Subtitle
};
