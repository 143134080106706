import { ADMIN_ROUTES } from './admin';
import { COMMUNICATE_ROUTES } from './communicate';
import { CUSTOMIZE_ROUTES } from './customize';
import { DASHBOARD_ROUTES } from './dashboard';
import { IMPROVE_ROUTES } from './improve';
import { LCA_ROUTES } from './lca';
import { MEASURE_ROUTES } from './measure';

export const ROUTES = {
  REGISTER: '/register',
  REGISTER_INVITED: '/register-invited',
  REGISTER_COMPANY: '/register-company',
  ONBOARDING: '/onboarding',
  CALLBACK: '/callback',
  EMAIL_SENT: '/email-sent',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  CREATE_PASSWORD: '/create-password',
  PROFILE: '/profile',
  MEMBERS: '/members',
  PLANS: '/plans',
  INVOICING: '/invoicing',
  TODOS: '/tasks',
  CREATE_ORGANIZATION_HOLDING: '/create-organization-holding',
  EMPLOYEE_FORM: '/employee-form',
  EMPLOYEE_FORM_QR: '/employee-form-qr',
  BUSINESS_TRAVEL_FORM: '/business-travel-form',
  BUDGET_CALCULATOR: '/budget-calculator',
  BOOK_MEETING: '/book-meeting',
  ORGANIZATION_SELECTOR: '/organization-selector',
  SOMETHING_WENT_WRONG: '/something-went-wrong',
  NOT_FOUND: '/not-found',
  DATA_PROGRESS: '/data-progress',
  ...DASHBOARD_ROUTES,
  ...ADMIN_ROUTES,
  ...IMPROVE_ROUTES,
  ...COMMUNICATE_ROUTES,
  ...MEASURE_ROUTES,
  ...LCA_ROUTES,
  ...CUSTOMIZE_ROUTES
};

export const PREMIUM_ROUTES = [
  ROUTES.MEASURE_ACTIVITY_PURCHASES,
  ROUTES.COMMUNICATE_GHG,
  ROUTES.COMMUNICATE_WEB,
  ROUTES.TODOS,
  ROUTES.SNIPPET,
  ROUTES.CUSTOM_EMISSION_FACTORS,
  ROUTES.CUSTOM_INTENSITY_METRICS,
  ROUTES.CUSTOMIZE_MAIN_PAGE,
  ROUTES.MEASURE_EVENTS,
  '?showOrgDescriptionModalGHG=true',
  '?showOrgDescriptionModalDcycle=true',
  '?showOrgDescriptionModalSnippet=true',
  '?showOrgDescriptionModalPai=true'
];

export const PAID_ROUTES = [
  ROUTES.COMMUNICATE_14001,
  ROUTES.COMMUNICATE_CSRD_REPORTING,
  ROUTES.IMPROVE_LEAN_AND_GREEN,
  ROUTES.COMMUNICATE_CDP_REPORTING,
  ROUTES.COMMUNICATE_ECOVADIS_REPORTING
];

export const ESG_ROUTES = [
  ROUTES.IMPROVE_DASHBOARD,
  ROUTES.IMPROVE_DASHBOARD_TOTAL,
  ROUTES.IMPROVE_DASHBOARD_INVESTMENT,
  ROUTES.IMPROVE_DASHBOARD_GROUPING,
  ROUTES.IMPROVE_DASHBOARD_LOGISTICS
];
