import { useTranslation } from 'react-i18next';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import { useFormContext } from '../hooks/useForm';
import FormTextArea from '../../../../ui/formComponents2/formInputs/formTextArea/FormTextArea';
import FormSelect from '../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormYesNoRadio from '../../../../ui/formComponents2/formInputs/formYesNoRadio/FormYesNoRadio';
import useYearOptions from '../../../../../customHooks/useYearOptions';
import { ROUTES } from '../../../../../constants/routes';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../../../../ui/tooltip/Tooltip';
import { RadioButtonsJustified } from '../../../../ui/formComponents2/formInputs/RadioButtonJustified';

const GeneralInfo = () => {
  const { t: tG } = useTranslation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'orgDescriptionModal.ghg_report.form.generalInfo'
  });
  const {
    formData,
    onChangeValue,
    loadingData,
    verificationOptions,
    approachOptions,
    intensityMetricOptions,
    loadingOptions,
    onClose
  } = useFormContext();
  const { years } = useYearOptions();

  const navigate = useNavigate();

  console.log('formData', formData);

  return (
    <>
      <FormText
        iconV2='business'
        label={t('tradeName')}
        value={formData.tradeName}
        onChange={onChangeValue('tradeName')}
        error={formData.errors.find((elem) => elem.error === 'tradeName')}
        placeholder={t('tradeNamePlaceholder')}
      />
      <FormTextArea
        label={t('contactInfo')}
        placeholder={t('contactInfoPlaceholder')}
        tooltip={t('contactInfoTooltip')}
        value={formData.contactInformation}
        onChange={onChangeValue('contactInformation')}
        error={formData.errors.find((elem) => elem.error === 'contactInformation')}
      />
      <FormSelect
        iconV2='calendar'
        label={t('baseYear')}
        tooltip={t('baseYearTooltip')}
        options={years}
        value={formData.baseYear}
        onChange={onChangeValue('baseYear')}
        error={formData.errors.find((elem) => elem.error === 'baseYear')}
      />

      <FormYesNoRadio.Justified
        label={t('changeBaseYear')}
        tooltip={t('changeBaseYearTooltip')}
        whenToJustify='yes'
        justificationValue={formData.justificationChangeBaseYear}
        value={formData.changeBaseYear}
        onChangeRadio={onChangeValue('changeBaseYear')}
        onChangeJustification={onChangeValue('justificationChangeBaseYear')}
        error={
          formData.errors.find((elem) => elem.error === 'changeBaseYear')
            ? {
                error: 'changeBaseYear',
                description: tG('error.requiredField')
              }
            : undefined
        }
        errorTextArea={formData.errors.find((elem) => elem.error === 'justificationChangeBaseYear')}
        translations={{
          yes: t('yes'),
          no: t('no'),
          justificationPlaceholder: t('justificationPlaceholder')
        }}
      />

      <RadioButtonsJustified
        label={t('verification')}
        value={formData.verification}
        onChangeRadio={onChangeValue('verification')}
        options={verificationOptions as any}
        onChangeJustification={onChangeValue('verificationOther')}
        whenToJustify={['other']}
        justificationValue={formData.verificationOther}
        errorTextArea={formData.errors.find((elem) => elem.error === 'verificationOther')}
      />

      {/* <FormSelect
        iconV2='vat'
        label={t('verification')}
        options={verificationOptions}
        value={formData.verification}
        onChange={onChangeValue('verification')}
        error={formData.errors.find((elem) => elem.error === 'verification')}
        loading={loadingData || loadingOptions}
      /> */}
      <FormSelect
        iconV2='input_text'
        label={t('approach')}
        tooltip={t('approachTooltip')}
        options={approachOptions}
        value={formData.approach}
        onChange={onChangeValue('approach')}
        error={formData.errors.find((elem) => elem.error === 'approach')}
        loading={loadingData}
      />
      <FormSelect
        iconV2='growth'
        label={
          <span className='flex gap-1'>
            {t('intensityMetric1')} ·
            <span
              className='link'
              onClick={() => {
                navigate(ROUTES.CUSTOM_INTENSITY_METRICS + '?open=create');
                onClose();
              }}>
              {t('createNewMetric')}
            </span>
            <Tooltip text={t('createNewMetricTooltip')} />
          </span>
        }
        options={intensityMetricOptions.filter((elem) => elem.id !== formData.intensityMetric2.id)}
        value={formData.intensityMetric1}
        onChange={onChangeValue('intensityMetric1')}
        error={formData.errors.find((elem) => elem.error === 'intensityMetric1')}
        loading={loadingOptions || loadingData}
        placeholder={t('intensityMetric1Placeholder')}
        removeSelectOption={true}
      />
      <FormSelect
        iconV2='growth'
        label={t('intensityMetric2')}
        options={intensityMetricOptions.filter((elem) => elem.id !== formData.intensityMetric1.id)}
        value={formData.intensityMetric2}
        onChange={onChangeValue('intensityMetric2')}
        error={formData.errors.find((elem) => elem.error === 'intensityMetric2')}
        loading={loadingOptions || loadingData}
        placeholder={t('intensityMetric2Placeholder')}
        removeSelectOption={true}
      />
    </>
  );
};

export default GeneralInfo;
