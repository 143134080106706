import { fetchFrameworkPlans, FrameworkPlan } from 'services/api/frameworks';
import { useAvailableFrameworks } from './useAvailableFrameworks';
import { useQuery } from '@tanstack/react-query';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';

const useFrameworksPlans = (organizationId?: string) => {
  const org = useSelectedOrganization();
  const orgId = organizationId ?? org?.id;
  const { availableFrameworks, isLoading: loadingAvailableFrameworks } =
    useAvailableFrameworks(orgId);
  const flags = useFeatureFlags();
  const { data, isLoading } = useQuery<FrameworkPlan[], Error>({
    queryKey: ['frameworkPlans', orgId, JSON.stringify(availableFrameworks)],
    queryFn: async () =>
      (
        await Promise.all(
          availableFrameworks?.map((framework) => fetchFrameworkPlans(framework.id, orgId)) ?? []
        )
      ).filter((plan): plan is FrameworkPlan => plan !== null),
    enabled: flags?.sotDcycleDemos && !!orgId && !!availableFrameworks
  });
  return {
    availableFrameworks,
    frameworkPlans: data,
    frameworksLoading: loadingAvailableFrameworks || isLoading
  };
};

export default useFrameworksPlans;
