import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { loadUser } from '../../../../../actions/auth';
import { authParseLoginCallback } from '../../../../../services/authService';
import apiFetch from '../../../../../utils/apiFetch';
import Button from '../../../../ui/button/Button';
import Checkbox from '../../../../ui/formComponents/checkbox/Checkbox';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import InputNumber from '../../../../ui/formComponents/inputNumber/InputNumber';
import InputText from '../../../../ui/formComponents/inputText/InputText';
import './style.scss';
import { HoldingCompany } from '../../../../../types/entities/holdings';
import { Session } from '../../../../../types/entities/user';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';
type Props = {
  company: string;
  handleOpenUnBindCompanyModal: (id: string) => void;
  holdingId: string;
  updateCompanyStatus: (id: string, childCompany: HoldingCompany) => void;
};
function ConfigureCompany({
  company,
  handleOpenUnBindCompanyModal,
  holdingId,
  updateCompanyStatus
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const flags = useFeatureFlags();
  const [sharePercentage, setSharePercentage] = useState('');
  const [includeScope3, setIncludeScope3] = useState(false);
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [tag, setTag] = useState('');

  const onChangeSharePercentage = (value: string) => {
    const newErrors = errors.filter((elem) => elem.error !== 'sharePercentage');
    setErrors(newErrors);
    const max = 100;
    const min = 0;
    if (value === '') {
      setSharePercentage(value);
    } else {
      const valueParsed = parseFloat(value);
      if (valueParsed <= max && valueParsed >= min) {
        setSharePercentage(value);
      }
    }
  };

  const onChangeTag = (e: ChangeEvent<HTMLInputElement>) => {
    setTag(e.target.value);
  };

  const handleLinkCompany = async () => {
    try {
      setLoadingButton(true);
      if (sharePercentage === '') {
        setErrors([{ error: 'sharePercentage' }]);
      } else {
        await apiFetch(
          'POST',
          '/matrices/confirm',
          {
            parent_id: holdingId,
            child_id: company,
            share: parseFloat(sharePercentage) / 100,
            investment_scopes: includeScope3 ? [1, 2, 3] : [1, 2],
            answer: 'accepted',
            tag: tag
          },
          { 'x-organization-id': holdingId }
        );
        updateCompanyStatus(company, {
          share: parseFloat(sharePercentage) / 100,
          investment_scopes: includeScope3 ? [1, 2, 3] : [1, 2],
          tag: tag,
          status: 'accepted',
          child_id: company,
          child_name: ''
        });
        const sessionData: Session = await authParseLoginCallback();

        await dispatch(loadUser(sessionData, false, false, holdingId, flags?.sotDcycleDemos));
      }
      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
    }
  };

  const deleteRequest = () => {
    handleOpenUnBindCompanyModal(company);
  };

  return (
    <div className='configure-company'>
      <FormHeader
        title={t('companies.configureCompany')}
        description={t('companies.configureCompanyDescription')}
      />
      <div className='form-wrapper'>
        <div className='input-element'>
          <InputNumber
            icon={'/images/icons/piechart.svg'}
            placeholder={t('companies.writeSharePercentage')}
            label={t('companies.sharePercentage')}
            value={sharePercentage}
            onChangeValue={onChangeSharePercentage}
            height='31px'
            fontClass='input-small-font'
            size='small'
            error={errors.find((elem) => elem.error === 'sharePercentage')}
          />
          <div className='checkbox-margin'>
            <Checkbox
              text={t('companies.scope3Included')}
              onChange={setIncludeScope3}
              selected={includeScope3}
            />
          </div>
        </div>
        <div className='input-element'>
          <InputText
            icon={'/images/icons/message.svg'}
            placeholder={t('companies.writeTag')}
            label={t('companies.tagOptional')}
            value={tag}
            onChangeValue={onChangeTag}
            height='31px'
            fontClass='input-small-font'
            size='small'
            maxChars={25}
          />
        </div>
      </div>

      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          text={t('companies.link')}
          onClick={handleLinkCompany}
          size='small'
          loading={loadingButton}
        />
      </div>
      <span className='highlight-text-color body1-bold-font pointer' onClick={deleteRequest}>
        {t('companies.deleteRequest')}
      </span>
    </div>
  );
}

export default ConfigureCompany;
