import Button from 'components/ui/button/Button';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from 'components/ui/formComponents2/select/Select';
import Modal from 'components/ui/modal/Modal';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import { useMaterialOptions } from '../hooks/useMaterialOptions';
import useCountries from 'components/measure/vehicles/hooks/useCountries';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateMaterialForm, createMaterialSchema } from '../schemas/createMaterial';
import { adaptHookFormDropdownError, adaptHookFormError } from 'lib/ReactHookForm/adaptError';
import { useErrorTranslations } from 'customHooks/translations/useErrorTranslations';
import InputTextArea from 'components/ui/formComponents2/inputTextArea/InputTextArea';
import { useCreateLCAMaterial } from 'components/lca/hooks/useCreateLCAMaterial';
import { adaptLCAPostMaterial } from 'adapters/lca';

type Props = {
  onClose: () => void;
};

const DEFAULT_VALUES = {
  name: '',
  description: '',
  supplier: '',
  country: { id: '', name: '' },
  type: { id: '', name: '' }
};

export const FormCreateMaterial: React.FC<Props> = ({ onClose }) => {
  const { t } = useLCATranslation({ keyPrefix: 'materialManagement.createMaterialModal' });
  const { t: tErrors } = useErrorTranslations();

  const { options } = useMaterialOptions();
  const countries = useCountries();

  const { createMaterial, isPending } = useCreateLCAMaterial();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(createMaterialSchema),
    defaultValues: DEFAULT_VALUES
  });

  const onSubmit: SubmitHandler<typeof DEFAULT_VALUES> = async (values) => {
    const typedVals = values as CreateMaterialForm;

    await createMaterial(adaptLCAPostMaterial(typedVals));

    onClose();
  };

  return (
    <>
      <Modal.Header title={t('title')} />
      <form className='grid grid-cols-2 gap-y-8 gap-x-2 pb-1' onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper
          iconV2='leather'
          label={t('nameLabel')}
          tooltip={t('nameTooltip')}
          error={adaptHookFormError(errors.name, tErrors)}
          className='col-span-2'>
          <InputText {...register('name')} placeholder={t('namePlaceholder')} />
        </InputWrapper>

        <InputWrapper
          iconV2='business'
          label={t('supplierLabel')}
          className='col-span-2'
          error={adaptHookFormError(errors.supplier, tErrors)}>
          <InputText {...register('supplier')} placeholder={t('supplierPlaceholder')} />
        </InputWrapper>

        <InputWrapper.WithoutWrapperPlain
          label={t('descriptionLabel')}
          tooltip={t('descriptionTooltip')}
          error={adaptHookFormError(errors.description, tErrors)}
          className='col-span-2'>
          <InputTextArea.Base
            {...register('description')}
            placeholder={t('descriptionPlaceholder')}
            hasError={Boolean(errors.description)}
          />
        </InputWrapper.WithoutWrapperPlain>

        <InputWrapper
          iconV2='location'
          tooltip={t('countryTooltip')}
          label={t('countryLabel')}
          error={adaptHookFormDropdownError(errors.country, tErrors)}>
          <Select
            onChange={(newVal) => setValue('country', newVal)}
            value={watch('country')}
            options={countries}
          />
        </InputWrapper>

        <InputWrapper
          iconV2='dropdown'
          label={t('typeLabel')}
          error={adaptHookFormDropdownError(errors.type, tErrors)}>
          <Select
            onChange={(newVal) => setValue('type', newVal)}
            value={watch('type')}
            options={options}
          />
        </InputWrapper>

        <Button lookAndFeel='secondary' text={t('cancel')} size='medium' onClick={onClose} />
        <Button
          lookAndFeel='primary'
          text={t('complete')}
          size='medium'
          type='submit'
          loading={isPending}
        />
      </form>
    </>
  );
};
